<script lang="ts">
import Toggle from './Toggle.svelte'
import { toggleSwitchStepper } from '../../../store/trainingWidgetsStore'
import { utilService } from '../../../utils/util.service'

export let values: {
	labels: string[]
	selectedIndex: number
	stepAmount: number
	label: string
	trigger: number
	value: {
		answer: string
		labels: string[]
		selectedIndex: number
		stepperValue: number
		stepperLabel: string
	}
}
export let data = []
export let id: string

let currentValue = 0

let toggleValues: any

if (!$toggleSwitchStepper[id]) {
	$toggleSwitchStepper[id] = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		stepAmount: values.stepAmount,
		label: values.label,
		trigger: values.trigger,
		value: {
			answer: '',
			labels: [],
			selectedIndex: values.selectedIndex,
			stepperValue: 0
		}
	}
}
if (data.length > 0 && $toggleSwitchStepper[id].value.answer == '') {
	currentValue = data[0].value.stepperValue
} else if ($toggleSwitchStepper[id]) {
	currentValue = $toggleSwitchStepper[id].value.stepperValue
		? $toggleSwitchStepper[id].value.stepperValue
		: 0
} else {
	currentValue = 0
}

if (data.length > 0 && $toggleSwitchStepper[id].value.answer == '') {
	toggleValues = {
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
	values.value = {
		selectedIndex: data[0].value.selectedIndex,
		answer: '',
		labels: values.labels,
		stepperValue: currentValue,
		stepperLabel: values.label
	}
} else if ($toggleSwitchStepper[id]) {
	toggleValues = {
		labels: values.labels,
		selectedIndex: $toggleSwitchStepper[id].value.selectedIndex,
		value: $toggleSwitchStepper[id].value.selectedIndex
	}
	values.value = {
		selectedIndex: $toggleSwitchStepper[id].value.selectedIndex,
		answer: '',
		labels: values.labels,
		stepperValue: currentValue,
		stepperLabel: values.label
	}
} else {
	toggleValues = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
	values.value = {
		selectedIndex: values.selectedIndex,
		answer: '',
		labels: values.labels,
		stepperValue: currentValue,
		stepperLabel: values.label
	}
}

function increment(event: Event) {
	event.preventDefault()
	currentValue = Math.min(currentValue + values.stepAmount, 100)
	$toggleSwitchStepper[id].value.stepperValue = currentValue
}

function decrement(event: Event) {
	event.preventDefault()
	currentValue = Math.max(currentValue - values.stepAmount, 0)
	$toggleSwitchStepper[id].value.stepperValue = currentValue
}

$: if (toggleValues.selectedIndex === values.trigger) {
	values.value.stepperLabel = values.label
	values.value.selectedIndex = toggleValues.selectedIndex
	values.value.stepperValue = currentValue
	values.value.answer = values.labels[toggleValues.selectedIndex]
	$toggleSwitchStepper[id] = values
} else {
	values.value.selectedIndex = toggleValues.selectedIndex
	values.value.answer = values.labels[toggleValues.selectedIndex]
	values.value.stepperValue = null
	values.value.stepperLabel = null
	$toggleSwitchStepper[id] = values
}
</script>

<Toggle bind:values={toggleValues} />

{#if toggleValues.selectedIndex === values.trigger}
	<div class="stepper flex">
		<button
			class="stepper-button"
			on:click|preventDefault={decrement}
			disabled={currentValue === 0}>-</button>
		<span class="stepper-value flex align-center"
			>{currentValue} {utilService.getShortLabel(values.label)}</span>
		<button
			class="stepper-button"
			on:click|preventDefault={increment}>+</button>
	</div>
{/if}

<style>
.stepper {
	display: flex;
	align-items: center;
	width: 200px;
	height: 40px;
	overflow: hidden;
	gap: 3.5px;
}

.stepper-button {
	padding: 9px 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.08);
}

.stepper-button:hover {
	background-color: #e5e5e5;
}

.stepper-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.stepper-value {
	min-width: 85px;
	height: 25px;
	text-align: center;
	justify-content: center;
	align-items: center;
	padding: 2px 13px;
	border-radius: 2px;
	border: 1px solid #e6e8f0;
	background: var(--primary-white, #fff);
}
</style>
