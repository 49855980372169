<script lang="ts">
import { _ } from 'svelte-i18n'
import TermsIcon from './icons/TermsIcon.svelte'
import PrivacyPolicyIcon from './icons/PrivacyPolicyIcon.svelte'
import CookieIcon from './icons/CookieIcon.svelte'
import RefundIcon from './icons/RefundIcon.svelte'
import UsePolicyIcon from './icons/UsePolicyIcon.svelte'
import { useNavigate } from 'svelte-navigator'

const navigate = useNavigate()

const terms = [
	{
		firstLine: 'terms',
		secondLine: 'conditions',
		icon: TermsIcon,
		action: () => window.open('https://app.getterms.io/view/x6YYi/tos/en-us')
	},
	{
		firstLine: 'privacy',
		secondLine: 'policy',
		icon: PrivacyPolicyIcon,
		action: () => window.open('https://app.getterms.io/view/x6YYi/privacy/en-us')
	},
	{
		firstLine: 'cookie',
		secondLine: 'policy',
		icon: CookieIcon,
		action: () => window.open('https://app.getterms.io/view/x6YYi/cookie/en-us')
	},
	{
		firstLine: 'use',
		secondLine: 'policy',
		icon: UsePolicyIcon,
		action: () => window.open('https://app.getterms.io/view/x6YYi/aup/en-us')
	},
	{
		firstLine: 'refund',
		secondLine: 'policy',
		icon: RefundIcon,
		action: () => window.open('https://app.getterms.io/view/x6YYi/return/en-us')
	}
]
</script>

<div class="terms grid">
	{#each terms as term}
		<button
			class="term"
			on:click={term.action}
			title={'terms_conditions'}>
			<div class="flex-column btn-content">
				<div class="svg-container">
					<svelte:component this={term.icon} />
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_(term.firstLine)}</span>
						<span>{$_(term.secondLine)}</span>
					</h1>
				</header>
			</div>
		</button>
	{/each}
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.terms {
	grid-template-columns: repeat(auto-fill, 160px);
	align-items: center;
	grid-gap: 15px;
	width: 100%;

	.term {
		@include mixins.card;
		aspect-ratio: 1/1;
		height: 160px;

		.btn-content {
			justify-content: center;
			padding: 20px;

			.svg-container {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin-bottom: auto;
			}

			.title {
				text-align: start;
				color: var(--clr-txt-dark-secondary);
				padding-top: 30px;

				span {
					font-weight: 500;

					&:first-child {
						font-size: 15px;
					}

					&:last-child {
						font-size: 20px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		grid-template-columns: repeat(2, 1fr);
	}
}
</style>
