<script>
import StarRating from '@ernane/svelte-star-rating'

export let score = 0

let config = {
	readOnly: false,
	countStars: 5,
	range: { min: 0, max: 5, step: 1 },
	score: score,
	starConfig: {
		size: 30,
		fillColor: '#F9ED4F',
		strokeColor: '#BB8511',
		unfilledColor: '#33383f',
		strokeUnfilledColor: '#00000'
	}
}

const changeSliderInput = () => {
	score = config.score
}
</script>

<main>
	<div class="content">
		<div class="star-rating">
			<StarRating
				bind:config
				on:change={changeSliderInput} />
		</div>
	</div>
</main>

<style lang="scss">
</style>
