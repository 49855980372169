<script lang="ts">
import TrainingReports from '../cmps/reports/TrainingReports.svelte'
import { onMount } from 'svelte'
import { currAccount } from '../store/stores'
import { trainingService } from '../services/core/training.service'
import { updateCategory } from '../cmps/reports/reportsCategoryStore'
import ReportsCategorySelector from '../cmps/reports/ReportsCategorySelector.svelte'
import DogReports from '../cmps/reports/DogReports.svelte'
import { dogService } from '../services/core/dog.service'
import { accountService } from '../services/core/account.service'
import UserReports from '../cmps/reports/UserReports.svelte'
import CmpLoader from '../cmps/common/loader/CmpLoader.svelte'
import DemoReports from '../cmps/insights/demo/DemoReports.svelte'
import DemoInsights from '../cmps/insights/demo/DemoInsights.svelte'

const categories = ['training', 'dog', 'trainedBy']
let category = 'training'

onMount(async () => {
	trainingsForDisplay = await loadTrainings()
	dogsForDisplay = await loadDogs()
	usersForDisplay = await loadUsers()
})

async function loadTrainings() {
	const trainings = await trainingService.query(
		$currAccount.id,
		$currAccount.stats.totalTrainingSessions
	)

	const trainingsForDisplay = []
	trainings.forEach((training) => {
		const trainingForDisplay = {
			Type: {
				name: training.type,
				icon: training.iconUrl
			},
			Team: {
				name: training.team.name,
				icon: training.team.icon
			},
			'Training By': training.trainedBy.name,
			Dog: {
				name: training.dog.name,
				image: training.dog.image
			},
			'Training Time': training.trainingTime,
			Date: training.dateTime.toDate(),
			Event: training.event,
			data: {
				data: training.data
			},
			Location: training.location.address,
			'How was your dog?': training.dogRating,
			'How do you feel?': training.trainedByRating
		}
		trainingsForDisplay.push(trainingForDisplay)
	})
	return trainingsForDisplay
}

async function loadDogs() {
	const dogs = await dogService.query($currAccount.id)

	const dogsForDisplay = []
	dogs.forEach((dog) => {
		const dogForDisplay = {
			dog: {
				name: dog.name,
				image: dog.image
			},
			'Dog Handler': dog.handler.name,
			'Last Trained': dog.stats.recentTraining ? dog.stats.recentTraining.toDate() : '',
			Type: dog.type,
			Status: dog.status,
			birthday: dog.birthday.toDate(),
			totalTrainingSessions: dog.stats.totalTrainingSessions,
			totalTrainingHours: dog.stats.totalTrainingTime,
			totalTrainingDays: dog.stats.totalTrainingDays,
			breedName: dog.breed.name,
			breedType: dog.breed.type,
			breedCountry: dog.breed.country,
			chipID: dog.chipId,
			papered: dog.registered
		}
		dogsForDisplay.push(dogForDisplay)
	})

	return dogsForDisplay
}

async function loadUsers() {
	const users = await accountService.queryUsers($currAccount.userIds, $currAccount.id)

	const usersForDisplay = []
	users.forEach((user) => {
		const userForDisplay = {
			userName: user.firstName + ' ' + user.lastName,
			Role: user.role,
			Type: user.type,
			'Last Trained': user.stats.recentTraining ? user.stats.recentTraining.toDate() : '',
			totalDogs: user.stats.dogsCount,
			totalTrainingSessions: user.stats.totalTrainingSessions
				? user.stats.totalTrainingSessions
				: 0,
			totalTrainingHours: user.stats.totalTrainingTime ? user.stats.totalTrainingTime : 0,
			totalTrainingDays: user.stats.totalTrainingDays ? user.stats.totalTrainingDays : 0
		}
		usersForDisplay.push(userForDisplay)
	})

	return usersForDisplay
}

$: trainingsForDisplay = []
$: dogsForDisplay = []
$: usersForDisplay = []
</script>

{#if trainingsForDisplay}
	{#if trainingsForDisplay.length > 14}
		<div class="reports flex-column">
			<div class="category-selector">
				<ReportsCategorySelector
					activeCategory={category}
					{categories}
					on:select={(ev) => {
						category = ev.detail
						updateCategory(category)
					}} />
			</div>
			{#if category == 'training'}
				<TrainingReports {trainingsForDisplay} />
			{:else if category == 'dog'}
				<DogReports {dogsForDisplay} />
			{:else if category == 'trainedBy'}
				<UserReports {usersForDisplay} />
			{/if}
		</div>
	{:else}
		<div class="no-reports">
			<DemoReports />
		</div>
	{/if}
{:else}
	<CmpLoader />
{/if}

<style lang="scss">
.reports {
	gap: 20px;
}

.no-reports {
	height: 600px;
	border-radius: 6px;
}

.no-reports {
	height: 600px;
	border-radius: 6px;
}
</style>
