<svg
	width="49"
	height="48"
	viewBox="0 0 49 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_548_25755)">
		<rect
			x="0.419922"
			width="48"
			height="48"
			rx="24"
			fill="#EEEDFD" />
		<path
			d="M29.0322 28.0205L27.8564 26.8379C27.4189 27.3779 26.9268 27.8701 26.3867 28.3145L27.5625 29.4902L29.0322 28.0205Z"
			fill="#6C5DD3" />
		<path
			d="M38.0088 35.8545L37.168 35.0137L34.5566 37.625L35.3975 38.4658C35.4863 38.5547 35.6367 38.5547 35.7256 38.4658L38.0088 36.1826C38.0498 36.1416 38.0771 36.0801 38.0771 36.0186C38.0771 35.957 38.0498 35.9023 38.0088 35.8545Z"
			fill="#6C5DD3" />
		<path
			d="M28.9297 32.0078L31.5395 29.398L36.6867 34.5451L34.0768 37.155L28.9297 32.0078Z"
			fill="#6C5DD3" />
		<path
			d="M30.2422 28.0889C30.1943 28.041 30.1396 28.0205 30.0781 28.0205C30.0166 28.0205 29.9619 28.0479 29.9141 28.0889L29.7295 28.2734L27.6309 30.3721C27.5898 30.4131 27.5625 30.4746 27.5625 30.5361C27.5625 30.5977 27.5898 30.6523 27.6309 30.7002L28.4648 31.5342L31.0762 28.9229L30.2422 28.0889Z"
			fill="#6C5DD3" />
		<path
			d="M15.832 18.6963L17.1172 19.209L20.125 20.4053L23.1465 19.2021L24.4111 18.6963L20.125 16.9873L15.832 18.6963Z"
			fill="#6C5DD3" />
		<path
			d="M14.4102 22.5176C14.4102 22.8047 14.6426 23.0371 14.9297 23.0371C15.2168 23.0371 15.4492 22.8047 15.4492 22.5176C15.4561 22.2373 15.2168 21.998 14.9297 21.998C14.6426 21.998 14.4102 22.2305 14.4102 22.5176Z"
			fill="#6C5DD3" />
		<path
			d="M20.125 21.0957C20.084 21.0957 20.043 21.0889 20.002 21.0752L17.3223 20.0088V22.4697C17.3223 22.9141 18.3887 23.5293 20.1318 23.5293C21.8682 23.5293 22.9414 22.9141 22.9414 22.4697V20.002L20.248 21.0752C20.207 21.0889 20.166 21.0957 20.125 21.0957Z"
			fill="#6C5DD3" />
		<path
			d="M19.7832 12.4551C15.4902 12.4551 11.9902 15.9482 11.9902 20.248C11.9902 24.541 15.4834 28.041 19.7832 28.041C24.0762 28.041 27.5762 24.5479 27.5762 20.248C27.5762 15.9482 24.083 12.4551 19.7832 12.4551ZM25.4434 19.0244L23.6113 19.749V22.4766C23.5977 23.4541 22.1074 24.1992 20.125 24.1992C18.1426 24.1992 16.6523 23.4541 16.6523 22.4766V19.749L15.2646 19.1953V21.3828C15.7568 21.5264 16.1191 21.9844 16.1191 22.5244C16.1191 23.1807 15.5859 23.7139 14.9297 23.7139C14.2734 23.7139 13.7402 23.1807 13.7402 22.5244C13.7402 21.9844 14.1025 21.5264 14.5947 21.3828V18.7031V18.6895C14.5947 18.6826 14.5947 18.6758 14.5947 18.6758C14.6016 18.5049 14.7383 18.3682 14.9092 18.3545L20.002 16.3311C20.084 16.2969 20.1729 16.2969 20.248 16.3311L25.4434 18.3955C25.5732 18.4434 25.6553 18.5664 25.6553 18.7031C25.6553 18.8398 25.5732 18.9629 25.4434 19.0244Z"
			fill="#6C5DD3" />
		<path
			d="M27.5361 26.1953C27.5566 26.168 27.5703 26.1406 27.5977 26.1133L27.6045 26.1064C28.8281 24.4727 29.5596 22.4424 29.5596 20.248C29.5596 14.8545 25.1777 10.4727 19.7773 10.4727C14.3906 10.4727 10.002 14.8545 10.002 20.248C10.002 25.6348 14.3838 30.0234 19.7773 30.0234C21.9922 30.0234 24.0361 29.2852 25.6768 28.041C25.6836 28.0342 25.6973 28.0273 25.7041 28.0205C26.3945 27.4941 27.0098 26.8789 27.5361 26.1953ZM19.7842 28.7041C15.1221 28.7041 11.3281 24.9102 11.3281 20.2412C11.3281 15.5723 15.1221 11.7852 19.7842 11.7852C24.4463 11.7852 28.2402 15.5791 28.2402 20.2412C28.2402 24.9033 24.4531 28.7041 19.7842 28.7041Z"
			fill="#6C5DD3" />
	</g>
	<defs>
		<clipPath id="clip0_548_25755">
			<rect
				width="48.1731"
				height="48"
				fill="white"
				transform="translate(0.419922)" />
		</clipPath>
	</defs>
</svg>
