<script lang="ts">
import CircularProgress from '@smui/circular-progress'
import { onDestroy, onMount } from 'svelte'
import { fade } from 'svelte/transition'
import SvgDogBase from '../SvgDogBase.svelte'

onMount(() => (document.body.style.overflow = 'hidden'))
onDestroy(() => document.body.style.removeProperty('overflow'))
</script>

<div class="page-loader">
	<div
		class="flex-column justify-center align-center loading-indicator"
		out:fade={{ duration: 1000 }}>
		<div class="svg-dog-base">
			<SvgDogBase />
		</div>
		<CircularProgress
			style="height: 42px; width: 42px;"
			indeterminate />
	</div>
</div>

<style lang="scss">
.page-loader {
	display: grid;
	place-items: center;
	height: 100vh;

	.svg-dog-base {
		margin-left: 30px;
	}

	.loading-indicator {
		gap: 44px;
	}
}
</style>
