<script lang="ts">
import { _ } from 'svelte-i18n'
import { textToTags } from '../../../store/trainingWidgetsStore'

let tagInput = ''

export let values: {
	tags: string[]
	value: string[]
	filled: boolean
}
export let data = []
export let id: string = null

if (!$textToTags[id]) {
	$textToTags[id] = []
}

function addTagsFromInput(event: Event) {
	event.preventDefault() // Prevent default form submission behavior
	if (tagInput) {
		const inputTags = tagInput
			.split(',')
			.map((tag) => tag.trim())
			.filter((tag) => tag !== '')
		if (inputTags.length > 0) {
			values.tags = [...values.tags, ...inputTags]
			tagInput = ''
		}
	}
}

function removeTag(tag: string) {
	const index = values.tags.indexOf(tag)
	if (index !== -1) {
		values.tags.splice(index, 1)
		values.tags = [...values.tags]
	}
}

function removeAllTags() {
	event.preventDefault()
	values.tags = []
}

function handleKeyDown(event: any) {
	if (event.key === 'Enter') {
		addTagsFromInput(event)
	}
}

$: {
	if (values.tags.length > 0) {
		values.filled = true
	} else values.filled = false
}

if (data.length > 0 && $textToTags[id] == '') {
	values.tags = data[0].value
}

$: {
	values.value = values.tags
	$textToTags[id] = values.value
}
</script>

<div class="text-to-tags-container flex-column">
	<div class="input-container flex-row align-center">
		<input
			class="tag-input"
			type="text"
			bind:value={tagInput}
			placeholder={$_('enter_tags')}
			on:keydown={handleKeyDown} />
		<div class="btns-container flex-row">
			<button
				on:click={addTagsFromInput}
				class="add-tags btn theme-btn">{$_('add_tags')}</button>
			<button
				class="remove-all-button btn theme-btn outline"
				on:click={() => removeAllTags()}>
				{$_('clear')}
			</button>
		</div>
	</div>
	<div class="tags-container flex">
		{#each values.tags as tag}
			<span class="tag flex align-center justify-center">
				<span class="tag-text">{tag}</span>
				<button
					class="tag-remove"
					on:click={() => removeTag(tag)}
					type="button">
					&times;
				</button>
			</span>
		{/each}
	</div>
</div>

<style lang="scss">
.text-to-tags-container {
	gap: 12px;
	width: 100%;

	.input-container {
		height: 42px;
		gap: 12px;

		.tag-input {
			background-color: white;
			outline: none;
			font-size: 14px;
			border: 1px solid #e6e8f0;
			border-radius: 6px;
			padding: 9px 13px;
			width: 300px;
		}

		.btns-container {
			height: 42px;
			gap: 12px;

			.remove-all-button,
			.add-tags {
				white-space: nowrap;
				padding: 6px 10px;
				font-size: 12px;
			}
		}

		@media screen and (max-width: 768px) {
			flex-direction: column !important;
			align-items: flex-start;
			margin-top: 20px;
		}
	}
}

.tags-container {
	flex-wrap: wrap;
	width: 100%;
	gap: 10px;

	@media screen and (max-width: 768px) {
		margin-top: 50px;
	}

	.tag {
		border-radius: 500px;
		background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
		padding: 4px 9px;
		font-size: 12px;
		font-family: Montserrat-Medium;
		width: fit-content;
		height: fit-content;
		font-weight: 900;
		line-height: 16px;
		color: #fff;
		gap: 6px;
		display: flex;

		.tag-remove {
			font-size: 16px;
			font-family: Nunito;
		}
	}
}
</style>
