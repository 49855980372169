<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#DCEBF2" />
	<g clip-path="url(#clip0_492_3756)">
		<path
			d="M12.7021 30.3284H31.0779C31.2427 30.3284 31.402 30.2707 31.5283 30.1656C31.7048 30.0178 35.731 26.5543 35.9765 19.0779H16.2412C15.9966 25.8645 12.2888 29.0544 12.2502 29.0862C12.0243 29.2771 11.9412 29.5889 12.0428 29.8663C12.1438 30.143 12.4068 30.3284 12.7021 30.3284Z"
			fill="url(#paint0_linear_492_3756)" />
		<path
			d="M35.2977 14.8589H31.7819V14.1558C31.7819 13.762 31.4726 13.4526 31.0788 13.4526C30.685 13.4526 30.3756 13.762 30.3756 14.1558V14.8589H26.813V14.1558C26.813 13.762 26.5036 13.4526 26.1098 13.4526C25.7161 13.4526 25.4067 13.762 25.4067 14.1558V14.8589H21.8909V14.1558C21.8909 13.762 21.5815 13.4526 21.1877 13.4526C20.794 13.4526 20.4846 13.762 20.4846 14.1558V14.8589H16.9688C16.575 14.8589 16.2656 15.1683 16.2656 15.5621V17.6716H36.0009V15.5621C36.0009 15.1683 35.6915 14.8589 35.2977 14.8589Z"
			fill="url(#paint1_linear_492_3756)" />
		<path
			d="M32.4322 31.2437C32.0504 31.5616 31.5711 31.7346 31.0788 31.7346H16.2656V33.8441C16.2656 34.2328 16.5801 34.5473 16.9688 34.5473H35.2977C35.6864 34.5473 36.0009 34.2328 36.0009 33.8441V25.9619C34.6452 29.2879 32.7534 30.9748 32.4322 31.2437Z"
			fill="url(#paint2_linear_492_3756)" />
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_492_3756"
			x1="23.9882"
			y1="19.0779"
			x2="23.9882"
			y2="30.3284"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<linearGradient
			id="paint1_linear_492_3756"
			x1="26.1333"
			y1="13.4526"
			x2="26.1333"
			y2="17.6716"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<linearGradient
			id="paint2_linear_492_3756"
			x1="26.1333"
			y1="25.9619"
			x2="26.1333"
			y2="34.5473"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<clipPath id="clip0_492_3756">
			<rect
				width="24"
				height="24"
				fill="white"
				transform="translate(12 12)" />
		</clipPath>
	</defs>
</svg>
