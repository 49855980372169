<script lang="ts">
import { onMount } from 'svelte'
import { insightsService } from '../../services/insights/data.service'
import * as Highcharts from 'highcharts'
import { _ } from 'svelte-i18n'
import exporting from 'highcharts/modules/exporting'
import variablepie from 'highcharts/modules/variable-pie'
import Tooltip from '../common/Tooltip.svelte'
import { utilService } from '../../utils/util.service'

export let groupBy: 'dogRating' | 'userRating'
export let category = undefined
export let infoId = undefined
export let data: any

let chart: Highcharts.Chart
let sessionKeys

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()

	if (data) {
		const ratingsCount = insightsService.getRating(data, groupBy, infoId)
		const total = ratingsCount.reduce((sum, count) => sum + count, 0)

		createChart(ratingsCount, total)
	}
})

function createChart(ratingsCount, total) {
	const DogRatingColors = ['#635B59', '#A37468', '#678E81', '#59E3B5', '#E37459']
	const UserRatingColors = ['#FF6B00', '#F59855', '#FFCC41', '#F1EA44', '#06BB4E']

	if (sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing') {
		exporting(Highcharts)
	}
	variablepie(Highcharts)

	const chartConfig: Highcharts.Options = {
		chart: {
			type: 'variablepie',
			margin: [-10, 0, 0, 160],
			spacing: [0, 0, 0, 0],
			height: 270
		},
		title: {
			text: ''
		},
		tooltip: {
			headerFormat: '',
			useHTML: true,
			outside: true,
			style: {},
			pointFormatter: function () {
				if (groupBy == 'dogRating') {
					const starRating = parseInt(this.name)
					const stars = Array.from(
						{ length: starRating },
						() =>
							'<img src="../../../assets/img/star-icon.png" alt="" style="margin-top: -1px; height: 15px; width: 15px;"/>'
					).join('')
					return `<div class="flex align-center" style="min-width: 114px">${stars}: ${this.percentage.toFixed(
						1
					)}%</div>`
				} else {
					const icons = [
						'../../../assets/smiles/Smile1.svg',
						'../../../assets/smiles/Smile2.svg',
						'../../../assets/smiles/Smile3.svg',
						'../../../assets/smiles/Smile4.svg',
						'../../../assets/smiles/Smile5.svg'
					]
					return `<div class="flex" style="min-width: 60px"><img src=${
						icons[parseInt(this.name) - 1]
					} style="margin-top: 0px; height: 20px; width: 20px" alt="s" /> : ${this.percentage.toFixed(
						1
					)}%</div>`
				}
			}
		},
		plotOptions: {
			variablepie: {
				minPointSize: 10,
				borderRadius: 5,
				allowPointSelect: true,
				cursor: 'pointer',
				innerSize: '20%',
				dataLabels: {
					enabled: false,
					format: '{point.percentage:.1f} %',
					style: {
						fontFamily: 'Montserrat-Bold',
						color: 'white',
						textOutline: 'none',
						fontSize: '12px',
						borderWidth: 1,
						borderColor: 'black'
					},
					distance: -70
				},
				colors: groupBy == 'dogRating' ? DogRatingColors : UserRatingColors,
				showInLegend: true
			}
		},
		series: [
			{
				type: 'variablepie',
				name: 'Percentage',
				zMin: 0,
				data: ratingsCount.map((count, index) => ({
					name: (index + 1).toString(),
					y: count,
					z: 200 + index * 25,
					dataLabels: {
						enabled: count / total >= 0.15
					}
				}))
			}
		],
		legend: {
			layout: 'vertical',
			align: 'left',
			verticalAlign: 'top',
			title: {
				text: '',
				style: {
					color: 'rgba(8, 23, 53, 1)',
					fontSize: '17px'
				}
			},
			itemStyle: {
				color: 'rgba(132, 129, 138, 1)',
				fontFamily: 'Nunito',
				fontSize: '14px'
			},
			itemMarginTop: 10,
			symbolWidth: groupBy == 'userRating' ? 0 : 40,
			symbolHeight: groupBy == 'userRating' ? 0 : 16,
			symbolPadding: groupBy == 'userRating' ? 16 : 0,
			useHTML: true,
			labelFormatter: function () {
				if (groupBy == 'dogRating') {
					const starRating = parseInt(this.name)
					const stars = Array.from(
						{ length: starRating },
						() =>
							'<img src="../../../assets/img/star-icon.png" alt="" style="margin-top: -1px; height: 15px; width: 15px !important;"/>'
					).join('')
					return `<div class="flex">${stars} <span style="opacity:0"> rating</span></div>`
				} else {
					const icons = [
						'../../../assets/smiles/Smile1.svg',
						'../../../assets/smiles/Smile2.svg',
						'../../../assets/smiles/Smile3.svg',
						'../../../assets/smiles/Smile4.svg',
						'../../../assets/smiles/Smile5.svg'
					]
					return `<div class="flex"><img src=${
						icons[parseInt(this.name) - 1]
					} style="margin-top: 0px; height: 20px; width: 20px !important;" alt="s" /> <span style="opacity:0"> ratings</span></div>`
				}
			}
		}
	}

	chart = Highcharts.chart(`${groupBy}-rating`, chartConfig)
}
</script>

<div class="chart-container flex-column">
	<div class="title-container flex align-center">
		<h1 class="title">{groupBy == 'dogRating' ? $_('how_was_your_dog') : $_('how_do_you_feel')}</h1>
		<Tooltip
			message={`Based on all trainings for this ${category}`}
			color="rgba(116, 116, 116, 0.3)" />
	</div>
	<div id="{groupBy}-rating" />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.chart-container {
	@include mixins.card;
	padding: 0 8px 8px 8px;
	overflow: visible;
}

:global(.highcharts-legend-item) {
	min-width: 180px;
}

.title-container {
	padding: 12px 0 0 12px;
	font-size: 13px;
	gap: 6px;
}

.toolt {
	opacity: 0;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 140%;
	left: 50%;
	font-size: 14px;
	margin-left: -60px;
	width: 180px;
}

.toolt::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 35%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #264a59 transparent transparent transparent;
}

.toolt:hover {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
	background-color: red;
}

.title {
	font-family: Montserrat-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
</style>
