<script lang="ts">
import { utilService } from '../../../utils/util.service'
import { customStepper } from '../../../store/trainingWidgetsStore'
import { _ } from 'svelte-i18n'

export let values: {
	stepAmount: number
	label: string
	filled: boolean
	value: {
		selectedValue: number
		label: string
	}
}
export let data = []
export let id: string

if (data.length > 0) {
	values.value = {
		selectedValue: data[0].value.selectedValue,
		label: data[0].value.label
	}
}

if (!values.value) {
	values.value = {
		selectedValue: 0,
		label: values.label
	}
}

if (!$customStepper[id]) {
	$customStepper[id] = 0
}

if ($customStepper[id] != 0) {
	values.value.selectedValue = $customStepper[id]
}

function increment(event: Event) {
	event.preventDefault()
	values.value.selectedValue = Math.min(values.value.selectedValue + values.stepAmount, 100)
	$customStepper[id] = values.value.selectedValue
}

function decrement(event: Event) {
	event.preventDefault()
	values.value.selectedValue = Math.max(values.value.selectedValue - values.stepAmount, 0)
	$customStepper[id] = values.value.selectedValue
}

$: {
	if (values.value.selectedValue != 0) {
		values.filled = true
	} else {
		values.filled = false
	}
}
</script>

<div class="stepper flex">
	<button
		class="stepper-button"
		on:click|preventDefault={decrement}
		disabled={values.value.selectedValue === 0}>-</button>
	<span class="stepper-value flex align-center"
		>{values.value.selectedValue} {utilService.getShortLabel(values.label)}</span>
	<button
		class="stepper-button"
		on:click|preventDefault={increment}>+</button>
</div>

<style>
.stepper {
	display: flex;
	align-items: center;
	height: 40px;
	overflow: hidden;
	gap: 3.5px;
}

.stepper-button {
	padding: 9px 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.08);
}

.stepper-button:hover {
	background-color: #e5e5e5;
}

.stepper-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.stepper-value {
	min-width: 85px;
	height: 25px;
	text-align: center;
	justify-content: center;
	align-items: center;
	padding: 2px 13px;
	border-radius: 2px;
	border: 1px solid #e6e8f0;
	background: var(--primary-white, #fff);
}
</style>
