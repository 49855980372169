<script lang="ts">
import type { SvelteComponent } from 'svelte'
import { _ } from 'svelte-i18n'

export let titleName = 'quick_insights'
export let insights: { title: string; value: string | number; icon: typeof SvelteComponent }[]
</script>

<div class="quick-insights">
	<header class="header">
		<h1>{$_(titleName)}</h1>
	</header>
	<div class="align-center space-around insights">
		{#each insights as insight}
			<div class="flex insight">
				<div class="svg-container">
					<svelte:component this={insight.icon} />
				</div>
				<div class="flex-column info">
					<h1 class="title">
						{$_(insight.title)}
					</h1>
					<h2 class="value">
						{insight.value}
					</h2>
				</div>
			</div>
		{/each}
	</div>
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.quick-insights {
	@include mixins.card;

	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.insights {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(min-content, 220px));

		.insight {
			gap: 20px;
			padding-inline: 20px;
			margin-bottom: 30px;

			.svg-container {
				width: 48px;
				height: 48px;
				min-width: 48px;
			}

			.info {
				flex-direction: column-reverse;
				.value {
					font-size: 18px;
					white-space: nowrap;
				}

				.title {
					width: fit-content;
					white-space: nowrap;
					font-size: 12px;
					font-family: Nunito;
					font-weight: 400;
					text-transform: capitalize;
					color: var(--clr-txt-dark-secondary);
				}
			}
		}
	}

	@media only screen and (max-width: 768px) {
		.insights {
			justify-content: center;
			grid-template-columns: repeat(auto-fit, 160px);
			align-items: flex-start;
			justify-content: flex-start;

			.insight {
				gap: 12px;
				.svg-container {
					width: 40px;
					height: 40px;
					min-width: 40px;
				}

				.info {
					justify-content: center;
					.value {
						font-size: 16px;
						white-space: nowrap;
						width: 100px;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.title {
						width: fit-content;
						white-space: nowrap;
						font-size: 12px;
						font-family: Nunito;
						font-weight: 400;
						text-transform: capitalize;
						color: var(--clr-txt-dark-secondary);
					}
				}
			}
		}
	}
}
</style>
