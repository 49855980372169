import { currTeams } from '../../store/stores'
import { teamDB } from '../database/team.db'
import type { TeamModel } from '../../models/team.model'
import type { TeamUserModel } from '../../models/user.model'
import { userMsg } from '../../utils/userMsg.service'

export const teamService = {
	query,
	queryUsers,
	getById,
	create,
	update,
	getEmptyTeam,
	queryTeamDogs
}

// Function that gets teams from firebase and returns them as an array
async function query(uid: string, accountId: string): Promise<TeamModel[]> {
	const teams = []

	try {
		const teamDocs = await teamDB.getMany(uid, accountId)
		teamDocs.forEach((doc) => teams.push(doc.data()))
		currTeams.set(teams)
		return teams
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get teams', error)
	}
}

// Function that gets teamUsers from firebase and returns them as an array
async function queryUsers(accountId: string, teamId: string, itemsPerPage = 10000): Promise<TeamUserModel[]> {
	const teamUsers = []

	try {
		const teamUserDocs = await teamDB.getManyUsers(accountId, teamId, itemsPerPage)
		teamUserDocs.forEach((doc) => teamUsers.push(doc.data()))
		return teamUsers
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get team users', error)
	}
}

// Function that gets teamDogs from firebase and returns them as an array
async function queryTeamDogs(accountId: string, teamId: string, itemsPerPage: number) {
	const teamDogs = []

	try {
		const teamDogsDocs = await teamDB.loadTeamDogs(accountId, teamId, itemsPerPage)
		teamDogsDocs.forEach(doc => teamDogs.push(doc.data()))
		return teamDogs
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get team dogs', error)
	}
}


// Function that returns a team by its ID
async function getById(teamId: string): Promise<TeamModel> {
	try {
		const teamDoc = await teamDB.get(teamId)
		return teamDoc as TeamModel
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get team', error)
	}
}


// Function that creates a team in firebase
async function create(initialData: Partial<TeamModel>): Promise<void> {
	try {
		const team = getEmptyTeam(initialData)
		await teamDB.add(team)
		userMsg.successMsg('Team created successfully')
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot create team', error)
	}
}

// Function that updates a team in firebase
async function update(team: TeamModel): Promise<void> {
	try {
		const res = await teamDB.update(team.id, team)
		userMsg.successMsg('Team updated successfully')
		return res
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot update team', error)
	}
}


// Function that generates an empty team model
function getEmptyTeam(initialData?: Partial<TeamModel>): TeamModel {
	const emptyTeam: TeamModel = {
		id: '',
		name: '',
		icon: 'general-team',
		accountId: '',
		createdBy: '',
		stats: {
			totalTrainingSessions: 0,
			totalTrainingTime: 0,
			totalTrainingDays: 0
		},
		location: {
			address: null,
			geoPoints: null
		},
		status: 'active',
		type: [],
		userIds: [],
		dogIds: [],
		fbfEventId: null,
		createdAt: null,
		updatedAt: null
	}

	const teamWithInitialData = { ...emptyTeam, ...initialData }

	return teamWithInitialData
}
