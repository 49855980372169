import type { User } from 'firebase/auth'
import { writable } from 'svelte/store'
import type { AccountModel } from '../models/account.model'
import type { DogModel } from '../models/dog.model'
import type { TeamModel } from '../models/team.model'
import type { UserModel } from '../models/user.model'
import type { TrainingModel } from '../models/training.model'
import type { AccountUserModel } from '../models/user.model'
import { utilService } from '../utils/util.service'

export const user = writable<User>(null)
export const loggedInUser = writable<UserModel>(null)
export const currAccount = writable<AccountModel>(null)
export const currTeams = writable<TeamModel[]>(null)
export const currDogs = writable<DogModel[]>(null)
export const currTrainings = writable<TrainingModel[]>(null)
export const navBarOpen = writable<boolean>(utilService.isClientInMobile() ? false : true)
export const currUsers = writable<UserModel[]>(null)
export const selectedPlan = writable<string>(null)
export const currAccountUsers = writable<AccountUserModel[]>(null)
export const eventData = writable<any>(null)
export const selectedAITraining = writable<any>(null)
export const underMaintenance =  writable<MaintenanceStatus>({ on: false, message: '' });