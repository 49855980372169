<script lang="ts">
import type { DogStatus } from '../../../models/dog.model'
import { _ } from 'svelte-i18n'

export let status: DogStatus
</script>

<div class="dog-status">
	<span class={status.toLowerCase()}>
		{$_(status)}
	</span>
</div>

<style lang="scss">
.dog-status {
	text-transform: capitalize;
	font-size: 14px;

	span {
		padding: 5px 10px;
		border-radius: 6px;
		color: white;

		&.active {
			background-color: #0ec256;
		}
		&.training {
			background-color: #fa9b11;
		}
		&.injured {
			background-color: #e57559;
		}
		&.retired {
			background-color: #a7a7a7;
		}
	}
}
</style>
