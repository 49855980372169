<script lang="ts">
export let stepNumber: string
import { _ } from 'svelte-i18n'

export let eventName = 'training'

$: steps = [
	{
		name: $_('general_information'),
		number: '1'
	},
	{
		name: eventName + $_(' data'),
		number: '2'
	},
	{
		name: $_('wrap_up'),
		number: '3'
	}
]
</script>

<div class="step-changer flex-row">
	{#each steps as step}
		<div
			class={stepNumber == step.number
				? 'step flex align-center active'
				: 'step flex align-center'}>
			<div
				class="number flex-column justify-center align-center {stepNumber == step.number
					? 'active'
					: ''}">
				{'0' + step.number}
			</div>
			<p class={stepNumber == step.number ? 'name active-name' : 'name'}>{step.name}</p>
		</div>
	{/each}
</div>

<style lang="scss">
.step-changer {
	height: auto;
	border: 1px #8f95b2 solid;
	gap: 40px;
	flex-wrap: wrap;

	.step {
		max-width: 100%;
		height: auto;
		padding: 10px;
		gap: 10px;
		background-image: linear-gradient(#264a59, #2d7392);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		.name {
			font-weight: 1000;
			font-size: 14px;
			text-transform: uppercase;
		}

		.number {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: 2px #8f95b2 solid;
		}

		.active {
			border: 2px #387087 solid;
		}
	}

	.active {
		border-bottom: #387087 4px solid;
		height: auto;
	}
}

@media (max-width: 768px) {
	.step-changer {
		gap: 12px;
		.step {
			align-items: center;
			padding: 8px;
		}

		.name {
			font-size: 12px;
			margin-top: 5px;
		}

		.active {
			border-bottom: none;
			border-left: #387087 6px solid;
			width: 100%;
			height: auto;
		}

		:not(.active, .active-name) {
			display: none;
		}
	}
}
</style>
