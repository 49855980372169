import {
	collection,
	getDoc,
	getDocs,
	query,
	where,
	doc,
	orderBy,
	limit,
	DocumentReference,
	type DocumentData
} from 'firebase/firestore'
import { Firestore } from '../firebase/firebase'
import type { Query, QuerySnapshot } from 'firebase/firestore'

export const trainingDB = {
	get,
	getMany,
	getDocsQuery,
	getTrainingRef,
	loadPage,
	getManyTrainingTypes,
	loadTeamTrainings,
	newTrainingRef,
	getAllTrainings
}

// Function that returns query of trainings documents from firebase
function getDocsQuery(accountId: string): Query<DocumentData> {
	return query(collection(Firestore, 'trainings'), where('accountId', '==', accountId))
}

// Function that returns query of a specific amount of trainings documents from firebase (used for pagination)
async function loadPage(accountId: string, itemsPerPage = 100): Promise<Query<DocumentData>> {
	return query(
		collection(Firestore, 'trainings'),
		where('accountId', '==', accountId),
		orderBy('dateTime', 'desc'),
		limit(itemsPerPage || 1)
	)
}

async function getAllTrainings(accountId: string): Promise<Query<DocumentData>> {
	return query(
		collection(Firestore, 'trainings'),
		where('accountId', '==', accountId),
		orderBy('dateTime', 'desc')
	)
}

function loadTeamTrainingsQuery(accountId: string, teamId: string, itemsPerPage: number): Query<DocumentData> {
	return query(
		collection(Firestore, 'trainings'),
		where('team.id', '==', teamId),
		where('accountId', '==', accountId),
		orderBy('dateTime', 'desc'),
		limit(itemsPerPage || 1)
	)
}

async function loadTeamTrainings(accountId: string, teamId: string, itemsPerPage: number): Promise<QuerySnapshot<DocumentData>> {
	const queryParams = loadTeamTrainingsQuery(accountId, teamId, itemsPerPage)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

// Function that gets trainings document snapshots
async function getMany(accountId: string, itemsPerPage: number): Promise<QuerySnapshot<DocumentData>> {
	const queryParams = await loadPage(accountId, itemsPerPage)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

// Function that returns query of a trainingTypes documents
async function getTrainingTypesDoc(type: string, accountId: string): Promise<Query<DocumentData>> {
	if (type == "private") {
		return query(
			collection(Firestore, 'trainingTypes'),
			where('access', '==', type),
			where('deployedType', 'in', ['web-app', 'both']),
			where('accountId', 'array-contains', accountId)
		)
	} else if (type == "public") {
		return query(
			collection(Firestore, 'trainingTypes'),
			where('access', '==', type),
			where('deployedType', 'in', ['web-app', 'both']),
			where('accountId', '==', accountId)
		)
	} else {
		return
	}
}

async function get(trainingId: string): Promise<DocumentData> {
	const trainingDocSnap = await getDoc(_getDocRef(trainingId))
	if (trainingDocSnap.exists()) return trainingDocSnap.data()
	else throw new Error('Dog not found')
}

// Function that gets trainingTypes document snapshots
async function getManyTrainingTypes(type: string, accountId: string): Promise<DocumentData> {
	const queryParams = await getTrainingTypesDoc(type, accountId)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

function getTrainingRef(documentId: string): DocumentReference<DocumentData> {
	return _getDocRef(documentId)
}

function _getDocRef(documentId: string): DocumentReference<DocumentData> {
	return doc(Firestore, 'trainings', documentId)
}

function newTrainingRef(): DocumentReference<DocumentData> {
	return _getNewDocRef()
}

function _getNewDocRef(): DocumentReference<DocumentData> {
	return doc(collection(Firestore, 'trainings'))
}
