import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage'
import { utilService } from '../../utils/util.service'

export const storageService = {
	uploadImgStr,
	downloadTrainingUpload,
	deleteTrainingUpload
}

async function uploadImgStr(path: string, imgStr: string): Promise<string> {
	const storage = getStorage()
	const imageRef = ref(storage, `images/${path}`)
	try {
		const snapshot = await uploadString(imageRef, imgStr, 'data_url')
		const url = await getDownloadURL(snapshot.ref)
		return url
	} catch (error: unknown) {
		console.log('error img upload:')
	}
}

async function downloadTrainingUpload(fileUrl: string, fileName: string) {
	const xhr = new XMLHttpRequest();
	xhr.responseType = 'blob';
	xhr.onload = function () {
		const a = document.createElement('a');
		a.href = window.URL.createObjectURL(xhr.response);
		a.download = fileName; // Name the file anything you'd like.
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
	};
	xhr.open('GET', fileUrl);
	xhr.send();
}

function deleteTrainingUpload(trainingId: string, fileId: string) {
	return utilService.restRequest(`https://dev-api.dogbase.co/training/${trainingId}/filedelete`, 'DELETE', { fileId })
}
