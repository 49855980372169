<script lang="ts">
import Icon from 'svelte-icons-pack'
import FaSolidFileInvoiceDollar from 'svelte-icons-pack/fa/FaSolidFileInvoiceDollar'
import { _ } from 'svelte-i18n'
import NoResultAnimation from '../common/animation/NoResultAnimation.svelte'
import { userMsg } from '../../utils/userMsg.service'

export let transactions: any[]
export let isListOpen: boolean

async function getInvoice(transactionId: string) {
	try {
		const url = `https://api.paddle.com/transactions/${transactionId}/invoice`
		const myHeaders = new Headers()
		myHeaders.append('Accept', '/')
		myHeaders.append('Access-Control-Allow-Origin', '*')
		myHeaders.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS')
		myHeaders.append(
			'Access-Control-Allow-Headers',
			'Origin, X-Requested-With, Content-Type, Accept'
		)
		myHeaders.append('Authorization', `Bearer ${import.meta.env?.VITE_APP_PADDLE_API_KEY}`)
		const response = await fetch(url, {
			method: 'GET',
			headers: myHeaders
		})
		const values = await response.json()
		window.open(values.data.url, '_blank')
	} catch (err) {
		userMsg.errorMsg($_('no_invoice'), err)
	}
}
</script>

{#if isListOpen}
	{#if transactions && transactions.length === 0}
		<div class="flex-column align-center no-dogs-animation">
			<NoResultAnimation />
		</div>
	{:else if transactions && transactions.length > 0}
		<section class="flex-column transaction-list">
			<header class="header-container">
				<ul class="header">
					<li class="transaction-name">
						<span>{$_('transaction_name')}</span>
					</li>
					<li class="cost">{$_('cost')}</li>
					<li class="date">{$_('date')}</li>
					<li class="action">{$_('get_invoice')}</li>
				</ul>
			</header>
			<div class="flex-column content">
				{#each transactions as transaction}
					{#if transaction.status == 'completed'}
						<div class="transaction-preview-container">
							<ul class="transaction-preview">
								<li class="flex align-center transaction-name">
									<span>
										{transaction.items[0].price.name}
									</span>
								</li>
								<li class="cost">
									{transaction.details.totals.subtotal / 100 + ' ' + transaction.currency_code}
								</li>
								<li class="date">{new Date(transaction.billed_at).toLocaleDateString()}</li>

								<li class="action">
									{#if transaction.status == 'completed'}
										<button
											class="btn btn-svg btn-edit"
											title={$_('edit')}
											on:click|stopPropagation={() => {
												getInvoice(transaction.id)
											}}>
											<Icon src={FaSolidFileInvoiceDollar} />
										</button>
									{/if}
								</li>
							</ul>
						</div>
					{/if}
				{/each}
			</div>
		</section>
	{/if}
{/if}

<style lang="scss">
@use '../../styles/setup/mixins';

.transaction-list {
	@include mixins.card;
	overflow-x: auto;
	height: fit-content;
	margin-top: 1.5rem;

	.header,
	.transaction-preview {
		display: grid;
		grid-template-columns:
			minmax(200px, 2fr) repeat(2, minmax(60px, 1fr))
			minmax(80px, 1fr);
		min-width: fit-content;

		> li {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		.transaction-name {
			gap: 10px;
		}

		.cost,
		.date {
			justify-content: center;
			text-align: center;
			min-width: 60px;
		}

		.cost,
		.action {
			justify-content: center;
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);
	}

	.content {
		font-size: 14px;
	}

	.transaction-preview {
		border-top: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 16px;
		transition: background-color 0.15s ease-out;

		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}

		.cost,
		.date {
			padding-inline-start: 6px;
		}

		.action {
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}

				:global(svg) {
					font-size: 18px;
				}
			}
		}
	}
	@media only screen and (max-width: 768px) {
		.cost,
		.date {
			display: none !important;
		}
		.header,
		.transaction-preview {
			grid-template-columns: minmax(120px, 1.5fr) minmax(80px, 1fr);
		}
	}
}
</style>
