<script lang="ts">
import 'ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-quartz.css'
import { createGrid } from '@ag-grid-community/core'
import { userCellRenderer } from '../reports/cellRenderers'
import { onMount } from 'svelte'
import type {
	GridOptions,
	ISetFilterParams,
	IDateFilterParams,
	ValueFormatterParams
} from '@ag-grid-community/core'
import { utilService } from '../../utils/util.service'

export let usersForDisplay

const nameValueFormatter = (params) => {
	return params.value.name
}

var dateFilterParams: IDateFilterParams = {
	comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
		var dateAsString = cellValue
		if (dateAsString == null) return -1
		var dateParts = dateAsString.split('/')
		var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))

		if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
			return 0
		}

		if (cellDate < filterLocalDateAtMidnight) {
			return -1
		}

		if (cellDate > filterLocalDateAtMidnight) {
			return 1
		}
		return 0
	}
}

const columnDefs = [
	{
		headerName: 'User Information',
		children: [
			{
				field: 'userName',
				cellRenderer: userCellRenderer,
				comparator: (valueA, valueB) => {
					return valueA.localeCompare(valueB)
				},
				rowDrag: true,
				minWidth: 260,
				filter: 'agMultiColumnFilter',
				rowSelection: 'multiple',
				checkboxSelection: true,
				floatingFilter: true,
				valueGetter: (data, value) => {
					return data.data.userName
				},
				valueFormatter: (params) => {
					if (params.data && params.data.userName) return params.data.userName.name
				},
				filterValueGetter: (data, value) => {
					return data.data.userName
				},
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								valueFormatter: (params) => params.value.name
							},
							display: 'subMenu'
						},
						{
							filter: 'agSetColumnFilter',
							filterParams: {
								valueFormatter: nameValueFormatter
							}
						}
					]
				} as ISetFilterParams
			},
			{
				field: 'Role',
				valueGetter: (params) => utilService.capitalizeString(params.data.Role),
				floatingFilter: true,
				valueFormatter: nameValueFormatter,
				filter: 'agMultiColumnFilter',
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								valueFormatter: (params) => params.value.name
							},
							display: 'subMenu'
						},
						{
							filter: 'agSetColumnFilter',
							filterParams: {
								valueFormatter: (params) => params.value
							}
						}
					]
				} as ISetFilterParams
			},
			{
				field: 'Type',
				valueGetter: (params) => utilService.capitalizeString(params.data.Type),
				floatingFilter: true,
				filter: 'agMultiColumnFilter',
				valueFormatter: nameValueFormatter,
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								valueFormatter: (params) => params.value.name
							},
							display: 'subMenu'
						},
						{
							filter: 'agSetColumnFilter',
							filterParams: {
								valueFormatter: (params) => params.value
							}
						}
					]
				} as ISetFilterParams
			},
			{
				field: 'Last Trained',
				cellRenderer: (params) => {
					if (params.value)
						return new Date(params.value).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})
				},
				valueFormatter: (params: ValueFormatterParams) => {
					if (params.value)
						return new Date(params.value).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})
				},
				filter: 'agDateColumnFilter',
				floatingFilter: true,
				filterParams: dateFilterParams
			}
		]
	},
	{
		headerName: 'User Stats',
		children: [
			{
				field: 'totalTrainingDays'
			},
			{
				field: 'totalTrainingHours'
			},
			{
				field: 'totalTrainingSessions'
			}
		]
	}
]

let api

let usersReportsGrid: HTMLDivElement
let gridOptions: GridOptions

onMount(() => {
	gridOptions = {
		columnDefs: columnDefs,
		rowData: usersForDisplay,
		rowDragManaged: true,
		rowDragText: (params, dragItemCount) => {
			return params.defaultTextValue.name
		},
		rowSelection: 'multiple',
		defaultCsvExportParams: {
			onlySelected: true
		},
		defaultExcelExportParams: {
			onlySelected: true
		},
		useValueFormatterForExport: true,
		suppressContextMenu: false,
		sideBar: {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					minWidth: 225,
					maxWidth: 225,
					width: 225
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					minWidth: 180,
					maxWidth: 400,
					width: 250
				}
			],
			position: 'right'
		}
	}

	api = createGrid(usersReportsGrid, gridOptions)
})
</script>

<div class="trainings-reports">
	{#if usersForDisplay}
		<div
			id="usersGrid"
			bind:this={usersReportsGrid}
			class="ag-theme-quartz"
			style="height: 500px; border: none;" />
	{/if}
</div>

<style lang="scss">
@use '../../styles/setup/mixins';
.trainings-reports {
	@include mixins.card;
	overflow: hidden;
}
</style>
