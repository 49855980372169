<script lang="ts">
import { onMount, onDestroy } from 'svelte'
import mapboxgl from 'mapbox-gl'
import '../../../node_modules/mapbox-gl/dist/mapbox-gl.css'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { currAccount } from '../../store/stores'
import { fireMsg } from '../../utils/userMsg.service'
import type { Location } from '../../models/common.model'
import Icon from 'svelte-icons-pack'
import TiLocation from 'svelte-icons-pack/ti/TiLocation'
import { _ } from 'svelte-i18n'
import SelectButton from './SelectButton.svelte'

export let withAccountLocation = false
export let teamLocation: Location = undefined

export let lng = -71.224518
export let lat = 42.213995
export let zoom = 9

export let showMap = true
export let location: Location = {
	address: '',
	geoPoints: null
}

const accessToken = import.meta.env?.VITE_APP_MAPBOX_ACCESS_TOKEN

const defaultGeoPoints = $currAccount.location.geoPoints ?? {
	latitude: 31.9686,
	longitude: -99.9018
} // USA coordinates

let center = {
	lat: location.geoPoints?.latitude || defaultGeoPoints.latitude,
	lng: location.geoPoints?.longitude || defaultGeoPoints.longitude
}

let map: any
let geocoder: any
let mapContainer: any
let marker: any
let currentStyle = `mapbox://styles/mapbox/outdoors-v11`

onMount(() => {
	const initialState = { lng: lng, lat: lat, zoom: zoom }

	if (showMap) {
		map = new mapboxgl.Map({
			container: mapContainer,
			accessToken: accessToken,
			style: currentStyle,
			center: [center.lng, center.lat],
			zoom: initialState.zoom
		})

		map.addControl(new mapboxgl.NavigationControl())
	}

	geocoder = new MapboxGeocoder({
		accessToken: accessToken,
		localGeocoder: coordinatesGeocoder,
		mapboxgl: mapboxgl,
		marker: false
	})

	if (map) document.getElementById('geocoder').appendChild(geocoder.onAdd(map))

	if (!location.geoPoints && showMap && map) {
		getDefaultLocation()
	}

	if (location) {
		(<HTMLInputElement>document.querySelector('.mapboxgl-ctrl-geocoder--input')).value =
			location.address
	}

	if (showMap) {
		map.on('click', function (e: any) {
			const coordinates = e.lngLat

			fetch(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lng},${coordinates.lat}.json?access_token=${accessToken}`
			)
				.then((response) => response.json())
				.then((data) => {
					const features = data.features
					if (features.length > 0) {
						location.address = features[0].place_name
						location.geoPoints = { longitude: coordinates.lng, latitude: coordinates.lat }
						;(<HTMLInputElement>document.querySelector('.mapboxgl-ctrl-geocoder--input')).value =
							features[0].place_name
						marker.remove()
						marker = new mapboxgl.Marker({
							color: '#2d7392'
						}).setLngLat([coordinates.lng, coordinates.lat])
						if (map) marker.addTo(map)
					} else {
						fireMsg({
							type: 'failure',
							msg: 'Could not find the address'
						})
					}
				})
				.catch((error: Error) => {
					console.error('Error:', error)
				})
		})
	}

	geocoder.on('result', function (e: any) {
		// This function will be called when a result is selected by the user
		location.address = e.result.place_name
		location.geoPoints = {
			latitude: e.result.geometry.coordinates[1],
			longitude: e.result.geometry.coordinates[0]
		}
		if (showMap) {
			marker.remove()
			marker = new mapboxgl.Marker({
				color: '#2d7392'
			}).setLngLat([e.result.geometry.coordinates[0], e.result.geometry.coordinates[1]])
			if (map) marker.addTo(map)
		}
	})

	if (showMap) {
		marker = new mapboxgl.Marker({
			color: '#2d7392'
		}).setLngLat([center.lng, center.lat])
		if (map) marker.addTo(map)
		map.on('move', () => {
			updateData()
		})
	}
})

onDestroy(() => {
	if (showMap) map.remove()
})

function coordinatesGeocoder(query) {
	const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i)
	if (!matches) {
		return null
	}

	function coordinateFeature(lng, lat) {
		return {
			center: [lng, lat],
			geometry: {
				type: 'Point',
				coordinates: [lng, lat]
			},
			place_name: 'Lat: ' + lat + ' Lng: ' + lng,
			place_type: ['coordinate'],
			properties: {},
			type: 'Feature'
		}
	}

	const coord1 = Number(matches[1])
	const coord2 = Number(matches[2])
	const geocodes = []

	if (coord1 < -90 || coord1 > 90) {
		// must be lng, lat
		geocodes.push(coordinateFeature(coord1, coord2))
	}

	if (coord2 < -90 || coord2 > 90) {
		// must be lat, lng
		geocodes.push(coordinateFeature(coord2, coord1))
	}

	if (geocodes.length === 0) {
		// else could be either lng, lat or lat, lng
		geocodes.push(coordinateFeature(coord1, coord2))
		geocodes.push(coordinateFeature(coord2, coord1))
	}

	return geocodes
}

function updateData() {
	zoom = map.getZoom()
	lng = map.getCenter().lng
	lat = map.getCenter().lat
}

function updateLocation(latitude: number, longitude: number) {
	zoom = map.getZoom()
	lng = longitude
	lat = latitude
	map.setCenter([longitude, latitude])
	marker.remove()
	marker = new mapboxgl.Marker({
		color: '#2d7392'
	}).setLngLat([longitude, latitude])
	if (map) marker.addTo(map)
}

async function getCurrPos() {
	// Getting current position
	async function success(pos: any) {
		location.geoPoints = { latitude: pos.coords.latitude, longitude: pos.coords.longitude }
		fetch(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/${pos.coords.longitude},${pos.coords.latitude}.json?access_token=${accessToken}`
		)
			.then((response) => response.json())
			.then((data) => {
				const features = data.features
				if (features.length > 0) {
					location.address = features[0].place_name
					;(<HTMLInputElement>document.querySelector('.mapboxgl-ctrl-geocoder--input')).value =
						features[0].place_name
				} else {
					fireMsg({
						type: 'failure',
						msg: 'Could not find the address'
					})
				}
			})
			.catch((error: Error) => {
				console.error('Error:', error)
			})
		if (showMap) updateLocation(pos.coords.latitude, pos.coords.longitude)
	}
	navigator.geolocation.getCurrentPosition(success, console.error)
}

async function getDefaultLocation() {
	// Getting current position
	if (withAccountLocation && $currAccount.location.address != '') location = $currAccount.location
	else if (teamLocation) location = teamLocation
	else {
		if (map) navigator.geolocation.getCurrentPosition(success, fallback)
	}

	function success(pos: any) {
		location.geoPoints = {
			latitude: pos.coords.latitude,
			longitude: pos.coords.longitude
		}
		fetch(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/${pos.coords.longitude},${pos.coords.latitude}.json?access_token=${accessToken}`
		)
			.then((response) => response.json())
			.then((data) => {
				const features = data.features
				if (features.length > 0) {
					location.address = features[0].place_name
				} else {
					fireMsg({
						type: 'failure',
						msg: 'Could not find the address'
					})
				}
			})
			.catch((error: Error) => {
				console.error('Error:', error)
			})
		if (showMap) {
			updateLocation(location.geoPoints.latitude, location.geoPoints.longitude)
		}
	}

	function fallback() {
		location.geoPoints = defaultGeoPoints
		if (showMap) updateLocation(defaultGeoPoints.latitude, defaultGeoPoints.longitude)
		location.address = 'USA'
	}
}

$: if (map) map.setStyle(currentStyle)
</script>

<div class="wrapper">
	<div class="flex container">
		<div
			id="geocoder"
			class="geocoder" />
		{#if showMap}
			<div class="sidebar">
				<SelectButton
					id="map-style"
					bind:value={currentStyle}
					choices={[
						{ label: $_('outdoor'), value: 'mapbox://styles/mapbox/outdoors-v11' },
						{ label: $_('street'), value: 'mapbox://styles/mapbox/streets-v12' },
						{ label: $_('satellite'), value: 'mapbox://styles/mapbox/satellite-v9' }
					]} />
			</div>
		{/if}
	</div>
	<div class="map-wrap">
		{#if showMap}
			<div
				class="map"
				bind:this={mapContainer} />
		{/if}
	</div>
	<button
		class="flex align-center btn theme-btn current-location"
		type="button"
		on:click={getCurrPos}>
		<Icon src={TiLocation} />
		{$_('get_my_location')}</button>
</div>

<style lang="scss">
.wrapper {
	.container {
		padding: 0;
		margin: 0;
		justify-content: space-between;

		.geocoder {
			width: 100%;
		}
	}

	.map {
		width: 100%;
		height: 281px;
		margin: 18px 0 18px 0;
	}

	.current-location {
		margin-top: 18px;
		fill: white;
		gap: 10px;
	}
}

@media screen and (max-width: 768px) {
	.container {
		flex-direction: column;
		gap: 18px;
	}
}
</style>
