<script lang="ts">
import Icon from 'svelte-icons-pack'
import BsQuestionCircleFill from 'svelte-icons-pack/bs/BsQuestionCircleFill'

export let message = ''
export let color = 'white'
let showTooltip = false

function toggleTooltip() {
	showTooltip = true
}

function hideTooltip() {
	showTooltip = false
}
</script>

<div
	class="svg-container flex"
	role="tooltip"
	on:mouseover={toggleTooltip}
	on:mouseout={hideTooltip}
	on:focus={toggleTooltip}
	on:blur={hideTooltip}
	aria-hidden={!showTooltip}>
	<Icon
		src={BsQuestionCircleFill}
		{color} />
	{#if showTooltip}
		<div class="tooltip">{message}</div>
	{/if}
</div>

<style lang="scss">
.svg-container {
	position: relative;
	margin-top: 2px;
	width: 13px;

	@media screen and (max-width: 768px) {
		display: none;
	}
}

.tooltip {
	visibility: hidden;
	opacity: 0;
	background: #333;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 12px;
	position: absolute;
	z-index: 1;
	bottom: 120%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	min-width: 110px;
}

.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #333 transparent transparent transparent;
}

.svg-container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}
</style>
