<script lang="ts">
import type { UserModel } from '../../models/user.model'
import { _ } from 'svelte-i18n'
import { authService } from '../../services/core/auth.service'
import { fireMsg } from '../../utils/userMsg.service'

export let user: UserModel

type InputIds = ['currentPassword', 'newPassword']
type InputId = InputIds[number]

let currentPassword = ''
let newPassword = ''

const fieldsValidation = {
	currentPassword: { invalid: false, errorMessage: '' },
	newPassword: { invalid: false, errorMessage: '' }
}

async function validateInput(inputId: InputId) {
	const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/

	switch (inputId) {
		case 'currentPassword':
			if (currentPassword.length < 5) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_five_characters_message')
				return
			}
			if (!re.test(currentPassword)) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('password_error')
				return
			}
			if (currentPassword.includes(' ')) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_space')
				return
			}
			break
		case 'newPassword':
			if (newPassword.length < 5) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_five_characters_message')
				return
			}
			if (!re.test(newPassword)) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('password_error')
				return
			}
			if (newPassword.includes(' ')) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_space')
				return
			}
			break
		default:
			break
	}
}

function cleanInput(inputId: InputId) {
	isFormDisabled = false
	fieldsValidation[inputId].invalid = false
	fieldsValidation[inputId].errorMessage = ''
}

async function validateAllFields() {
	let isValid = true
	for (const fieldId in fieldsValidation) {
		if (Object.prototype.hasOwnProperty.call(fieldsValidation, fieldId)) {
			await validateInput(fieldId as InputId)
			if (fieldsValidation[fieldId].invalid) {
				fireMsg({
					type: 'failure',
					msg: `Error in field: ${fieldId}, ${fieldsValidation[fieldId].errorMessage}`
				})
				isValid = false
			}
		}
	}
	return isValid
}

async function handlePasswordChange(ev: Event) {
	const url = `${import.meta.env.VITE_APP_API_URL}/user/${user.id}`
	isFormDisabled = true
	ev.preventDefault()
	ev.stopPropagation()

	const isFormValid = await validateAllFields()
	if (!isFormValid) return (isFormDisabled = false)

	try {
		isFormDisabled = true
		await authService.changePassword(currentPassword, newPassword)
	} finally {
		isFormDisabled = false
	}
}

let isFormDisabled = false
</script>

<div class="password-change flex-column justify-center">
	<form
		on:submit={handlePasswordChange}
		class="flex-column user-edit-form">
		<header class="flex align-center form-header">
			<h1 class="title">{$_('change_password')}</h1>
		</header>
		<div class="field new-password flex-column">
			<label for="new-password">{$_('new_password')}</label>
			<input
				class="edit-input"
				class:invalid={fieldsValidation['newPassword'].errorMessage}
				type="password"
				required
				pattern={'^[a-zA-Z0-9 ]{2,}$'}
				id="name"
				on:focus={() => cleanInput('newPassword')}
				on:blur={() => validateInput('newPassword')}
				bind:value={newPassword} />
			<label
				for="new-password"
				role="alert">
				{fieldsValidation['newPassword'].errorMessage}
			</label>
		</div>
		<div class="field current-password">
			<label for="current-password">{$_('current_password')}</label>
			<div class="flex-column">
				<input
					class="edit-input"
					class:invalid={fieldsValidation['currentPassword'].errorMessage}
					type="password"
					required
					pattern={'^[a-zA-Z0-9 ]{2,}$'}
					id="password"
					on:focus={() => cleanInput('currentPassword')}
					on:blur={() => validateInput('currentPassword')}
					bind:value={currentPassword} />
				<label
					for="current-password"
					role="alert">
					{fieldsValidation['currentPassword'].errorMessage}
				</label>
			</div>
		</div>
	</form>
	<div class="flex align-center btns-container">
		<button
			disabled={isFormDisabled}
			type="submit"
			class="btn theme-btn submit"
			on:click={handlePasswordChange}>
			{$_('submit')}
		</button>
	</div>
</div>

<style lang="scss">
@use '../../styles/setup/mixins';
.password-change {
	@include mixins.card;
	padding: 28px 24px;
	gap: 26px;
	flex: 1;

	.user-edit-form {
		height: 260px;
		gap: 20px;

		.field {
			label {
				font-size: 14px;
				font-family: Nunito-SemiBold;
				color: #33383f;
			}

			.edit-input {
				font-size: 16px;
				color: var(--clr-txt-dark-primary);
			}

			&:has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			input.invalid {
				border-color: #b00020;
			}

			label[role='alert'] {
				color: #b00020;
			}
		}
	}

	.btns-container {
		justify-content: flex-end;
	}
}
</style>
