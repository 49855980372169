<script lang="ts">
import type { TrainingModel } from '../../../../models/training.model'

export let weather: TrainingModel['weather']
</script>

{#if weather.alerts}
	<section class="alert">
		<header class="header">
			<h1 class="title">Alerts</h1>
		</header>
		<div class="content">
			<h1>{weather.alerts[0].event}</h1>
			<p>{weather.alerts[0].description}</p>
		</div>
	</section>
{/if}

<style lang="scss">
@use '../../../../styles/setup/mixins';

.alert {
	@include mixins.card;
	padding: 0;
	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}
	.content {
		padding: 0 3rem 2rem 3rem;

		h1 {
			font-family: Montserrat-Bold;
			font-size: 1.5rem;
			color: #5e5873;
			margin-bottom: 1rem;
		}
	}
}
</style>
