import { getAuth } from 'firebase/auth'
import { parseDate } from 'svelty-picker'
import { en } from 'svelty-picker/i18n'
import type { ActivityModel } from '../models/activity.model.js'
import * as Sentry from '@sentry/browser'
import moment from 'moment'

//TODO: Needs to be broken down
//
//
//stringHelpers/
//capitalize.ts
//formatDate.ts
//arrayHelpers.ts
//mathHelpers.ts

export const utilService = {
	capitalizeString,
	getPastRelativeTime,
	formatTimestampToInputDate,
	capitalizeFirstLetter,
	camelCaseToTitleCase,
	isClientInMobile,
	userGreeting,
	httpRequest,
	getSessionKeys,
	restRequest,
	getShortLabel,
	getProperTrainingValue,
	combineDateTime,
	getActivitiesData,
	convertFahrenheitToCelsius,
	minutesToHoursAndMinutesStr,
	dogNameExp,
	dogWeightExp,
	getProperTime
}

function capitalizeString(string: string): string {
	return string
		.split(' ')
		.map(function (word) {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		})
		.join(' ')
}

function getPastRelativeTime(timestamp: number = Date.now()): string {
	const second = 1000
	const minute = second * 60
	const hour = minute * 60
	const day = hour * 24
	const month = day * 28
	const year = month * 12

	const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

	const diff = timestamp - new Date().getTime()

	function getRoundTime(
		unit: number,
		unitStr: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
	) {
		return rtf.format(Math.round(diff / unit), unitStr)
	}

	const relativeTime =
		-minute < diff
			? getRoundTime(second, 'second')
			: -hour < diff
			? getRoundTime(minute, 'minute')
			: -day < diff
			? getRoundTime(hour, 'hour')
			: -month < diff
			? getRoundTime(day, 'day')
			: -year < diff
			? getRoundTime(month, 'month')
			: new Date(timestamp).toLocaleString(undefined, { dateStyle: 'medium' })

	return relativeTime
}

function getProperTime(date: Date): string {
	return moment().startOf('day').diff(moment(date), 'days') > 2
		? moment(date).format('MMMM D[,] YYYY')
		: moment(date).fromNow()
}

function formatTimestampToInputDate(timestamp: number = Date.now()): string {
	const date = new Date(timestamp)

	const padTo2Digits = (num: number) => num.toString().padStart(2, '0')

	return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
		'-'
	)
}

function capitalizeFirstLetter(str: string): string {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}

function camelCaseToTitleCase(str: string): string {
	const modifiedStr = str.replace(/([A-Z])/g, ' $1')
	return modifiedStr.charAt(0).toUpperCase() + modifiedStr.slice(1)
}

function isClientInMobile(): boolean {
	return /Mobi/i.test(window.navigator.userAgent)
}

function userGreeting(): string {
	const greetings = [
		[21, 'good_night'],
		[18, 'good_evening'],
		[12, 'good_afternoon'],
		[0, 'good_morning']
	]
	const currHour = new Date().getHours()

	for (let i = 0; i < greetings.length; i++) {
		if (currHour >= +greetings[i][0]) {
			return `${greetings[i][1]}`
		}
	}
}

function httpRequest(api: string, callBackFunc: (apiData: any) => void): void {
	const xhr = new XMLHttpRequest()
	xhr.onreadystatechange = () => {
		if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
			const res = JSON.parse(xhr.responseText)
			callBackFunc(res.data)
		}
	}
	xhr.open('GET', api, true)
	xhr.send()
}

async function getSessionKeys(): Promise<void> {
	try {
		const url = `${import.meta.env.VITE_APP_API_URL}/sessionkeys`
		return restRequest(url, 'GET')
	} catch (err: unknown) {
		Sentry.captureException(err)
	}
}

async function restRequest(url: string, httpMethod: string, body?: object) {
	const auth = getAuth()

	try {
		const idToken = await auth.currentUser.getIdToken(true)
		const myHeaders = new Headers()
		myHeaders.append('Accept', '/')
		myHeaders.append('Content-Type', 'application/json')
		myHeaders.append('Authorization', `Bearer ${idToken}`)

		const response = await fetch(url, {
			method: httpMethod,
			body: JSON.stringify(body),
			headers: myHeaders
		})

		if (!response.ok) {
			const contentType = response.headers.get('content-type')
			if (!contentType || !contentType.includes('application/json')) {
				const errorMessage = await response.text()
				throw new Error(`Code ${response.status}, ${errorMessage}`)
			}
			const errorResponse = await response.json()
			throw new Error(`Code ${response.status}, ${errorResponse.message}`)
		}

		const contentType = response.headers.get('content-type')
		if (contentType && contentType.includes('application/json')) {
			const responseText = await response.text()

			if (!responseText.trim()) {
				return {}
			}

			return JSON.parse(responseText)
		} else {
			return {}
		}
	} catch (error: unknown) {
		console.error('Error in restRequest:', error)
		throw error
	}
}

function getShortLabel(fullLabelName: string): string {
	switch (fullLabelName) {
		case 'meters':
		case 'm':
			return 'm'
		case 'feet':
		case 'ft':
			return 'ft'
		case 'kilometers':
		case 'km':
			return 'km'
		case 'miles':
		case 'mi':
			return 'mi'
		case 'hectares':
		case 'ha':
			return 'ha'
		case 'acres':
		case 'ac':
			return 'ac'
		case 'kilograms':
		case 'kg':
			return 'kg'
		case 'pounds':
		case 'lb':
			return 'lb'
		case 'minutes':
		case 'min':
			return 'min'
		case 'hours':
		case 'h':
			return 'h'
		case 'ounces':
		case 'oz':
			return 'oz'
		case 'grams':
		case 'g':
			return 'g'
		case 'degrees':
		case 'deg':
			return 'deg'
		case 'times':
			return 'times'
		case 'square-meters':
			return 'm²'
		case 'square-feet':
			return 'ft²'
		case 'celsius':
			return '°C'
		case 'fahrenheit':
			return '℉'
		case 'centimeters':
			return 'cm'
		case 'inches':
			return 'in'
		default:
			return fullLabelName
	}
}

function minutesToHoursAndMinutesStr(widgetValue: any): string {
	if (widgetValue) {
		const hours = Math.floor(
			widgetValue.sliderValue ? widgetValue.sliderValue / 3600 : widgetValue / 3600
		)
		const minutes = widgetValue.sliderValue
			? Math.floor((widgetValue.sliderValue - hours * 3600) / 60)
			: Math.floor((widgetValue - hours * 3600) / 60)
		const seconds = widgetValue.sliderValue
			? widgetValue.sliderValue - hours * 3600 - minutes * 60
			: widgetValue - hours * 3600 - minutes * 60
		return widgetValue.useSeconds
			? hours
				? `${hours}h ${minutes} min ${seconds} s`
				: minutes
				? `${minutes} min ${seconds} s`
				: `${seconds} s`
			: hours
			? `${hours}h ${minutes} min ${seconds} s`
			: minutes
			? `${minutes} h ${seconds} min`
			: `${seconds} min`
	} else {
		return 'Unknown value'
	}
}

function secondsToHoursAndMinutesStr(seconds: number): string {
	const minutes = Math.floor(seconds / 60)
	const remainingSeconds = seconds % 60
	return `${minutes} min ${remainingSeconds} s`
}

function minutesToYears(data: any): string {
	const units = [
		{ name: 'years', singular: 'year' },
		{ name: 'months', singular: 'month' },
		{ name: 'days', singular: 'day' },
		{ name: 'hours', singular: 'hour' },
		{ name: 'minutes', singular: 'minute' }
	]

	const result = []

	for (const unit of units) {
		if (data[unit.name] !== undefined && data[unit.name] !== 0) {
			const value = data[unit.name]
			result.push(`${value} ${value === 1 ? unit.singular : unit.name}`)
		}
	}

	return result.join(' ')
}

const formatOdorInfo = (info) => {
	const components = []

	if (info.odorPlacement) {
		components.push(info.odorPlacement)
	}

	if (info.odorAge) {
		components.push(minutesToYears(info.odorAge))
	}

	if (info.odorWeight) {
		components.push(info.odorWeight.weight + ' ' + getShortLabel(info.odorWeight.label))
	}

	return components.join(', ')
}

function getProperTrainingValue(data): string {
	if (data.value) {
		switch (data.type) {
			case 'dropdownMultiSlider': {
				let valueText = ''
				if (data.value.selectValue) {
					valueText += data.value.selectValue
				} else {
					valueText = '-no data logged-'
				}

				if (data.value.sliderValue) {
					const timeValue = data.value.sliderValue
					valueText +=
						data.value.label === 'minutes' || timeValue.label === 'minutes'
							? data.value.useSeconds
								? `, ${secondsToHoursAndMinutesStr(timeValue)}`
								: `, ${minutesToHoursAndMinutesStr(timeValue)}`
							: ', ' + timeValue.selectedValue + ' ' + timeValue.label ?? ''
				}

				if (data.value.textField) {
					valueText += `, ${data.value.textField}`
				}

				return valueText
			}
			case 'toggleMultiTextField':
				return `${data.value.answer}${data.value.text ? ', ' + data.value.text : ''}`
			case 'slider':
				return `${data.value.selectedValue + ' ' + getShortLabel(data.value.label)}`
			case 'toggleDistanceSlider': {
				return `${data.value.answer}${
					data.value.distanceSlider
						? ', ' +
						  data.value.distanceSlider.distance +
						  ' ' +
						  (data.value.distanceSlider.label
								? utilService.getShortLabel(data.value.distanceSlider.label)
								: '')
						: ''
				} `
			}
			case 'toggleSwitch': {
				return `${utilService.capitalizeFirstLetter(data.value.answer)}`
			}
			case 'toggleMultiselect': {
				return `${data.value.answer} ${
					data.value.dropdownValue ? ', ' + data.value.dropdownValue : ''
				}`
			}
			case 'timerSlider': {
				return `${minutesToHoursAndMinutesStr(data.value.sliderValue)}`
			}
			case 'dropdownText':
				return `${
					data.value.dropdownValue
						? data.value.dropdownValue + (data.value.text ? ', ' + data.value.text : '')
						: '-no data logged-'
				}`
			case 'areaSlider':
			case 'distanceSlider':
				return `${
					data.value.distance
						? data.value.distance + ' ' + utilService.getShortLabel(data.value.label)
						: '-no data logged-'
				}`
			case 'toggleFitness':
				if (data.value.selectedIndex == 0) return `slat_mill`
				else if (data.value.selectedIndex == 1)
					return `eMill: ${data.value.distance} ${utilService.getShortLabel(data.value.label)}, ${
						data.value.incline
					} deg`
				else if (data.value.selectedIndex == 2)
					return `eBike: ${data.value.distance} ${utilService.getShortLabel(data.value.label)}`
				else if (data.value.selectedIndex == 3)
					return `Run: ${data.value.distance} ${utilService.getShortLabel(data.value.label)}`
				else if (data.value.selectedIndex == 5)
					return `Hiking: ${data.value.distance} ${utilService.getShortLabel(data.value.label)}`
				else if (data.value.selectedIndex == 4) return `bike`
				else return `${data.value.distance} ${utilService.getShortLabel(data.value.label)}`
			case 'bitLossECorrection':
				return `${
					data.value.selectedWon.answer
						? `Won Sleeve: ` + capitalizeFirstLetter(data.value.selectedWon.answer)
						: ''
				}${
					data.value.selectedWon.answer
						? ', Loss Correction: ' +
						  utilService.capitalizeFirstLetter(data.value.selectedWon.answer)
						: ''
				}${
					data.value.sliderValue
						? ', ' +
						  data.value.sliderValue +
						  (data.value.textFieldValue ? ', ' + data.value.textFieldValue : '')
						: ''
				}`
			case 'toggleStepper':
				return `${data.value.answer}${
					data.value.stepperValue
						? ', ' + data.value.stepperValue + ' ' + data.value.stepperLabel
						: ''
				}`
			case 'multiSelectSlider':
				return `${
					data.value.items.length != 0
						? data.value.items.join(', ') +
						  ', ' +
						  (data.value.sliderValue ? data.value.sliderValue.selectedValue : '')
						: '-no data logged-'
				}`
			case 'rangeSlider':
				return `${
					data.value.selectedRange
						? data.value.selectedRange.join(' - ') +
						  ' ' +
						  utilService.getShortLabel(data.value.label)
						: ''
				}`
			case 'weightSlider':
				return `${data.value.weight} ${utilService.getShortLabel(data.value.label)}`
			case 'toggleSwitchTextField':
				return `${data.value.answer}${data.value.text ? ', ' + data.value.text : ''}`
			case 'distanceStepper':
				return `${data.value.distance} ${utilService.getShortLabel(data.value.label)}`
			case 'toggleTextToTags':
				return `${utilService.capitalizeFirstLetter(data.value.answer)}${
					data.value.tags ? ', ' + data.value.tags.join(', ') : ''
				}`
			case 'toggleSwitchSlider':
				return `${utilService.capitalizeFirstLetter(data.value.answer)}${
					data.value.sliderValue
						? typeof data.value.sliderValue === 'number'
							? ', ' + data.value.sliderValue
							: data.value.sliderValue.selectedValue
							? ', ' +
							  data.value.sliderValue.selectedValue +
							  ' ' +
							  getShortLabel(data.value.sliderValue.label)
							: ''
						: ''
				}`
			case 'dropdownTextToTags':
				return `${
					data.value.dropdownValue
						? data.value.dropdownValue + (data.value.tags ? ', ' + data.value.tags.join(', ') : '')
						: '-no data logged-'
				}`
			case 'toggleDropdown':
				return `${data.value.answer}${
					data.value.dropdownValue ? ', ' + data.value.dropdownValue : ''
				}`
			case 'multiSelect':
				return `${data.value.length !== 0 ? data.value.join(', ') : '-no data logged-'}`
			case 'textToTags':
				return `${data.value.length !== 0 ? data.value.join(', ') : '-no data logged-'}`
			case 'customStepper':
				return `${data.value.selectedValue} ${
					data.value.label ? getShortLabel(data.value.label) : ''
				}`
			case 'daysToYearsStepper':
				return `${Math.floor(data.value / 365) ? Math.floor(data.value / 365) + ' years, ' : ''}${
					Math.floor((data.value % 365) / 31)
						? Math.floor((data.value % 365) / 31) + ' months, '
						: ''
				}${
					Math.floor((data.value % 365) % 31) ? Math.floor((data.value % 365) % 31) + ' days' : ''
				}`
					.replace(/,\s*$/, '')
					.trim()
			case 'minutesToYearsStepper':
				return minutesToYears(data.value).length != 0
					? minutesToYears(data.value)
					: '-no data logged-'
			case 'temperatureSlider':
				return `${data.value ? data.value.temperature + ' ' + getShortLabel(data.value.label) : ''}`
			case 'stepperMultiply': {
				const allOutputs = []
				for (let i = 0; i < data.value.odorInfo.length; i++) {
					const valueItem = data.value.odorInfo[i]
					const output = []
					if (Array.isArray(valueItem.multiselect) && valueItem.multiselect.length) {
						output.push(valueItem.multiselect.join(', '))
					}
					if (
						Array.isArray(valueItem.multiselectTextToTags?.multiselectValue) &&
						valueItem.multiselectTextToTags.multiselectValue.length
					) {
						output.push(valueItem.multiselectTextToTags.multiselectValue.join(', '))
						if (
							Array.isArray(valueItem.multiselectTextToTags.tags) &&
							valueItem.multiselectTextToTags.tags.length
						) {
							output.push('Tags: ' + valueItem.multiselectTextToTags.tags.join(', '))
						}
					}
					if (
						Array.isArray(valueItem.multiselectTextToTags2?.multiselectValue) &&
						valueItem.multiselectTextToTags2.multiselectValue.length
					) {
						output.push(valueItem.multiselectTextToTags2.multiselectValue.join(', '))
						if (
							Array.isArray(valueItem.multiselectTextToTags2.tags) &&
							valueItem.multiselectTextToTags2.tags.length
						) {
							output.push('Tags: ' + valueItem.multiselectTextToTags2.tags.join(', '))
						}
					}
					if (valueItem.odorAge) {
						const ageComponents = []
						if (valueItem.odorAge.years) ageComponents.push(`${valueItem.odorAge.years} years`)
						if (valueItem.odorAge.months) ageComponents.push(`${valueItem.odorAge.months} months`)
						if (valueItem.odorAge.days) ageComponents.push(`${valueItem.odorAge.days} days`)
						if (valueItem.odorAge.hours) ageComponents.push(`${valueItem.odorAge.hours} hours`)
						if (valueItem.odorAge.minutes)
							ageComponents.push(`${valueItem.odorAge.minutes} minutes`)
						output.push(ageComponents.join(', '))
					}
					allOutputs.push(`${i + 1}. ${output.join(', ')}`)
				}

				const result = allOutputs.length ? allOutputs.join('\n') : '-no data logged-'
				return result
			}
			case 'sizeSlider':
				return `${
					data.value.length
						? data.value.length + ' ' + utilService.getShortLabel(data.value.label)
						: '-no data logged-'
				}`
			case 'multiselectTextToTags':
				return `${
					data.value.multiselectValue.length != 0
						? data.value.multiselectValue.join(', ') +
						  (data.value.tags && data.value.tags.length != 0
								? ', Tags: ' + data.value.tags.join(', ')
								: '')
						: '-no data logged-'
				}`
			case 'toggleMultiSlider': {
				const output = []
				if (data.value.answer) output.push(data.value.answer)
				if (data.value.customStepperValue && data.value.customStepperValue.selectedValue)
					output.push(
						`${data.value.customStepperValue.selectedValue} ${data.value.customStepperValue.label}`
					)
				if (data.value.distanceSliderValue && data.value.distanceSliderValue.distance)
					output.push(
						`${data.value.distanceSliderValue.distance} ${data.value.distanceSliderValue.label}`
					)
				if (data.value.sliderValue && data.value.sliderValue.selectedValue)
					output.push(`${data.value.sliderValue.selectedValue} ${data.value.sliderValue.label}`)
				if (data.value.temperatureSliderValue && data.value.temperatureSliderValue.temperature)
					output.push(
						`${data.value.temperatureSliderValue.temperature} ${data.value.temperatureSliderValue.label}`
					)

				return output.length ? output.join(', ') : '-no data logged-'
			}
			case 'multiLevelDropdown': {
				const output = []

				if (data.value.firstValue.label) {
					output.push(`${data.value.firstValue.label}`)

					if (data.value.secondaryValue) {
						output.push(`${data.value.secondaryValue}`)
					}
				}
				return output.length ? output.join(', ') : '-no data logged-'
			}
			default:
				return utilService.capitalizeFirstLetter(
					`${data.value} ${data.label ? utilService.getShortLabel(data.label) : ''}`
				)
		}
	} else return `-no data logged-`
}

function combineDateTime(date: Date, time: Date): Date {
	const dateWithDate = parseDate(date, 'dd/mm/yyyy', en, 'standard')
	const dateWithTime = parseDate(time, 'H.i', en, 'php')

	// Extracting parts of the date from the first date
	const year = dateWithDate.getFullYear()
	const month = dateWithDate.getMonth()
	const day = dateWithDate.getDate()

	// Extracting time parts from the second date
	const hours = dateWithTime.getHours()
	const minutes = dateWithTime.getMinutes()
	const seconds = dateWithTime.getSeconds()
	const milliseconds = dateWithTime.getMilliseconds()

	// Creating a new date by combining values from two dates
	const combinedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds)
	return combinedDate
}

function getActivitiesData(activity: ActivityModel): {
	info?: string
	name?: string
	color?: string
	icon?: string
	image?: string
} {
	switch (activity.type) {
		case 'invite_sent':
			return {
				info: `${activity.createdBy.name} invited ${activity.data.invite.invitedUserName}`,
				name: `${activity.createdBy.name}`,
				color: `#4ECDC4`
			}
		case 'account_updated':
			return {
				info: `Account was updated`,
				color: `#E63946`
			}
		case 'team_added':
			return {
				info: `Team ${activity.data.team.team.name} was created`,
				icon: `${activity.data.team.icon}`,
				color: `#8338EC`
			}
		case 'team_updated':
			return {
				info: `Team ${activity.data.team.team.name} was updated`,
				icon: `${activity.data.team.icon}`,
				color: `#8084E8`
			}
		case 'dog_updated':
			return {
				info: `${activity.data.dog.dog.name} was updated`,
				image: `${activity.data.dog.dog.image}`,
				color: `#FFEB3B`
			}
		case 'dog_added':
			return {
				info: `${activity.createdBy.name} added a new dog ${activity.data.dog.dog.name}`,
				image: `${activity.data.dog.dog.image}`,
				color: `#FFD700`
			}
		case 'training_added':
			return {
				info: `${activity.createdBy.name} logged a new ${activity.data.training.trainingType} training session with ${activity.data.training.dog.name}`,
				image: `${activity.data.training.icon}`,
				color: `#FE9803`
			}
		case 'dog_added_to_team':
			return {
				info: `${activity.data.dog.dogName} was added to team ${activity.data.dog.teamName}`,
				image: `${activity.data.dog.dogImage}`,
				color: `#FFD700`
			}
	}
}

function convertFahrenheitToCelsius(fahrenheit: number): number {
	const celsius = Math.floor((fahrenheit - 32) * (5 / 9))
	return celsius
}

function dogNameExp(dogName: string): boolean {
	const nameRegEx = /^[a-zA-Z\u0590-\u05FF\u200f\u200e0-9 ]+$/
	return nameRegEx.test(dogName)
}

function dogWeightExp(dogWeight: string): boolean {
	const weightRegEx = /^([0-9]{1,3}(\.[0-9]{1,2})?)$/
	return weightRegEx.test(dogWeight)
}
