<svg
	width="80"
	height="80"
	viewBox="0 0 80 80"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#F96E6F"
		fill-opacity="0.07" />
	<path
		d="M44.4344 22.9993C41.5924 21.6669 38.4074 21.6669 35.5654 22.9993L22.1832 29.2732C20.0312 30.2821 19.4701 33.127 20.5 35.0933L20.5 45C20.5 45.8284 21.1716 46.5 22 46.5C22.8284 46.5 23.5 45.8284 23.5 45V37.3441L35.5656 43.0007C38.4076 44.3331 41.5926 44.3331 44.4346 43.0007L57.8168 36.7268C60.7277 35.3621 60.7277 30.638 57.8168 29.2733L44.4344 22.9993Z"
		fill="#EF4F50" />
	<path
		opacity="0.5"
		d="M26 38.5166L35.5656 43.0012C38.4076 44.3336 41.5926 44.3336 44.4346 43.0012L54 38.5167V49.251C54 51.267 52.993 53.1545 51.2293 54.1313C48.2926 55.7576 43.592 58.0001 40 58.0001C36.408 58.0001 31.7074 55.7576 28.7707 54.1313C27.007 53.1545 26 51.267 26 49.251V38.5166Z"
		fill="#EF4F50" />
</svg>
