import type { User } from 'firebase/auth'
import { loggedInUser, user as currUser } from '../../store/stores'
import { userDB } from '../database/user.db'
import type { UserModel, UserRole, UserType } from '../../models/user.model'
import { userMsg } from '../../utils/userMsg.service'
import { utilService } from '../../utils/util.service'
import type { DocumentData } from 'firebase/firestore'

export const userService = {
	query,
	getById,
	storeUser,
	create,
	getEmptyUser
}

async function query(
	userIds: string[],
	filterBy?: Partial<{ role: UserRole; type: UserType }>
): Promise<UserModel[]> {
	const users = []

	try {
		const teamDocs = await userDB.getMany(userIds, filterBy)
		teamDocs.forEach((doc) => users.push(doc.data()))
		return users
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get users', error)
	}
}

async function getById(uid: string): Promise<DocumentData> {
	try {
		const user = await userDB.get(uid)
		return user
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get user', error)
	}
}

async function storeUser(user: User): Promise<DocumentData> {
	try {
		const userData = (await getById(user.uid)) as UserModel
		loggedInUser.set(userData)
		currUser.set(user)
		return userData
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get user', error)
	}
}

async function create(initialData: Partial<UserModel>, accountName: string, accountId: string): Promise<void> {
	const url = `${import.meta.env.VITE_APP_API_URL}/user`
	const body = {
		id: initialData.id,
		email: initialData.email,
		firstName: initialData.firstName,
		lastName: initialData.lastName,
		accountIds: [accountId],
		SelectedAccount: {
			Id: accountId,
			Name: accountName
		}
	}
	utilService.restRequest(url, 'POST', body)
}

function getEmptyUser(initialData?: Partial<UserModel>): UserModel {
	const emptyUser: UserModel = {
		id: '',
		email: '',
		firstName: '',
		lastName: '',
		accountIds: [],
		selectedAccount: {
			id: '',
			name: ''
		},
		fbfEventId: null,
		createdAt: null,
		updatedAt: null,
		type: [],
		role: ''
	}

	const userWithInitData = { ...emptyUser, ...initialData }

	return userWithInitData
}