<script lang="ts">
import { _ } from 'svelte-i18n'
import { loggedInUser } from '../../../store/stores'
import UserAddModal from './UserAddModal.svelte'
import type { AccountUserModel } from '../../../models/user.model'
import { onMount } from 'svelte'
import { fireMsg } from '../../../utils/userMsg.service'
import Tooltip from '../../common/Tooltip.svelte'
import { utilService } from '../../../utils/util.service'

export let accountUsers: AccountUserModel[]
export let teamId: string
export let teamHandlers: any

let disabled = true
let sessionKeys: any

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

let isOpen = false

function closeModal() {
	isOpen = false
}

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	allowed = await checkIfAllowed()
})

async function checkIfAllowed() {
	if (accountUsers)
		if (accountUsers.find((user) => user.id === $loggedInUser.id).role == 'member') {
			return false
		}
	return true
}

let allowed = false
</script>

{#if disabled}
	<button
		{disabled}
		class="btn theme-btn flex align-center flex"
		on:click={() => {
			if (allowed) isOpen = true
			else
				fireMsg({
					type: 'failure',
					msg: $_('only_admin_message')
				})
		}}>
		<Tooltip message={$_('locked_feature')} />
		{$_('add_user')}
	</button>
{:else}
	<button
		{disabled}
		class="btn theme-btn flex align-center"
		on:click={() => {
			if (allowed) isOpen = true
			else
				fireMsg({
					type: 'failure',
					msg: $_('only_admin_message')
				})
		}}>
		{$_('add_user')}
		<Tooltip message={$_('add_existing_user_to_team')} />
	</button>
{/if}
<UserAddModal
	{isOpen}
	{accountUsers}
	{teamHandlers}
	{teamId}
	on:closeModal={closeModal} />

<style lang="scss">
.btn {
	padding-right: 12px;
	gap: 7px;

	@media screen and (max-width: 768px) {
		padding-right: 20px;
	}
}
.btn:disabled {
	pointer-events: auto;
	gap: 6px;
}
</style>
