import { userMsg } from '../utils/userMsg.service';
import { writable } from 'svelte/store';

interface ErrorState {
  message: string;
  visible: boolean;
}

type ErrorDetails = {
  name?: string;
  message?: string;
  stack?: string;
  [key: string]: unknown;
};

function createErrorStore() {
  const { subscribe, set, update } = writable<ErrorState>({
    message: '',
    visible: false,
  });

  return {
    subscribe,
    showError: (message: string, error?: Error | ErrorDetails) => {
      //console.log('ErrorStore: showError called', { message, error });
      set({ message, visible: true });
      userMsg.errorMsg(message, error);
    },
    hideError: () => update(state => ({ ...state, visible: false })),
    reset: () => set({ message: '', visible: false }),
  };
}

export const errorStore = createErrorStore();