<script lang="ts">
import { useParams } from 'svelte-navigator'
import { onMount, onDestroy } from 'svelte'
import { insightsService } from '../../../services/insights/data.service'
import TrainingPercentage from '../TrainingPercentage.svelte'
import TotalSessions from '../TotalSessions.svelte'
import TotalTrainingTime from '../TotalTrainingTime.svelte'
import TotalTrainingDays from '../TotalTrainingDays.svelte'
import Rating from '../Rating.svelte'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import Success from '../Success.svelte'
import { navBarOpen } from '../../../store/stores'
import ProfileCard from '../ProfileCard.svelte'
import { updateCategory } from '../categoryStore'
import EventPercentage from '../EventPercentage.svelte'
import { _ } from 'svelte-i18n'
import CompareChoice from '../comparison/CompareChoice.svelte'
import { utilService } from '../../../utils/util.service'
import CompareIcon from '../comparison/icon/CompareIcon.svelte'
import Alert from '../../common/Alert.svelte'
import { useNavigate } from 'svelte-navigator'
import { currAccount } from '../../../store/stores'
import { allowedSubscriptions } from '../comparison/compareStore'

const navigate = useNavigate()
const params = useParams()

let showModal = false

let dataForDisplay
let data
let userSubscription

onMount(async () => {
	data = await insightsService.request()
	const sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()

	userSubscription = sessionKeys.activeSubscriptions[0]
		? sessionKeys.activeSubscriptions[0].items[0].price.product_id
		: ''
})

onDestroy(() => {
	updateCategory('trainedBy')
})

function closeModal() {
	showModal = false
}

$: {
	if (data) {
		dataForDisplay = new Map(
			[...insightsService.getTrainingData(data, 'trainedBy', $params.id).entries()].sort(
				(a, b) => b[1].trainingTime - a[1].trainingTime
			)
		)
	}
}
</script>

{#if data}
	<div
		class="dogs-insights flex-column"
		class:nav-open={$navBarOpen}>
		<div class="header-container grid">
			<div class="profile-card">
				<ProfileCard element={[...dataForDisplay][0][1]} />
			</div>
			<button
				class="compare-button flex-column justify-center"
				on:click={() => {
					showModal = true
				}}>
				<CompareIcon />
				<header class="title">
					<h1 class="flex-column action">
						<span>Add {utilService.capitalizeFirstLetter('user')}</span>
						<span>To Comparison</span>
					</h1>
				</header>
			</button>
		</div>
		<h1 class="title">{$_(`insights_pages.total_trainings_by_type`)}</h1>
		<div class="training-percentage grid">
			<TrainingPercentage
				groupBy={'sessions'}
				dogId={$params.id}
				{data} />
			<TrainingPercentage
				groupBy={'hours'}
				dogId={$params.id}
				{data} />
			<TrainingPercentage
				groupBy={'days'}
				dogId={$params.id}
				{data} />
		</div>
		<div class="success-training grid">
			<Rating
				{data}
				category="user"
				infoId={$params.id}
				groupBy={'dogRating'} />
			<Rating
				{data}
				category="user"
				infoId={$params.id}
				groupBy={'userRating'} />
		</div>
		<Success
			{data}
			infoId={$params.id} />
		<EventPercentage
			{data}
			infoId={$params.id} />
		<TotalSessions
			infoId={$params.id}
			{data} />
		<TotalTrainingTime
			infoId={$params.id}
			{data} />
		<TotalTrainingDays
			infoId={$params.id}
			{data} />
	</div>
{:else}
	<CmpLoader />
{/if}
{#if showModal}
	{#if !allowedSubscriptions.includes(userSubscription)}
		<Alert
			title="Upgrade your plan!"
			message="To access the insight comparison feature, please upgrade your subscription plan."
			onConfirm={() => {
				navigate('/checkout')
			}}
			onCancel={closeModal} />
	{:else}
		<CompareChoice
			title="Select user for comparison"
			element={[...dataForDisplay][0][1]}
			onCancel={closeModal}
			category={'user'} />
	{/if}
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';
.dogs-insights {
	gap: 14px;

	.header-container {
		grid-template-columns: 4fr 1fr;
		gap: 1rem;

		.compare-button {
			@include mixins.card;

			color: var(--clr-txt-dark-secondary);
			padding: 20px;
			gap: 12px;

			.title {
				text-align: start;
				color: var(--clr-txt-dark-secondary);

				span {
					font-weight: 500;

					&:first-child {
						font-size: 14px;
					}

					&:last-child {
						font-size: 24px;
					}
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
	}

	&.nav-open {
		width: calc(100vw - 312px);
	}

	&:not(.nav-open) {
		width: calc(100vw - 122px);
	}

	@media (max-width: 768px) {
		&.nav-open,
		&:not(.nav-open) {
			width: calc(100vw - 56px);
		}
	}

	.training-percentage {
		grid-template-columns: repeat(3, 1fr);
		gap: 12px;

		@media screen and (max-width: 1100px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.success-training {
		grid-template-columns: repeat(2, 1fr);
		gap: 12px;

		@media screen and (max-width: 1100px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.title {
		font-family: Montserrat-Medium;
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}
</style>
