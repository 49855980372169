<script lang="ts">
import { onMount, onDestroy } from 'svelte'
import { _ } from 'svelte-i18n'
import { Lottie } from 'lottie-svelte'

let container: HTMLDivElement
let animation: Lottie
let globalError = $_('global_error')
let toHomepage = $_('back_to_home')
let issue = $_('issue_message')

onMount(() => {
	const element = document.createElement('h1') as HTMLHeadingElement
	element.innerHTML = globalError
	element.style.margin = '12px 6px'
	element.style.fontSize = '23px'
	element.style.textAlign = 'center'

	const issueMessage = document.createElement('p') as HTMLParagraphElement
	issueMessage.innerHTML = issue
	issueMessage.style.fontSize = '16px'
	issueMessage.style.textAlign = 'center'

	const link = document.createElement('a') as HTMLAnchorElement
	link.href = '/'
	link.innerHTML = toHomepage
	link.style.fontSize = '13px'
	link.style.padding = '12px 20px'
	link.style.marginTop = '60px'
	link.classList.add('btn', 'theme-btn')

	container.appendChild(element)
	container.appendChild(issueMessage)
	container.appendChild(link)
	document.body.innerHTML = ''
	document.body.appendChild(container)
})

onDestroy(() => {
	animation.destroy()
})
</script>

<div
	class="error flex-column justify-center align-center"
	bind:this={container}>
	<div class="animation">
		<Lottie
			path="/assets/animations/error.json"
			bind:this={animation} />
	</div>
</div>

<style lang="scss">
.error {
	height: 100vh;

	.animation {
		margin-top: -100px;
		width: 350px;
	}
}
</style>
