<script lang="ts">
import { _ } from 'svelte-i18n'
import Select from 'svelte-select/Select.svelte'
import type { AccountUserModel } from '../../../models/user.model'
import { userMsg } from '../../../utils/userMsg.service'
import { utilService } from '../../../utils/util.service'
import { currAccountUsers } from '../../../store/stores'

export let title = ''
export let message = ''
export let onCancel: () => void
export let users: AccountUserModel[]
export let selectedUserId: string

let userForReplace: { value: string; label: string }

async function removeUserFromAccount(selectedUserId: string, userForReplaceId: string) {
	try {
		const url = `${import.meta.env.VITE_APP_API_URL}/accountRemoveUser`
		const body = {
			UserID: selectedUserId,
			ReplacementUserID: userForReplaceId
		}
		onCancel()
		currAccountUsers.set(
			$currAccountUsers.filter((accountUser) => accountUser.id != selectedUserId)
		)

		await utilService.restRequest(url, 'PATCH', body)
	} finally {
		userMsg.successMsg('User was successfully removed from account!')
	}
}
</script>

<div id="background-overlay" />

<div class="alert flex-column justify-center">
	<header class="form-header flex align-center">
		<h1 class="title">{$_(title)}</h1>
	</header>
	<p>{message}</p>
	<Select
		showChevron={true}
		clearable={false}
		bind:value={userForReplace}
		items={users
			.filter((user) => user.id != selectedUserId)
			.map((user) => ({
				label: user.firstName + ' ' + user.lastName,
				value: user.id
			}))} />
	<div class="buttons flex">
		<button
			class="btn theme-btn outline"
			on:click={onCancel}>{$_('cancel')}</button>
		<button
			disabled={userForReplace ? false : true}
			class="btn theme-btn"
			on:click={() => removeUserFromAccount(selectedUserId, userForReplace.value)}
			>{$_('continue')}</button>
	</div>
</div>

<style lang="scss">
.alert {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -70%);
	width: 540px;
	background-color: white;
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	padding: 28px 28px 18px;
	border-radius: 16px;
	z-index: 101;
	gap: 12px;

	@media screen and (max-width: 768px) {
		height: fit-content;
		width: 90%;
	}

	.form-header {
		font-size: 20px;
		font-family: Nunito-SemiBold;
		margin-bottom: 10px;
		gap: 16px;

		&::before {
			content: '';
			position: relative;
			width: 16px;
			height: 32px;
			background-color: #ef4f5052;
			border-radius: 4px;
		}
	}

	p {
		width: fit-content;
		font-size: 15px;
		color: #33383f;
	}

	.buttons {
		margin: 20px 10px 10px 0;
		align-self: flex-end;
		gap: 10px;

		.btn {
			font-size: 14px !important;
		}
	}
}

#background-overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
}
</style>
