<script lang="ts">
import { onMount, onDestroy } from 'svelte'
import * as Highcharts from 'highcharts'
import { insightsService } from '../../services/insights/data.service'
import exporting from 'highcharts/modules/exporting'
import { _ } from 'svelte-i18n'
import { utilService } from '../../utils/util.service'

export let dogId = undefined
export let groupBy: string
export let data: any

let chart
let sessionKeys

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()

	if (data) {
		const trainingData = getTrainingPercentage(data, dogId)
		createChart(trainingData)
	}
})

function getTrainingPercentage(data, dogId?) {
	data = insightsService.getTrainingPercentage(data, groupBy, dogId)

	data = {
		labels: Array.from(data.keys()),
		values: Array.from(data.values())
	}
	data.values.sort((a, b) => b - a)

	const topLabels = data.labels.slice(0, 4)
	const topValues = data.values.slice(0, 4)

	const colors = [
		'rgba(249, 110, 111, 1)',
		'rgba(254, 152, 3, 1)',
		'rgba(0, 0, 0, 1)',
		'rgba(38, 70, 84, 1)'
	]

	return {
		labels: topLabels,
		values: topValues,
		colors
	}
}

function createChart(trainingData) {
	if (sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing') {
		exporting(Highcharts)
	}

	const chartConfig: Highcharts.Options = {
		chart: {
			type: 'pie',
			margin: [0, 0, 0, 160],
			spacing: [10, 0, 0, 5],
			height: 200
		},
		title: {
			text: ''
		},
		tooltip: {
			headerFormat: '',
			pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			},
			description: 'This is the most used desktop screen reader'
		},
		plotOptions: {
			pie: {
				size: '80%',
				borderRadius: 4,
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					format: '{point.percentage:.1f} %',
					style: {
						fontFamily: 'Montserrat-Bold',
						color: 'white',
						textOutline: 'none',
						fontSize: '8px'
					},
					distance: -25
				},
				showInLegend: true,
				colors: trainingData.colors
			}
		},
		legend: {
			layout: 'vertical',
			align: 'left',
			verticalAlign: 'top',
			title: {
				text: '',
				style: {
					color: 'rgba(8, 23, 53, 1)',
					fontSize: '17px'
				}
			},
			itemStyle: {
				color: 'rgba(132, 129, 138, 1)',
				fontFamily: 'Nunito',
				fontSize: '11px'
			},
			itemMarginTop: 5
		},
		series: [
			{
				type: 'pie',
				name: 'Percentage',
				data: trainingData.values
			}
		],
		navigation: {
			buttonOptions: {
				align: 'right'
			}
		}
	}

	chartConfig.series[0]['data'] = trainingData.labels.map((label: string, index: number) => ({
		name: label,
		y: trainingData.values[index]
	}))

	chart = Highcharts.chart(`${groupBy}-${dogId}-chart-container`, chartConfig)
}

onDestroy(() => {
	if (chart) {
		chart.destroy()
	}
})
</script>

<div class="chart-container">
	<h1 class="title">{`${$_('by')} ${$_(groupBy)}`}</h1>
	<div
		class="training-percentage-chart"
		id="{groupBy}-{dogId}-chart-container" />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';
.chart-container {
	@include mixins.card;
	padding: 12px 8px 0 0;
	min-height: 210px;
	overflow: visible;

	.title {
		font-family: Montserrat-Medium;
		text-transform: capitalize;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-left: 12px;
	}
}
</style>
