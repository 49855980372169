<script lang="ts">
import { onMount } from 'svelte'
import Product from '../cmps/checkout/Product.svelte'
import Payment from '../cmps/checkout/Payment.svelte'
import PaymentFooter from '../cmps/checkout/PaymentFooter.svelte'
import { _ } from 'svelte-i18n'
import PaymentAlert from '../cmps/checkout/PaymentAlert.svelte'
import * as Sentry from '@sentry/browser'
import { userMsg } from '../utils/userMsg.service'


interface LineItem {
	priceId: string
	quantity: number
}

interface Prices {
	data: {
		details: {
			lineItems: LineItem[]
		}
	}
}

let prices: Prices
let productId: ''

var billingCycle = 'month'
var isProfit = false

onMount(() => {
	getPrices(billingCycle);
})


/**
 * Retrieves the prices for a given billing cycle.
 *
 * @param {string} cycle - The billing cycle, either 'month' or 'year'.
 * @return {Promise<void>} A promise that resolves when the prices are retrieved.
 */
function getPrices(cycle: string) {
	cycle
	window.Paddle.PricePreview({
		items: items
	})
		.then((result: Prices) => {
			prices = result
		})
		.catch((error: string) => {
			userMsg.errorMsg('Error: issue price preview.')
			console.warn(error)
			Sentry.captureException(error)
		})
}

var items: { priceId: string; quantity: number }[]

$: {
	if (isProfit) {
		if (billingCycle == 'month') {
			items = [
				{
					priceId: 'pri_01hg7ma2mdpewjr7axn2h1cnw4',
					quantity: 1
				},
				{
					priceId: 'pri_01hg7mekekyf1xpykj11dq9djc',
					quantity: 1
				},
				{
					priceId: 'pri_01hg7mpkmggh6kxvx61h5vg602',
					quantity: 1
				}
			]
			getPrices(billingCycle)
		} else {
			items = [
				{
					priceId: 'pri_01hg7n5k7aqndhn7z9m0xdmd3d',
					quantity: 1
				},
				{
					priceId: 'pri_01hg7n7wn8w10m5gp0kkxdb6az',
					quantity: 1
				},
				{
					priceId: 'pri_01hg7naxt1nhtph1b6zxnhfnfc',
					quantity: 1
				}
			]
			getPrices(billingCycle)
		}
	} else {
		if (billingCycle == 'month') {
			items = [
				{
					priceId: 'pri_01hty173e9x3f3x5wx0btsmgge',
					quantity: 1
				},
				{
					priceId: 'pri_01hty105m85msje3vd6ww815vp',
					quantity: 1
				},
				{
					priceId: 'pri_01hty0td8n9vv9q49kbpvexhwm',
					quantity: 1
				}
			]
			getPrices(billingCycle)
		} else {
			items = [
				{
					priceId: 'pri_01hty0g0vehdt1ewj0h5f0srph',
					quantity: 1
				},
				{
					priceId: 'pri_01hty07j3zpdycmrb33zvcyqja',
					quantity: 1
				},
				{
					priceId: 'pri_01hty01qxx0y9qtpcpymchsj96',
					quantity: 1
				}
			]
			getPrices(billingCycle)
		}
	}
}
</script>

{#if prices && !productId}
	<div class="products">
		<header class="flex-column justify-center align-center form-header">
			<h1 class="title">Simple pricing for everyone</h1>
			<h2 class="description">Choose plan that works best for you and your team.</h2>
		</header>
		<div class="plans flex-column">
			<Product
				bind:billingCycle
				bind:isProfit
				bind:productId
				productInfo={prices.data.details.lineItems} />
		</div>
		<PaymentAlert />
		<footer class="products-footer flex-column align-center">
			<PaymentFooter />
		</footer>
	</div>
{/if}

{#if productId}
	<div class="btn-container">
		<button
			class="back-to-plan"
			on:click={() => {
				productId = ''
			}}>{$_('back_to_plan_selection')}</button>
	</div>
	<Payment selectedPlan={productId} />
{/if}

<style lang="scss">
@use '../styles/setup/mixins';

.products {
	@include mixins.card;
	padding: 32px 24px;
	margin: 20px 0;
	width: 100%;
	gap: 70px;
}

.form-header {
	text-align: center;

	.title {
		display: flex;
		align-items: center;
		font-size: 33px;
		font-family: Nunito-Bold;
		margin-bottom: 10px;
		gap: 16px;
	}

	.description {
		color: rgb(105, 105, 105);
		font-family: Nunito;
		font-size: 15px;
		font-weight: normal;
	}
}

.btn-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: right;

	.back-to-plan {
		font-weight: bold;
		margin-bottom: 1rem;
		padding: 1em 2em;
		color: #fff;
		border-radius: 8px;
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		-webkit-transition: background-color 0.3s;
		transition: background-color 0.3s;
	}
}

@media screen and (max-width: 900px) {
	.form-header {
		text-align: center;
		.title {
			align-self: flex-start !important;
			font-size: 18px;
			margin-bottom: 24px;
		}
	}
}
</style>
