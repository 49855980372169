<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#FBD8D9" />
	<g clip-path="url(#clip0_548_25708)">
		<path
			d="M36.4332 20.7955C35.8732 19.4685 35.6049 18.8002 35.0214 17.5014L36.2355 14.9508L33.0308 11.7649L30.5132 12.9743C29.2002 12.4143 28.532 12.1461 27.2191 11.5861L26.2685 8.94141H21.732L20.7908 11.5673C19.4591 12.1273 18.7908 12.3955 17.4967 12.9555L14.9696 11.7649L11.7649 14.9508L12.9743 17.4873C12.4143 18.8002 12.1461 19.4685 11.5861 20.7814L8.94141 21.732V26.2685L11.5673 27.2096C12.1273 28.5414 12.3955 29.2096 12.9555 30.5038L11.7649 33.0308C13.3696 34.6496 13.3696 34.6496 14.9885 36.2543L17.5061 35.0449C18.8191 35.6049 19.4873 35.8732 20.8002 36.4567L21.7414 39.0826H26.2779L27.2191 36.4567C28.5461 35.8967 29.2143 35.6285 30.5132 35.0449L33.0308 36.2355C34.6496 34.6308 34.6496 34.6308 36.2543 33.012L35.0449 30.4943C35.6049 29.1814 35.8732 28.5132 36.4567 27.2002L39.0826 26.2591V21.732L36.4332 20.7955ZM24.0002 32.1273C22.3917 32.1273 20.8193 31.6502 19.482 30.7565C18.1446 29.8628 17.1023 28.5925 16.487 27.1063C15.8717 25.6202 15.7109 23.9849 16.0251 22.4074C16.3392 20.8298 17.1142 19.3809 18.2519 18.2438C19.3896 17.1068 20.839 16.3327 22.4167 16.0194C23.9944 15.7062 25.6296 15.8679 27.1154 16.4841C28.6012 17.1003 29.8709 18.1433 30.7638 19.4812C31.6568 20.8191 32.1329 22.3917 32.132 24.0002C32.1307 26.1561 31.2735 28.2232 29.7486 29.7472C28.2237 31.2712 26.1561 32.1273 24.0002 32.1273Z"
			fill="#F96E6F" />
		<path
			d="M29.2807 27.1204C28.6822 27.9634 27.8906 28.6508 26.972 29.1252C26.0534 29.5996 25.0346 29.8472 24.0007 29.8472C22.9668 29.8472 21.948 29.5996 21.0294 29.1252C20.1108 28.6508 19.3192 27.9634 18.7207 27.1204C19.176 25.7305 20.1622 24.577 21.4642 23.911C22.2087 24.4287 23.0939 24.7063 24.0007 24.7063C24.9069 24.7067 25.7911 24.4273 26.5325 23.9062C27.8382 24.5712 28.8267 25.7272 29.2807 27.1204Z"
			fill="#F96E6F" />
		<path
			d="M26.485 20.6353C26.486 21.1269 26.341 21.6078 26.0686 22.017C25.7961 22.4262 25.4084 22.7454 24.9544 22.9342C24.5005 23.123 24.0008 23.1729 23.5185 23.0775C23.0362 22.9822 22.593 22.7459 22.245 22.3986C21.8971 22.0513 21.66 21.6086 21.5637 21.1265C21.4675 20.6443 21.5164 20.1445 21.7043 19.6902C21.8922 19.2359 22.2107 18.8476 22.6194 18.5744C23.0281 18.3011 23.5087 18.1553 24.0003 18.1553C24.3264 18.1547 24.6494 18.2183 24.9508 18.3427C25.2523 18.4671 25.5262 18.6496 25.757 18.88C25.9878 19.1103 26.1709 19.3839 26.2958 19.6851C26.4207 19.9863 26.485 20.3092 26.485 20.6353Z"
			fill="#F96E6F" />
	</g>
	<defs>
		<clipPath id="clip0_548_25708">
			<rect
				width="32"
				height="32"
				fill="white"
				transform="translate(8 8)" />
		</clipPath>
	</defs>
</svg>
