<script lang="ts">
import { insightsService } from '../../services/insights/data.service'
import SvgIcon from '../common/SvgIcon.svelte'
import { _ } from 'svelte-i18n'
import NameInitials from '../common/NameInitials.svelte'

export let element: any
</script>

<div class="profile-card grid">
	<div class="image flex align-center">
		<div
			class="{element.iconUrl
				? 'training-img-container'
				: element.icon
				? 'team-img-container'
				: 'dog-img-container'} flex align-center justify-center">
			{#if element.image}
				<img
					src={element.image}
					alt="dog profile" />
			{:else if element.icon}
				<SvgIcon
					width="50px"
					name={element.icon} />
			{:else if element.iconUrl}
				<img
					class="type-icon"
					src={element.iconUrl}
					alt="training profile"
					width="40px"
					height="40px" />
			{:else}
				<NameInitials name={element.name} />
			{/if}
		</div>
		<h1 class="title-name">{element.name}</h1>
	</div>
	<ul class="stats flex-column">
		<li class="hours flex align-center">
			{$_('time')}
			<h2>
				{insightsService.getTrainingHours(element.trainingTime, localStorage.getItem('language'))}
			</h2>
		</li>
		<li class="sessions flex align-center">
			{$_('sessions')}
			<h2>{element.trainingSessions}</h2>
		</li>
		<li class="sessions flex align-center">
			{$_('days')}
			<h2>{element.days}</h2>
		</li>
	</ul>
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.profile-card {
	@include mixins.card;
	grid-template-columns: 1.5fr 1fr;
	padding: 24px;

	.image {
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		padding: 12px;
		gap: 10px;

		.dog-img-container,
		.team-img-container {
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			border: 1px solid #ddd;
			border-radius: 50%;
			overflow: hidden;
			height: 80px;
			width: 80px;
		}

		.training-img-container {
			background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			aspect-ratio: 1/1;
			margin-right: 4px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			border: 1px solid #ddd;
			border-radius: 50%;
			overflow: hidden;

			img {
				filter: none;
				width: 40px;
				height: 40px;
			}
		}

		.team-img-container {
			margin-right: 10px;
		}

		.title-name {
			font-size: 24px;
		}
	}

	.stats {
		font-size: 16px;
		padding: 12px 22px;
		gap: 5px;
		text-transform: capitalize;

		h2 {
			font-family: Montserrat-Medium;
			font-weight: 100 !important;
		}

		.sessions,
		.days,
		.hours {
			justify-content: space-between;
		}
	}

	.btn-svg {
		box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

		&:not(:hover) {
			:global(svg) {
				fill: var(--clr-btn-svg);
			}
		}

		:global(svg) {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 1100px) {
		grid-template-columns: repeat(1, 1fr);
		padding: 0;
		gap: 12px;

		.image {
			border: none;
			padding: 17px;
			border-bottom: rgba(232, 232, 232, 0.832) 1px solid;

			.dog-img-container,
			.training-img-container {
				width: 40px;
				height: 40px;
			}

			.training-img-container img {
				width: 26px;
				height: 26px;
			}

			.title-name {
				font-family: Montserrat-Bold;
				font-size: 15px;
			}
		}

		.stats {
			padding: 12px 24px;
		}
	}
}
</style>
