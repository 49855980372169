<script lang="ts">
import { Router, Route } from 'svelte-navigator'
import PrivateRoute from './cmps/common/route/PrivateRoute.svelte'
import { routes } from './routes'
import { SvelteToast } from '@zerodevx/svelte-toast'
import ErrorAnimation from './cmps/common/animation/ErrorAnimation.svelte'
import { errorStore } from '../src/store/errorStore'
import { onMount } from 'svelte'


onMount(() => {
	window.onerror = (message, source, lineno, colno, error) => {
		//console.log('Global onerror caught:', { message, error })
		errorStore.showError('An unexpected error occurred', error)
		return true // Prevents the error from being propagated
	}

	window.onunhandledrejection = (event) => {
		//console.log('Global onunhandledrejection caught:', event.reason)
		errorStore.showError('An unhandled promise rejection occurred', event.reason)
		event.preventDefault() // Prevents the error from being propagated
	}
})
</script>

<SvelteToast />

<Router primary={false}>
	{#each routes.publicRoutes as route, index (`public-${index}-${route.path}`)}
		<Route
			path={route.path}
			component={route.component} />
	{/each}
	{#each routes.authProtectedRoutes as route, index (`private-${index}-${route.path}`)}
		<PrivateRoute path={route.path}>
			<svelte:component this={route.component} />
		</PrivateRoute>
	{/each}
	<Route component={ErrorAnimation} />
</Router>
