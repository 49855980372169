<script lang="ts">
import { Lottie } from 'lottie-svelte'
import { Link } from 'svelte-navigator'
import { _ } from 'svelte-i18n'
import { onDestroy, onMount } from 'svelte'
import type { Unsubscribe } from 'firebase/firestore'
import { onSnapshot } from 'firebase/firestore'
import { DogBaseNewsDB } from '../../services/database/dogBaseNews'
import type { DogBaseNewsModel } from '../../models/dogBaseNews.model'
import { writable } from 'svelte/store'
import CmpLoader from '../common/loader/CmpLoader.svelte'
import * as Sentry from '@sentry/browser'

let itemsPerPage = 5
let itemsOnPage = 0

let unsubscribe: Unsubscribe

onDestroy(() => unsubscribe && unsubscribe())

onMount(() => {
	loadPage()
})

async function loadPage() {
	try {
		messagesForDisplay.set(null)
		itemsOnPage += itemsPerPage
		const newsQuery = await DogBaseNewsDB.loadPage(itemsOnPage)
		unsubscribe = onSnapshot(newsQuery, (querySnapshot) => {
			const news: DogBaseNewsModel[] = []
			querySnapshot.forEach((doc) => {
				news.push(doc.data() as DogBaseNewsModel)
			})

			let numImages = news.filter((message) => message.published && message.iconLink).length

			if (numImages === 0) {
				messagesForDisplay.set(news)
			} else {
				imagesLoaded = 0
				news.forEach((message) => {
					if (message.published && message.iconLink) {
						const image = new Image()
						image.src = message.iconLink
						image.onload = image.onerror = () => {
							imagesLoaded++
							if (imagesLoaded === numImages) {
								messagesForDisplay.set(news)
							}
						}
					}
				})
			}
		})
	} catch (err: unknown) {
		Sentry.captureException(err)
	}
}

let imagesLoaded = 0
const messagesForDisplay = writable<DogBaseNewsModel[]>([])
</script>

<div class="messages">
	<header class="flex align-center space-between header">
		<h1>{$_('dogbase_messages')}</h1>
		{#if $messagesForDisplay && $messagesForDisplay.length > 0}
			<Link
				class="all-messages"
				to="#">
				{$_('see_all')}
			</Link>
		{/if}
	</header>
	{#if !$messagesForDisplay}
		<div class="preloader">
			<CmpLoader />
		</div>
	{:else if $messagesForDisplay.length === 0}
		<div class="flex-column align-center no-messages-animation">
			<div class="animation">
				<Lottie
					path="/assets/animations/no-messages.json"
					speed={0.5}
					loop={false} />
			</div>
			<p class="description">{$_('empty_inbox_message')}</p>
		</div>
	{:else}
		{#each $messagesForDisplay as message, idx}
			{#if message.published}
				<a
					href="http://{message.url}"
					target="_blank">
					<article class="flex align-center message">
						<img
							class="message-icon"
							src={message.iconLink}
							alt="message-icon"
							width="75px" />
						<div class="main">
							<header>
								<h1 class="title">{message.title}</h1>
							</header>
							<p class="content">
								{#if !message.url}
									{message.bodyMessage}
								{:else}
									{message.bodyMessage}
									<a
										href="http://{message.url}"
										target="_blank"
										class="message-url">{$_('read_more')}</a>
								{/if}
							</p>
						</div>
					</article>
				</a>
			{/if}
		{/each}
	{/if}
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.messages {
	@include mixins.card;
	flex: 1;

	.preloader {
		margin-top: -40px;
		height: 310px;
	}

	.header {
		padding: 17px 20px;
		color: #5e5873;

		h1 {
			font-size: 22px;
		}

		:global(.all-messages) {
			display: none;
			font-size: 12px;
			text-decoration: underline;
		}
	}

	.message {
		gap: 25px;
		height: 70px;
		margin-inline: 18px;
		margin-bottom: 12px;

		.svg-container {
			min-width: 71px;
			width: 71px;
			height: 66px;
			border-radius: 6px;
			padding: 14.5px 17px;
		}

		.main {
			.title,
			.content {
				display: -webkit-box;
				overflow: hidden;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				white-space: normal;
			}

			.title {
				-webkit-line-clamp: 1;
				font-family: Nunito-SemiBold;
				font-size: 16px;
				color: var(--clr-txt-dark-primary);
			}

			.content {
				-webkit-line-clamp: 2;
				font-size: 14px;
				color: var(--clr-txt-dark-secondary);
			}
		}

		.message-url {
			color: var(--clr-secondary);
		}
	}

	.no-messages-animation {
		margin-inline: 20px;
		height: 250px;

		.animation {
			width: 200px;
			height: 200px;
		}

		.description {
			color: var(--clr-txt-dark-secondary);
			margin-bottom: 16px;
		}
	}
}
</style>
