<script lang="ts">
import multiSelect from '../widgets/Multiselect.svelte'
import slider from '../widgets/Slider.svelte'
import toggleSwitch from '../widgets/Toggle.svelte'
import textField from '../widgets/Textarea.svelte'
import textToTags from '../widgets/TextToTags.svelte'
import distanceSlider from '../widgets/DistanceSlider.svelte'
import customStepper from '../widgets/CustomStepper.svelte'
import dropdownBottomSheet from '../widgets/DropdownBottomSheet.svelte'
import rangeSlider from '../widgets/RangeSlider.svelte'
import customTimer from '../widgets/CustomTimer.svelte'
import toggleSwitchTextField from '../widgets/ToggleSwitchTextField.svelte'
import toggleSwitchSlider from '../widgets/ToggleSwitchSlider.svelte'
import distanceStepper from '../widgets/DistanceStepper.svelte'
import timerSlider from '../widgets/TimerSlider.svelte'
import multiLevelDropdown from '../widgets/MultiLevelDropdown.svelte'
import bitLossECorrection from '../widgets/BitLossECorrection.svelte'
import dropdownText from '../widgets/DropdownText.svelte'
import dropdownTextToTags from '../widgets/DropdownTextToTags.svelte'
import weightSlider from '../widgets/WeightSlider.svelte'
import toggleTextToTags from '../widgets/ToggleSwitchTextToTags.svelte'
import toggleStepper from '../widgets/ToggleSwitchStepper.svelte'
import toggleDropdown from '../widgets/ToggleSwitchDropdown.svelte'
import toggleDistanceSlider from '../widgets/ToggleDistanceSlider.svelte'
import headerTile from '../widgets/HeaderTile.svelte'
import toggleMultiTextField from '../widgets/ToggleSwitchMultiTextField.svelte'
import multiSelectSlider from '../widgets/MultiSelectSlider.svelte'
import toggleFitness from '../widgets/ToggleFitness.svelte'
import areaSlider from '../widgets/AreaSlider.svelte'
import dropdownMultiSlider from '../widgets/DropdownMultiSlider.svelte'
import minutesToYearsStepper from '../widgets/MinutesToYearsStepper.svelte'
import temperatureSlider from '../widgets/TemperatureSlider.svelte'
import toggleMultiselect from '../widgets/ToggleMultiSelect.svelte'
import stepperMultiply from '../widgets/odorWidget/StepperMultiply.svelte'
import sizeSlider from '../widgets/SizeSlider.svelte'
import multiselectTextToTags from '../widgets/MultiselectTextToTags.svelte'
import toggleMultiSlider from '../widgets/ToggleMultislider.svelte'
import { onMount } from 'svelte'

let filled: boolean

// list of widgets
const widgets = [
	'slider',
	'toggleSwitch',
	'textField',
	'textToTags',
	'multiSelect',
	'distanceSlider',
	'customStepper',
	'dropdownBottomSheet',
	'rangeSlider',
	'customTimer',
	'toggleSwitchTextField',
	'distanceStepper',
	'toggleSwitchSlider',
	'timerSlider',
	'multiLevelDropdown',
	'bitLossECorrection',
	'dropdownText',
	'dropdownTextToTags',
	'weightSlider',
	'toggleTextToTags',
	'toggleStepper',
	'toggleDropdown',
	'toggleDistanceSlider',
	'headerTile',
	'toggleMultiTextField',
	'multiSelectSlider',
	'toggleFitness',
	'areaSlider',
	'dropdownMultiSlider',
	'minutesToYearsStepper',
	'temperatureSlider',
	'toggleMultiselect',
	'stepperMultiply',
	'sizeSlider',
	'multiselectTextToTags',
	'toggleMultiSlider'
]

// Widgets object used for special <svelte:component>
const components = {
	slider,
	toggleSwitch,
	textField,
	textToTags,
	multiSelect,
	distanceSlider,
	customStepper,
	dropdownBottomSheet,
	rangeSlider,
	customTimer,
	toggleSwitchTextField,
	distanceStepper,
	timerSlider,
	toggleSwitchSlider,
	multiLevelDropdown,
	bitLossECorrection,
	dropdownText,
	dropdownTextToTags,
	weightSlider,
	toggleTextToTags,
	toggleStepper,
	toggleDropdown,
	toggleDistanceSlider,
	headerTile,
	toggleMultiTextField,
	multiSelectSlider,
	toggleFitness,
	areaSlider,
	dropdownMultiSlider,
	minutesToYearsStepper,
	temperatureSlider,
	toggleMultiselect,
	stepperMultiply,
	sizeSlider,
	multiselectTextToTags,
	toggleMultiSlider
}

export let trainingType: any
export let presentTypes = []
export let isButtonDisabled = false
export let data: any = []

let typesWidgets: any

onMount(() => {
	loadPresentTrainingTypes()
})

function removeDuplicates(array: any[]) {
	return [...new Set(array)]
}

// Function that only loads trainingTypes that are using only widgets that are present in widgets list
function loadPresentTrainingTypes() {
	presentTypes = []
	typesWidgets = trainingType.map((training) => training.widgets)
	typesWidgets.forEach((type) =>
		type.forEach((widget) => {
			if (widgets.includes(widget.type)) {
				presentTypes.push(widget)
			}
		})
	)
	presentTypes = removeDuplicates(presentTypes)
}

// Function that checks if all necessary fields are filled
function validateAllFields(presentTypes: any) {
	isButtonDisabled = !presentTypes
		.map((type: any) => {
			return type.values.map((value: any) => {
				if (type.required) {
					return value.filled
				} else return undefined
			})
		})
		.flat()
		.filter((el: any) => el != undefined)
		.every((el: any) => el)
}

$: validateAllFields(presentTypes)
</script>

<!-- Here we loop through all trainingTypes and display each widget on the screen using special <svelte:component> -->
{#each presentTypes.sort((a, b) => a.orderBy - b.orderBy) as presentType}
	{#each presentType.values as value, i}
		<div class="widget flex-column">
			{#if presentType.name}
				<h3 class="title">{presentType.name}</h3>
			{/if}
			{#if presentType.description}
				<h5 class="description">{presentType.description}</h5>
			{/if}
			{#if data.length > 0}
				<svelte:component
					this={components[presentType.type]}
					bind:values={value}
					data={data.filter(
						(widget) => widget.id == presentType.id && widget.name == presentType.name
					)}
					id={presentType.id}
					bind:filled />
			{:else}
				<svelte:component
					this={components[presentType.type]}
					bind:values={value}
					id={presentType.id}
					bind:filled />
			{/if}
		</div>
	{/each}
{/each}

<style lang="scss">
.widget {
	gap: 10px;
	.title {
		color: var(--neutral-05, #33383f);
		font-size: 13px;
		font-family: Montserrat-Medium;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.14px;
	}

	.title {
		margin: 0;
		padding: 0;
	}

	.description {
		font-size: 0.75rem;
		font-weight: normal;
		color: rgb(185, 181, 181);
		margin-bottom: 12px;
	}
}
</style>
