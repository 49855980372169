<script lang="ts">
import { onMount } from 'svelte'
import * as Highcharts from 'highcharts'
import { _ } from 'svelte-i18n'
import { insightsService } from '../../services/insights/data.service'

export let data: any
export let infoId: string = undefined

let chart: Highcharts.Chart

onMount(() => {
	if (data) {
		const trainingData = insightsService.getSuccessTrainings(data, infoId)
		createChart(trainingData)
	}
})

function createChart(
	trainingData: Map<string, { Successful: number; Unsuccessful: number; NeedsImprovement: number }>) {
	const colorScheme = ['#3B9463', '#FE9803', '#D65E5E']
	const chartConfig: Highcharts.Options = {
		chart: {
			type: 'column'
		},
		title: {
			text: ''
		},
		xAxis: {
			categories: Array.from(trainingData.keys()),
			lineWidth: 0.1
		},
		yAxis: {
			min: 0,
			title: {
				text: 'Percentage'
			},
			gridLineWidth: 0.6,
			minorGridLineWidth: 0
		},
		tooltip: {
			headerFormat: '<b>{point.x}</b><br/>',
			pointFormat:
				'{series.name}: {point.y} trainings ({point.percentage:.1f}%)<br/>Total: {point.stackTotal} trainings'
		},
		plotOptions: {
			column: {
				stacking: 'percent',
				pointWidth: 48
			}
		},
		legend: {
			verticalAlign: 'top',
			y: -10
		},
		series: (
			[
				{
					type: 'column',
					name: 'Successful',
					data: Array.from(trainingData.values()).map((item) => item.Successful),
					color: colorScheme[0]
				},
				{
					type: 'column',
					name: 'Needs Improvement',
					data: Array.from(trainingData.values()).map((item) => item.NeedsImprovement),
					color: colorScheme[1]
				},
				{
					type: 'column',
					name: 'Unsuccessful',
					data: Array.from(trainingData.values()).map((item) => item.Unsuccessful),
					color: colorScheme[2]
				}
			] as Highcharts.SeriesOptionsType[]
		).reverse()
	}

	chart = Highcharts.chart('success-training', chartConfig)
}
</script>

<h1 class="title">{$_(`insights_pages.result_by_training_type`)}</h1>
<div class="chart-container">
	<div id="success-training" />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';
.chart-container {
	@include mixins.card;
	padding: 10px 8px 0 8px;
	min-height: 300px;
}

.title {
	font-family: Montserrat-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
</style>
