<script lang="ts">
import { Lottie } from 'lottie-svelte'
import { _ } from 'svelte-i18n'
import { currAccount } from '../../../store/stores'
import { Col, Container, Row } from 'sveltestrap'
</script>

<Container class="no-training-animation d-flex flex-column align-items-center w-25 mb-3">
	<Row>
		<div class="animation">
			<Lottie path="/assets/animations/no-training-animation.json" />
		</div>
	</Row>
	<h1>{$_('nothing_here')}</h1>
	<p class="mb-2">{$currAccount.dogIds.length > 0 ? $_('add_first_training') : $_('add_first_dog')}</p>
</Container>
