<script>
import { _ } from 'svelte-i18n'
import { createEventDispatcher } from 'svelte'

const dispatch = createEventDispatcher()

export let breedImage = ''

function close() {
	dispatch('close')
}
</script>

<button
	id="background-overlay"
	on:click={close} />

<div class="dog-breed-modal">
	<img
		src={breedImage}
		alt="Dog" />
</div>

<style lang="scss">
.dog-breed-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	border-radius: 16px;
	border: 4px solid var(--clr-primary);
	overflow: hidden;
	border-radius: 8px;
	z-index: 101;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		background-color: red;
		max-height: 450px;
	}
}

#background-overlay {
	all: unset;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
	&:hover {
		cursor: pointer;
	}
}
</style>
