<svg
	width="25"
	height="25"
	viewBox="0 0 24 24"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_0_1041)">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.8613 3.0409L21.0579 10.5374C21.0585 10.5379 21.059 10.5385 21.0595 10.539C21.5396 11.0341 21.8 11.703 21.8 12.39V19.13C21.8 20.5547 20.6831 21.76 19.25 21.76H4.85001C3.41693 21.76 2.29999 20.5547 2.29999 19.13V12.39C2.29999 11.7033 2.56019 11.0346 3.03996 10.5395L10.2282 3.03133C10.2585 2.99968 10.2912 2.97111 10.3257 2.94574C11.3333 2.00718 12.8944 2.03284 13.8613 3.0409ZM11.2364 4.14737C11.2613 4.1276 11.2851 4.1061 11.3076 4.0829C11.7253 3.65352 12.376 3.65912 12.7787 4.07908L19.9789 11.5794L19.982 11.5826C20.1809 11.7873 20.3 12.0777 20.3 12.39V19.13C20.3 19.7853 19.7969 20.26 19.25 20.26H4.85001C4.30308 20.26 3.79999 19.7852 3.79999 19.13V12.39C3.79999 12.0777 3.91903 11.7873 4.11795 11.5826L11.2364 4.14737Z"
			fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.44996 14.3099C9.44998 12.8961 10.5961 11.75 12.0099 11.75C13.4238 11.75 14.5699 12.8961 14.5699 14.31V15.19C14.5699 16.6039 13.4238 17.75 12.0099 17.75C10.5961 17.75 9.44993 16.6038 9.44995 15.19L9.44996 14.3099ZM12.0099 13.25C11.4245 13.25 10.95 13.7246 10.95 14.31L10.95 15.19C10.9499 15.7754 11.4245 16.25 12.0099 16.25C12.5954 16.25 13.0699 15.7754 13.0699 15.19V14.31C13.0699 13.7246 12.5954 13.25 12.0099 13.25Z"
			fill="currentColor" />
	</g>
	<defs>
		<clipPath id="clip0_0_1041">
			<rect
				width="24"
				height="24"
				fill="currentColor" />
		</clipPath>
	</defs>
</svg>
