<script lang="ts">
import Toggle from './Toggle.svelte'
import TextToTags from './TextToTags.svelte'
import { toggleTextToTags } from '../../../store/trainingWidgetsStore'

export let values: {
	labels: string[]
	selectedIndex: number
	trigger: number
	value: {
		selectedIndex: number
		answer: string
		labels: string[]
		tags: string[]
	}
}
export let data = []
export let id: string

let toggleValues: any
let textToTagsValues: any

if (!$toggleTextToTags[id]) {
	$toggleTextToTags[id] = {
		labels: [],
		selectedIndex: 0,
		trigger: 0,
		value: {
			selectedIndex: 0,
			answer: '',
			labels: [],
			tags: []
		}
	}
}

if (data.length > 0 && $toggleTextToTags[id].value.answer == '') {
	toggleValues = {
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
} else if ($toggleTextToTags[id].value.tags) {
	toggleValues = {
		labels: values.labels,
		selectedIndex: $toggleTextToTags[id].value.selectedIndex,
		value: $toggleTextToTags[id].value.selectedIndex
	}
} else {
	toggleValues = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
}

if (data.length > 0 && $toggleTextToTags[id].value.answer == '') {
	textToTagsValues = {
		tags: data[0].value.tags,
		value: data[0].value.tags,
		filled: false
	}
	values.value = {
		answer: data[0].value.answer,
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		tags: data[0].value.tags
	}
} else if ($toggleTextToTags[id].value.tags) {
	textToTagsValues = {
		tags: $toggleTextToTags[id].value.tags,
		value: $toggleTextToTags[id].value.tags,
		filled: false
	}
	values.value = {
		answer: $toggleTextToTags[id].value.answer,
		labels: values.labels,
		selectedIndex: $toggleTextToTags[id].value.selectedIndex,
		tags: $toggleTextToTags[id].value.tags
	}
} else {
	textToTagsValues = {
		tags: values.value.tags,
		value: [],
		filled: false
	}
	values.value = {
		answer: '',
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		tags: textToTagsValues.value
	}
}

$: if (toggleValues.selectedIndex === values.trigger) {
	values.value.selectedIndex = toggleValues.selectedIndex
	values.value.tags = textToTagsValues.value
	values.value.answer = values.labels[toggleValues.selectedIndex]
	$toggleTextToTags[id] = values
} else {
	values.value.selectedIndex = toggleValues.selectedIndex
	values.value.answer = values.labels[toggleValues.selectedIndex]
	values.value.tags = []
	$toggleTextToTags[id] = values
}
</script>

<Toggle bind:values={toggleValues} />

{#if toggleValues.selectedIndex === values.trigger}
	<TextToTags bind:values={textToTagsValues} />
{/if}
