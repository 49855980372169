<svg
	width="20"
	height="20"
	viewBox="0 0 20 20"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_0_371)">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.5511 2.53411L17.5483 8.7812C17.5487 8.78164 17.5492 8.78209 17.5496 8.78254C17.9497 9.1951 18.1667 9.75257 18.1667 10.325V15.9417C18.1667 17.129 17.2359 18.1334 16.0417 18.1334H4.04167C2.84744 18.1334 1.91666 17.129 1.91666 15.9417V10.325C1.91666 9.75277 2.13349 9.1955 2.5333 8.78297L8.52354 2.52614C8.54879 2.49976 8.57598 2.47596 8.60472 2.45481C9.44442 1.67268 10.7453 1.69406 11.5511 2.53411ZM9.36365 3.45617C9.38438 3.4397 9.40423 3.42178 9.42303 3.40245C9.77104 3.04463 10.3133 3.0493 10.6489 3.39926L16.6491 9.64952L16.6517 9.65218C16.8175 9.82281 16.9167 10.0648 16.9167 10.325V15.9417C16.9167 16.4878 16.4974 16.8834 16.0417 16.8834H4.04167C3.5859 16.8834 3.16666 16.4877 3.16666 15.9417V10.325C3.16666 10.0648 3.26586 9.82281 3.43162 9.65218L9.36365 3.45617Z"
			fill="#FE9803" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.87498 11.925C7.87499 10.7468 8.83011 9.79169 10.0083 9.79169C11.1865 9.79169 12.1416 10.7468 12.1416 11.925V12.6584C12.1416 13.8366 11.1865 14.7917 10.0083 14.7917C8.83008 14.7917 7.87495 13.8365 7.87497 12.6583L7.87498 11.925ZM10.0083 11.0417C9.52046 11.0417 9.12498 11.4372 9.12498 11.925L9.12497 12.6584C9.12496 13.1462 9.52044 13.5417 10.0083 13.5417C10.4961 13.5417 10.8916 13.1462 10.8916 12.6584V11.925C10.8916 11.4372 10.4961 11.0417 10.0083 11.0417Z"
			fill="black" />
	</g>
	<defs>
		<clipPath id="clip0_0_371">
			<rect
				width="20"
				height="20"
				fill="white" />
		</clipPath>
	</defs>
</svg>
