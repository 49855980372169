<script lang="ts">
import { onMount } from 'svelte'
import Highcharts from 'highcharts'
import HighchartsMap from 'highcharts/modules/map'
import { _ } from 'svelte-i18n'

import 'highcharts/modules/exporting' // Import the exporting module

export let data

let container: HTMLDivElement
let chart: Highcharts.Chart

onMount(async () => {
	if (data) {
		const locationData = getTrainingLocationData(data)

		const topology = await fetch('https://code.highcharts.com/mapdata/custom/world.topo.json').then(
			(response) => response.json()
		)

		await createChart(locationData, topology)
	}
})

function getTrainingLocationData(data) {
	let trainingLocations = []

	data.forEach((training) => {
		const rawData = JSON.parse(training['data'])
		const location = {
			lat: rawData.location.geoPoints.latitude,
			lon: rawData.location.geoPoints.longitude,
			name: rawData.dog.name + ' ',
			address: rawData.location.address
		}

		if (location.lat !== 0 || location.lon !== 0) {
			trainingLocations.push(location)
		}
	})

	return trainingLocations
}

async function createChart(dataForDisplay, topology) {
	HighchartsMap(Highcharts)

	const chartOptions: Highcharts.Options = {
		chart: {
			map: topology
		},
		title: {
			text: ''
		},
		mapNavigation: {
			enabled: true,
			buttonOptions: {
				verticalAlign: 'bottom'
			}
		},
		tooltip: {
			headerFormat: '',
			pointFormat: '<b>{point.name}</b><br>{point.address}'
		},
		series: [
			{
				type: 'map',
				name: 'Europe',
				borderColor: '#A0A0A0',
				nullColor: 'rgba(177, 244, 177, 0.4)',
				showInLegend: false
			},
			{
				type: 'mappoint',
				colorKey: 'clusterPointsAmount',
				name: 'Cities',
				data: dataForDisplay,
				color: '#e76f51',
				showInLegend: false
			}
		]
	}

	chart = Highcharts.mapChart(container, chartOptions)
}
</script>

<h1 class="title">{$_('training_location')}</h1>
<div class="chart-container">
	<div bind:this={container} />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.chart-container {
	@include mixins.card;
	#container {
		min-width: 310px;
		max-width: 800px;
		margin: 0 auto;
	}
}

.loading {
	margin-top: 10em;
	text-align: center;
	color: gray;
}

.title {
	font-family: Montserrat-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
</style>
