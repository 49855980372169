<script lang="ts">
import { onMount } from 'svelte'
import { insightsService } from '../../services/insights/data.service'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting'
import { utilService } from '../../utils/util.service'
import { _ } from 'svelte-i18n'

export let infoId = ''
export let data: any

let trainingData = undefined
let sessionKeys

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	if (data) {
		trainingData = insightsService.getEventPercentage(data, infoId)
		createChart(trainingData)
	}
})

function createChart(trainingData) {
	if (sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing') {
		exporting(Highcharts)
	}

	const filteredData = Array.from(trainingData.entries()).filter(([key, value]) => value > 0)

	Highcharts.chart('event-chart-container', {
		chart: {
			type: 'pie',
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			height: 320,
			margin: [0, 0, 0, 0]
		},
		title: {
			text: ''
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					distance: -35,
					connectorPadding: 0,
					style: {
						fontFamily: 'Montserrat-Bold',
						color: 'white',
						textOutline: 'none',
						fontSize: '12px'
					},
					formatter: function () {
						return this.point.percentage >= 5
							? `<b>${Math.round(this.point.percentage)}%</b>`
							: undefined
					}
				},
				showInLegend: true,
				startAngle: -90,
				endAngle: 90,
				center: ['50%', '75%']
			}
		},
		series: [
			{
				type: 'pie',
				name: 'Total Trainings',
				data: filteredData.map(([key, value]) => ({
					name: utilService.capitalizeFirstLetter(key),
					y: value
				})),
				innerSize: '50%'
			}
		],
		legend: {
			enabled: true,
			align: 'left',
			verticalAlign: 'top',
			labelFormatter: function () {
				return utilService.capitalizeFirstLetter(this.name)
			}
		}
	})
}
</script>

<h1 class="title">{$_(`insights_pages.total_trainings_by_event`)}</h1>
<div class="chart-container">
	<div id="event-chart-container" />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';
.chart-container {
	@include mixins.card;
	padding: 0 8px;

	#event-chart-container {
		height: 300px;
	}
}

.title {
	font-family: Montserrat-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
</style>
