<script lang="ts">
import { _ } from 'svelte-i18n'

export let values: {
	initialTime: {
		hours: number
		minutes: number
	}
	snapToMins: number
	value: number
} = {
	initialTime: {
		hours: 0,
		minutes: 0
	},
	snapToMins: 1,
	value: 0
}

export let data = []

if (data.length > 0) {
	values.initialTime = {
		hours: Math.floor(data[0].value / 60),
		minutes: data[0].value - Math.floor(data[0].value / 60) * 60
	}
}

function incrementHours(event: Event) {
	event.preventDefault()
	if (values.initialTime.hours == null) {
		values.initialTime.hours = 0
		values.initialTime.hours = (values.initialTime.hours + 1) % 24 // Limit to 0-23
	} else {
		values.initialTime.hours = (values.initialTime.hours + 1) % 24 // Limit to 0-23
	}
}

function decrementHours(event: Event) {
	event.preventDefault()
	if (values.initialTime.hours == null) {
		values.initialTime.hours = 0
		values.initialTime.hours = (values.initialTime.hours - 1 + 24) % 24 // Handle negative wrapping
	} else {
		values.initialTime.hours = (values.initialTime.hours - 1 + 24) % 24 // Handle negative wrapping
	}
}

function incrementMinutes(event: Event) {
	event.preventDefault()
	if (values.initialTime.minutes == null) {
		values.initialTime.minutes = 0
		values.initialTime.minutes = (values.initialTime.minutes + values.snapToMins) % 60 // Limit to 0-59
	} else {
		values.initialTime.minutes = (values.initialTime.minutes + values.snapToMins) % 60 // Limit to 0-59
	}
}

function decrementMinutes(event: Event) {
	event.preventDefault()
	if (values.initialTime.minutes == null) {
		values.initialTime.minutes = 0
		values.initialTime.minutes = (values.initialTime.minutes - values.snapToMins + 60) % 60 // Handle negative wrapping
	} else {
		values.initialTime.minutes = (values.initialTime.minutes - values.snapToMins + 60) % 60 // Handle negative wrapping
	}
}

$: {
	if (values.initialTime.hours > 23) {
		values.initialTime.hours = 23
	} else if (values.initialTime.minutes > 59) {
		values.initialTime.minutes = 59
	}
	values.value = values.initialTime.hours * 60 + values.initialTime.minutes
}
</script>

<div class="timer-container">
	<div class="input-container flex">
		<button
			class="arrow-button"
			on:click|preventDefault={decrementHours}>
			<svg
				class="arrow-icon"
				viewBox="0 5 24 24">
				<path d="M8 15l4 4 4-4H8z" />
			</svg>
		</button>
		<div class="input flex justify-center align-center">{values.initialTime.hours} {$_('h')}</div>
		<button
			class="arrow-button"
			on:click|preventDefault={incrementHours}>
			<svg
				class="arrow-icon"
				viewBox="0 -5 24 24">
				<path d="M8 9l4-4 4 4H8z" />
			</svg>
		</button>
	</div>
	<div class="input-container flex">
		<button
			class="arrow-button"
			on:click|preventDefault={decrementMinutes}>
			<svg
				class="arrow-icon"
				viewBox="0 5 24 24">
				<path d="M8 15l4 4 4-4H8z" />
			</svg>
		</button>
		<div class="input flex justify-center align-center">
			{values.initialTime.minutes}
			{$_('min')}
		</div>
		<button
			class="arrow-button"
			on:click|preventDefault={incrementMinutes}>
			<svg
				class="arrow-icon"
				viewBox="0 -5 24 24">
				<path d="M8 9l4-4 4 4H8z" />
			</svg>
		</button>
	</div>
</div>

<style lang="scss">
.timer-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.input-container {
		gap: 3.5px;
	}

	.input {
		text-align: center;
		height: 25px;
		padding: 9px 13px;
		width: 85px;
		border-radius: 2px;
		border: 1px solid #e6e8f0;
		background: var(--primary-white, #fff);
	}

	.arrow-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 25px;
		height: 25px;
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.08);
	}

	.arrow-button:hover {
		background-color: #f1f1f1;
	}
}
</style>
