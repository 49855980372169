import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { firebaseConfig } from './firebase.config'
import { getPerformance } from "firebase/performance";

const FirebaseApp = initializeApp(firebaseConfig)
const Auth = getAuth(FirebaseApp)
const Firestore = getFirestore(FirebaseApp)
const perf = getPerformance(FirebaseApp);

export { FirebaseApp, Firestore, Auth, perf }
