<script lang="ts">
import Toggle from './Toggle.svelte'
import Slider from './Slider.svelte'
import DistanceSlider from './DistanceSlider.svelte'
import Multiselect from './Multiselect.svelte'
import MultiselectTextToTags from './MultiselectTextToTags.svelte'
import TemperatureSlider from './TemperatureSlider.svelte'
import CustomStepper from './CustomStepper.svelte'
import { toggleMultiSlider } from '../../../store/trainingWidgetsStore'

export let values: {
	selectedIndex: number
	labels: string[]
	trigger: number
	items: any[]
	filled: boolean
	value: {
		answer: string
		labels: string[]
		selectedIndex: number
		sliderValue: {
			selectedValue: number
			label: string
		}
		distanceSliderValue: {
			distance: number
			label: 'meters' | 'feet'
		}
		multiselectValue: string[]
		multiselectTextToTagsValue: {
			multiselectValue: string[]
			tags: string[]
		}
		temperatureSliderValue: {
			temperature: number
			label: 'celsius' | 'fahrenheit'
		}
		customStepperValue: {
			selectedValue: number
			label: string
		}
	}
}
export let data = []
export let id: string

let unitsOfMeasurement: string
if (!$toggleMultiSlider[id]) {
	$toggleMultiSlider[id] = {
		selectedIndex: values.selectedIndex,
		labels: values.labels,
		trigger: values.trigger,
		items: values.items,
		filled: false,
		value: {
			answer: '',
			labels: [],
			selectedIndex: values.selectedIndex,
			sliderValue: {
				selectedValue: 0,
				label: ''
			},
			distanceSliderValue: {
				distance: 0,
				label: 'meters'
			},
			multiselectValue: [],
			multiselectTextToTagsValue: {
				multiselectValue: [],
				tags: []
			},
			temperatureSliderValue: {
				temperature: 0,
				label: 'celsius'
			},
			customStepperValue: {
				selectedValue: 0,
				label: ''
			}
		}
	}
}

$: {
	if (
		temperatureSliderValues.value.temperature != 0 &&
		sliderValues.value.selectedValue != 0 &&
		distanceSliderValues.value.distance != 0 &&
		multiselectValues.value.length != 0 &&
		customStepperValues.value.selectedValue != 0 &&
		multiselectTextToTagsValues.value.multiselectValue.length != 0
	) {
		values.filled = true
	} else {
		values.filled = false
	}
}

let sliderValues: {
	min: number
	max: number
	divisions: number
	defaultSliderValue?: number
	label?: string
	value: {
		selectedValue: number
		label: string
	}
}

let customStepperValues: {
	stepAmount: number
	label: string
	filled: boolean
	value: {
		selectedValue: number
		label: string
	}
}

let distanceSliderValues: {
	minMeters: number
	maxMeters: number
	minFeet: number
	maxFeet: number
	divisions: number
	defaultSliderValue?: number
	label?: 'meters' | 'feet' | 'kilometers' | 'miles'
	value: {
		distance: number
		label: 'meters' | 'feet'
	}
}

localStorage.getItem('measurement-type')
	? (unitsOfMeasurement = localStorage.getItem('measurement-type'))
	: (unitsOfMeasurement = 'metric')

let multiselectValues: {
	items: string[]
	value: string[]
	returnedValue?: { value: string; label: string }[]
	filled: boolean
}

let multiselectTextToTagsValues: {
	items: string[]
	trigger: number
	value: {
		multiselectValue: string[]
		tags: string[]
	}
}

let temperatureSliderValues: {
	minCelcium: number
	maxCelcium: number
	minFahrenheit: number
	maxFahrenheit: number
	divisions: number
	defaultSliderValue?: number
	label?: 'celsius' | 'fahrenheit'
	value: {
		temperature: number
		label: 'celsius' | 'fahrenheit'
	}
}

let toggleValues: {
	labels: string[]
	selectedIndex: number
	value: number
}

if (data.length > 0 && $toggleMultiSlider[id].value.answer == '') {
	toggleValues = {
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
	sliderValues = {
		min: values.items[0].min,
		max: values.items[0].max,
		divisions: values.items[0].divisions,
		defaultSliderValue: data[0].value.sliderValue.selectedValue,
		label: data[0].value.sliderValue.label,
		value: {
			selectedValue: data[0].value.sliderValue.selectedValue,
			label: data[0].value.sliderValue.label
		}
	}
	distanceSliderValues = {
		minMeters: values.items[2].minMeters,
		maxMeters: values.items[2].maxMeters,
		minFeet: values.items[2].minFeet,
		maxFeet: values.items[2].maxFeet,
		divisions: values.items[2].divisions,
		defaultSliderValue: data[0].value.distanceSliderValue.distance,
		label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet',
		value: {
			distance: data[0].value.distanceSliderValue.distance,
			label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
		}
	}
	customStepperValues = {
		stepAmount: values.items[1].stepAmount,
		label: values.items[1].label,
		filled: false,
		value: {
			selectedValue: data[0].value.customStepperValue.selectedValue,
			label: data[0].value.customStepperValue.label
		}
	}
	multiselectValues = {
		items: values.items[3].items,
		value: [],
		returnedValue: data[0].value.multiselectValue.map((value) => {
			return { value: value, label: value }
		}),
		filled: false
	}
	multiselectTextToTagsValues = {
		items: values.items[4].items,
		trigger: values.items[4].trigger,
		value: {
			multiselectValue: data[0].value.multiselectTextToTagsValue.multiselectValue,
			tags: data[0].value.multiselectTextToTagsValue.tags
		}
	}
	temperatureSliderValues = {
		minCelcium: values.items[5].minCelcium,
		maxCelcium: values.items[5].maxCelcium,
		minFahrenheit: values.items[5].minFahrenheit,
		maxFahrenheit: values.items[5].maxFahrenheit,
		divisions: values.items[5].divisions,
		defaultSliderValue: data[0].value.temperatureSliderValue.temperature,
		label: unitsOfMeasurement == 'metric' ? 'celsius' : 'fahrenheit',
		value: {
			temperature: data[0].value.temperatureSliderValue.temperature,
			label: unitsOfMeasurement == 'metric' ? 'celsius' : 'fahrenheit'
		}
	}
	values.value = {
		answer: data[0].value.answer,
		labels: data[0].value.labels,
		selectedIndex: data[0].value.selectedIndex,
		sliderValue: {
			selectedValue: data[0].value.sliderValue.selectedIndex,
			label: data[0].value.sliderValue.label
		},
		distanceSliderValue: {
			distance: data[0].value.distanceSliderValue.distance,
			label: data[0].value.distanceSliderValue.label
		},
		multiselectValue: data[0].value.multiselectValue,
		multiselectTextToTagsValue: {
			multiselectValue: data[0].value.multiselectTextToTagsValue.multiselectValue,
			tags: data[0].value.multiselectTextToTagsValue.tags
		},
		temperatureSliderValue: {
			temperature: data[0].value.temperatureSliderValue.temperature,
			label: data[0].value.temperatureSliderValue.label
		},
		customStepperValue: {
			selectedValue: data[0].value.customStepperValue.selectedValue,
			label: data[0].value.customStepperValue.label
		}
	}
} else if ($toggleMultiSlider[id]) {
	toggleValues = {
		labels: values.labels,
		selectedIndex: $toggleMultiSlider[id].value.selectedIndex,
		value: $toggleMultiSlider[id].value.selectedIndex
	}
	sliderValues = {
		min: $toggleMultiSlider[id].items[0].min,
		max: $toggleMultiSlider[id].items[0].max,
		divisions: $toggleMultiSlider[id].items[0].divisions,
		defaultSliderValue: $toggleMultiSlider[id].value.sliderValue.selectedValue,
		label: $toggleMultiSlider[id].items[0].label,
		value: {
			selectedValue: $toggleMultiSlider[id].value.sliderValue.selectedValue,
			label: $toggleMultiSlider[id].items[0].label
		}
	}
	customStepperValues = {
		stepAmount: values.items[1].stepAmount,
		label: values.items[1].label,
		filled: false,
		value: {
			selectedValue: $toggleMultiSlider[id].value.customStepperValue.selectedValue,
			label: values.items[1].label
		}
	}
	distanceSliderValues = {
		minMeters: values.items[2].minMeters,
		maxMeters: values.items[2].maxMeters,
		minFeet: values.items[2].minFeet,
		maxFeet: values.items[2].maxFeet,
		divisions: values.items[2].divisions,
		defaultSliderValue: $toggleMultiSlider[id].value.distanceSliderValue.distance,
		label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet',
		value: {
			distance: $toggleMultiSlider[id].value.distanceSliderValue.distance,
			label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
		}
	}
	multiselectValues = {
		items: values.items[3].items,
		value: [],
		returnedValue: $toggleMultiSlider[id].value.multiselectValue.map((value) => {
			return { value: value, label: value }
		}),
		filled: false
	}
	multiselectTextToTagsValues = {
		items: values.items[4].items,
		trigger: values.items[4].trigger,
		value: {
			multiselectValue: $toggleMultiSlider[id].value.multiselectTextToTagsValue.multiselectValue,
			tags: $toggleMultiSlider[id].value.multiselectTextToTagsValue.tags
		}
	}
	temperatureSliderValues = {
		minCelcium: values.items[5].minCelcium,
		maxCelcium: values.items[5].maxCelcium,
		minFahrenheit: values.items[5].minFahrenheit,
		maxFahrenheit: values.items[5].maxFahrenheit,
		divisions: values.items[5].divisions,
		defaultSliderValue: $toggleMultiSlider[id].value.temperatureSliderValue.temperature,
		label: unitsOfMeasurement == 'metric' ? 'celsius' : 'fahrenheit',
		value: {
			temperature: $toggleMultiSlider[id].value.temperatureSliderValue.temperature,
			label: unitsOfMeasurement == 'metric' ? 'celsius' : 'fahrenheit'
		}
	}
	values.value = {
		answer: $toggleMultiSlider[id].value.answer,
		labels: $toggleMultiSlider[id].value.labels,
		selectedIndex: $toggleMultiSlider[id].value.selectedIndex,
		sliderValue: {
			selectedValue: $toggleMultiSlider[id].value.sliderValue.selectedIndex,
			label: $toggleMultiSlider[id].value.sliderValue.label
		},
		distanceSliderValue: {
			distance: $toggleMultiSlider[id].value.distanceSliderValue.distance,
			label: $toggleMultiSlider[id].value.distanceSliderValue.label
		},
		multiselectValue: $toggleMultiSlider[id].value.multiselectValue,
		multiselectTextToTagsValue: {
			multiselectValue: $toggleMultiSlider[id].value.multiselectTextToTagsValue.multiselectValue,
			tags: $toggleMultiSlider[id].value.multiselectTextToTagsValue.tags
		},
		temperatureSliderValue: {
			temperature: $toggleMultiSlider[id].value.temperatureSliderValue.temperature,
			label: $toggleMultiSlider[id].value.temperatureSliderValue.label
		},
		customStepperValue: {
			selectedValue: $toggleMultiSlider[id].value.customStepperValue.selectedValue,
			label: $toggleMultiSlider[id].value.customStepperValue.label
		}
	}
} else {
	toggleValues = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
	sliderValues = {
		min: values.items[0].min,
		max: values.items[0].max,
		divisions: values.items[0].divisions,
		defaultSliderValue: values.items[0].defaultSliderValue,
		label: values.items[0].label,
		value: {
			selectedValue: values.items[0].defaultSliderValue,
			label: values.items[0].label
		}
	}
	customStepperValues = {
		stepAmount: values.items[1].stepAmount,
		label: values.items[1].label,
		filled: false,
		value: {
			selectedValue: 0,
			label: values.items[1].label
		}
	}
	distanceSliderValues = {
		minMeters: values.items[2].minMeters,
		maxMeters: values.items[2].maxMeters,
		minFeet: values.items[2].minFeet,
		maxFeet: values.items[2].maxFeet,
		divisions: values.items[2].divisions,
		defaultSliderValue: values.items[2].defaultSliderValue,
		label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet',
		value: {
			distance: values.items[2].defaultSliderValue,
			label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
		}
	}
	multiselectValues = {
		items: values.items[3].items,
		value: [],
		filled: false
	}
	multiselectTextToTagsValues = {
		items: values.items[4].items,
		trigger: values.items[4].trigger,
		value: {
			multiselectValue: [],
			tags: []
		}
	}
	temperatureSliderValues = {
		minCelcium: values.items[5].minCelcium,
		maxCelcium: values.items[5].maxCelcium,
		minFahrenheit: values.items[5].minFahrenheit,
		maxFahrenheit: values.items[5].maxFahrenheit,
		divisions: values.items[5].divisions,
		defaultSliderValue: values.items[5].defaultSliderValue,
		label: unitsOfMeasurement == 'metric' ? 'celsius' : 'fahrenheit',
		value: {
			temperature: values.items[5].defaultSliderValue,
			label: unitsOfMeasurement == 'metric' ? 'celsius' : 'fahrenheit'
		}
	}
}

$: {
	values.value.selectedIndex = toggleValues.selectedIndex
	if (values.value.selectedIndex === values.trigger) {
		values.value.sliderValue = sliderValues.value
		values.value.customStepperValue = customStepperValues.value
		values.value.distanceSliderValue = distanceSliderValues.value
		values.value.multiselectValue = multiselectValues.value
		values.value.multiselectTextToTagsValue = multiselectTextToTagsValues.value
		values.value.temperatureSliderValue = temperatureSliderValues.value
		$toggleMultiSlider[id] = values
	} else {
		values.value.sliderValue = {
			selectedValue: null,
			label: ''
		}
		values.value.customStepperValue = {
			selectedValue: null,
			label: ''
		}
		values.value.distanceSliderValue = {
			distance: null,
			label: 'meters'
		}
		values.value.multiselectValue = []
		values.value.multiselectTextToTagsValue = { multiselectValue: [], tags: [] }
		values.value.temperatureSliderValue = {
			temperature: null,
			label: 'celsius'
		}
	}
}
</script>

<Toggle bind:values={toggleValues} />

<div class="subwidgets-container flex-column">
	{#if values.value.selectedIndex === values.trigger}
		{#if values.items[0].isShown}
			<h4 class="title">
				{values.items[0].name}
			</h4>
			<Slider bind:values={sliderValues} />
		{/if}
		{#if values.items[1].isShown}
			<h4 class="title">
				{values.items[1].name}
			</h4>
			<CustomStepper
				bind:values={customStepperValues}
				id={'huy'} />
		{/if}
		{#if values.items[2].isShown}
			<h4 class="title">
				{values.items[2].name}
			</h4>
			<DistanceSlider bind:values={distanceSliderValues} />
		{/if}
		{#if values.items[3].isShown}
			<h4 class="title">
				{values.items[3].name}
			</h4>
			<Multiselect bind:values={multiselectValues} />
		{/if}
		{#if values.items[4].isShown}
			<h4 class="title">
				{values.items[4].name}
			</h4>
			{#if data.length > 0}
				<MultiselectTextToTags
					bind:values={multiselectTextToTagsValues}
					{id}
					data={[
						{
							value: {
								tags: data[0].value.multiselectTextToTagsValue.tags,
								multiselectValue: data[0].value.multiselectTextToTagsValue.multiselectValue
							}
						}
					]} />
			{:else}
				<MultiselectTextToTags
					bind:values={multiselectTextToTagsValues}
					{id} />
			{/if}
		{/if}
		{#if values.items[5].isShown}
			<h4 class="title">
				{values.items[5].name}
			</h4>
			<TemperatureSlider bind:values={temperatureSliderValues} />
		{/if}
	{/if}
</div>

<style>
.subwidgets-container {
	gap: 16px;
	margin-left: 0.75rem;
}

.title {
	color: var(--neutral-05, #33383f);
	font-size: 13px;
	font-family: Montserrat-Medium;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.14px;
}
</style>
