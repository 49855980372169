<script lang="ts">
import Icon from 'svelte-icons-pack'
import RiDesignPencilLine from 'svelte-icons-pack/ri/RiDesignPencilLine'
import { useNavigate } from 'svelte-navigator'
import AiOutlineEye from 'svelte-icons-pack/ai/AiOutlineEye'
import NameInitials from '../common/NameInitials.svelte'
import DogStatus from './dog/DogStatus.svelte'
import { _ } from 'svelte-i18n'
import type { SearchDogModel } from '../../models/dog.model'
import NoResultAnimation from '../common/animation/NoResultAnimation.svelte'
import { utilService } from '../../utils/util.service'

const navigate = useNavigate()

export let dogs: SearchDogModel[]
export let disabled: boolean
</script>

{#if dogs && dogs.length === 0}
	<div class="flex-column align-center justify-center no-dogs-animation">
		<NoResultAnimation />
	</div>
{:else if dogs && dogs.length > 0}
	<section class="flex-column dog-list">
		<header class="header-container">
			<ul class="header">
				<li class="dog-name">{$_('dog_name')}</li>
				<li class="handler">{$_('dog_handler')}</li>
				<li class="last-trained">{$_('last_trained')}</li>
				<li class="type">{$_('type')}</li>
				<li class="status">{$_('status')}</li>
				<li class="action">{$_('action')}</li>
			</ul>
		</header>
		<div class="flex-column content">
			{#each dogs as dog}
				{#if !dog.archived}
					<button
						class="dog-preview-container"
						on:click={() => navigate(dog.id, { state: { pageName: $_('dog_details') } })}>
						<ul class="dog-preview">
							<li class="flex align-center dog-name">
								<div class="dog-img-container">
									{#if dog.image}
										<img
											src={dog.image}
											alt="dog profile" />
									{:else}
										<NameInitials name={dog.name} />
									{/if}
								</div>
								{dog.name}
							</li>
							<li class="handler">
								<div class="initials-container">
									{#if dog.handler.name}
										<NameInitials
											name={dog.handler.name}
											theme="secondary"
											fontSize={18} />
									{/if}
								</div>
								{dog.handler?.name || $_('without_handler')}
							</li>
							<li class="last-trained">
								{#if typeof dog.stats.recentTraining == 'object'}
									{dog.stats.recentTraining
										? utilService.getProperTime(dog.stats.recentTraining.toDate())
										: 'N/A'}
								{:else}
									{dog.stats.recentTraining
										? utilService.getProperTime(new Date(dog.stats.recentTraining * 1000))
										: 'N/A'}
								{/if}
							</li>
							<li
								class="type"
								title={dog.type.join(', ')}>
								{$_(`dog_types.${dog.type[0]}`)}
							</li>
							<li class="status">
								<DogStatus status={dog.status} />
							</li>
							<li class="action">
								{#if !disabled}
									<button
										class="btn btn-svg btn-edit"
										title={$_('edit')}
										on:click|stopPropagation={() =>
											navigate(`/dogs/edit/${dog.id}`, { state: { pageName: $_('edit_dog') } })}>
										<Icon src={RiDesignPencilLine} />
									</button>
								{/if}
								<button
									on:click={() => navigate(dog.id, { state: { pageName: $_('dog_details') } })}
									class="btn btn-svg btn-edit"
									title={$_('details')}>
									<Icon src={AiOutlineEye} />
								</button>
							</li>
						</ul>
					</button>
				{/if}
			{/each}
		</div>
	</section>
{:else if (dogs.length = 0)}
	<div>nothing</div>
{/if}

<style lang="scss">
@use '../../styles/setup/mixins';

.initials-container {
	width: 40px;
	aspect-ratio: 1;
}

.no-dogs-animation {
	width: 300px;
	margin-inline: auto;
}

.dog-list {
	@include mixins.card;
	overflow-x: auto;
	height: fit-content;

	.header,
	.dog-preview {
		display: grid;
		grid-template-columns:
			minmax(130px, 2fr) minmax(100px, 1.5fr) repeat(2, minmax(100px, 1fr)) minmax(40px, 110px)
			minmax(100px, 1fr);
		min-width: fit-content;

		> li {
			align-items: center;
			display: flex;
			gap: 8px;
		}

		> *:not(.dog-name) {
			text-align: center;
			place-self: center;
		}

		.type {
			display: -webkit-box;
			white-space: normal;
			position: relative;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);
	}

	.content {
		font-size: 14px;
	}

	.dog-preview {
		border-top: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 14px;
		transition: background-color 0.15s ease-out;

		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}

		.dog-name {
			gap: 12px;
		}

		.action {
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}

				:global(svg) {
					font-size: 18px;
				}
			}
		}
	}

	@media only screen and (max-width: 1190px) {
		.last-trained,
		.type,
		.handler,
		.action {
			display: none !important;
		}
		.header,
		.dog-preview {
			grid-template-columns: minmax(130px, 2fr) minmax(40px, 110px);
		}
		justify-content: space-between;
	}
}
</style>
