<svg
	width="49"
	height="48"
	viewBox="0 0 49 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_276_3169)">
		<rect
			x="0.0751953"
			width="48"
			height="48"
			rx="24"
			fill="#EA5455"
			fill-opacity="0.12" />
		<path
			d="M22.6944 9.29458C23.5222 8.90376 24.4778 8.90376 25.3055 9.29458L37.45 15.0153C37.9222 15.2362 38.2222 15.7176 38.2222 16.25C38.2222 16.7825 37.9222 17.2639 37.45 17.4848L25.3055 23.2055C24.4778 23.5963 23.5222 23.5963 22.6944 23.2055L10.55 17.4848C10.0778 17.2583 9.77777 16.7768 9.77777 16.25C9.77777 15.7233 10.0778 15.2362 10.55 15.0153L22.6944 9.29458ZM34.4944 20.8719L37.45 22.2653C37.9222 22.4862 38.2222 22.9676 38.2222 23.5C38.2222 24.0325 37.9222 24.5139 37.45 24.7348L25.3055 30.4555C24.4778 30.8463 23.5222 30.8463 22.6944 30.4555L10.55 24.7348C10.0778 24.5083 9.77777 24.0268 9.77777 23.5C9.77777 22.9733 10.0778 22.4862 10.55 22.2653L13.5055 20.8719L21.95 24.8481C23.25 25.4598 24.75 25.4598 26.05 24.8481L34.4944 20.8719ZM26.05 32.0981L34.4944 28.1219L37.45 29.5153C37.9222 29.7362 38.2222 30.2176 38.2222 30.75C38.2222 31.2825 37.9222 31.7639 37.45 31.9848L25.3055 37.7055C24.4778 38.0963 23.5222 38.0963 22.6944 37.7055L10.55 31.9848C10.0778 31.7583 9.77777 31.2768 9.77777 30.75C9.77777 30.2233 10.0778 29.7362 10.55 29.5153L13.5055 28.1219L21.95 32.0981C23.25 32.7098 24.75 32.7098 26.05 32.0981Z"
			fill="url(#paint0_linear_276_3169)" />
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_276_3169"
			x1="24"
			y1="9.00146"
			x2="24"
			y2="37.9986"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#EF4F50" />
			<stop
				offset="1"
				stop-color="#C71F20" />
		</linearGradient>
		<clipPath id="clip0_276_3169">
			<rect
				width="48"
				height="48"
				fill="white"
				transform="translate(0.0751953)" />
		</clipPath>
	</defs>
</svg>
