<script lang="ts">
import { onMount } from 'svelte'
import { _ } from 'svelte-i18n'
import { utilService } from '../../../utils/util.service'
import { afterUpdate } from 'svelte'

export let values: {
	min: number
	max: number
	divisions: number
	defaultSliderValue?: number
	label?: string
	value: {
		selectedValue: number
		label: string
	}
}
export let data = []

values.value = {
	selectedValue: values.defaultSliderValue,
	label: values.label
}

onMount(() => {
	data.length > 0
		? (values.defaultSliderValue = data[0].value.selectedValue)
		: values.defaultSliderValue != undefined
		? (values.defaultSliderValue = values.defaultSliderValue)
		: (values.defaultSliderValue = 0)
	values.value.selectedValue =
		data.length > 0
			? data[0].value.selectedValue
			: values.defaultSliderValue
			? values.defaultSliderValue
			: 0
	if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
		document.body.classList.add('firefox')
	}
})

function handleChange(event) {
	values.defaultSliderValue = +event.target.value
	values.value.selectedValue = values.defaultSliderValue
}

let inputElement: HTMLInputElement
let editingLabel = false
let editValue = ''

let shouldFocusInput = false

function handleLabelClick() {
	editingLabel = true
	editValue = values.value.selectedValue != 0 ? values.value.selectedValue.toString() : ''
	shouldFocusInput = true
}

function handleNumberInput(event) {
	if (event.target.value.length > 8) {
		event.target.value = event.target.value.slice(0, 8)
	}
}

function handleLabelBlur() {
	editingLabel = false

	let rawValue = parseFloat(editValue)

	if (isNaN(rawValue) || rawValue < values.min) {
		rawValue = values.min
	}

	let maxValue = values.max
	if (rawValue > maxValue) {
		rawValue = maxValue
	}

	values.value.selectedValue = rawValue
	editValue = values.value.selectedValue.toString()
	values.defaultSliderValue = values.value.selectedValue
}

let sliderWidth: number

afterUpdate(() => {
	if (shouldFocusInput) {
		inputElement.focus()
		shouldFocusInput = false
	}
})

let stepSize = (values.max - values.min) / values.divisions

$: filledWidth =
	(sliderWidth / (values.max - values.min)) *
	(Math.round(values.value.selectedValue / stepSize) * stepSize - values.min)
</script>

<div class="slider-container flex">
	<div class="slider-range flex">
		<div
			class="slider-wrapper"
			bind:clientWidth={sliderWidth}>
			<input
				type="range"
				class="slider"
				min={values.min}
				max={values.max}
				step={((values.max - values.min) / values.divisions).toFixed()}
				bind:value={values.value.selectedValue}
				on:input={handleChange} />
		</div>
		<div class="current-value">
			{#if editingLabel}
				<div class="input-container">
					<input
						class="label-input"
						bind:this={inputElement}
						type="number"
						bind:value={editValue}
						on:input={handleNumberInput}
						on:blur={handleLabelBlur} />
					<span class="tooltip"
						>Type the value {values.value.label != undefined
							? `in ${values.value.label}`
							: ''}</span>
				</div>
			{:else}
				<div
					on:click={handleLabelClick}
					on:keydown={handleLabelClick}>
					{values.value.selectedValue}
					{values.label ? $_(utilService.getShortLabel(values.label)) : ''}
				</div>
			{/if}
		</div>
		<div
			class="filled"
			style={`width:${filledWidth}px`} />
	</div>
</div>

<style lang="scss">
.slider-range {
	align-items: center;
	width: 100%;
	gap: 10px;

	.slider-wrapper {
		width: 510px;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.filled {
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		position: absolute;
		border-radius: 5px;
		z-index: 0;
		height: 2.5px;
	}
}

.label-input {
	height: 21px;
	width: 80px;
	border: none;
}

.label-input:focus {
	outline: none;
	border-color: transparent;
	box-shadow: none;
}

.input-container {
	position: relative;
	display: inline-block;
}

.input-container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}

.tooltip {
	visibility: hidden;
	opacity: 0;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 140%;
	left: 50%;
	font-size: 14px;
	margin-left: -60px;
	width: 180px;
}

.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 35%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #264a59 transparent transparent transparent;
}

.current-value,
.label-input {
	min-width: 5rem;
	text-align: center;
	font-size: 14px;
	font-family: Montserrat-Medium;
	border: 1px solid rgba(0, 0, 0, 0.12);
	padding: 5px 0;
	border-radius: 6px;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']::-ms-clear {
	display: none;
}

.label-input {
	border: none;
}

input[type='range'] {
	height: 23px;
	-webkit-appearance: none;
	margin: 10px 0;
	width: 100%;
}
input[type='range']:focus {
	outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
	width: 100%;
	height: 2.5px;
	cursor: pointer;
	transition: 0.2s;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 5px;
}
input[type='range']::-webkit-slider-thumb {
	height: 16px;
	width: 16px;
	border-radius: 50px;
	z-index: 1;
	position: relative;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	cursor: pointer;
	margin-top: -7px;
}
input[type='range']:active::-webkit-slider-thumb {
	outline: 10px solid rgba(45, 115, 146, 0.3);
	outline-offset: -1px;
	cursor: grabbing;
}
input[type='range']:focus::-webkit-slider-runnable-track {
	background: rgba(143, 149, 178, 0.4);
}
input[type='range']::-moz-range-track {
	width: 100%;
	height: 2.5px;
	cursor: pointer;
	transition: 0.2s;
	z-index: -1;
	position: relative;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 5px;
}
@-moz-document url-prefix() {
	.filled {
		display: none;
	}
}
input[type='range']::-moz-range-thumb {
	height: 16px;
	width: 16px;
	border-radius: 50px;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	position: relative;
	z-index: 1;
	cursor: pointer;
}
input[type='range']::-moz-range-progress {
	border-radius: 6px;
	height: 3px;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
}
input[type='range']::-ms-track {
	width: 100%;
	height: 2.5px;
	cursor: pointer;
	transition: 0.2s;
	background: transparent;
	border-color: transparent;
	color: transparent;
}
input[type='range']:active::-moz-range-thumb {
	outline: 10px solid rgba(45, 115, 146, 0.3);
	outline-offset: -1px;
	cursor: grabbing;
}
input[type='range']::-ms-fill-lower {
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;
}
input[type='range']::-ms-fill-upper {
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;
}
input[type='range']::-ms-thumb {
	margin-top: 1px;
	height: 17px;
	width: 17px;
	border-radius: 50px;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
	background: rgba(143, 149, 178, 0.4);
}
input[type='range']:focus::-ms-fill-upper {
	background: rgba(143, 149, 178, 0.4);
}
body.firefox .slider-range .filled {
	display: none;
}
</style>
