<svg
	width="80"
	height="80"
	viewBox="0 0 80 80"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#FFEFD8" />
	<g opacity="0.5">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M53 44C49.134 44 46 46.9167 46 50.5146C46 54.0844 48.2342 58.2499 51.7199 59.7396C52.5325 60.0868 53.4675 60.0868 54.2801 59.7396C57.7658 58.2499 60 54.0844 60 50.5146C60 46.9167 56.866 44 53 44Z"
			fill="#FD9E19" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M27 20C23.134 20 20 22.9167 20 26.5146C20 30.0844 22.2342 34.2499 25.7199 35.7396C26.5325 36.0868 27.4675 36.0868 28.2801 35.7396C31.7658 34.2499 34 30.0844 34 26.5146C34 22.9167 30.866 20 27 20Z"
			fill="#FD9E19" />
	</g>
	<path
		d="M55 51C55 52.1046 54.1046 53 53 53C51.8954 53 51 52.1046 51 51C51 49.8954 51.8954 49 53 49C54.1046 49 55 49.8954 55 51Z"
		fill="#FD9E19" />
	<path
		d="M29 27C29 28.1046 28.1046 29 27 29C25.8954 29 25 28.1046 25 27C25 25.8954 25.8954 25 27 25C28.1046 25 29 25.8954 29 27Z"
		fill="#FD9E19" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M38.5002 26C38.5002 25.1716 39.1718 24.5 40.0002 24.5H48.264C53.7664 24.5 55.859 31.686 51.2168 34.6401L30.3943 47.8908C28.2841 49.2336 29.2353 52.5 31.7364 52.5H36.3789L35.9395 52.0607C35.3538 51.4749 35.3538 50.5251 35.9395 49.9393C36.5253 49.3536 37.4751 49.3536 38.0609 49.9393L41.0609 52.9393C41.6466 53.5251 41.6466 54.4749 41.0609 55.0607L38.0609 58.0607C37.4751 58.6464 36.5253 58.6464 35.9395 58.0607C35.3538 57.4749 35.3538 56.5251 35.9395 55.9393L36.3789 55.5H31.7364C26.2339 55.5 24.1414 48.314 28.7836 45.3599L49.6062 32.1092C51.7163 30.7664 50.7651 27.5 48.264 27.5H40.0002C39.1718 27.5 38.5002 26.8284 38.5002 26Z"
		fill="#FD9E19" />
</svg>
