<script lang="ts">
import { onMount } from 'svelte'
import { insightsService } from '../../services/insights/data.service'
import exporting from 'highcharts/modules/exporting'
import * as Highcharts from 'highcharts'
import { _ } from 'svelte-i18n'
import { utilService } from '../../utils/util.service'

export let infoId = ''
export let data: any

let chart: Highcharts.Chart
let sessionKeys

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()

	if (data) {
		const dataForDisplay = insightsService.getTotalTrainingTime(data, infoId)
		generateChart(dataForDisplay)
	}
})

function generateChart(dataForDisplay) {
	if (sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing') {
		exporting(Highcharts)
	}

	const chartConfig: Highcharts.Options = {
		chart: {
			type: 'column'
		},
		title: {
			text: ''
		},
		xAxis: {
			categories: dataForDisplay.labels,
			lineWidth: 0.1
		},
		yAxis: {
			title: {
				text: $_('minutes')
			},
			gridLineWidth: 0.6,
			minorGridLineWidth: 0,
			labels: {
				formatter: function () {
					return this.value + ''
				},
				style: {
					opacity: 0.6
				}
			}
		},
		plotOptions: {
			column: {
				pointWidth: 10
			}
		},
		tooltip: {
			formatter: function () {
				const month = this.x
				const value = Highcharts.numberFormat(this.y, 0, ',', '')
				return (
					'<span style="font-weight: normal; font-size: 11px">' +
					month +
					'</span><br/><b>' +
					value +
					' min</b>'
				)
			}
		},
		series: dataForDisplay.datasets.map((dataset, index) => ({
			data: dataset.data,
			color: `#fd7f6f`
		})),
		navigation: {
			buttonOptions: {
				align: 'right'
			}
		},
		legend: {
			enabled: false
		}
	}

	chart = Highcharts.chart('total-training-time', chartConfig)
}
</script>

<h1 class="title">{$_(`insights_pages.total_training_time`)}</h1>
<div class="chart-container">
	<div id="total-training-time" />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.chart-container {
	@include mixins.card;
	background-color: #fff;
	border-radius: 6px;
	padding-top: 30px;
	min-height: 430px;
}

.title {
	font-family: Montserrat-Medium;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
</style>
