<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#FBD8D9" />
	<g clip-path="url(#clip0_548_25811)">
		<path
			d="M34.3772 11.6475H31.9066V14.118C31.9066 14.6122 31.4948 14.9416 31.083 14.9416C30.6713 14.9416 30.2595 14.6122 30.2595 14.118V11.6475H17.083V14.118C17.083 14.6122 16.6713 14.9416 16.2595 14.9416C15.8477 14.9416 15.436 14.6122 15.436 14.118V11.6475H12.9654C11.7301 11.6475 10.8242 12.718 10.8242 14.118V17.0828H37.1772V14.118C37.1772 12.718 35.6948 11.6475 34.3772 11.6475ZM10.8242 18.8122V33.8828C10.8242 35.3651 11.7301 36.3533 13.0477 36.3533H34.4595C35.7772 36.3533 37.2595 35.2828 37.2595 33.8828V18.8122H10.8242ZM18.1536 32.6475H16.1772C15.8477 32.6475 15.5183 32.4004 15.5183 31.9886V29.9298C15.5183 29.6004 15.7654 29.271 16.1772 29.271H18.236C18.5654 29.271 18.8948 29.518 18.8948 29.9298V31.9886C18.8125 32.4004 18.5654 32.6475 18.1536 32.6475ZM18.1536 25.2357H16.1772C15.8477 25.2357 15.5183 24.9886 15.5183 24.5769V22.518C15.5183 22.1886 15.7654 21.8592 16.1772 21.8592H18.236C18.5654 21.8592 18.8948 22.1063 18.8948 22.518V24.5769C18.8125 24.9886 18.5654 25.2357 18.1536 25.2357ZM24.7419 32.6475H22.683C22.3536 32.6475 22.0242 32.4004 22.0242 31.9886V29.9298C22.0242 29.6004 22.2713 29.271 22.683 29.271H24.7419C25.0713 29.271 25.4007 29.518 25.4007 29.9298V31.9886C25.4007 32.4004 25.1536 32.6475 24.7419 32.6475ZM24.7419 25.2357H22.683C22.3536 25.2357 22.0242 24.9886 22.0242 24.5769V22.518C22.0242 22.1886 22.2713 21.8592 22.683 21.8592H24.7419C25.0713 21.8592 25.4007 22.1063 25.4007 22.518V24.5769C25.4007 24.9886 25.1536 25.2357 24.7419 25.2357ZM31.3301 32.6475H29.2713C28.9419 32.6475 28.6125 32.4004 28.6125 31.9886V29.9298C28.6125 29.6004 28.8595 29.271 29.2713 29.271H31.3301C31.6595 29.271 31.9889 29.518 31.9889 29.9298V31.9886C31.9889 32.4004 31.7419 32.6475 31.3301 32.6475ZM31.3301 25.2357H29.2713C28.9419 25.2357 28.6125 24.9886 28.6125 24.5769V22.518C28.6125 22.1886 28.8595 21.8592 29.2713 21.8592H31.3301C31.6595 21.8592 31.9889 22.1063 31.9889 22.518V24.5769C31.9889 24.9886 31.7419 25.2357 31.3301 25.2357Z"
			fill="#E76F51" />
	</g>
	<defs>
		<clipPath id="clip0_548_25811">
			<rect
				width="28"
				height="28"
				fill="white"
				transform="translate(10 10)" />
		</clipPath>
	</defs>
</svg>
