import { fireMsg } from '../userMsg.service'

export const authValidation = {
	email,
	password,
	username,
	phoneNumber,
	url,
	specialCharacter,
	fullValidation
}

export type AuthField = {
	name: string
	value: string
	invalid: boolean
	errorMessage: string
}

export interface LoginFields {
	email: AuthField
	password: AuthField
}

export interface SignupFields extends LoginFields {
	accountName: AuthField
	firstName: AuthField
	lastName: AuthField
}

function email(email: AuthField): AuthField {
	const { value } = email
	if (value.includes(' ')) return _inputError(email, 'NO_SPACE')

	const re = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
	if (!re.test(value)) return _inputError(email, 'Invalid email address')

	return { ...email, invalid: false, errorMessage: '' }
}

function password(pass: AuthField): AuthField {
	const { value } = pass
	if (value.length <= 5) {
		return _inputError(pass, 'Password must contain at least 6 characters')
	}

	if (value.includes(' ')) {
		return _inputError(pass, 'NO_SPACE')
	}

	const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/

	if (!re.test(value)) {
		return _inputError(
			pass,
			'Password must contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.'
		)
	}
	return { ...pass, invalid: false, errorMessage: '' }
}

function phoneNumber(number: AuthField): AuthField {
	const { value } = number

	const re =
		/(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})\s*(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+)\s*)?$/

	if (!re.test(value)) {
		return _inputError(number, 'Invalid number')
	}
	return { ...number, invalid: false, errorMessage: '' }
}

function url(url: AuthField): AuthField {
	const { value } = url

	const re =
		/^([-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*))?$/

	if (!re.test(value)) {
		return _inputError(url, 'Invalid website')
	}
	return { ...url, invalid: false, errorMessage: '' }
}

function username(username: AuthField) {
	const { value } = username

	const re = /^[a-zA-Z\u0590-\u05FF\u200f\u200e0-9 ]+$/

	if (value.length < 2) {
		return _inputError(username, 'At least 2 characters')
	}

	if (!re.test(value)) {
		return _inputError(username, 'No special characters allowed')
	}

	return { ...username, invalid: false, errorMessage: '' }
}

function specialCharacter(value: string): boolean {
	const re = /^[a-zA-Z0-9 ]{2,}$/
	return !re.test(value)
}

function fullValidation(fields: SignupFields | LoginFields): boolean {
	for (const fieldName in fields) {
		const field = fields[fieldName] as AuthField
		if (field.invalid) {
			fireMsg({
				type: 'failure',
				msg: `Error in ${field.name}: ${field.errorMessage.toLowerCase()}`
			})
			return false
		}
		if (field.value.length < 2) {
			fireMsg({ type: 'failure', msg: `${field.name} is required` })
			return false
		}
	}
	return true
}

function _inputError(field: AuthField, errorMessage: string): AuthField {
	field.invalid = true

	switch (errorMessage) {
		case 'NO_SPACE':
			return { ...field, errorMessage: 'No empty spaces allowed' }

		default:
			return { ...field, errorMessage }
	}
}
