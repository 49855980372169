import { toast, type SvelteToastOptions } from '@zerodevx/svelte-toast'

type MsgType = 'success' | 'warning' | 'failure' | 'general'

interface MsgOptions extends SvelteToastOptions {
	type: MsgType
}

export const userMsg = {
	successMsg,
	errorMsg
}

export function fireMsg(options: MsgOptions): void {
	const toastOptions: SvelteToastOptions = {
		duration:7000,
		pausable: true,
		...options,
		theme: _getMsgTheme(options.type)
	}
	toast.push(toastOptions)
}

function successMsg(msg: string): void {
	fireMsg({ type: 'success', msg })
}

function errorMsg(msg: string, error: any = ''): void {
	msg += error ? ': ' + (error.code ? error.code : error.message) : ''
	fireMsg({ type: 'failure', msg })
	//console.error(error)
}

function _getMsgTheme(type: MsgType) {
	switch (type) {
		case 'success':
			return {
				'--toastBackground': 'rgba(72,187,120,0.9)',
				'--toastBarBackground': '#2F855A'
			}
		case 'warning':
			return {
				'--toastBackground': 'rgba(253,151,3,0.9)',
				'--toastBarBackground': '#655e19'
			}
		case 'failure':
			return {
				'--toastBackground': 'rgba(236,54,54,0.9)',
				'--toastBarBackground': '#f99c9c'
			}
		default:
			return { '--toastBarBackground': '#e76f51' }
	}
}
