<script lang="ts">
import { stepperMultiply } from '../../../../store/trainingWidgetsStore'
import StepperMultiplyHelper from './StepperMultiplyHelper.svelte'
import CustomStepper from '../CustomStepper.svelte'

// TODO: this widget is only used for the odor stepper, it needs to be renamed
// TODO: This code is still a mess needs to cleaned up

export let values: {
	items: [
		{
			isShown: boolean
			items: string[]
			name: string
			//subWidgetType: string
		},
		{
			isShown: boolean
			name: string
			showDays: boolean
			showMonths: boolean
			showYears: boolean
			showMinutes: boolean
			showHours: boolean
			//subWidgetType: string
		},
		{
			isShown: boolean
			defaultSliderValue: number
			divisions: number
			maxKilos: number
			maxPounds: number
			minKilos: number
			minPounds: number
			useGrams: boolean
			name: string
			//subWidgetType: string
		}
	]
	filled: boolean
	label: string
	stepAmount: number
	value: {
		odorAmount: number
		odorInfo: {
			odorPlacement: string
			odorAge: number
			odorWeight: number
			multiselect: number
		}[]
	}
}
export let data = []
export let id: string

let stepperValues
let helperValues = []

if (!$stepperMultiply[id]) {
	$stepperMultiply[id] = {
		items: values.items,
		value: {
			odorAmount: 1,
			odorInfo: []
		}
	}
}

if (data.length > 0 && $stepperMultiply[id].value.odorAmount == 1) {
	stepperValues = {
		stepAmount: values.stepAmount,
		label: values.label,
		filled: false,
		value: {
			selectedValue: data[0].value.odorAmount,
			label: 'odor amount'
		}
	}
	values.value = {
		odorAmount: data[0].value.odorAmount,
		odorInfo: []
	}
} else if ($stepperMultiply[id]) {
	stepperValues = {
		stepAmount: values.stepAmount,
		label: values.label,
		filled: false,
		value: {
			selectedValue: $stepperMultiply[id].value.odorAmount,
			label: 'odor amount'
		}
	}
	values.value = {
		odorAmount: $stepperMultiply[id].value.odorAmount,
		odorInfo: []
	}
} else {
	stepperValues = {
		stepAmount: values.stepAmount,
		label: values.label,
		filled: false,
		value: {
			selectedValue: 0,
			label: 'odor amount'
		}
	}
	values.value = {
		odorAmount: 1,
		odorInfo: []
	}
}

if ($stepperMultiply[id].value.odorInfo.length != 0) {
	helperValues = $stepperMultiply[id].value.odorInfo.map((odor) => {
		return {
			items: values.items,
			value: {
				odorAge: odor.odorAge,
				odorPlacement: odor.odorPlacement,
				odorWeight: odor.odorWeight,
				multiselect: odor.multiselectValue
			}
		}
	})
}

$: {
	if (stepperValues.value.selectedValue > 0) {
		values.value.odorAmount = stepperValues.value.selectedValue
		values.value.odorInfo = helperValues.map((helperValue) => {
			return {
				odorPlacement: helperValue.value.odorPlacement,
				odorAge: helperValue.value?.odorAge,
				odorWeight: helperValue.value.odorWeight,
				multiselect: helperValue.value.multiselectValue,
				odorSetTime: helperValue.value.odorSetTime,
				multiselectTextToTags: helperValue.value.multiselectTextToTagsValue,
				multiselectTextToTags2: helperValue.value.multiselectTextToTagsValue2
			}
		})
	}
}

$: {
	if (values.value.odorInfo.length != 0)
		$stepperMultiply[id] = {
			items: values.items,
			value: {
				odorAmount: stepperValues.value.selectedValue,
				odorInfo: values.value.odorInfo
			}
		}
}

$: if (values.value.odorInfo.length > 0) {
	values.filled = true
} else {
	values.filled = false
}

function add(e: Event) {
	e.preventDefault()
	helperValues = []
	for (let i = 0; i < stepperValues.value.selectedValue; i++) {
		helperValues.push({
			items: values.items,
			value: {
				odorPlacement: '',
				odorAge: 0,
				odorWeight: 0,
				multiselect: []
			}
		})
	}
}

function loadWidgetFromData() {
	helperValues = []
	for (let i = 0; i < stepperValues.value.selectedValue; i++) {
		helperValues.push({
			items: values.items,
			value: {
				odorPlacement: '',
				odorAge: 0,
				odorWeight: 0,
				multiselect: []
			}
		})
	}
}

function remove(e: Event) {
	e.preventDefault()
	helperValues = []
	stepperValues = {
		stepAmount: values.stepAmount,
		label: values.label,
		filled: false,
		value: {
			selectedValue: 0,
			label: 'odor amount'
		}
	}
}

loadWidgetFromData()
</script>

<div class="flex align-center stepper-multiply-container">
	<button
		on:click|stopPropagation={remove}
		type="button"
		class="btn theme-btn remove">Remove</button>
	<CustomStepper
		bind:values={stepperValues}
		{id} />
	<button
		class="btn theme-btn add"
		type="button"
		on:click|stopPropagation={add}>Add</button>
</div>

{#if data.length > 0}
	{#each helperValues as helperValue, i}
		<StepperMultiplyHelper
			bind:values={helperValue}
			id={id + i}
			counter={i}
			data={[data[0].value]} />
	{/each}
{:else}
	{#each helperValues as helperValue, i}
		<StepperMultiplyHelper
			bind:values={helperValue}
			id={id + i} />
	{/each}
{/if}

<style lang="scss">
.stepper-multiply-container {
	gap: 10px;
}

.btn {
	width: 5rem;
	height: 2rem;
	padding: 0;
}
</style>
