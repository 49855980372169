import {
	addDoc,
	collection,
	CollectionReference,
	doc,
	DocumentReference,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
	writeBatch,
	type DocumentData
} from 'firebase/firestore'
import { Firestore } from '../firebase/firebase'
import type { AccountModel } from '../../models/account.model'
import type { UserModel, UserRole, UserType } from '../../models/user.model'
import { accountDB } from './account.db'
import type { QuerySnapshot, Query } from 'firebase/firestore'

export const userDB = {
	getMany,
	get,
	add,
	addWithAccount,
	set,
	update,
	newUserRef,
	getUserRef,
	getDocsQuery
}

async function getMany(userIds: string[], filterBy?: Partial<{ role: UserRole; type: UserType }>): Promise<QuerySnapshot<DocumentData>> {
	const queryParams = getDocsQuery(userIds)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

async function get(uid: string): Promise<DocumentData> {
	const userDocSnap = await getDoc(_getDocRef(uid))
	if (userDocSnap.exists()) return userDocSnap.data()
	else throw new Error('User was not found')
}

async function add(newUser: UserModel): Promise<DocumentReference<DocumentData>> {
	const docRef = await addDoc(_getCollectionRef(), newUser)
	return docRef
}

async function addWithAccount(user: UserModel, account: AccountModel) {
	const newAccountDocRef = accountDB.newAccountRef()
	const accountId = newAccountDocRef.id
	const userDocRef = _getDocRef(user.id)
	user.accountIds.push(accountId)
	user.selectedAccount.id = account.id = accountId
	const batch = writeBatch(Firestore)
	batch.set(userDocRef, user)
	batch.set(newAccountDocRef, account)
	await batch.commit()
}

async function set(uid: string, user: UserModel) {
	const docRef = await setDoc(_getDocRef(uid), user)
	return docRef
}

async function update(uid: string, editedUser: Partial<UserModel>) {
	if (!uid) throw new Error('User ID is required')
	const docRef = await updateDoc(_getDocRef(uid), editedUser)
	return docRef
}

function newUserRef(): DocumentReference<DocumentData> {
	return _getNewDocRef()
}

function getUserRef(userId: string): DocumentReference<DocumentData> {
	return _getDocRef(userId)
}

function getDocsQuery(userIds: string[], filterBy?: Partial<{ role: UserRole[]; type: UserType[] }>): Query<DocumentData> {
	const queryConstraints = []
	if (filterBy) {
		const { role, type } = filterBy
		if (role) queryConstraints.push(where('role', '==', role))
		if (type) queryConstraints.push(where('type', '==', type))
	}
	return query(collection(Firestore, 'users'), where('id', 'in', userIds), ...queryConstraints)
}

function _getDocRef(documentId: string): DocumentReference<DocumentData> {
	return doc(Firestore, 'users', documentId)
}

function _getCollectionRef(): CollectionReference<DocumentData> {
	return collection(Firestore, 'users')
}

function _getNewDocRef(): DocumentReference<DocumentData> {
	return doc(collection(Firestore, 'users'))
}
