<script lang="ts">
import NameInitials from '../../common/NameInitials.svelte'
import TypeIcon from './icons/TypeIcon.svelte'
import type { AccountUserModel } from '../../../models/user.model'
import RoleIcon from './icons/RoleIcon.svelte'
import LastTrainingsIcon from './icons/LastTrainingsIcon.svelte'
import { _ } from 'svelte-i18n'
import moment from 'moment'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

moment.locale(localStorage.getItem('language'))

export let user: AccountUserModel

function getUserInfo(user: AccountUserModel) {
	if (!user) return
	const userInfo = [
		{
			title: 'type',
			icon: TypeIcon,
			value: user.type
		},
		{
			title: 'role',
			icon: RoleIcon,
			value: user.role
		},
		{
			title: 'last_trained',
			icon: LastTrainingsIcon,
			value: user.stats.recentTraining
				? moment(user.stats.recentTraining.toDate()).format('MMMM D[,] YYYY')
				: 'N/A'
		}
	]
	return userInfo
}

$: userInfo = getUserInfo(user)
</script>

<section class="user-info">
	<header class="header">
		<h1 class="title">{$_('user_information')}</h1>
	</header>
	<div class="align-center space-around content">
		<div class="flex align-center info name">
			<div class="svg-container">
				<NameInitials name={user.firstName + ' ' + user.lastName} />
			</div>
			<h1 class="name">{user.firstName + ' ' + user.lastName}</h1>
		</div>
		{#each userInfo as info}
			<div class="flex align-center info type">
				<div class="svg-container">
					<svelte:component this={info.icon} />
				</div>
				<div class="flex-column description ">
					<h1 class="title">{$_(info.title)}</h1>
					<h2 class="value {info.title}">{$_(info.value.toString())}</h2>
				</div>
			</div>
		{/each}
	</div>
</section>

<style lang="scss">
@use '../../../styles/setup/mixins';

.user-info {
	@include mixins.card;

	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.content {
		display: grid;
		grid-template-columns: repeat(auto-fit, 300px);

		.info {
			padding-inline: 20px;
			margin-bottom: 30px;
			text-transform: capitalize;

			&:not(.user-name) {
				gap: 16px;
			}

			.name {
				font-size: 15px;
				font-family: Nunito;
				font-weight: normal;
			}

			.email {
				text-transform: none;
			}

			.email::first-letter {
				text-transform: uppercase;
			}

			.user-img-container,
			.svg-container {
				width: 48px;
				height: 48px;
				min-width: 48px;
			}

			.description {
				width: fit-content;

				.title {
					font-size: 14px;
					font-family: Nunito;
					font-weight: normal;
				}

				.value {
					font-size: 16px;
					font-weight: 500;
					white-space: nowrap;
				}
			}
		}
	}
}
</style>
