<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#EEF0F7" />
	<path
		d="M32.8205 12H15.9795C14.8798 12 14 12.8263 14 13.8591V31.8899C14 32.9228 14.8798 33.7491 15.9795 33.7491H17.7076C17.7076 37.2313 20.7239 40.0348 24.4 40.0348C28.0761 40.0348 31.0924 37.2018 31.0924 33.7491H32.8205C33.9202 33.7491 34.8 32.9228 34.8 31.8899V13.8591C34.8 12.8263 33.9202 12 32.8205 12ZM24.4 38.2937C21.7293 38.2937 19.5299 36.2575 19.5299 33.7196C19.5299 31.2112 21.6979 29.1455 24.4 29.1455C27.1021 29.1455 29.2701 31.1817 29.2701 33.7196C29.2701 36.2575 27.0707 38.2937 24.4 38.2937ZM30.1813 25.9584H18.6187C18.116 25.9584 17.7076 25.5747 17.7076 25.1026C17.7076 24.6304 18.116 24.2468 18.6187 24.2468H30.1813C30.684 24.2468 31.0924 24.6304 31.0924 25.1026C31.0924 25.5747 30.684 25.9584 30.1813 25.9584ZM30.1813 21.7089H18.6187C18.116 21.7089 17.7076 21.3253 17.7076 20.8531C17.7076 20.3809 18.116 19.9973 18.6187 19.9973H30.1813C30.684 19.9973 31.0924 20.3809 31.0924 20.8531C31.0924 21.3253 30.684 21.7089 30.1813 21.7089ZM30.1813 17.4594H18.6187C18.116 17.4594 17.7076 17.0758 17.7076 16.6036C17.7076 16.1314 18.116 15.7478 18.6187 15.7478H30.1813C30.684 15.7478 31.0924 16.1314 31.0924 16.6036C31.0924 17.0758 30.684 17.4594 30.1813 17.4594Z"
		fill="#264A59" />
	<path
		d="M25.7511 31.9785L23.5831 34.0147L22.829 33.3064C22.4834 32.9818 21.8864 32.9818 21.5408 33.3064C21.1952 33.631 21.1952 34.1917 21.5408 34.5164L22.9233 35.8148C23.0804 35.9624 23.3317 36.0804 23.5831 36.0804C23.8344 36.0804 24.0544 35.9919 24.2429 35.8148L27.0393 33.1884C27.3849 32.8638 27.3849 32.3031 27.0393 31.9785C26.6937 31.6539 26.0967 31.6539 25.7511 31.9785Z"
		fill="#264A59" />
</svg>
