<script lang="ts">
import { onMount } from 'svelte'
import UnderDevelopment from '../cmps/common/UnderDevelopment.svelte'

onMount(() => {
})
</script>

<div class="calendar">
	<UnderDevelopment />
</div>

<style lang="scss">
.calendar {
	height: 100vh;
}
</style>
