<svg
	width="80"
	height="80"
	viewBox="0 0 80 80"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#FFEFD8" />
	<path
		opacity="0.5"
		d="M22 36C22 28.4575 22 24.6863 24.3431 22.3431C26.6863 20 30.4575 20 38 20H42C49.5425 20 53.3137 20 55.6569 22.3431C58 24.6863 58 28.4575 58 36V44C58 51.5425 58 55.3137 55.6569 57.6569C53.3137 60 49.5425 60 42 60H38C30.4575 60 26.6863 60 24.3431 57.6569C22 55.3137 22 51.5425 22 44V36Z"
		fill="#FE9803" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M30.5 40C30.5 39.1716 31.1716 38.5 32 38.5H48C48.8284 38.5 49.5 39.1716 49.5 40C49.5 40.8284 48.8284 41.5 48 41.5H32C31.1716 41.5 30.5 40.8284 30.5 40Z"
		fill="#FE9803" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M30.5 32C30.5 31.1716 31.1716 30.5 32 30.5H48C48.8284 30.5 49.5 31.1716 49.5 32C49.5 32.8284 48.8284 33.5 48 33.5H32C31.1716 33.5 30.5 32.8284 30.5 32Z"
		fill="#FE9803" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M30.5 48C30.5 47.1716 31.1716 46.5 32 46.5H42C42.8284 46.5 43.5 47.1716 43.5 48C43.5 48.8284 42.8284 49.5 42 49.5H32C31.1716 49.5 30.5 48.8284 30.5 48Z"
		fill="#FE9803" />
</svg>
