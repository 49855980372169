<script lang="ts">
import type { UserModel } from '../../models/user.model'
import { loggedInUser } from '../../store/stores'
import { _ } from 'svelte-i18n'
import { utilService } from '../../utils/util.service'
import { userMsg } from '../../utils/userMsg.service'
import { authValidation, type AuthField } from '../../utils/validation/auth.validation'
import SelectButton from '../common/SelectButton.svelte'

export let user: UserModel

let firstName: AuthField = {
	name: 'first-name',
	value: user.firstName,
	invalid: false,
	errorMessage: ''
}

let lastName: AuthField = {
	name: 'last-name',
	value: user.lastName,
	invalid: false,
	errorMessage: ''
}

function cleanInput(authField: AuthField) {
	isFormDisabled = false
	authField.invalid = false
	authField.errorMessage = ''
}

async function handleSubmit(ev: Event) {
	const url = `${import.meta.env.VITE_APP_API_URL}/user/${$loggedInUser.id}`
	isFormDisabled = true
	ev.preventDefault()
	ev.stopPropagation()
	try {
		isFormDisabled = true
		utilService.restRequest(url, 'PATCH', {
			firstName: firstName.value,
			lastName: lastName.value,
			type: user.type
		})
		loggedInUser.update((user) => ({
			...user,
			firstName: firstName.value,
			lastName: lastName.value,
			type: user.type
		}))
		userMsg.successMsg('Changes saved')
	} catch (error: unknown) {
		isFormDisabled = false
	}
}
let isFormDisabled = false
</script>

<div class="edit-info">
	<form
		on:submit={handleSubmit}
		class="flex-column user-edit-form">
		<header class="flex align-center form-header">
			<h1 class="title">{$_('edit_user_info')}</h1>
		</header>
		<div class="field user-name">
			<label for="first-name">{$_('first_name')}</label>
			<input
				class="edit-input"
				class:invalid={firstName.errorMessage}
				type="text"
				required
				pattern={'^[a-zA-Z0-9 ]{2,}$'}
				id="first-name"
				on:focus={() => cleanInput(firstName)}
				on:blur={() => (firstName = authValidation.username(firstName))}
				bind:value={firstName.value} />
			<label
				for="first-name"
				role="alert">
				{firstName.errorMessage}
			</label>
		</div>
		<div class="field user-name">
			<label for="last-name">{$_('last_name')}</label>
			<input
				class="edit-input"
				class:invalid={lastName.errorMessage}
				type="text"
				required
				pattern={'^[a-zA-Z0-9 ]{2,}$'}
				id="last-name"
				on:focus={() => cleanInput(lastName)}
				on:blur={() => (lastName = authValidation.username(lastName))}
				bind:value={lastName.value} />
			<label
				for="last-name"
				role="alert">
				{lastName.errorMessage}
			</label>
		</div>
		<div class="field type">
			<label for="status">{$_('type')}</label>
			<SelectButton
				id="status"
				bind:value={user.type[0]}
				choices={[
					{ label: $_('dog handler'), value: 'dog handler' },
					{ label: $_('trainer'), value: 'trainer' },
					{ label: $_('viewer'), value: 'viewer' }
				]} />
		</div>
	</form>
	<div class="flex align-center btns-container">
		<button
			disabled={isFormDisabled}
			type="submit"
			class="btn theme-btn submit"
			on:click={handleSubmit}>
			{$_('submit')}
		</button>
	</div>
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.edit-info {
	@include mixins.card;
	padding: 28px 24px;

	.user-edit-form {
		gap: 20px;

		.field {
			display: flex;
			flex-direction: column;
			gap: 6px;

			label {
				font-size: 14px;
				font-family: Nunito-SemiBold;
				color: #33383f;
			}

			.edit-input {
				font-size: 16px;
				color: var(--clr-txt-dark-primary);
			}

			&:has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			input.invalid {
				border-color: #b00020;
			}

			label[role='alert'] {
				color: #b00020;
			}
		}
	}
	.btns-container {
		margin-top: 50px;
		justify-content: flex-end;
	}
}
</style>
