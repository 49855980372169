<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_2_61"
		style="mask-type:luminance"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="48"
		height="48">
		<path
			d="M48 0H0V48H48V0Z"
			fill="white" />
	</mask>
	<g mask="url(#mask0_2_61)">
		<path
			d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z"
			fill="#DCEBF2" />
		<path
			d="M14.7143 10.875C14.7143 9.83789 13.8844 9 12.8571 9C11.8299 9 11 9.83789 11 10.875V12.75V30.5625V37.125C11 38.1621 11.8299 39 12.8571 39C13.8844 39 14.7143 38.1621 14.7143 37.125V29.625L18.446 28.6816C20.8313 28.0781 23.3558 28.3594 25.5554 29.4668C28.1205 30.7617 31.0978 30.9199 33.779 29.9004L35.7929 29.1387C36.5183 28.8633 37 28.166 37 27.3809V12.873C37 11.5254 35.5955 10.6465 34.4 11.25L33.8429 11.5313C31.1558 12.8906 27.9929 12.8906 25.3058 11.5313C23.2688 10.5 20.9299 10.2422 18.7188 10.7988L14.7143 11.8125V10.875Z"
			fill="#2D5E73" />
	</g>
</svg>
