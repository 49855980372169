<script lang="ts">
import type { SvelteComponent } from 'svelte'
import type { SvgIconName } from '../../models/common.model'
import AddGeneralPerson from './icon/add-general-person.svelte'
import AddGeneralTeam from './icon/add-general-team.svelte'
import AddPolicePerson from './icon/add-police-person.svelte'
import AddTeam from './icon/add-team.svelte'
import DogAlert from './icon/dog-alert.svelte'
import DogBall from './icon/dog-ball.svelte'
import DogScent from './icon/dog-scent.svelte'
import FirePerson from './icon/fire-person.svelte'
import FireTeam from './icon/fire-team.svelte'
import GeneralDog from './icon/general-dog.svelte'
import GeneralTeam from './icon/general-team.svelte'
import MilitaryPerson from './icon/military-person.svelte'
import MilitaryTeam from './icon/military-team.svelte'
import PolicePerson from './icon/police-person.svelte'
import PoliceTeam from './icon/police-team.svelte'
import RemoveTeam from './icon/remove-team.svelte'
import RescueDog from './icon/rescue-dog.svelte'
import RescuePerson from './icon/rescue-person.svelte'
import RescueTeam from './icon/rescue-team.svelte'
import RunningDog from './icon/running-dog.svelte'

const icons: { name: SvgIconName; icon: typeof SvelteComponent }[] = [
	{
		name: 'add-general-person',
		icon: AddGeneralPerson
	},
	{
		name: 'add-general-team',
		icon: AddGeneralTeam
	},
	{
		name: 'add-police-person',
		icon: AddPolicePerson
	},
	{
		name: 'add-team',
		icon: AddTeam
	},
	{
		name: 'dog-alert',
		icon: DogAlert
	},
	{
		name: 'dog-ball',
		icon: DogBall
	},
	{
		name: 'dog-scent',
		icon: DogScent
	},
	{
		name: 'fire-person',
		icon: FirePerson
	},
	{
		name: 'fire-team',
		icon: FireTeam
	},
	{
		name: 'general-dog',
		icon: GeneralDog
	},
	{
		name: 'general-team',
		icon: GeneralTeam
	},
	{
		name: 'military-person',
		icon: MilitaryPerson
	},
	{
		name: 'military-team',
		icon: MilitaryTeam
	},
	{
		name: 'police-person',
		icon: PolicePerson
	},
	{
		name: 'police-team',
		icon: PoliceTeam
	},
	{
		name: 'remove-team',
		icon: RemoveTeam
	},
	{
		name: 'rescue-dog',
		icon: RescueDog
	},
	{
		name: 'rescue-person',
		icon: RescuePerson
	},
	{
		name: 'rescue-team',
		icon: RescueTeam
	},
	{
		name: 'running-dog',
		icon: RunningDog
	}
]

export let name: SvgIconName
export let width = '24px'
export let theme: 'outline' | 'fill' | 'gradient' = 'gradient'

const getIcon = (name: SvgIconName) => {
	const selected = icons.find((icon) => icon.name === name)
	return selected ? selected.icon : GeneralTeam
}
</script>

<div>
	<div style="width: 0; height: 0; overflow: hidden;">
		<svg>
			<defs>
				<linearGradient
					id="shape-gradient"
					y1="100%"
					y2="0">
					<stop
						offset="20%"
						stop-color="#264a59" />
					<stop
						offset="100%"
						stop-color="#2d7392" />
				</linearGradient>
			</defs>
		</svg>
	</div>
	<div
		class="svg-icon {theme}"
		style="width: {width};">
		<svelte:component this={getIcon(name)} />
	</div>
</div>

<style lang="scss">
.svg-icon {
	display: grid;

	place-items: center;
	aspect-ratio: 1/1;

	&.outline {
		:global(svg path) {
			fill: none;
			stroke: black;
			stroke-width: 8px;
		}
	}

	&.gradient {
		:global(svg path) {
			fill: url(#shape-gradient);
			// background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		}
	}
}
:global(.svg-icon > *) {
	width: 100%;
	height: 100%;
}
</style>
