<svg width="150" height="105" viewBox="0 0 150 105" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3892 15.8688C12.3892 8.62083 13.8682 6.02783 20.3892 1.84883C24.2552 -0.628166 32.7682 -0.190168 36.6192 2.68583C41.7482 6.51483 43.3892 9.75083 43.3892 16.0378C43.3892 22.3138 41.7482 25.5608 36.6522 29.3658C32.5832 32.4028 24.9042 32.7408 20.2352 30.0878C14.0412 26.5678 12.3892 23.5738 12.3892 15.8688Z" fill="currentColor"/>
<path d="M106.317 12.5668C107.115 8.31883 111.243 3.22883 115.339 1.44483C122.114 -1.50617 128.752 0.0858331 134.389 6.01383C137.108 8.87383 137.389 9.79383 137.389 15.8538C137.389 23.5118 135.736 26.5678 129.753 29.9698C117.899 36.7108 103.758 26.2098 106.317 12.5668Z" fill="currentColor"/>
<path d="M49.5372 25.9478C49.8702 15.6498 57.7592 6.98483 69.9022 3.57983C77.0472 1.57583 88.7222 2.17183 93.1662 4.76783C97.2702 7.16383 98.8562 10.3428 98.8742 16.2058C98.9002 24.3978 99.7112 24.0378 81.2122 24.0378H64.7492L60.2252 27.1508C57.7372 28.8628 54.2812 30.3248 52.5452 30.4008C49.4222 30.5368 49.3912 30.4898 49.5372 25.9478Z" fill="currentColor"/>
<path d="M57.0522 32.2358C59.4372 31.1508 62.2892 29.5518 63.3892 28.6818C65.0182 27.3948 68.3362 27.0958 81.2592 27.0698L97.1292 27.0378L96.7022 32.9478C96.1852 40.0898 92.8432 45.7168 86.7032 49.7788C83.0662 52.1868 81.5622 52.5378 74.8892 52.5378C68.2162 52.5378 66.7121 52.1868 63.0751 49.7788C58.6352 46.8408 54.4272 41.2488 53.3632 36.8728C52.7792 34.4728 53.1452 34.0128 57.0522 32.2358Z" fill="currentColor"/>
<path d="M6.68416 45.8428C11.5002 40.5118 15.4482 37.8628 20.8282 36.3518C29.3142 33.9678 40.6152 37.0648 46.8312 43.4788L50.0372 46.7858L42.7132 48.5398C38.6852 49.5038 35.0152 50.6318 34.5592 51.0458C34.1022 51.4598 34.2051 53.5218 34.7861 55.6278C35.6321 58.6918 35.5412 59.9208 34.3272 61.7728C32.8322 64.0548 32.5832 64.0828 16.6002 63.8128L0.389176 63.5378L0.0531772 61.1588C-0.453823 57.5768 2.73316 50.2158 6.68416 45.8428Z" fill="currentColor"/>
<path d="M102.571 43.8238C105.727 40.5038 109.47 38.3318 115.389 36.3878C119.894 34.9078 127.461 35.4138 132.536 37.5348C140.865 41.0148 150.482 54.0848 149.698 60.8608L149.389 63.5378L133.178 63.8128C117.195 64.0828 116.946 64.0548 115.451 61.7728C114.237 59.9208 114.146 58.6918 114.992 55.6278C115.573 53.5218 115.676 51.4598 115.219 51.0458C114.763 50.6318 111.096 49.5048 107.071 48.5408L99.7532 46.7888L102.571 43.8238Z" fill="currentColor"/>
<path d="M43.6392 53.1318C45.7022 52.5908 49.8642 51.4888 52.8892 50.6838C60.3242 48.7048 60.8892 48.7298 60.8892 51.0378C60.8892 52.1378 60.9162 53.3688 60.9482 53.7728C61.0102 54.5398 44.6862 58.9698 41.6392 59.0128C40.4402 59.0298 39.8892 58.2638 39.8892 56.5768C39.8892 54.5738 40.5882 53.9318 43.6392 53.1318Z" fill="currentColor"/>
<path d="M88.8302 53.7728C88.8622 53.3688 88.8892 52.1378 88.8892 51.0378C88.8892 48.6388 88.5972 48.6558 96.2452 50.5988C110.52 54.2238 109.889 53.9478 109.889 56.5768C109.889 58.2638 109.338 59.0298 108.139 59.0128C105.092 58.9698 88.7682 54.5398 88.8302 53.7728Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.8892 58.7118C45.5642 60.6338 39.6242 62.9558 38.6892 63.8718C37.5332 65.0048 36.1732 70.3818 34.4392 80.6738C31.3672 98.9088 31.3622 99.0958 33.9282 101.849C35.6962 103.747 37.0262 104.038 43.9282 104.038H51.8892V97.5378C51.8892 93.8708 52.3252 91.0378 52.8892 91.0378C53.4532 91.0378 53.8892 93.8708 53.8892 97.5378V104.038H74.8892H95.8892V97.5378C95.8892 93.8708 96.3252 91.0378 96.8892 91.0378C97.4532 91.0378 97.8892 93.8708 97.8892 97.5378V104.038H105.715C112.801 104.038 113.766 103.798 115.926 101.499L118.311 98.9598L115.568 82.2488C113.641 70.5118 112.313 65.0418 111.107 63.8698C110.162 62.9528 104.439 60.7508 98.3892 58.9768C92.3392 57.2028 86.9262 55.5808 86.3602 55.3728C85.7942 55.1638 83.1122 59.5038 80.4002 65.0158C77.6882 70.5278 75.2082 75.0378 74.8892 75.0378C74.5702 75.0378 72.0952 70.5378 69.3892 65.0378C66.6832 59.5378 64.2262 55.0788 63.9292 55.1278C63.6322 55.1778 58.2142 56.7898 51.8892 58.7118ZM94.8892 77.0378C103.111 77.0378 104.889 76.7708 104.889 75.5378C104.889 74.3048 103.111 74.0378 94.8892 74.0378C86.6672 74.0378 84.8892 74.3048 84.8892 75.5378C84.8892 76.7708 86.6672 77.0378 94.8892 77.0378ZM94.8892 81.5378C94.8892 82.6708 93.6672 83.0378 89.8892 83.0378C86.1112 83.0378 84.8892 82.6708 84.8892 81.5378C84.8892 80.4048 86.1112 80.0378 89.8892 80.0378C93.6672 80.0378 94.8892 80.4048 94.8892 81.5378ZM101.889 83.0378C103.889 83.0378 104.889 82.5378 104.889 81.5378C104.889 80.5378 103.889 80.0378 101.889 80.0378C99.8892 80.0378 98.8892 80.5378 98.8892 81.5378C98.8892 82.5378 99.8892 83.0378 101.889 83.0378Z" fill="currentColor"/>
</svg>
