<script lang="ts">
import { onMount } from 'svelte'
import { utilService } from '../../utils/util.service'
import { _ } from 'svelte-i18n'
import CmpLoader from '../common/loader/CmpLoader.svelte'
import * as Sentry from '@sentry/browser'

let loading = true // Variable to keep track of loading state
let articles = []

onMount(async () => {
	const rssURL = import.meta.env?.PROD
		? 'https://us-central1-dogbase-app-production.cloudfunctions.net/getRSS'
		: 'https://us-central1-dogbase-app-production.cloudfunctions.net/getRSS'

	try {
		utilService.httpRequest(rssURL, (jsonArticles) => {
			setArticles(jsonArticles)
			loading = false // Set loading to false when fetching is complete
		})
	} catch (error: unknown) {
		console.error('Error fetching RSS data:', error)
		Sentry.captureException(error)
		// Handle the error if necessary
		loading = false // Set loading to false in case of an error
	}
})

function setArticles(
	jsonArticles: {
		title: string
		link: string
		description: string
		mediaThumbnail: string
		pubDate: string
	}[]
) {
	//? If there is no articles return
	// TODO(or-agami): render a 'no news' message where there is no articles
	if (!(jsonArticles && jsonArticles.length > 0)) return

	const parser = new DOMParser()

	// Sorting articles by pubDate in descending order (latest first)
	const sortedArticles = jsonArticles.sort((a, b) => {
		const dateA = new Date(a.pubDate)
		const dateB = new Date(b.pubDate)

		return dateB.getTime() - dateA.getTime()
	})

	articles = sortedArticles.slice(0, 3).map(({ title, link, description, mediaThumbnail }) => {
		const docTitle = parser.parseFromString(title, 'text/html')
		const docDescription = parser.parseFromString(description, 'text/html')

		// Extract the text content
		const sanitizedTitle = docTitle.body.textContent || ''
		const sanitizedDescription = docDescription.body.textContent || ''

		return {
			title: sanitizedTitle,
			url: link,
			description: sanitizedDescription,
			imgUrl: mediaThumbnail
		}
	})
}
</script>

<div class="flex-column rss-news">
	<header class="flex align-center space-between header">
		<h1>{$_('blog')}</h1>
		<a
			class="all-news"
			href="https://www.dogbase.co/blog"
			target="_blank"
			rel="noreferrer">
			{$_('see_all')}
		</a>
	</header>
	{#if loading}
		<!-- Show CmpLoader while loading is true -->
		<div class="preloader">
			<CmpLoader />
		</div>
	{:else if articles.length === 0}
		<p>{$_('no_news_message')}</p>
	{:else}
		{#each articles as article}
			<a
				href={article.url}
				target="_blank"
				rel="noreferrer">
				<article class="flex article">
					<div class="img-container">
						<img
							src={article.imgUrl}
							alt="dog-hero" />
					</div>
					<div class="flex-column content">
						<h1 class="title">{article.title}</h1>
						<p class="description">{article.description}</p>
					</div>
				</article>
			</a>
		{/each}
	{/if}
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.rss-news {
	@include mixins.card;
	height: 450px;
	flex: 1;
}

.header {
	color: #5e5873;
	padding: 17px 20px;
	color: #5e5873;

	h1 {
		font-size: 22px;
	}

	.all-news {
		font-size: 12px;
		text-decoration: underline;
	}
}

.preloader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 240px;
	margin-top: 60px;
}

.article {
	gap: 12px;
	font-size: 0.9em;
	margin-inline: 18px;
	margin-bottom: 18px;

	.img-container {
		min-width: 120px;
		width: 120px;
		height: 112px;
		box-shadow: var(--shadow-card);
		border-radius: 6px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&:hover {
		.title {
			text-decoration: underline;
		}
	}

	.content {
		gap: 4px;

		> * {
			display: -webkit-box;
			overflow: hidden;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			white-space: normal;
		}

		.title {
			color: var(--clr-txt-dark-primary);
			font-family: Nunito;
			font-size: 16px;
			font-family: Nunito-SemiBold;

			-webkit-line-clamp: 2;
		}

		.description {
			color: var(--clr-txt-dark-secondary);
			-webkit-line-clamp: 3;
		}
	}
}
</style>
