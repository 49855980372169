<svg
	width="80"
	height="80"
	viewBox="0 0 80 80"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#EEEDFD" />
	<path
		opacity="0.5"
		d="M20 40C20 30.5719 20 25.8579 22.9289 22.9289C25.8579 20 30.5719 20 40 20C49.4281 20 54.1421 20 57.0711 22.9289C60 25.8579 60 30.5719 60 40C60 49.4281 60 54.1421 57.0711 57.0711C54.1421 60 49.4281 60 40 60C30.5719 60 25.8579 60 22.9289 57.0711C20 54.1421 20 49.4281 20 40Z"
		fill="#6C5DD3" />
	<path
		d="M51.1524 36.9603C51.6827 36.3239 51.5967 35.3781 50.9603 34.8477C50.3239 34.3174 49.3781 34.4033 48.8477 35.0398L45.2539 39.3523C44.5123 40.2422 44.0569 40.783 43.6818 41.1218C43.5078 41.2789 43.4046 41.3415 43.3551 41.3655C43.3451 41.3704 43.3379 41.3733 43.3334 41.375C43.3334 41.375 43.3248 41.3716 43.3181 41.3685L43.3116 41.3655C43.2622 41.3415 43.159 41.2789 42.985 41.1218C42.6098 40.783 42.1544 40.2422 41.4128 39.3523L40.828 38.6505L40.828 38.6505C40.1711 37.862 39.5789 37.1512 39.0257 36.6517C38.4238 36.1082 37.6657 35.6241 36.6667 35.6241C35.6677 35.6241 34.9096 36.1082 34.3077 36.6517C33.7545 37.1512 33.1623 37.8621 32.5054 38.6505L28.8477 43.0398C28.3174 43.6762 28.4033 44.622 29.0398 45.1524C29.6762 45.6827 30.622 45.5967 31.1524 44.9603L34.7462 40.6478C35.4877 39.7579 35.9431 39.217 36.3183 38.8783C36.4923 38.7211 36.5955 38.6585 36.645 38.6346C36.6503 38.632 36.6548 38.63 36.6585 38.6284L36.6667 38.6251C36.6712 38.6268 36.6784 38.6297 36.6884 38.6346C36.7379 38.6585 36.8411 38.7211 37.0151 38.8783C37.3903 39.217 37.8457 39.7579 38.5873 40.6478L39.1721 41.3495L39.1721 41.3496C39.829 42.138 40.4212 42.8488 40.9744 43.3484C41.5763 43.8919 42.3344 44.3759 43.3334 44.3759C44.3324 44.3759 45.0905 43.8919 45.6924 43.3484C46.2455 42.8488 46.8378 42.138 47.4947 41.3496L51.1524 36.9603Z"
		fill="#6C5DD3" />
	<path
		d="M60 26C60 29.3137 57.3137 32 54 32C50.6863 32 48 29.3137 48 26C48 22.6863 50.6863 20 54 20C57.3137 20 60 22.6863 60 26Z"
		fill="#6C5DD3" />
</svg>
