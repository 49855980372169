<script lang="ts">
export let values: {
	text: string
	value: string
}

values.value = values.text
</script>

<h1 class="header-tile">{values.value}</h1>

<style lang="scss">
.header-tile {
	font-family: Nunito-SemiBold;
	font-size: 1.5rem;
}
</style>
