<script lang="ts">
import '../../../styles/themes/_globals.scss'
import 'instantsearch.css/themes/satellite.css'
import type { TrainingModel } from '../../../models/training.model'
import NameInitials from '../../common/NameInitials.svelte'
import NoTrainingAnimation from '../../common/animation/NoTrainingAnimation.svelte'
import { Link } from 'svelte-navigator'
import { currAccount } from '../../../store/stores'
import { _ } from 'svelte-i18n'
import { useNavigate } from 'svelte-navigator'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import { utilService } from '../../../utils/util.service'
import TrainingEvent from '../../trainings/training/TrainingEvent.svelte'

const navigate = useNavigate()

export let trainings: TrainingModel[]
export let disabled: boolean
</script>

<div id="searchbox" />
<section class="flex-column training-list-header-algolia">
	<header class="header-container">
		<ul class="header">
			<li class="type">{$_('type')}</li>
			<li class="team-name">{$_('team_name')}</li>
			<li class="handler">{$_('training_by')}</li>
			<li class="dog">{$_('dog')}</li>
			<li class="training-time">{$_('training_time')}</li>
			<li class="date">{$_('date')}</li>
			<li class="event">{$_('event')}</li>
		</ul>
	</header>
	<div id="hits" />
</section>

{#if !trainings}
	<CmpLoader />
{:else if trainings.length == 0}
	<div class="flex-column align-center no-trainings-animation">
		<NoTrainingAnimation />
		{#if disabled}
			<button
				{disabled}
				class="btn theme-btn btn-add-dog">
				{$currAccount.dogIds.length > 0 ? $_('add_training') : $_('add_dog')}
			</button>
		{:else}
			<Link to={$currAccount.dogIds.length > 0 ? '/trainings/types' : '/dogs/add'}>
				<button
					{disabled}
					class="btn theme-btn btn-add-dog">
					{$currAccount.dogIds.length > 0 ? $_('add_training') : $_('add_dog')}
				</button>
			</Link>
		{/if}
	</div>
{:else if trainings && trainings.length > 0}
	<section class="flex-column training-list">
		<header class="header-container">
			<ul class="header">
				<li class="type">{$_('type')}</li>
				<li class="handler">{$_('training_by')}</li>
				<li class="dog">{$_('dog')}</li>
				<li class="training-time">{$_('training_time')}</li>
				<li class="date">{$_('date')}</li>
				<li class="event">{$_('event')}</li>
			</ul>
		</header>
		<div class="flex-column content">
			{#each trainings as training}
				<button
					class="dog-preview-container"
					on:click={() =>
						navigate(`/trainings/${training.id}`, { state: { pageName: $_('training_details') } })}>
					<ul class="dog-preview">
						<li class="flex align-center training-name">
							<div class="training-img-container">
								{#if training.iconUrl}
									<img
										class="type-icon"
										src={training.iconUrl}
										alt="training profile"
										width="40px"
										height="40px" />
								{/if}
							</div>
							<p class="training-type-name">
								{training.type}
							</p>
						</li>
						<li class="handler">
							{#if training.trainedBy.image}
								<img
									class="trained-by-image"
									src={training.trainedBy.image}
									alt="trained by"
									width="40px"
									height="40px" />
							{:else}
								<div class="btn-container">
									<NameInitials
										name={training.trainedBy.name}
										theme="secondary"
										fontSize={18} />
								</div>
							{/if}
							{training.trainedBy.name || 'Without trainer'}
						</li>
						<li
							class="dog"
							title={training.dog.name}>
							<img
								class="dog-image"
								src={training.dog.image}
								alt="dog"
								width="40px"
								height="40px" />
							{training.dog.name}
						</li>
						<li class="training-time">{training.trainingTime + ' min'}</li>
						<li class="date">{utilService.getProperTime(training.dateTime.toDate())}</li>
						{#if training.event}
							<li class="event">
								<TrainingEvent event={training.event} />
							</li>
						{:else if training.category}
							<li class="event">
								<TrainingEvent event={training.category} />
							</li>
						{:else}
							<li class="event"><TrainingEvent event={'training'} /></li>
						{/if}
					</ul>
				</button>
			{/each}
		</div>
	</section>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';
.no-dogs-animation {
	width: 300px;
	margin-inline: auto;
}
.training-list-header-algolia {
	display: none;
}
.training-list {
	@include mixins.card;
	overflow-x: auto;

	.header,
	.dog-preview {
		display: grid;
		grid-template-columns:
			minmax(130px, 2fr) minmax(100px, 1fr) minmax(80px, 1fr) minmax(100px, 1fr)
			minmax(100px, 1fr) minmax(100px, 1fr);
		min-width: fit-content;
		transition: background-color 0.15s ease-out;

		> li {
			display: flex;
			align-items: center;
			gap: 8px;

			.btn-container {
				width: 40px;
				aspect-ratio: 1;
			}
		}

		> *:not(.dog-name) {
			text-align: center;
			// place-self: center;
		}

		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}

		.type {
			white-space: normal;
			position: relative;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.training-name {
			.training-type-name {
				text-align: start;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.header {
			display: none !important;
		}
		.dog-preview {
			grid-template-columns: minmax(130px, 1fr) !important;
		}
		.training-time,
		.date,
		.team-name,
		.dog,
		.event {
			display: none !important;
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);
	}

	.content {
		position: relative;
		font-size: 14px;
	}
	.dog-preview {
		border-top: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 14px;
		transition: background-color 0.15s ease-out;
		img {
			object-fit: cover;
			aspect-ratio: 1;
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);
		display: grid;
	}

	@media (max-width: 1218px) {
		.header,
		.dog-preview {
			grid-template-columns: minmax(130px, 1fr) minmax(100px, 1fr) minmax(80px, 1fr);
		}
		.training-time,
		.handler,
		.event {
			display: none !important;
		}
	}

	.hits-container {
		position: relative;
	}
}

.training-img-container {
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1000px;
	width: 40px;
	height: 40px;
	aspect-ratio: 1/1;

	img {
		filter: none;
		width: 27px;
		height: 27px;
	}
}

.no-trainings-animation {
	margin-top: 20px;
	gap: 30px;
}
</style>
