<script lang="ts">
import { useNavigate } from 'svelte-navigator'
import type { TeamModel } from '../../../models/team.model'
import { _ } from 'svelte-i18n'

export let team: TeamModel
export let sessionKeys: any

const navigate = useNavigate()

let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}
</script>

{#if !sessionKeys}
	<button
		disabled={true}
		class="edit-team"
		on:click={() => navigate(`/teams/edit/${team.id}`, { state: { pageName: $_('edit_team') } })}
		title={$_('edit_team')}>
		<div class="flex-column btn-content">
			<div class="svg-container">
				<svg
					width="38"
					height="23"
					viewBox="0 0 38 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M3.55095 1.98555C4.11279 1.04024 4.65664 0.63686 6.14111 0.0640211C6.34168 -0.013396 6.94339 -0.0217855 7.47825 0.0453252C12.2934 0.650042 12.1174 7.64849 7.2784 7.98788C4.96102 8.15015 3.22712 6.66916 3.03287 4.36127C2.94 3.25585 3.02338 2.87284 3.55095 1.98555Z"
						fill="#808080" />
					<path
						d="M27.5509 1.98555C28.1128 1.04024 28.6566 0.63686 30.1411 0.0640211C30.3417 -0.013396 30.9434 -0.0217855 31.4782 0.0453252C36.2934 0.650042 36.1174 7.64849 31.2784 7.98788C28.961 8.15015 27.2271 6.66916 27.0329 4.36127C26.94 3.25585 27.0234 2.87284 27.5509 1.98555Z"
						fill="#808080" />
					<path
						d="M13.1086 5.40216C13.4528 3.74735 15.0306 1.89872 16.5541 1.36561C18.7722 0.589338 20.6785 1.04753 22.3388 2.7563C24.3658 4.84195 24.5489 7.52182 22.8271 9.89512C21.4919 11.7353 18.5007 12.531 16.327 11.6243C13.9998 10.6535 12.5854 7.91865 13.1086 5.40216Z"
						fill="#808080" />
					<path
						d="M0.0991047 15.2029C0.483171 13.1562 2.78906 10.8452 5.07922 10.2117C7.5263 9.5348 10.3921 10.5204 12.0408 12.6057L13 13.8194L11.3778 15.4097L9.75581 17L5.09 16.9335C-0.119209 16.8591 -0.20589 16.8291 0.0991047 15.2029Z"
						fill="#808080" />
					<path
						d="M25.8075 12.7709C26.7335 11.5016 27.9956 10.6488 29.6095 10.2022C32.6729 9.35461 36.3298 11.253 37.6391 14.3706C38.0908 15.4465 38.1199 16.3056 37.7183 16.7053C37.509 16.9135 36.0684 17 32.8112 17H28.2L26.6002 15.4391L25 13.8779L25.8075 12.7709Z"
						fill="#808080" />
					<path
						d="M12.8832 14.9481C13.4192 14.5421 14.5236 13.9316 15.3372 13.5919C17.4257 12.7203 20.6649 12.8218 22.8353 13.827C23.5197 14.1439 24.4544 14.8399 25.1669 15.7248C26.7579 17.701 28.7881 21.4359 27.686 22.6838C27.4068 23 18.0632 23 18.0632 23C11.8581 23 9.54652 22.9165 9.31237 22.6838C8.17343 21.5521 10.328 16.8847 12.8832 14.9481Z"
						fill="#808080" />
					<defs>
						<linearGradient
							id="paint0_linear_0_386"
							x1="7"
							y1="0"
							x2="7"
							y2="8"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_0_386"
							x1="31"
							y1="0"
							x2="31"
							y2="8"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint2_linear_0_386"
							x1="18.5"
							y1="1"
							x2="18.5"
							y2="12"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint3_linear_0_386"
							x1="6.5"
							y1="10"
							x2="6.5"
							y2="17"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint4_linear_0_386"
							x1="31.5"
							y1="10"
							x2="31.5"
							y2="17"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint5_linear_0_386"
							x1="18.5"
							y1="13"
							x2="18.5"
							y2="23"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
					</defs>
				</svg>
			</div>
			<header class="title">
				<h1 class="flex-column action">
					<span>{$_('edit')}</span>
					<span>{$_('team')}</span>
				</h1>
			</header>
		</div>
	</button>
{:else if disabled}
	<button
		{disabled}
		class="edit-team"
		on:click={() => navigate(`/teams/edit/${team.id}`, { state: { pageName: $_('edit_team') } })}
		title={$_('edit_team')}>
		<div class="flex-column btn-content">
			<div class="svg-container">
				<svg
					width="38"
					height="23"
					viewBox="0 0 38 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M3.55095 1.98555C4.11279 1.04024 4.65664 0.63686 6.14111 0.0640211C6.34168 -0.013396 6.94339 -0.0217855 7.47825 0.0453252C12.2934 0.650042 12.1174 7.64849 7.2784 7.98788C4.96102 8.15015 3.22712 6.66916 3.03287 4.36127C2.94 3.25585 3.02338 2.87284 3.55095 1.98555Z"
						fill="#808080" />
					<path
						d="M27.5509 1.98555C28.1128 1.04024 28.6566 0.63686 30.1411 0.0640211C30.3417 -0.013396 30.9434 -0.0217855 31.4782 0.0453252C36.2934 0.650042 36.1174 7.64849 31.2784 7.98788C28.961 8.15015 27.2271 6.66916 27.0329 4.36127C26.94 3.25585 27.0234 2.87284 27.5509 1.98555Z"
						fill="#808080" />
					<path
						d="M13.1086 5.40216C13.4528 3.74735 15.0306 1.89872 16.5541 1.36561C18.7722 0.589338 20.6785 1.04753 22.3388 2.7563C24.3658 4.84195 24.5489 7.52182 22.8271 9.89512C21.4919 11.7353 18.5007 12.531 16.327 11.6243C13.9998 10.6535 12.5854 7.91865 13.1086 5.40216Z"
						fill="#808080" />
					<path
						d="M0.0991047 15.2029C0.483171 13.1562 2.78906 10.8452 5.07922 10.2117C7.5263 9.5348 10.3921 10.5204 12.0408 12.6057L13 13.8194L11.3778 15.4097L9.75581 17L5.09 16.9335C-0.119209 16.8591 -0.20589 16.8291 0.0991047 15.2029Z"
						fill="#808080" />
					<path
						d="M25.8075 12.7709C26.7335 11.5016 27.9956 10.6488 29.6095 10.2022C32.6729 9.35461 36.3298 11.253 37.6391 14.3706C38.0908 15.4465 38.1199 16.3056 37.7183 16.7053C37.509 16.9135 36.0684 17 32.8112 17H28.2L26.6002 15.4391L25 13.8779L25.8075 12.7709Z"
						fill="#808080" />
					<path
						d="M12.8832 14.9481C13.4192 14.5421 14.5236 13.9316 15.3372 13.5919C17.4257 12.7203 20.6649 12.8218 22.8353 13.827C23.5197 14.1439 24.4544 14.8399 25.1669 15.7248C26.7579 17.701 28.7881 21.4359 27.686 22.6838C27.4068 23 18.0632 23 18.0632 23C11.8581 23 9.54652 22.9165 9.31237 22.6838C8.17343 21.5521 10.328 16.8847 12.8832 14.9481Z"
						fill="#808080" />
					<defs>
						<linearGradient
							id="paint0_linear_0_386"
							x1="7"
							y1="0"
							x2="7"
							y2="8"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_0_386"
							x1="31"
							y1="0"
							x2="31"
							y2="8"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint2_linear_0_386"
							x1="18.5"
							y1="1"
							x2="18.5"
							y2="12"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint3_linear_0_386"
							x1="6.5"
							y1="10"
							x2="6.5"
							y2="17"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint4_linear_0_386"
							x1="31.5"
							y1="10"
							x2="31.5"
							y2="17"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
						<linearGradient
							id="paint5_linear_0_386"
							x1="18.5"
							y1="13"
							x2="18.5"
							y2="23"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" />
							<stop
								offset="1"
								stop-color="#808080" />
						</linearGradient>
					</defs>
				</svg>
			</div>
			<header class="title">
				<h1 class="flex-column action">
					<span>{$_('edit')}</span>
					<span>{$_('team')}</span>
				</h1>
			</header>
		</div>
	</button>
{:else if !disabled}
	<button
		{disabled}
		class="edit-team"
		on:click={() => navigate(`/teams/edit/${team.id}`, { state: { pageName: $_('edit_team') } })}
		title={$_('edit_team')}>
		<div class="flex-column btn-content">
			<div class="svg-container">
				<svg
					width="38"
					height="23"
					viewBox="0 0 38 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M3.55095 1.98555C4.11279 1.04024 4.65664 0.63686 6.14111 0.0640211C6.34168 -0.013396 6.94339 -0.0217855 7.47825 0.0453252C12.2934 0.650042 12.1174 7.64849 7.2784 7.98788C4.96102 8.15015 3.22712 6.66916 3.03287 4.36127C2.94 3.25585 3.02338 2.87284 3.55095 1.98555Z"
						fill="url(#paint0_linear_0_386)" />
					<path
						d="M27.5509 1.98555C28.1128 1.04024 28.6566 0.63686 30.1411 0.0640211C30.3417 -0.013396 30.9434 -0.0217855 31.4782 0.0453252C36.2934 0.650042 36.1174 7.64849 31.2784 7.98788C28.961 8.15015 27.2271 6.66916 27.0329 4.36127C26.94 3.25585 27.0234 2.87284 27.5509 1.98555Z"
						fill="url(#paint1_linear_0_386)" />
					<path
						d="M13.1086 5.40216C13.4528 3.74735 15.0306 1.89872 16.5541 1.36561C18.7722 0.589338 20.6785 1.04753 22.3388 2.7563C24.3658 4.84195 24.5489 7.52182 22.8271 9.89512C21.4919 11.7353 18.5007 12.531 16.327 11.6243C13.9998 10.6535 12.5854 7.91865 13.1086 5.40216Z"
						fill="url(#paint2_linear_0_386)" />
					<path
						d="M0.0991047 15.2029C0.483171 13.1562 2.78906 10.8452 5.07922 10.2117C7.5263 9.5348 10.3921 10.5204 12.0408 12.6057L13 13.8194L11.3778 15.4097L9.75581 17L5.09 16.9335C-0.119209 16.8591 -0.20589 16.8291 0.0991047 15.2029Z"
						fill="url(#paint3_linear_0_386)" />
					<path
						d="M25.8075 12.7709C26.7335 11.5016 27.9956 10.6488 29.6095 10.2022C32.6729 9.35461 36.3298 11.253 37.6391 14.3706C38.0908 15.4465 38.1199 16.3056 37.7183 16.7053C37.509 16.9135 36.0684 17 32.8112 17H28.2L26.6002 15.4391L25 13.8779L25.8075 12.7709Z"
						fill="url(#paint4_linear_0_386)" />
					<path
						d="M12.8832 14.9481C13.4192 14.5421 14.5236 13.9316 15.3372 13.5919C17.4257 12.7203 20.6649 12.8218 22.8353 13.827C23.5197 14.1439 24.4544 14.8399 25.1669 15.7248C26.7579 17.701 28.7881 21.4359 27.686 22.6838C27.4068 23 18.0632 23 18.0632 23C11.8581 23 9.54652 22.9165 9.31237 22.6838C8.17343 21.5521 10.328 16.8847 12.8832 14.9481Z"
						fill="url(#paint5_linear_0_386)" />
					<defs>
						<linearGradient
							id="paint0_linear_0_386"
							x1="7"
							y1="0"
							x2="7"
							y2="8"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#F96E6F" />
							<stop
								offset="1"
								stop-color="#EF4F50" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_0_386"
							x1="31"
							y1="0"
							x2="31"
							y2="8"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#F96E6F" />
							<stop
								offset="1"
								stop-color="#EF4F50" />
						</linearGradient>
						<linearGradient
							id="paint2_linear_0_386"
							x1="18.5"
							y1="1"
							x2="18.5"
							y2="12"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#F96E6F" />
							<stop
								offset="1"
								stop-color="#EF4F50" />
						</linearGradient>
						<linearGradient
							id="paint3_linear_0_386"
							x1="6.5"
							y1="10"
							x2="6.5"
							y2="17"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#F96E6F" />
							<stop
								offset="1"
								stop-color="#EF4F50" />
						</linearGradient>
						<linearGradient
							id="paint4_linear_0_386"
							x1="31.5"
							y1="10"
							x2="31.5"
							y2="17"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#F96E6F" />
							<stop
								offset="1"
								stop-color="#EF4F50" />
						</linearGradient>
						<linearGradient
							id="paint5_linear_0_386"
							x1="18.5"
							y1="13"
							x2="18.5"
							y2="23"
							gradientUnits="userSpaceOnUse">
							<stop stop-color="#F96E6F" />
							<stop
								offset="1"
								stop-color="#EF4F50" />
						</linearGradient>
					</defs>
				</svg>
			</div>
			<header class="title">
				<h1 class="flex-column action">
					<span>{$_('edit')}</span>
					<span>{$_('team')}</span>
				</h1>
			</header>
		</div>
	</button>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.edit-team {
	@include mixins.card;
	aspect-ratio: 1/1;
	overflow: initial;
	min-width: 160px;
	padding: 20px;

	.btn-content {
		justify-content: center;

		.svg-container {
			width: 50px;
			height: 50px;
			background-color: #f96e6f12;
			border-radius: 50%;
			padding: 10px;
			margin-bottom: auto;
		}

		.title {
			text-align: start;
			color: var(--clr-txt-dark-secondary);
			padding-top: 30px;

			span {
				font-weight: 500;

				&:first-child {
					font-size: 14px;
				}

				&:last-child {
					font-size: 24px;
				}
			}
		}
	}
}
</style>
