<script lang="ts">
export let results = { Successful: 0, Unsuccessful: 0, 'Needs-Improvement': 0 }

let total = Object.values(results).reduce((a, b) => a + b, 0)
</script>

<div class="results-bar">
	<div class="bar">
		{#each Object.entries(results) as [result, count], index}
			<div
				class="results-bar-section {result} bar-{index + 1}"
				style="width: {(count / total) * 100}%"
				title="{result}: {count}">
				<p class="distribution-text">
					{(count / total) * 100}%
				</p>
			</div>
		{/each}
	</div>
	<section class="color-explanation">
		<div class="explanation-container">
			<span class="circle circle-1" />
			<p class="explanation">Succesfull</p>
		</div>
		<div class="explanation-container">
			<span class="circle circle-3" />
			<p class="explanation">Needs Improvement</p>
		</div>
		<div class="explanation-container">
			<span class="circle circle-2" />
			<p class="explanation">Unsuccesfull</p>
		</div>
	</section>
</div>

<style lang="scss">
.results-bar {
	display: flex;
	flex-direction: column;
	max-height: 50px;
	width: 300px; /* Set a width for the results bar */
	gap: 0.2rem;

	.bar {
		display: flex;
		gap: 2px;
	}

	.color-explanation {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.explanation-container {
			display: flex;
			align-items: center;
			gap: 2px;
			.explanation {
				font-size: 0.8rem;
			}

			.circle {
				width: 5px;
				height: 5px;
				border-radius: 50%;
				display: block;
			}

			.circle-1 {
				background-color: #3d9265;
			}
			.circle-2 {
				background-color: #d65656;
			}
			.circle-3 {
				background-color: #f39b17;
			}
		}
	}

	.results-bar-section {
		display: flex;
		justify-content: flex-start;
	}

	.Successful {
		background-color: #3d9265;
	}

	.Unsuccessful {
		background-color: #d65656;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.Needs-Improvement {
		background-color: #f39b17;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	.distribution-text {
		font-size: 11px;
		padding: 3px;
		font-family: Nunito;
		color: white;
		font-weight: bold;
		text-transform: capitalize;
		text-align: center;
		margin-left: 0.25rem;
	}
}
</style>
