<script lang="ts">
import { Lottie } from 'lottie-svelte'
import { _ } from 'svelte-i18n'
</script>

<div class="container flex justify-center">
	<div class="under-development flex-column justify-center">
		<Lottie path="/assets/animations/under-development.json" />
		<h1>{$_('this_page_is_under_development')}</h1>
	</div>
</div>

<style lang="scss">
.container {
	margin-left: 30px;
	.under-development {
		width: 350px;

		h1 {
			margin-left: 20px;
		}
	}

	@media screen and (max-width: 768px) {
		margin: 0;

		.under-development {
			margin-left: 20%;
			width: 80%;

			h1 {
				margin-left: -20px;
			}
		}
	}
}
</style>
