import { fireMsg } from '../userMsg.service'

export const authValidation = {
	email,
	password,
	specialCharacter,
	fullValidation
}

export type AuthField = {
	name: string
	value: string
	invalid: boolean
	errorMessage: string
}

export interface LoginFields {
	email: AuthField
	password: AuthField
}

export interface SignupFields extends LoginFields {
	accountName: AuthField
	firstName: AuthField
	lastName: AuthField
}

function email(email: AuthField): AuthField {
	const { value } = email
	if (value.includes(' ')) return _inputError(email, 'NO_SPACE')

	const re = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
	if (!re.test(value)) return _inputError(email, 'Invalid email address')

	return { ...email, invalid: false, errorMessage: '' }
}

function password(pass: AuthField): AuthField {
	const { value } = pass
	if (value.length <= 5) {
		return _inputError(pass, 'Password must contain at least 6 characters')
	}

	if (value.includes(' ')) {
		return _inputError(pass, 'NO_SPACE')
	}

	return { ...pass, invalid: false, errorMessage: '' }
}

function specialCharacter(value: string): boolean {
	const re = /^[a-zA-Z0-9 ]{2,}$/
	return !re.test(value)
}

function fullValidation(fields: SignupFields | LoginFields): boolean {
	for (const fieldName in fields) {
		const field = fields[fieldName] as AuthField
		if (field.invalid) {
			fireMsg({
				type: 'failure',
				msg: `Error in ${field.name}: ${field.errorMessage.toLowerCase()}`
			})
			return false
		}
		if (field.value.length < 2) {
			fireMsg({ type: 'failure', msg: `${field.name} is required` })
			return false
		}
	}
	return true
}

function _inputError(field: AuthField, errorMessage: string): AuthField {
	field.invalid = true

	switch (errorMessage) {
		case 'NO_SPACE':
			return { ...field, errorMessage: 'No empty spaces allowed' }

		default:
			return { ...field, errorMessage }
	}
}
