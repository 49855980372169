<script lang="ts">
import { Lottie } from 'lottie-svelte'
import { _ } from 'svelte-i18n';
import { useNavigate } from 'svelte-navigator';

const navigate = useNavigate()
</script>

<section class="container flex-column align-center justify-center">
	<div class="animation">
		<Lottie path="/assets/animations/404-error.json" />
	</div>
	<h1>{$_('page_not_found')}</h1>
	<h2>{$_('page_error_description')}</h2>
	<button on:click={() => navigate('/')} class="btn theme-btn">{$_('back_to_home')}</button>
</section>

<style lang="scss">
.container {
	width: 100vw;
	height: 100vh;
	gap: 24px;

	.animation {
		margin-top: -100px;
		width: 350px;
	}

	h1 {
		outline: none;
		font-size: 25px;
	}
	
	h2 {
		font-size: 16px;
		font-weight: 100;
		font-family: Nunito;
		text-align: center;
		width: 400px;
	}

	button {
		padding: 12px 20px;
		margin-top: 60px;
		font-size: 13px;
	}
}
</style>
