<script lang="ts">
import { useNavigate } from 'svelte-navigator'
import { fireMsg } from '../../../utils/userMsg.service'
import { currAccount } from '../../../store/stores'
import { _ } from 'svelte-i18n'

export let sessionKeys: any

let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

const navigate = useNavigate()

let showTooltip = false
function toggleTooltip() {
	showTooltip = true
}

function hideTooltip() {
	showTooltip = false
}
</script>

{#if !sessionKeys}
	<div class="container">
		<button
			disabled={true}
			class="add-training"
			on:click={() => {
				if ($currAccount.teamIds && $currAccount.dogIds) {
					navigate('/trainings/types', { state: { pageName: $_('add_training') } })
				} else if ($currAccount.teamIds && !$currAccount.dogIds) {
					fireMsg({
						type: 'failure',
						msg: $_('no_team_message')
					})
				} else {
					fireMsg({
						type: 'failure',
						msg: $_('no_dog_message')
					})
				}
			}}
			title={$_('add_training')}>
			<div class="flex-column btn-content">
				<div class="svg-container">
					<svg
						width="32"
						height="33"
						viewBox="0 0 32 33"
						fill="#808080"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M27.367 29.8682C27.367 29.8682 28.7795 25.4176 22.8009 23.4921C22.8009 23.4921 22.8994 20.4303 21.2569 19.2308C21.2569 19.2308 21.8482 18.126 20.14 17.4316L19.9429 18.4417L18.8917 17.6841C18.8917 17.6841 18.6946 18.4101 18.8917 18.7573C18.8917 18.7573 18.0376 19.1676 18.0047 19.6411L15.9023 20.4618C15.9023 20.4618 16.0337 20.8406 16.4937 21.03C16.4937 21.03 16.6251 22.1347 18.9245 21.7559C18.9245 21.7559 18.366 25.0071 19.0887 26.9641C19.0887 26.9641 19.2859 30.0575 17.9062 30.9414C17.9062 30.9414 17.1834 31.4464 17.3806 31.8883H18.8259C18.8259 31.8883 20.6983 29.1737 20.6656 28.2899L21.4539 28.5424C21.4539 28.5424 20.994 29.4578 21.9138 30.5626C21.9138 30.5626 19.5486 30.8467 20.0742 31.8883H25.1659C25.1659 31.8883 26.2499 31.5726 26.447 30.8782C26.447 30.8782 32.13 34.6029 31.9986 29.1105C31.9988 29.1107 29.6336 30.752 27.367 29.8682Z"
							fill="#808080" />
						<path
							d="M8.00956 8.39528C6.50774 5.45405 1.95174 6.13237 1.43665 9.358L0.0330051 18.1481C-0.182067 19.5278 0.672305 20.8734 2.06098 21.3181V30.7748C2.06098 31.5138 2.68448 32.1129 3.45349 32.1129C4.2225 32.1129 4.84599 31.5138 4.84599 30.7748V20.8588C5.90367 20.0697 5.75683 19.9048 7.79484 12.4057L12.1165 17.5642C12.5977 18.1399 13.4757 18.2309 14.0741 17.7678C14.6731 17.3042 14.7683 16.462 14.2855 15.8865L8.00956 8.39528Z"
							fill="#808080" />
						<path
							d="M7.97884 6.0212C9.70923 6.0212 11.112 4.67331 11.112 3.0106C11.112 1.34789 9.70923 0 7.97884 0C6.24846 0 4.8457 1.34789 4.8457 3.0106C4.8457 4.67331 6.24846 6.0212 7.97884 6.0212Z"
							fill="#808080" />
					</svg>
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('add')}</span>
						<span class={localStorage.language == 'es' ? 'small-letters' : ''}
							>{$_('training_session')}</span>
					</h1>
				</header>
			</div>
		</button>
	</div>
{:else if disabled}
	<div
		class="container"
		on:mouseover={toggleTooltip}
		on:mouseout={hideTooltip}
		on:focus={toggleTooltip}
		on:blur={hideTooltip}
		aria-hidden={!showTooltip}>
		<button
			{disabled}
			class="add-training"
			on:click={() => {
				if ($currAccount.teamIds && $currAccount.dogIds) {
					navigate('/trainings/types', { state: { pageName: $_('add_training') } })
				} else if ($currAccount.teamIds && !$currAccount.dogIds) {
					fireMsg({
						type: 'failure',
						msg: $_('no_team_message')
					})
				} else {
					fireMsg({
						type: 'failure',
						msg: $_('no_dog_message')
					})
				}
			}}
			title={$_('add_training')}>
			<div class="flex-column btn-content">
				<div class="svg-container">
					<svg
						width="32"
						height="33"
						viewBox="0 0 32 33"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M27.367 29.8682C27.367 29.8682 28.7795 25.4176 22.8009 23.4921C22.8009 23.4921 22.8994 20.4303 21.2569 19.2308C21.2569 19.2308 21.8482 18.126 20.14 17.4316L19.9429 18.4417L18.8917 17.6841C18.8917 17.6841 18.6946 18.4101 18.8917 18.7573C18.8917 18.7573 18.0376 19.1676 18.0047 19.6411L15.9023 20.4618C15.9023 20.4618 16.0337 20.8406 16.4937 21.03C16.4937 21.03 16.6251 22.1347 18.9245 21.7559C18.9245 21.7559 18.366 25.0071 19.0887 26.9641C19.0887 26.9641 19.2859 30.0575 17.9062 30.9414C17.9062 30.9414 17.1834 31.4464 17.3806 31.8883H18.8259C18.8259 31.8883 20.6983 29.1737 20.6656 28.2899L21.4539 28.5424C21.4539 28.5424 20.994 29.4578 21.9138 30.5626C21.9138 30.5626 19.5486 30.8467 20.0742 31.8883H25.1659C25.1659 31.8883 26.2499 31.5726 26.447 30.8782C26.447 30.8782 32.13 34.6029 31.9986 29.1105C31.9988 29.1107 29.6336 30.752 27.367 29.8682Z"
							fill="#808080" />
						<path
							d="M8.00956 8.39528C6.50774 5.45405 1.95174 6.13237 1.43665 9.358L0.0330051 18.1481C-0.182067 19.5278 0.672305 20.8734 2.06098 21.3181V30.7748C2.06098 31.5138 2.68448 32.1129 3.45349 32.1129C4.2225 32.1129 4.84599 31.5138 4.84599 30.7748V20.8588C5.90367 20.0697 5.75683 19.9048 7.79484 12.4057L12.1165 17.5642C12.5977 18.1399 13.4757 18.2309 14.0741 17.7678C14.6731 17.3042 14.7683 16.462 14.2855 15.8865L8.00956 8.39528Z"
							fill="#808080" />
						<path
							d="M7.97884 6.0212C9.70923 6.0212 11.112 4.67331 11.112 3.0106C11.112 1.34789 9.70923 0 7.97884 0C6.24846 0 4.8457 1.34789 4.8457 3.0106C4.8457 4.67331 6.24846 6.0212 7.97884 6.0212Z"
							fill="#808080" />
					</svg>
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('add')}</span>
						<span class={localStorage.language == 'es' ? 'small-letters' : ''}
							>{$_('training_session')}</span>
					</h1>
				</header>
			</div>
		</button>
		{#if showTooltip}
			<div class="tooltip">{$_('locked_feature')}</div>
		{/if}
	</div>
{:else if !disabled}
	<div class="container">
		<button
			{disabled}
			class="add-training"
			on:click={() => {
				if ($currAccount.teamIds && $currAccount.dogIds) {
					navigate('/trainings/types', { state: { pageName: $_('add_training') } })
				} else if ($currAccount.teamIds && !$currAccount.dogIds) {
					fireMsg({
						type: 'failure',
						msg: $_('no_team_message')
					})
				} else {
					fireMsg({
						type: 'failure',
						msg: $_('no_dog_message')
					})
				}
			}}
			title={$_('add_training')}>
			<div class="flex-column btn-content">
				<div class="svg-container">
					<svg
						width="32"
						height="33"
						viewBox="0 0 32 33"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M27.367 29.8682C27.367 29.8682 28.7795 25.4176 22.8009 23.4921C22.8009 23.4921 22.8994 20.4303 21.2569 19.2308C21.2569 19.2308 21.8482 18.126 20.14 17.4316L19.9429 18.4417L18.8917 17.6841C18.8917 17.6841 18.6946 18.4101 18.8917 18.7573C18.8917 18.7573 18.0376 19.1676 18.0047 19.6411L15.9023 20.4618C15.9023 20.4618 16.0337 20.8406 16.4937 21.03C16.4937 21.03 16.6251 22.1347 18.9245 21.7559C18.9245 21.7559 18.366 25.0071 19.0887 26.9641C19.0887 26.9641 19.2859 30.0575 17.9062 30.9414C17.9062 30.9414 17.1834 31.4464 17.3806 31.8883H18.8259C18.8259 31.8883 20.6983 29.1737 20.6656 28.2899L21.4539 28.5424C21.4539 28.5424 20.994 29.4578 21.9138 30.5626C21.9138 30.5626 19.5486 30.8467 20.0742 31.8883H25.1659C25.1659 31.8883 26.2499 31.5726 26.447 30.8782C26.447 30.8782 32.13 34.6029 31.9986 29.1105C31.9988 29.1107 29.6336 30.752 27.367 29.8682Z"
							fill="#6C5DD3" />
						<path
							d="M8.00956 8.39528C6.50774 5.45405 1.95174 6.13237 1.43665 9.358L0.0330051 18.1481C-0.182067 19.5278 0.672305 20.8734 2.06098 21.3181V30.7748C2.06098 31.5138 2.68448 32.1129 3.45349 32.1129C4.2225 32.1129 4.84599 31.5138 4.84599 30.7748V20.8588C5.90367 20.0697 5.75683 19.9048 7.79484 12.4057L12.1165 17.5642C12.5977 18.1399 13.4757 18.2309 14.0741 17.7678C14.6731 17.3042 14.7683 16.462 14.2855 15.8865L8.00956 8.39528Z"
							fill="#6C5DD3" />
						<path
							d="M7.97884 6.0212C9.70923 6.0212 11.112 4.67331 11.112 3.0106C11.112 1.34789 9.70923 0 7.97884 0C6.24846 0 4.8457 1.34789 4.8457 3.0106C4.8457 4.67331 6.24846 6.0212 7.97884 6.0212Z"
							fill="#6C5DD3" />
					</svg>
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('add')}</span>
						<span class={localStorage.language == 'es' ? 'small-letters' : ''}
							>{$_('training_session')}</span>
					</h1>
				</header>
			</div>
		</button>
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.small-letters {
	font-size: 18px !important;
}

.container {
	position: relative;
	margin: 0;
	padding: 0;
	.add-training {
		@include mixins.card;
		aspect-ratio: 1/1;
		min-width: 160px;
		padding: 20px;

		.btn-content {
			justify-content: center;

			.svg-container {
				width: 50px;
				height: 50px;
				background-color: #eeedfd;
				border-radius: 50%;
				padding: 9px;
				margin-bottom: auto;
			}

			.title {
				text-align: start;
				color: var(--clr-txt-dark-secondary);
				padding-top: 30px;

				span {
					font-weight: 500;

					&:first-child {
						font-size: 14px;
					}

					&:last-child {
						font-size: 24px;
					}
				}
			}
		}
	}
}

.tooltip {
	visibility: hidden;
	opacity: 0;
	background: #333;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 12px;
	position: absolute;
	z-index: 1;
	bottom: 105%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	min-width: 110px;
}
.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #333 transparent transparent transparent;
}
.container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}
</style>
