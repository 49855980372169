<script lang="ts">
import { Lottie } from 'lottie-svelte'
import { _ } from 'svelte-i18n'
import { useNavigate } from 'svelte-navigator'

const navigate = useNavigate()
</script>

<div
	class="no-training-animation flex-column align-center">
	<div class="animation">
		<Lottie path="/assets/animations/custom-training.json" />
	</div>
	<button class="btn theme-btn" on:click={() => navigate(`/help-center`)}>{$_('contact_support')}</button>
	<p>{$_('about_training_types')}</p>
</div>

<style lang="scss">
.no-training-animation {
	width: 100%;
	height: fit-content;
	margin-bottom: 20px;
	.animation {
		margin-bottom: 30px;
		width: 400px;
	}
	p {
		color: var(--clr-txt-dark-secondary);
		font-size: 15px;
		margin-top: 20px;
		text-align: center;
	}
}
</style>
