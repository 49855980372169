<script lang="ts">
import Select from 'svelte-select/Select.svelte'
import { loggedInUser } from '../../store/stores'
import { onMount } from 'svelte'
import { accountService } from '../../services/core/account.service'
import type { AccountModel } from '../../models/account.model'
import { utilService } from '../../utils/util.service'
import { fireMsg } from '../../utils/userMsg.service'
import { _ } from 'svelte-i18n'

onMount(() => {
	loadAccounts()
})

//Loading account names for selector
function loadAccounts() {
	$loggedInUser.accountIds.forEach(async (accountId) => {
		accountPromise = accountService.getById(accountId)
		let account = await accountPromise
		if (account) {
			options.push({
				value: account.id,
				label: account.name
			})
			accounts.push(account)
		}
	})
}

async function handleSubmit() {
	const url = `${import.meta.env.VITE_APP_API_URL}/selectedaccount`

	let body = {
		id: selectedaccount.value,
		name: selectedaccount.label
	}

	if ($loggedInUser.selectedAccount.id == body.id) {
		//If account already switched
		fireMsg({ type: 'failure', msg: $_('account_already') + body.name })
	} else {
		try {
			await utilService.restRequest(url, 'PATCH', body)
			window.location.reload()
		} catch (error: unknown) {
			fireMsg({ type: 'failure', msg: $_('error_switching_accounts') })
		}
	}
}

let accountPromise: Promise<AccountModel>
let accounts: AccountModel[] = []

let options = []

let selectedaccount = {
	value: $loggedInUser.selectedAccount.id,
	label: $loggedInUser.selectedAccount.name
}
</script>

<div class="account-selector flex align-center">
	{#if $loggedInUser.accountIds.length < 2}
		<label for="select">{$_('current_account')}: {$loggedInUser.selectedAccount.name} </label>
	{:else}
		<label for="select">{$_('current_account')}</label>
		{#await accountPromise}
			<Select
				loading={true}
				clearable={false}
				placeholder={$_('select_account')} />
		{:then}
			<Select
				id="select"
				placeholder={$_('select_account')}
				showChevron={true}
				clearable={false}
				bind:value={selectedaccount}
				items={options} />
		{/await}
		<button
			class="btn theme-btn submit"
			on:click={handleSubmit}>{$_('switch_account')}</button>
	{/if}
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.account-selector {
	@include mixins.card;
	padding: 10px 16px;
	overflow: visible;
	height: 100%;
	gap: 20px;

	label {
		font-size: 16px;
		font-family: Nunito-SemiBold;
		white-space: nowrap;
		width: fit-content;
		color: #33383f;
	}

	.submit {
		width: fit-content;
		white-space: nowrap;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		gap: 12px;

		label {
			margin: 0 auto 0 0;
		}

		.submit {
			margin: 0 0 0 auto;
		}
	}
}
</style>
