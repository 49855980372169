<script lang="ts">
import { onMount } from 'svelte'
import { insightsService } from '../../services/insights/data.service'
import exporting from 'highcharts/modules/exporting'
import * as Highcharts from 'highcharts'
import { _ } from 'svelte-i18n'
import { utilService } from '../../utils/util.service'

export let comparisonIds = []
export let category = undefined
export let infoId = ''
export let data: any

let chart: Highcharts.Chart
let sessionKeys

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()

	if (data) {
		const dataForDisplay = insightsService.getTotalTrainingDays(data, infoId, category)

		if (comparisonIds.length > 0) {
			dataForDisplay.datasets = dataForDisplay.datasets.filter((dataset) =>
				comparisonIds.includes(dataset.id)
			)
		}

		generateChart(dataForDisplay)
	}
})

function generateChart(data) {
	if (sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing') {
		exporting(Highcharts)
	}

	const colorList = [
		'#fd7f6f',
		'#7eb0d5',
		'#b2e061',
		'#bd7ebe',
		'#ffb55a',
		'#ffee65',
		'#beb9db',
		'#fdcce5',
		'#8bd3c7'
	]

	data.datasets.sort((a, b) => a.label.localeCompare(b.label))

	const config: Highcharts.Options = {
		chart: {
			type: 'column',
			borderRadius: 5
		},
		title: {
			text: ''
		},
		xAxis: {
			categories: data.labels,
			lineWidth: 0.1,
			labels: {
				style: {
					opacity: 0.6
				}
			}
		},
		yAxis: {
			title: {
				text: $_('days')
			},
			gridLineWidth: 0.6,
			minorGridLineWidth: 0,
			labels: {
				formatter: function () {
					return this.value + ''
				},
				style: {
					opacity: 0.6
				}
			}
		},
		tooltip: {
			formatter: function () {
				const month = this.x
				const value = this.series.name + ': ' + Highcharts.numberFormat(this.y, 0, ',', '')
				return (
					'<span style="font-weight: normal; font-size: 11px">' +
					month +
					'</span><br/><b>' +
					value +
					' days</b>'
				)
			}
		},
		plotOptions: {
			column: {
				pointWidth: 10,
				borderWidth: 0,
				groupPadding: 0
			}
		},
		series: data.datasets.map((dataset, index) => {
			return {
				name: dataset.label,
				data: dataset.data,
				color: colorList[index % colorList.length]
			}
		}),
		legend: {
			enabled: !(!category || category === 'all'),
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'top',
			title: {
				text: ''
			}
		},
		navigation: {
			buttonOptions: {
				align: 'right'
			}
		}
	}

	chart = Highcharts.chart('total-training-days', config)
}
</script>

<h1 class="title">{$_(`insights_pages.total_training_days`)}</h1>
<div
	class="chart-container"
	style={!(!category || category === 'all') ? 'padding-top: 5px' : 'padding-top: 30px'}>
	<div id="total-training-days" />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.chart-container {
	@include mixins.card;
	background-color: #fff;
	border-radius: 6px;
}

.title {
	font-family: Montserrat-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
</style>
