<script lang="ts">
import Select from 'svelte-select'
import { _ } from 'svelte-i18n'
import { dropdownBottomSheet } from '../../../store/trainingWidgetsStore'

export let values: {
	items: any[]
	value: string
	returnedValue?: { value: string; label: string }
	filled: boolean
}
export let data = []
export let id: string = null

let items = values.items.map((item) => ({ value: item, label: item }))

if (!$dropdownBottomSheet[id]) {
	$dropdownBottomSheet[id] = ''
}

$: {
	if (values.returnedValue != null) {
		values.filled = true
	} else values.filled = false
}

if (data.length > 0 && $dropdownBottomSheet[id] == '') {
	values.returnedValue = { value: data[0].value, label: data[0].value }
}

$: values.value = values.returnedValue == null ? '' : values.returnedValue.value
</script>

<Select
	on:change={() => ($dropdownBottomSheet[id] = values.returnedValue)}
	showChevron={true}
	clearable={false}
	placeholder={$_('please_select')}
	{items}
	bind:value={values.returnedValue} />
