<svg
	width="80"
	height="80"
	viewBox="0 0 80 80"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#EEF0F7" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M24 25.3887V53.3887C24 56.7024 26.6863 59.3887 30 59.3887H50C53.3137 59.3887 56 56.7024 56 53.3887V33.3887C56 30.075 53.3137 27.3887 50 27.3887H26C24.8954 27.3887 24 26.4932 24 25.3887ZM30.5 39.3887C30.5 38.5602 31.1716 37.8887 32 37.8887H48C48.8284 37.8887 49.5 38.5602 49.5 39.3887C49.5 40.2171 48.8284 40.8887 48 40.8887H32C31.1716 40.8887 30.5 40.2171 30.5 39.3887ZM30.5 46.3887C30.5 45.5602 31.1716 44.8887 32 44.8887H43C43.8284 44.8887 44.5 45.5602 44.5 46.3887C44.5 47.2171 43.8284 47.8887 43 47.8887H32C31.1716 47.8887 30.5 47.2171 30.5 46.3887Z"
		fill="url(#paint0_linear_548_22431)" />
	<path
		opacity="0.5"
		d="M52 24.0008V27.7301C51.3744 27.509 50.7013 27.3887 50 27.3887H26C24.8954 27.3887 24 26.4932 24 25.3887V25.2466C24 24.1805 24.7839 23.2767 25.8392 23.126L47.4343 20.041C49.844 19.6967 52 21.5666 52 24.0008Z"
		fill="url(#paint1_linear_548_22431)" />
	<defs>
		<linearGradient
			id="paint0_linear_548_22431"
			x1="38.5483"
			y1="75.7446"
			x2="71.0889"
			y2="46.8002"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264A59" />
			<stop
				offset="1"
				stop-color="#2D7392" />
		</linearGradient>
		<linearGradient
			id="paint1_linear_548_22431"
			x1="36.7298"
			y1="31.4487"
			x2="40.7396"
			y2="17.7219"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264A59" />
			<stop
				offset="1"
				stop-color="#2D7392" />
		</linearGradient>
	</defs>
</svg>
