<script lang="ts">
import { loggedInUser } from '../store/stores'
import EmailIcon from '../cmps/users/user/icons/EmailIcon.svelte'
import NameInitials from '../cmps/common/NameInitials.svelte'
import { _ } from 'svelte-i18n'
import moment from 'moment'
import CreatedDateIcon from '../cmps/users/user/icons/CreatedDateIcon.svelte'
import { useNavigate } from 'svelte-navigator'
import AccountSelector from '../cmps/profile/AccountSelector.svelte'
import { utilService } from '../utils/util.service'
import TypeIcon from '../cmps/users/user/icons/TypeIcon.svelte'
import { onMount } from 'svelte'

const navigate = useNavigate()

onMount(() => {
})

$: userInfo = [
	{
		title: $_('email'),
		icon: EmailIcon,
		value: $loggedInUser.email
	},
	{
		title: $_('created_at'),
		icon: CreatedDateIcon,
		value: utilService.capitalizeFirstLetter(
			moment($loggedInUser.createdAt.toDate()).format('MMMM[,] DD YYYY')
		)
	},
	{
		title: 'type',
		icon: TypeIcon,
		value: $loggedInUser.type
	}
]
$: userName = $loggedInUser.firstName + ' ' + $loggedInUser.lastName
</script>

<div class="profile flex-column">
	<div class="account-selector">
		<AccountSelector />
	</div>
	<section class="user-info">
		<header class="header">
			<h1 class="title">{$_('your_info')}</h1>
		</header>
		<div class="align-center space-around content">
			<div class="flex align-center info name">
				<div class="svg-container">
					<NameInitials name={userName} />
				</div>
				<h1 class="name">{userName}</h1>
			</div>
			{#each userInfo as info}
				<div class="flex align-center info type">
					<div class="svg-container">
						<svelte:component this={info.icon} />
					</div>
					<div class="flex-column description">
						<h1 class="title">{$_(info.title)}</h1>
						<h2 class="value {info.title}">{$_(info.value.toString())}</h2>
					</div>
				</div>
			{/each}
		</div>
	</section>
	<div class="flex edit-user-container">
		<button
			on:click={() => navigate(`/profile/edit`, { state: { pageName: $_('edit_profile') } })}
			class="btn theme-btn edit-user">
			{$_('edit_info')}
		</button>
	</div>
</div>

<style lang="scss">
@use '../styles/setup/mixins';

.profile {
	gap: 20px;

	.user-info {
		@include mixins.card;

		.header {
			padding: 8px 12px 22px;
			color: var(--clr-txt-dark-primary);

			h1 {
				font-size: 22px;
			}
		}

		.content {
			display: grid;
			grid-template-rows: auto;
			justify-items: center;
			grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));

			.info {
				padding-inline: 20px;
				margin-bottom: 30px;
				text-transform: capitalize;
				min-width: 230px;

				&:not(.user-name) {
					gap: 16px;
				}

				.name {
					font-size: 15px;
					font-family: Nunito;
					font-weight: normal;
				}

				.email {
					text-transform: none;

					&::first-letter {
						text-transform: uppercase;
					}
				}

				.svg-container {
					width: 48px;
					height: 48px;
					min-width: 48px;
				}

				.description {
					width: fit-content;

					.title {
						font-size: 14px;
						font-family: Nunito;
						font-weight: normal;
					}

					.value {
						font-size: 16px;
						font-weight: 500;
						white-space: nowrap;
						text-transform: none;
					}
				}
			}
		}
	}

	.edit-user-container {
		justify-content: flex-end;
		align-items: flex-start;
	}
}
</style>
