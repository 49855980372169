<script lang="ts">
import { Lottie } from 'lottie-svelte'
import { _ } from 'svelte-i18n';
</script>

<section class="container flex-column">
	<div class="no-dog-animation">
		<Lottie path="/assets/animations/no-dog-animation.json" />
	</div>
	<h1>{$_('nothing_here')}</h1>
	<p>{$_('add_first_dog')}</p>
</section>

<style lang="scss">
.container {
	align-items: center;
	width: 425px;

	.no-dog-animation {
		margin-left: -30px;
		height: 250px;
	}

	h1 {
		margin: 50px 0 0 0;
	}
}
</style>
