<script lang="ts">
import LocaleSwitcher from '../../../i18n/LocaleSwitcher.svelte'
import { userMsg } from '../../../utils/userMsg.service'
import SelectButton from '../SelectButton.svelte'
import { _, locale } from 'svelte-i18n'
import { measurementType }  from '../modal/measurementStore'

export let isOpen = false

function handleSubmit(event: Event) {
	event.preventDefault()
	commonSettings.forEach((setting: { value: string; name: string }) =>
		localStorage.setItem(setting.name, setting.value)
	)
	$locale = commonSettings[0].value
	measurementType.set(localStorage.getItem('measurement-type'))

	userMsg.successMsg($_('settings_updated'))
	isOpen = false
}

let commonSettings = [
	{
		name: 'language',
		value: localStorage.getItem('locale') || 'en'
	},
	{
		name: 'measurement-type',
		value: localStorage.getItem('measurement-type') || 'metric'
	}
]
</script>

{#if isOpen}
	<div id="background-overlay" />

	<div class="lang-and-unit-select flex-column align-center">
		<div class="header">
			<h1 class="title">{$_('select_lang_and_unit')}</h1>
		</div>
		<form
			on:submit={handleSubmit}
			class="flex-column settings-form">
			<div class="field language">
				<label for="measurement-type">{$_(commonSettings[0].name)}</label>
				<LocaleSwitcher bind:value={commonSettings[0].value} />
			</div>
			<div class="field measurement-type">
				<label for="measurement-type">{$_(commonSettings[1].name)}</label>
				<SelectButton
					id="measurement-type"
					bind:value={commonSettings[1].value}
					choices={[
						{ label: $_('metric'), value: 'metric' },
						{ label: $_('imperial'), value: 'imperial' }
					]} />
			</div>
			<div class="flex align-center btns-container">
				<button
					type="submit"
					class="btn theme-btn submit"
					on:click={handleSubmit}>
					{$_('continue')}
				</button>
			</div>
		</form>
	</div>
{/if}

<style lang="scss">
.lang-and-unit-select {
	position: fixed;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 450px;
	background-color: white;
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	padding-bottom: 15px;
	border-radius: 16px;
	z-index: 101;

	.header {
		padding: 24px 22px;
		font-size: 18px;
	}

	.settings-form {
		padding: 6px 24px;
		width: 100%;
		gap: 16px;

		.field {
			flex-direction: column;
			display: flex;
			gap: 3px;

			label {
				font-size: 12px;
				font-family: Nunito-SemiBold;
				color: #33383f;
			}
		}
	}

	@media screen and (max-width: 768px) {
		width: 95%;

		.header {
			text-align: center;
		}
	}

	.btns-container {
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;

		.btn {
			font-size: 13px;
		}
	}
}

#background-overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
}
</style>
