<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#EEF0F7" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M23.6583 16.4484C27.8286 16.4484 31.2094 19.8296 31.2094 24C31.2094 28.1704 27.8286 31.5516 23.6583 31.5516C19.4879 31.5516 16.1067 28.1704 16.1067 24C16.1067 19.8296 19.4879 16.4484 23.6583 16.4484ZM33.1975 30.35C34.0471 29.1047 35.9433 30.3984 35.0933 31.6442C32.5606 35.3477 28.3808 37.5622 23.8922 37.5622C16.4023 37.5622 10.33 31.4903 10.33 24C10.33 16.5097 16.4023 10.4378 23.8922 10.4378C30.116 10.4378 35.5412 14.6845 37.0447 20.702L37.5134 19.8992C38.2749 18.5949 40.2603 19.754 39.4991 21.0584L37.3641 24.7102C37.0043 25.3251 36.1675 25.4695 35.6236 24.9989L32.5206 22.3128C31.3818 21.3279 32.8819 19.5934 34.0211 20.5787L34.8265 21.2761C33.5822 16.2666 29.0698 12.7381 23.8922 12.7381C17.6726 12.7381 12.6303 17.78 12.6303 24C12.6303 30.22 17.6726 35.2619 23.8922 35.2619C27.6276 35.2619 31.0892 33.433 33.1975 30.35ZM20.6225 25.3142L22.0931 25.1686C22.2606 26.0965 22.7519 26.5977 23.7191 26.5977C24.3548 26.5977 25.1814 26.3403 25.1814 25.57C25.1814 24.7688 23.89 24.6214 23.2845 24.4698C22.0924 24.1708 20.9041 23.6474 20.9041 22.2191C20.9041 20.9446 21.903 20.3361 22.998 20.1747V19.2294H24.3186V20.1774C25.5102 20.3501 26.4257 21.0066 26.4752 22.4141L24.9622 22.4666C24.828 21.6597 24.3975 21.3815 23.6023 21.3815C23.1424 21.3815 22.3437 21.5187 22.3437 22.1189C22.3437 22.7645 23.4829 22.964 23.95 23.0751C25.4006 23.4161 26.694 23.8466 26.694 25.5613C26.694 26.465 26.1622 27.2516 25.3288 27.5978C25.0389 27.7172 24.7022 27.7992 24.3186 27.84V28.7706H22.998V27.8238C21.6562 27.6337 20.8058 26.8551 20.6225 25.3142Z"
		fill="#264A59" />
</svg>
