import { trainingDB } from '../database/training.db'
import { Timestamp } from 'firebase/firestore'
import type { TrainingModel } from '../../models/training.model'
import { userMsg } from '../../utils/userMsg.service'
import { storageService } from './storage.service'

export const trainingService = {
  query,
  getById,
  getEmptyTraining,
  uploadTrainingImg,
  getTrainingTypes,
  queryTeamTrainings
}

// Function that gets trainings from firebase and returns them as an array
async function query(
  accountId: string,
  itemsPerPage: number
): Promise<TrainingModel[]> {
  const trainings = []

  try {
    const trainingDocs = await trainingDB.getMany(accountId, itemsPerPage)
    trainingDocs.forEach((doc) => trainings.push(doc.data()))
    return trainings
  } catch (error: unknown) {
    userMsg.errorMsg('Cannot get trainings', error)
  }
}

async function getById(trainigId: string): Promise<TrainingModel> {
  try {
    const training = await trainingDB.get(trainigId)
    return training as TrainingModel
  } catch (error: unknown) {
    userMsg.errorMsg('Cannot get training', error)
  }
}

async function queryTeamTrainings(accountId: string, teamId: string, itemsPerPage: number) {
  const teamTrainings = []

  try {
    const teamTrainingsDocs = await trainingDB.loadTeamTrainings(accountId, teamId, itemsPerPage)
    teamTrainingsDocs.forEach(doc => teamTrainings.push(doc.data()))
    return teamTrainings
  } catch (error: unknown) {
    userMsg.errorMsg('Cannot get team dogs', error)
  }
}

// Function that generates an empty training model
function getEmptyTraining(initialData?: Partial<TrainingModel>): TrainingModel {
  const emprtyTraining: TrainingModel = {
    accountId: '',
    createdAt: Timestamp.now(),
    data: [],
    dog: {
      name: '',
      id: '',
      image: '',
      type: []
    },
    dogRating: 0,
    id: '',
    location: {
      address: '',
      geoPoints: null
    },
    notes: '',
    team: {
      icon: '',
      id: '',
      name: ''
    },
    timesOfDay: 'midday',
    dateTime: Timestamp.now(),
    trainedBy: {
      id: '',
      image: '',
      name: '',
      type: ''
    },
    trainedByRating: 0,
    trainingTime: 0,
    type: '',
    unitsOfMeasurement: '',
    updatedAt: Timestamp.now(),
    weather: {
      data: [{
        clouds: 0,
        dew_point: 0,
        de: 0,
        humidity: 0,
        pressure: 0,
        sunrise: 0,
        sunset: 0,
        temp: 0,
        wind_speed: 0,
        wind_deg: 0,
        uvi: 0,
        visibility: 0,
        wind_gust: 0,
        feels_like: 0
      }],
      lat: 0,
      lon: 0,
      timezone: '',
      timezone_offset: 0
    },
    airQuality: '',
    trainingTypeId: '',
    iconUrl: '',
    trainingTypeName: '',
    event: 'training',
    season: ''
  }

  const dogWithInitData = { ...emprtyTraining, ...initialData }

  return dogWithInitData
}

// Function that uploades training image to firebase storage
async function uploadTrainingImg(trainingId: string, imgStr: string): Promise<string> {
  return storageService.uploadImgStr(`trainings/${trainingId}.jpg`, imgStr)
}

// Function that gets trainingTypes from firebase and returns them as an array
async function getTrainingTypes(type: string, accountId: string) {
  const trainingTypes = []

  try {
    const trainingTypesDocs = await trainingDB.getManyTrainingTypes(type, accountId)
    trainingTypesDocs.forEach((doc) => trainingTypes.push(doc.data()))
    return trainingTypes
  } catch (error: unknown) {
    return []
  }
}