<script lang="ts">
import type { TeamModel, TeamStats } from '../../../models/team.model'
import BullishIcon from '../../dashboard/icons/BullishIcon.svelte'
import DogsIcon from '../../dashboard/icons/DogsIcon.svelte'
import LayerIcon from '../../dashboard/icons/LayerIcon.svelte'
import TimeIcon from '../../dashboard/icons/TimeIcon.svelte'
import UsersIcon from '../../dashboard/icons/UsersIcon.svelte'
import QuickInsights from '../../common/QuickInsights.svelte'
import { _ } from 'svelte-i18n'

export let team: TeamModel

$: insights = team && buildQuickInsights(team.userIds, team.dogIds, team.stats)

function buildQuickInsights(userIds: Array<string>, dogIds: Array<string>, stats: TeamStats) {
	const totals = [
		{
			title: 'total_users',
			value: userIds.length,
			icon: UsersIcon
		},
		{
			title: 'total_dogs',
			value: dogIds.length,
			icon: DogsIcon
		},
		{
			title: 'training_days',
			value: stats.totalTrainingDays,
			icon: LayerIcon
		},
		{
			title: 'training_hours',
			value: (stats.totalTrainingTime / 60).toFixed(),
			icon: TimeIcon
		},
		{
			title: 'total_trainings',
			value: stats.totalTrainingSessions,
			icon: BullishIcon
		}
	]
	return totals
}
</script>

<div class="team-quick-insights">
	<QuickInsights
		{insights}
		titleName={$_("team_quick_insights")} />
</div>
