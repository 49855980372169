<script lang="ts">
import { useNavigate } from 'svelte-navigator'
import { _ } from 'svelte-i18n'
import { utilService } from '../../utils/util.service'
import { userMsg } from '../../utils/userMsg.service'
import CancelModal from './CancelModal.svelte'
import { onMount } from 'svelte'
import { loggedInUser, currAccount } from '../../store/stores'
import { beaconService } from '../../services/APIs/beacon.service'

export let isSubscribed: boolean
export let subscriptionInfo: {
	planTitle: string
	subscriptionExpiryDate: string
	price: string
	id: string
} = null
export let isListOpen: boolean
export let sessionKeys: any = null

let expiryDate: Date

let year: number
let months: number
let days: number

onMount(() => {

	if (window.Beacon) window.Beacon('close')
	beaconService.navigateBeacon('/ask/');
	beaconService.identifyBeacon();
	beaconService.prefillBeacon(`${$currAccount.name} Enterprise Extension`, `Hi, I'm inquiring about extend my trial period\n--- \n Name: ${
			$loggedInUser.firstName + ' ' + $loggedInUser.lastName
		} \n Account: ${$currAccount.name}\n Dogs: ${
			$currAccount.dogIds.length
		}\n Users: ${$currAccount.userIds.length}\n Teams: ${
			$currAccount.teamIds.length
		}`)
	if (subscriptionInfo) {
		expiryDate = new Date(subscriptionInfo.subscriptionExpiryDate)
		year = expiryDate.getUTCFullYear()
		months = expiryDate.getUTCMonth() + 1
		days = expiryDate.getUTCDate()
	}
})

let showAlert = false


const navigate = useNavigate()

async function cancelSubscription() {
	const url = `https://api.paddle.com/subscriptions/${subscriptionInfo.id}/cancel`
	const body = {
		effective_from: 'next_billing_period'
	}
	const myHeaders = new Headers()
	myHeaders.append('Accept', '/')
	myHeaders.append('Authorization', `Bearer ${import.meta.env?.VITE_APP_PADDLE_API_KEY}`)
	return await utilService.restRequest(url, 'POST', body)
}

function onCancel() {
	showAlert = false
}

async function onConfirm() {
	try {
		await cancelSubscription()
		showAlert = false
		userMsg.successMsg($_('subscription_cancelled'))
	} catch (err) {
		userMsg.errorMsg($_('subscription_cancel_error'), err)
	}
}
</script>

{#if isSubscribed}

	<div class="plan-container flex-column">
		<div class="plan flex-column">
			<div class="flex">
				<!-- <h2 class="plan-title">{subscriptionInfo.planTitle}</h2> -->
				<h2 class="plan-title">
					{$currAccount.subscriptionData.items[0].price.description
						.split(' ')
						.slice(0, 2)
						.join(' ')}
				</h2>
				<p>{$_('billed_monthly')}</p>
			</div>
			<h3>
				{subscriptionInfo.price} ({$_('next_renew')}
				{`${year}-${months < 10 ? '0' : ''}${months}-${days < 10 ? '0' : ''}${days}`})
			</h3>
		</div>
		<div class="btns-container flex">
			<button
				class="btn theme-btn"
				on:click={() => {
					isListOpen = !isListOpen
				}}>{$_('transaction_history')}</button>
			<button
				class="btn theme-btn"
				on:click={() => {
					showAlert = true
				}}>{$_('cancel_subscription')}</button>
		</div>
	</div>
{:else}
	<div class="plan">
		<h2 class="trial-plan">
			{$_('trial_plan')}
			{sessionKeys.daysLeftInTrial}
			{$_('x_days_left')}
		</h2>
		<div class="plan-btns flex">
			<button
				on:click={() => navigate('/checkout', { state: { pageName: $_('checkout') } })}
				class="subscribe-btn">{$_('subscribe_now')}</button>
			<button
				on:click={() => window.Beacon('open')}
				class="request-trial">{$_('request_trial_extension')}</button>
		</div>
	</div>
{/if}

{#if showAlert}
	<CancelModal
		{onCancel}
		{onConfirm} />
{/if}

<style lang="scss">
@use '../../styles/setup/mixins';

.plan-title {
	font-family: Montserrat-Bold;
	font-size: 1.1rem;
}

.plan-container {
	padding: 0 24px 25px 24px;
	width: 100%;
	max-height: 200px;
	gap: 3px;

	h2 {
		font-family: Nunito-SemiBold;
		color: #33383f;
	}
}

.plan {
	width: max-content;
	gap: 10px;
	margin-top: 2rem;
	.trial-plan {
		font-family: Montserrat-Bold;
		font-size: 1.1rem;
	}

	h3 {
		color: var(--clr-txt-dark-secondary);
		font-family: Nunito;
		font-weight: 100;
		font-size: 15px;
	}

	.flex {
		align-items: center;
		font-size: 12px;
		gap: 6px;

		p {
			background: rgb(40, 199, 111);
			border-radius: 4px;
			color: #fff;
			padding: 4px 7px;
			font-size: 11px;
		}
	}
}

.btns-container {
	margin-top: 16px;
	gap: 8px;

	.btn {
		font-size: 13px;
		padding: 9px 14px;
	}
}
.subscribe-btn {
	font-weight: bold;
	margin-top: 1rem;
	padding: 1em 2em;
	color: #fff;
	border-radius: 8px;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.request-trial {
	font-weight: bold;
	margin-top: 1rem;
	padding: 1em 2em;
	color: #fff;
	border-radius: 8px;
	background: linear-gradient(46.62deg, #f57003 0%, #fca12a 93.64%);
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

@media screen and (max-width: 900px) {
	.plan-btns {
		flex-direction: column;
		align-items: flex-start !important;

		.subscribe-btn,
		.request-trial {
			margin-top: 0;
			width: 100%;
		}
	}

	.plan {
		.trial-plan {
			margin-bottom: 24px;
		}
	}
}
</style>
