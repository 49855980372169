<script lang="ts">
import DropdownBottomSheet from './DropdownBottomSheet.svelte'
import Slider from './Slider.svelte'
import Textarea from './Textarea.svelte'
import { dropdownMultiSlider } from '../../../store/trainingWidgetsStore'
import { dropdownMultiSliderSliderValues } from '../../../store/trainingWidgetsStore'
import { dropdownMultiSliderTextFieldValues } from '../../../store/trainingWidgetsStore'
import TimerSlider from './TimerSlider.svelte'

export let values: {
	items: {
		selectedOption: string
		sliderLabel: string
		max: number
		min: number
		optionType: string
		divisions: number
		useSeconds?: boolean
	}[]
	maxLines: number
	filled: boolean
	value: {
		selectValue: string
		sliderValue: number
		label: string
		textField: string
		useSeconds?: boolean
	}
}
export let data = []
export let id: string

let dropdownItems = values.items.map((item) => item.selectedOption)

let dropdownValues: any
let sliderValues: any
let textFieldValues: any

if (!$dropdownMultiSlider[id]) {
	$dropdownMultiSlider[id] = {
		items: values.items,
		maxLines: values.maxLines,
		filled: values.filled,
		value: {
			selectValue: '',
			sliderValue: '',
			label: '',
			textField: ''
		}
	}
}

if ($dropdownMultiSlider[id].value.selectValue) {
	dropdownValues = {
		items: $dropdownMultiSlider[id].items.map((item) => item.selectedOption),
		value: $dropdownMultiSlider[id].value.selectValue,
		returnedValue: {
			value: $dropdownMultiSlider[id].value.selectValue,
			label: $dropdownMultiSlider[id].value.selectValue
		},
		filled: false
	}
} else if (data.length > 0) {
	dropdownValues = {
		items: dropdownItems,
		value: data[0].value.selectValue,
		filled: false
	}
} else {
	dropdownValues = {
		items: dropdownItems,
		value: '',
		filled: false
	}
}

if ($dropdownMultiSliderSliderValues[id]) {
	sliderValues = $dropdownMultiSliderSliderValues[id]
} else {
	sliderValues = {
		min: 0,
		max: 0,
		label: '',
		defaultSliderValue: 0,
		divisions: 0,
		selectedOption: '',
		value: {
			selectedValue: 0,
			label: ''
		}
	}
}

if ($dropdownMultiSliderTextFieldValues[id]) {
	textFieldValues = $dropdownMultiSliderTextFieldValues[id]
} else {
	textFieldValues = {
		initialValue: '',
		maxLines: values.maxLines,
		readOnly: false,
		selectedOption: '',
		value: '',
		filled: false
	}
}

values.filled = false

values.value = {
	selectValue: '',
	sliderValue: 0,
	label: '',
	textField: '',
	useSeconds: false
}

const items = values.items

$: {
	items.forEach((item) => {
		if (dropdownValues.value == item.selectedOption) {
			if (item.optionType == 'Slider') {
				if (sliderValues.selectedOption != item.selectedOption) {
					sliderValues = {
						min: item.min,
						max: item.max,
						label: item.sliderLabel,
						defaultSliderValue: 0,
						divisions: item.divisions,
						selectedOption: item.selectedOption,
						useSeconds: item.useSeconds ?? false,
						value: {
							selectedValue: 0,
							label: item.sliderLabel,
							useSeconds: item.useSeconds
						}
					}
				}
				values.value.selectValue = dropdownValues.value
				values.value.sliderValue = sliderValues.value
				values.value.textField = ''
				values.value.label = sliderValues.label
				values.filled = values.value.sliderValue == 0 ? false : true
				$dropdownMultiSlider[id] = values
				$dropdownMultiSliderSliderValues[id] = sliderValues
			} else if (item.optionType == 'Text') {
				if (textFieldValues.selectedOption != item.selectedOption) {
					textFieldValues = {
						initialValue: '',
						maxLines: values.maxLines,
						readOnly: false,
						selectedOption: item.selectedOption,
						value: '',
						filled: false
					}
				}
				values.value.selectValue = dropdownValues.value
				values.value.sliderValue = null
				values.value.textField = textFieldValues.value
				values.value.label = ''
				values.filled = textFieldValues.value == '' ? false : true
				$dropdownMultiSlider[id] = values
				$dropdownMultiSliderTextFieldValues[id] = textFieldValues
			} else {
				values.value.selectValue = dropdownValues.value
				values.value.sliderValue = null
				values.value.textField = ''
				values.value.label = ''
				values.filled = dropdownValues.value == '' ? false : true
				$dropdownMultiSlider[id] = values
			}
		}
	})
}
</script>

<DropdownBottomSheet
	bind:values={dropdownValues}
	data={data.length > 0 ? [{ value: data[0].value.selectValue }] : []} />

{#each values.items as item}
	{#if dropdownValues.value == item.selectedOption}
		{#if item.optionType == 'Slider'}
			{#if item.sliderLabel == 'minutes'}
				<TimerSlider
					{id}
					bind:values={sliderValues}
					data={data.length > 0 && data[0].value.sliderValue
						? [{ value: data[0].value.sliderValue }]
						: []} />
			{:else}
				<Slider
					bind:values={sliderValues}
					data={data.length > 0 && data[0].value.sliderValue
						? [{ value: { selectedValue: data[0].value.sliderValue.selectedValue } }]
						: []} />
			{/if}
		{/if}
		{#if item.optionType == 'Text'}
			<Textarea
				bind:values={textFieldValues}
				data={data.length > 0 && data[0].value.textField != null && data[0].value.textField != ''
					? [{ value: data[0].value.textField }]
					: []} />
		{/if}
	{/if}
{/each}
