import { getAuth } from 'firebase/auth'

const AiUrl = import.meta.env.VITE_APP_AI_URL;

export const dogbaseAiService = {
	getTrainingRecap
}

async function getTrainingRecap(trainingTypeId: string, dogId: string) {
	const auth = getAuth()
	const result = await auth.currentUser
		.getIdToken(/* forceRefresh */ true)
		.then(async function (idToken) {
			const myHeaders = new Headers()
			myHeaders.append('Content-Type', 'application/json')
			myHeaders.append('Authorization', `Bearer ${idToken}`)
			const response = await fetch(`${AiUrl}/api/v1/synchronous/summary/training/selectedTrainings?trainingTypeId=${trainingTypeId}&dogId=${dogId}`, {
				method: 'GET',
				headers: myHeaders
			})
			const values = await response.json()
			return values
		})

	return await result
}