<script lang="ts">
import { onSnapshot, type Unsubscribe } from 'firebase/firestore'
import { onDestroy, onMount } from 'svelte'
import TeamList from '../cmps/teams/TeamList.svelte'
import { teamDB } from '../services/database/team.db'
import { currTeams, loggedInUser } from '../store/stores'
import type { TeamModel, TeamStatus } from '../models/team.model'
import NoTeamAnimation from '../cmps/common/animation/NoTeamAnimation.svelte'
import { Link } from 'svelte-navigator'
import CmpLoader from '../cmps/common/loader/CmpLoader.svelte'
import LoadMoreBtn from '../cmps/common/LoadMoreBtn.svelte'
import TeamsQuickInsights from '../cmps/teams/TeamsQuickInsights.svelte'
import { writable } from 'svelte/store'
import { _ } from 'svelte-i18n'
import * as Typesense from 'typesense'
import { currAccount } from '../store/stores'
import type { SearchTeamModel } from '../models/team.model'
import type { SvgIconName } from '../models/common.model'
import SearchBar from '../cmps/common/SearchBar.svelte'
import Shepherd from 'shepherd.js'
import { user } from '../store/stores'
import { Container } from 'sveltestrap'
import { utilService } from '../utils/util.service'
import Tooltip from '../cmps/common/Tooltip.svelte'


let unsubscribe: Unsubscribe

let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

onMount(async () => {
	currTeams.set(null)
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	loadPage()
})



onDestroy(() => {
	if (unsubscribe && typeof unsubscribe === 'function') {
		unsubscribe()
	}
})

let itemsPerPage = 10
let itemsOnPage = 0
let teamListLength = 10

let tour: any

let searchBarValue = ''
let sessionKeys: any
let hits: {
	name: string
	status: TeamStatus
	accountId: string
	dogIds: string[]
	id: string
	icon: SvgIconName
	userIds: string[]
	location: any
}[]
let placeholder = $_('search')



function initializeTour() {
	tour = new Shepherd.Tour({
		useModalOverlay: true,
		defaultStepOptions: {
			classes: 'shadow-md bg-purple-dark',
			scrollTo: false,
			when: {
				show() {
					const currentStep = Shepherd.activeTour?.getCurrentStep()
					const currentStepElement = currentStep?.getElement()
					const footer = currentStepElement?.querySelector('.shepherd-footer')
					const progress = document.createElement('span')
					progress.className = 'shepherd-progress'
					progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${
						Shepherd.activeTour?.steps.length
					}`
					footer?.insertBefore(
						progress,
						currentStepElement.querySelector('.shepherd-button:last-child')
					)
				}
			}
		}
	})

	const stepsInfo = [
		{
			element: '.teams',
			text: 'teams-page',
			on: 'top'
		},
		{
			element: '.page-header',
			text: 'page-header',
			on: 'bottom'
		},
		{
			element: '.team-list-container',
			text: 'team-list-container',
			on: 'top'
		}
	]

	stepsInfo.forEach((step) => {
		tour.addStep({
			text: $_(`tour_description.${step.text}`),
			attachTo: {
				element: step.element,
				on: step.on
			},
			classes: 'step-design',
			buttons: [
				{
					text: $_('exit'),
					action: function () {
						tour.complete()
					},
					classes: 'exit-button'
				},
				{
					text: $_('next'),
					action: tour.next
				}
			]
		})
	})
}

function isFirstLogin(user: any) {
	const creationTime = user.metadata.creationTime
	const lastSignInTime = user.metadata.lastSignInTime

	if (creationTime === lastSignInTime) {
		// It's the user's first login.
		return true
	} else {
		return false
	}
}

function submit(ev: Event) {
	ev.preventDefault()
	if (searchBarValue != '') {
		let client = new Typesense.Client({
			nodes: [
				{
					host: import.meta.env.VITE_APP_TYPESENSE_URL,
					port: 443,
					protocol: 'https'
				}
			],
			apiKey: sessionKeys.typeSenseKey,
			connectionTimeoutSeconds: 2
		})

		let search = {
			q: searchBarValue,
			query_by: 'name,status',
			per_page: 100
		}

		client
			.collections('teams')
			.documents()
			.search(search)
			.then(function (searchResults) {
				hits = searchResults.hits.map((hit) => {
					return {
						name: hit.document['name'],
						accountId: hit.document['accountId'],
						userIds: hit.document['userIds'],
						dogIds: hit.document['dogIds'],
						status: hit.document['status'],
						id: hit.document['id'],
						icon: hit.document['icon'],
						location: hit.document['location']
					}
				})
				teamsForDisplay.set(hits)
			})
	} else {
		hits = null
		itemsPerPage = 10
		itemsOnPage = 0
		teamListLength = 10
		loadPage()
	}
}

async function loadPage() {
	itemsOnPage += itemsPerPage
	const paginated = await teamDB.loadPage(
		$loggedInUser.id,
		$loggedInUser.selectedAccount.id,
		itemsOnPage
	)
	unsubscribe = onSnapshot(paginated, (querySnapshot) => {
		const teams: TeamModel[] = []
		querySnapshot.forEach((doc) => teams.push(doc.data() as TeamModel))
		currTeams.set(teams)
		teamsForDisplay.set(teams)
		teamListLength = teams.length
	})
}

$: {
	const teamTourShown = localStorage.getItem('teamTourShown')
	if (
		$user &&
		$teamsForDisplay.length > 0 &&
		isFirstLogin($user) &&
		(teamTourShown === 'false' || !teamTourShown)
	) {
		initializeTour()
		if (tour) tour.start()

		if (teamTourShown === 'false' || !teamTourShown) {
			localStorage.setItem('teamTourShown', 'true')
		}
	}
}

const teamsForDisplay = writable<SearchTeamModel[]>([])
</script>

<Container
	class="teams p-0"
	fluid>
	{#if !$currTeams}
		<CmpLoader />
	{:else if $currAccount.teamIds.length > 0}
		<header class="page-header">
			<TeamsQuickInsights />
		</header>
		<div class="flex-column main-content mt-4 gap-3">
			<header class="flex align-center space-between list-header">
				<h1 class="title">{$_('teams')}</h1>
				{#if disabled}
					<button
						{disabled}
						class="btn theme-btn btn-add-team flex"
						><Tooltip message={$_('locked_feature')} />{$_('add_team')}</button>
				{:else}
					<Link
						to="edit"
						state={{ pageName: 'Create Team' }}>
						<button
							{disabled}
							class="btn theme-btn btn-add-team">{$_('add_team')}</button>
					</Link>
				{/if}
			</header>
			<form
				action=""
				on:submit={submit}>
				<SearchBar
					bind:searchTerm={searchBarValue}
					{placeholder} />
			</form>
			<div class="team-list-container">
				<TeamList
					teams={$teamsForDisplay}
					{disabled} />
			</div>
		</div>
		{#if !hits}
			<LoadMoreBtn
				loadFunc={loadPage}
				{itemsOnPage}
				listLength={teamListLength} />
		{/if}
		<!-- If account has no teams show animations -->
	{:else}
		<div class="flex-column align-center no-teams-animation">
			<NoTeamAnimation />
			{#if disabled}
				<button
					{disabled}
					class="btn theme-btn btn-add-team"
					><Tooltip message={$_('locked_feature')} />{$_('add_team')}</button>
			{:else}
				<Link
					to="edit"
					state={{ pageName: 'Create Team' }}>
					<button
						{disabled}
						class="btn theme-btn btn-add-team">{$_('add_team')}</button>
				</Link>
			{/if}
		</div>
	{/if}
</Container>

<style lang="scss">
.page-header {
	margin-bottom: 25px;
}

.no-teams-animation {
	margin-top: 50px;
	gap: 30px;
}

.main-content {
	gap: 20px;
}

.list-header {
	.title {
		color: var(--clr-txt-dark-primary);
		font-size: 20px;
	}
}

.team-list-container {
	display: grid; // For table scroll overflow
	margin-bottom: 20px;
}

.btn-add-team:disabled {
	pointer-events: auto;
	gap: 6px;
}
</style>
