<script lang="ts">
import { _ } from 'svelte-i18n'
import { onMount, afterUpdate } from 'svelte'

export let value = 0

onMount(() => {
	if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
		document.body.classList.add('firefox')
	}


})

let min = 0
let max = 600

let currentSliderValue = value

$: label =
	currentSliderValue < 60
		? `${currentSliderValue} min`
		: `${Math.floor(currentSliderValue / 60)} h ${currentSliderValue % 60} min`

$: {
	if (currentSliderValue > 600) {
		currentSliderValue = 600
	} else if (currentSliderValue < 0) {
		currentSliderValue = 0
	} else if (currentSliderValue == null) {
		currentSliderValue = 0
	}
}

let inputElement: HTMLInputElement
let editingLabel = false
let editValue = ''
let shouldFocusInput = false

function handleLabelClick() {
	editingLabel = true
	editValue = currentSliderValue.toString()
	shouldFocusInput = true
}

function handleNumberInput(event) {
	if (event.target.value.length > 8) {
		event.target.value = event.target.value.slice(0, 8)
	}
}

function handleLabelBlur() {
	editingLabel = false

	let rawValue = parseFloat(editValue)

	if (rawValue < 0) {
		rawValue = min
	}

	let maxValue = max
	if (rawValue > maxValue) {
		rawValue = maxValue
	}

	value = rawValue
	currentSliderValue = rawValue
	editValue = currentSliderValue.toString()
}

function handleChange(event) {
	currentSliderValue = parseInt(event.target.value)
	value = currentSliderValue
}

//TODO: had to add this as the app was crashing or doing a full page refresh  when the user was pressing enter in the input
function handleKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

afterUpdate(() => {
	if (shouldFocusInput) {
		inputElement.focus()
		shouldFocusInput = false
	}
})

let sliderWidth: number

$: filledWidth = (currentSliderValue / max) * sliderWidth
</script>

<div class="slider-container flex">
	<div class="slider-range flex align-center">
		<div
			class="slider-wrapper"
			bind:clientWidth={sliderWidth}>
			<input
				name="training-time-slider"
				type="range"
				class="slider"
				{min}
				{max}
				step={1}
				bind:value={currentSliderValue}
				on:input={handleChange} />
		</div>
		<div
			class="filled"
			style={`width:${filledWidth}px`} />
		<div class="current-value flex justify-center">
			{#if editingLabel}
				<div class="input-container">
					<input
						class="label-input"
						bind:this={inputElement}
						type="number"
						bind:value={editValue}
						on:input={handleNumberInput}
						on:blur={handleLabelBlur}
						on:keydown={handleKeydown}  />
					<span class="tooltip">Type the value in minutes</span>
				</div>
			{:else}
				<div
					on:click={handleLabelClick}
					on:keydown={handleLabelClick}>
					{label}
				</div>
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
.slider-range {
	align-items: center;
	gap: 10px;

	.slider-wrapper {
		width: 510px;
	}

	@media (max-width: 768px) {
		width: 100%;

		.slider-wrapper {
			width: 100%;
		}
	}

	.filled {
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		position: absolute;
		border-radius: 5px;
		z-index: 5;
		height: 2px;
	}
}

.label-input {
	height: 21px;
	width: 76px;
	border: none;
}

.label-input:focus {
	outline: none;
	border-color: transparent;
	box-shadow: none;
}

.input-container {
	position: relative;
	display: inline-block;
}

.input-container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}

.tooltip {
	visibility: hidden;
	opacity: 0;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 140%;
	left: 50%;
	font-size: 14px;
	margin-left: -60px;
	width: 180px;
}
.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 35%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #264a59 transparent transparent transparent;
}
.current-value,
.label-input {
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 6px;
	padding: 5px 0px;
	min-width: 5.5rem;
	font-size: 13px;
	font-family: Montserrat-Medium;
	text-align: center;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']::-ms-clear {
	display: none;
}

.label-input {
	border: none;
	padding: 0;
}

input[type='range'] {
	height: 23px;
	-webkit-appearance: none;
	margin: 10px 0;
	width: 100%;
}
input[type='range']:focus {
	outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
	width: 100%;
	height: 2.5px;
	cursor: pointer;
	transition: 0.2s;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 5px;
}
input[type='range']::-webkit-slider-thumb {
	height: 16px;
	width: 16px;
	border-radius: 50px;
	z-index: 9;
	position: relative;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -7px;
}
input[type='range']:active::-webkit-slider-thumb {
	outline: 10px solid rgba(45, 115, 146, 0.3);
	outline-offset: -1px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
	background: rgba(143, 149, 178, 0.4);
}
input[type='range']::-moz-range-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	transition: 0.2s;
	position: relative;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 5px;
}
input[type='range']::-moz-range-thumb {
	height: 16px;
	width: 16px;
	border-radius: 50px;
	z-index: 9;
	position: relative;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	cursor: pointer;
}
input[type='range']::-moz-range-progress {
	border-radius: 6px;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
}
input[type='range']::-ms-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	transition: 0.2s;
	background: transparent;
	position: relative;
	border-color: transparent;
	color: transparent;
}
input[type='range']::-ms-fill-lower {
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;
}
input[type='range']::-ms-fill-upper {
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;
}
input[type='range']::-ms-thumb {
	margin-top: 1px;
	height: 16px;
	width: 16px;
	border-radius: 50px;
	z-index: 9;
	position: relative;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
	background: rgba(143, 149, 178, 0.4);
}
input[type='range']:focus::-ms-fill-upper {
	background: rgba(143, 149, 178, 0.4);
}

body.firefox .slider-range .filled {
	display: none;
}
</style>
