import { writable } from 'svelte/store';

export const toggleSwitchSlider = writable<any>({});
export const toggleSwitchText = writable<any>({});
export const toggleTextToTags = writable<any>({});
export const toggleSwitchStepper = writable<any>({});
export const toggleDistanceSlider = writable<any>({});
export const toggleMultiText = writable<any>({});
export const toggleSwitchDropdown = writable<any>({});
export const toggleFitness = writable<any>({});
export const toggleMultiSelect = writable<any>({});
export const dropdownTextToTags = writable<any>({});
export const dropdownText = writable<any>({});
export const dropdownMultiSlider = writable<any>({});
export const dropdownMultiSliderSliderValues = writable<any>({});
export const dropdownMultiSliderTextFieldValues = writable<any>({});
export const multiSelectSlider = writable<any>({});
export const weightSlider = writable<any>({});
export const timerSlider = writable<any>({});
export const rangeSlider = writable<any>({});
export const customStepper = writable<any>({});
export const distanceStepper = writable<any>({});
export const daysToYears = writable<any>({});
export const bitLossECorrection = writable<any>({});
export const stepperMultiply = writable<any>({});
export const stepperMultiplyHelper = writable<any>({});
export const multiselectTextToTags = writable<any>({});
export const toggleMultiSlider = writable<any>({});
export const dropdownBottomSheet = writable<any>({});
export const textArea = writable<any>({});
export const textToTags = writable<any>({});
export const multiselect = writable<any>({});
export const toggle = writable<any>({});
export const distanceSlider = writable<any>({});

export function clearAllStores() {
  toggleSwitchSlider.set({});
  toggleSwitchText.set({});
  toggleTextToTags.set({});
  toggleSwitchStepper.set({})
  toggleDistanceSlider.set({})
  toggleMultiText.set({})
  toggleSwitchDropdown.set({})
  toggleFitness.set({})
  dropdownTextToTags.set({})
  dropdownText.set({})
  dropdownMultiSlider.set({})
  dropdownMultiSliderSliderValues.set({})
  dropdownMultiSliderTextFieldValues.set({})
  multiSelectSlider.set({})
  weightSlider.set({})
  timerSlider.set({})
  rangeSlider.set({})
  customStepper.set({})
  distanceStepper.set({})
  bitLossECorrection.set({})
  daysToYears.set({})
  stepperMultiply.set({})
  toggleMultiSelect.set({})
  multiselectTextToTags.set({})
  toggleMultiSlider.set({})
  stepperMultiplyHelper.set({})
  dropdownBottomSheet.set({})
  textArea.set({})
  textToTags.set({})
  multiselect.set({})
  toggle.set({})
  distanceSlider.set({})
}