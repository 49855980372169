<script lang="ts">
import Multiselect from './Multiselect.svelte'
import TextToTags from './TextToTags.svelte'
import { multiselectTextToTags } from '../../../store/trainingWidgetsStore'

export let values: {
	items: string[]
	trigger: number
	value: {
		multiselectValue: string[]
		tags: string[]
	}
}
export let data = []
export let id: string

let multiselectValues: any
let textToTagsValues: any

if (!$multiselectTextToTags[id]) {
	$multiselectTextToTags[id] = {
		items: values.items,
		trigger: values.trigger,
		value: {
			multiselectValue: [],
			tags: []
		}
	}
}
if (data.length > 0 && $multiselectTextToTags[id].value.multiselectValue.length == 0) {
	multiselectValues = {
		items: values.items,
		value: data[0].value.multiselectValue,
		returnedValue: data[0].value.multiselectValue.map((item) => ({ value: item, label: item })),
		filled: false
	}
	textToTagsValues = {
		tags: data[0].value.tags,
		value: data[0].value.tags
	}
} else if ($multiselectTextToTags[id].value.multiselectValue) {
	multiselectValues = {
		items: $multiselectTextToTags[id].items,
		value: $multiselectTextToTags[id].value.multiselectValue,
		returnedValue: $multiselectTextToTags[id].returnedValue,
		filled: false
	}
	textToTagsValues = {
		tags: $multiselectTextToTags[id].value.tags,
		value: $multiselectTextToTags[id].value.tags
	}
}

values.value = {
	multiselectValue: multiselectValues.value,
	tags: textToTagsValues.value
}

$: {
	if (multiselectValues.value.includes(values.items[values.trigger])) {
		if (multiselectValues.returnedValue) {
			values.value = {
				multiselectValue: multiselectValues.returnedValue.map((value) => value.value),
				tags: textToTagsValues.value
			}
		}
		$multiselectTextToTags[id] = values
		$multiselectTextToTags[id].returnedValue = multiselectValues.value
			? multiselectValues.value.map((value) => {
					return {
						value: value,
						label: value
					}
				})
			: []
	} else {
		values.value.tags = []
		if (multiselectValues.returnedValue) {
			values.value = {
				multiselectValue: multiselectValues.returnedValue.map((value) => value.value),
				tags: textToTagsValues.value
			}
		}
		$multiselectTextToTags[id] = values
		$multiselectTextToTags[id].returnedValue = multiselectValues.value
			? multiselectValues.value.map((value) => {
					return {
						value: value,
						label: value
					}
				})
			: []
	}
}
</script>

<Multiselect bind:values={multiselectValues} />

{#if multiselectValues.value.includes(values.items[values.trigger])}
	<TextToTags bind:values={textToTagsValues} />
{/if}
