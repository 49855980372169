<script lang="ts">
import { _ } from 'svelte-i18n'

export let name: string
export let icon: string
export let info: string
export let disabled: boolean

let showTooltip = false
function toggleTooltip() {
	showTooltip = true
}

function hideTooltip() {
	showTooltip = false
}
</script>

{#if disabled}
	<div
		class="container"
		on:mouseover={toggleTooltip}
		on:mouseout={hideTooltip}
		on:focus={toggleTooltip}
		on:blur={hideTooltip}
		aria-hidden={!showTooltip}>
		<div class="training-type-card flex-column">
			<div class="card-body">
				<p class="training-type-name">{name}</p>
				<p class="training-type-info">{info}</p>
			</div>
			<div class="image-background flex align-center justify-center">
				<img
					src={icon}
					alt="" />
			</div>
		</div>
		{#if showTooltip}
			<div class="tooltip">{$_('locked_feature')}</div>
		{/if}
	</div>
{:else}
	<div class="container">
		<div class="training-type-card flex-column">
			<div class="card-body">
				<p class="training-type-name">{name}</p>
				<p class="training-type-info">{info}</p>
			</div>
			<div class="image-background flex align-center justify-center">
				<img
					src={icon}
					alt="" />
			</div>
		</div>
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';
.container {
	position: relative;
	.training-type-card {
		@include mixins.card;
		width: 207px;
		height: 210px;
		padding: 7.5px;

		&:hover {
			color: black;
		}

		.card-body {
			flex: 1 0 auto;
			padding: 0 5px;

			.training-type-name {
				color: #33383f;
				font-weight: 600;
				line-height: 18px;
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 3px;
				max-height: 36px;
			}
			.training-type-info {
				color: var(--clr-txt-dark-secondary);
				font-size: 14px;
				max-height: 65px;
				line-height: 18px;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		.image-background {
			width: 192px;
			height: 100px;
			background: var(--dark-blue-gradient, linear-gradient(276deg, #1f74a8 0%, #264654 100%));
			border-radius: 4px;
			vertical-align: bottom;
			flex: 0 0 auto;

			img {
				width: 70px;
			}
		}
	}
}

.tooltip {
	visibility: hidden;
	opacity: 0;
	background: #333;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 12px;
	position: absolute;
	z-index: 1;
	bottom: 105%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	min-width: 110px;
}
.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #333 transparent transparent transparent;
}
.container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}
</style>
