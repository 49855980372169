<svg
	width="49"
	height="48"
	viewBox="0 0 49 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_276_3161)">
		<rect
			x="0.376953"
			width="48"
			height="48"
			rx="24"
			fill="#DCEBF2" />
		<path
			d="M32.1139 16.9345C33.171 16.9139 34.1925 15.4971 34.6353 13.4382L34.9445 12H36.4499C38.7168 12 39.8551 12.7418 39.8551 14.2196C39.8551 14.6669 40.0578 14.7463 41.1983 14.7463C43.0916 14.7463 43.2885 15.0822 42.719 17.3426C41.9651 20.3358 41.9241 20.3608 37.5397 20.466L33.7974 20.5561V27.3381V34.12L34.7677 34.6413C35.7141 35.1496 36.2334 36.118 35.8722 36.701C35.762 36.8787 34.4172 36.9913 32.4044 36.9913C28.0715 36.9913 28.015 36.9424 28.015 33.1945V30.496L24.229 30.4738C21.8868 30.4598 19.8391 30.2983 18.8596 30.0498L17.2764 29.6485V31.8843V34.12L18.2467 34.6413C19.1931 35.1496 19.7124 36.118 19.3511 36.701C19.241 36.8787 17.8962 36.9913 15.8834 36.9913C11.1696 36.9913 11.494 37.5861 11.494 28.94V21.9869L10.0066 21.5192C6.86287 20.5308 4.73303 16.1313 6.85103 15.0006C7.81972 14.4837 8.69864 15.0745 8.90845 16.3834C9.00951 17.0142 9.38123 17.7823 9.77663 18.1764L10.4678 18.8657H19.1804H27.8931L28.3939 16.9263C28.6696 15.8596 29.0228 14.531 29.1792 13.974L29.4637 12.9612L29.837 14.2245C30.2374 15.5787 31.3884 16.9488 32.1139 16.9345Z"
			fill="url(#paint0_linear_276_3161)" />
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_276_3161"
			x1="24.5"
			y1="12"
			x2="24.5"
			y2="37"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#417186" />
			<stop
				offset="1"
				stop-color="#1B4C62" />
		</linearGradient>
		<clipPath id="clip0_276_3161">
			<rect
				width="48"
				height="48"
				fill="white"
				transform="translate(0.376953)" />
		</clipPath>
	</defs>
</svg>
