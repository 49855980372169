<script lang="ts">
import { utilService } from '../../../utils/util.service'
import { daysToYears } from '../../../store/trainingWidgetsStore'
import { _ } from 'svelte-i18n'

export let values: {
	filled: boolean
	showDays: boolean
	showMonths: boolean
	showYears: boolean
	showMinutes: boolean
	showHours: boolean
	value: {
		minutes: number
		hours: number
		days: number
		months: number
		years: number
	}
}
export let data = []
export let id: string

if (!$daysToYears[id]) {
	$daysToYears[id] = {
		blank: true,
		minutes: 0,
		hours: 0,
		days: 0,
		months: 0,
		years: 0
	}
}

let minutesStepperValues: any
let hoursStepperValues: any
let daysStepperValues: any
let monthsStepperValues: any
let yearsStepperValues: any

if (data.length > 0 && $daysToYears[id].blank) {
	minutesStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: data[0].value.minutes,
			label: $_('minutes')
		}
	}
	hoursStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: data[0].value.hours,
			label: $_('hours')
		}
	}
	daysStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: data[0].value.days,
			label: $_('days')
		}
	}
	monthsStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: data[0].value.months,
			label: $_('months')
		}
	}
	yearsStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: data[0].value.years,
			label: $_('years')
		}
	}
} else if ($daysToYears[id]) {
	minutesStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: $daysToYears[id].minutes,
			label: $_('minutes')
		}
	}
	hoursStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: $daysToYears[id].hours,
			label: $_('hours')
		}
	}
	daysStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: $daysToYears[id].days,
			label: $_('days')
		}
	}
	monthsStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: $daysToYears[id].months,
			label: $_('months')
		}
	}
	yearsStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: $daysToYears[id].years,
			label: $_('years')
		}
	}
} else {
	minutesStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: 0,
			label: $_('minutes')
		}
	}
	hoursStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: 0,
			label: $_('hours')
		}
	}
	daysStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: 0,
			label: $_('days')
		}
	}
	monthsStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: 0,
			label: $_('months')
		}
	}
	yearsStepperValues = {
		stepAmount: 1,
		label: '',
		filled: false,
		value: {
			selectedValue: 0,
			label: $_('years')
		}
	}
}

$: {
	values.value = {
		minutes: minutesStepperValues.value.selectedValue,
		hours: hoursStepperValues.value.selectedValue,
		days: daysStepperValues.value.selectedValue,
		months: monthsStepperValues.value.selectedValue,
		years: yearsStepperValues.value.selectedValue
	}
	$daysToYears[id] = values.value
}

$: {
	if (
		values.value.days != 0 ||
		values.value.minutes != 0 ||
		values.value.hours != 0 ||
		values.value.years != 0 ||
		values.value.months != 0
	) {
		values.filled = true
	} else {
		values.filled = false
	}
}

function increment(event: Event, stepperType: string) {
	switch (stepperType) {
		case 'minutes':
			minutesStepperValues.value.selectedValue = Math.max(
				minutesStepperValues.value.selectedValue + 1,
				0
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
		case 'hours':
			hoursStepperValues.value.selectedValue = Math.max(
				hoursStepperValues.value.selectedValue + 1,
				0
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
		case 'days':
			daysStepperValues.value.selectedValue = Math.min(
				daysStepperValues.value.selectedValue + 1,
				100
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
		case 'months':
			monthsStepperValues.value.selectedValue = Math.min(
				monthsStepperValues.value.selectedValue + 1,
				100
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
		case 'years':
			yearsStepperValues.value.selectedValue = Math.min(
				yearsStepperValues.value.selectedValue + 1,
				100
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
	}
	event.preventDefault()
}

function decrement(event: Event, stepperType: string) {
	event.preventDefault()
	switch (stepperType) {
		case 'minutes':
			minutesStepperValues.value.selectedValue = Math.max(
				minutesStepperValues.value.selectedValue - 1,
				0
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
		case 'hours':
			hoursStepperValues.value.selectedValue = Math.max(
				hoursStepperValues.value.selectedValue - 1,
				0
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
		case 'days':
			daysStepperValues.value.selectedValue = Math.max(daysStepperValues.value.selectedValue - 1, 0)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
		case 'months':
			monthsStepperValues.value.selectedValue = Math.max(
				monthsStepperValues.value.selectedValue - 1,
				0
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
		case 'years':
			yearsStepperValues.value.selectedValue = Math.max(
				yearsStepperValues.value.selectedValue - 1,
				0
			)
			$daysToYears[id] = {
				minutes: minutesStepperValues.value.selectedValue,
				hours: hoursStepperValues.value.selectedValue,
				days: daysStepperValues.value.selectedValue,
				months: monthsStepperValues.value.selectedValue,
				years: yearsStepperValues.value.selectedValue
			}
			break
	}
}
</script>

<div class="grid">
	{#if values.showMinutes}
		<div class="minutes">
			<p>{$_('minutes')}</p>
			<div class="stepper flex">
				<button
					class="stepper-button"
					on:click|preventDefault={(event) => decrement(event, 'minutes')}
					disabled={minutesStepperValues.value.selectedValue === 0}>-</button>
				<span class="stepper-value flex align-center"
					>{minutesStepperValues.value.selectedValue}
					{utilService.getShortLabel(minutesStepperValues.label)}</span>
				<button
					class="stepper-button"
					disabled={minutesStepperValues.value.selectedValue === 60}
					on:click|preventDefault={(event) => increment(event, 'minutes')}>+</button>
			</div>
		</div>
	{/if}
	{#if values.showHours}
		<div class="hours">
			<p>{$_('hours')}</p>
			<div class="stepper flex">
				<button
					class="stepper-button"
					on:click|preventDefault={(event) => decrement(event, 'hours')}
					disabled={hoursStepperValues.value.selectedValue === 0}>-</button>
				<span class="stepper-value flex align-center"
					>{hoursStepperValues.value.selectedValue}
					{utilService.getShortLabel(hoursStepperValues.label)}</span>
				<button
					class="stepper-button"
					disabled={hoursStepperValues.value.selectedValue === 24}
					on:click|preventDefault={(event) => increment(event, 'hours')}>+</button>
			</div>
		</div>
	{/if}
	{#if values.showDays}
		<div class="days">
			<p>{$_('days')}</p>
			<div class="stepper flex">
				<button
					class="stepper-button"
					on:click|preventDefault={(event) => decrement(event, 'days')}
					disabled={daysStepperValues.value.selectedValue === 0}>-</button>
				<span class="stepper-value flex align-center"
					>{daysStepperValues.value.selectedValue}
					{utilService.getShortLabel(daysStepperValues.label)}</span>
				<button
					class="stepper-button"
					disabled={daysStepperValues.value.selectedValue === 31}
					on:click|preventDefault={(event) => increment(event, 'days')}>+</button>
			</div>
		</div>
	{/if}
	{#if values.showMonths}
		<div class="months">
			<p>{$_('months')}</p>
			<div class="stepper flex">
				<button
					class="stepper-button"
					on:click|preventDefault={(event) => decrement(event, 'months')}
					disabled={monthsStepperValues.value.selectedValue === 0}>-</button>
				<span class="stepper-value flex align-center"
					>{monthsStepperValues.value.selectedValue}
					{utilService.getShortLabel(monthsStepperValues.label)}</span>
				<button
					class="stepper-button"
					disabled={monthsStepperValues.value.selectedValue === 12}
					on:click|preventDefault={(event) => increment(event, 'months')}>+</button>
			</div>
		</div>
	{/if}
	{#if values.showYears}
		<div class="years">
			<p>{$_('years')}</p>
			<div class="stepper flex">
				<button
					class="stepper-button"
					on:click|preventDefault={(event) => decrement(event, 'years')}
					disabled={yearsStepperValues.value.selectedValue === 0}>-</button>
				<span class="stepper-value flex align-center"
					>{yearsStepperValues.value.selectedValue}
					{utilService.getShortLabel(yearsStepperValues.label)}</span>
				<button
					class="stepper-button"
					on:click|preventDefault={(event) => increment(event, 'years')}>+</button>
			</div>
		</div>
	{/if}
</div>

<style>
.grid {
	grid-template-columns: repeat(auto-fit, 160px);
}

.stepper {
	display: flex;
	align-items: center;
	width: 200px;
	height: 40px;
	overflow: hidden;
	gap: 3.5px;
}

.stepper-button {
	padding: 9px 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.08);
}

.stepper-button:hover {
	background-color: #e5e5e5;
}

.stepper-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.stepper-value {
	width: 85px;
	height: 25px;
	text-align: center;
	justify-content: center;
	align-items: center;
	padding: 2px 13px;
	border-radius: 2px;
	border: 1px solid #e6e8f0;
	background: var(--primary-white, #fff);
}
</style>
