<script lang="ts">
import type { TrainingModel } from '../../../models/training.model'
import StarRating from 'svelte-star-rating'
import { _ } from 'svelte-i18n'

export let dog: TrainingModel
</script>

<div class="dog-detail">
	<section class="details">
		<header class="header">
			<h1 class="title">{$_('how_was_the_dog')}</h1>
		</header>
		<div class="user-rating-info content flex align-center justify-center">
			<div class="dog-info flex align-center">
				<div class="user-rating">
					<StarRating
						style={'margin: 0;'}
						config={{
							size: 45
						}}
						rating={dog.dogRating} />
				</div>
			</div>
		</div>
	</section>
</div>

<style lang="scss">
@use '../../../styles/setup/mixins';

.dog-detail {
	.details {
		@include mixins.card;
		height: 170px;

		.header {
			padding: 8px 12px 22px;
			color: var(--clr-txt-dark-primary);

			h1 {
				font-size: 22px;
			}
		}

		.content {
			gap: 10px;
			margin: 15px 0 0 -45px;
		}
		.title {
			font-weight: 600;
			font-size: 18px;
		}
	}
}
@media (max-width: 1500px) {
	.dog-name {
		display: none;
	}
}
@media (max-width: 1200px) and (min-width: 768px) {
	.dog-detail {
		margin-left: -12px !important;
	}
	.dog-name {
		display: block;
	}
}
@media (max-width: 900px) {
	.dog-name,
	.dog-image {
		display: none;
	}
	.user-rating {
		padding-left: 0 !important;
	}
}

.row > * {
	padding-left: 0 !important;
}
</style>
