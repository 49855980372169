<script lang="ts">
import { onSnapshot, type Unsubscribe } from 'firebase/firestore'
import { onDestroy, onMount } from 'svelte'
import { useNavigate, useParams } from 'svelte-navigator'
import type { DogModel } from '../../../models/dog.model'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import { dogDB } from '../../../services/database/dog.db'
import DogInfo from './DogInfo.svelte'
import DogQuickInsights from './DogQuickInsights.svelte'
import dogBreeds from '../../../data/fci-dog-breeds.json'
import FciIcon from '../dog/icons/fci_logo.svg'
import WikipediaIcon from '../dog/icons/wikipedia_logo.svg'
import AllianzCanineIcon from '../dog/icons/allianzcanine_logo.svg'
import { _ } from 'svelte-i18n'
import DogNotes from './DogNotes.svelte'
import DogBreedModal from './DogBreedModal.svelte'
import ArchiveDogConfirm from '../../common/modal/ArchiveDogConfirm.svelte'
import { Col, Container, Row } from 'sveltestrap'
import { utilService } from '../../../utils/util.service'
import Tooltip from '../../common/Tooltip.svelte'

const navigate = useNavigate()
const params = useParams()
const dogId = $params.id

let unsubscribe: Unsubscribe
let dog: DogModel

let breedStandart: string
let wikipediaUrl: string
let breedImage: string
let breedUrl: string
let standartLogo: string

let showModal = false
let showArchiveModal = false

let sessionKeys: any
let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	initSnapshotListener()
})

onDestroy(() => {
	if (unsubscribe) unsubscribe()
})

function initSnapshotListener() {
	if (unsubscribe) unsubscribe()
	unsubscribe = onSnapshot(dogDB.getDogRef(dogId), (doc) => {
		dog = doc.data() as DogModel
		breedImage = dogBreeds.find((breed) => breed.breedId == dog.breed.breedId).image
		breedUrl = dogBreeds.find((breed) => breed.breedId == dog.breed.breedId).url
		wikipediaUrl = dogBreeds.find((breed) => breed.breedId == dog.breed.breedId).wikipedia_url
		breedStandart = dogBreeds.find((breed) => breed.breedId == dog.breed.breedId).pdf
		standartLogo = dogBreeds.find((breed) => breed.breedId == dog.breed.breedId).logo
	})
}

function getDogDetails(dog: DogModel) {
	if (!dog) return
	const dogDetails = [
		{
			title: 'breed_name',
			value: dog.breed.name
		},
		{
			title: 'breed_type',
			value: dog.breed.type
		},
		{
			title: 'breed_country',
			value: dog.breed.country
		},
		{
			title: 'dog_type',
			value: dog.type
		},
		{
			title: 'chip_ID',
			value: dog.chipId || $_('not_added')
		},
		{
			title: 'papered',
			value: dog.registered ? $_('yes') : $_('no')
		}
	]
	return dogDetails
}

$: dogDetails = getDogDetails(dog)
</script>

{#if !dog}
	<CmpLoader />
{:else}
	<Container
		fluid
		class="dog-details p-0 flex-column gap-4">

		<ArchiveDogConfirm bind:isOpen={showArchiveModal} message="Are you sure you want to archive this dog?" dogId={dogId} on:close={() => {
			showArchiveModal = false
		}} dogName={dog.name}/>

		<Row>
			<Col class="dog-info-container">
				<DogInfo {dog} />
			</Col>
		</Row>
		<Row>
			<Col class="quick-insights-container">
				<DogQuickInsights {dog} />
			</Col>
		</Row>
		<Row>
			<Col class="dog-notes-container">
				<DogNotes dogNotes={dog.note} />
			</Col>
		</Row>
		<Row>
			<Col>
				<section class="details">
					<header class="header">
						<h1 class="title">{$_('breed_details')}</h1>
					</header>
					<div class="space-around content grid">
						{#each dogDetails as detail}
							{#if detail.value && detail.title != 'dog_type'}
								<div class="detail">
									<span class="title">{$_(detail.title)}:</span><br />
									<span class="value {detail.title}">{$_(`${detail.value}`)}</span>
								</div>
							{/if}
						{/each}
					</div>
					<div class="flex align-center fci-links grid">
						{#if breedStandart}
							<a
								href={breedStandart}
								target="_blank"
								rel="noreferrer">
								<img
									width="54px"
									height="54px"
									src={breedUrl ? (standartLogo ? standartLogo : FciIcon) : AllianzCanineIcon}
									alt="FCI Standard" />
								{$_('standart')}
							</a>
						{/if}
						{#if breedUrl || wikipediaUrl}
							<a
								href={breedUrl ?? wikipediaUrl}
								target="_blank"
								rel="noreferrer">
								<img
									width="54px"
									height="54px"
									src={breedUrl ? (standartLogo ? standartLogo : FciIcon) : WikipediaIcon}
									alt="FCI Breed URL" />
								{$_('breed_url')}
							</a>
						{/if}
						{#if breedImage}
							<button
								class="breed-image-button"
								on:click={() => {
									showModal = true
								}}>
								<div class="breed-img-container">
									<img
										class="breed-img"
										src={breedImage}
										alt="FCI Breed" />
								</div>
							</button>
						{/if}
					</div>
				</section>
			</Col>
		</Row>
		<Row>
			<Col class="flex edit-dog-container justify-content-end">
				{#if disabled}
					<button
						{disabled}
						class="btn theme-btn edit-dog flex"
						on:click={() =>
							navigate(`/dogs/edit/${dog.id}`, { state: { pageName: $_('edit_dog') } })}
						><Tooltip message={$_('locked_feature')} />
						{$_('edit_dog')}
					</button>
					<button
						class="btn theme-btn edit-dog flex orange"
						on:click={() => {
							showArchiveModal = true
						}}>
						{$_('archive_dog')}
					</button>{:else}
					<button
						{disabled}
						class="btn theme-btn edit-dog"
						on:click={() =>
							navigate(`/dogs/edit/${dog.id}`, { state: { pageName: $_('edit_dog') } })}>
						{$_('edit_dog')}
					</button>
					<button
						{disabled}
						class="btn theme-btn orange"
						on:click={() => {
							showArchiveModal = true
						}}>
						{$_('archive_dog')}
					</button>
				{/if}
			</Col>
		</Row>
	</Container>
{/if}
{#if showModal}
	<DogBreedModal
		{breedImage}
		on:close={() => {
			showModal = false
		}} />
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.details {
	@include mixins.card;

	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.content {
		display: grid;
		grid-template-columns: repeat(auto-fit, 200px);
		gap: 40px;
		margin-inline-start: 40px;
		margin-block-end: 40px;

		@media screen and (max-width: 768px) {
			padding-inline-start: 18px;
			justify-content: flex-start;
		}

		.detail {
			text-transform: capitalize;
			color: var(--clr-txt-dark-secondary);

			.title {
				font-weight: 600;
			}
			.value {
				font-size: 15px;
			}
		}

		.types-container {
			flex-wrap: wrap;
			width: 190px;
			gap: 5px;
		}

		.dog_type {
			background-color: #fd9703;
			font-size: 13px !important;
			white-space: nowrap;
			border-radius: 6px;
			padding: 5px 7px;
			color: white;
		}
	}
	.fci-links {
		display: grid;
		grid-template-columns: repeat(auto-fill, 90px);
		align-items: center;
		justify-content: center;
		gap: 30px;
		margin-inline-start: 40px;
		margin-block-end: 30px;

		a {
			display: flex;
			flex-direction: column;
			text-transform: uppercase;
			align-items: center;
			color: #264654;
			font-family: Nunito;
			font-size: 16px;
			font-weight: 700;
			gap: 10px;
		}
		.breed-image-button {
			border: none;
			background: transparent;
			padding: 0;

			.breed-img-container {
				border: 2px solid var(--clr-primary);
				border-radius: 6px;
				overflow: hidden;
				height: 88px;
				.breed-img {
					object-fit: contain;
					height: 100%;
					width: 100%;
				}
			}

			&:hover {
				cursor: pointer;
			}
		}

		@media screen and (max-width: 768px) {
			margin-block-end: 50px;
			margin-inline: 20px;
		}
	}
}
.btn + .btn {
    margin-left: 10px;
}
.btn:disabled {
	pointer-events: auto;
	gap: 6px;
}
</style>
