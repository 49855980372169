<script lang="ts">
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import { onDestroy, onMount } from 'svelte'
import { onSnapshot, type Unsubscribe } from 'firebase/firestore'
import { loggedInUser } from '../../../store/stores'
import { trainingDB } from '../../../services/database/training.db'
import { useParams } from 'svelte-navigator'
import TrainingInfo from './TrainingInfo.svelte'
import TrainingByRating from './TrainingByRating.svelte'
import TrainingDogRating from './TrainingDogRating.svelte'
import TrainingNotes from './TrainingNotes.svelte'
import TrainingWeather from './weatherComponent/TrainingWeather.svelte'
import { utilService } from '../../../utils/util.service'
import { Container, Col, Row } from 'sveltestrap'
import { _ } from 'svelte-i18n'
import { currAccount } from '../../../store/stores'
import { userMsg } from '../../../utils/userMsg.service'
import TrainingCompliance from './TrainingCompliance.svelte'
import TrainingLocation from './TrainingLocation.svelte'
import { useNavigate } from 'svelte-navigator'
import convert from 'convert'
import TrainingTerrainImages from './TrainingTerrainImages.svelte'
import Tooltip from '../../common/Tooltip.svelte'
import UploadsList from './UploadsList.svelte'
import { selectedAITraining } from '../../../store/stores'

const navigate = useNavigate()

const params = useParams()
const trainingId = $params.id

let sessionKeys: any
let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

let unsubscribe: Unsubscribe
let training: any

let pdf: string
let isGenerating = false
let generateButtonText = $_('generate_pdf')

let trainingResults

const unitSystem = localStorage.getItem('measurement-type')

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]

const trainingData = []

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	initSnapshotListener()
})

onDestroy(() => {
	if (unsubscribe) unsubscribe()
})

async function createPDFLink() {
	isGenerating = true
	generateButtonText = $_('generating')
	let trainingDetails = trainingData.map((data) => {
		return { name: data.name, value: utilService.getProperTrainingValue(data) }
	})
	let body
	if (training.weather) {
		if (training.weather.data) {
			body = {
				id: trainingId,
				'data[training][type]': training.type,
				'data[training][team]': training.team.name,
				'data[training][trainer]': training.trainedBy.name,
				'data[training][dog]': training.dog.name,
				'data[training][trainingTime]': `${Math.round(training.trainingTime / 60)} hr ${
					training.trainingTime - Math.round(training.trainingTime / 60) * 60
				} min`,
				'data[training][date]': `${
					months[training.dateTime.toDate().getMonth()]
				} ${training.dateTime.toDate().getDate()}, ${training.dateTime.toDate().getFullYear()}`,
				'data[training][time]': training.dateTime.toDate().toLocaleTimeString(),
				'data[training][feelingRating]': `${training.trainedByRating}`,
				'data[training][dogRating]': `${training.dogRating}`,
				'data[training][notes]': training.notes ? training.notes : 'No notes',
				'data[training][event]': training.event ? capitalizeWords(training.event) : '',
				'data[training][dogImage]': training.dog.image,
				'data[location]': training.location.address ? training.location.address : 'No location',
				'data[account][name]': $currAccount.name,
				'data[training][season]': training.season,
				'data[training][timesOfDay]': training.timesOfDay,
				'data[training][version]': `${training.version}`,
				'data[training][outdoorTerrainMaps]': training.terraingMaps
					? training.terraingMaps.outdoors
						? `${training.terraingMaps.outdoors}`
						: `No terrain data`
					: `No terrain data`,
				'data[weather][windDegree]': training.weather.data[0].wind_deg
					? `${training.weather.data[0].wind_deg}`
					: 'No data',
				'data[weather][windGust]': training.weather.data[0].wind_gust
					? unitSystem == 'metric'
						? `${(training.weather.data[0].wind_gust * 1.60934).toFixed(2)} km/h`
						: `${training.weather.data[0].wind_gust} mph`
					: 'No data',
				'data[weather][windSpeed]': training.weather.data[0].wind_speed
					? unitSystem == 'metric'
						? `${(training.weather.data[0].wind_speed * 1.60934).toFixed(2)}} km/h`
						: `${training.weather.data[0].wind_speed} mph`
					: 'No data',
				'data[weather][humidity]': training.weather.data[0].humidity
					? `${training.weather.data[0].humidity} %`
					: 'No data',
				'data[weather][uvIndex]': training.weather.data[0].uvi
					? `${training.weather.data[0].uvi} of 10`
					: 'No data',
				'data[weather][visibility]': training.weather.data[0].visibility
					? unitSystem == 'metric'
						? `${(training.weather.data[0].visibility / 1000).toFixed(1)} km`
						: `${convert(training.weather.data[0].visibility / 1000, 'km')
								.to('mi')
								.toFixed(1)} mi`
					: 'No data',
				'data[weather][airQuality]': training.airQuality.list[0].main.aqi
					? `${getAirQuality(training.airQuality.list[0].main.aqi)}`
					: 'No data',
				'data[weather][dewPoint]': training.weather.data[0].dew_point
					? unitSystem == 'metric'
						? `${training.weather.data[0].dew_point}°C`
						: `${utilService.convertFahrenheitToCelsius(training.weather.data[0].dew_point)}°F`
					: 'No data',
				'data[weather][pressure]': training.weather.data[0].pressure
					? `${training.weather.data[0].pressure} hPa`
					: 'No data',
				'data[weather][temp]': training.weather.data[0].temp
					? `${
							unitSystem
								? unitSystem === 'metric'
									? `${utilService.convertFahrenheitToCelsius(training.weather.data[0].temp)}°C`
									: `${training.weather.data[0].temp}°F`
								: utilService.convertFahrenheitToCelsius(training.weather.data[0].temp)
						}`
					: 'No data',
				'data[weather][tempDescription]': training.weather.data[0].weather[0].description
					? `${training.weather.data[0].weather[0].description}`
					: 'No data',
				'data[weather][sunrise]': training.weather.data[0].sunrise
					? new Date(training.weather.data[0].sunrise * 1000).toLocaleTimeString()
					: 'No data',
				'data[weather][sunset]': training.weather.data[0].sunset
					? new Date(training.weather.data[0].sunset * 1000).toLocaleTimeString()
					: 'No data'
			}
			trainingDetails.forEach((detail) => {
				body[`data[details][${detail.name}]`] = detail.value
			})
		} else {
			body = {
				id: trainingId,
				'data[training][type]': training.type,
				'data[training][team]': training.team.name,
				'data[training][dogImage]': training.dog.image,
				'data[training][trainer]': training.trainedBy.name,
				'data[training][dog]': training.dog.name,
				'data[training][trainingTime]': `${Math.round(training.trainingTime / 60)} hr ${
					training.trainingTime - Math.round(training.trainingTime / 60) * 60
				} min`,
				'data[training][date]': `${
					months[training.dateTime.toDate().getMonth()]
				} ${training.dateTime.toDate().getDate()}, ${training.dateTime.toDate().getFullYear()}`,
				'data[training][time]': training.dateTime.toDate().toLocaleTimeString(),
				'data[training][feelingRating]': `${training.trainedByRating}`,
				'data[training][dogRating]': `${training.dogRating}`,
				'data[training][event]': training.event ? capitalizeWords(training.event) : '',
				'data[training][notes]': training.notes ? training.notes : 'No notes',
				'data[location]': training.location.address ? training.location.address : 'No location',
				'data[account][name]': $currAccount.name,
				'data[training][season]': training.season,
				'data[training][timesOfDay]': training.timesOfDay,
				'data[training][version]': `${training.version}`
			}
			trainingDetails.forEach((detail) => {
				body[`data[details][${detail.name}]`] = detail.value
			})
		}
	} else {
		body = {
			id: trainingId,
			'data[training][type]': training.type,
			'data[training][team]': training.team.name,
			'data[training][dogImage]': training.dog.image,
			'data[training][trainer]': training.trainedBy.name,
			'data[training][dog]': training.dog.name,
			'data[training][trainingTime]': `${Math.round(training.trainingTime / 60)} hr ${
				training.trainingTime - Math.round(training.trainingTime / 60) * 60
			} min`,
			'data[training][date]': `${months[training.dateTime.toDate().getMonth()]} ${training.dateTime
				.toDate()
				.getDate()}, ${training.dateTime.toDate().getFullYear()}`,
			'data[training][time]': training.dateTime.toDate().toLocaleTimeString(),
			'data[training][feelingRating]': `${training.trainedByRating}`,
			'data[training][dogRating]': `${training.dogRating}`,
			'data[training][event]': training.event ? capitalizeWords(training.event) : '',
			'data[training][notes]': training.notes ? training.notes : 'No notes',
			'data[location]': training.location.address ? training.location.address : 'No location',
			'data[account][name]': $currAccount.name,
			'data[training][season]': training.season,
			'data[training][timesOfDay]': training.timesOfDay,
			'data[training][version]': `${training.version}`
		}
		trainingDetails.forEach((detail) => {
			body[`data[details][${detail.name}]`] = detail.value
		})
	}
	pdf = await utilService.restRequest(`${import.meta.env.VITE_APP_API_URL}/createpdf/training`, 'POST', body)
	var arrBuffer = base64ToArrayBuffer(pdf)

	// It is necessary to create a new blob object with mime-type explicitly set
	// otherwise only Chrome works like it should
	var newBlob = new Blob([arrBuffer], { type: 'application/pdf' })

	// For other browsers:
	// Create a link pointing to the ObjectURL containing the blob.
	var data = window.URL.createObjectURL(newBlob)

	var link = document.createElement('a')
	document.body.appendChild(link) //required in FF, optional for Chrome
	link.href = data
	link.download =
		`${training.type}-${training.event}-${training.dateTime.toDate().toLocaleDateString()}` + '.pdf'
	link.target = '_blank'

	isGenerating = false
	generateButtonText = $_('generate_pdf')

	link.click()
	window.URL.revokeObjectURL(data)
	link.remove()
	userMsg.successMsg($_('pdf_downloaded'))
}

function getAirQuality(aqi: number) {
	switch (aqi) {
		case 1:
			return $_('very_poor')
		case 2:
			return $_('poor')
		case 3:
			return $_('fair')
		case 4:
			return $_('good')
		case 5:
			return $_('excellent')
	}
}

function base64ToArrayBuffer(data: string) {
	var binaryString = window.atob(data)
	var binaryLen = binaryString.length
	var bytes = new Uint8Array(binaryLen)
	for (var i = 0; i < binaryLen; i++) {
		var ascii = binaryString.charCodeAt(i)
		bytes[i] = ascii
	}
	return bytes
}

function capitalizeWords(str: string) {
	return str
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

function initSnapshotListener() {
	if (unsubscribe) unsubscribe()
	unsubscribe = onSnapshot(trainingDB.getTrainingRef(trainingId), (doc) => {
		training = doc.data()
		getTrainingData()
	})
}

function getTrainingData() {
	training.data.forEach((element: any) => {
		const keys = Object.keys(element)
		var dataFields = keys.map((el) => {
			return { [el]: element[el] }
		})
		const objFields = Object.assign({}, ...dataFields)
		trainingData.push(objFields)
	})
	trainingData.forEach((training: any) => {
		training.fullMessage = utilService.getProperTrainingValue(training)
		if (utilService.getProperTrainingValue(training).length > 100) {
			training.shortMessage = utilService.getProperTrainingValue(training).slice(0, 100) + '...'
			training.showAll = false
		}
	})
	trainingResults = training.data.filter((item) => item.name == 'Results')
	$selectedAITraining = training
}

function showMore(index: number) {
	trainingData[index].showAll = !trainingData[index].showAll
}
</script>

{#if !training}
	<CmpLoader />
{:else}
	<Container
		fluid
		class="flex-column gap-4">
		<div class="dog-details">
			<Row class="gy-4">
				<TrainingInfo
					{training}
					{trainingResults} />
				<section class="details">
					<header class="header">
						<h1 class="title">{$_('details')}</h1>
					</header>
					<div class="space-around content">
						{#each trainingData as data, index}
							{#if data.name && (training.trainingTypeId != 'nzRVI7uibOwkXOkR9wfq' || (training.trainingTypeId == 'nzRVI7uibOwkXOkR9wfq' && data.value.answer != 'No')) && data.name != 'Results'}
								<div class="data">
									<span class="title">{$_(data.name)}:</span><br />
									{#if data.fullMessage.length > 100 && !data.showAll}
										<span class="value">{$_(data.shortMessage)}</span>
										<button
											class="show-more-btn"
											on:click={() => {
												showMore(index)
											}}>{$_('show_more')}</button>
									{:else if data.fullMessage.length > 0 && data.showAll}
										<span class="value">{$_(data.fullMessage)}</span>
										<button
											class="show-more-btn"
											on:click={() => {
												showMore(index)
											}}>{$_('show_less')}</button>
									{:else}
										<span class="value">{$_(data.fullMessage)}</span>
									{/if}
								</div>
							{/if}
						{/each}
					</div>
				</section>
			</Row>
		</div>
		<Row class="gy-4">
			<Col
				xl="12"
				class="p-0">
				<TrainingNotes {training} />
			</Col>
		</Row>
		<Row class="gy-4">
			<Col
				xl="6"
				class="pl-0 pr-md-0"><TrainingByRating user={training} /></Col>
			<Col
				xl="6"
				class="pl-md-0 pr-0"><TrainingDogRating dog={training} /></Col>
		</Row>
		<Row>
			<Col
				xl="12"
				class="p-0">
				<TrainingLocation
					location={{
						address: training.location.address,
						geoPoints: {
							latitude: training.location.geoPoints.latitude,
							longitude: training.location.geoPoints.longitude
						}
					}} />
			</Col>
		</Row>
		<Row class="gy-4">
			<Col
				xl="6"
				class="pl-0 pr-md-0">
				<TrainingTerrainImages {training} />
			</Col>
			<Col
				xl="6"
				class="pl-md-0 pr-0">
				<UploadsList
					fileUploads={training.fileUploads}
					trainingId={training.id} />
			</Col>
		</Row>
		<Row class="gy-4">
			<Col class="p-0">
				<TrainingWeather
					bind:weather={training.weather}
					bind:airQuality={training.airQuality}
					bind:location={training.location} />
			</Col>
		</Row>
		<Row class="gy-4">
			<Col class="p-0">
				<TrainingCompliance
					version={training.version}
					updatedAt={training.updatedAt}
					createdAt={training.createdAt}
					dateTime={training.dateTime} />
			</Col>
		</Row>
	</Container>
	<div class="generate-pdf-container flex">
		{#if training.createdAt.seconds ? training.createdAt.seconds > new Date('2023-10-13T13:40:00').getTime() / 1000 : Number(training.createdAt) > new Date('2023-10-13T13:30:00').getTime() / 1000 && ($loggedInUser.id == training.trainedBy.id || $loggedInUser.role == 'Admin')}
			{#if disabled}
				<button
					class="btn theme-btn btn-edit flex"
					on:click|stopPropagation={() =>
						navigate(`/trainings/edit/${training.id}`, {
							state: { pageName: $_('edit_training') }
						})}
					title="Edit"
					disabled={isGenerating || disabled}
					><Tooltip message={$_('locked_feature')} />
					{$_('edit')}
				</button>
			{:else}
				<button
					class="btn theme-btn btn-edit"
					on:click|stopPropagation={() =>
						navigate(`/trainings/edit/${training.id}`, {
							state: { pageName: $_('edit_training') }
						})}
					title="Edit"
					disabled={isGenerating || disabled}>
					{$_('edit')}
				</button>{/if}
		{/if}
		{#if disabled}
			<button
				class="btn theme-btn generate-pdf flex"
				on:click={() => createPDFLink()}
				disabled={isGenerating || disabled}>
				<Tooltip message={$_('locked_feature')} />
				{generateButtonText}
			</button>
		{:else}
			<button
				class="btn theme-btn generate-pdf"
				on:click={() => createPDFLink()}
				disabled={isGenerating || disabled}>
				{generateButtonText}
			</button>{/if}
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.dog-details {
	.details {
		@include mixins.card;

		.header {
			padding: 8px 12px 22px;
			color: var(--clr-txt-dark-primary);

			h1 {
				font-size: 22px;
			}
		}

		.content {
			display: grid;
			grid-template-columns: repeat(auto-fit, 230px);
			justify-content: flex-start;
			gap: 40px;
			margin-inline-start: 40px;
			padding: 0;
			margin-block-end: 40px;

			@media screen and (max-width: 768px) {
				justify-content: flex-start;
				gap: 20px;
			}

			.data {
				color: var(--clr-txt-dark-secondary);
				overflow-y: auto;
				overflow-x: auto;
				overflow-wrap: break-word;
				max-width: 350px;
				max-height: 250px;

				.title {
					font-weight: 600;
				}
				.value {
					height: 100%;
					font-size: 15px;
					white-space: pre-line;
				}
				.show-more-btn {
					color: black;
					text-decoration: underline;
				}
			}
		}
	}
}
:global(.pl-md-0) {
	@media screen and (max-width: 768px) {
		padding-left: 0 !important;
	}
}

:global(.pr-md-0) {
	@media screen and (max-width: 768px) {
		padding-right: 0 !important;
	}
}

.generate-pdf-container {
	margin-top: 24px;
	justify-content: right;
	gap: 18px;
}
.btn:disabled {
	pointer-events: auto;
	gap: 6px;
}
</style>
