<script lang="ts">
import { Lottie } from 'lottie-svelte'

export let message: string
</script>

<div class="lottie-container flex-column align-center justify-center">
	<span class="lottie"><Lottie path="/assets/animations/under-maintenance-animation.json" /></span>
	<h1 class="message">{message}</h1>
</div>

<style lang="scss">
.lottie-container {
	width: 100%;
	// padding-top: 15em;
	// max-height: 600px;
	.lottie {
		min-width: 50%;
		max-width: 900px;
		overflow: hidden;
	}

	.message {
		font-size: 2rem;
		color: #3f5b91;
		margin-bottom: 1.5rem;
	}
}
</style>
