<script lang="ts">
import { trainingService } from '../../../services/core/training.service'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import { _ } from 'svelte-i18n'
import { Link } from 'svelte-navigator'
import CategorySelector from '../../common/CategorySelector.svelte'
import { userMsg } from '../../../utils/userMsg.service'
import NoCustomTrainingAnimation from '../../common/animation/NoCustomTrainingAnimation.svelte'
import { currAccount } from '../../../store/stores'
import TrainingTypeCard from './TrainingTypeCard.svelte'
import NoResultAnimation from '../../common/animation/NoResultAnimation.svelte'
import SearchBar from '../../common/SearchBar.svelte'
import { utilService } from '../../../utils/util.service'
import { onMount } from 'svelte'
import Tooltip from '../../common/Tooltip.svelte'

let trainingTypes: any

let disabled = true
let sessionKeys: any

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
})

let searchBarValue = ''
let placeholder = $_('search')
let searchTypes: any

type ListCategory = 'public' | 'private'
const categories: ListCategory[] = ['public', 'private']
let category: ListCategory = 'public'

function selectType(trainingName: string, id: string) {
	localStorage.setItem('training', JSON.stringify({ name: trainingName, access: category, id: id }))
}

$: try {
	loadTrainingTypes(category, category == 'public' ? '' : $currAccount.id)
} catch (err: any) {
	userMsg.errorMsg(err.message, $_('can_not_load_training_types'))
}

async function loadTrainingTypes(category: string, accountId: string) {
	trainingTypes = await getTrainingTypes(category, accountId)
	searchTypes = trainingTypes
}

async function getTrainingTypes(category: string, accountId: string) {
	const trainingTypes = await trainingService.getTrainingTypes(category, accountId)
	return trainingTypes.sort((a, b) => a.name.localeCompare(b.name))
}

async function searchTypesByName(searchString: string) {
	searchTypes = trainingTypes
	// Convert the search string to lowercase for case-insensitive search
	const searchLower = searchString.toLowerCase()

	if (searchLower === '') {
		// If the search string is empty return all training types
		searchTypes = trainingTypes
	} else {
		// Use the filter method to create a new array with matching objects
		searchTypes = trainingTypes.filter((obj: any) => {
			const nameLower = obj.name.toLowerCase()
			const infoLower = obj.info.toLowerCase()
			return nameLower.includes(searchLower) || infoLower.includes(searchLower)
		})
	}
}
</script>

{#if !trainingTypes}
	<CmpLoader />
{:else}
	<div class="training-types flex-column">
		<div class="search-container">
			<form
				action=""
				on:submit={(event) => {
					event.preventDefault()
					searchTypesByName(searchBarValue)
				}}>
				<div class="flex align-center search-bar">
					<SearchBar
						bind:searchTerm={searchBarValue}
						{placeholder} />
				</div>
			</form>
		</div>
		<div class="category-selector">
			<CategorySelector
				activeCategory={category}
				{categories}
				on:select={(ev) => (category = ev.detail)} />
		</div>
		{#if trainingTypes.length == 0}
			<div class="no-training-animation">
				<NoCustomTrainingAnimation />
			</div>
		{:else}
			{#if category == 'public'}
				{#if searchTypes.length == 0}
					<div class="flex-column align-center no-result-animation">
						<NoResultAnimation />
					</div>
				{:else}
					<div class="card-container grid">
						{#each searchTypes as type}
							{#if disabled}
								<div>
									<TrainingTypeCard
										{disabled}
										name={type.name}
										icon={type.icon}
										info={type.info} />
								</div>
							{:else}
								<Link
									to="/trainings/add"
									state={{ pageName: $_('add_training') }}
									on:click={() => selectType(type.name, type.id)}>
									<TrainingTypeCard
										{disabled}
										name={type.name}
										icon={type.icon}
										info={type.info} />
								</Link>
							{/if}
						{/each}
					</div>
				{/if}
			{/if}
			{#if category == 'private'}
				{#if searchTypes.length == 0}
					<div class="flex-column align-center no-result-animation">
						<NoResultAnimation />
					</div>
				{:else}
					<div class="card-container grid">
						{#each searchTypes as type}
							{#if disabled}
								<div>
									<TrainingTypeCard
										{disabled}
										name={type.name}
										icon={type.icon}
										info={type.info} />
								</div>
							{:else}
								<Link
									to="/trainings/add"
									state={{ pageName: $_('add_training') }}
									on:click={() => selectType(type.name, type.id)}>
									<TrainingTypeCard
										{disabled}
										name={type.name}
										icon={type.icon}
										info={type.info} />
								</Link>
							{/if}
						{/each}
					</div>
				{/if}
			{/if}
		{/if}
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.training-types {
	@include mixins.card;
	padding: 24px 24px 48px 24px;
	gap: 48px;

	.category-selector {
		width: 331px;
	}

	.card-container {
		justify-content: center;
		grid-template-columns: repeat(auto-fill, 207px);
		grid-template-rows: repeat(auto-fill, 1fr);
		grid-column-gap: 48px;
		height: fit-content;
		grid-row-gap: 32px;

		.no-training-animation {
			align-items: center;
			justify-content: center;
		}

		.no-result-animation {
			width: 100%;
		}
	}

	@media screen and (max-width: 800px) {
		.category-selector {
			width: 100%;
		}
	}
}
</style>
