<script lang="ts">
import 'ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-quartz.css'
import { createGrid } from '@ag-grid-community/core'
import { userCellRenderer, dogCellRenderer } from '../reports/cellRenderers'
import type {
	GridOptions,
	ISetFilterParams,
	IDateFilterParams,
	ValueFormatterParams
} from '@ag-grid-community/core'
import { onMount } from 'svelte'
import { utilService } from '../../utils/util.service'

export let dogsForDisplay

const nameValueFormatter = (params) => {
	return params.value.name
}

var dateFilterParams: IDateFilterParams = {
	comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
		var dateAsString = cellValue
		if (dateAsString == null) return -1
		var dateParts = dateAsString.split('/')
		var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))

		if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
			return 0
		}

		if (cellDate < filterLocalDateAtMidnight) {
			return -1
		}

		if (cellDate > filterLocalDateAtMidnight) {
			return 1
		}
		return 0
	}
}

const columnDefs = [
	{
		headerName: 'Dog Information',
		children: [
			{
				field: 'dog',
				cellRenderer: dogCellRenderer,
				comparator: (valueA, valueB) => {
					return valueA.localeCompare(valueB)
				},
				rowDrag: true,
				floatingFilter: true,
				minWidth: 260,
				useValueFormatterForExport: true,
				valueGetter: (data) => data.data?.dog,
				valueFormatter: (params) => params.data?.dog?.name,
				filterValueGetter: (data) => data.data?.dog?.name,
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								valueFormatter: (params) => params.value.name
							},
							display: 'subMenu'
						},
						{
							filter: 'agSetColumnFilter',
							filterParams: {
								valueFormatter: (params) => params.value
							}
						}
					]
				} as ISetFilterParams,
				filter: 'agMultiColumnFilter',
				checkboxSelection: (params) => !params.node.group
			},
			{
				field: 'Dog Handler',
				cellRenderer: userCellRenderer,
				comparator: (valueA, valueB) => {
					return valueA.localeCompare(valueB)
				},
				floatingFilter: true,
				valueFormatter: nameValueFormatter,
				filter: 'agMultiColumnFilter',
				filterParams: {
					cellRenderer: nameValueFormatter,
					cellRendererParams: { isFilterRenderer: true }
				} as ISetFilterParams
			},
			{
				field: 'Status',
				valueGetter: (params) => utilService.capitalizeString(params.data.Status),
				floatingFilter: true,
				valueFormatter: nameValueFormatter,
				filter: 'agMultiColumnFilter',
				filterParams: {
					cellRenderer: nameValueFormatter,
					cellRendererParams: { isFilterRenderer: true }
				} as ISetFilterParams
			},
			{
				field: 'Type',
				filter: 'agMultiColumnFilter',
				floatingFilter: true,
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								valueFormatter: (params) => params.value.name
							},
							display: 'subMenu'
						},
						{
							filter: 'agSetColumnFilter',
							filterParams: {
								valueFormatter: nameValueFormatter
							}
						}
					]
				} as ISetFilterParams
			},
			{
				field: 'Last Trained',
				cellRenderer: (params) => {
					if (params.value)
						return new Date(params.value).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})
				},
				valueFormatter: (params: ValueFormatterParams) => {
					if (params.value)
						return new Date(params.value).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})
				},
				filter: 'agDateColumnFilter',
				floatingFilter: true,
				filterParams: dateFilterParams
			},
			{
				field: 'birthday',
				cellRenderer: (params) => {
					if (params.value)
						return new Date(params.value).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})
				},
				valueFormatter: (params: ValueFormatterParams) => {
					if (params.value)
						return new Date(params.value).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})
				}
			},
			{
				field: 'chipID'
			}
		]
	},
	{
		headerName: 'Dog Stats',
		children: [
			{
				field: 'totalTrainingDays'
			},
			{
				field: 'totalTrainingHours'
			},
			{
				field: 'totalTrainingSessions'
			}
		]
	}
]

let api

let dogsReportsGrid: HTMLDivElement
let gridOptions: GridOptions

onMount(() => {
	gridOptions = {
		columnDefs: columnDefs,
		rowDragManaged: true,
		rowDragText: (params, dragItemCount) => {
			return params.defaultTextValue['name']
		},
		rowData: dogsForDisplay,
		suppressContextMenu: false,
		rowSelection: 'multiple',
		defaultCsvExportParams: {
			onlySelected: true
		},
		defaultExcelExportParams: {
			onlySelected: true
		},
		useValueFormatterForExport: true,
		sideBar: {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					minWidth: 225,
					maxWidth: 225,
					width: 225
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					minWidth: 180,
					maxWidth: 400,
					width: 250
				}
			],
			position: 'right'
		},
		defaultColDef: {
			minWidth: 200
		}
	}

	api = createGrid(dogsReportsGrid, gridOptions)
})
</script>

<div class="trainings-reports">
	{#if dogsForDisplay}
		<div
			id="dogGrid"
			bind:this={dogsReportsGrid}
			class="ag-theme-quartz"
			style="height: 500px; border: none;" />
	{/if}
</div>

<style lang="scss">
@use '../../styles/setup/mixins';
.trainings-reports {
	@include mixins.card;
}

:global(.ag-root-wrapper) {
	border: none;
}
</style>
