<span class="nav-text">
	<slot />
</span>

<style lang="scss">
.nav-text {
	font-family: Nunito-Bold;
	font-size: 14px;

	@media (max-width: 768px) {
		white-space: nowrap;
		font-size: 15px;
	}
}
</style>
