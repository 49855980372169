<script lang="ts">
import type { TrainingModel } from '../../../models/training.model'
import CgNotes from 'svelte-icons-pack/cg/CgNotes'
import Icon from 'svelte-icons-pack'
import { _ } from 'svelte-i18n'

export let training: TrainingModel
</script>

<div class="notes-container">
	<section class="details">
		<header class="header">
			<h1 class="title">{$_('notes')}</h1>
		</header>
		<div class="content flex">
			{#if training.notes != ''}
				<p class="notes">{training.notes}</p>
			{:else}
				<div class="no-notes flex align-center justify-center">
					<Icon src={CgNotes} />
					<p>{$_('no_notes')}</p>
				</div>
			{/if}
		</div>
	</section>
</div>

<style lang="scss">
@use '../../../styles/setup/mixins';

.notes-container {
	// margin-top: 24px;
	.details {
		@include mixins.card;
		overflow-y: auto;
		height: 300px;

		.header {
			padding: 8px 12px 22px;
			color: var(--clr-txt-dark-primary);

			h1 {
				font-size: 22px;
			}
		}

		.content {
			gap: 10px;
			min-height: 130px;
			.no-notes {
				color: var(--clr-txt-dark-secondary);
				justify-self: center;
				gap: 5px;
				width: 100%;
				font-size: 17px;
				margin-top: 50px;

				p {
					margin-top: 3px;
				}
			}

			.notes {
				margin: 0 50px;
				justify-self: center;
				font-size: 17px;
				width: 100%;
				color: var(--clr-txt-dark-secondary);
			}
		}
		.title {
			font-weight: 600;
			font-size: 22px;
		}
	}

	@media screen and (max-width: 1200px) and (min-width: 768px) {
		margin-right: -12px;
	}
}
</style>
