<svg width="150" height="105" viewBox="0 0 150 105" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3892 15.8688C12.3892 8.62083 13.8682 6.02784 20.3892 1.84884C24.2552 -0.628163 32.7682 -0.190172 36.6192 2.68583C41.7482 6.51483 43.3892 9.75083 43.3892 16.0378C43.3892 22.3138 41.7482 25.5608 36.6522 29.3658C32.5832 32.4028 24.9042 32.7408 20.2352 30.0878C14.0412 26.5678 12.3892 23.5738 12.3892 15.8688Z" fill="currentColor"/>
<path d="M106.317 12.5668C107.115 8.31882 111.243 3.22883 115.339 1.44483C122.114 -1.50617 128.752 0.0858459 134.389 6.01385C137.108 8.87385 137.389 9.79384 137.389 15.8538C137.389 23.5118 135.736 26.5678 129.753 29.9698C117.899 36.7108 103.758 26.2098 106.317 12.5668Z" fill="currentColor"/>
<path d="M63.0751 49.7788C58.3132 46.6278 54.2301 40.9948 53.2861 36.2718L52.6392 33.0378H74.8892H97.1392L96.4922 36.2718C95.5482 40.9948 91.4652 46.6278 86.7032 49.7788C83.0662 52.1868 81.5622 52.5378 74.8892 52.5378C68.2162 52.5378 66.7121 52.1868 63.0751 49.7788Z" fill="currentColor"/>
<path d="M6.68416 45.8428C11.5002 40.5118 15.4482 37.8628 20.8282 36.3518C31.8192 33.2638 44.7392 38.6058 51.0862 48.8608C52.2332 50.7138 52.7552 52.5378 52.2662 52.9828C51.7842 53.4228 48.1442 54.5588 44.1772 55.5088C37.4472 57.1188 31.8892 60.5978 31.8892 63.1998C31.8892 63.6918 24.8682 63.9658 16.1392 63.8128C0.403179 63.5378 0.389177 63.5358 0.0531772 61.1588C-0.453823 57.5768 2.73316 50.2158 6.68416 45.8428Z" fill="currentColor"/>
<path d="M106.037 40.7618C107.494 39.6828 111.094 37.9458 114.037 36.9018C119.891 34.8258 126.601 35.0548 132.536 37.5348C140.865 41.0148 150.482 54.0848 149.698 60.8608L149.389 63.5378L133.821 63.8118C118.261 64.0868 118.252 64.0858 116.961 61.6728C115.301 58.5698 112.887 57.3218 104.415 55.1838C100.625 54.2268 97.3642 52.9618 97.1672 52.3718C96.7102 51.0008 102.571 43.3298 106.037 40.7618Z" fill="currentColor"/>
<path d="M64.8892 77.0378V66.0378V55.0378H74.8892H84.8892V66.0378V77.0378H74.8892H64.8892Z" fill="currentColor"/>
<path d="M37.4692 63.0378C38.2802 62.2128 41.7442 60.7938 45.1672 59.8848C55.7632 57.0688 54.8892 55.7968 54.8892 74.0378V90.0378H59.8892H64.8892V86.5378V83.0378H74.8892H84.8892V86.5378V90.0378H89.8892H94.8892V74.0378C94.8892 63.5838 95.2512 58.0378 95.9332 58.0378C99.0702 58.0378 110.633 61.5308 112.089 62.9178C113.253 64.0278 114.592 69.3068 116.339 79.6738C119.411 97.9058 119.449 99.4778 116.889 102.038C114.956 103.971 113.556 104.038 74.8892 104.038C36.2222 104.038 34.8222 103.971 32.8892 102.038C31.7892 100.938 30.8892 98.8618 30.8892 97.4238C30.8892 91.5458 36.0732 64.4588 37.4692 63.0378Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M67.6372 3.40484C61.7182 5.61184 57.2462 9.60485 54.7202 14.9408C53.4382 17.6498 51.3762 20.3458 50.1392 20.9308C47.2122 22.3148 47.0092 27.4968 49.8232 29.0028C50.9972 29.6308 60.8562 30.0378 74.8892 30.0378C88.9222 30.0378 98.7812 29.6308 99.9552 29.0028C102.912 27.4198 102.556 22.2988 99.3892 20.8558C98.0142 20.2298 96.8892 19.2018 96.8892 18.5728C96.8892 15.7658 90.7132 8.01882 86.5142 5.55782C80.9942 2.32282 72.9892 1.40984 67.6372 3.40484ZM80.6392 10.8578C79.1932 10.6518 78.2752 9.73383 78.0692 8.28783C77.8232 6.55283 77.0952 6.03783 74.8892 6.03783C72.6832 6.03783 71.9552 6.55283 71.7092 8.28783C71.5032 9.73383 70.5852 10.6518 69.1392 10.8578C67.3052 11.1178 66.8892 11.8108 66.8892 14.6078C66.8892 17.5998 67.2082 18.0378 69.3892 18.0378C71.3892 18.0378 71.8892 18.5378 71.8892 20.5378C71.8892 22.6488 72.3562 23.0378 74.8892 23.0378C77.4222 23.0378 77.8892 22.6488 77.8892 20.5378C77.8892 18.5378 78.3892 18.0378 80.3892 18.0378C82.5702 18.0378 82.8892 17.5998 82.8892 14.6078C82.8892 11.8108 82.4732 11.1178 80.6392 10.8578Z" fill="currentColor"/>
</svg>
