<svg
	width="80"
	height="80"
	viewBox="0 0 80 80"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#FD9E19"
		fill-opacity="0.17" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M48.2014 42.7181L49.1123 41.8123C50.3715 40.5603 52.3345 40.303 53.9456 41.1788L57.7667 43.256C60.2204 44.5899 60.7611 47.859 58.8433 49.7659L56.0022 52.5909C55.2798 53.3092 54.3834 53.8342 53.3526 53.9303C50.9683 54.1526 46.0625 54.0326 40.2748 50.6447L48.2014 42.7181ZM36.3814 30.9651L36.955 30.3948C38.3681 28.9897 38.5014 26.7338 37.2685 25.087L34.7465 21.7182C33.2206 19.6798 30.2719 19.4106 28.5229 21.1497L25.3837 24.271C24.5165 25.1334 23.9353 26.2512 24.0058 27.4912C24.1352 29.7689 24.9116 33.9298 28.3035 39.043L36.3814 30.9651Z"
		fill="#FD9E19" />
	<path
		opacity="0.6"
		d="M40.1255 38.9942C34.2339 33.1361 36.3672 30.9783 36.3806 30.9648L28.3027 39.0427C29.6351 41.0513 31.3711 43.2068 33.6299 45.4528C35.9094 47.7193 38.151 49.4017 40.274 50.6444L48.2007 42.7178C48.2007 42.7178 46.0355 44.8706 40.1255 38.9942Z"
		fill="#FD9E19" />
</svg>
