<script lang="ts">
import Checkbox from '@smui/checkbox'
import FormField from '@smui/form-field'
import Button, { Label } from '@smui/button'
import { fade } from 'svelte/transition'
import { useNavigate } from 'svelte-navigator'
import SvgDogBaseFull from '../cmps/common/SvgDogBaseFull.svelte'
import { authValidation, type SignupFields } from '../utils/validation/auth.validation'
import Loader from '../cmps/common/loader/Loader.svelte'
import { Auth } from '../services/firebase/firebase'
import { authService } from '../services/core/auth.service'
import { getAuth, createUserWithEmailAndPassword, deleteUser } from 'firebase/auth'
import { fireMsg, userMsg } from '../utils/userMsg.service'
import { beforeUpdate } from 'svelte'
import { _ } from 'svelte-i18n'
import { Form, Input } from 'sveltestrap'
import { onMount } from 'svelte'
import UnderMaintenance from '../cmps/common/UnderMaintenance.svelte'
import { underMaintenance, currAccount, loggedInUser, user } from '../store/stores'
import { utilService } from '../utils/util.service'
import { beaconService } from '../services/APIs/beacon.service'


onMount(() => {
})

const navigate = useNavigate()
let isLoading = false
let loaderMsg = 'Creating your account...'

type SignupField = 'accountName' | 'firstName' | 'lastName' | 'email' | 'password'

const signupFields: SignupFields = {
	accountName: { name: 'Account Name', value: '', invalid: false, errorMessage: '' },
	firstName: { name: 'First Name', value: '', invalid: false, errorMessage: '' },
	lastName: { name: 'Last Name', value: '', invalid: false, errorMessage: '' },
	email: { name: 'Email', value: '', invalid: false, errorMessage: '' },
	password: { name: 'Password', value: '', invalid: false, errorMessage: '' }
}

$: formValues = {
	accountName: signupFields.accountName.value,
	firstName: signupFields.firstName.value,
	lastName: signupFields.lastName.value,
	email: signupFields.email.value,
	password: signupFields.password.value
}

let termsAccepted = false
let isFormDisabled = false
let navigating = false
let resolveNavigating: () => void

beforeUpdate(() => {
	if ($loggedInUser || ($user && $user.emailVerified)) window.location.assign('/')
})

async function handleSubmit(ev: Event) {
	ev.preventDefault()

	//? Full validation for all inputs
	for (const signupField in signupFields) validateField(signupField as SignupField)
	if (!authValidation.fullValidation(signupFields)) return

	//? Check for terms and conditions
	if (!termsAccepted) {
		fireMsg({ type: 'failure', msg: $_('accept_the_rules') })
		return
	}

	try {
		const userCredentials = await createUserWithEmailAndPassword(
			Auth,
			formValues.email,
			formValues.password
		)
		isLoading = true
		isFormDisabled = true
		loaderMsg = 'Creating user'
		const url = `${import.meta.env.VITE_APP_API_URL}/signup`
		loaderMsg = 'Creating new account'
		const body = {
			user: {
				id: userCredentials.user.uid,
				email: formValues.email,
				firstName: formValues.firstName,
				lastName: formValues.lastName
			},
			account: {
				name: formValues.accountName
			}
		}
		loaderMsg = 'Sending email verification'
		await authService.sendEmailVerify()

		const response = await utilService.restRequest(url, 'POST', body)
		if (!response.user) {
			isLoading = false
			throw new Error('Could not create account')
		} else {
			navigateTo('/')
		}
	} catch (error: unknown) {
		const auth = getAuth()
		const user = auth.currentUser

		deleteUser(user)
			.then(() => {
				// user deleted
			})
			.catch((error) => {
				userMsg.errorMsg('Error', error.message)
			})
		userMsg.errorMsg('Error', error)
		isFormDisabled = false
	}
}

function navigateTo(to: string): void {
	resolveNavigating = () => navigate(to, { replace: true })
	navigating = true
}

function validateField(fieldName: SignupField) {
	if (fieldName === 'email' || fieldName === 'password') {
		signupFields[fieldName] = authValidation[fieldName](signupFields[fieldName])
	} else {
		if (formValues[fieldName].length < 2) {
			signupFields[fieldName].invalid = true
			signupFields[fieldName].errorMessage = $_('at_least_two_characters_message')
		}
		if (authValidation.specialCharacter(formValues[fieldName])) {
			signupFields[fieldName].invalid = true
			signupFields[fieldName].errorMessage = $_('no_special_characters_message')
		}
	}
}
function handleFocus(fieldName: SignupField) {
	signupFields[fieldName].invalid = false
	signupFields[fieldName].errorMessage = ''
}

function openBeacon() {
	if (window.Beacon) window.Beacon('close')

	beaconService.navigateBeacon('/ask/')
	beaconService.identifyBeacon()
	beaconService.prefillBeacon(
		`${$currAccount.name} Website Maintenance Question`,
		`Hi, when will the DogBase Teams Web App be back up?\n--- \n Name: ${
			$loggedInUser.firstName + ' ' + $loggedInUser.lastName
		} \n Account: ${$currAccount.name}\n Dogs: ${
			$currAccount.dogIds.length
		}\n Users: ${$currAccount.userIds.length}\n Teams: ${$currAccount.teamIds.length}`
	)
	beaconService.openBeacon()
}
</script>

{#if $underMaintenance && $underMaintenance.isUnderMaintenance}
	<section class="maintenance-container flex-column">
		<UnderMaintenance message={$underMaintenance.message} />
		<button
			on:click={() => openBeacon()}
			class="btn theme-btn send-message">
			{$_('send_us')}
		</button>
	</section>
{:else}
	<main class="signup">
		<div class="flex-column form-container">
			{#if !navigating}
				<div
					class="svg-container"
					transition:fade={{ delay: 250, duration: 0 }}
					on:outroend={() => {if($underMaintenance && !$underMaintenance.isUnderMaintenance) resolveNavigating()}}>
					{#if !isLoading}
						<SvgDogBaseFull />
					{:else if isLoading}
						<Loader
							{isLoading}
							{loaderMsg} />
					{/if}
				</div>
				{#if !isLoading}
					<form
						on:submit={handleSubmit}
						class="flex-column justify-center signup-form">
						<Form>
							<header class="flex-column header">
								<h1 class="title">{$_('welcome')}</h1>
								<h4 class="sub-title">{$_('lets_get_started')}</h4>
							</header>
							<fieldset class="flex-column form-fields">
								<div class="flex-column field account-name">
									<Input
										type="text"
										class="rounded"
										placeholder="Account name*"
										bind:value={signupFields.accountName.value}
										bind:invalid={signupFields.accountName.invalid}
										on:blur={() => validateField('accountName')}
										on:focus={() => handleFocus('accountName')}
										autocomplete="username"
										required={true} />
								</div>
								<fieldset class="flex name-fields">
									<div class="flex-column field first-name">
										<Input
											type="text"
											class="rounded"
											placeholder="First name*"
											bind:value={signupFields.firstName.value}
											bind:invalid={signupFields.firstName.invalid}
											on:blur={() => validateField('firstName')}
											on:focus={() => handleFocus('firstName')}
											autocomplete="given-name"
											required={true} />
									</div>
									<div class="flex-column field last-name">
										<Input
											type="text"
											class="rounded"
											placeholder="Last name*"
											bind:value={signupFields.lastName.value}
											bind:invalid={signupFields.lastName.invalid}
											on:blur={() => validateField('lastName')}
											on:focus={() => handleFocus('lastName')}
											autocomplete="family-name"
											required={true} />
									</div>
								</fieldset>
								<div class="flex-column field email">
									<Input
										type="email"
										class="rounded"
										placeholder="Email*"
										bind:value={signupFields.email.value}
										bind:invalid={signupFields.email.invalid}
										on:blur={() => validateField('email')}
										on:focus={() => handleFocus('email')}
										autocomplete="email"
										required={true} />
								</div>
								<div class="flex-column field password">
									<Input
										type="password"
										class="rounded"
										placeholder="Password*"
										bind:value={signupFields.password.value}
										bind:invalid={signupFields.password.invalid}
										on:blur={() => validateField('password')}
										on:focus={() => handleFocus('password')}
										autocomplete="new-password"
										required={true} />
								</div>
								<FormField>
									<Checkbox bind:checked={termsAccepted} />
									<span slot="label">
										{$_('i_accept_the')}
										<a
											class="link"
											href="https://app.getterms.io/view/x6YYi/tos/en-us"
											target="_blank"
											rel="noreferrer">
											{$_('terms_and_conditions')}
										</a>
									</span>
								</FormField>
							</fieldset>

							<div class="flex justify-center">
								<Button
									disabled={isFormDisabled}
									variant="raised"
									on:click={handleSubmit}>
									<Label>{$_('sign_up')}</Label>
								</Button>
							</div>

							<div class="flex justify-center suggest-sign-in">
								{$_('already_have_an_account')}
								<a href="/login">
									&nbsp;
									<span class="link">{$_('sign_in')}</span>
								</a>
							</div>
						</Form>
					</form>
				{/if}
			{/if}
		</div>
	</main>
{/if}

<style lang="scss">
.maintenance-container {
	align-items: center;
	.send-message {
		max-width: 250px;
	}
}

.signup {
	display: grid;
	width: 100%;
	height: 100vh;
	background-image: url('/assets/img/login-background.png');
	background-repeat: no-repeat;
	background-position: left max(600px, 100%) top;
	background-color: var(--clr-primary-bg);
	background-size: contain;

	.form-container {
		background-color: white;
		z-index: 10;
		padding: 30px 60px;
		padding-top: 20px;
		box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		width: 535px;
		margin: 0 auto;
		place-self: center;
		gap: 30px;
		.svg-container {
			width: 300px;
			height: 80px;
			align-self: center;
		}
	}

	.signup-form {
		gap: 25px;
		--mdc-theme-secondary: var(--clr-primary);

		:global(.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label) {
			color: var(--clr-primary);
		}

		.header {
			margin-bottom: 20px;
			:focus-visible {
				outline: none;
			}

			.title {
				color: var(--clr-primary);
			}

			.sub-title {
				color: var(--clr-txt-light-secondary);
				font-size: 14px;
				font-family: Nunito-Light;
				font-weight: 300;
			}
		}

		.form-fields {
			gap: 20px;
		}

		.name-fields {
			gap: 15px;
		}

		.suggest-sign-in {
			margin-top: 0.5rem;
			font-size: 14px;
			font-family: Nunito-Light;
		}
	}

	@media (max-width: 768px) {
		.form-container {
			width: 90%;
			padding: 15px 30px;
			.svg-container {
				width: 200px;
				height: 55px;
			}
		}

		.signup-form {
			gap: 15px;

			.header {
				margin-bottom: 10px;
				.title {
					font-size: 20px;
				}
				.sub-title {
					font-size: 12px;
				}
			}

			.form-fields {
				gap: 10px;
			}

			.name-fields {
				flex-direction: column;
				gap: 10px;
			}

			.suggest-sign-in {
				font-size: 12px;
			}
		}
	}
}
</style>
