<svg width="151" height="122" viewBox="0 0 151 122" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4788 15.8819C12.4788 8.50391 14.1168 5.45491 19.9788 1.92091C24.3998 -0.744088 32.7288 -0.441094 36.7088 2.52991C41.8378 6.35891 43.4788 9.59491 43.4788 15.8819C43.4788 22.1579 41.8378 25.4049 36.7418 29.2099C32.6728 32.2469 24.9938 32.5849 20.3248 29.9319C14.1728 26.4359 12.4788 23.4029 12.4788 15.8819Z" fill="currentColor"/>
<path d="M106.407 12.4109C107.205 8.1629 111.333 3.07291 115.429 1.28891C122.273 -1.69209 130.973 0.655904 135.479 6.6999C136.999 8.7379 137.479 10.9429 137.479 15.8819C137.479 23.3399 135.781 26.4369 129.843 29.8139C117.989 36.5549 103.848 26.0539 106.407 12.4109Z" fill="currentColor"/>
<path d="M63.1649 49.6229C58.4029 46.4719 54.3198 40.8389 53.3758 36.1159L52.7288 32.8819H74.9788H97.2288L96.5819 36.1159C95.6379 40.8389 91.5548 46.4719 86.7928 49.6229C83.1558 52.0309 81.6518 52.3819 74.9788 52.3819C68.3058 52.3819 66.8019 52.0309 63.1649 49.6229Z" fill="currentColor"/>
<path d="M9.6379 42.7739C14.0729 38.6469 16.9568 37.1349 23.4788 35.5159C30.3668 33.8059 41.7969 37.9099 47.9249 44.2929L51.3708 47.8819H47.6749C43.4529 47.8819 43.4318 47.9229 45.5528 52.0249C46.4178 53.6979 46.9808 55.1699 46.8028 55.2949C46.6248 55.4189 44.4898 56.0929 42.0588 56.7929C39.5838 57.5049 36.5149 59.3449 35.0859 60.9729L32.5339 63.8819H17.8378C9.75585 63.8819 2.43087 63.6089 1.56087 63.2749C-2.60413 61.6769 2.0689 49.8159 9.6379 42.7739Z" fill="currentColor"/>
<path d="M102.033 44.2939C105.675 40.5019 111.492 37.0709 116.479 35.7729C120.366 34.7609 128.35 35.5939 132.897 37.4859C143.027 41.7009 154.57 60.9059 148.397 63.2749C147.527 63.6089 140.202 63.8819 132.12 63.8819H117.424L114.872 60.9729C113.443 59.3449 110.374 57.5049 107.899 56.7929C105.468 56.0929 103.333 55.4189 103.155 55.2949C102.977 55.1699 103.54 53.6979 104.405 52.0249C106.526 47.9229 106.505 47.8819 102.283 47.8819H98.5869L102.033 44.2939Z" fill="currentColor"/>
<path d="M46.5509 60.3249C53.2159 58.5209 53.6748 58.0519 51.9438 54.8159C50.9658 52.9889 51.2978 52.8819 57.9438 52.8819C63.5028 52.8819 64.9788 53.1939 64.9788 54.3689C64.9788 56.0729 74.1478 74.8819 74.9788 74.8819C75.8098 74.8819 84.9788 56.0729 84.9788 54.3689C84.9788 53.1939 86.4549 52.8819 92.0139 52.8819C98.6599 52.8819 98.9919 52.9889 98.0139 54.8159C96.2829 58.0519 96.7418 58.5209 103.407 60.3249C108.855 61.7999 114.288 65.1649 112.89 66.1969C112.664 66.3639 109.428 66.9979 105.698 67.6069C97.0068 69.0249 89.9538 73.4389 85.4109 80.3019C81.5749 86.0989 79.4748 94.3259 80.3818 100.002L81.0029 103.882H67.4908H53.9788V97.3819C53.9788 93.7149 53.5428 90.8819 52.9788 90.8819C52.4148 90.8819 51.9788 93.7149 51.9788 97.3819V103.882H44.1528C37.0668 103.882 36.1019 103.642 33.9419 101.343L31.5568 98.8039L34.2938 82.1429C35.9828 71.8629 37.6239 64.8269 38.5769 63.7729C39.4279 62.8339 43.0159 61.2819 46.5509 60.3249Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.4788 74.2649C90.3508 78.5809 85.0228 86.9159 85.0228 96.8819C85.0228 110.748 96.1258 121.862 109.979 121.862C123.825 121.862 134.916 110.765 134.947 96.8819C134.988 78.6469 115.924 66.4909 99.4788 74.2649ZM113.479 93.3819L112.979 87.8819C112.546 83.1169 112.178 82.3389 110.225 82.0619C107.636 81.6939 107.152 82.6479 106.761 88.8819L106.479 93.3819L100.979 93.8819C95.9118 94.3429 95.4788 94.5789 95.4788 96.8819C95.4788 99.1849 95.9118 99.4209 100.979 99.8819L106.479 100.382L106.979 105.882C107.44 110.949 107.676 111.382 109.979 111.382C112.282 111.382 112.518 110.949 112.979 105.882L113.479 100.382L118.979 99.8819C124.046 99.4209 124.479 99.1849 124.479 96.8819C124.479 94.5789 124.046 94.3429 118.979 93.8819L113.479 93.3819Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.2288 5.54491L53.9788 9.04892V15.4299C53.9788 20.7879 53.6578 21.9829 51.9788 22.8819C49.4338 24.2439 49.3978 27.5009 51.9128 28.8469C53.0718 29.4669 62.3248 29.8819 74.9788 29.8819C87.6328 29.8819 96.8859 29.4669 98.0449 28.8469C100.56 27.5009 100.524 24.2439 97.9788 22.8819C96.2998 21.9829 95.9788 20.7879 95.9788 15.4289V9.0469L86.2288 5.81792C80.8668 4.04192 76.0288 2.46492 75.4788 2.31392C74.9288 2.16392 69.8668 3.61691 64.2288 5.54491ZM67.7558 8.88191L68.4399 12.5279C70.6599 24.3599 79.2978 24.3599 81.5178 12.5279L82.2019 8.88191H74.9788H67.7558Z" fill="currentColor"/>
</svg>
