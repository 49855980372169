<script>
import { _ } from 'svelte-i18n'

export let message = ''
export let onConfirm
export let onCancel
</script>

<div id="background-overlay" />

<div class="navigation-alert">
	<header class="form-header flex">
		<h1 class="title">{$_('warning')}</h1>
	</header>
	<p>{message}</p>
	<div class="buttons flex">
		<button
			class="btn theme-btn outline"
			on:click={onCancel}>{$_('cancel')}</button>
		<button
			class="btn theme-btn"
			on:click={onConfirm}>{$_('continue')}</button>
	</div>
</div>

<style lang="scss">
.navigation-alert {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 450px;
	min-height: 200px;
	background-color: white;
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	border-radius: 16px;
	z-index: 101;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 768px) {
		height: fit-content;
		width: 90%;
	}

	.form-header {
		font-size: 20px;
		align-self: flex-start;
		margin-top: 26px;
		margin-left: 26px;
	}

	p {
		margin-top: 8px;
		padding: 0 26px;
		width: fit-content;
		font-size: 16px;
		color: #33383f;
		margin-bottom: 20px;
	}

	.buttons {
		margin: auto 10px 10px 0;
		align-self: flex-end;
		gap: 10px;

		.btn {
			font-size: 14px !important;
		}
	}
}

#background-overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
}
</style>
