import { getAuth } from 'firebase/auth'
import moment from 'moment'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

const insightsURL = import.meta.env.VITE_APP_INSIGHTS_URL;

export const insightsService = {
	request,
	getLabels,
	getTrainingData,
	getComparisonChartData,
	getTotalTrainingTime,
	getTotalTrainingDays,
	getTotalTrainingSessions,
	getTrainingHours,
	getTrainingPercentage,
	getSuccessTrainings,
	getEventPercentage,
	getRating,
	getTrainingsNumber,
	getRandomColor,
	countTrainingDays,
	getTrainingTypes
}

async function request() {
	const auth = getAuth()
	const result = await auth.currentUser
		.getIdToken(/* forceRefresh */ true)
		.then(async function (idToken) {
			const myHeaders = new Headers()
			myHeaders.append('Content-Type', 'application/json')
			myHeaders.append('Authorization', `Bearer ${idToken}`)
			const response = await fetch(insightsURL, {
				method: 'GET',
				headers: myHeaders
			})
			const values = await response.json()
			return values
		})

	return await result
}

function getLabels(data, labelAttribute, dogId?) {
	const labels = new Map()
	data.forEach((element) => {
		let value = JSON.parse(element['data'])[labelAttribute]
		if ((dogId && (JSON.parse(element['data']).dog.id == dogId || JSON.parse(element['data']).team.id == dogId)) || !dogId) {
			if (value == null) {
				value = 'Unspecified'
			}
			if (labels.get(value) == undefined) {
				labels.set(value, 1)
			} else {
				labels.set(value, labels.get(value) + 1)
			}
		}
	})
	return labels
}

function getTrainingTypes(trainingData) {
	const trainingTypes = new Map()

	trainingData.forEach((element) => {
		const rawData = JSON.parse(element['data']);

		if (!trainingTypes.has(rawData.trainingTypeId)) {
			trainingTypes.set(rawData.trainingTypeId, rawData.type)
		}
	})

	return trainingTypes
}

function getSuccessTrainings(data, infoId?) {
	const trainings = new Map();

	data.forEach((element) => {
		const rawData = JSON.parse(element['data']);

		rawData.data.forEach(el => {
			if (el.name === 'Results' && (rawData.dog.id == infoId || rawData.team.id == infoId || rawData.trainedBy.id)) {
				const trainingType = rawData.type;

				if (!trainings.has(trainingType)) {
					trainings.set(trainingType, { Successful: 0, Unsuccessful: 0, NeedsImprovement: 0 });
				}

				switch (el.value.answer) {
					case 'Successful':
						trainings.get(trainingType).Successful++;
						break;
					case 'Unsuccessful':
						trainings.get(trainingType).Unsuccessful++;
						break;
					case 'Needs Improvement':
						trainings.get(trainingType).NeedsImprovement++;
						break;
					default:
						break;
				}
			}
		});
	});

	return trainings;
}

function getRating(data, groupBy, infoId?) {
	const ratingsCount = [0, 0, 0, 0, 0];

	data.forEach((element) => {
		const rawData = JSON.parse(element['data']);

		if (rawData.dog.id == infoId || rawData.team.id == infoId || rawData.trainingTypeId == infoId || rawData.trainedBy.id == infoId) {
			switch (groupBy) {
				case 'dogRating': {
					const rating = rawData.dogRating;

					if (rating >= 1 && rating <= 5) {
						ratingsCount[rating - 1]++;
					}
					break;
				}
				case 'userRating': {
					const rating = rawData.trainedByRating;

					if (rating >= 1 && rating <= 5) {
						ratingsCount[rating - 1]++;
					}
					break;
				}
			}
		}
	});

	return ratingsCount;
}

function getTrainingPercentage(data, groupBy, dogId?) {
	const labels = new Map()
	let value

	data.forEach((element) => {
		if ((dogId && (JSON.parse(element['data']).dog.id == dogId || JSON.parse(element['data']).team.id == dogId) || JSON.parse(element['data']).trainedBy.id == dogId) || !dogId) {

			value = JSON.parse(element['data'])['type'];

			switch (groupBy) {
				case 'sessions': {
					if (!labels.get(value)) {
						labels.set(value, 1)
					}
					else {
						labels.set(value, labels.get(value) + 1)
					}
					break;
				}

				case 'hours': {
					const trainingTime = JSON.parse(element['data'])['trainingTime']
					if (!labels.get(value)) {
						labels.set(value, trainingTime)
					}
					else {
						labels.set(value, labels.get(value) + trainingTime)
					}
					break;
				}

				case 'days': {
					const day = new Date(JSON.parse(element['data']).dateTime._seconds * 1000).toDateString();

					if (!labels.has(value)) {
						// If the key doesn't exist, set it with a count of 1
						labels.set(value, new Set([day]));
					} else {
						// If the key exists, check if the day has already been counted
						const existingDays = labels.get(value);
						existingDays.add(day);
						labels.set(value, existingDays);
					}
					break;
				}

			}
		}
	})

	if (groupBy == 'days') {
		const formattedLabels = new Map<string, number>();

		labels.forEach((value, key) => {
			formattedLabels.set(key, value.size);
		});

		return formattedLabels;
	}

	return labels
}

function getTrainingData(trainingData, groupBy, infoId?) {
	const dataMap = new Map();

	if (groupBy === 'training') {
		const days = new Map()

		trainingData.forEach((training) => {
			const rawData = JSON.parse(training['data']);
			const id = rawData.trainingTypeId;

			if (id == infoId || !infoId) {
				const data = dataMap.get(id) || {
					name: rawData.type,
					id: rawData.trainingTypeId,
					iconUrl: rawData.iconUrl,
					trainingTime: 0,
					trainingSessions: 0,
					days: 0
				};

				data.trainingTime += rawData.trainingTime;
				data.trainingSessions += 1;

				const dateTime = new Date(rawData.dateTime._seconds * 1000);

				if (!days.has(dateTime.toDateString())) {
					days.set(dateTime.toDateString(), 1)
					data.days++
				} ``

				dataMap.set(id, data);
			}
		});

		return dataMap;
	}

	const days = new Map()

	trainingData.forEach((training) => {
		const rawData = JSON.parse(training['data']);
		const id = rawData[groupBy].id;

		if (id == infoId || !infoId) {
			const data = dataMap.get(id) || {
				name: rawData[groupBy].name,
				id: rawData[groupBy].id,
				image: rawData[groupBy].image,
				icon: rawData[groupBy].icon,
				trainingTime: 0,
				trainingSessions: 0,
				days: 0
			}

			data.trainingTime += rawData.trainingTime;
			data.trainingSessions += 1;

			const dateTime = new Date(rawData.dateTime._seconds * 1000);

			if (!days.has(dateTime.toDateString()) || days.get(dateTime.toDateString()) != id) {
				days.set(dateTime.toDateString(), id)
				data.days++
			}

			dataMap.set(id, data);
		}
	});

	return dataMap;
}

function getComparisonChartData(trainingData, groupBy) {
	const getGroupName = (data, groupBy) => {
		if (groupBy === 'dog') {
			return data.dog.id;
		} else if (groupBy === 'trainedBy') {
			return data.trainedBy.id;
		} else if (groupBy === 'training') {
			return data.trainingTypeId
		} else
			return data.team.id;
	}

	const processTrainingData = (data) => {
		const result = new Map();

		data.forEach((training) => {
			const { type, trainingTypeId, trainingTime, dateTime } = JSON.parse(training['data']);
			const key = groupBy === 'training' ? trainingTypeId : groupBy === 'user' ? getGroupName(JSON.parse(training.data), 'trainedBy') : getGroupName(JSON.parse(training.data), groupBy);
			const month = new Date(dateTime._seconds * 1000).getMonth();

			if (!result.get(key)) {
				const name = groupBy === 'training' ? type : groupBy == 'user' ? JSON.parse(training['data'])['trainedBy'].name : JSON.parse(training['data'])[groupBy].name
				result.set(key, { totalTrainingTime: 0, count: 0, data: new Array(12).fill(0), name: name, id: key });
			}

			result.get(key).totalTrainingTime += trainingTime;
			result.get(key).count++;
			result.get(key).data[month] += trainingTime;
		});

		const sortedResult = Array.from(result.entries()).sort((a, b) => b[1].totalTrainingTime - a[1].totalTrainingTime);
		const sortedMap = new Map(sortedResult);

		return sortedMap;
	};

	const chartData = {
		labels: [moment('January 1, 2024').format('MMMM'), moment('February 1, 2024').format('MMMM'), moment('March 1, 2024').format('MMMM'), moment('April 1, 2024').format('MMMM'), moment('May 1, 2024').format('MMMM'), moment('June 1, 2024').format('MMMM'), moment('July 1, 2024').format('MMMM'), moment('August 1, 2024').format('MMMM'), moment('September 1, 2024').format('MMMM'), moment('October 1, 2024').format('MMMM'), moment('November 1, 2024').format('MMMM'), moment('December 1, 2024').format('MMMM')],
		datasets: Array.from(processTrainingData(trainingData).values()).map((value, index) => ({
			label: value.name,
			id: value.id,
			data: value.data,
			backgroundColor: `rgba(${index === 0 ? '75, 192, 192' : '192, 75, 192'}, 0.5)`,
			borderColor: `rgba(${index === 0 ? '75, 192, 192' : '192, 75, 192'}, 1)`,
			borderWidth: 1,
		}))
	};

	return chartData;
}

function getTotalTrainingTime(trainingData, infoId?: string) {
	const trainingByMonth = new Array(12).fill(0);

	trainingData.forEach((training) => {
		const rawData = JSON.parse(training['data']);

		if ((!infoId || (infoId == rawData.dog.id || infoId == rawData.team.id || infoId == rawData.trainingTypeId || infoId == rawData.trainedBy.id))) {
			const trainingTime = rawData.trainingTime;
			const dateTime = new Date(rawData.dateTime._seconds * 1000);
			const month = dateTime.getMonth();

			trainingByMonth[month] += trainingTime;
		}
	});

	const chartData = {
		labels: [moment('January 1, 2024').format('MMMM'), moment('February 1, 2024').format('MMMM'), moment('March 1, 2024').format('MMMM'), moment('April 1, 2024').format('MMMM'), moment('May 1, 2024').format('MMMM'), moment('June 1, 2024').format('MMMM'), moment('July 1, 2024').format('MMMM'), moment('August 1, 2024').format('MMMM'), moment('September 1, 2024').format('MMMM'), moment('October 1, 2024').format('MMMM'), moment('November 1, 2024').format('MMMM'), moment('December 1, 2024').format('MMMM')],
		datasets: [
			{
				label: 'Total Training Time',
				data: trainingByMonth,
				backgroundColor: '#fd7f6f',
				borderColor: 'rgba(75, 192, 192, 1)',
				borderWidth: 1,
			},
		],
	};

	return chartData;
}

function getTotalTrainingDays(trainingData, infoId?: string, groupBy?: string) {
	if (!groupBy) {
		const trainingByMonth = new Array(12).fill(0);
		const days = new Map()

		trainingData.forEach((training) => {
			const rawData = JSON.parse(training['data']);

			if (!infoId || infoId == rawData.dog.id || infoId == rawData.team.id || (infoId == rawData.trainedBy.id) || (infoId == rawData.trainingTypeId)) {
				const dateTime = new Date(rawData.dateTime._seconds * 1000);
				const month = dateTime.getMonth();

				if (!(days.get(dateTime.toDateString()))) {
					days.set(dateTime.toDateString(), 1)
					trainingByMonth[month]++
				}
			}
		});

		const chartData = {
			labels: [moment('January 1, 2024').format('MMMM'), moment('February 1, 2024').format('MMMM'), moment('March 1, 2024').format('MMMM'), moment('April 1, 2024').format('MMMM'), moment('May 1, 2024').format('MMMM'), moment('June 1, 2024').format('MMMM'), moment('July 1, 2024').format('MMMM'), moment('August 1, 2024').format('MMMM'), moment('September 1, 2024').format('MMMM'), moment('October 1, 2024').format('MMMM'), moment('November 1, 2024').format('MMMM'), moment('December 1, 2024').format('MMMM')],
			datasets: [
				{
					label: 'Total Training Days',
					data: trainingByMonth,
					backgroundColor: '#fd7f6f',
					borderColor: 'rgba(75, 192, 192, 1)',
					borderWidth: 1,
				},
			],
		};

		return chartData;
	} else {
		const getGroupName = (data, groupBy) => {
			if (groupBy === 'dog') {
				return data.dog.id;
			} else if (groupBy === 'trainedBy') {
				return data.trainedBy.id;
			} else if (groupBy === 'training') {
				return data.trainingTypeId
			} else
				return data.team.id;
		}

		const processTrainingData = (data) => {
			const result = new Map();
			const days = new Map()

			data.forEach((training) => {
				const { type, trainingTypeId } = JSON.parse(training['data']);
				const key = groupBy === 'training' ? trainingTypeId : groupBy === 'user' ? getGroupName(JSON.parse(training.data), 'trainedBy') : getGroupName(JSON.parse(training.data), groupBy);

				const dateTime = new Date(JSON.parse(training['data']).dateTime._seconds * 1000);

				const month = dateTime.getMonth();

				if (!result.get(key)) {
					const name = groupBy === 'training' ? type : groupBy == 'user' ? JSON.parse(training['data'])['trainedBy'].name : JSON.parse(training['data'])[groupBy].name
					result.set(key, { totalDays: 0, data: new Array(12).fill(0), name: name, id: key });
				}

				if (!(days.get(dateTime.toDateString())) || days.get(dateTime.toDateString()) != key) {
					days.set(dateTime.toDateString(), key)
					result.get(key).data[month]++
					result.get(key).totalDays++
				}
			});

			const sortedResult = Array.from(result.entries()).sort((a, b) => b[1].totalDays - a[1].totalDays);
			const sortedMap = new Map(sortedResult);

			return sortedMap;
		};

		const chartData = {
			labels: [moment('January 1, 2024').format('MMMM'), moment('February 1, 2024').format('MMMM'), moment('March 1, 2024').format('MMMM'), moment('April 1, 2024').format('MMMM'), moment('May 1, 2024').format('MMMM'), moment('June 1, 2024').format('MMMM'), moment('July 1, 2024').format('MMMM'), moment('August 1, 2024').format('MMMM'), moment('September 1, 2024').format('MMMM'), moment('October 1, 2024').format('MMMM'), moment('November 1, 2024').format('MMMM'), moment('December 1, 2024').format('MMMM')],
			datasets: Array.from(processTrainingData(trainingData).values()).map((value, index) => ({
				id: value.id,
				label: value.name,
				data: value.data,
				backgroundColor: `rgba(${index === 0 ? '75, 192, 192' : '192, 75, 192'}, 0.5)`,
				borderColor: `rgba(${index === 0 ? '75, 192, 192' : '192, 75, 192'}, 1)`,
				borderWidth: 1,
			}))
		};

		return chartData;

	}
}

function getEventPercentage(trainingData: any, infoId: string) {
	const eventMap = new Map();

	trainingData.forEach(training => {
		const rawData = JSON.parse(training['data']);
		const infoMatch = !infoId || [rawData.dog.id, rawData.team.id, rawData.trainingTypeId, rawData.trainedBy.id].includes(infoId);

		const trainingEvent = rawData.category || 'training';
		eventMap.set(trainingEvent, (eventMap.get(trainingEvent) || 0) + (infoMatch ? 1 : 0));
	});

	return eventMap;
}

function getTotalTrainingSessions(trainingData, groupBy, infoId?: string) {
	moment.locale(localStorage.getItem('language'))
	const getSessionKey = (data) => (groupBy === 'dog' ? data.dog.id : groupBy === 'team' ? data.team.id : groupBy === 'training' ? data.trainingTypeId : groupBy === 'trainedBy' ? data.trainedBy.id : '');

	const groupedData = trainingData.reduce((acc, training) => {
		const rawData = JSON.parse(training['data']);
		const groupKey = getSessionKey(rawData);

		if (!infoId || [rawData.dog.id, rawData.team.id, rawData.trainedBy.id].includes(infoId)) {
			if (!acc[groupKey]) {
				acc[groupKey] = { groupData: rawData, trainingSessions: 0, trainingSessionsByMonth: new Array(12).fill(0), id: groupKey };
			}

			acc[groupKey].trainingSessions += 1;
			const dateTime = new Date(rawData.dateTime._seconds * 1000);
			const month = dateTime.getMonth();
			acc[groupKey].trainingSessionsByMonth[month] += 1;
		}

		return acc;
	}, {});

	const sortedGroups = Object.values(groupedData).sort((a, b) => b.trainingSessions - a.trainingSessions);
	const topGroups = sortedGroups.slice(0, 15);

	const chartData = {
		labels: [moment('January 1, 2024').format('MMMM'), moment('February 1, 2024').format('MMMM'), moment('March 1, 2024').format('MMMM'), moment('April 1, 2024').format('MMMM'), moment('May 1, 2024').format('MMMM'), moment('June 1, 2024').format('MMMM'), moment('July 1, 2024').format('MMMM'), moment('August 1, 2024').format('MMMM'), moment('September 1, 2024').format('MMMM'), moment('October 1, 2024').format('MMMM'), moment('November 1, 2024').format('MMMM'), moment('December 1, 2024').format('MMMM')],
		datasets: topGroups.map((group) => ({
			id: group.id,
			name: groupBy === 'dog' ? group.groupData.dog.name : groupBy === 'team' ? group.groupData.team.name : groupBy === 'training' ? group.groupData.type : group.groupData.trainedBy.name,
			data: group.trainingSessionsByMonth,
			color: `rgba(31, 123, 244, 0.08)`,
			fillOpacity: 0.5,
		})),
	};

	return chartData;
}


function getTrainingHours(totalTrainingTime, locale) {
	const totalHours = Math.floor(totalTrainingTime / 60);
	const totalMinutes = totalTrainingTime - totalHours * 60;
	let hourStr;
	let minuteStr;

	if (locale === 'es') {
		hourStr = (totalHours === 1) ? 'h' : 'hs';
		minuteStr = (totalMinutes === 1) ? 'min' : 'mins';
	} else if (locale === 'he') {
		hourStr = (totalHours === 1) ? 'ש' : 'שעות';
		minuteStr = (totalMinutes === 1) ? 'דקה' : 'דקות';
	} else {
		hourStr = (totalHours === 1) ? 'h' : 'h';
		minuteStr = (totalMinutes === 1) ? 'min' : 'min';
	}

	return `${totalHours}${hourStr} ${totalMinutes}${minuteStr}`;
}

function getTrainingsNumber(trainingData) {
	let trainings = 0

	trainingData.forEach(() => {
		trainings++
	})

	return trainings
}

function getRandomColor() {
	const letters = '0123456789ABCDEF';
	let color = '#';
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

function countTrainingDays(trainingData) {
	const days = new Map()

	trainingData.forEach(training => {
		const dateTime = new Date(JSON.parse(training['data']).dateTime._seconds * 1000);

		if (!(days.get(dateTime.toDateString()))) {
			days.set(dateTime.toDateString(), 1)
		}
	})

	return days.size
}







