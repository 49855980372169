<script lang="ts">
import type { TrainingModel } from '../../../../models/training.model'
import { _ } from 'svelte-i18n'
import { Lottie } from 'lottie-svelte'

export let weather: TrainingModel['weather']

const unitSystem = localStorage.getItem('measurement-type')

function degreesToDirection(degrees) {
	// Mapping of directions and their degree ranges
	const directions = [
		{ label: $_('north'), min: 348.75, max: 11.25 },
		{ label: $_('north-north-east'), min: 11.25, max: 33.75 },
		{ label: $_('north-east'), min: 33.75, max: 56.25 },
		{ label: $_('east-north-east'), min: 56.25, max: 78.75 },
		{ label: $_('east'), min: 78.75, max: 101.25 },
		{ label: $_('east-south-east'), min: 101.25, max: 123.75 },
		{ label: $_('south-east'), min: 123.75, max: 146.25 },
		{ label: $_('south-south-east'), min: 146.25, max: 168.75 },
		{ label: $_('south'), min: 168.75, max: 191.25 },
		{ label: $_('south-south-west'), min: 191.25, max: 213.75 },
		{ label: $_('south-west'), min: 213.75, max: 236.25 },
		{ label: $_('west-south-west'), min: 236.25, max: 258.75 },
		{ label: $_('west'), min: 258.75, max: 281.25 },
		{ label: $_('west-north-west'), min: 281.25, max: 303.75 },
		{ label: $_('north-west'), min: 303.75, max: 326.25 },
		{ label: $_('north-north-west'), min: 326.25, max: 348.75 }
	]

	// Loop through the directions and find the matching direction
	for (const direction of directions) {
		if ((degrees > 348.75 && degrees < 360) || (degrees > 0 && degrees < 11.25)) {
			return directions[0].label
		} else if (degrees >= direction.min && degrees < direction.max) {
			return direction.label
		}
	}

	return '' // Return empty string if the degree is out of range
}
</script>

<section class="wind-container">
	<h1>{$_('wind')}</h1>
	<div class="wind-stats grid">
		<div class="direction flex-column">
			<div class="flex">
				<div class="lottie-container wind-speed">
					<Lottie path="../../../../../assets/animations/weather/wind-speed.json" />
				</div>
				<div class="flex-column">
					<h2>{$_('wind_speed')}</h2>
					<p class="speed">
						{weather.data[0].wind_speed
							? unitSystem == 'metric'
								? `${(weather.data[0].wind_speed * 1.60934).toFixed(2)} km/h`
								: `${weather.data[0].wind_speed} mph`
							: $_('no_data')}
						{weather.data[0].wind_deg
							? degreesToDirection(weather.data[0].wind_deg)
							: $_('no_data')}
					</p>
				</div>
			</div>
		</div>
		<div class="gust info-container flex">
			<div class="lottie-container">
				<Lottie path="../../../../../assets/animations/weather/wind-gust.json" />
			</div>
			<span>
				<h2>{$_('gust')}</h2>
				{#if unitSystem == 'metric'}
					<p>
						{weather.data[0].wind_gust
							? (weather.data[0].wind_gust * 1.60934).toFixed(2) + 'km/h'
							: $_('no_data')}
					</p>
				{:else}
					<p>{weather.data[0].wind_gust ? weather.data[0].wind_gust + 'mph' : $_('no_data')}</p>
				{/if}
			</span>
		</div>
		<div class="degrees info-container flex">
			<div class="lottie-container">
				<Lottie path="../../../../../assets/animations/weather/wind-degrees.json" />
			</div>
			<span>
				<h2>{$_('degrees')}</h2>
				{#if weather.data[0].wind_deg}
					{#if unitSystem == 'metric'}
						<p>{weather.data[0].wind_deg}</p>
					{:else}
						<p>{weather.data[0].wind_deg}</p>
					{/if}
				{:else}
					<p>{$_('no_data')}</p>
				{/if}
			</span>
		</div>
	</div>
</section>

<style lang="scss">
@use '../../../../styles/setup/mixins';

.wind-container {
	@include mixins.card;
	min-height: 156px;
	background: linear-gradient(#d7e0fa, #a7b3d360);
	padding: 8px 20px 20px 20px;

	h1 {
		margin-bottom: 26px;
		font-size: 22px;
	}

	.wind-stats {
		grid-template-columns: repeat(auto-fill, minmax(110px, 170px));
		justify-content: space-around;
	}

	@media (max-width: 768px) {
		.wind-stats {
			grid-template-columns: repeat(auto-fill, minmax(110px, 140px));
			align-items: center;
			margin: 0;

			.info-container,
			.direction {
				width: 200px;
			}
		}
	}

	.direction {
		font-size: 17px;
		font-weight: 500;
		white-space: nowrap;
		.speed {
			font-size: 14px;
		}
		.flex {
			gap: 10px;
		}
	}

	.gust,
	.degrees,
	.direction {
		gap: 10px;
		margin-top: 2px;
		white-space: nowrap;
		h2 {
			font-size: 20px;
			font-family: Montserrat-Medium;
			font-weight: 400;
			line-height: 24px;
		}

		p {
			font-size: 14px;
			font-family: Montserrat-Light;
			font-weight: 700;
			line-height: 17px;
		}

		.lottie-container {
			width: 40px;
			height: 40px;
			flex-shrink: 0;
		}
	}
}

@media (max-width: 1530px) {
	.wind-container {
		.wind-stats {
			gap: 18px;
		}
		.gust,
		.degrees,
		.direction {
			flex: 0;
			h2 {
				font-size: 16px;
			}

			p {
				font-size: 12px;
			}
		}
	}
}
</style>
