import {
	collection,
	doc,
	DocumentReference,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
	orderBy,
	limit,
	type DocumentData
} from 'firebase/firestore'
import { Firestore } from '../firebase/firebase'
import type { TeamModel, TeamStatus } from '../../models/team.model'
import type { QuerySnapshot, Query } from 'firebase/firestore'

export const teamDB = {
	getMany,
	getManyUsers,
	get,
	add,
	update,
	getTeamRef,
	newTeamRef,
	getDocsQuery,
	loadPage,
	loadTeamDogs
}

async function getMany(uid: string, accountId: string): Promise<QuerySnapshot<DocumentData>> {
	const queryParams = getDocsQuery(uid, accountId)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

async function getManyUsers(accountId: string, teamId: string, itemsPerPage: number): Promise<QuerySnapshot<DocumentData>> {
	const queryParams = _getUsersDocsQuery(accountId, teamId, itemsPerPage)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

async function loadTeamDogs(accountId: string, teamId: string, itemsPerPage: number): Promise<QuerySnapshot<DocumentData>> {
	const queryParams = loadTeamDogsQuery(accountId, teamId, itemsPerPage)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

async function get(teamId: string): Promise<DocumentData> {
	const docSnap = await getDoc(_getDocRef(teamId))
	if (docSnap.exists()) return docSnap.data()
	else throw new Error('Team was not found')
}

async function add(newTeam: TeamModel) {
	const newDocRef = _getNewDocRef()
	newTeam.id = newDocRef.id
	return await setDoc(newDocRef, newTeam)
}

async function update(teamId: string, editedTeam: Partial<TeamModel>) {
	if (!teamId) throw new Error('Team ID is required')
	const docRef = await updateDoc(_getDocRef(teamId), editedTeam)
	return docRef
}

function getTeamRef(documentId: string): DocumentReference<DocumentData> {
	return _getDocRef(documentId)
}

function newTeamRef(): DocumentReference<DocumentData> {
	return _getNewDocRef()
}

function getDocsQuery(uid: string, accountId: string, teamStatuses?: TeamStatus[]): Query<DocumentData> {
	// TODO: make dynamic filter ("where") handling for search
	const queryConstraints = []
	if (teamStatuses && teamStatuses.length > 0)
		queryConstraints.push(where('status', 'in', teamStatuses))
	return query(
		collection(Firestore, 'teams'),
		where('accountId', '==', accountId),
		...queryConstraints
	)
}

async function loadPage(uid: string, accountId: string, itemsPerPage: number): Promise<Query<DocumentData>> {
	return query(
		collection(Firestore, 'teams'),
		where('accountId', '==', accountId),
		orderBy('__name__'),
		limit(itemsPerPage || 1)
	)
}

function loadTeamDogsQuery(accountId: string, teamId: string, itemsPerPage: number): Query<DocumentData> {
	return query(
		collection(Firestore, 'teams', teamId, 'dogs'),
		where('accountId', '==', accountId),
		orderBy('__name__'),
		limit(itemsPerPage || 1)
	)
}

function _getUsersDocsQuery(accountId: string, teamId: string, itemsPerPage: number): Query<DocumentData> {
	return query(
		collection(Firestore, 'teams', teamId, 'teamUsers'),
		where('accountId', '==', accountId),
		orderBy('__name__'),
		limit(itemsPerPage || 1)
	)
}

function _getDocRef(documentId: string): DocumentReference<DocumentData> {
	return doc(Firestore, 'teams', documentId)
}

function _getNewDocRef(): DocumentReference<DocumentData> {
	return doc(collection(Firestore, 'teams'))
}
