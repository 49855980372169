<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#FFF0CF"
		fill-opacity="0.63" />
	<g clip-path="url(#clip0_548_25836)">
		<path
			d="M36.3333 13.3337H29.3333V13.0003C29.3333 11.7137 28.2867 10.667 27 10.667H26.6667H21.3333H21C19.7133 10.667 18.6667 11.7137 18.6667 13.0003V13.3337H11.6667C9.644 13.3337 8 14.9777 8 17.0003V33.667C8 35.6897 9.644 37.3337 11.6667 37.3337H36.3333C38.356 37.3337 40 35.6897 40 33.667V17.0003C40 14.9777 38.356 13.3337 36.3333 13.3337ZM26.668 16.0003H21.3333V13.3337H26.6667L26.668 16.0003ZM18 20.0003C19.8373 20.0003 21.3333 21.4963 21.3333 23.3337C21.3333 25.171 19.8373 26.667 18 26.667C16.1627 26.667 14.6667 25.171 14.6667 23.3337C14.6667 21.4963 16.1627 20.0003 18 20.0003ZM24 32.3337C24 32.8857 23.552 33.3337 23 33.3337H13C12.448 33.3337 12 32.8857 12 32.3337V31.667C12 29.6443 13.644 28.0003 15.6667 28.0003H20.3333C22.356 28.0003 24 29.6443 24 31.667V32.3337ZM35 33.3337H27.6667C27.1147 33.3337 26.6667 32.8857 26.6667 32.3337C26.6667 31.7817 27.1147 31.3337 27.6667 31.3337H35C35.552 31.3337 36 31.7817 36 32.3337C36 32.8857 35.552 33.3337 35 33.3337ZM35 28.0003H27.6667C27.1147 28.0003 26.6667 27.5523 26.6667 27.0003C26.6667 26.4483 27.1147 26.0003 27.6667 26.0003H35C35.552 26.0003 36 26.4483 36 27.0003C36 27.5523 35.552 28.0003 35 28.0003ZM35 22.667H27.6667C27.1147 22.667 26.6667 22.219 26.6667 21.667C26.6667 21.115 27.1147 20.667 27.6667 20.667H35C35.552 20.667 36 21.115 36 21.667C36 22.219 35.552 22.667 35 22.667Z"
			fill="#FD9703" />
	</g>
	<defs>
		<clipPath id="clip0_548_25836">
			<rect
				width="32"
				height="32"
				fill="white"
				transform="translate(8 8)" />
		</clipPath>
	</defs>
</svg>
