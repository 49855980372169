<svg
	width="80"
	height="80"
	viewBox="0 0 80 80"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#EEF0F7" />
	<path
		d="M46.6543 21H57.1436V30.7368H46.6543V21Z"
		fill="#8DB2C4" />
	<path
		d="M61 30.7373L53.3225 30.7722L53.2969 24.8741C53.2876 22.7541 54.9988 21.0276 57.1189 21.018V21.018C59.2389 21.0084 60.9651 22.7192 60.9743 24.8393L61 30.7373Z"
		fill="#4A86A1" />
	<rect
		x="26.6289"
		y="21"
		width="26.7"
		height="37"
		rx="7"
		fill="#8DB2C4" />
	<path
		d="M19 48.2627H46.6536V57.9995H24C21.2386 57.9995 19 55.761 19 52.9995V48.2627Z"
		fill="#B5D6E6" />
	<rect
		x="39.9785"
		y="40.4736"
		width="13.35"
		height="17.5263"
		rx="6.675"
		fill="#8DB2C4" />
	<rect
		opacity="0.7"
		x="31.3965"
		y="26.8418"
		width="9.53572"
		height="2.53158"
		rx="1.26579"
		fill="#2D7392" />
	<rect
		opacity="0.7"
		x="31.3965"
		y="31.7109"
		width="17.1643"
		height="2.53158"
		rx="1.26579"
		fill="#2D7392" />
	<rect
		opacity="0.7"
		x="31.3965"
		y="36.5791"
		width="17.1643"
		height="2.53158"
		rx="1.26579"
		fill="#2D7392" />
	<rect
		opacity="0.7"
		x="31.3965"
		y="41.4473"
		width="17.1643"
		height="2.53158"
		rx="1.26579"
		fill="#2D7392" />
</svg>
