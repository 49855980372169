<script lang="ts">
import type { TrainingModel } from '../../../../models/training.model'
import { utilService } from '../../../../utils/util.service'
import moment from 'moment'
import { _ } from 'svelte-i18n'
import OpenWeatherLogo from './icon/OpenWeatherLogo.svelte'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

moment.locale(localStorage.getItem('language'))

export let weather: TrainingModel['weather']
export let location

const unitSystem = localStorage.getItem('measurement-type')

function convertTimestampToTime(timestamp) {
	const date = new Date(timestamp * 1000)
	const hours = String(date.getHours()).padStart(2, '0')
	const minutes = String(date.getMinutes()).padStart(2, '0')
	const seconds = String(date.getSeconds()).padStart(2, '0')
	return `${hours}:${minutes}:${seconds}`
}
</script>

<section class="temperature-container flex-column">
	<div class="temperature-today flex align-center justify-center">
		<div class="col col-first flex-column">
			<p class="day">
				{weather.data[0].dt
					? moment(new Date(weather.data[0].dt * 1000)).format('MMMM[,] D YYYY h:mm a')
					: $_('no_data')}
			</p>
			<p class="place">{location.address}</p>
			<span class="flex align-center">
				{#if unitSystem == 'imperial'}
					<p class="degrees">
						{weather.data[0].temp ? Math.floor(weather.data[0].temp) + '°F' : $_('no_data')}
					</p>
				{:else}
					<p class="degrees">
						{weather.data[0].temp
							? utilService.convertFahrenheitToCelsius(weather.data[0].temp) + '°C'
							: $_('no_data')}
					</p>
				{/if}
				{#if weather.data[0].clouds}
					{#if weather.data[0].clouds < 10}
						<i class="wi wi-day-sunny wi-fw" />
					{:else}
						<i class="wi wi-day-cloudy wi-fw" />
					{/if}
				{:else}
					<p>{$_('no_data')}</p>
				{/if}
			</span>
			<span class="flex align-center feels-like">
				{#if unitSystem == 'imperial'}
					<p class="flex align-center">
						{$_('feels_like')}
						{weather.data[0].feels_like
							? Math.floor(weather.data[0].feels_like) + '°F'
							: $_('no_data')}.
					</p>
				{:else}
					<p class="flex align-center">
						{$_('feels_like')}
						{weather.data[0].feels_like
							? utilService.convertFahrenheitToCelsius(weather.data[0].feels_like) + '°C'
							: $_('no_data')}.
					</p>
				{/if}
				{#if weather.data[0].weather[0] && weather.data[0].weather[0].main}
					&nbsp{utilService.capitalizeFirstLetter(weather.data[0].weather[0].main)}. {utilService.capitalizeFirstLetter(
						weather.data[0].weather[0].description
					)}
				{/if}
			</span>
		</div>
		<div class="col col-second flex">
			<div class="sunrise info-container flex">
				<span class="svg-container"
					><svg
						width="29"
						height="34"
						viewBox="0 0 29 34"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M4.14088 25.9247H1.49805M7.1988 17.1037L5.33003 14.9097M22.2255 17.1037L24.0943 14.9097M27.9264 25.9247H25.2836M8.10514 25.9247C8.10514 21.6407 11.0632 18.1678 14.7122 18.1678C18.3612 18.1678 21.3193 21.6407 21.3193 25.9247M27.9264 32.1302H1.49805M19.9979 7.30808L14.7122 1.10254M14.7122 1.10254L9.42656 7.30808M14.7122 1.10254V11.9622"
							stroke="#020419"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
				<span>
					<h2>{$_('sunrise')}</h2>
					<p>
						{weather.data[0].sunrise
							? convertTimestampToTime(weather.data[0].sunrise)
							: $_('sunrise_unknown')}
					</p>
				</span>
			</div>
			<div class="sunset info-container flex">
				<span class="svg-container"
					><svg
						width="29"
						height="34"
						viewBox="0 0 29 34"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M4.1858 25.9247H1.54297M7.24372 17.1037L5.37495 14.9097M22.2705 17.1037L24.1392 14.9097M27.9713 25.9247H25.3285M8.15006 25.9247C8.15006 21.6407 11.1082 18.1678 14.7571 18.1678C18.4061 18.1678 21.3642 21.6407 21.3642 25.9247M27.9713 32.1302H1.54297M20.0428 5.75669L14.7571 11.9622M14.7571 11.9622L9.47148 5.75669M14.7571 11.9622V1.10254"
							stroke="#020419"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
				<span>
					<h2>{$_('sunset')}</h2>
					<p>
						{weather.data[0].sunset
							? convertTimestampToTime(weather.data[0].sunset)
							: $_('sunset_unknown')}
					</p>
				</span>
			</div>
		</div>
	</div>
	<a
		href="https://openweathermap.org/"
		target="_blank"
		class="open-weather flex">
		<svelte:component this={OpenWeatherLogo} />
	</a>
</section>

<style lang="scss">
@use '../../../../styles/setup/mixins';

@font-face {
	font-family: 'weathericons';
	src: url('./font/weathericons-regular-webfont.eot');
	src: url('./font/weathericons-regular-webfont.eot?#iefix') format('embedded-opentype'),
		url('./font/weathericons-regular-webfont.woff2') format('woff2'),
		url('./font/weathericons-regular-webfont.woff') format('woff'),
		url('./font/weathericons-regular-webfont.ttf') format('truetype'),
		url('./font/weathericons-regular-webfont.svg#weather_iconsregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
.wi {
	display: inline-block;
	font-family: 'weathericons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 3rem;
	margin-left: 0.5rem;
}
.wi-day-sunny:before {
	content: '\f00d';
}

.wi-day-cloudy:before {
	content: '\f002';
}

.wi-fw {
	text-align: center;
	width: 1.4em;
}

.temperature-container {
	@include mixins.card;
	height: 100%;

	font-family: 'Montserrat';
	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.temperature-today {
		font-family: Montserrat-Medium;
		height: fit-content;
		padding: 2rem 3rem 0 3rem;
		gap: 1rem;

		.col {
			&.col-first {
				white-space: nowrap;
			}

			justify-content: flex-end;
			.degrees {
				font-style: normal;
				font-weight: 400;
				font-size: 80px;
				line-height: 84px;
			}
			.place {
				font-size: 21px;
				white-space: normal;
				margin-bottom: 30px;
			}
			.time {
				font-family: Montserrat-Bold;
				font-size: 45px;
			}
			.day {
				color: var(--clr-txt-dark-secondary);
				font-family: Montserrat-Medium;
				font-size: 14px;
			}

			.feels-like {
				font-family: Montserrat-Medium;
				white-space: normal;
				font-size: 22px;

				p {
					gap: 10px;
				}
			}
		}
		.col-second {
			text-align: right;
			white-space: nowrap;
			flex-direction: column;
			align-items: flex-end;
			font-family: Montserrat-Medium !important;
			gap: 40px;

			@media (max-width: 1670px) {
				flex-direction: row !important;
			}

			.sunset,
			.sunrise {
				gap: 10px;
				h2 {
					font-size: 24px;
					font-family: Montserrat-Medium;
					font-weight: 400;
					line-height: 24px;
				}

				p {
					font-size: 15px;
					font-family: Montserrat-Light;
					font-weight: 700;
					line-height: 17px;
				}
			}

			.svg-container {
				height: 35px;
				width: 35px;
			}
		}
	}

	.open-weather {
		color: var(--clr-txt-dark-secondary);
		margin: auto 22px 14px auto;
	}

	@media (max-width: 1670px) {
		.temperature-today {
			flex-direction: column !important;
			align-self: flex-start;
			align-items: flex-start;
			gap: 2rem;

			.col-second {
				text-align: left;
				flex-direction: row;
				margin-top: 2rem;
			}
		}
	}
}
</style>
