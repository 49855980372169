<script lang="ts">
import Select from 'svelte-select/Select.svelte'
import type { TrainingEvent } from '../../../models/training.model'
import { _ } from 'svelte-i18n'

export let category: { value: TrainingEvent; label: TrainingEvent }

const categories: TrainingEvent[] = [
	'training',
	'deployment/mission',
	'demonstration',
	'testing',
	'certification'
]

function displayFormat(category: TrainingEvent): string {
	if (category === 'negative/blank') {
		return 'Negative/Blank'
	} else
	if (category === 'deployment/mission') {
		return 'Deployment/Mission'
	}
	return category.charAt(0).toUpperCase() + category.slice(1)
}
</script>

<Select
	bind:value={category}
	clearable={false}
	showChevron={true}
	searchable={false}
	items={categories.map((cat) => ({ value: cat, label: displayFormat(cat) }))} />
