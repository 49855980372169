<script lang="ts">
import { dogService } from '../../../services/core/dog.service'
import type { TeamModel } from '../../../models/team.model'
import type { TeamUserModel } from '../../../models/user.model'
import { loggedInUser } from '../../../store/stores'
import NoUserAnimation from '../../common/animation/NoUserAnimation.svelte'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import { _ } from 'svelte-i18n'
import { useNavigate } from 'svelte-navigator'
import NameInitials from '../../common/NameInitials.svelte'
import { utilService } from '../../../utils/util.service'
import { userMsg, fireMsg } from '../../../utils/userMsg.service'
import RemoveUserIcon from './icons/RemoveUserIcon.svelte'
import Alert from '../../common/Alert.svelte'
import Icon from 'svelte-icons-pack'
import AiOutlineEye from 'svelte-icons-pack/ai/AiOutlineEye'

export let teamHandlers: TeamUserModel[]
export let team: TeamModel
export let rolesAndTypes: { id: string; role: string; type: string }[]
export let disabled: boolean

const navigate = useNavigate()

let showAlert = false
let alertMessage = ''
let selectedUserId: string

const getFullName = (user: TeamUserModel) => {
	return `${user.firstName} ${user.lastName}`
}

async function getDogName(): Promise<string | string[]> {
	if (!team.dogIds || team.dogIds.length === 0) return ['NA']
	const accountId = $loggedInUser.selectedAccount.id
	const dogs = await dogService.query(accountId)
	if (!dogs || dogs.length === 0) return ['NA']
	const dogsName = dogs.map((dog) => dog.name)
	return dogsName
}

function capitalizeWords(str: string) {
	return str
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

async function removeUserFromTeam(userId: string) {
	try {
		const url = `${import.meta.env.VITE_APP_API_URL}/teamRemoveUser/team/${team.id}/user/${userId}`
		await utilService.restRequest(url, 'PATCH')
		userMsg.successMsg($_('user_was_removed_from_team'))
	} catch (error: unknown) {
		fireMsg({
			type: 'failure',
			msg: $_('must_have_admin_role')
		})
	}
}

let getAlertMessage = (userName: string) =>
	`Are you sure you want to remove ${userName} user from ${team.name}?`

function onModalCancel() {
	showAlert = false
}

function onAlertConfirm() {
	showAlert = false
	removeUserFromTeam(selectedUserId)
}

$: isUserAdmin = rolesAndTypes
	? rolesAndTypes.find((user) => user.id == $loggedInUser.id).role === 'admin'
	: false
</script>

{#if !teamHandlers}
	<NoUserAnimation />
{:else if teamHandlers.length === 0}
	<CmpLoader />
{:else if teamHandlers && teamHandlers.length > 0}
	<section class="flex-column user-list">
		<header class="header-container">
			<ul class="flex align-center header">
				<li class="user">{$_('users')}</li>
				<li class="type">{$_('type')}</li>
				<li class="role">{$_('role')}</li>
				<li class="dog">{$_('dogs')}</li>
				<li class="hours">{$_('training_hours')}</li>
				<li class="days">{$_('training_days')}</li>
				<li class="sessions">{$_('training_sessions')}</li>
				{#if isUserAdmin}
					<li class="action">{$_('action')}</li>
				{/if}
			</ul>
		</header>
		<div class="flex-column content">
			{#each teamHandlers as user, i}
				<button
					on:click={() =>
						navigate(`/users/${user.id}`, { state: { pageName: $_('user_details') } })}
					class="user-preview-container">
					<ul class="flex align-center user-preview">
						<li class="user">
							<div class="user-img-container">
								<NameInitials
									name={getFullName(user)}
									theme="secondary"
									fontSize={18} />
							</div>
							{getFullName(user)}
						</li>
						<li class="role">
							{capitalizeWords($_(`user_types.${rolesAndTypes[i].type}`))}
						</li>
						<li class="type">
							{capitalizeWords($_(`user_roles.${rolesAndTypes[i].role}`))}
						</li>
						<li>
							<div class="dog">
								{#await getDogName() then dogNames}
									{dogNames.length}
								{/await}
							</div>
						</li>
						<li class="hours">{(user.stats.totalTrainingTime / 60).toFixed()}</li>
						<li class="days">{user.stats.totalTrainingDays}</li>
						<li class="sessions">{user.stats.totalTrainingSessions}</li>

						{#if isUserAdmin}
							<li class="action flex">
								<button
									on:click={() =>
										navigate(`/users/${user.id}`, {
											state: { pageName: $_('user_details') }
										})}
									class="btn btn-svg btn-edit"
									title="Details">
									<Icon src={AiOutlineEye} />
								</button>
								{#if !disabled}
									<button
										on:click={(event) => {
											event.stopPropagation()
											alertMessage = getAlertMessage(getFullName(user))
											selectedUserId = user.id
											showAlert = true
										}}
										class="btn btn-svg btn-edit"
										title="Remove User">
										<div class="svg-container">
											<RemoveUserIcon />
										</div>
									</button>
								{/if}
							</li>
						{/if}
					</ul>
				</button>
			{/each}
		</div>
	</section>
{/if}
{#if showAlert}
	<Alert
		title={'warning'}
		message={alertMessage}
		onConfirm={onAlertConfirm}
		onCancel={onModalCancel} />
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';
.user-list {
	@include mixins.card;
	overflow-x: auto;

	.header,
	.user-preview {
		display: grid;
		grid-template-columns:
			minmax(110px, 2fr) minmax(120px, 0.7fr) repeat(2, 60px) repeat(3, minmax(72px, 1fr))
			90px;
		min-width: fit-content;

		> li {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		.hours,
		.days,
		.sessions,
		.dog,
		.action {
			text-align: center;
			justify-content: center;
			min-width: 60px;
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);
	}

	.content {
		font-size: 14px;
	}

	.user-preview {
		border-top: 2px solid #ebe9f1;
		padding: 10px 14px;
		transition: background-color 0.15s ease-out;

		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}

		.user-img-container {
			width: 40px;
			height: 40px;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.action {
			gap: 0px;
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

				.svg-container {
					width: 15px;
					height: 15px;
				}

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}
				:global(svg) {
					font-size: 18px;
				}
			}
		}
	}

	@media screen and (max-width: 1100px) {
		.header {
			display: none;
		}
		.user-preview {
			display: flex;

			.action {
				margin: 0 0 0 auto;
			}
		}
		.role,
		.type,
		.hours,
		.dog,
		.days,
		.sessions {
			display: none !important;
		}
	}
}
</style>
