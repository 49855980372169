
import {
  getDoc,
  doc, DocumentReference,
  type DocumentData
} from 'firebase/firestore'
import { Firestore } from '../firebase/firebase'

export const maintenanceDB = {
  get
}

async function get(id: string): Promise<DocumentData> {
  const docSnap = await getDoc(_getDocRef(id))
  if (docSnap.exists()) return docSnap.data()
  else throw new Error('not found')
}

function _getDocRef(documentId: string): DocumentReference<DocumentData> {
  return doc(Firestore, 'underMaintenance', documentId)
}