<svg
	width="25"
	height="25"
	viewBox="0 0 24 24"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M5 3.75C4.66848 3.75 4.35054 3.8817 4.11612 4.11612C3.8817 4.35054 3.75 4.66848 3.75 5V19C3.75 19.3315 3.8817 19.6495 4.11612 19.8839C4.35054 20.1183 4.66848 20.25 5 20.25H9C9.41421 20.25 9.75 20.5858 9.75 21C9.75 21.4142 9.41421 21.75 9 21.75H5C4.27065 21.75 3.57118 21.4603 3.05546 20.9445C2.53973 20.4288 2.25 19.7293 2.25 19V5C2.25 4.27065 2.53973 3.57118 3.05546 3.05546C3.57118 2.53973 4.27065 2.25 5 2.25H9C9.41421 2.25 9.75 2.58579 9.75 3C9.75 3.41421 9.41421 3.75 9 3.75H5Z"
		fill="currentColor" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M15.4697 6.46967C15.7626 6.17678 16.2374 6.17678 16.5303 6.46967L21.5303 11.4697C21.8232 11.7626 21.8232 12.2374 21.5303 12.5303L16.5303 17.5303C16.2374 17.8232 15.7626 17.8232 15.4697 17.5303C15.1768 17.2374 15.1768 16.7626 15.4697 16.4697L19.9393 12L15.4697 7.53033C15.1768 7.23744 15.1768 6.76256 15.4697 6.46967Z"
		fill="currentColor" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12Z"
		fill="currentColor" />
</svg>
