<script lang="ts">
import { afterUpdate, onMount } from 'svelte'
import { _ } from 'svelte-i18n'
import { toggle } from '../../../store/trainingWidgetsStore'

export let values: {
	icons?: string[]
	labels: string[]
	selectedIndex: number
	value: {
		selectedIndex: number | string
		labels: string[]
		answer: string
	}
	filled?: boolean
	maxLines?: number
	trigger?: number
	min?: number
	max?: number
	defaultSliderValue?: number
	label?: string
	divisions?: number
}

export let data = []
export let selectedValue = ''
export let id: string = null
values.value = {
	selectedIndex: values.selectedIndex,
	answer: values.labels[values.selectedIndex],
	labels: values.labels
}
if (!$toggle[id]) {
	$toggle[id] = null
}
let refs: HTMLDivElement[] = []
let selector: HTMLDivElement

function selectOption(index: number) {
	values.selectedIndex = index
	selectedValue = values.labels[index]
	values.value.selectedIndex = values.selectedIndex
	values.value.labels = values.labels
	values.value.answer = values.labels[index]
	$toggle[id] = index
}

onMount(() => {
	const container = document.querySelector('.toggle-switch-container') as HTMLElement
	if (container) {
		const containerWidth = container.offsetWidth

		const longestLabelLength = Math.max(...values.labels.map((label) => label.length))

		const baseFontSize = 8
		const desiredFontSize = (baseFontSize * containerWidth) / (longestLabelLength * 10)
		document.documentElement.style.setProperty('--dynamic-font-size', `${desiredFontSize}px`)
	}
})

afterUpdate(() => {
	if (values.selectedIndex === -1) return
	const activeOptionDiv = refs[values.selectedIndex] as HTMLDivElement
	selector.style.transform = `translateX(${activeOptionDiv.offsetLeft}px)`
	selector.style.width = `${activeOptionDiv.clientWidth}px`
	selector.style.height = `${activeOptionDiv.clientHeight}px`
	selector.style.transition = 'transform 0.3s ease-out, width 0.2s 0.1s'
	if (data.length > 0 && $toggle[id] == null) {
		values.selectedIndex = data[0].value.selectedIndex
	}
})
</script>

<div class="toggle-switch-container">
	<div
		class="selector"
		bind:this={selector} />
	<div class="toggle-options flex">
		{#each values.labels as label, index}
			<div
				class="toggle-option"
				class:selected={values.value.selectedIndex === index}
				class:first-child={(index = 0)}
				class:last-child={index === values.labels.length - 1}
				on:click={() => selectOption(index)}
				bind:this={refs[index]}
				on:keydown>
				<span>{$_(label)}</span>
			</div>
		{/each}
	</div>
</div>

<style lang="scss">
:global(:root) {
	--toggle-dynamic-font-size: 18px;
}
.toggle-switch-container {
	position: relative;
	display: flex;
	align-items: center;
	background-color: #e6e8f0;
	width: fit-content;
	border-radius: 6px;
	padding: 5px 4px;
	gap: 10px;

	.selector {
		position: absolute;
		left: 0;
		height: 26px;
		z-index: 1;
		border-radius: 5px;
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	}

	.toggle-options {
		display: flex;

		@media screen and (max-width: 768px) {
			span {
				font-size: var(--dynamic-font-size);
			}
		}
	}

	@keyframes color-to-white {
		0% {
			color: black;
		}
		100% {
			color: #fff;
		}
	}

	.toggle-option {
		position: relative;
		border-radius: 6px;
		padding: 2px 12px;
		cursor: pointer;
		color: black;
		z-index: 1;

		@media screen and (max-width: 768px) {
			padding: 2px 6px;
		}

		&.selected {
			animation: color-to-white 0.3s forwards;
		}
	}
}
</style>
