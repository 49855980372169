<script lang="ts">
import { _ } from 'svelte-i18n'
import Icon from 'svelte-icons-pack'
import CgClose from 'svelte-icons-pack/cg/CgClose'
import { createEventDispatcher } from 'svelte'
import Select from 'svelte-select/Select.svelte'
import type { AccountUserModel, TeamUserModel } from '../../../models/user.model'
import { userMsg } from '../../../utils/userMsg.service'
import { fireMsg } from '../../../utils/userMsg.service'
import { utilService } from '../../../utils/util.service'

export let isOpen: boolean

export let accountUsers: AccountUserModel[]
export let teamHandlers: TeamUserModel[]
export let teamId: string

const dispatch = createEventDispatcher()

function closeModal() {
	isOpen = false
	selectedUserId = { value: '', label: '' }
	dispatch('closeModal', { isOpen })
}

async function addUserToTeam() {
	try {
		const url = `${import.meta.env.VITE_APP_API_URL}/teamAddUser/team/${teamId}/user/${selectedUserId.value}`
		await utilService.restRequest(url, 'PATCH')
		userMsg.successMsg($_('user_was_added_to_team'))
	} catch (error) {
		fireMsg({
			type: 'failure',
			msg: $_('must_have_admin_role')
		})
	}
}

let selectedUserId: { value: string; label: string } = { value: '', label: '' }
let selectItems: { value: string; label: string }[]
let usersToAdd = []

$: {
	if (teamHandlers && teamHandlers.length > 0) {
		usersToAdd = accountUsers.filter(
			(accountUser) => !teamHandlers.some((teamUser) => teamUser.id === accountUser.id)
		)
	} else usersToAdd = accountUsers

	selectItems = usersToAdd.map((user) => ({
		value: user.id,
		label: user.firstName + ' ' + user.lastName
	}))
}
$: isFormDisabled = !selectedUserId
</script>

{#if isOpen}
	<div
		id="background-overlay"
		on:click={closeModal}
		on:keydown={closeModal} />

	<div class="user-add-modal-container">
		<form class="user-add-modal">
			<header class="form-header flex">
				<h1 class="title">{$_('add_user')}</h1>
				<button
					on:click={closeModal}
					class="close-button">
					<Icon
						src={CgClose}
						color="#D8DAE5" />
				</button>
			</header>

			<div class="select flex-column">
				<Select
					showChevron={true}
					searchable={true}
					clearable={false}
					required={true}
					placeholder={$_('select_user_to_add')}
					bind:value={selectedUserId}
					items={selectItems}>
					<div
						slot="empty"
						style="padding: 25px 10px; text-align: center; color: var(--list-empty-color, #78848f)">
						{$_('no_users_to_add')}
					</div>
				</Select>
			</div>

			<div class="btns-container flex">
				<button
					class="btn theme-btn outline"
					on:click={closeModal}>{$_('cancel')}</button>
				<button
					disabled={isFormDisabled}
					class="btn theme-btn"
					on:click={(event) => {
						event.stopPropagation()
						event.preventDefault()
						addUserToTeam()
						closeModal()
					}}>{$_('add_user')}</button>
			</div>
		</form>
	</div>
{/if}

<style lang="scss">
.user-add-modal-container {
	position: fixed;
	z-index: 2000;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	background: #fff;
	width: 747px;
	border-radius: 16px;

	.user-add-modal {
		padding: 36px 34px;
		font-size: 24px;

		.form-header {
			margin-bottom: 30px;

			.close-button {
				margin: 0 0 0 auto;
			}
		}

		.btns-container {
			justify-content: flex-end;
			margin: 30px 0 0 auto;
			gap: 12px;
		}
	}

	@media screen and (max-width: 768px) {
		width: 90% !important;
	}
}

#background-overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
}
</style>
