<script lang="ts">
import type { TrainingEvent } from '../../../models/training.model'
import { _ } from 'svelte-i18n'

export let event: TrainingEvent
</script>

<div class="dog-status">
	<span class={event.toLowerCase()}>
		{$_(`event_types.${event}`)}
	</span>
</div>

<style lang="scss">
.dog-status {
	text-transform: capitalize;
	font-size: 12px;

	span {
		padding: 4px 8px;
		border-radius: 6px;
		color: white;

		&.certification {
			background-color: #0ec256;
		}
		&.training {
			background-color: #fa9b11;
		}
		&.deployment\/mission {
			background-color: #e57559;
		}
		&.demonstration {
			background-color: #e9df58;
		}
		&.negative\/blank {
			background-color: #a7a7a7;
		}
		&.testing {
			background-color: #4ea3f3;
		}
	}
}
</style>
