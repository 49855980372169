<script lang="ts">
import SveltyPicker from 'svelty-picker'
import { _ } from 'svelte-i18n'

export let dateTime = ''
export let initialDate: Date = undefined

export let options: {
	id: string
	showDate: boolean
	timepicker: boolean
	onlyTimepicker?: boolean
}

const endDate = new Date()
endDate.setHours(23, 59, 59, 999)
</script>

<SveltyPicker
	bind:value={dateTime}
	mode={options.timepicker ? 'time' : 'date'}
	format={options.timepicker ? 'hh:i' : 'dd/mm/yyyy'}
	todayBtn={true}
	clearBtn={true}
	inputClasses="rounded border w-100"
	todayBtnClasses="sdt-action-btn sdt-today-btn ml-auto mr-1"
	clearBtnClasses="sdt-action-btn sdt-clear-btn mr-1"
	placeholder={$_(options.timepicker ? 'please_select_time' : 'please_select_date')}
	{initialDate}
	{endDate} />
