<script lang="ts">
import SelectButton from '../cmps/common/SelectButton.svelte'
import Terms from '../cmps/settings/Terms.svelte'
import LocaleSwitcher from '../i18n/LocaleSwitcher.svelte'
import { _, locale } from 'svelte-i18n'
import { userMsg } from '../utils/userMsg.service'
import { Container } from 'sveltestrap'
import { onMount } from 'svelte'
import TransactionList from '../cmps/checkout/TransactionList.svelte'
import YourPlan from '../cmps/checkout/YourPlan.svelte'
import { utilService } from '../utils/util.service'
import CmpLoader from '../cmps/common/loader/CmpLoader.svelte'



let transactions: any[]
let sessionKeys: any
let subscription: any

onMount(async () => {

	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	subscription = sessionKeys.activeSubscriptions[0]
	transactions = sessionKeys.transactions
})

function handleSubmit(event: Event) {
	event.preventDefault()
	$locale = commonSettings[0].value
	commonSettings.forEach((setting) => localStorage.setItem(setting.name, setting.value))
	trainingSettings.forEach((setting) => localStorage.setItem(setting.name, setting.value))
	userMsg.successMsg($_('settings_updated'))
}

export const getAppVersion = () => {
	return import.meta.env.VITE_APP_VERSION as string
}

const version = getAppVersion()

let commonSettings = [
	{
		name: 'language',
		value: $locale || 'en'
	},
	{
		name: 'measurement-type',
		value: localStorage.getItem('measurement-type') || 'metric'
	}
]

let trainingSettings = [
	{
		name: 'gps-enabled',
		value: localStorage.getItem('gps-enabled') || 'yes'
	},
	{
		name: 'address-enabled',
		value: localStorage.getItem('address-enabled') || 'yes'
	}
]

let isListOpen = false
</script>

<Container
	class="flex-column form-container p-0 gap-4"
	fluid>
	<div class="settings flex-column align-center form-container">
		<div class="plan">
			{#if !sessionKeys}
				<div class="preloader">
					<CmpLoader />
				</div>
			{:else}
				<h1 class="title">{$_('subscription_and_billing')}</h1>
				{#if sessionKeys.subscriptionStatus !== 'active' || !subscription}
					<YourPlan
						{sessionKeys}
						isSubscribed={false}
						bind:isListOpen />
				{:else}
					<YourPlan
						isSubscribed={true}
						bind:isListOpen
						subscriptionInfo={{
							planTitle: 'subscription.items[0].price.description',
							subscriptionExpiryDate: subscription.current_billing_period.ends_at,
							price:
								'subscription.items[0].price.unit_price.amount.slice(0, -2)' +
								',' +
								'subscription.items[0].price.unit_price.amount.slice(-2)' +
								' ' +
								'subscription.items[0].price.unit_price.currency_code',
							id: subscription.id
						}} />
				{/if}
			{/if}
			<TransactionList
				{transactions}
				{isListOpen} />
		</div>
		<form
			on:submit={handleSubmit}
			class="flex-column settings-form">
			<div class="common-settings flex-column">
				<header class="flex align-center form-header">
					<h1 class="title">{$_('common_settings')}</h1>
				</header>
				<div class="field language">
					<label for="measurement-type">{$_(commonSettings[0].name)}</label>
					<LocaleSwitcher bind:value={commonSettings[0].value} />
				</div>
				<div class="field measurement-type">
					<label for="measurement-type">{$_(commonSettings[1].name)}</label>
					<SelectButton
						id="measurement-type"
						bind:value={commonSettings[1].value}
						choices={[
							{ label: $_('metric'), value: 'metric' },
							{ label: $_('imperial'), value: 'imperial' }
						]} />
				</div>
			</div>
			<div class="training-settings flex-column">
				<header class="flex align-center form-header">
					<h1 class="title">{$_('training_settings')}</h1>
				</header>
				<div class="training-settings-grid grid">
					{#each trainingSettings as setting}
						<div class="field {setting.name}">
							<label for={setting.name}>{$_(setting.name)}</label>
							<SelectButton
								id={setting.name}
								bind:value={setting.value}
								choices={[
									{ label: $_('yes'), value: 'yes' },
									{ label: $_('no'), value: 'no' }
								]} />
						</div>
					{/each}
				</div>
			</div>
			<div class="flex align-center btns-container">
				<button
					type="submit"
					class="btn theme-btn submit"
					on:click={handleSubmit}>
					{$_('save')}
				</button>
			</div>
		</form>
	</div>
	<Terms />
	{#if version}
		<h4 class="app-version flex justify-center">
			{$_('app_version')}: {version}
			- Env: {import.meta.env?.PROD ? $_('production') : $_('staging')}&nbsp;-&nbsp;
			<a
				href="https://support.dogbase.co/category/59-changelog"
				target="_blank">
				{$_('changelog')}</a>
		</h4>
	{/if}
</Container>

<style lang="scss">
@use '../styles/setup/mixins';

.settings {
	gap: 25px;

	.plan {
		@include mixins.card;
		align-self: flex-start;
		padding: 28px 24px;
		width: 100%;

		.title {
			display: flex;
			align-items: center;
			font-size: 20px;
			font-family: Nunito-SemiBold;
			gap: 16px;

			&::before {
				content: '';
				position: relative;
				width: 16px;
				height: 32px;
				background-color: #ef4f5052;
				border-radius: 4px;
			}
		}
	}

	.settings-form {
		@include mixins.card;
		padding: 28px 24px;
		width: 100%;
		gap: 70px;

		.form-header {
			.title {
				display: flex;
				align-items: center;
				font-size: 20px;
				font-family: Nunito-SemiBold;
				gap: 16px;

				&::before {
					content: '';
					position: relative;
					width: 16px;
					height: 32px;
					background-color: #ef4f5052;
					border-radius: 4px;
				}
			}
		}

		.field {
			display: flex;
			flex-direction: column;
			gap: 6px;

			label {
				font-size: 14px;
				font-family: Nunito-SemiBold;
				color: #33383f;
			}
		}
	}

	.training-settings,
	.common-settings {
		gap: 25px;
	}

	.training-settings-grid {
		grid-template-columns: repeat(auto-fill, 130px);
		column-gap: 50px;
		row-gap: 25px;
		width: 600px;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.btns-container {
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;
	}
}
</style>
