<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#EEF0F7" />
	<g clip-path="url(#clip0_37_23)">
		<path
			d="M34.0125 29.0725L30.9469 27.5647C30.826 27.5053 30.6928 27.4743 30.5578 27.4743C30.4228 27.4743 30.2896 27.5053 30.1688 27.5647L27.1125 29.0725C26.9669 29.1431 26.8441 29.2523 26.758 29.3879C26.6719 29.5236 26.6258 29.6802 26.625 29.8402C26.625 36.6667 30.375 36.75 30.5625 36.75C30.75 36.75 34.5 36.676 34.5 29.8402C34.4992 29.6802 34.4531 29.5236 34.367 29.3879C34.2809 29.2523 34.1581 29.1431 34.0125 29.0725ZM30.5625 35.0202C29.8313 35.0202 28.4813 33.938 28.3594 30.3952L30.5625 29.2945L32.7375 30.3675C32.6156 33.975 31.275 34.9832 30.5625 35.0202Z"
			fill="#264A59" />
		<path
			d="M31.875 10.85H19.6219C19.5061 10.8493 19.3914 10.8713 19.2844 10.9147C19.2482 10.9368 19.2137 10.9616 19.1813 10.9888C19.1175 11.0184 19.0577 11.0557 19.0031 11.0997L13.7531 16.2797V16.3352C13.6095 16.4847 13.532 16.6844 13.5375 16.8902V34.16C13.5374 34.8405 13.8088 35.4938 14.293 35.9784C14.7772 36.4631 15.4353 36.7403 16.125 36.75H24.8719C24.987 36.75 25.101 36.7276 25.2073 36.6842C25.3137 36.6407 25.4103 36.577 25.4917 36.4967C25.5731 36.4164 25.6377 36.321 25.6817 36.2161C25.7258 36.1112 25.7484 35.9987 25.7484 35.8851C25.7484 35.7715 25.7258 35.6591 25.6817 35.5542C25.6377 35.4492 25.5731 35.3539 25.4917 35.2736C25.4103 35.1933 25.3137 35.1295 25.2073 35.0861C25.101 35.0426 24.987 35.0202 24.8719 35.0202H16.125C15.8938 35.0202 15.672 34.9296 15.5085 34.7683C15.345 34.607 15.2531 34.3882 15.2531 34.16V17.7597H19.6313C19.8625 17.7597 20.0843 17.6691 20.2478 17.5078C20.4113 17.3465 20.5031 17.1277 20.5031 16.8995V12.5797H31.875C32.1062 12.5797 32.328 12.6704 32.4915 12.8317C32.655 12.993 32.7469 13.2118 32.7469 13.44V26.39C32.7469 26.6194 32.8392 26.8394 33.0036 27.0016C33.168 27.1638 33.391 27.2549 33.6234 27.2549C33.8559 27.2549 34.0789 27.1638 34.2433 27.0016C34.4077 26.8394 34.5 26.6194 34.5 26.39V13.44C34.5 12.7531 34.2234 12.0943 33.7312 11.6086C33.2389 11.1229 32.5712 10.85 31.875 10.85ZM16.5 16.03L18.75 13.7915V16.03H16.5Z"
			fill="#264A59" />
		<path
			d="M27.45 21.21C27.45 20.5276 27.2449 19.8605 26.8607 19.2931C26.4764 18.7258 25.9303 18.2835 25.2913 18.0224C24.6524 17.7612 23.9493 17.6929 23.2709 17.826C22.5926 17.9592 21.9695 18.2878 21.4805 18.7703C20.9914 19.2528 20.6584 19.8676 20.5234 20.5369C20.3885 21.2062 20.4578 21.8999 20.7224 22.5304C20.9871 23.1608 21.4353 23.6997 22.0104 24.0788C22.5854 24.4579 23.2615 24.6603 23.9531 24.6603C24.8798 24.6578 25.7678 24.2935 26.4231 23.647C27.0783 23.0005 27.4475 22.1243 27.45 21.21ZM22.2 21.21C22.2 20.8679 22.3028 20.5335 22.4955 20.249C22.6881 19.9645 22.9619 19.7428 23.2822 19.6119C23.6026 19.481 23.9551 19.4467 24.2951 19.5135C24.6352 19.5802 24.9476 19.745 25.1928 19.9869C25.438 20.2288 25.6049 20.537 25.6726 20.8725C25.7402 21.2081 25.7055 21.5559 25.5728 21.8719C25.4401 22.188 25.2154 22.4582 24.9271 22.6482C24.6388 22.8383 24.2999 22.9397 23.9531 22.9397C23.4882 22.9397 23.0423 22.7575 22.7135 22.4331C22.3847 22.1087 22.2 21.6688 22.2 21.21Z"
			fill="#264A59" />
		<path
			d="M27.4969 27.2595C27.4969 27.0313 27.405 26.8125 27.2415 26.6512C27.078 26.4899 26.8562 26.3993 26.625 26.3993H20.3906C20.2755 26.3993 20.1615 26.4216 20.0552 26.4651C19.9488 26.5085 19.8522 26.5723 19.7708 26.6526C19.6894 26.7329 19.6248 26.8282 19.5808 26.9332C19.5367 27.0381 19.5141 27.1505 19.5141 27.2641C19.5141 27.3777 19.5367 27.4902 19.5808 27.5951C19.6248 27.7 19.6894 27.7954 19.7708 27.8757C19.8522 27.956 19.9488 28.0197 20.0552 28.0632C20.1615 28.1066 20.2755 28.129 20.3906 28.129H26.625C26.7403 28.129 26.8544 28.1065 26.9608 28.0626C27.0672 28.0188 27.1638 27.9546 27.2449 27.8738C27.3259 27.7929 27.39 27.697 27.4332 27.5915C27.4765 27.4861 27.4981 27.3732 27.4969 27.2595Z"
			fill="#264A59" />
		<path
			d="M20.3906 29.8495C20.1582 29.8495 19.9352 29.9406 19.7708 30.1028C19.6064 30.265 19.5141 30.485 19.5141 30.7144C19.5141 30.9438 19.6064 31.1637 19.7708 31.3259C19.9352 31.4881 20.1582 31.5792 20.3906 31.5792H24C24.2325 31.5792 24.4554 31.4881 24.6198 31.3259C24.7842 31.1637 24.8766 30.9438 24.8766 30.7144C24.8766 30.485 24.7842 30.265 24.6198 30.1028C24.4554 29.9406 24.2325 29.8495 24 29.8495H20.3906Z"
			fill="#264A59" />
	</g>
	<defs>
		<clipPath id="clip0_37_23">
			<rect
				width="30"
				height="37"
				fill="white"
				transform="translate(9 9)" />
		</clipPath>
	</defs>
</svg>
