<script lang="ts">
import moment from 'moment'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale
import { _ } from 'svelte-i18n'

moment.locale(localStorage.getItem('language'))

export let version
export let updatedAt
export let createdAt
export let dateTime

let text = moment

let infoForDisplay = [
	{
		title: 'version',
		value: version.toFixed(1),
		iconSrc: '../../../assets/img/version.svg'
	},
	{
		title: 'created_at',
		value: moment(createdAt.toDate()).format('MMMM D[,] YYYY HH:mm'),
		iconSrc: '../../../assets/img/created-at.svg'
	},
	{
		title: 'training_date_time',
		value: moment(dateTime.toDate()).format('MMMM D[,] YYYY HH:mm'),
		iconSrc: '../../../assets/img/calendar.svg'
	}
]

$: if (updatedAt) {
	infoForDisplay.push(
		{
			title: 'last_updated',
			value: updatedAt ? moment(updatedAt.toDate()).format('MMMM D[,] YYYY HH:mm') : '',
			iconSrc: '../../../assets/img/edited-at.svg'
		},
		{
			title: 'times_edited',
			value: +version.toFixed(1).toString().split('.')[1] - 1,
			iconSrc: '../../../assets/img/times-edited.svg'
		}
	)
}
</script>

{#if version}
	<div class="compliance">
		<header class="header">
			<h1 class="title">{$_('compliance')}</h1>
		</header>
		<div class="compliance-info flex">
			{#each infoForDisplay as info, index}
				<div class="flex-row align-center container">
					<img
						src={info.iconSrc}
						alt=""
						width="48px"
						height="48px" />
					<p id={'compliance-title-' + index}>
						{$_(info.title)}: <span id={'compliance-value-' + index}>{info.value}</span>
					</p>
				</div>
			{/each}
		</div>
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.compliance {
	@include mixins.card;
	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.compliance-info {
		font-size: 1.05rem;
		font-weight: 600;
		margin: 0 0 24px 16px;
		gap: 1rem;

		.container {
			gap: 1rem;
			margin-bottom: 0.5rem;
			padding: 0;
		}

		#compliance-value-1,
		#compliance-value-2,
		#compliance-value-3 {
			display: block;
		}
	}
}

@media screen and (max-width: 900px) {
	.compliance {
		.compliance-info {
			flex-direction: column;
			font-size: 1.05rem;
			font-weight: 600;
			margin: 0 0 24px 16px;
			gap: 1rem;

			.container {
				gap: 1rem;
				margin-bottom: 0.5rem;
				padding: 0;
			}
		}
	}
}
</style>
