<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#E9ECEF" />
	<g clip-path="url(#clip0_548_25716)">
		<path
			d="M35.0399 12.9497C34.5291 12.3946 33.8211 12.0617 33.0678 12.0225C32.3144 11.9832 31.5757 12.2407 31.0099 12.7397L22.1899 21.5697C22.0316 21.7296 21.9298 21.9367 21.8999 22.1597L21.5099 25.3797C21.4399 25.9557 21.9909 26.6477 22.6199 26.4897L25.8399 26.0997C26.0629 26.0699 26.2701 25.9681 26.4299 25.8097L35.2499 16.9797C35.7556 16.4171 36.0175 15.6768 35.9781 14.9213C35.9388 14.1658 35.6014 13.4568 35.0399 12.9497Z"
			fill="#2D7392" />
		<path
			d="M26.09 28.08L22.86 28.48C22.409 28.5266 21.9534 28.4719 21.5262 28.3199C21.0991 28.1679 20.7114 27.9224 20.3913 27.6014C20.0712 27.2803 19.8268 26.8918 19.6761 26.4642C19.5253 26.0367 19.472 25.5808 19.52 25.13L19.92 21.91C19.9981 21.2476 20.2976 20.6309 20.77 20.16L25.92 15H15C14.2051 15.0024 13.4434 15.3192 12.8813 15.8813C12.3192 16.4434 12.0024 17.2051 12 18V33C12.0024 33.7949 12.3192 34.5566 12.8813 35.1187C13.4434 35.6808 14.2051 35.9976 15 36H29C29.7949 35.9976 30.5566 35.6808 31.1187 35.1187C31.6808 34.5566 31.9976 33.7949 32 33V23.07L27.84 27.23C27.3691 27.7024 26.7524 28.0019 26.09 28.08Z"
			fill="#2D7392" />
	</g>
	<defs>
		<clipPath id="clip0_548_25716">
			<rect
				width="24"
				height="24"
				fill="white"
				transform="translate(12 12)" />
		</clipPath>
	</defs>
</svg>
