<script lang="ts">
import { _ } from 'svelte-i18n'
import UserInviteModal from './../../../cmps/users/user/UserInviteModal.svelte'
import type { AccountUserModel } from '../../../models/user.model'
import { accountService } from '../../../services/core/account.service'
import { currAccount } from '../../../store/stores'
import { loggedInUser } from '../../../store/stores'
import { onMount } from 'svelte'
import { fireMsg } from '../../../utils/userMsg.service'

export let sessionKeys: any

let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

let isOpen = false

function closeModal() {
	isOpen = false
}

onMount(async () => {
	isAllowed = await checkIfAllowed()
})

let accountUsersPromise: Promise<AccountUserModel[]>
let accountUsers: AccountUserModel[]

async function checkIfAllowed() {
	accountUsersPromise = accountService.queryUsers($currAccount.userIds, $currAccount.id)
	accountUsers = await accountUsersPromise
	if (accountUsers)
		if (accountUsers.find((user) => user.id === $loggedInUser.id).role == 'member') {
			return false
		}
	return true
}

let isAllowed = false

let showTooltip = false
function toggleTooltip() {
	showTooltip = true
}

function hideTooltip() {
	showTooltip = false
}
</script>

{#if !sessionKeys}
	<div class="container">
		<button
			disabled={true}
			class="invite-user"
			on:click={() => {
				if (isAllowed) isOpen = true
				else
					fireMsg({
						type: 'failure',
						msg: $_('only_admin_message')
					})
			}}
			title={$_('invite_user')}>
			<div class="flex-column btn-content">
				<div
					class="svg-container"
					style="background-color: #d3cfcf">
					<svg
						width="26"
						height="30"
						viewBox="0 0 26 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.60235 6.00295C6.0717 3.74638 8.22324 1.22553 10.3007 0.498557C13.3253 -0.559993 15.9249 0.0648161 18.1889 2.39495C20.953 5.23902 21.2027 8.89339 18.8549 12.1297C17.034 14.6391 12.9552 15.7241 9.99099 14.4877C6.81763 13.1639 4.88889 9.43452 5.60235 6.00295Z"
							fill="#808080" />
						<path
							d="M5.29532 19.02C6.02612 18.4664 7.53224 17.6339 8.64165 17.1707C11.4896 15.9822 15.9066 16.1205 18.8663 17.4912C19.7996 17.9234 21.0741 18.8725 22.0457 20.0792C24.2153 22.774 26.9838 27.867 25.481 29.5687C25.1002 29.9999 12.3589 29.9999 12.3589 29.9999C3.89736 29.9999 0.745252 29.886 0.425955 29.5687C-1.12714 28.0255 1.8109 21.6609 5.29532 19.02Z"
							fill="#808080" />
						<defs fill="#808080">
							<linearGradient
								id="paint0_linear_0_415"
								x1="12.9542"
								y1="0"
								x2="12.9542"
								y2="15"
								gradientUnits="userSpaceOnUse"
								fill="#808080">
								<stop
									stop-color="#808080"
									fill="#808080" />
								<stop
									offset="1"
									stop-color="#808080"
									fill="#808080" />
							</linearGradient>
							<linearGradient
								id="paint1_linear_0_415"
								x1="12.9545"
								y1="16.3635"
								x2="12.9545"
								y2="29.9999"
								gradientUnits="userSpaceOnUse"
								fill="#808080">
								<stop
									stop-color="#808080"
									fill="#808080" />
								<stop
									offset="1"
									stop-color="#808080"
									fill="#808080" />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('invite')}</span>
						<span>{$_('user')}</span>
					</h1>
				</header>
			</div>
		</button>
	</div>
{:else if disabled}
	<div
		class="container"
		on:mouseover={toggleTooltip}
		on:mouseout={hideTooltip}
		on:focus={toggleTooltip}
		on:blur={hideTooltip}
		aria-hidden={!showTooltip}>
		<button
			{disabled}
			class="invite-user"
			on:click={() => {
				if (isAllowed) isOpen = true
				else
					fireMsg({
						type: 'failure',
						msg: $_('only_admin_message')
					})
			}}
			title={$_('invite_user')}>
			<div class="flex-column btn-content">
				<div class="flex-column btn-content">
					<div
						class="svg-container"
						style="background-color: #d3cfcf">
						<svg
							width="26"
							height="30"
							viewBox="0 0 26 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M5.60235 6.00295C6.0717 3.74638 8.22324 1.22553 10.3007 0.498557C13.3253 -0.559993 15.9249 0.0648161 18.1889 2.39495C20.953 5.23902 21.2027 8.89339 18.8549 12.1297C17.034 14.6391 12.9552 15.7241 9.99099 14.4877C6.81763 13.1639 4.88889 9.43452 5.60235 6.00295Z"
								fill="#808080" />
							<path
								d="M5.29532 19.02C6.02612 18.4664 7.53224 17.6339 8.64165 17.1707C11.4896 15.9822 15.9066 16.1205 18.8663 17.4912C19.7996 17.9234 21.0741 18.8725 22.0457 20.0792C24.2153 22.774 26.9838 27.867 25.481 29.5687C25.1002 29.9999 12.3589 29.9999 12.3589 29.9999C3.89736 29.9999 0.745252 29.886 0.425955 29.5687C-1.12714 28.0255 1.8109 21.6609 5.29532 19.02Z"
								fill="#808080" />
							<defs fill="#808080">
								<linearGradient
									id="paint0_linear_0_415"
									x1="12.9542"
									y1="0"
									x2="12.9542"
									y2="15"
									gradientUnits="userSpaceOnUse"
									fill="#808080">
									<stop
										stop-color="#808080"
										fill="#808080" />
									<stop
										offset="1"
										stop-color="#808080"
										fill="#808080" />
								</linearGradient>
								<linearGradient
									id="paint1_linear_0_415"
									x1="12.9545"
									y1="16.3635"
									x2="12.9545"
									y2="29.9999"
									gradientUnits="userSpaceOnUse"
									fill="#808080">
									<stop
										stop-color="#808080"
										fill="#808080" />
									<stop
										offset="1"
										stop-color="#808080"
										fill="#808080" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<header class="title">
						<h1 class="flex-column action">
							<span>{$_('invite')}</span>
							<span>{$_('user')}</span>
						</h1>
					</header>
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('invite')}</span>
						<span>{$_('user')}</span>
					</h1>
				</header>
			</div>
		</button>
		{#if showTooltip}
			<div class="tooltip">{$_('locked_feature')}</div>
		{/if}
	</div>
{:else if !disabled}
	<div class="container">
		<button
			class="invite-user"
			on:click={() => {
				if (isAllowed) isOpen = true
				else
					fireMsg({
						type: 'failure',
						msg: $_('only_admin_message')
					})
			}}
			title={$_('invite_user')}>
			<div class="flex-column btn-content">
				{#if isAllowed}
					<div class="svg-container">
						<svg
							width="26"
							height="30"
							viewBox="0 0 26 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M5.60235 6.00295C6.0717 3.74638 8.22324 1.22553 10.3007 0.498557C13.3253 -0.559993 15.9249 0.0648161 18.1889 2.39495C20.953 5.23902 21.2027 8.89339 18.8549 12.1297C17.034 14.6391 12.9552 15.7241 9.99099 14.4877C6.81763 13.1639 4.88889 9.43452 5.60235 6.00295Z"
								fill="url(#paint0_linear_0_415)" />
							<path
								d="M5.29532 19.02C6.02612 18.4664 7.53224 17.6339 8.64165 17.1707C11.4896 15.9822 15.9066 16.1205 18.8663 17.4912C19.7996 17.9234 21.0741 18.8725 22.0457 20.0792C24.2153 22.774 26.9838 27.867 25.481 29.5687C25.1002 29.9999 12.3589 29.9999 12.3589 29.9999C3.89736 29.9999 0.745252 29.886 0.425955 29.5687C-1.12714 28.0255 1.8109 21.6609 5.29532 19.02Z"
								fill="url(#paint1_linear_0_415)" />
							<defs>
								<linearGradient
									id="paint0_linear_0_415"
									x1="12.9542"
									y1="0"
									x2="12.9542"
									y2="15"
									gradientUnits="userSpaceOnUse">
									<stop stop-color="#FFAF63" />
									<stop
										offset="1"
										stop-color="#E9A61E" />
								</linearGradient>
								<linearGradient
									id="paint1_linear_0_415"
									x1="12.9545"
									y1="16.3635"
									x2="12.9545"
									y2="29.9999"
									gradientUnits="userSpaceOnUse">
									<stop stop-color="#FFAF63" />
									<stop
										offset="1"
										stop-color="#E9A61E" />
								</linearGradient>
							</defs>
						</svg>
					</div>
				{:else if !isAllowed}
					<div
						class="svg-container"
						style="background-color: #d3cfcf">
						<svg
							width="26"
							height="30"
							viewBox="0 0 26 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M5.60235 6.00295C6.0717 3.74638 8.22324 1.22553 10.3007 0.498557C13.3253 -0.559993 15.9249 0.0648161 18.1889 2.39495C20.953 5.23902 21.2027 8.89339 18.8549 12.1297C17.034 14.6391 12.9552 15.7241 9.99099 14.4877C6.81763 13.1639 4.88889 9.43452 5.60235 6.00295Z"
								fill="#808080" />
							<path
								d="M5.29532 19.02C6.02612 18.4664 7.53224 17.6339 8.64165 17.1707C11.4896 15.9822 15.9066 16.1205 18.8663 17.4912C19.7996 17.9234 21.0741 18.8725 22.0457 20.0792C24.2153 22.774 26.9838 27.867 25.481 29.5687C25.1002 29.9999 12.3589 29.9999 12.3589 29.9999C3.89736 29.9999 0.745252 29.886 0.425955 29.5687C-1.12714 28.0255 1.8109 21.6609 5.29532 19.02Z"
								fill="#808080" />
							<defs fill="#808080">
								<linearGradient
									id="paint0_linear_0_415"
									x1="12.9542"
									y1="0"
									x2="12.9542"
									y2="15"
									gradientUnits="userSpaceOnUse"
									fill="#808080">
									<stop
										stop-color="#808080"
										fill="#808080" />
									<stop
										offset="1"
										stop-color="#808080"
										fill="#808080" />
								</linearGradient>
								<linearGradient
									id="paint1_linear_0_415"
									x1="12.9545"
									y1="16.3635"
									x2="12.9545"
									y2="29.9999"
									gradientUnits="userSpaceOnUse"
									fill="#808080">
									<stop
										stop-color="#808080"
										fill="#808080" />
									<stop
										offset="1"
										stop-color="#808080"
										fill="#808080" />
								</linearGradient>
							</defs>
						</svg>
					</div>
				{/if}
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('invite')}</span>
						<span>{$_('user')}</span>
					</h1>
				</header>
			</div>
		</button>
	</div>
{/if}

<UserInviteModal
	{isOpen}
	on:closeModal={closeModal} />

<style lang="scss">
@use '../../../styles/setup/mixins';

.container {
	position: relative;
	margin: 0;
	padding: 0;
	.invite-user {
		@include mixins.card;
		aspect-ratio: 1/1;
		min-width: 160px;
		padding: 20px;

		.btn-content {
			justify-content: center;

			.svg-container {
				width: 50px;
				height: 50px;
				background-color: #ffab001f;
				border-radius: 50%;
				padding: 12px;
				margin-bottom: auto;
			}

			.title {
				text-align: start;
				color: var(--clr-txt-dark-secondary);
				padding-top: 30px;

				span {
					font-weight: 500;

					&:first-child {
						font-size: 14px;
					}

					&:last-child {
						font-size: 24px;
					}
				}
			}
		}
	}
}

.tooltip {
	visibility: hidden;
	opacity: 0;
	background: #333;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 12px;
	position: absolute;
	z-index: 1;
	bottom: 105%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	min-width: 110px;
}
.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #333 transparent transparent transparent;
}
.container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}
</style>
