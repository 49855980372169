<script lang="ts">
import NoTrainingAnimation from '../../common/animation/NoTrainingAnimation.svelte'
import { Lottie } from 'lottie-svelte'
</script>

<div class="demo-insights grid">
	<div class="demo blur" />
	<div class="no-training-animation">
		<h1 class="title">Log at least 15 sessions to get access to Insights</h1>
	</div>
</div>

<style lang="scss">
@use '../../../styles/setup/mixins';

.demo-insights {
	position: relative;
	height: 700px;
}

.demo {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.blur {
	background: url('/assets/img/insights-demo.jpg') center/contain no-repeat;
	background-size: 100%;
	position: relative;
}

.no-training-animation {
	position: absolute;
	top: 36%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 540px;

	.title {
		margin-left: 20px;
		font-size: 21px;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 6px;
		border-radius: 6px;
	}
}
</style>
