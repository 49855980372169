<svg
	width="56"
	height="56"
	viewBox="0 0 56 56"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_548_24096)">
		<rect
			width="56"
			height="56"
			rx="28"
			fill="#147CA9"
			fill-opacity="0.15" />
		<g clip-path="url(#clip1_548_24096)">
			<path
				d="M36.8961 18.8591C38.1474 18.8347 39.3567 17.1524 39.8808 14.7077L40.2468 13H42.0286C44.712 13 46.0594 13.8808 46.0594 15.6354C46.0594 16.1666 46.2992 16.2609 47.6492 16.2609C49.8903 16.2609 50.1233 16.6597 49.4493 19.3437C48.5569 22.8977 48.5084 22.9274 43.3186 23.0523L38.889 23.1592V31.212V39.2647L40.0375 39.8836C41.1578 40.4872 41.7724 41.637 41.3448 42.3292C41.2145 42.5402 39.6226 42.6739 37.24 42.6739C31.579 42.6739 32.0444 43.7347 32.0444 30.8311C32.0444 22.6633 32.1494 19.9303 32.5102 18.6843C32.7663 17.7997 33.1512 16.4158 33.3657 15.6087L33.7559 14.1413L34.1995 15.6413C34.6753 17.2499 36.038 18.8761 36.8961 18.8591Z"
				fill="url(#paint0_linear_548_24096)" />
			<path
				d="M6.31995 19.9527C5.82943 18.185 5.88223 17.6538 6.62241 16.9133C7.81562 15.7195 9.13008 16.3374 9.43321 18.2349C9.57368 19.1124 9.95371 19.7991 10.6013 20.3444C11.466 21.0722 11.8496 21.1524 14.4697 21.1524H17.378V24.1019C17.378 26.7989 17.4464 27.1201 18.1781 27.8519C18.9665 28.641 19.0594 28.6524 24.6966 28.6524H30.415V31.7303V34.8079L28.9513 35.0277C27.4452 35.2537 22.2718 34.7669 20.3928 34.2223L19.3335 33.9154V36.59V39.2649L20.4821 39.8838C21.6023 40.4874 22.217 41.6372 21.7894 42.3295C21.659 42.5404 20.0672 42.6741 17.6847 42.6741C12.1051 42.6741 12.4891 43.3804 12.4891 33.1142V24.8584L10.7284 24.3031C8.65912 23.6506 6.85121 21.8662 6.31995 19.9527Z"
				fill="url(#paint1_linear_548_24096)" />
			<path
				d="M23.2441 21.4778V20.1735V18.8691H24.7108H26.1775V20.1735V21.4778H27.4812H28.7849V22.9452V24.4126H27.4812H26.1775V25.717V27.0213H24.7108H23.2441V25.717V24.4126H21.9404H20.6367V22.9452V21.4778H21.9404H23.2441Z"
				fill="url(#paint2_linear_548_24096)" />
		</g>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_548_24096"
			x1="40.9085"
			y1="13"
			x2="40.9085"
			y2="42.6951"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<linearGradient
			id="paint1_linear_548_24096"
			x1="18.2075"
			y1="16.3027"
			x2="18.2075"
			y2="42.6845"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<linearGradient
			id="paint2_linear_548_24096"
			x1="24.7108"
			y1="18.8691"
			x2="24.7108"
			y2="27.0213"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<clipPath id="clip0_548_24096">
			<rect
				width="56"
				height="56"
				fill="white" />
		</clipPath>
		<clipPath id="clip1_548_24096">
			<rect
				width="44"
				height="30"
				fill="white"
				transform="translate(6 13)" />
		</clipPath>
	</defs>
</svg>
