<script lang="ts">
import type { TrainingModel } from '../../../models/training.model'
import RiSystemArrowRightSFill from 'svelte-icons-pack/ri/RiSystemArrowRightSFill'
import RiSystemArrowLeftSFill from 'svelte-icons-pack/ri/RiSystemArrowLeftSFill'
import Icon from 'svelte-icons-pack'
import Carousel from 'svelte-carousel'
import { utilService } from '../../../utils/util.service'
import { onMount } from 'svelte'
import BsCardImage from 'svelte-icons-pack/bs/BsCardImage'
import { _ } from 'svelte-i18n'

export let training: TrainingModel

let activeTerrain: string

onMount(() => {
	if (training.terrainMaps) activeTerrain = Object.keys(training.terrainMaps)[0]
})

function updateActiveTerrain(key: string) {
	activeTerrain = key
}

let activeTerrainIndex = 0

function handlePrevButtonClick() {
	activeTerrainIndex =
		(activeTerrainIndex - 1 + Object.keys(training.terrainMaps).length) %
		Object.keys(training.terrainMaps).length
	updateActiveTerrain(Object.keys(training.terrainMaps)[activeTerrainIndex])
}

function handleNextButtonClick() {
	activeTerrainIndex = (activeTerrainIndex + 1) % Object.keys(training.terrainMaps).length
	updateActiveTerrain(Object.keys(training.terrainMaps)[activeTerrainIndex])
}
</script>

<div class="terrain-images-container">
	<header class="header flex">
		<h1 class="title">{$_('terrain_images')}</h1>
		{#if activeTerrain}
			<div class="terrain-name flex align-center justify-center">
				{activeTerrain != 'satelliteStreets'
					? utilService.capitalizeFirstLetter(activeTerrain)
					: 'Satellite Streets'}
			</div>
		{/if}
	</header>
	{#if training.terrainMaps}
		<section class="details">
			<Carousel
				let:showPrevPage
				let:showNextPage
				dots={false}>
				<div
					slot="prev"
					on:click={() => {
						handlePrevButtonClick()
						showPrevPage()
					}}
					class="custom-arrow custom-arrow-prev"
					on:keydown={showPrevPage}>
					<i>
						<Icon
							src={RiSystemArrowLeftSFill}
							color={'white'}
							size="36" />
					</i>
				</div>
				{#if training.terrainMaps && Object.keys(training.terrainMaps)}
					{#each Object.keys(training.terrainMaps) as key (key)}
						<div
							class="terrain-image"
							on:keydown={() => {
								updateActiveTerrain(key)
							}}
							on:click={() => {
								updateActiveTerrain(key)
							}}>
							<div class="image-title flex justify-center">
								<p>
									{key != 'satelliteStreets'
										? utilService.capitalizeFirstLetter(key)
										: 'Satellite Streets'}
								</p>
							</div>
							<img
								src={training.terrainMaps[key]}
								alt="No Terrain Imagе"
								style="height: 410px; width: 100%; object-fit: cover; overflow: hidden;" />
						</div>
					{/each}
				{/if}
				<div
					slot="next"
					on:click={() => {
						handleNextButtonClick()
						showNextPage()
					}}
					class="custom-arrow custom-arrow-next"
					on:keydown={showPrevPage}>
					<i>
						<Icon
							src={RiSystemArrowRightSFill}
							color={'white'}
							size="36" />
					</i>
				</div>
			</Carousel>
		</section>
	{:else}
		<div class="no-terrain-images flex align-center justify-center">
			<Icon src={BsCardImage} />
			<p>{$_('no_terrain_images')}</p>
		</div>
	{/if}
</div>

<style lang="scss">
@use '../../../styles/setup/mixins';

.terrain-images-container {
	@include mixins.card;
	height: 450px;

	.header {
		padding: 8px 12px 8px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}

		.terrain-name {
			margin: 0 0 0 auto;
		}
	}

	.details {
		position: relative;

		.carousel-container {
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
		}

		.terrain-image {
			height: 100%;
			overflow: visible;

			img {
				position: relative;
			}

			.image-title {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 200;
				box-sizing: border-box;

				p {
					background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
					padding: 4px 18px;
					border-radius: 6px;
					color: white;
					width: fit-content;
					max-width: 100%;
					white-space: nowrap;
					overflow: visible;
					text-overflow: ellipsis;
				}
			}
		}
	}

	@media screen and (max-width: 1200px) and (min-width: 768px) {
		margin-right: -12px;
	}
}

.no-terrain-images {
	color: var(--clr-txt-dark-secondary);
	justify-self: center;
	gap: 5px;
	width: 100%;
	height: 80%;
	font-size: 17px;
	// min-height: 130px;

	p {
		margin-top: 3px;
	}
}

:global {
	.custom-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		border: none;
		font-size: 24px;
		cursor: pointer;
		width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.231);
		height: 80px;
	}

	.custom-arrow-prev {
		z-index: 1;
		top: 200px;
		left: 0;
	}

	.custom-arrow-next {
		z-index: 0;
		top: 200px;
		right: 0;
	}
}
</style>
