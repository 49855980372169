<script lang="ts">
import { useNavigate, useParams } from 'svelte-navigator'
import type { AccountUserModel } from '../../../models/user.model'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import { onMount, onDestroy } from 'svelte'
import { _ } from 'svelte-i18n'
import SelectButton from '../../common/SelectButton.svelte'
import { utilService } from '../../../utils/util.service'
import { currAccount } from '../../../store/stores'
import { userMsg } from '../../../utils/userMsg.service'
import type { Unsubscribe } from 'firebase/firestore'
import { accountService } from '../../../services/core/account.service'

const params = useParams()
const navigate = useNavigate()
const userId = $params.id

let unsubscribe: Unsubscribe

let userInAccountPromise: Promise<AccountUserModel[]>
let userInAccountOptions: AccountUserModel[]
let user: AccountUserModel

async function getUserInAccount(userId: string) {
	userInAccountPromise = accountService.queryUsers([userId], $currAccount.id)
	userInAccountOptions = await userInAccountPromise
	user = userInAccountOptions[0]
}

onMount(() => {
	getUserInAccount(userId)
})

onDestroy(() => {
	if (unsubscribe) unsubscribe()
})

async function handleSubmit(ev: Event) {
	ev.preventDefault()
	ev.stopPropagation()

	try {
		//Update in accountUsers
		await utilService.restRequest(`${import.meta.env.VITE_APP_API_URL}/accountuser/${$currAccount.id}/${user.id}`, 'PATCH', {
			type: user.type,
			Role: user.role
		})
	} finally {
		userMsg.successMsg(`${$_('user_updated')}`)
		navigate(-1)
	}
}
</script>

{#if !user}
	<CmpLoader />
{:else}
	<div class="user-edit">
		<div class="flex-column align-center form-container">
			<form
				on:submit={handleSubmit}
				class="flex-column justify-center user-edit-form">
				<header class="flex align-center form-header">
					<h1 class="title">{$_('user_information')}</h1>
				</header>
				<div class="field role">
					<label for="status">{$_('role')}</label>
					<SelectButton
						id="status"
						bind:value={user.role}
						choices={[
							{ label: $_('admin'), value: 'admin' },
							{ label: $_('member'), value: 'member' }
						]} />
				</div>
				<div class="field type">
					<label for="status">{$_('type')}</label>
					<SelectButton
						id="status"
						bind:value={user.type}
						choices={[
							{ label: $_('dog handler'), value: 'dog handler' },
							{ label: $_('trainer'), value: 'trainer' },
							{ label: $_('viewer'), value: 'viewer' }
						]} />
				</div>
				<div class="flex align-center btns-container">
					<button
						type="button"
						class="btn theme-btn outline discard"
						on:click={() => navigate(-1)}>
						{$_('cancel')}
					</button>
					<button
						type="submit"
						class="btn theme-btn submit"
						on:click={handleSubmit}>
						{$_('submit')}
					</button>
				</div>
			</form>
		</div>
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';
.user-edit {
	max-width: 800px;
	margin-inline: auto;

	.form-container {
		padding-top: 20px;
	}

	.user-edit-form {
		@include mixins.card;
		gap: 20px;
		width: 100%;
		padding: 28px 24px;

		.field {
			display: flex;
			flex-direction: column;
			gap: 6px;

			label {
				font-size: 14px;
				font-family: Nunito-SemiBold;
				color: #33383f;
			}

			.double-input {
				gap: 15px;
			}

			.edit-input {
				font-size: 16px;
				color: var(--clr-txt-dark-primary);
			}

			&.type,
			&.teams {
				align-items: baseline;
				margin-bottom: 20px;

				:global(.mdc-chip) {
					border-radius: 12px;
					background-color: var(--clr-primary);
					color: white;
					border-radius: 6px;

					:global(.mdc-chip__ripple) {
						border-radius: 6px;
					}

					:global(.mdc-chip__checkmark-svg path) {
						stroke: white;
					}
				}
			}

			&:has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			input.invalid {
				border-color: #b00020;
			}

			label[role='alert'] {
				color: #b00020;
			}
		}

		.btns-container {
			justify-content: flex-end;
			gap: 12px;
		}
	}
}
</style>
