<script lang="ts">
import { fade } from 'svelte/transition'
import { createEventDispatcher } from 'svelte'
import { _ } from 'svelte-i18n'
import NameInitials from '../../common/NameInitials.svelte'
import CategorySelector from '../CategorySelector.svelte'
import moment from 'moment'
import Icon from 'svelte-icons-pack'
import HiSolidX from 'svelte-icons-pack/hi/HiSolidX'
import FaCalendar from 'svelte-icons-pack/fa/FaCalendar'
import AiOutlineClockCircle from 'svelte-icons-pack/ai/AiOutlineClockCircle'
import { onMount } from 'svelte'
import { utilService } from '../../../utils/util.service'
import SvgIcon from '../../common/SvgIcon.svelte'
import eventIcon from './../../trainings/training/icon/shield-dog-solid.svg'
import UnsuccsessfulIcon from './../../trainings/training/icon/Smile1.svg'
import NeedsImprovementIcon from './../../trainings/training/icon/Smile2.svg'
import SuccsessfulIcon from './../../trainings/training/icon/Smile5.svg'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

export let summaryTraining
const dispatch = createEventDispatcher()

moment.locale(localStorage.getItem('language'))

var textWrapper: any
var textWrapper1: any

const trainingResults = summaryTraining.data.filter((item) => item.name == 'Results')
const resultsIcon =
	trainingResults[0].value.answer == 'Successful'
		? SuccsessfulIcon
		: trainingResults[0].value.answer == 'Needs Improvement'
		? NeedsImprovementIcon
		: UnsuccsessfulIcon

// Define the categories for the category selector and set the default category to 'Summary'
type ListCategory = 'Summary'
const categories: ListCategory[] = ['Summary']

let roundedTrainerRating = Math.round(summaryTraining.dogRating)
let roundedDogRating = Math.round(summaryTraining.trainedByRating)

let dogIcon =
	roundedDogRating >= 0 && roundedDogRating < 3
		? '../../../assets/img/segment_analysis/dog_negative.svg'
		: roundedDogRating == 3
		? '../../../assets/img/segment_analysis/dog_neutral.svg'
		: '../../../assets/img/segment_analysis/dog_positive.svg'

let trainerIcon =
	roundedTrainerRating >= 0 && roundedTrainerRating < 3
		? '../../../assets/img/segment_analysis/handler_negative.svg'
		: roundedTrainerRating == 3
		? '../../../assets/img/segment_analysis/handler_neutral.svg'
		: '../../../assets/img/segment_analysis/handler_positive.svg'

let category: ListCategory = 'Summary'

onMount(async () => {
	textWrapper = document.querySelectorAll('.ml11 .letters')
	textWrapper.forEach(
		(textWrapper) =>
			(textWrapper.innerHTML = textWrapper.textContent.replace(
				/([a-zA-Z0-9.,!?"'/`~:;<>^&*()%$#@[\]{}|\\\-=+_]+)/g,
				function (match) {
					return "<span class='letter'>" + match + '</span>'
				}
			))
	)
	textWrapper1 = document.querySelectorAll('.ml12 .letters')
	textWrapper1.forEach(
		(textWrapper) =>
			(textWrapper.innerHTML = textWrapper.textContent.replace(
				/([a-zA-Z0-9.,!?"'/`~:;<>^&*()%$#@[\]{}|\\\-=+_]+)/g,
				function (match) {
					return "<span class='letter'>" + match + '</span>'
				}
			))
	)
})

function getProperTime(date: Date) {
	return moment().startOf('day').diff(moment(date), 'days') > 11
		? moment(date).format('MMMM D[,] YYYY')
		: moment(date).fromNow()
}

function close() {
	dispatch('close')
}

function handleVote(vote) {
	// Handle the vote
	console.log(`You voted ${vote}`)
}

$: if (textWrapper) {
	// add animation to the text
	(window as any).anime
		.timeline({ loop: false })
		.add({
			targets: '.ml11 .letter',
			opacity: [0, 1],
			easing: 'easeOutExpo',
			duration: 600,
			delay: (el, i) => 70 * (i + 1)
		})
		.add({
			targets: '.ml12 .letter',
			opacity: [0, 0],
			easing: 'easeOutExpo',
			duration: 1
		})
		.add({
			targets: '.error-icon',
			opacity: [0, 1],
			easing: 'easeOutExpo',
			duration: 600,
			delay: (el, i) => 70 * (i + 1)
		})
		.add({
			targets: '.ml12 .letter',
			opacity: [0, 1],
			easing: 'easeOutExpo',
			duration: 600,
			delay: (el, i) => 70 * (i + 1)
		})
}
</script>

<div
	class="ai-summary-modal"
	in:fade={{ duration: 250 }}
	out:fade={{ duration: 250 }}>
	<div class="modal-content">
		<div class="header-image">
			<img
				class="training-icon"
				width="150px"
				height="150px"
				src={summaryTraining.iconUrl}
				alt="" />
			<button
				class="close-button"
				on:click={close}>
				<Icon src={HiSolidX} />
			</button>
		</div>
		<div class="info">
			<button
				class="close-button-inner"
				on:click={close}>
				<Icon src={HiSolidX} />
			</button>
			<header class="header">
				<h1 class="title">{$_('dogbase_summary')}</h1>
				<span class="beta-badge">Beta</span>
				<!-- <button class="btn theme-btn btn-add-team">{$_('share')}</button> -->
			</header>
			<div class="flex-row info-badge-container">
				<span class="info-badge">
					<div class="info-icon">
						<Icon src={FaCalendar} />
					</div>
					<p1>
						{#if typeof summaryTraining.dateTime == 'object'}{getProperTime(
								summaryTraining.dateTime.toDate()
							)}
						{:else}
							{getProperTime(new Date(summaryTraining.dateTime * 1000))}
						{/if}
					</p1>
				</span>
				<span class="info-badge">
					<div class="info-icon">
						<Icon src={AiOutlineClockCircle} />
					</div>
					<p1>{utilService.minutesToHoursAndMinutesStr(summaryTraining.trainingTime)} training</p1>
				</span>
			</div>
			<div class="flex-row info-wrapper">
				<span class="info-container">
					<p1>Training name</p1>
					<div class="flex-row">
						<div class="svg-container">
							<div class="training-image-container">
								<img
									width="12px"
									height="12px"
									src={summaryTraining.iconUrl}
									alt="training icon" />
							</div>
						</div>
						<h1>{summaryTraining.type}</h1>
					</div>
				</span>
				<span class="info-container">
					<p1>Dog</p1>
					<div class="flex-row">
						<div class="svg-container">
							<img
								width="12px"
								height="12px"
								src={summaryTraining.dog.image}
								alt="{'handlerimage'} icon" />
						</div>
						<h1>{summaryTraining.dog.name}</h1>
					</div>
				</span>
				<span class="info-container">
					<p1>Handler</p1>
					<div class="flex-row">
						<div class="svg-container">
							<NameInitials name={summaryTraining.trainedBy.name} />
						</div>
						<h1>{summaryTraining.handler.name}</h1>
					</div>
				</span>
				<span class="info-container">
					<p1>Team</p1>
					<div class="flex-row">
						<div class="svg-container">
							<SvgIcon
								width="30px"
								name={summaryTraining.team.icon} />
						</div>
						<h1>{summaryTraining.team.name}</h1>
					</div>
				</span>
				<span class="info-container">
					<p1>Event</p1>
					<div class="flex-row event">
						<h1>{utilService.capitalizeFirstLetter(summaryTraining.event)}</h1>
					</div>
				</span>
			</div>
			<div class="flex-row">
				<div class="category-selector">
					<CategorySelector
						activeCategory={category}
						{categories}
						on:select={(ev) => (category = ev.detail)} />
				</div>
			</div>
			{#if category == 'Summary'}
				<section class="text-container">
					<span class="ml11">
						<span class="text-wrapper">
							<span
								class="summary-text letters"
								id="letters">{summaryTraining.summary}</span
							></span>
					</span>
					<span class="ml12 observations">
						<span class="text-wrapper">
							<h1
								class="letters"
								id="letters">
								AI Recommendations
							</h1>
						</span>
						<span class="ai-recommendations letters"
							>(based on observations, objectives, ATBT, notes)</span>
					</span>
					<span class="ml12">
						<span class="text-wrapper">
							<div class="flex-row">
								{#if summaryTraining.actionsToBeTaken.includes('cannot generate ATBT')}
									<div class="error-icon">
										<img
											src="../../../assets/exclamation-solid.svg"
											alt="" />
									</div>
								{/if}
								<span
									class="summary-text letters"
									id="letters">{summaryTraining.actionsToBeTaken}</span>
							</div>
						</span>
					</span>
				</section>
				<!-- <div class="flex-row summary-rating">
				<button
					class="thumb-button"
					on:click={() => handleVote('up')}>
					<Icon
						src={FaThumbsUp}
						size="25" />
				</button>
				<button
					class="thumb-button"
					on:click={() => handleVote('down')}>
					<Icon
						src={FaThumbsDown}
						size="25" />
				</button>
			</div> -->
				<div class="flex-row sentiment-ratings">
					<div class="flex-row sentiment-rating-container">
						<div class="svg-container">
							<img
								width="48px"
								height="48px"
								src={dogIcon}
								alt="emoji icon" />
						</div>
						<span class="flex-column">
							<h1 class="title">Dog Rating</h1>
							<h2 class="value">{summaryTraining.dogRating} out of 5</h2>
						</span>
					</div>
					<div class="flex-row sentiment-rating-container">
						<div class="svg-container">
							<img
								width="48px"
								height="48px"
								src={trainerIcon}
								alt="{'trainerSentiment'} icon" />
						</div>
						<span class="flex-column">
							<h1 class="title">Trainer Rating</h1>
							<h2 class="value">{summaryTraining.trainedByRating} out of 5</h2>
						</span>
					</div>
					<div class="flex-row sentiment-rating-container">
						<div class="svg-container">
							<img
								width="48px"
								height="48px"
								src={resultsIcon}
								alt="emoji icon" />
						</div>
						<span class="flex-column">
							<h1 class="title">Results</h1>
							<h2 class="value">{trainingResults[0].value.answer}</h2>
						</span>
					</div>
					<div class="flex-row sentiment-rating-container">
						<div class="svg-container">
							<img
								width="48px"
								height="48px"
								src={eventIcon}
								alt="emoji icon" />
						</div>
						<span class="flex-column">
							<h1 class="title">Event</h1>
							<h2 class="value">{utilService.capitalizeFirstLetter(summaryTraining.event)}</h2>
						</span>
					</div>
				</div>
				<!-- Commented out until backend fixes -->
				<!-- {:else if category == 'Weather'}
				<div class="weather-container flex-column">
					{#if summaryTraining.weather && summaryTraining.location && summaryTraining.airQuality}
						<span>
							<TemperatureComponent
								weather={summaryTraining.weather}
								location={summaryTraining.location} />
						</span>
						<span class="wind-and-stats">
							<WindComponent weather={summaryTraining.weather} />
							<WeatherStatsComponent
								weather={summaryTraining.weather}
								airQuality={summaryTraining.airQuality} />
						</span>
					{:else}
						<section class="no-weather flex align-center justify-center">
							<div class="icon">
								<Icon src={TiWeatherCloudy} />
							</div>
							<h1 class="title">
								{$_('no_weather_data')}
							</h1>
						</section>
					{/if}
				</div> -->
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
.info-badge-container {
	gap: 1.8rem;
}

.training-image-container {
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1000px;
	aspect-ratio: 1/1;

	img {
		filter: none;
		width: 27px;
		height: 27px;
	}
}
.text-container {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 200px;

	.ml11 {
		.text-wrapper {
			position: relative;
			display: inline-block;
			padding-top: 0.1em;
			padding-right: 0.05em;
			padding-bottom: 0.15em;
		}
	}

	.ml12 {
		.ai-recommendations {
			font-style: italic;
			font-size: 12px;
		}
		.text-wrapper {
			position: relative;
			display: inline-block;
			padding-top: 0.1em;
			padding-right: 0.05em;
			padding-bottom: 0.15em;
			h1 {
				padding-top: 25px;
			}
			.error-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px; /* Adjust as needed */
				height: 50px; /* Adjust as needed */
			}
			.error-icon img {
				width: 50px;
				height: 50px;
			}
		}
	}
}

.observations {
	font-size: 1.1rem;
	font-weight: 700;
}

.ai-summary-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(
		--gradient-2,
		linear-gradient(47deg, rgba(38, 74, 89, 0.75) 0%, rgba(45, 115, 146, 0.75) 100%)
	);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;

	.modal-content {
		background: white; /* Semi-transparent grey */
		max-width: 950px;
		max-height: 95%;
		border-radius: 8px;

		.header-image {
			position: relative;
			min-height: 100px;
			background: #ffa470;
			background-size: cover;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;

			.training-icon {
				width: 100px;
				height: 100px;
				padding: 2.75rem 0 1.75rem 0;
				margin: 0 auto;
				height: 100%;
			}

			.close-button {
				position: absolute;
				right: 10px;
				top: 10px;
				background: none;
				border: none;
				font-size: 1.5em;
				cursor: pointer;
				&:hover {
					filter: brightness(1.2);
				}
			}
		}

		.info {
			.close-button-inner {
				display: none;
			}

			padding: 1.5em;

			> * {
				/* Selects all direct children of .info */
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.header {
				display: flex;
				// justify-content: space-between;
				align-items: center;
				background: white; /* Solid background */
				color: var(--clr-txt-dark-primary);
				h1 {
					font-size: 25px;
				}

				.beta-badge {
					display: inline-block;
					margin-left: 10px;
					margin-right: 10px;
					padding: 4px 12px;
					font-size: 1rem;
					border: 1px solid rgba(0, 0, 0, 0.1);
					border-radius: 4px;
				}
			}

			.svg-container {
				width: 24px;
				height: 24px;
				margin-right: 5px;
				border-radius: 1000px;
				overflow: hidden;

				img {
					filter: none;
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			.info-badge {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 30px;
				font-size: 100%;

				.info-icon {
					margin-right: 5px;
				}
			}

			.info-wrapper {
				align-items: start;

				.event {
					margin-top: 0.5rem;
				}
				.info-container {
					display: flex;
					flex-direction: column;
					margin-right: 40px;
					font-size: 75%;

					.flex-row {
						align-items: center;

						.svg-container {
							width: 30px;
							height: 30px;
						}
					}
				}
			}

			.category-selector {
				margin-top: 20px;
				margin-bottom: 20px;
			}

			.sentiment-ratings {
				margin-top: 30px;
				padding-top: 15px;
				border-top: 1px solid #000; /* Change color as needed */
				justify-content: space-between;
				flex-wrap: wrap;
				max-height: 80px;
				overflow-y: auto;

				.sentiment-rating-container {
					margin-right: 48px;
					margin-top: 14px;
					align-items: center;

					.svg-container {
						width: 48px;
						height: 48px;
						margin-right: 0.5rem;
					}

					.title {
						font-size: 14px;
						font-family: Nunito;
						font-weight: normal;
						text-transform: capitalize;
					}

					.value {
						font-size: 18px;
						font-weight: 500;
						white-space: nowrap;
					}
				}
			}
		}
	}
}

@media screen and (max-height: 750px) {
	.header-image {
		display: none;
	}

	.close-button-inner {
		display: block !important;
	}
}

@media screen and (max-width: 1100px) {
	.modal-content {
		margin: 2.5rem;
		max-height: 95%;
	}
}

@media screen and (max-width: 600px) {
	.info-wrapper {
		flex-wrap: wrap;
	}

	.info-container {
		margin-top: 0.5rem;
	}
	.observations {
		font-size: 1rem;
		font-weight: 700;
	}
	h1 {
		font-size: 12px;
	}

	.text-container {
		max-height: 150px;

		.text-wrapper {
			font-size: 0.9rem;
		}
	}

	.sentiment-ratings {
		margin-top: 0 !important;
		flex-direction: column;
		height: 90px;
		overflow-y: scroll;
	}

	.sentiment-rating-container {
		.value {
			font-size: 14px !important;
			font-weight: 500;
			white-space: nowrap;
		}
		.svg-container {
			width: 48px !important;
		}
	}
}
</style>
