<script lang="ts">
import { onSnapshot, type Unsubscribe } from 'firebase/firestore'
import { onDestroy, onMount } from 'svelte'
import { useParams } from 'svelte-navigator'
import { teamDB } from '../../services/database/team.db'
import type { TeamModel } from '../../models/team.model'
import type { TeamUserModel } from '../../models/user.model'
import type { TrainingModel } from '../../models/training.model'
import HandlerList from './team/HandlerList.svelte'
import type { DogModel } from '../../models/dog.model'
import { trainingService } from '../../services/core/training.service'
import DogList from './team/DogList.svelte'
import TrainingList from './team/TrainingList.svelte'
import { currAccount, loggedInUser } from '../../store/stores'
import CmpLoader from '../common/loader/CmpLoader.svelte'
import CategorySelector from '../common/CategorySelector.svelte'
import TeamInfo from './TeamInfo.svelte'
import EditTeam from '../common/quick-actions/EditTeam.svelte'
import LoadMoreBtn from '../common/LoadMoreBtn.svelte'
import TeamQuickInsights from './team/TeamQuickInsights.svelte'
import { teamService } from '../../services/core/team.service'
import { accountService } from '../../services/core/account.service'
import { _ } from 'svelte-i18n'
import { activityService } from '../../services/core/activity.service'
import type { ActivityModel } from '../../models/activity.model'
import ActivitiesList from './team/ActivitiesList.svelte'
import UserInviteButton from '../users/user/UserInviteButton.svelte'
import UserAddButton from './team/UserAddButton.svelte'
import { utilService } from '../../utils/util.service'

let usersPerPage = 10
let usersOnPage = 0
let activitiesPerPage = 10
let activitiesOnPage = 0
let activitiesListLength = 10
let teamListLength = 10
let dogsPerPage = 10
let dogsOnPage = 0
let dogListLength = 10
let trainingsPerPage = 10
let trainingsOnPage = 0
let trainingListLength = 10

const params = useParams()
const teamId = $params.id

let rolesAndTypes: { id: string; role: string; type: string }[]
let isAllowed = false
let accountUsersPromise
let accountUsers
let sessionKeys: any
let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

type ListCategory = 'users' | 'dogs' | 'trainings' | 'activities'
const categories: ListCategory[] = ['users', 'dogs', 'trainings', 'activities']

let unsubscribe: Unsubscribe
let team: TeamModel
let teamHandlers: TeamUserModel[] = []
let teamActivities: ActivityModel[] = []
let teamDogs: DogModel[] = []
let teamTrainings: TrainingModel[] = []
let category: ListCategory = 'users'

$: loadPage(category)

onDestroy(() => {
	if (unsubscribe) unsubscribe()
})

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	loadRolesAndTypes()
	isAllowed = await checkIfAllowed()
})

async function checkIfAllowed() {
	accountUsersPromise = accountService.queryUsers($currAccount.userIds, $currAccount.id)
	accountUsers = await accountUsersPromise
	if (accountUsers.find((user: TeamUserModel) => user.id === $loggedInUser.id).role == 'member') {
		return false
	}
	return true
}

async function loadPage(category: ListCategory = 'users') {
	if (unsubscribe) unsubscribe()
	unsubscribe = onSnapshot(teamDB.getTeamRef(teamId), async (doc) => {
		team = doc.data() as TeamModel

		switch (category) {
			case 'users':
				loadTeamUsers(team.userIds)
				break
			case 'dogs':
				loadTeamDogs(team.dogIds)
				break
			case 'trainings':
				loadTeamTrainings(teamId)
				break
			case 'activities':
				loadTeamActivities(teamId)
				break
			default:
				break
		}
	})
}

async function loadTeamUsers(userIds: string[]) {
	activitiesOnPage = 0
	dogsOnPage = 0
	trainingsOnPage = 0
	usersOnPage += usersPerPage
	if (!userIds || userIds.length === 0) return (teamHandlers = null)
	teamHandlers = await teamService.queryUsers($currAccount.id, team.id, usersOnPage)
	teamListLength = team.userIds.length
}

async function loadTeamActivities(teamId: string) {
	usersOnPage = 0
	dogsOnPage = 0
	trainingsOnPage = 0
	activitiesOnPage += activitiesPerPage
	if (!teamId) return (teamActivities = null)
	teamActivities = await activityService.queryAccountTeamActivities(
		$currAccount.id,
		teamId,
		activitiesOnPage
	)
	activitiesListLength = teamActivities.length
}

async function loadTeamDogs(dogIds: string[]) {
	activitiesOnPage = 0
	usersOnPage = 0
	trainingsOnPage = 0
	dogsOnPage += dogsPerPage
	if (!dogIds || dogIds.length === 0) return (teamDogs = null)
	teamDogs = await teamService.queryTeamDogs($loggedInUser.selectedAccount.id, team.id, dogsOnPage)
	dogListLength = team.dogIds.length
}

async function loadTeamTrainings(teamId: string) {
	activitiesOnPage = 0
	usersOnPage = 0
	dogsOnPage = 0
	trainingsOnPage += trainingsPerPage
	if (!teamId) return (teamTrainings = null)
	teamTrainings = await trainingService.queryTeamTrainings(
		$loggedInUser.selectedAccount.id,
		teamId,
		trainingsOnPage
	)
	trainingListLength = teamTrainings.length
}

async function getAccountUsersRolesAndTypes() {
	const accountUsers = await accountService.queryUsers($currAccount.userIds, $currAccount.id)
	const usersRolesAndTypes = accountUsers.map((accountUser) => {
		return {
			id: accountUser.id,
			role: accountUser.role,
			type: accountUser.type
		}
	})
	return usersRolesAndTypes
}

async function loadRolesAndTypes() {
	rolesAndTypes = await getAccountUsersRolesAndTypes()
}
</script>

{#if !team}
	<CmpLoader />
{:else}
	<div class="team-details">
		<header class="flex page-header">
			<div class="team-info-container">
				<TeamInfo {team} />
			</div>
			<EditTeam
				{team}
				{sessionKeys} />
		</header>
		<div class="quick-insights-container">
			<TeamQuickInsights {team} />
		</div>
		<div class="flex-row space-between invite-category">
			<div class="category-selector">
				<CategorySelector
					activeCategory={category}
					{categories}
					on:select={(ev) => (category = ev.detail)} />
			</div>
			<div class="btns-container flex">
				{#await accountUsersPromise then}
					{#if isAllowed}
						<UserAddButton
							{accountUsers}
							{teamHandlers}
							teamId={team.id} />
						<UserInviteButton
							teamSelected={true}
							team={team.id} />
					{/if}
				{/await}
			</div>
		</div>
		<div class="list-container">
			{#if category === 'users'}
				<HandlerList
					{disabled}
					{teamHandlers}
					{team}
					{rolesAndTypes} />
			{/if}
			{#if category === 'dogs'}
				<DogList {teamDogs} />
			{/if}
			{#if category === 'trainings'}
				<TrainingList
					trainings={teamTrainings}
					{disabled} />
			{/if}
			{#if category === 'activities'}
				{#if activitiesOnPage == 0}
					<div class="preloader">
						<CmpLoader />
					</div>
				{:else}
					<ActivitiesList {teamActivities} />
				{/if}
			{/if}
		</div>
	</div>
{/if}
{#if category === 'users' && teamHandlers && teamHandlers.length > 0}
	<LoadMoreBtn
		loadFunc={() => loadPage('users')}
		itemsOnPage={usersOnPage}
		listLength={teamListLength} />
{/if}

{#if category === 'dogs' && teamDogs && teamDogs.length > 0}
	<LoadMoreBtn
		loadFunc={() => loadPage('dogs')}
		itemsOnPage={dogsOnPage}
		listLength={dogListLength} />
{/if}

{#if category === 'trainings' && teamTrainings.length > 0}
	<LoadMoreBtn
		loadFunc={() => loadPage('trainings')}
		itemsOnPage={trainingsOnPage}
		listLength={trainingListLength} />
{/if}

{#if category === 'activities' && teamActivities && teamActivities.length > 0 && activitiesOnPage != 0}
	<LoadMoreBtn
		loadFunc={() => loadPage('activities')}
		itemsOnPage={activitiesOnPage}
		listLength={activitiesListLength} />
{/if}

<style lang="scss">
.team-details {
	.page-header,
	.quick-insights-container {
		margin-bottom: 24px;
	}

	.page-header {
		gap: 18px;

		.team-info-container {
			flex: 1;
		}
	}

	.invite-category {
		align-items: flex-start;
		white-space: nowrap;
	}
	.category-selector {
		width: fit-content;
		margin-bottom: 22px;
	}

	.list-container {
		display: grid; // For table scroll overflow
		margin-bottom: 20px;

		.preloader {
			height: 200px;
		}
	}

	.btns-container {
		gap: 4px;
	}

	@media screen and (max-width: 768px) {
		.page-header {
			flex-direction: column;

			:global(.edit-team) {
				max-height: 170px;
			}
		}

		.invite-category {
			flex-direction: column-reverse !important;
			gap: 12px;

			.category-selector {
				width: 100%;
			}
		}
	}
}
</style>
