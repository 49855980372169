<script lang="ts">
import { afterUpdate } from 'svelte'

export let choices: { label: string; value: string | boolean }[]
export let value: string | boolean
let selector: HTMLSpanElement

let refs: HTMLButtonElement[] = []
let selectedIdx: number = choices.findIndex((choice) => choice.value === value)

afterUpdate(() => {
	if (value != undefined) {
		if (selectedIdx === -1) return
		const activeCategoryBtn = refs[selectedIdx] as HTMLSpanElement
		selector.style.transform = `translateX(${activeCategoryBtn.offsetLeft}px)`
		selector.style.width = `${activeCategoryBtn.clientWidth}px`
		selector.style.height = `${activeCategoryBtn.clientHeight}px`
		selector.style.transition = 'transform 0.3s ease-out, width 0.2s 0.1s'
		selector.style.display = 'block'
	} else selector.style.display = 'none'
})

function onSelect(idx: number, newValue: string | boolean) {
	selectedIdx = idx
	value = newValue
}
</script>

<div
	class="select-button"
	id={$$props.id}>
	<div class="flex align-center btn-group">
		<span
			class="selector"
			bind:this={selector} />
		{#each choices as choice, i}
			<button
				type="button"
				class="btn category {choice.value}"
				bind:this={refs[i]}
				on:click={() => onSelect(i, choice.value)}>
				<span
					class="label"
					class:active={choice.value === value}>{choice.label}</span>
			</button>
		{/each}
	</div>
</div>

<style lang="scss">
.select-button {
	width: fit-content;

	.btn-group {
		position: relative;
		padding: 4px 4px 4px 7px;
		gap: 6px;
		border-radius: 6px;
		background-color: #e6e8f0;

		.selector {
			position: absolute;
			left: 0;
			width: 70px;
			height: 28px;
			transform: translateX(5px);
			z-index: 1;
			border-radius: 4px;
			background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		}

		.category {
			position: relative;
			padding: 2px 14px;

			.label {
				position: relative;
				z-index: 2;
				text-transform: capitalize;
				font-family: Nunito-SemiBold;
				font-size: 14px;
				color: #5e5873;
				transition: color 0.2s;
				outline: none;

				&.active {
					color: white;
				}
			}
			border: 1px solid transparent;
		}

		@media screen and (max-width: 768px) {
			.category {
				padding: 2px 6px;

				.label {
					font-size: 12px;
				}
			}
		}
	}
}
</style>
