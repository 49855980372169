<script lang="ts">
import Toggle from './Toggle.svelte'
import DistanceSlider from './DistanceSlider.svelte'
import { toggleDistanceSlider } from '../../../store/trainingWidgetsStore'

export let values: {
	minMeters: number
	maxMeters: number
	minFeet: number
	maxFeet: number
	labels: string[]
	selectedIndex: number
	trigger: number
	divisions: number
	value: {
		selectedIndex: number
		labels: string[]
		answer: string
		distanceSlider: {
			distance: number
			label: string | 'meters' | 'feet'
		}
	}
}
export let data = []
export let id: string = null

let toggleValues: any
let distanceValues: any

if (!$toggleDistanceSlider[id]) {
	$toggleDistanceSlider[id] = {
		minMeters: values.minMeters,
		maxMeters: values.maxMeters,
		minFeet: values.minFeet,
		maxFeet: values.maxFeet,
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		trigger: values.trigger,
		divisions: values.divisions,
		value: {
			selectedIndex: 0,
			labels: [],
			answer: '',
			distanceSlider: {
				distance: 0,
				label: 'meters'
			}
		}
	}
}
let unitsOfMeasurement: string
localStorage.getItem('measurement-type')
	? (unitsOfMeasurement = localStorage.getItem('measurement-type'))
	: (unitsOfMeasurement = 'metric')

if (data.length > 0 && $toggleDistanceSlider[id].value.answer == '') {
	distanceValues = {
		minMeters: values.minMeters,
		maxMeters: values.maxMeters,
		minFeet: values.minFeet,
		maxFeet: values.maxFeet,
		divisions: values.divisions,
		defaultSliderValue: data[0].value.distanceSlider ? data[0].value.distanceSlider.distance : 0,
		value: {
			distance: data[0].value.distanceSlider ? data[0].value.distanceSlider.distance : 0,
			label: data[0].value.distanceSlider
				? data[0].value.distanceSlider.label
				: unitsOfMeasurement === 'metric'
				? 'meters'
				: 'feet'
		}
	}
} else if ($toggleDistanceSlider[id].value.distanceSlider) {
	distanceValues = {
		minMeters: $toggleDistanceSlider[id].minMeters,
		maxMeters: $toggleDistanceSlider[id].maxMeters,
		minFeet: $toggleDistanceSlider[id].minFeet,
		maxFeet: $toggleDistanceSlider[id].maxFeet,
		divisions: $toggleDistanceSlider[id].divisions,
		defaultSliderValue: $toggleDistanceSlider[id].value.distanceSlider.distance,
		value: {
			distance: $toggleDistanceSlider[id].value.distanceSlider.distance,
			label: unitsOfMeasurement === 'metric' ? 'meters' : 'feet'
		}
	}
} else {
	distanceValues = {
		minMeters: values.minMeters,
		maxMeters: values.maxMeters,
		minFeet: values.minFeet,
		maxFeet: values.maxFeet,
		divisions: values.divisions,
		defaultSliderValue: 0,
		value: {
			distance: 0,
			label: unitsOfMeasurement === 'metric' ? 'meters' : 'feet'
		}
	}
}

if (data.length > 0 && $toggleDistanceSlider[id].value.answer == '') {
	toggleValues = {
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
} else if ($toggleDistanceSlider[id]) {
	toggleValues = {
		labels: values.labels,
		selectedIndex: $toggleDistanceSlider[id].value.selectedIndex,
		value: $toggleDistanceSlider[id].value.selectedIndex
	}
} else {
	toggleValues = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
}

if (data.length > 0 && $toggleDistanceSlider[id].value.answer == '') {
	values.value = {
		labels: values.labels,
		answer: '',
		selectedIndex: data[0].value.selectedIndex,
		distanceSlider: {
			distance: distanceValues.value.distance,
			label: distanceValues.value.label
		}
	}
} else if ($toggleDistanceSlider[id]) {
	values.value = {
		labels: values.labels,
		answer: '',
		selectedIndex: $toggleDistanceSlider[id].value.selectedIndex,
		distanceSlider: {
			distance: distanceValues.value.distance,
			label: distanceValues.value.label
		}
	}
}

$: if (toggleValues.value.selectedIndex === values.trigger) {
	values.value.selectedIndex = toggleValues.value.selectedIndex
	values.selectedIndex = toggleValues.value.selectedIndex
	values.value.answer = values.labels[toggleValues.value.selectedIndex]
	values.value.distanceSlider = distanceValues.value
	$toggleDistanceSlider[id] = values
} else {
	values.value.selectedIndex = toggleValues.value.selectedIndex
	values.selectedIndex = toggleValues.value.selectedIndex
	values.value.answer = values.labels[toggleValues.value.selectedIndex]
	values.value.distanceSlider = null
	$toggleDistanceSlider[id] = values
}
</script>

<Toggle bind:values={toggleValues} />

{#if values.value.selectedIndex === values.trigger}
	<DistanceSlider bind:values={distanceValues} />
{/if}
