<!-- RangePicker.svelte -->
<script lang="ts">
import { onMount } from 'svelte'
import { utilService } from '../../../utils/util.service'
import { rangeSlider } from '../../../store/trainingWidgetsStore'
import { _ } from 'svelte-i18n'

export let values: {
	min: number
	max: number
	start: number
	end: number
	label: string
	divisions: number
	value: {
		selectedRange: number[]
		label: string
	}
}
export let data = []
export let id: string

let rangeValues: [number, number]
let firstDraggableCircle: any
let secondDraggableCircle: any
let rangeBar: any
let rangeWidth = 0
let firstDraggableCirclePosition = 0
let secondDraggableCirclePosition = 0
let draggingCircle = null

if ($rangeSlider[id]) {
	rangeValues = $rangeSlider[id].value.selectedRange
} else if (data.length > 0) {
	rangeValues = data[0].value.selectedRange
} else {
	rangeValues = [values.min, values.max]
}

values.value = {
	selectedRange: [values.min, values.max],
	label: values.label
}

function updateCirclePositions() {
	if ($rangeSlider[id].value.selectedRange[1] == 0) {
		firstDraggableCirclePosition = calculatePosition(values.start)
		secondDraggableCirclePosition = calculatePosition(values.end)
	} else {
		firstDraggableCirclePosition = calculatePosition($rangeSlider[id].value.selectedRange[0])
		secondDraggableCirclePosition = calculatePosition($rangeSlider[id].value.selectedRange[1])
	}
}

if (!$rangeSlider[id]) {
	$rangeSlider[id] = {
		min: values.min,
		max: values.max,
		start: values.start,
		end: values.end,
		label: values.label,
		divisions: values.divisions,
		value: {
			selectedRange: [values.start, values.end],
			label: values.label
		}
	}
}

function calculatePosition(val: number) {
	const range = values.max - values.min
	return ((val - values.min) / range) * rangeWidth
}

function calculateValue(pos: number) {
	const range = values.max - values.min
	return Math.round((pos / rangeWidth) * range + values.min)
}

function handleStart(event: any, circle: any) {
	event.preventDefault()

	const isTouchEvent = event.changedTouches && event.changedTouches.length > 0
	const clientX = isTouchEvent ? event.changedTouches[0].clientX : event.clientX

	let currentCircle: any
	let currentPosition: any

	if (circle === 'firstDraggableCircle') {
		currentCircle = firstDraggableCircle
		currentPosition =
			firstDraggableCircle.getBoundingClientRect().left - rangeBar.getBoundingClientRect().left
	} else if (circle === 'secondDraggableCircle') {
		currentCircle = secondDraggableCircle
		currentPosition =
			secondDraggableCircle.getBoundingClientRect().left - rangeBar.getBoundingClientRect().left
	}

	draggingCircle = currentCircle

	if (isTouchEvent) {
		window.addEventListener('touchmove', handleMove)
		window.addEventListener('touchend', handleEnd)
	} else {
		window.addEventListener('mousemove', handleMove)
		window.addEventListener('mouseup', handleEnd)
	}
}

function handleMove(event) {
	const isTouchEvent = event.changedTouches && event.changedTouches.length > 0
	const clientX = isTouchEvent ? event.changedTouches[0].clientX : event.clientX

	if (draggingCircle) {
		const rect = rangeBar.getBoundingClientRect()
		let snappedPosition

		if (draggingCircle === firstDraggableCircle) {
			const maxPosition =
				calculatePosition(calculateValue(secondDraggableCirclePosition)) -
				draggingCircle.offsetWidth

			const x = clientX - rect.left
			const newPosition = Math.min(Math.max(x, 0), rangeWidth)

			const stepSize = values.max / values.divisions
			snappedPosition = Math.min(newPosition, maxPosition)
			snappedPosition = Math.round(snappedPosition / stepSize) * stepSize

			draggingCircle.style.left = `${snappedPosition}px`
			firstDraggableCirclePosition = snappedPosition
		} else if (draggingCircle === secondDraggableCircle) {
			const minPosition =
				calculatePosition(calculateValue(firstDraggableCirclePosition)) + draggingCircle.offsetWidth

			const x = clientX - rect.left
			const newPosition = Math.min(Math.max(x, 0), rangeWidth)

			const stepSize = values.max / values.divisions
			snappedPosition = Math.max(newPosition, minPosition)
			snappedPosition = Math.round(snappedPosition / stepSize) * stepSize

			draggingCircle.style.left = `${snappedPosition}px`
			secondDraggableCirclePosition = snappedPosition
		}

		rangeBar.querySelector('.range').style.left = `${Math.min(
			firstDraggableCirclePosition,
			secondDraggableCirclePosition
		)}px`

		rangeBar.querySelector('.range').style.width = `${Math.abs(
			firstDraggableCirclePosition - secondDraggableCirclePosition
		)}px`

		rangeValues = [
			calculateValue(Math.min(firstDraggableCirclePosition, secondDraggableCirclePosition)),
			calculateValue(Math.max(firstDraggableCirclePosition, secondDraggableCirclePosition))
		]
	}
}

function handleEnd() {
	draggingCircle = null
	window.removeEventListener('mousemove', handleMove)
	window.removeEventListener('mouseup', handleEnd)
	window.removeEventListener('touchmove', handleMove)
	window.removeEventListener('touchend', handleEnd)
}

function handleMouseDown(event: Event, circle: any) {
	event.preventDefault()

	let currentCircle
	let currentPosition

	if (circle === 'firstDraggableCircle') {
		currentCircle = firstDraggableCircle
		currentPosition =
			firstDraggableCircle.getBoundingClientRect().left - rangeBar.getBoundingClientRect().left
	} else if (circle === 'secondDraggableCircle') {
		currentCircle = secondDraggableCircle
		currentPosition =
			secondDraggableCircle.getBoundingClientRect().left - rangeBar.getBoundingClientRect().left
	}

	draggingCircle = currentCircle
	const circleRadius = draggingCircle.offsetWidth / 2 - 1

	function handleMouseMove(event) {
		if (draggingCircle) {
			const rect = rangeBar.getBoundingClientRect()
			let snappedPosition
			if (draggingCircle === firstDraggableCircle) {
				const maxPosition =
					calculatePosition(calculateValue(secondDraggableCirclePosition)) -
					draggingCircle.offsetWidth

				const x = event.clientX - rect.left
				const newPosition = Math.min(Math.max(x, 0), rangeWidth)

				// Calculate the snapped position based on the step size
				const stepSize = values.max / values.divisions
				snappedPosition = Math.min(newPosition, maxPosition)
				snappedPosition = Math.round(snappedPosition / stepSize) * stepSize

				draggingCircle.style.left = `${snappedPosition}px`
				firstDraggableCirclePosition = snappedPosition
			} else if (draggingCircle === secondDraggableCircle) {
				const minPosition =
					calculatePosition(calculateValue(firstDraggableCirclePosition)) +
					draggingCircle.offsetWidth

				const x = event.clientX - rect.left
				const newPosition = Math.min(Math.max(x, 0), rangeWidth)

				// Calculate the snapped position based on the step size
				const stepSize = values.max / values.divisions
				snappedPosition = Math.max(newPosition, minPosition)
				snappedPosition = Math.round(snappedPosition / stepSize) * stepSize

				draggingCircle.style.left = `${snappedPosition}px`
				secondDraggableCirclePosition = snappedPosition
			}

			rangeBar.querySelector('.range').style.left = `${Math.min(
				firstDraggableCirclePosition,
				secondDraggableCirclePosition
			)}px`
			rangeBar.querySelector('.range').style.width = `${Math.abs(
				firstDraggableCirclePosition - secondDraggableCirclePosition
			)}px`

			rangeValues = [
				calculateValue(Math.min(firstDraggableCirclePosition, secondDraggableCirclePosition)),
				calculateValue(Math.max(firstDraggableCirclePosition, secondDraggableCirclePosition))
			]
		}
	}

	const handleMouseUp = () => {
		draggingCircle = null
		window.removeEventListener('mousemove', handleMouseMove)
		window.removeEventListener('mouseup', handleMouseUp)
	}

	window.addEventListener('mousemove', handleMouseMove)
	window.addEventListener('mouseup', handleMouseUp)
}

onMount(() => {
	values.value.selectedRange = $rangeSlider[id].value.selectedRange
	rangeWidth = rangeBar.offsetWidth
	updateCirclePositions()
})

$: {
	values.value.selectedRange = [...rangeValues]
	$rangeSlider[id] = values
}

let rangeBarWidth
</script>

<div class="range-picker">
	<div
		class="range-bar"
		bind:this={rangeBar}
		bind:clientWidth={rangeBarWidth}>
		<div
			class="range"
			style="left: {firstDraggableCirclePosition}px; width: {secondDraggableCirclePosition -
				firstDraggableCirclePosition}px;" />
		<div
			class="filled"
			style={`left: ${firstDraggableCirclePosition}px; width:${
				secondDraggableCirclePosition - firstDraggableCirclePosition
			}px`} />
		<div
			class="circle firstDraggableCircle"
			style="left: {firstDraggableCirclePosition}px;"
			bind:this={firstDraggableCircle}
			on:mousedown={(event) => handleMouseDown(event, 'firstDraggableCircle')}
			on:touchstart={(event) => handleStart(event, 'firstDraggableCircle')}>
			<div class="current-value flex justify-center">
				<p>{values.value.selectedRange[0]} {$_(utilService.getShortLabel(values.label))}</p>
			</div>
		</div>
		<div
			class="circle secondDraggableCircle"
			style="left: {secondDraggableCirclePosition}px;"
			bind:this={secondDraggableCircle}
			on:mousedown={(event) => handleMouseDown(event, 'secondDraggableCircle')}
			on:touchstart={(event) => handleStart(event, 'secondDraggableCircle')}>
			<div class="current-value flex justify-center">
				<p>
					{values.value.selectedRange[1] != 0 ? values.value.selectedRange[1] : values.max}
					{$_(utilService.getShortLabel(values.label))}
				</p>
			</div>
		</div>
	</div>
</div>

<style lang="scss">
.range-picker {
	position: relative;
	width: 600px;
	margin: 30px 0;

	@media (max-width: 768px) {
		width: 100%;
	}
}
.range-bar {
	position: relative;
	width: 600px;
	height: 2.5px;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;

	@media (max-width: 768px) {
		width: 100%;
	}

	.range {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 4px;
		z-index: -1;
	}

	.filled {
		position: absolute;
		overflow: hidden;
		background: var(--dark-blue-gradient, linear-gradient(276deg, #1f74a8 0%, #264654 100%));
		height: 2.5px;
	}
}

.circle {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	margin-top: -0.5px;

	.current-value {
		margin: 22px 0 0 -17px;
		width: 50px;

		p {
			background: #fff;
			border: 1px solid rgba(0, 0, 0, 0.12);
			border-radius: 6px;
			padding: 2px 5px;
			white-space: nowrap;
			width: fit-content;
			font-size: 12px;
			font-family: Montserrat-Medium;
			text-align: center;
		}
	}
}

.circle:active {
	cursor: grabbing;
	outline: 10px solid rgba(45, 115, 146, 0.3);
	outline-offset: -1px;
}

.secondDraggableCircle {
	margin-left: -15px;
}
</style>
