<script lang="ts">
import { _ } from 'svelte-i18n'
import SelectDog from '../../trainings/training/SelectDog.svelte'
import { loggedInUser } from '../../../store/stores'
import { dogService } from '../../../services/core/dog.service'
import { compareCategory, compareStore } from './compareStore'
import { onMount } from 'svelte'
import { useNavigate } from 'svelte-navigator'
import { utilService } from '../../../utils/util.service'
import SvgIcon from '../../common/SvgIcon.svelte'
import NameInitials from '../../common/NameInitials.svelte'
import Select from 'svelte-select'
import { teamService } from '../../../services/core/team.service'
import { accountService } from '../../../services/core/account.service'
import { currAccount } from '../../../store/stores'
import { insightsService } from '../../../services/insights/data.service'
import CmpLoader from '../../common/loader/CmpLoader.svelte'

export let title = ''
export let onCancel
export let element
export let category
export let data = null

const navigate = useNavigate()

let accountElementsForCompare

onMount(async () => {
	elementsToCompare = await loadElementsToCompare(category)
})

async function loadElementsToCompare(category) {
	switch (category) {
		case 'dog': {
			accountElementsForCompare = await dogService.query($currAccount.id)
			return accountElementsForCompare
				.filter((el) => el.stats.totalTrainingSessions > 1 && el.id != element.id)
				.map((el) => ({ label: el.name, value: el.id, image: el.image }))
		}
		case 'team': {
			accountElementsForCompare = await teamService.query($loggedInUser.id, $currAccount.id)
			return accountElementsForCompare
				.filter((el) => el.stats.totalTrainingSessions > 1 && el.id != element.id)
				.map((el) => ({ label: el.name, value: el.id, image: el.icon }))
		}
		case 'user': {
			accountElementsForCompare = await accountService.queryUsers(
				$currAccount.userIds,
				$currAccount.id
			)
			return accountElementsForCompare
				.filter((el) => el.stats.totalTrainingSessions > 1 && el.id != element.id)
				.map((el) => ({ label: el.firstName + ' ' + el.lastName, value: el.id, image: el.icon }))
		}
		case 'training': {
			accountElementsForCompare = insightsService.getTrainingTypes(data)

			const filteredMap = Array.from(accountElementsForCompare.entries())
				.filter(([key, el]) => key !== element.id)
				.map(([key, el]) => ({ label: el, value: key, image: '' }))

			return filteredMap
		}
	}
}

function makeCompareList() {
	compareStore.set([element.id, ...elementsForCompareId])
	compareCategory.set(category)

	navigate(`/insights/${category == 'trainedBy' ? 'user' : category}/compare`, {
		state: {
			pageName: utilService.capitalizeFirstLetter('Compare List')
		}
	})
}

let elementsToCompare: Array<{ value: string; label: string; image: string }> = []
let elementsForCompareId: string[]
</script>

<div id="background-overlay" />

<div class="alert flex-column justify-center">
	<header class="form-header flex align-center">
		<h1 class="title flex align-center">{$_(title)}</h1>
	</header>
	{#if element.id && elementsToCompare.length > 0}
		<div class="reference-container flex-column">
			<h2 class="title">Reference</h2>
			<div class="reference-select flex align-center">
				<div
					class="{element.iconUrl
						? 'training-img-container'
						: element.icon
						? 'team-img-container'
						: 'dog-img-container'} flex align-center justify-center">
					{#if element.image}
						<img
							src={element.image}
							alt="dog profile" />
					{:else if element.icon}
						<SvgIcon
							width="26px"
							name={element.icon} />
					{:else if element.iconUrl}
						<img
							class="type-icon"
							src={element.iconUrl}
							alt="training profile"
							width="40px"
							height="40px" />
					{:else}
						<NameInitials name={element.name} />
					{/if}
				</div>
				{element.name}
			</div>
		</div>
		<div class="compare-container flex-column">
			<h2 class="title">Compare</h2>
			<Select
				showChevron={true}
				items={elementsToCompare}
				multiple={true}
				clearable={false}
				bind:justValue={elementsForCompareId} />
		</div>
		<div class="buttons flex">
			<button
				class="btn theme-btn outline"
				on:click={onCancel}>{$_('cancel')}</button>
			<button
				class="btn theme-btn"
				on:click={makeCompareList}>Compare</button>
		</div>
	{:else}
		<CmpLoader />
	{/if}
</div>

<style lang="scss">
.alert {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -70%);
	min-width: 540px;
	height: 370px;
	background-color: white;
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	border-radius: 16px;
	z-index: 101;
	padding: 36px;
	gap: 24px;

	@media screen and (max-width: 768px) {
		height: fit-content;
		width: 90%;
	}

	.form-header {
		margin-bottom: 8px;

		.title {
			font-size: 20px;
			font-family: Nunito-SemiBold;
			gap: 16px;

			&::before {
				content: '';
				position: relative;
				width: 16px;
				height: 32px;
				background-color: #ef4f5052;
				border-radius: 4px;
			}
		}
	}

	.reference-container,
	.compare-container {
		gap: 4px;

		.title {
			font-size: 15px;
		}

		.reference-select {
			background: rgba(143, 149, 178, 0.13);
			border: 1px solid #e6e8f0;
			padding: 6px 5px 5px 13px;
			border-radius: 6px;
			height: 42px;
		}

		.dog-img-container {
			aspect-ratio: 1/1;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			border: 0.7px solid #ddd;
			border-radius: 50%;
			overflow: hidden;
			height: 28px;
			width: 28px;
			flex-shrink: 0;
		}

		.team-img-container {
			margin-right: 8px;
		}

		.training-img-container {
			background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			width: 24px;
			height: 24px;
			margin-right: 8px;
			img {
				filter: none;
				width: 70%;
				height: 70%;
			}
		}
	}

	.buttons {
		margin-top: 16px;
		align-self: flex-end;
		gap: 10px;

		.btn {
			font-size: 14px !important;
		}
	}
}

#background-overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
}
</style>
