<script lang="ts">
import TotalDogsIcon from './icons/TotalDogsIcon.svelte'
import TotalTrainingsIcon from './icons/TotalTrainingsIcon.svelte'
import TotalTrainingHoursIcon from './icons/TotalTrainingHoursIcon.svelte'
import TotalTrainingSessionsIcon from './icons/TotalTrainingSessionsIcon.svelte'
import type { AccountUserModel } from '../../../models/user.model'
import { _ } from 'svelte-i18n'

export let user: AccountUserModel

let totalHours = Math.floor(user.stats.totalTrainingTime / 60)

$: userStats = [
	{
		title: 'total_dogs',
		icon: TotalDogsIcon,
		value: user.stats.dogsCount
	},
	{
		title: 'total_trainings',
		icon: TotalTrainingsIcon,
		value: user.stats.totalTrainingDays
	},
	{
		title: 'total_training_hours',
		icon: TotalTrainingHoursIcon,
		value: `${totalHours} hours ${user.stats.totalTrainingTime - totalHours * 60} minutes`
	},
	{
		title: 'total_training_sessions',
		icon: TotalTrainingSessionsIcon,
		value: user.stats.totalTrainingSessions
	}
]
</script>

<section class="user-info">
	<header class="header">
		<h1 class="title">{$_('user_quick_insights')}</h1>
	</header>
	<div class="align-center space-around content">
		{#each userStats as stat}
			<div class="flex align-center info type">
				<div class="svg-container">
					<svelte:component this={stat.icon} />
				</div>
				<div class="flex-column description">
					<h2 class="value">{stat.value}</h2>
					<h1 class="title">{$_(stat.title)}</h1>
				</div>
			</div>
		{/each}
	</div>
</section>

<style lang="scss">
@use '../../../styles/setup/mixins';

.user-info {
	@include mixins.card;

	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.content {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(min-content, 230px));

		@media screen and (max-width: 768px) {
			padding-inline-start: 18px;
			justify-content: flex-start;
		}

		.info {
			padding-inline: 20px;
			margin-bottom: 30px;
			text-transform: capitalize;

			&:not(.user-title) {
				gap: 16px;
			}

			.user-img-container,
			.svg-container {
				width: 48px;
				height: 48px;
				min-width: 48px;
			}

			.description {
				width: fit-content;

				.title {
					font-size: 12px;
					font-family: Nunito;
					font-weight: normal;
					color: var(--clr-txt-dark-secondary);
				}

				.value {
					font-size: 18px;
					font-weight: 500;
					white-space: nowrap;
				}
			}
		}
	}
}
</style>
