<script lang="ts">
import { _ } from 'svelte-i18n'
import { textArea } from '../../../store/trainingWidgetsStore'

export let values: {
	trigger?: number
	selectedIndex?: number
	initialValue?: string
	maxLines: number
	readOnly?: boolean
	value: string
	filled: boolean
}
export let id: string = null
export let data = []

if (!$textArea[id]) {
	$textArea[id] = ''
}

$: {
	if (values.value != '') {
		values.filled = true
	} else values.filled = false
}
if (data.length > 0 && $textArea[id] == '') {
	values.initialValue = data[0].value
}
$: {
	values.value = values.initialValue
	$textArea[id] = values.value
}
</script>

{#if values.readOnly}
	<textarea
		readonly
		bind:value={values.initialValue}
		placeholder={$_('write_some_text')}
		class="textarea edit-input"
		rows={values.maxLines} />
{:else}
	<textarea
		bind:value={values.initialValue}
		placeholder={$_('write_some_text')}
		class="textarea edit-input"
		rows={values.maxLines} />
{/if}

<style lang="scss">
.textarea {
	width: 600px;
	resize: none;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}
</style>
