<script lang="ts">
import Toggle from './Toggle.svelte'
import Textarea from './Textarea.svelte'
import { toggleMultiText } from '../../../store/trainingWidgetsStore'

export let values: {
	labels: string[]
	maxLines: number
	selectedIndex: number
	trigger: number[]
	value: {
		selectedIndex: number
		answer: string
		labels: string[]
		text: string
	}
}
export let data = []
export let id: string

let toggleValues: any
let textValues: any

if (!$toggleMultiText[id]) {
	$toggleMultiText[id] = {
		labels: values.labels,
		maxLines: values.maxLines,
		selectedIndex: values.selectedIndex,
		trigger: values.trigger,
		value: {
			selectedIndex: 0,
			answer: '',
			labels: [],
			text: ''
		}
	}
}

if (data.length > 0 && $toggleMultiText[id].value.answer == '') {
	toggleValues = {
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
} else if ($toggleMultiText[id]) {
	toggleValues = {
		labels: values.labels,
		selectedIndex: $toggleMultiText[id].value.selectedIndex,
		value: $toggleMultiText[id].value.selectedIndex
	}
} else {
	toggleValues = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
}

if (data.length > 0 && $toggleMultiText[id].value.answer == '') {
	textValues = {
		initialValue: data[0].value.text,
		maxLines: values.maxLines,
		filled: false,
		value: data[0].value.text
	}
} else if ($toggleMultiText[id].value.text) {
	textValues = {
		initialValue: $toggleMultiText[id].value.text,
		maxLines: values.maxLines,
		filled: false,
		value: $toggleMultiText[id].value.text
	}
} else {
	textValues = {
		maxLines: values.maxLines,
		filled: false,
		value: ''
	}
}

if (data.length > 0 && $toggleMultiText[id].value.answer == '') {
	values.value = {
		selectedIndex: data[0].value.selectedIndex,
		answer: data[0].value.answer,
		labels: values.labels,
		text: data[0].value.text
	}
} else if ($toggleMultiText[id]) {
	values.value = {
		selectedIndex: $toggleMultiText[id].value.selectedIndex,
		answer: $toggleMultiText[id].value.answer,
		labels: values.labels,
		text: $toggleMultiText[id].value.text
	}
} else {
	values.value = {
		selectedIndex: toggleValues.selectedIndex,
		answer: '',
		labels: values.labels,
		text: textValues.value
	}
}

$: if (values.trigger.includes(toggleValues.selectedIndex)) {
	values.value.selectedIndex = toggleValues.selectedIndex
	values.value.text = textValues.value
	values.value.answer = values.labels[toggleValues.selectedIndex]
	$toggleMultiText[id] = values
} else {
	values.value.selectedIndex = toggleValues.selectedIndex
	values.value.answer = values.labels[toggleValues.selectedIndex]
	values.value.text = null
	$toggleMultiText[id] = values
}
</script>

<Toggle bind:values={toggleValues} />

{#if values.trigger.includes(toggleValues.selectedIndex)}
	<Textarea bind:values={textValues} />
{/if}
