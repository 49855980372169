<svg
	width="20"
	height="20"
	viewBox="0 0 20 20"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M4.68326 2.01227C4.97438 1.92136 5.27642 1.875 5.58051 1.875C7.04213 1.875 8.12501 3.16205 8.125 4.61756L8.125 7.88245C8.12501 9.33795 7.04212 10.625 5.5805 10.625C5.27642 10.625 4.97438 10.5786 4.68326 10.4877L4.46072 10.4182C4.38474 10.3945 4.30998 10.3682 4.23652 10.3394C5.39593 12.694 7.30603 14.6041 9.66059 15.7635C9.6318 15.69 9.60548 15.6153 9.58176 15.5393L9.51227 15.3167C9.42136 15.0256 9.375 14.7236 9.375 14.4195C9.375 12.9579 10.662 11.875 12.1176 11.875H15.3824C16.838 11.875 18.125 12.9579 18.125 14.4195C18.125 14.7236 18.0786 15.0256 17.9877 15.3167L17.9182 15.5393C17.5135 16.8356 16.3557 17.7744 14.9707 18.0184C14.1643 18.1605 13.3357 18.1605 12.5293 18.0184C12.4976 18.0128 12.466 18.0069 12.4345 18.0006C7.14979 16.9838 3.01615 12.8502 1.99942 7.56547C1.99311 7.534 1.98716 7.50241 1.98157 7.4707C1.83948 6.66433 1.83948 5.83567 1.98157 5.0293C2.22562 3.64435 3.1644 2.48653 4.46072 2.08176C4.46072 2.08176 4.46072 2.08176 4.46072 2.08175L4.68325 2.01227L4.68326 2.01227ZM3.22623 7.32575C3.22145 7.3009 3.21675 7.27602 3.21213 7.25111C3.0958 6.58909 3.09596 5.9082 3.2126 5.24623C3.38468 4.26968 4.03133 3.52535 4.83329 3.27494L4.8333 3.27494L5.05583 3.20545C5.05583 3.20545 5.05583 3.20545 5.05583 3.20545C5.22733 3.1519 5.40379 3.125 5.58051 3.125C6.23911 3.125 6.87501 3.73408 6.875 4.61755L6.875 7.88245C6.875 8.76592 6.23911 9.375 5.58051 9.375C5.40379 9.375 5.22733 9.3481 5.05583 9.29455L4.83329 9.22506C4.05115 8.98084 3.41674 8.26681 3.22623 7.32575ZM12.7488 16.7879C13.4109 16.9042 14.0918 16.904 14.7538 16.7874C15.7303 16.6153 16.4746 15.9687 16.7251 15.1667L16.7945 14.9442C16.8481 14.7727 16.875 14.5962 16.875 14.4195C16.875 13.7609 16.2659 13.125 15.3824 13.125H12.1175C11.2341 13.125 10.625 13.7609 10.625 14.4195C10.625 14.5962 10.6519 14.7727 10.7055 14.9442L10.7749 15.1667C11.0192 15.9489 11.7332 16.5833 12.6743 16.7738C12.6991 16.7786 12.724 16.7832 12.7488 16.7879Z"
		fill="#FD9703" />
</svg>
