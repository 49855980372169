<script lang="ts">
import DropdownBottomSheet from './DropdownBottomSheet.svelte'
import TextToTags from './TextToTags.svelte'
import { dropdownTextToTags } from '../../../store/trainingWidgetsStore'

export let values: {
	items: string[]
	trigger: number
	value: {
		dropdownValue: string
		tags: string[]
	}
}
export let data = []
export let id: string

let dropdownValues: any
let textToTagsValues: any

if (!$dropdownTextToTags[id]) {
	$dropdownTextToTags[id] = {
		items: values.items,
		trigger: values.trigger,
		value: {
			dropdownValue: '',
			tags: []
		}
	}
}

if ($dropdownTextToTags[id].value.dropdownValue != '') {
	dropdownValues = {
		items: $dropdownTextToTags[id].items,
		value: $dropdownTextToTags[id].value.dropdownValue,
		returnedValue: $dropdownTextToTags[id].returnedValue,
		filled: false
	}
} else {
	dropdownValues = {
		items: values.items,
		value: '',
		filled: false
	}
}

textToTagsValues = $dropdownTextToTags[id]
	? { tags: $dropdownTextToTags[id].value.tags, value: $dropdownTextToTags[id].value.tags }
	: { tags: [], value: [] }

values.value = {
	dropdownValue: dropdownValues.value,
	tags: textToTagsValues.value
}

$: {
	if (dropdownValues.value == values.items[values.trigger]) {
		values.value = {
			dropdownValue: dropdownValues.value,
			tags: textToTagsValues.value
		}
		$dropdownTextToTags[id] = values
		$dropdownTextToTags[id].returnedValue = {
			value: dropdownValues.value,
			label: dropdownValues.value
		}
	} else {
		values.value = {
			dropdownValue: dropdownValues.value,
			tags: []
		}
		$dropdownTextToTags[id] = values
		$dropdownTextToTags[id].returnedValue = {
			value: dropdownValues.value,
			label: dropdownValues.value
		}
	}
}
</script>

<DropdownBottomSheet
	bind:values={dropdownValues}
	data={data.length > 0 ? [{ value: data[0].value.dropdownValue }] : []} />

{#if dropdownValues.value == values.items[values.trigger]}
	<TextToTags
		bind:values={textToTagsValues}
		{id}
		data={data.length > 0 ? [{ value: data[0].value.tags }] : []} />
{/if}
