<svg
	width="49"
	height="48"
	viewBox="0 0 49 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_548_25770)">
		<rect
			x="0.224609"
			width="48"
			height="48"
			rx="24"
			fill="#DCEBF2" />
		<g clip-path="url(#clip1_548_25770)">
			<path
				d="M31.1998 16.8826C32.2236 16.8622 33.213 15.4603 33.6418 13.4231L33.9413 12H35.3991C37.5946 12 38.697 12.734 38.697 14.1962C38.697 14.6389 38.8933 14.7174 39.9978 14.7174C41.8314 14.7174 42.0221 15.0497 41.4706 17.2864C40.7405 20.2481 40.7007 20.2728 36.4546 20.3769L32.8303 20.466V27.1766V33.8872L33.7701 34.403C34.6866 34.906 35.1895 35.8641 34.8397 36.441C34.733 36.6168 33.4306 36.7283 31.4812 36.7283C26.8495 36.7283 27.2302 37.6122 27.2302 26.8592C27.2302 20.0527 27.3161 17.7753 27.6113 16.737C27.8209 15.9997 28.1358 14.8465 28.3113 14.1739L28.6305 12.9511L28.9934 14.2011C29.3828 15.5416 30.4977 16.8967 31.1998 16.8826Z"
				fill="url(#paint0_linear_548_25770)" />
			<path
				d="M6.18365 17.7936C5.78232 16.3205 5.82552 15.8779 6.43112 15.2607C7.40738 14.2659 8.48285 14.7808 8.73086 16.3621C8.8458 17.0933 9.15673 17.6656 9.6866 18.12C10.3941 18.7265 10.7079 18.7933 12.8517 18.7933H15.2311V21.2512C15.2311 23.4988 15.2871 23.7664 15.8858 24.3762C16.5308 25.0338 16.6069 25.0433 21.2191 25.0433H25.8978V27.6083V30.173L24.7002 30.3561C23.4679 30.5444 19.2351 30.1387 17.6978 29.6849L16.8311 29.4292V31.658V33.8871L17.7708 34.4029C18.6874 34.9058 19.1903 35.864 18.8405 36.4409C18.7338 36.6167 17.4314 36.7281 15.4821 36.7281C10.917 36.7281 11.2311 37.3167 11.2311 28.7615V21.8817L9.7906 21.4189C8.09752 20.8751 6.61832 19.3882 6.18365 17.7936Z"
				fill="url(#paint1_linear_548_25770)" />
			<path
				d="M20.0318 19.0655V17.9786V16.8916H21.2318H22.4318V17.9786V19.0655H23.4984H24.5651V20.2883V21.5112H23.4984H22.4318V22.5981V23.6851H21.2318H20.0318V22.5981V21.5112H18.9651H17.8984V20.2883V19.0655H18.9651H20.0318Z"
				fill="url(#paint2_linear_548_25770)" />
		</g>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_548_25770"
			x1="34.4827"
			y1="12"
			x2="34.4827"
			y2="36.7459"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<linearGradient
			id="paint1_linear_548_25770"
			x1="15.9098"
			y1="14.752"
			x2="15.9098"
			y2="36.7367"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<linearGradient
			id="paint2_linear_548_25770"
			x1="21.2318"
			y1="16.8916"
			x2="21.2318"
			y2="23.6851"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#264654" />
			<stop
				offset="1"
				stop-color="#255367" />
		</linearGradient>
		<clipPath id="clip0_548_25770">
			<rect
				width="48.1731"
				height="48"
				fill="white"
				transform="translate(0.224609)" />
		</clipPath>
		<clipPath id="clip1_548_25770">
			<rect
				width="36"
				height="25"
				fill="white"
				transform="translate(5.92188 12)" />
		</clipPath>
	</defs>
</svg>
