<script lang="ts">
import '../../../styles/themes/_globals.scss'
import { createEventDispatcher } from 'svelte'
import type { SvgIconName } from '../../../models/common.model'
import moment from 'moment'
import { _ } from 'svelte-i18n'
import type { SearchTrainingModel } from '../../../models/training.model'
import NoResultAnimation from '../../../cmps/common/animation/NoResultAnimation.svelte'
import AiOutlineEye from 'svelte-icons-pack/ai/AiOutlineEye'
import Icon from 'svelte-icons-pack'
import Modal from '../../../cmps/common/modal/AISummary.svelte'
import type { Timestamp } from 'firebase/firestore'
import CmpLoader from '../loader/CmpLoader.svelte'
import 'moment/locale/es' // Import Spanish locale
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

moment.locale(localStorage.getItem('language'))

type TypeInfoDog = {
	id: string
	image: string
	name: string
}

const dispatch = createEventDispatcher()
let isLoading = false

function trainingTypeSelected(training) {
	// Dispatch event to parent component
	isLoading = true
	dispatch('trainingTypeSelected', training)
}

export let trainingsForDisplay: {
	typeInfo: {
		count: number
		dog: TypeInfoDog
		latestDate: Timestamp
		type: string
		trainingTypeId: string
		ids: string[]
		iconUrl: string
	}[]
}

const teamIcons: SvgIconName[] = [
	'add-general-person',
	'add-general-team',
	'add-police-person',
	'add-team',
	'dog-alert',
	'dog-ball',
	'dog-scent',
	'fire-person',
	'fire-team',
	'general-dog',
	'general-team',
	'military-person',
	'military-team',
	'police-person',
	'police-team',
	'remove-team',
	'rescue-dog',
	'rescue-person',
	'rescue-team',
	'running-dog'
]

function getProperTime(date: Date) {
	return moment().startOf('day').diff(moment(date), 'days') > 11
		? moment(date).format('MMMM D[,] YYYY')
		: moment(date).fromNow()
}

// AI Summary Modal Logic
let summaryTraining: SearchTrainingModel = null
</script>

{#if !isLoading}
	{#if trainingsForDisplay.typeInfo && trainingsForDisplay.typeInfo.length === 0}
		<div class="flex-column align-center no-dogs-animation">
			<NoResultAnimation />
		</div>
	{:else if trainingsForDisplay.typeInfo && trainingsForDisplay.typeInfo.length > 0}
		{#if summaryTraining && summaryTraining.summary}
			<Modal
				{summaryTraining}
				on:close={() => (summaryTraining = null)} />
		{/if}
		<section class="flex-column training-list">
			<header class="header-container">
				<ul class="header">
					<li class="type">{$_('type')}</li>
					<li class="number-of-trainings">{$_('number_of_trainings')}</li>
					<li class="last-training">{$_('last_training')}</li>
					<li class="action">{$_('action')}</li>
				</ul>
			</header>
			<div class="flex-column content">
				{#each trainingsForDisplay.typeInfo as training}
					<button
						class="training-preview-container"
						on:click={() => trainingTypeSelected(training)}>
						<ul class="training-preview">
							<li class="flex training-name">
								<div class="training-img-container">
									{#if training.iconUrl}
										<img
											class="type-icon"
											src={training.iconUrl}
											alt="training profile"
											width="40px"
											height="40px" />
									{/if}
								</div>
								<div class="training-type flex">
									<p>
										{training.type}
									</p>
								</div>
							</li>
							<li class="number-of-trainings">
								<p>
									{training.count}
								</p>
							</li>
							<li class="last-training">
								{getProperTime(training.latestDate.toDate())}
							</li>
							<li class="action action-data">
								<button
									class="btn btn-svg btn-edit"
									title="Details">
									<Icon src={AiOutlineEye} />
								</button>
							</li>
						</ul>
					</button>
				{/each}
			</div>
		</section>
	{/if}
{:else}
	<CmpLoader />
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.training-list {
	@include mixins.card;
	overflow-x: auto;

	max-height: 500px;
	overflow-y: auto;

	.header,
	.training-preview {
		display: grid;
		grid-template-columns: minmax(100px, 1fr) minmax(100px, 0.9fr) minmax(100px, 1fr) minmax(
				100px,
				0.8fr
			);
		min-width: fit-content;
		column-gap: 8px;
		overflow: hidden;

		> li {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		.training-name,
		.number-of-trainings {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			gap: 12px;

			.training-type {
				p {
					max-width: 110px;
					white-space: nowrap;
					position: relative;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}

		.type {
			white-space: normal;
			position: relative;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.action {
			margin-right: 12px;
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}

				:global(svg) {
					font-size: 18px;
				}
			}
		}
	}
	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);

		.action {
			margin-right: 2rem;
		}
	}

	.content {
		position: relative;
		font-size: 14px;
	}
	.training-preview {
		border-top: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 14px;
		transition: background-color 0.15s ease-out;
		img {
			object-fit: cover;
			aspect-ratio: 1;
		}
		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}
	}

	@media (max-width: 1350px) {
		.header {
			display: none;
		}
		.header,
		.training-preview {
			grid-template-columns: minmax(200px, 2fr) minmax(90px, 1fr);
		}
		.number-of-trainings,
		.action {
			display: none !important;
		}

		.training-name {
			width: 200px;
		}
	}

	.training-img-container {
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1000px;
		width: 40px;
		height: 40px;
		aspect-ratio: 1/1;

		img {
			filter: none;
			width: 27px;
			height: 27px;
		}
	}
}

@media screen and (max-width: 768px) {
	.content {
		max-height: 400px;
	}
}
</style>
