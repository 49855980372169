<script lang="ts">
import { afterUpdate, createEventDispatcher } from 'svelte'
import { _ } from 'svelte-i18n'

const dispatch = createEventDispatcher()

export let categories: string[]
export let activeCategory: string

let selector: HTMLSpanElement

afterUpdate(() => {
	const activeCategoryBtn = document.querySelector(
		`.btn.category.${activeCategory}`
	) as HTMLSpanElement
	selector.style.transform = `translateX(${activeCategoryBtn.offsetLeft}px)`
	selector.style.width = `${activeCategoryBtn.clientWidth}px`
	selector.style.height = `${activeCategoryBtn.clientHeight}px`
})
</script>

<div class="category-selector">
	<div class="flex align-center btn-group">
		<span
			class="selector"
			bind:this={selector} />
		{#each categories as category}
			<button
				class="btn category {category}"
				class:active={activeCategory === category}
				on:click={() => dispatch('select', category)}>
				<span class="label">{$_(category)}</span>
			</button>
		{/each}
	</div>
</div>

<style lang="scss">
.category-selector {
	.btn-group {
		position: relative;
		padding: 5px;
		gap: 6px;
		border-radius: 6px;
		background-color: #e6e8f0;

		.selector {
			position: absolute;
			left: 0;
			width: 10px;
			height: 10px;
			background-color: white;
			z-index: 1;
			transition: transform 0.3s ease-out, width 0.2s 0.1s;
			border-radius: 4px;
		}

		.category {
			position: relative;
			padding: 3px 12px;
			border: none;

			.label {
				position: relative;
				z-index: 2;
				text-transform: capitalize;
				font-family: Nunito-Bold;
				font-size: 15px;
				color: #5e5873;
			}
		}
	}
}
</style>
