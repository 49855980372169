<script lang="ts">
import * as Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting'
import { compareStore, compareCategory } from './compareStore'
import { onMount } from 'svelte'
import { _ } from 'svelte-i18n'

export let groupBy = 'sessions'
export let data = undefined
export let category = undefined
export let infoIds

let chart

onMount(() => {
	if (data) {
		const dataForDisplay = processDataForChart(data, groupBy, category, infoIds)

		generateChart(dataForDisplay)
	}
})

function processDataForChart(data, groupBy, category, infoIds) {
	const getSessionKey = (data) =>
		groupBy === 'dog'
			? data.dog.id
			: groupBy === 'team'
			? data.team.id
			: groupBy === 'training'
			? data.trainingTypeId
			: data.trainedBy.id

	const trainings = new Map()

	data.forEach((element) => {
		const rawData = JSON.parse(element['data'])
		const trainingType = rawData.type
		const groupKey = getSessionKey(rawData)

		if (!infoIds || infoIds.includes(rawData[category].id)) {
			const key = rawData[category].id
			const name = rawData[category].name
			const trainingTime = rawData.trainingTime

			if (!trainings.has(key)) {
				trainings.set(key, { key: name })
			}

			trainings.get(key)[trainingType] = (trainings.get(key)[trainingType] || 0) + trainingTime
		}
	})

	let resultMap = new Map()

	Array.from(trainings.values()).map((training) => {
		resultMap.set(training.key, training)
	})

	return resultMap
}

function generateChart(data) {
	const colorList = [
		'#fd7f6f',
		'#7eb0d5',
		'#b2e061',
		'#bd7ebe',
		'#ffb55a',
		'#ffee65',
		'#beb9db',
		'#fdcce5',
		'#8bd3c7'
	]

	const trainingTypes = Array.from(data.values())[0]
	const series: Highcharts.SeriesOptionsType[] = Array.from(data).map(
		([category, training], index) => {
			const color = colorList[index % colorList.length]

			return {
				type: 'column',
				name: category,
				data: Object.keys(trainingTypes)
					.map((trainingType) => training[trainingType] || 0)
					.slice(1),
				color: color
			}
		}
	)

	const chartConfig: Highcharts.Options = {
		chart: {
			type: 'column',
			renderTo: 'training-time-chart'
		},
		title: {
			text: ''
		},
		xAxis: {
			categories: Object.keys(trainingTypes).slice(1),
			lineWidth: 0.1,
			labels: {
				style: {
					opacity: 0.6
				}
			}
		},
		yAxis: {
			min: 0,
			title: {
				text: $_('minutes')
			},
			stackLabels: {
				enabled: false,
				style: {
					fontWeight: 'bold',
					color: 'gray',
					opacity: 0.6
				}
			}
		},
		tooltip: {
			formatter: function () {
				const month = this.x
				const value = Highcharts.numberFormat(this.y, 0, ',', '')
				return (
					'<span style="font-weight: normal; font-size: 11px">' +
					month +
					'</span><br/><b>' +
					value +
					' minutes</b>'
				)
			}
		},
		legend: {
			align: 'center',
			verticalAlign: 'top',
			shadow: false
		},
		plotOptions: {
			column: {
				pointWidth: 40,
				stacking: 'normal',
				dataLabels: {
					enabled: false,
					color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
					style: {
						textShadow: '0 0 3px black, 0 0 3px black'
					}
				}
			}
		},
		series: series
	}

	chart = new Highcharts.Chart(chartConfig)
}
</script>

<h1 class="title">Total Training Time by Type</h1>
<div class="chart-container">
	<div id="training-time-chart" />
</div>

<style lang="scss">
@use '../../../styles/setup/mixins';
.chart-container {
	@include mixins.card;
	min-width: 500px;
}
</style>
