<script lang="ts">
import { utilService } from '../../../utils/util.service'
import HomeIcon from './icons/HomeIcon.svelte'
import LayersIcon from './icons/LayersIcon.svelte'
import PhoneIcon from './icons/PhoneIcon.svelte'
import WebsiteIcon from './icons/WebsiteIcon.svelte'
import { _ } from 'svelte-i18n'
import AccountEditButton from './AccountEditButton.svelte'
import { currAccount } from '../../../store/stores'
import { loggedInUser } from '../../../store/stores'
import CmpLoader from '../../common/loader/CmpLoader.svelte'

$: accountInfo = [
	{
		name: 'phone number',
		value: $currAccount.phoneNumber || 'add_phone_number',
		icon: PhoneIcon
	},
	{
		name: 'subscription',
		value:
			$currAccount.subscriptionType == 'team'
				? $currAccount.subscriptionData.items[0].price.description
						.split(' ')
						.slice(0, 2)
						.join(' ') +
					' ' +
					`(${$currAccount.subscriptionData.billing_cycle.interval + 'ly'})`
				: $currAccount.subscriptionType == 'trial'
				? $_(utilService.capitalizeFirstLetter($currAccount.subscriptionType))
				: $_('trial_extended'),
		icon: LayersIcon
	},
	{
		name: 'address',
		value: !$currAccount.location.address ? 'add_address' : $currAccount.location.address,
		icon: HomeIcon
	},
	{
		name: 'website',
		value: $currAccount.website || 'add_website',
		icon: WebsiteIcon
	}
]
</script>

<div class="flex-column account-info">
	{#if $currAccount.id != $loggedInUser.selectedAccount.id}
		<CmpLoader />
	{:else}
		<header class="flex align-center space-between header">
			<h1>{$currAccount.name}</h1>
			<div id="account-edit-button">
				<AccountEditButton />
			</div>
		</header>
		<div class="body">
			{#each accountInfo as infoField}
				{#if infoField.value}
					<div
						class="flex align-center info-field"
						title={$_(utilService.capitalizeFirstLetter(infoField.name))}>
						<div class="icon svg-icon">
							<svelte:component this={infoField.icon} />
						</div>
						<p class="value">{$_(infoField.value)}</p>
					</div>
				{/if}
			{/each}
		</div>
	{/if}
</div>

<style lang="scss">
@use '../../../styles/setup/mixins';
.account-info {
	@include mixins.card;
	flex: 2;
	min-width: 210px;
	height: 100%;

	.header {
		padding: 12px 12px;
		padding-bottom: 6px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.body {
		padding: 9px 17px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));

		.info-field {
			height: fit-content;
			max-width: 210px;
			gap: 8px;
			padding: 2px;
			font-size: 0.9em;

			.value {
				color: var(--clr-txt-dark-secondary);
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				width: 230px;
				height: 22px;
			}

			:global(.icon) {
				width: 20px;
			}
		}

		@media screen and (max-width: 1525px) {
			padding: 0px 17px;
		}
	}
}
</style>
