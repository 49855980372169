<script lang="ts">
import Dropzone from 'dropzone'
import { onMount } from 'svelte'
import 'dropzone/dist/dropzone.css'
import { getAuth } from 'firebase/auth'
import type { TrainingModel } from '../../../models/training.model'
import { _ } from 'svelte-i18n'
import { fireMsg } from '../../../utils/userMsg.service'

export let myDropzone
export let sessionKeys

let idToken

const auth = getAuth()

let id = ''

onMount(async () => {
	idToken = await auth.currentUser.getIdToken(true)
	myDropzone = new Dropzone('.dropzone', {
		url: `${import.meta.env.VITE_APP_API_URL}/training/${id}/fileupload`,
		method: 'POST',
		success: function () {
			fireMsg({
				type: 'success',
				msg: $_('upload_successful')
			})
		},
		delete: function () {
			fireMsg({
				type: 'success',
				msg: $_('file was deleted successfully')
			})
		},
		error: function () {
			fireMsg({
				type: 'failure',
				msg: $_('upload_error')
			})
		},
		acceptedFiles:
			sessionKeys.fileUploadLimit == 5
				? '.gpx,.GPX,.png,.jpeg,.jpg'
				: sessionKeys.fileUploadLimit == 10
				? '.gpx,.GPX,.png,.jpeg,.jpg,.doc,.docx,.pdf'
				: '.gpx,.GPX,.png,.jpeg,.jpg,.doc,.docx,.pdf,.avi,.wav,.wmv,.mp4,.webm',
		maxFilesize: 5e8,
		paramName: 'files',
		autoProcessQueue: false,
		addRemoveLinks: true,
		maxFiles: sessionKeys.fileUploadLimit,
		parallelUploads: 1000000,
		headers: {
			Accept: '/',
			Authorization: `Bearer ${idToken}`
		}
	})
	myDropzone.on('addedfile', (file) => {
		console.log(`File added: ${file.name}`)
	})
})
</script>

<form class="dropzone" />
