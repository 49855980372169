<script lang="ts">
import type { TrainingModel } from '../../../../models/training.model'
import WindComponent from './Wind.svelte'
import TemperatureComponent from './Temperature.svelte'
import WeatherStatsComponent from './WeatherStats.svelte'
import TiWeatherCloudy from 'svelte-icons-pack/ti/TiWeatherCloudy'
import Icon from 'svelte-icons-pack'
import Alert from './Alert.svelte'
import { _ } from 'svelte-i18n'
import { Col, Row } from 'sveltestrap'

export let weather: TrainingModel['weather']
export let location: TrainingModel['location']
export let airQuality
</script>

{#if weather}
	{#if !weather.data}
		<section class="details flex align-center justify-center">
			<div class="icon">
				<Icon src={TiWeatherCloudy} />
			</div>
			<h1 class="title">
				{$_('no_weather_data')}
			</h1>
		</section>
	{:else}
		<Row>
			<Col xl="6">
				<div class="component-wrapper">
					<WindComponent bind:weather />
					<WeatherStatsComponent
						bind:weather
						bind:airQuality />
				</div>
			</Col>
			<Col xl="6">
				<TemperatureComponent
					bind:weather
					bind:location />
			</Col>
			<Col />
		</Row>
		<Alert bind:weather />
	{/if}
{:else}
	<section class="details flex align-center justify-center">
		<div class="icon">
			<Icon src={TiWeatherCloudy} />
		</div>
		<h1 class="title">
			{$_('no_weather_data')}
		</h1>
	</section>
{/if}

<style lang="scss">
@use '../../../../styles/setup/mixins';

.details {
	@include mixins.card;
	min-height: 170px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--clr-txt-dark-secondary);
	padding: 0px 20px;
	font-size: 21px;
	gap: 4px;
	overflow: auto;
}
.title {
	font-family: Nunito;
	font-weight: 500;
	font-size: 16px;
	overflow: auto;
}

.component-wrapper {
	overflow: auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.component-wrapper > * {
	overflow: auto;
	flex: 1;
}

:global(.mb-md-3) {
	@media screen and (max-width: 1200px) {
		margin-bottom: 18px !important;
	}
}
</style>
