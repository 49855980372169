<script lang="ts">
import { Lottie } from 'lottie-svelte'
import { _ } from 'svelte-i18n'
</script>

<section class="container flex-column">
	<div class="no-result-animation">
		<Lottie path="/assets/animations/no-result-animation.json" />
	</div>
	<h1>{$_('no_result_found')}</h1>
</section>

<style lang="scss">
.container {
	align-items: center;
	justify-content: center;
	width: 425px;

	.no-result-animation,
	h1 {
		margin-left: -40px;
	}

	@media screen and (max-width: 768px) {
		width: 100%;

		.no-result-animation,
		h1 {
			margin: 0;
		}
	}
}
</style>
