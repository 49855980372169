<script lang="ts">
import { _ } from 'svelte-i18n'
import { insightsService } from '../../services/insights/data.service'
import CmpLoader from '../common/loader/CmpLoader.svelte'
import Carousel from 'svelte-carousel'
import TotalLifetimeTraining from './TotalLifetimeTraining.svelte'
import { navBarOpen } from '../../store/stores'

export let infoId: string = undefined
export let comparisonIds = []
export let category: string
export let data: any

export let arrows = true
export let dots = true
export let swiping = true

let containerWidth: number
let dataForDisplay
let particlesToShow

$: {
	if (data) {
		dataForDisplay = [
			...new Map(
				[...insightsService.getTrainingData(data, category, infoId).entries()].sort(
					(a, b) => b[1].trainingTime - a[1].trainingTime
				)
			)
		]
		if (comparisonIds.length > 0) dataForDisplay = dataForDisplay.filter((data) => comparisonIds.includes(data[0]))
	}
}

$: particlesToShow = containerWidth ? Math.floor(containerWidth / 354) : 1
</script>

{#if (!infoId && comparisonIds.length == 0)}
	<h1 class="title">{$_(`insights_pages.total_lifetime_training`)}</h1>
	<h2 class="description">{$_(`insights_pages.get_detailed_information`)}</h2>
{/if}
{#if dataForDisplay && dataForDisplay.length > 0}
	<div
		class="carousel-container flex"
		class:nav-open={$navBarOpen}
		bind:offsetWidth={containerWidth}>
		<Carousel
			{particlesToShow}
			particlesToScroll={particlesToShow}
			dots={dataForDisplay.length > particlesToShow && dots}
			arrows={dataForDisplay.length > particlesToShow && arrows}
			{swiping}>
			{#each [...dataForDisplay] as [index, el]}
				<TotalLifetimeTraining
					element={el}
					{index}
					{category} />
			{/each}
		</Carousel>
	</div>
{:else}
	<CmpLoader />
{/if}

<style lang="scss">
@use '../../styles/setup/mixins';

.carousel-container {
	height: 208px;
	max-width: 100vw;
	gap: 12px;
	position: relative;

	&::-webkit-scrollbar {
		height: 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
}

.title {
	font-family: Montserrat-Medium;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.description {
	font-family: Montserrat-Medium;
	color: rgb(185, 181, 181);
	margin-top: -13px;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.carousel-container.nav-open {
	width: calc(100vw - 312px);
}

.carousel-container:not(.nav-open) {
	width: calc(100vw - 122px);
}

@media (max-width: 768px) {
	.carousel-container.nav-open,
	.carousel-container:not(.nav-open) {
		width: calc(100vw - 56px);
	}
}

:global(.sc-carousel-arrow__circle) {
	width: 30px !important;
	height: 30px !important;
}

:global(.sc-carousel-arrow__circle) {
	background-color: #fff !important;
}
</style>
