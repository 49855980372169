<script lang="ts">
import { afterUpdate, createEventDispatcher } from 'svelte'
import { _ } from 'svelte-i18n'

const dispatch = createEventDispatcher()

export let categories: string[]
export let activeCategory: string

let selector: HTMLSpanElement

afterUpdate(() => {
	const activeCategoryBtn = document.querySelector(
		`.btn.category.${activeCategory}`
	) as HTMLSpanElement
	selector.style.transform = `translateX(${activeCategoryBtn.offsetLeft}px)`
	selector.style.width = `${activeCategoryBtn.clientWidth}px`
})
</script>

<div class="category-selector">
	<div class="flex align-center btn-group">
		<span
			class="selector"
			bind:this={selector} />
		{#each categories as category}
			<button
				class="btn category {category}"
				class:active={activeCategory === category}
				on:click={() => dispatch('select', category)}>
				<span class="label">{#if (category != 'training')}{$_(`insights_categories.${category}`)}{:else}Trainings{/if}</span>
			</button>
		{/each}
	</div>
</div>

<style lang="scss">
@use '../../styles/setup/mixins';
.category-selector {
	@include mixins.card;
	overflow-x: auto;
	width: 100%;

	.btn-group {
		padding: 0 14px 0 4px;
		position: relative;
		height: 48px;
		width: 450px;
		gap: 6px;

		.selector {
			position: absolute;
			left: 1px;
			width: 10px;
			height: 3px;
			background-color: #2d7392;
			z-index: 1;
			transition: transform 0.3s ease-out, width 0.2s 0.1s;
			bottom: 0;
		}

		.category {
			position: relative;
			padding: 3px 8px;
			border: none;

			.label {
				position: relative;
				z-index: 2;
				text-transform: capitalize;
				font-family: Nunito;
				font-size: 16px;
			}
		}
	}

	&::-webkit-scrollbar {
		height: 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
}
</style>
