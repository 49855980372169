<script lang="ts">
import type { TrainingModel } from '../../../models/training.model'
import SvgIcon from '../../common/SvgIcon.svelte'
import type { SvgIconName } from '../../../models/common.model'
import TimeDoneIcon from './icon/TimeDoneIcon.svg'
import { _ } from 'svelte-i18n'
import NameInitials from '../../common/NameInitials.svelte'
import moment from 'moment'
import { utilService } from '../../../utils/util.service'
import morningIcon from '../training/icon/morning.svg'
import afternoonIcon from '../training/icon/afternoon.svg'
import eveningIcon from '../training/icon/evening.svg'
import nightIcon from '../training/icon/night.svg'
import midnightIcon from '../training/icon/midnight.svg'
import middayIcon from '../training/icon/midday.svg'
import sunriseIcon from '../training/icon/sunrise.svg'
import duskIcon from '../training/icon/dusk.svg'
import springIcon from '../training/icon/spring.svg'
import summerIcon from '../training/icon/summer.svg'
import fallIcon from '../training/icon/fall.svg'
import winterIcon from '../training/icon/winter.svg'
import eventIcon from '../training/icon/shield-dog-solid.svg'
import UnsuccsessfulIcon from '../training/icon/Smile1.svg'
import NeedsImprovementIcon from '../training/icon/Smile2.svg'
import SuccsessfulIcon from '../training/icon/Smile5.svg'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

moment.locale(localStorage.getItem('language'))

export let training: TrainingModel
export let trainingResults

const teamIcons: SvgIconName[] = [
	'add-general-person',
	'add-general-team',
	'add-police-person',
	'add-team',
	'dog-alert',
	'dog-ball',
	'dog-scent',
	'fire-person',
	'fire-team',
	'general-dog',
	'general-team',
	'military-person',
	'military-team',
	'police-person',
	'police-team',
	'remove-team',
	'rescue-dog',
	'rescue-person',
	'rescue-team',
	'running-dog'
]

const timesOfDayIcons = {
	morning: morningIcon,
	afternoon: afternoonIcon,
	evening: eveningIcon,
	night: nightIcon,
	midnight: midnightIcon,
	midday: middayIcon,
	sunrise: sunriseIcon,
	dusk: duskIcon
}

const seasonIcons = {
	Spring: springIcon,
	Summer: summerIcon,
	Fall: fallIcon,
	Winter: winterIcon
}

function getFullTrainingTime(totalMinutes) {
	const language = localStorage.getItem('language') || 'en' // Default to English if no language is set
	const hours = Math.floor(totalMinutes / 60)
	const minutes = totalMinutes % 60

	let hourLabel = 'h'
	let minuteLabel = 'min'

	// Set labels based on language
	switch (language) {
		case 'es':
			hourLabel = 'h'
			minuteLabel = 'min'
			break
		case 'he':
			hourLabel = 'שעות'
			minuteLabel = 'דקות'
			break
		default:
			// English is default, no need to change labels
			break
	}

	return hours
		? minutes
			? `${hours} ${hourLabel} ${minutes} ${minuteLabel}`
			: `${hours} ${hourLabel}`
		: `${minutes} ${minuteLabel}`
}

const infoForDisplay = [
	{
		title: 'type',
		value: training.type,
		iconSrc: training.iconUrl
	},
	{
		title: 'team',
		value: training.team.name,
		iconSrc: training.team.icon
	},
	{
		title: 'training_by',
		value: training.trainedBy.name,
		iconSrc: training.trainedBy.image
	},
	{
		title: 'dog',
		value: training.dog.name,
		iconSrc: training.dog.image
	},
	{
		title: 'training_time',
		value: getFullTrainingTime(training.trainingTime),
		iconSrc: '../../../assets/img/clock.svg'
	},
	{
		title: 'date',
		value: moment(training.dateTime.toDate()).format('MMMM D[,] YYYY'),
		iconSrc: '../../../assets/img/calendar.svg'
	},
	{
		title: 'time_done',
		value: moment(training.dateTime.toDate()).format('HH:mm'),
		iconSrc: TimeDoneIcon
	},
	{
		title: 'times_of_day',
		value: utilService.capitalizeFirstLetter(training.timesOfDay),
		iconSrc: timesOfDayIcons[training.timesOfDay]
	},
	{
		title: 'season',
		value: utilService.capitalizeFirstLetter(training.season),
		iconSrc: seasonIcons[training.season]
	},
	{
		title: 'training_event',
		value: training.event ? utilService.capitalizeFirstLetter(training.event) : $_('training'),
		iconSrc: eventIcon
	}
]

$: if (trainingResults[0]) {
	infoForDisplay.push({
		title: 'results',
		value: trainingResults[0].value.answer,
		description: trainingResults[0].value.text,
		iconSrc:
			trainingResults[0].value.answer == 'Successful'
				? SuccsessfulIcon
				: trainingResults[0].value.answer == 'Needs Improvement'
				? NeedsImprovementIcon
				: UnsuccsessfulIcon,
		showAll: false,
		shortMessage: trainingResults[0].value.text
			? trainingResults[0].value.text.slice(0, 20) + '...'
			: ''
	})
}

function showMore(index: number) {
	infoForDisplay[index].showAll = !infoForDisplay[index].showAll
}
</script>

<section class="dog-info">
	<header class="header">
		<h1 class="title">{$_('training_information')}</h1>
	</header>
	<div class="align-center space-around content">
		{#each infoForDisplay as info, index}
			{#if info.value}
				<div class="flex align-center info {info.title}">
					<div class="flex-row description">
						{#if info.title === 'type' && info.iconSrc === training.iconUrl}
							<div class="training-img-container">
								<img
									width="50px"
									height="50px"
									class="type-icon"
									src={info.iconSrc}
									alt="training icon" />
							</div>
						{:else if info.title === 'team' && info.iconSrc == training.team.icon}
							<SvgIcon
								name={teamIcons.filter((icon) => icon == training.team.icon)[0]}
								width="50px" />
						{:else if info.title === 'training_by' && !info.iconSrc}
							<div class="svg-container">
								<NameInitials name={training.trainedBy.name} />
							</div>
						{:else if info.title === 'times_of_day' && info.iconSrc}
							<div class="time-of-day-svg-container">
								<img
									width="48px"
									height="48px"
									src={info.iconSrc}
									alt="{info.value} icon" />
							</div>
						{:else if info.title === 'season'}
							<div class="seasons-svg-container">
								<img
									width="48px"
									height="48px"
									src={info.iconSrc}
									alt="{info.value} icon" />
							</div>
						{:else if info.iconSrc}
							<div class="svg-container">
								<img
									width="48px"
									height="48px"
									src={info.iconSrc}
									alt="icon" />
							</div>
						{/if}
						<span class="flex-column">
							<h1 class="title">{$_(info.title)}</h1>
							<h2 class="value">{info.value}</h2>
							{#if info.description}
								{#if info.description.length > 20 && !info.showAll}
									<span class="info-description">{$_(info.shortMessage)}</span>
									<button
										class="show-more-btn"
										on:click={() => {
											showMore(index)
										}}>{$_('show_more')}</button>
								{:else if info.description.length > 0 && info.showAll}
									<span class="info-description">{$_(info.description)}</span>
									<button
										class="show-more-btn"
										on:click={() => {
											showMore(index)
										}}>{$_('show_less')}</button>
								{:else}
									<span class="info-description">{$_(info.description)}</span>
								{/if}
							{/if}
						</span>
					</div>
				</div>
			{/if}
		{/each}
	</div>
</section>

<style lang="scss">
@use '../../../styles/setup/mixins';

.dog-info {
	@include mixins.card;

	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.content {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(290px, auto));

		.info {
			padding-inline: 10px;
			margin-bottom: 30px;
			min-width: 240px;

			&:not(.dog-name) {
				gap: 16px;
			}

			.dog-img-container,
			.svg-container {
				width: 48px;
				height: 48px;
				border-radius: 1000px;
				overflow: hidden;

				img {
					filter: none;
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			.trainings-icon {
				border-radius: 1000px;
			}

			.description {
				align-items: center;
				gap: 12px;

				.show-more-btn {
					font-size: 0.8rem;
				}

				.info-description {
					max-width: 150px;
					max-height: 100px;
					word-wrap: break-word;
					font-size: 0.8rem;
					overflow: auto;
				}

				.training-img-container {
					background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 1000px;
					width: 48px;
					height: 48px;
					aspect-ratio: 1/1;

					img {
						filter: none;
						width: 34px;
						height: 34px;
					}
				}

				.time-of-day-svg-container {
					img {
						height: 54px !important;
					}
				}

				.title {
					font-size: 14px;
					font-family: Nunito;
					font-weight: normal;
					text-transform: capitalize;
				}

				.value {
					font-size: 18px;
					font-weight: 500;
					white-space: nowrap;
				}
			}
		}
	}
	.handler-image {
		max-width: 48px;
		aspect-ratio: 1;
		border-radius: 50%;
		object-fit: cover;
	}

	@media only screen and (max-width: 768px) {
		.content {
			justify-content: center;
			grid-template-columns: repeat(auto-fit, 200px);
			align-items: center;
			justify-content: flex-start;
			padding-inline-start: 18px;

			.value {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 230px;
			}
		}
	}
}
</style>
