<script lang="ts">
import { useNavigate } from 'svelte-navigator'
import '../../styles/themes/_globals.scss'
import SvgIcon from '../common/SvgIcon.svelte'
import type { SvgIconName } from '../../models/common.model'
import moment from 'moment'
import NameInitials from '../common/NameInitials.svelte'
import { _ } from 'svelte-i18n'
import type { SearchTrainingModel, TrainingModel } from '../../models/training.model'
import NoResultAnimation from '../common/animation/NoResultAnimation.svelte'
import RiDesignPencilLine from 'svelte-icons-pack/ri/RiDesignPencilLine'
import AiOutlineEye from 'svelte-icons-pack/ai/AiOutlineEye'
import HiOutlineSparkles from 'svelte-icons-pack/hi/HiOutlineSparkles'
import Icon from 'svelte-icons-pack'
import { loggedInUser } from '../../store/stores'
import TrainingEvent from './training/TrainingEvent.svelte'
import Modal from '../common/modal/AISummary.svelte'
import { fireMsg } from '../../utils/userMsg.service'

export let trainings: SearchTrainingModel[]
export let disabled: boolean

const navigate = useNavigate()

const teamIcons: SvgIconName[] = [
	'add-general-person',
	'add-general-team',
	'add-police-person',
	'add-team',
	'dog-alert',
	'dog-ball',
	'dog-scent',
	'fire-person',
	'fire-team',
	'general-dog',
	'general-team',
	'military-person',
	'military-team',
	'police-person',
	'police-team',
	'remove-team',
	'rescue-dog',
	'rescue-person',
	'rescue-team',
	'running-dog'
]

function getProperTime(date: Date) {
	return moment().startOf('day').diff(moment(date), 'days') > 11
		? moment(date).format('MMMM D[,] YYYY')
		: moment(date).fromNow()
}

let title = $_('ai-summary')

// AI Summary Modal Logic
let summaryTraining: SearchTrainingModel = null
</script>

{#if trainings && trainings.length === 0}
	<div class="flex-column align-center no-dogs-animation">
		<NoResultAnimation />
	</div>
{:else if trainings && trainings.length > 0}
	{#if summaryTraining && summaryTraining.summary}
		<Modal
			{summaryTraining}
			on:close={() => (summaryTraining = null)} />
	{/if}
	<section class="flex-column training-list">
		<header class="header-container">
			<ul class="header">
				<li class="type">{$_('type')}</li>
				<li class="team-name">{$_('team_name')}</li>
				<li class="handler">{$_('training_by')}</li>
				<li class="dog">{$_('dog')}</li>
				<li class="training-time">{$_('training_time')}</li>
				<li class="date">{$_('date')}</li>
				<li class="event">{$_('event')}</li>
				<li class="action">{$_('action')}</li>
			</ul>
		</header>
		<div class="flex-column content">
			{#each trainings as training}
				<button
					class="training-preview-container"
					on:click={() => navigate(training.id, { state: { pageName: $_('training_details') } })}>
					<ul class="training-preview">
						<li class="flex training-name">
							<div class="training-img-container">
								{#if training.iconUrl}
									<img
										class="type-icon"
										src={training.iconUrl}
										alt="training profile"
										width="40px"
										height="40px" />
								{/if}
							</div>
							<div class="training-type flex">
								<p>
									{training.type}
								</p>
							</div>
						</li>
						<li class="team-name">
							<SvgIcon
								name={teamIcons.filter((icon) => icon == training.team.icon)[0]}
								width="40px" />
							{training.team.name}
						</li>
						<li class="handler">
							<div class="initials-container">
								<NameInitials
									name={training.trainedBy.name}
									theme="secondary"
									fontSize={18} />
							</div>
							{training.trainedBy.name || 'Without trainer'}
						</li>
						<li
							class="dog"
							title={training.dog.name}>
							<img
								class="dog-image"
								src={training.dog.image}
								alt="dog"
								width="40px"
								height="40px" />
							{training.dog.name}
						</li>
						<li class="training-time">{training.trainingTime + ' min'}</li>
						<li class="date">
							{#if typeof training.dateTime == 'object'}{getProperTime(training.dateTime.toDate())}
							{:else}
								{getProperTime(new Date(training.dateTime * 1000))}
							{/if}
						</li>
						{#if training.event}
							<li class="event">
								<TrainingEvent event={training.event} />
							</li>
						{:else if training.category}
							<li class="event">
								<TrainingEvent event={training.category} />
							</li>
						{:else}
							<li class="event"><TrainingEvent event={'training'} /></li>
						{/if}
						<li class="action">
							{#if !training.summary}
								<button
									on:click|stopPropagation={() => {
										fireMsg({
											type: 'failure',
											msg: $_('old_training')
										})
									}}
									class="btn btn-svg btn-edit"
									title={$_('ai_summary')}>
									<Icon src={HiOutlineSparkles} />
								</button>
							{:else}
								<button
									on:click|stopPropagation={() => {
										summaryTraining = training
									}}
									class="btn btn-svg btn-edit"
									title={$_('ai_summary')}>
									<Icon src={HiOutlineSparkles} />
								</button>
							{/if}

							{#if !disabled}
								{#if training.createdAt.seconds ? training.createdAt.seconds > new Date('2023-10-13T13:40:00').getTime() / 1000 : Number(training.createdAt) > new Date('2023-10-13T13:30:00').getTime() / 1000 && ($loggedInUser.id == training.trainedBy.id || $loggedInUser.role == 'Admin')}
									<button
										class="btn btn-svg btn-edit"
										title={$_('edit')}
										on:click|stopPropagation={() =>
											navigate(`/trainings/edit/${training.id}`, {
												state: { pageName: $_('edit_training') }
											})}>
										<Icon src={RiDesignPencilLine} />
									</button>
								{/if}
							{/if}
							<button
								on:click={() =>
									navigate(training.id, { state: { pageName: $_('training_details') } })}
								class="btn btn-svg btn-edit"
								title={$_('details')}>
								<Icon src={AiOutlineEye} />
							</button>
						</li>
					</ul>
				</button>
			{/each}
		</div>
	</section>
{/if}

<style lang="scss">
@use '../../styles/setup/mixins';

.no-training-animation {
	margin-inline: auto;
}
.training-list-header-algolia {
	display: none;
}
.training-list,
.training-list-header-algolia {
	@include mixins.card;
	overflow-x: auto;

	.header,
	.training-preview {
		display: grid;
		grid-template-columns:
			minmax(100px, 1fr) minmax(100px, 0.9fr) minmax(100px, 1fr) minmax(100px, 0.8fr)
			minmax(80px, 0.7fr) minmax(70px, 0.7fr) minmax(90px, 0.5fr) minmax(100px, 0.8fr);
		min-width: fit-content;
		column-gap: 8px;
		overflow: hidden;

		> li {
			display: flex;
			align-items: center;
			gap: 8px;

			.initials-container {
				width: 40px;
				aspect-ratio: 1;
				flex-shrink: 0;
			}
		}

		.training-name,
		.handler,
		.team-name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			gap: 12px;

			.training-type {
				p {
					max-width: 110px;
					white-space: nowrap;
					position: relative;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}

		.event {
			justify-content: center;
		}

		.type {
			white-space: normal;
			position: relative;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.action {
			justify-content: center;
			margin-right: 12px;
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}

				:global(svg) {
					font-size: 18px;
				}
			}
		}
	}
	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);

		.action {
			margin-right: 2rem;
		}
	}

	.content {
		position: relative;
		font-size: 14px;
	}
	.training-preview {
		border-top: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 14px;
		transition: background-color 0.15s ease-out;
		img {
			object-fit: cover;
			aspect-ratio: 1;
		}
		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}
	}

	@media (max-width: 1350px) {
		.header {
			display: none;
		}
		.header,
		.training-preview {
			grid-template-columns: minmax(200px, 2fr) minmax(90px, 1fr);
		}
		.training-time,
		.handler,
		.dog,
		.team-name,
		.action,
		.event {
			display: none !important;
		}

		.date {
			color: var(--clr-txt-dark-secondary);
			white-space: nowrap;
			justify-content: flex-end;
			font-size: 11px;
		}

		.training-name {
			width: 200px;
		}
	}

	.hits-container {
		position: relative;
	}

	.training-img-container {
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1000px;
		width: 40px;
		height: 40px;
		aspect-ratio: 1/1;

		img {
			filter: none;
			width: 27px;
			height: 27px;
		}
	}
}
</style>
