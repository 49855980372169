<svg
	width="20"
	height="20"
	viewBox="0 0 20 20"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M1.875 10C1.875 5.51316 5.51316 1.875 10 1.875C14.4868 1.875 18.125 5.51316 18.125 10C18.125 14.4868 14.4868 18.125 10 18.125C5.51316 18.125 1.875 14.4868 1.875 10ZM10 3.125C6.20351 3.125 3.125 6.20351 3.125 10C3.125 13.7965 6.20351 16.875 10 16.875C13.7965 16.875 16.875 13.7965 16.875 10C16.875 6.20351 13.7965 3.125 10 3.125Z"
		fill="#FE9803" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M8.27134 2.63671C8.75102 2.1802 9.33624 1.875 9.99999 1.875C10.6637 1.875 11.249 2.1802 11.7286 2.63671C12.2051 3.09013 12.6057 3.71669 12.9284 4.44308C13.5747 5.89742 13.9583 7.86269 13.9583 10C13.9583 12.1373 13.5747 14.1026 12.9284 15.5569C12.6057 16.2833 12.2051 16.9099 11.7286 17.3633C11.249 17.8198 10.6637 18.125 9.99999 18.125C9.33624 18.125 8.75102 17.8198 8.27134 17.3633C7.79489 16.9099 7.39432 16.2833 7.07155 15.5569C6.4253 14.1026 6.04166 12.1373 6.04166 10C6.04166 7.86269 6.4253 5.89742 7.07155 4.44308C7.39432 3.71669 7.79489 3.09013 8.27134 2.63671ZM8.21385 4.95067C7.65385 6.21091 7.29166 7.99565 7.29166 10C7.29166 12.0044 7.65385 13.7891 8.21385 15.0493C8.4942 15.6802 8.81186 16.1521 9.13307 16.4578C9.45105 16.7604 9.7429 16.875 9.99999 16.875C10.2571 16.875 10.5489 16.7604 10.8669 16.4578C11.1881 16.1521 11.5058 15.6802 11.7861 15.0493C12.3461 13.7891 12.7083 12.0044 12.7083 10C12.7083 7.99565 12.3461 6.21091 11.7861 4.95067C11.5058 4.31976 11.1881 3.84789 10.8669 3.5422C10.5489 3.23959 10.2571 3.125 9.99999 3.125C9.7429 3.125 9.45105 3.23959 9.13307 3.5422C8.81186 3.84789 8.4942 4.31976 8.21385 4.95067Z"
		fill="black" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M17.6136 10.2646C12.5802 11.1951 7.41983 11.1951 2.38638 10.2646L2.61362 9.0354C7.49685 9.93818 12.5032 9.93818 17.3864 9.0354L17.6136 10.2646Z"
		fill="#FE9803" />
</svg>
