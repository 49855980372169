import { get } from 'svelte/store';
import { loggedInUser } from '../../store/stores'
import { currAccount } from '../../store/stores'

export const beaconService = {
	initBeacon,
	identifyBeacon,
  navigateBeacon,
  prefillBeacon,
  openBeacon
}

function initBeacon(){
  window.Beacon('init', 'e4138cd3-32fd-48d6-8e8b-27a806a9e11b')
}

function identifyBeacon(){
	const name = get(loggedInUser).firstName + ' ' + get(loggedInUser).lastName;
	window.Beacon('identify', {
		name: name,
		email: get(loggedInUser).email,
    phone: get(currAccount).phoneNumber,
		address: get(currAccount).location.address,
		'account-name': get(currAccount).name,
		'first-name': get(loggedInUser).firstName,
		'last-name': get(loggedInUser).lastName,
		'account-url': get(currAccount).website,
		'dogs-count':get(currAccount).dogIds.length,
		'account-subscription':get(currAccount).subscription,
		'training-sessions': get(currAccount).stats.totalTrainingSessions,
		'total-account-users': get(currAccount).userIds.length
	})
}

function navigateBeacon(rout:string) {
  window.Beacon('navigate', rout)
}

function prefillBeacon(subject:string, text:string) {
  window.Beacon('prefill', {
		subject: subject,
		text: text,
	})
}

function openBeacon() {
  window.Beacon('open')
}




