<script lang="ts">
import Icon from 'svelte-icons-pack'
import AiOutlineSearch from 'svelte-icons-pack/ai/AiOutlineSearch'
import { _ } from 'svelte-i18n'

export let placeholder = $_('search')
export let searchTerm = ''
</script>

<div class="flex align-center search-bar">
	<input
		class="input"
		type="text"
		{placeholder}
		bind:value={searchTerm} />
	<button
		type="submit"
		class="search-submit-button">
		<div class="svg-container">
			<Icon
				src={AiOutlineSearch}
				color="#6E6B7B" />
		</div>
	</button>
</div>

<style lang="scss">
.search-bar {
	width: 100%;
	flex: 3;
	min-width: 216px;
	gap: 6px;
	height: 48px;
	background-color: #fafafd;
	border: 1px solid #d8dae5;
	border-radius: 6px;
	padding: 8px;

	::placeholder {
		color: var(--clr-txt-dark-secondary);
	}
	.svg-container {
		margin-inline: 8px;
		width: 85%;
		height: 85%;
	}

	.input {
		background-color: transparent;
		outline: none;
		border: none;
		width: 100%;
		font-size: 14px;

		&:focus {
			box-shadow: none;
		}
	}

	.search-submit-button {
		margin-right: 8px;
		padding-left: 4px;
		border-left: 1px #8f95b27b solid;
		max-width: 34px;
		height: 100%;
	}
}
</style>
