import {
	collection,
	doc,
	DocumentReference,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
	orderBy,
	limit,
	type DocumentData
} from 'firebase/firestore'
import { Firestore } from '../firebase/firebase'
import type { DogModel, DogStatus } from '../../models/dog.model'
import type { QuerySnapshot, Query } from 'firebase/firestore'

export const dogDB = {
	getMany,
	get,
	add,
	update,
	getDocsQuery,
	getDogRef,
	newDogRef,
	loadPage
}

async function getMany(
	accountId: string,
	itemsPerPage: number,
	filterBy?: Partial<{ status: DogStatus[]; dogIds: string[]; userId: string }>
): Promise<QuerySnapshot<DocumentData>> {
	const queryParams = getDocsQuery(accountId, itemsPerPage, filterBy)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

async function get(dogId: string): Promise<DocumentData> {
	const dogDocSnap = await getDoc(_getDocRef(dogId))
	if (dogDocSnap.exists()) return dogDocSnap.data()
	else throw new Error('Dog not found')
}

async function add(newDog: DogModel) {
	const newDocRef = _getNewDocRef()
	newDog.id = newDocRef.id
	return await setDoc(newDocRef, newDog)
}

async function update(dogId: string, editedDog: Partial<DogModel>) {
	if (!dogId) throw new Error('Dog ID is required')
	const docRef = await updateDoc(_getDocRef(dogId), editedDog)
	return docRef
}

function getDocsQuery(
	accountId: string,
	itemsPerPage: number,
	filterBy?: Partial<{ status: DogStatus[]; dogIds: string[]; userId: string }>
): Query<DocumentData> {
	const queryConstraints = []
	if (filterBy) {
		const { status, dogIds, userId } = filterBy
		if (status && status.length > 0)
			queryConstraints.push(where('status', 'array-contains', status))
		if (dogIds) queryConstraints.push(where('id', 'array-contains', dogIds))
		if (userId) queryConstraints.push(where('handler.id', '==', userId))
	}
	return query(
		collection(Firestore, 'dogs'),
		where('accountId', '==', accountId),
		orderBy('name'),
		limit(itemsPerPage || 1),
		...queryConstraints
	)
}

function loadPage(accountId: string, itemsPerPage: number): Query<DocumentData> {
	return query(
		collection(Firestore, 'dogs'),
		where('accountId', '==', accountId),
		orderBy('name'),
		limit(itemsPerPage || 1)
	)
}

function getDogRef(documentId: string): DocumentReference<DocumentData> {
	return _getDocRef(documentId)
}

function newDogRef(): DocumentReference<DocumentData> {
	return _getNewDocRef()
}

function _getDocRef(documentId: string): DocumentReference<DocumentData> {
	return doc(Firestore, 'dogs', documentId)
}

function _getNewDocRef(): DocumentReference<DocumentData> {
	return doc(collection(Firestore, 'dogs'))
}
