interface FirebaseConfig {
  apiKey: string,
  authDomain: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string
}
const firebaseConfig: FirebaseConfig = (import.meta.env?.PROD) ?
  {
    apiKey: "AIzaSyCKba5X7ZzGHrqAds-DBTJcXGizUxkxKss",
    authDomain: "dogbase-app-production.firebaseapp.com",
    projectId: "dogbase-app-production",
    storageBucket: "dogbase-app-production.appspot.com",
    messagingSenderId: "284121690940",
    appId: "1:284121690940:web:0c76f4e47643c235a12439",
    measurementId: "G-NSD9YXFXZX"
  } :
  {
    apiKey: "AIzaSyCAJyUG0rfbHb4JB2rG7ebkp2SyXRYW8xE",
    authDomain: "dogbase-app-staging.firebaseapp.com",
    projectId: "dogbase-app-staging",
    storageBucket: "dogbase-app-staging.appspot.com",
    messagingSenderId: "777189622170",
    appId: "1:777189622170:web:41c4c86a8bc0bb50887a23",
    measurementId: "G-827HD2EBXT"
  }

export { firebaseConfig }