<script lang="ts">
export let rating = 0
let icons = [
	'../../../assets/smiles/Smile1.svg',
	'../../../assets/smiles/Smile2.svg',
	'../../../assets/smiles/Smile3.svg',
	'../../../assets/smiles/Smile4.svg',
	'../../../assets/smiles/Smile5.svg'
]

function handleRating(index: number) {
	rating = index + 1
}
</script>

<div class="rating-container">
	{#each icons as icon, index}
		<img
			src={icon}
			class:rating-image={rating !== index + 1}
			class:selected-rating-image={rating === index + 1}
			alt={`Rating ${index + 1}`}
			on:click={() => handleRating(index)}
			on:keydown />
	{/each}
</div>

<style>
.rating-container {
	display: flex;
	align-items: center;
	gap: 8px;
	height: 70px;
}

.rating-image {
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.selected-rating-image {
	width: 45px;
	height: 45px;
}
</style>
