<script lang="ts">
import Toggle from './Toggle.svelte'
import DistanceSlider from './DistanceSlider.svelte'
import Slider from './Slider.svelte'
import { toggleFitness } from '../../../store/trainingWidgetsStore'

export let values: {
	defaultSliderValue: number
	divisionDistance: number
	divisionIncline: number
	maxDistanceMeters: number
	maxDistanceFeet: number
	minDistanceMeters: number
	minDistanceFeet: number
	inclineLabel: string
	maxIncline: number
	minIncline: number
	selectedIndex: number
	value: {
		selectedIndex: number
		distance: number
		incline: number
		label: 'meters' | 'feet'
	}
	filled: boolean
}
export let data = []
export let id: string = null

const labels = ['slatMill', 'eMill', 'eBike', 'Run', 'Bike', 'Hiking']
let unitsOfMeasurement: string

localStorage.getItem('measurement-type')
	? (unitsOfMeasurement = localStorage.getItem('measurement-type'))
	: (unitsOfMeasurement = 'metric')

let toggleValues: any

values.defaultSliderValue = 0

if (!$toggleFitness[id]) {
	$toggleFitness[id] = {
		defaultSliderValue: values.defaultSliderValue,
		divisionDistance: values.divisionDistance,
		divisionIncline: values.divisionIncline,
		maxDistanceMeters: values.maxDistanceMeters,
		maxDistanceFeet: values.maxDistanceFeet,
		minDistanceMeters: values.minDistanceMeters,
		minDistanceFeet: values.minDistanceFeet,
		inclineLabel: values.inclineLabel,
		maxIncline: values.maxIncline,
		minIncline: values.minIncline,
		selectedIndex: values.selectedIndex,
		value: {
			selectedIndex: values.selectedIndex,
			distance: 0,
			incline: null,
			label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
		},
		filled: false
	}
}

let distanceSliderValues: any
let inclineSliderValues: any

if (data.length > 0 && $toggleFitness[id].value.distance == 0) {
	distanceSliderValues = {
		minMeters: values.minDistanceMeters,
		minFeet: values.minDistanceFeet,
		maxMeters: values.maxDistanceMeters,
		maxFeet: values.maxDistanceFeet,
		divisions: values.divisionDistance,
		defaultSliderValue: data[0].value.distance,
		value: {
			distance: data[0].value.distance,
			label: data[0].value.label
		}
	}
} else if ($toggleFitness[id].value.distance) {
	distanceSliderValues = {
		minMeters: $toggleFitness[id].minDistanceMeters,
		minFeet: $toggleFitness[id].minDistanceFeet,
		maxMeters: $toggleFitness[id].maxDistanceMeters,
		maxFeet: $toggleFitness[id].maxDistanceFeet,
		divisions: $toggleFitness[id].divisionDistance,
		defaultSliderValue: $toggleFitness[id].value.distance,
		value: {
			distance: $toggleFitness[id].value.distance,
			label: $toggleFitness[id].value.label
		}
	}
} else {
	distanceSliderValues = {
		minMeters: values.minDistanceMeters,
		minFeet: values.minDistanceFeet,
		maxMeters: values.maxDistanceMeters,
		maxFeet: values.maxDistanceFeet,
		divisions: values.divisionDistance,
		defaultSliderValue: values.defaultSliderValue,
		value: {
			distance: 0,
			label: 'meters'
		}
	}
}

if (data.length > 0 && $toggleFitness[id].value.incline == null) {
	inclineSliderValues = {
		min: values.minIncline,
		max: values.maxIncline,
		divisions: values.divisionIncline,
		defaultSliderValue: data[0].value.incline,
		label: values.inclineLabel,
		value: {
			selectedValue: data[0].value.incline,
			label: values.inclineLabel
		}
	}
} else if ($toggleFitness[id].value.incline) {
	inclineSliderValues = {
		min: values.minIncline,
		max: values.maxIncline,
		divisions: values.divisionIncline,
		label: values.inclineLabel,
		defaultSliderValue: $toggleFitness[id].value.incline,
		value: {
			selectedValue: $toggleFitness[id].value.incline,
			label: values.inclineLabel
		}
	}
} else {
	inclineSliderValues = {
		min: values.minIncline,
		max: values.maxIncline,
		divisions: values.divisionIncline,
		defaultSliderValue: 0,
		label: values.inclineLabel,
		value: {
			selectedValue: 0,
			label: values.inclineLabel
		}
	}
}

if (data.length > 0 && $toggleFitness[id].value.distance == 0) {
	toggleValues = {
		labels: labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
} else if ($toggleFitness[id]) {
	toggleValues = {
		labels: labels,
		selectedIndex: $toggleFitness[id].value.selectedIndex,
		value: $toggleFitness[id].value.selectedIndex
	}
} else {
	toggleValues = {
		labels: labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
}

$: {
	if (toggleValues.selectedIndex === 0) {
		values.value = {
			selectedIndex: toggleValues.selectedIndex,
			distance: 0,
			incline: null,
			label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
		}
	} else if (toggleValues.selectedIndex === 4) {
		values.value = {
			selectedIndex: toggleValues.selectedIndex,
			distance: 0,
			incline: null,
			label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
		}
	} else if (toggleValues.selectedIndex === 1) {
		values.value = {
			selectedIndex: toggleValues.selectedIndex,
			distance: distanceSliderValues.value.distance,
			incline: inclineSliderValues.value.selectedValue,
			label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
		}
	} else {
		values.value = {
			selectedIndex: toggleValues.selectedIndex,
			distance: distanceSliderValues.value.distance,
			incline: null,
			label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
		}
	}
	$toggleFitness[id] = values
}
</script>

<Toggle bind:values={toggleValues} />

{#if toggleValues.selectedIndex === 1}
	<DistanceSlider bind:values={distanceSliderValues} />
	<Slider bind:values={inclineSliderValues} />
{:else if toggleValues.selectedIndex === 2}
	<DistanceSlider bind:values={distanceSliderValues} />
{:else if toggleValues.selectedIndex === 3}
	<DistanceSlider bind:values={distanceSliderValues} />
{:else if toggleValues.selectedIndex === 5}
	<DistanceSlider bind:values={distanceSliderValues} />
{/if}
