<svg
	width="20"
	height="20"
	viewBox="0 0 20 20"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_0_377)">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.82308 3.15874L3.392 6.76016C3.26023 6.83628 3.18946 6.90856 3.15468 6.95824C3.12173 7.0053 3.12083 7.03104 3.12083 7.03751C3.12083 7.04397 3.12173 7.06971 3.15468 7.11678C3.18946 7.16645 3.26023 7.23873 3.392 7.31485L9.82308 10.9163C9.87345 10.9373 9.93439 10.95 9.99999 10.95C10.0656 10.95 10.1265 10.9373 10.1769 10.9163L16.608 7.31485C16.7398 7.23873 16.8105 7.16645 16.8453 7.11678C16.8783 7.06971 16.8791 7.04397 16.8791 7.03751C16.8791 7.03104 16.8783 7.0053 16.8453 6.95824C16.8105 6.90856 16.7398 6.83628 16.608 6.76016L10.1769 3.15874C10.1265 3.13774 10.0656 3.125 9.99999 3.125C9.93439 3.125 9.87345 3.13774 9.82308 3.15874ZM9.27555 2.03448C9.50577 1.92614 9.75571 1.875 9.99999 1.875C10.2443 1.875 10.4942 1.92614 10.7244 2.03448L10.7444 2.04389L17.228 5.67471C17.7365 5.96682 18.1291 6.43912 18.1291 7.03751C18.1291 7.63589 17.7365 8.10816 17.228 8.40027L17.222 8.40368L10.7444 12.0311L10.7244 12.0405C10.4942 12.1489 10.2443 12.2 9.99999 12.2C9.75571 12.2 9.50577 12.1489 9.27555 12.0405L9.25556 12.0311L2.77199 8.4003C2.2635 8.10819 1.87083 7.63589 1.87083 7.03751C1.87083 6.43912 2.26352 5.96685 2.77201 5.67474L2.77794 5.67134L9.25556 2.04389L9.27555 2.03448Z"
			fill="black" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.0372 11.0962C15.2049 10.7945 15.5855 10.686 15.8872 10.8538L17.228 11.5997C17.7365 11.8918 18.1291 12.3641 18.1291 12.9625C18.1291 13.5609 17.7365 14.0332 17.228 14.3253L17.222 14.3287L10.7444 17.9561L10.7244 17.9655C10.4942 18.0739 10.2443 18.125 9.99999 18.125C9.75571 18.125 9.50577 18.0739 9.27555 17.9655L9.25556 17.9561L2.77199 14.3253C2.2635 14.0332 1.87083 13.5609 1.87083 12.9625C1.87083 12.3641 2.26352 11.8919 2.77201 11.5998L2.77803 11.5963L4.10302 10.8546C4.40422 10.686 4.78507 10.7935 4.95368 11.0947C5.12228 11.3959 5.01479 11.7768 4.71359 11.9454L3.39195 12.6852C3.26021 12.7613 3.18946 12.8336 3.15468 12.8832C3.12173 12.9303 3.12083 12.956 3.12083 12.9625C3.12083 12.969 3.12173 12.9947 3.15468 13.0418C3.18946 13.0915 3.26022 13.1637 3.39198 13.2399L9.82307 16.8413C9.87345 16.8623 9.93439 16.875 9.99999 16.875C10.0656 16.875 10.1265 16.8623 10.1769 16.8413L16.608 13.2399C16.7398 13.1637 16.8105 13.0915 16.8453 13.0418C16.8783 12.9947 16.8791 12.969 16.8791 12.9625C16.8791 12.956 16.8783 12.9303 16.8453 12.8832C16.8106 12.8337 16.74 12.7615 16.6087 12.6856L15.2795 11.9462C14.9779 11.7784 14.8694 11.3978 15.0372 11.0962Z"
			fill="#FE9803" />
	</g>
	<defs>
		<clipPath id="clip0_0_377">
			<rect
				width="20"
				height="20"
				fill="white" />
		</clipPath>
	</defs>
</svg>
