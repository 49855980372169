import {
	getAuth,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	updateEmail,
	reauthenticateWithCredential,
	updatePassword,
	EmailAuthProvider,
	onAuthStateChanged,
	signOut
} from 'firebase/auth'
import { user,  user as currUser } from '../../store/stores'
import { Auth } from '../firebase/firebase'
import type { AccountModel } from '../../models/account.model'
import { fireMsg, userMsg } from '../../utils/userMsg.service'
import { accountService } from './account.service'
import { paddleService} from '../APIs/paddle.service'
import { userService } from './user.service'
//import { user as currUser } from '../../store/stores'

export let sessionKeys: { algoliaKey: string; helpScoutSignature: string; signedNoltToken: string }

interface LoginCredentials {
	email: string
	password: string
}
interface SignupCredentials extends LoginCredentials {
	accountName: string
	firstName: string
	lastName: string
}

export const authService = {
	login,
	signup,
	sendEmailVerify,
	changeEmail,
	changePassword,
	resetPassword,
	logout
}

const auth = getAuth();

async function login(credentials: LoginCredentials): Promise<void> {
	const { email, password } = credentials
	try {
		await signInWithEmailAndPassword(Auth, email, password)
	} catch (error: any) {
		fireMsg({ type: 'failure', msg: error.message })
		throw new Error('Login error:', error)
	}
}

async function signup(credentials: SignupCredentials, userId: string): Promise<AccountModel> {
	const { accountName } = credentials
	for (const field in credentials) {
		credentials[field] = credentials[field].trim()
	}
	try {
		return await accountService.create({ accountName: accountName, userId: userId })
	} catch (error: any) {
		fireMsg({ type: 'failure', msg: error.message })
		throw new Error('Signup error:', error)
	}
}

async function sendEmailVerify(): Promise<void> {
	const auth = getAuth()
	if (auth.currentUser && !auth.currentUser.emailVerified) {
		try {
			await sendEmailVerification(auth.currentUser)
		} catch (error: any) {
			fireMsg({ type: 'failure', msg: error.message })
			throw new Error('Email verification error:', error)
		}
	}
}

async function changeEmail(currentPassword: string, newEmail: string): Promise<void> {
	const auth = getAuth()
	try {
		const user = auth.currentUser
		const credential = EmailAuthProvider.credential(user.email, currentPassword)

		const { user: authenticatedUser } = await reauthenticateWithCredential(user, credential)

		if (authenticatedUser) {
			try {
				await updateEmail(user, newEmail)
				await sendEmailVerification(user)
				userMsg.successMsg('Email verification sent, please check your inbox')
				await reauthenticateWithCredential(
					user,
					EmailAuthProvider.credential(newEmail, currentPassword)
				)
				if (user.emailVerified) userMsg.successMsg('Email changed successfully')
			} catch (error: unknown) {
				console.error('Error sending verification email:', error)
			}
		}
	} catch (error: any) {
		fireMsg({ type: 'failure', msg: error.message })
		throw new Error('Email verification error:', error)
	}
}

async function changePassword(currentPassword: string, newPassword: string): Promise<void> {
	const auth = getAuth()
	try {
		const user = auth.currentUser
		const credential = EmailAuthProvider.credential(user.email, currentPassword)

		const { user: authenticatedUser } = await reauthenticateWithCredential(user, credential)

		if (authenticatedUser) {
			try {
				await updatePassword(user, newPassword)
				userMsg.successMsg('Password changed successfully')
			} catch (error: any) {
				fireMsg({ type: 'failure', msg: error.message })
			}
		}
	} catch (error: any) {
		fireMsg({ type: 'failure', msg: error.message })
	}
}

async function resetPassword(email: string): Promise<void> {
	const auth = getAuth()
	try {
		await sendPasswordResetEmail(auth, email)
	} catch (error: any) {
		fireMsg({ type: 'failure', msg: error.message })
		throw new Error('Reset password error:', error)
	}
}

async function logout(): Promise<void> {
	try {
		indexedDB.deleteDatabase('firebaseLocalStorageDb')
		indexedDB.deleteDatabase('firebase-heartbeat-database')
		localStorage.removeItem('training')
		localStorage.removeItem('tourShown')
		localStorage.removeItem('teamTourShown')
		await signOut(Auth)
		user.set(null)
	} catch (error: any) {
		fireMsg({ type: 'failure', msg: error.message })
		throw new Error('Logout error:', error)
	}
}

onAuthStateChanged(auth, (user) => {
	//alert('onAuthStateChanged');
	console.log('onAuthStateChanged', user);
 if (user) {
	user.emailVerified ? userService.storeUser(user) : currUser.set(user);
	if (user.email) {
    // User is signed in, update paddle with user email for paddle retain
		paddleService.updatePaddle(user.email);
  }
 }
});
