<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#FBD8D9" />
	<g clip-path="url(#clip0_354_13334)">
		<path
			d="M17.8972 20.7352C17.7488 20.3744 17.401 20.1414 17.0108 20.1414C17.0106 20.1414 17.0102 20.1414 17.0099 20.1414C16.6194 20.1417 16.2716 20.3755 16.1237 20.7369C16.1211 20.7431 16.1187 20.7493 16.1163 20.7556L13.8274 26.7655C13.6661 27.1889 13.8786 27.6628 14.302 27.824C14.7257 27.9854 15.1993 27.7727 15.3605 27.3494L15.7294 26.3809H18.2747L18.6393 27.347C18.7633 27.6755 19.0754 27.8779 19.4069 27.8779C19.5031 27.8779 19.6011 27.8609 19.6964 27.8249C20.1203 27.6649 20.3342 27.1917 20.1742 26.7678L17.9061 20.7575C17.9033 20.7501 17.9003 20.7426 17.8972 20.7352ZM16.3543 24.7404L17.0079 23.0242L17.6555 24.7404H16.3543Z"
			fill="#EF4F50" />
		<path
			d="M33.4064 21.8098C33.8595 21.8098 34.2267 21.4425 34.2267 20.9895C34.2267 20.5365 33.8595 20.1692 33.4064 20.1692H30.8613C30.4083 20.1692 30.041 20.5365 30.041 20.9895V27.0297C30.041 27.4827 30.4083 27.85 30.8613 27.85H33.4064C33.8595 27.85 34.2267 27.4827 34.2267 27.0297C34.2267 26.5766 33.8595 26.2094 33.4064 26.2094H31.6816V24.8299H33.2187C33.6718 24.8299 34.0391 24.4626 34.0391 24.0096C34.0391 23.5565 33.6718 23.1893 33.2187 23.1893H31.6816V21.8098H33.4064Z"
			fill="#EF4F50" />
		<path
			d="M24.0009 22.0082C24.3055 21.8513 24.6512 21.7627 25.017 21.7627C25.4633 21.7627 25.8937 21.8945 26.2617 22.1428C26.6374 22.3965 27.1471 22.2975 27.4009 21.9224C27.6546 21.5467 27.5556 21.037 27.1805 20.7832C26.5401 20.3507 25.792 20.1221 25.017 20.1221C24.6654 20.1221 24.3247 20.1691 24.0009 20.2577C22.3592 20.705 21.1484 22.2089 21.1484 23.9907C21.1484 25.7718 22.3592 27.2757 24.0009 27.7231C24.3247 27.8117 24.6654 27.8587 25.017 27.8587C27.0492 27.8587 28.4684 26.2679 28.4684 23.9907C28.4684 23.5373 28.1009 23.1704 27.648 23.1704H25.8242C25.3714 23.1704 25.0039 23.5373 25.0039 23.9907C25.0039 24.4435 25.3714 24.811 25.8242 24.811H26.7227C26.5193 25.5657 26.0025 26.2181 25.017 26.2181C24.6512 26.2181 24.3055 26.1295 24.0009 25.9725C23.2818 25.6029 22.7891 24.8531 22.7891 23.9907C22.7891 23.1282 23.2818 22.3785 24.0009 22.0082Z"
			fill="#EF4F50" />
		<path
			d="M37.1797 23.1797C36.7269 23.1797 36.3594 23.5472 36.3594 24C36.3594 27.3015 35.0737 30.405 32.7396 32.7396C30.405 35.0737 27.3015 36.3594 24 36.3594C20.6985 36.3594 17.595 35.0737 15.2604 32.7396C12.9263 30.405 11.6406 27.3015 11.6406 24C11.6406 20.6985 12.9263 17.595 15.2604 15.2604C17.595 12.9263 20.6985 11.6406 24 11.6406C25.6554 11.6406 27.2616 11.9622 28.7737 12.596C29.6372 12.958 30.4559 13.4174 31.216 13.9654L30.1288 14.1027C29.6793 14.159 29.3605 14.5697 29.4173 15.0192C29.4698 15.4338 29.8226 15.7367 30.23 15.7367C30.2645 15.7367 30.2989 15.7345 30.3339 15.7302L33.2684 15.3605C33.7048 15.3244 34.0521 14.9077 34.0062 14.4713L33.7448 11.5269C33.7048 11.0757 33.3062 10.7421 32.855 10.782C32.4038 10.8225 32.0708 11.2206 32.1107 11.6718L32.1977 12.6507C31.3303 12.0229 30.3952 11.4968 29.408 11.0834C27.6941 10.3642 25.8747 10 24 10C20.2605 10 16.7446 11.4563 14.1005 14.1005C11.4563 16.7446 10 20.2605 10 24C10 27.7395 11.4563 31.2554 14.1005 33.8995C16.7446 36.5437 20.2605 38 24 38C27.7395 38 31.2554 36.5437 33.8995 33.8995C36.5437 31.2554 38 27.7395 38 24C38 23.5472 37.6325 23.1797 37.1797 23.1797Z"
			fill="#EF4F50" />
	</g>
	<defs>
		<clipPath id="clip0_354_13334">
			<rect
				width="28"
				height="28"
				fill="white"
				transform="translate(10 10)" />
		</clipPath>
	</defs>
</svg>
