<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#EEEDFD" />
	<path
		d="M28.7343 25.3281L26.0542 28.0173C24.9674 29.1079 23.0552 29.1314 21.9449 28.0173L19.2647 25.3281L9.63867 34.9854C9.99698 35.1511 10.392 35.2499 10.812 35.2499H37.187C37.607 35.2499 38.002 35.1512 38.3602 34.9854L28.7343 25.3281Z"
		fill="#6C5DD3" />
	<path
		d="M37.1869 12.75H10.8119C10.3919 12.75 9.99686 12.8489 9.63867 13.0146L19.9247 23.3348C19.9254 23.3354 19.9262 23.3356 19.9269 23.3363C19.9276 23.3369 19.9277 23.3379 19.9277 23.3379L23.2723 26.6935C23.6275 27.0488 24.3714 27.0488 24.7267 26.6935L28.0705 23.3384C28.0705 23.3384 28.0714 23.3369 28.072 23.3363C28.072 23.3363 28.0735 23.3354 28.0742 23.3348L38.36 13.0145C38.0019 12.8488 37.6069 12.75 37.1869 12.75Z"
		fill="#6C5DD3" />
	<path
		d="M8.29912 14.3262C8.11375 14.701 8 15.1172 8 15.5629V32.4379C8 32.8837 8.11362 33.2998 8.29906 33.6747L17.9417 24.0007L8.29912 14.3262Z"
		fill="#6C5DD3" />
	<path
		d="M39.7012 14.3252L30.0586 23.9999L39.7012 33.6739C39.8865 33.2991 40.0003 32.8829 40.0003 32.4371V15.5621C40.0003 15.1162 39.8865 14.7001 39.7012 14.3252Z"
		fill="#6C5DD3" />
</svg>
