<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_0_655)">
		<rect
			width="48"
			height="48"
			rx="24"
			fill="#7367F0"
			fill-opacity="0.12" />
		<path
			d="M17.3322 14.0972C17.7307 12.1811 19.5576 10.0406 21.3217 9.42334C23.8899 8.5245 26.0973 9.05504 28.0197 11.0336C30.3667 13.4486 30.5788 16.5516 28.5852 19.2996C27.0391 21.4304 23.5756 22.3517 21.0587 21.3019C18.3641 20.1777 16.7264 17.0111 17.3322 14.0972Z"
			fill="url(#paint0_linear_0_655)" />
		<path
			d="M17.0716 25.1504C17.6921 24.6802 18.971 23.9733 19.913 23.5801C22.3313 22.5708 26.0819 22.6883 28.595 23.8522C29.3875 24.2192 30.4697 25.0251 31.2947 26.0497C33.137 28.3379 35.4877 32.6625 34.2117 34.1075C33.8883 34.4736 23.0694 34.4736 23.0694 34.4736C15.8845 34.4736 13.208 34.3769 12.9369 34.1075C11.6181 32.7971 14.1129 27.3928 17.0716 25.1504Z"
			fill="url(#paint1_linear_0_655)" />
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_0_655"
			x1="23.5748"
			y1="9"
			x2="23.5748"
			y2="21.7368"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#7367F0" />
			<stop
				offset="1"
				stop-color="#6C5DD3" />
		</linearGradient>
		<linearGradient
			id="paint1_linear_0_655"
			x1="23.5752"
			y1="22.8947"
			x2="23.5752"
			y2="34.4736"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#7367F0" />
			<stop
				offset="1"
				stop-color="#6C5DD3" />
		</linearGradient>
		<clipPath id="clip0_0_655">
			<rect
				width="48"
				height="48"
				fill="white" />
		</clipPath>
	</defs>
</svg>
