import { currAccount } from '../../store/stores'
import { accountDB } from '../database/account.db'
import type { AccountModel } from '../../models/account.model'
import type { AccountUserModel } from '../../models/user.model'
import { userMsg } from '../../utils/userMsg.service'
import { utilService } from '../../utils/util.service'
import { currAccountUsers } from '../../store/stores'

export const accountService = {
	queryUsers,
	getById,
	create,
	update,
	getEmptyAccount
}

async function queryUsers(userIds: string[], accountId: string): Promise<AccountUserModel[]> {
	const accountUsers = []

	try {
		const accountUserDocs = await accountDB.getManyUsers(userIds, accountId)
		accountUserDocs.forEach((doc) => accountUsers.push(doc.data()))
		currAccountUsers.set(accountUsers)
		return accountUsers
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get account users', error)
	}
}

async function getById(accountId: string): Promise<AccountModel> {
	try {
		const account = (await accountDB.get(accountId)) as AccountModel
		currAccount.set(account)
		return account
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get account', error)
	}
}

async function create(initialData: { accountName: string, userId: string }): Promise<AccountModel> {
	// const account = getEmptyAccount(initialData)

	try {
		const url = `${import.meta.env.VITE_APP_API_URL}/account`
		const body = {
			name: initialData.accountName,
			subscription: 'trial',
			userIds: [],
			teamIds: [],
			customTrainingTypes: false,
			dogIds: [],
			stats: {
				totalTrainingSessions: 0,
				totalTrainingTime: 0,
				totalTrainingDays: 0,
				recentTraining: null
			},
			location: {
				address: '',
				geoPoints: {
					latitude: null,
					longitude: null
				}
			},
			phoneNumber: '',
			website: ''
		}
		return utilService.restRequest(url, 'POST', body)
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot create account', error)
	}
}

async function update(updatedAccount: AccountModel): Promise<void> {
	try {
		await accountDB.update(updatedAccount.id, updatedAccount)
		userMsg.successMsg('Account updated successfully')
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot update account', error)
	}
}

function getEmptyAccount(initialData?: Partial<AccountModel>): AccountModel {
	const emptyAccount: AccountModel = {
		id: '',
		name: '',
		ownerId: '',
		subscription: 'trial',
		teamIds: [],
		userIds: [],
		dogIds: [],
		stats: {
			totalTrainingSessions: 0,
			totalTrainingTime: 0,
			totalTrainingDays: 0
		},
		location: {
			address: null,
			geoPoints: null
		},
		customTrainingTypes: false,
		phoneNumber: null,
		website: null,
		fdfEventId: null,
		createdAt: null,
		updatedAt: null
	}

	const accountWithInitData = { ...emptyAccount, ...initialData }

	return accountWithInitData
}
