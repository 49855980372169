<script lang="ts">
import type { TeamModel } from '../../models/team.model'
import TeamStatus from './TeamStatus.svelte'
import { _ } from 'svelte-i18n'

export let team: TeamModel
</script>

<div class="flex-column team-info">
	<header class="header">
		<h3 class="title">{team.name}</h3>
	</header>
	<ul class="flex-column content">
		<li class="flex align-center status">
			<span>{$_('team_status') + ':'} </span>
			<TeamStatus status={team.status} />
		</li>
		<li class="type flex align-center">
			<span>{$_('team_type') + ':'}</span>
			{#each team.type as type, i (type)}
				<span class="tag">
					{$_(`team_types.${type}`)}{i < team.type.length - 1 ? ', ' : ''}
				</span>
			{/each}
		</li>
		<li class="location">
			<span>{$_('location') + ':'} {team.location.address ? team.location.address : 'N/A'}</span>
		</li>
	</ul>
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.team-info {
	@include mixins.card;
	padding: 24px;
	gap: 12px;

	.title {
		color: var(--clr-primary);
		font-size: 32px;
	}

	.content {
		gap: 6px;

		.status {
			gap: 3px;

			.team-status span {
				padding-block: 3px;
			}
		}

		.type {
			gap: 0;

			span {
				margin-right: 3px;
			}
		}
	}
}
</style>
