<script lang="ts">
import Select from 'svelte-select'
import { _ } from 'svelte-i18n'

export let values: {
	items: any
	dropdownOption: { label: string; value: string[] }
	finalValue: { label: string; value: string }
	value: { firstValue: any; secondaryValue: any }
	filled: boolean
}
export let data = []
let selectedValue: any

if (data.length > 0) {
	values.value = {
		firstValue: data[0].value.firstValue,
		secondaryValue: data[0].value.secondaryValue
	}
	values.dropdownOption =
		typeof data[0].value.firstValue == 'string'
			? {
					value: [],
					label: data[0].value.firstValue
				}
			: {
					value: data[0].value.firstValue.value,
					label: data[0].value.firstValue.label
				}
	values.finalValue = data[0].value.secondaryValue
} else {
	values.value = {
		firstValue: '',
		secondaryValue: ''
	}
}

$: {
	if (
		values.value.secondaryValue != '' &&
		values.value.secondaryValue != null &&
		values.value.secondaryValue != undefined
	) {
		values.filled = true
	} else {
		values.filled = false
	}
}

$: {
	if (selectedValue == '') {
		values.value.secondaryValue = values.dropdownOption
		values.value.firstValue = values.dropdownOption
	} else {
		values.value.secondaryValue = values.finalValue == null ? '' : values.finalValue.value
		values.value.firstValue = values.dropdownOption
			? values.dropdownOption
			: { value: '', label: '' }
	}
}
</script>

<Select
	--margin="0 0 1rem 0"
	bind:items={values.items}
	bind:justValue={selectedValue}
	bind:value={values.dropdownOption}
	showChevron={true}
	placeholder={$_('please_select')} />

{#if selectedValue != '' && selectedValue != null}
	<Select
		class="dropdown-secondary"
		placeholder={$_('please_select')}
		items={selectedValue}
		showChevron={true}
		bind:value={values.finalValue} />
{/if}
