<script lang="ts">
import { insightsService } from '../../services/insights/data.service'
import SvgIcon from '../common/SvgIcon.svelte'
import { _ } from 'svelte-i18n'
import { useNavigate } from 'svelte-navigator'
import Icon from 'svelte-icons-pack'
import AiOutlineEye from 'svelte-icons-pack/ai/AiOutlineEye'
import NameInitials from '../common/NameInitials.svelte'
import { utilService } from '../../utils/util.service'
import { compareStore } from './comparison/compareStore'

const navigate = useNavigate()

export let index: string = undefined
export let category: string
export let element

function AddToCompareList(element) {
	compareStore.set(element)

	navigate(`/insights/${category == 'trainedBy' ? 'user' : category}/compare`, {
		state: {
			pageName: utilService.capitalizeFirstLetter('Compare List')
		}
	})
}
</script>

<div class="container">
	<div class="dog-button">
		<div class="flex align-center name">
			<div class="flex align-center">
				<div
					class="{element.iconUrl
						? 'training-img-container'
						: element.icon
						? 'team-img-container'
						: 'dog-img-container'} flex align-center justify-center">
					{#if element.image}
						<img
							src={element.image}
							alt="dog profile" />
					{:else if element.icon}
						<SvgIcon
							width="26px"
							name={element.icon} />
					{:else if element.iconUrl}
						<img
							class="type-icon"
							src={element.iconUrl}
							alt="training profile"
							width="40px"
							height="40px" />
					{:else}
						<NameInitials name={element.name} />
					{/if}
				</div>
				<h1>{element.name}</h1>
			</div>
			{#if index}
				<div class="btns-container flex">
					<button
						class="btn btn-svg btn-edit"
						on:click={() => {
							navigate(`/insights/${category == 'trainedBy' ? 'user' : category}/${index}`, {
								state: {
									pageName: utilService.capitalizeFirstLetter(
										`${$_(`${category == 'trainedBy' ? 'user' : category}`)} Insights`
									),
									data: element
								}
							})
						}}>
						<Icon src={AiOutlineEye} />
					</button>
				</div>
			{/if}
		</div>
		<ul class="stats flex-column">
			<li class="hours flex align-center">
				{$_('time')}
				<h2>
					{insightsService.getTrainingHours(element.trainingTime, localStorage.getItem('language'))}
				</h2>
			</li>
			<li class="sessions flex align-center">
				{$_('sessions')}
				<h2>{element.trainingSessions}</h2>
			</li>
			<li class="sessions flex align-center">
				{$_('days')}
				<h2>{element.days}</h2>
			</li>
		</ul>
	</div>
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.container {
	padding: 6px;
	height: 196px;
}

.dog-button {
	@include mixins.card;
	box-shadow: none;

	.name {
		border-bottom: rgba(232, 232, 232, 0.832) 1px solid;
		justify-content: space-between;
		padding: 17px;
		gap: 8px;

		h1 {
			font-size: 16px;
		}

		.flex {
			gap: 4px;

			.btns-container {
				gap: 12px;
			}
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);
				flex-shrink: 0;

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}
			}
		}

		.dog-img-container,
		.team-img-container,
		.training-img-container {
			aspect-ratio: 1/1;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			border: 0.7px solid #ddd;
			border-radius: 50%;
			overflow: hidden;
			height: 40px;
			width: 40px;
			flex-shrink: 0;
		}

		.team-img-container,
		.training-img-container {
			margin-right: 10px;
		}

		.training-img-container {
			background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			img {
				filter: none;
				width: 24px;
				height: 24px;
			}
		}
	}
	.stats {
		font-size: 16px;
		padding: 12px 24px;
		gap: 5px;

		h2 {
			font-family: Montserrat-Medium;
			font-weight: 100 !important;
		}

		.sessions,
		.days,
		.hours {
			justify-content: space-between;
			text-transform: capitalize;
		}
	}
}
</style>
