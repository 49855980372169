<script lang="ts">
import { onSnapshot, type Unsubscribe } from 'firebase/firestore'
import { onDestroy, onMount } from 'svelte'
import DogList from '../cmps/dogs/DogList.svelte'
import { fireMsg } from '../utils/userMsg.service'
import { dogDB } from '../services/database/dog.db'
import { currAccount, currDogs, loggedInUser } from '../store/stores'
import type { DogModel } from '../models/dog.model'
import { Link } from 'svelte-navigator'
import CmpLoader from '../cmps/common/loader/CmpLoader.svelte'
import NoDogAnimation from '../cmps/common/animation/NoDogAnimation.svelte'
import LoadMoreBtn from '../cmps/common/LoadMoreBtn.svelte'
import { writable } from 'svelte/store'
import { _ } from 'svelte-i18n'
import * as Typesense from 'typesense'
import type { SearchDogModel } from '../models/dog.model'
import type { DogType } from '../models/dog.model'
import type { DogStatus } from '../models/dog.model'
import type { DogStats } from '../models/dog.model'
import type { DogHandler } from '../models/dog.model'
import SearchBar from '../cmps/common/SearchBar.svelte'
import { Container, Row, Col } from 'sveltestrap'
import { utilService } from '../utils/util.service'
import Tooltip from '../cmps/common/Tooltip.svelte'

let itemsPerPage = 10
let itemsOnPage = 0
let dogListLength = 10

let searchBarValue = ''
let sessionKeys
let hits: {
	name: string
	handler: DogHandler
	stats: DogStats
	type: DogType[]
	status: DogStatus
	id: string
	image: string
	archived: boolean
}[]
let placeholder = $_('search')

let unsubscribe: Unsubscribe

let disabled = true
const token = import.meta.env?.VITE_APP_PADDLE_TOKEN

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

onDestroy(() => unsubscribe ?? unsubscribe())

onMount(async () => {
	currDogs.set(null)
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	loadPage();
})

function submit(ev: Event) {
	ev.preventDefault()
	if (searchBarValue != '') {
		let client = new Typesense.Client({
			nodes: [
				{
					host: import.meta.env.VITE_APP_TYPESENSE_URL,
					port: 443,
					protocol: 'https'
				}
			],
			apiKey: sessionKeys.typeSenseKey,
			connectionTimeoutSeconds: 2
		})

		let search = {
			q: searchBarValue,
			query_by: 'handler.name,name,type,status',
			per_page: 100
		}

		client
			.collections('dogs')
			.documents()
			.search(search)
			.then(function (searchResults) {
				hits = searchResults.hits.map((hit) => {
					return {
						name: hit.document['name'],
						handler: hit.document['handler'],
						stats: hit.document['stats'],
						type: hit.document['type'],
						status: hit.document['status'],
						id: hit.document['id'],
						image: hit.document['image'],
						archived: hit.document['archived']
					}
				})
				dogsForDisplay.set(hits)
			})
	} else {
		hits = null
		itemsPerPage = 10
		itemsOnPage = 0
		dogListLength = 10
		loadPage()
	}
}

async function loadPage() {
	itemsOnPage += itemsPerPage
	const paginated = await dogDB.loadPage($loggedInUser.selectedAccount.id, itemsOnPage)
	unsubscribe = onSnapshot(paginated, (querySnapshot) => {
		let dogs: DogModel[] = []
		querySnapshot.forEach((doc) => dogs.push(doc.data() as DogModel))
		currDogs.set(dogs.filter((dog) => dog.accountId == $loggedInUser.selectedAccount.id))
		dogsForDisplay.set(dogs.filter((dog) => dog.accountId == $loggedInUser.selectedAccount.id))
		dogListLength = dogs.length
	})
}

const dogsForDisplay = writable<SearchDogModel[]>([])
</script>

<Container
	fluid
	class="p-0">
	{#if !$currDogs}
		<Row>
			<Col>
				<CmpLoader />
			</Col>
		</Row>
	{:else}
		<Row class="dogs">
			<Col>
				{#if $currAccount.dogIds.length > 0}
					<div class="flex-column main-content gap-3">
						<header class="flex align-center space-between list-header">
							<h1 class="title">{$_('dogs')}</h1>
							{#if disabled}
								<button
									{disabled}
									on:click={() => {
										if ($currAccount.teamIds.length == 0) {
											fireMsg({
												type: 'failure',
												msg: $_('no_team_message')
											})
										}
									}}
									class="btn theme-btn btn-add-dog flex">
									<Tooltip message={$_('locked_feature')} />
									{$_('add_dog')}
								</button>
							{:else}
								<Link
									to={$currAccount.teamIds.length > 0 ? 'add' : ''}
									state={{ pageName: $_('add_dog') }}>
									<button
										{disabled}
										on:click={() => {
											if ($currAccount.teamIds.length == 0) {
												fireMsg({
													type: 'failure',
													msg: $_('no_team_message')
												})
											}
										}}
										class="btn theme-btn btn-add-dog">{$_('add_dog')}</button>
								</Link>
							{/if}
						</header>
						<form
							action=""
							on:submit={submit}>
							<SearchBar
								bind:searchTerm={searchBarValue}
								{placeholder} />
						</form>
						<div class="dog-list-container">
							<DogList
								dogs={$dogsForDisplay}
								{disabled} />
						</div>
					</div>
					{#if !hits}
						<div class="loadmore-btn mt-3">
							<LoadMoreBtn
								loadFunc={loadPage}
								{itemsOnPage}
								listLength={dogListLength} />
						</div>
					{/if}
				{:else if $currAccount.teamIds.length == 0}
					<div class="flex-column align-center no-dogs-animation">
						<div class="animation-container flex-column align-center">
							<NoDogAnimation />
							<h1 class="title no-team-message">{$_('no_team_message')}</h1>
						</div>
						<div class="button-container">
							{#if disabled}
								<button
									{disabled}
									on:click={() => {
										if ($currAccount.teamIds.length == 0) {
											fireMsg({
												type: 'failure',
												msg: $_('no_team_message')
											})
										}
									}}
									class="btn theme-btn btn-add-dog">{$_('add_dog')}</button>
							{:else}
								<Link
									to={$currAccount.teamIds.length > 0 ? 'add' : ''}
									state={{ pageName: $_('add_dog') }}>
									<button
										{disabled}
										on:click={() => {
											if ($currAccount.teamIds.length == 0) {
												fireMsg({
													type: 'failure',
													msg: $_('no_team_message')
												})
											}
										}}
										class="btn theme-btn btn-add-dog">{$_('add_dog')}</button>
								</Link>
							{/if}
						</div>
					</div>
				{:else}
					<div class="flex-column align-center no-dogs-animation gap-4 mt-5">
						<NoDogAnimation />
						{#if disabled}
							<button
								{disabled}
								on:click={() => {
									if ($currAccount.teamIds.length == 0) {
										fireMsg({
											type: 'failure',
											msg: $_('no_team_message')
										})
									}
								}}
								class="btn theme-btn btn-add-dog">{$_('add_dog')}</button>
						{:else}
							<Link
								to={$currAccount.teamIds.length > 0 ? 'add' : ''}
								state={{ pageName: $_('add_dog') }}>
								<button
									{disabled}
									on:click={() => {
										if ($currAccount.teamIds.length == 0) {
											fireMsg({
												type: 'failure',
												msg: $_('no_team_message')
											})
										}
									}}
									class="btn theme-btn btn-add-dog">{$_('add_dog')}</button>
							</Link>
						{/if}
					</div>
				{/if}
			</Col>
		</Row>
	{/if}
</Container>

<style lang="scss">
.no-dogs-animation {
	margin-top: 50px;
	gap: 10px;
}

.main-content {
	gap: 20px;
}

.list-header {
	.title {
		color: var(--clr-txt-dark-primary);
		font-size: 20px;
	}
}

.no-team-message {
	margin-top: 1rem;
}

.dog-list-container {
	display: grid; // For table scroll overflow
	margin-bottom: 20px;
}

.btn-add-dog:disabled {
	pointer-events: auto;
	gap: 6px;
}
</style>
