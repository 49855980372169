<script lang="ts">
import Icon from 'svelte-icons-pack'
import RiDesignPencilLine from 'svelte-icons-pack/ri/RiDesignPencilLine'
import AiOutlineEye from 'svelte-icons-pack/ai/AiOutlineEye'
import { useNavigate } from 'svelte-navigator'
import SvgIcon from '../common/SvgIcon.svelte'
import TeamStatus from './TeamStatus.svelte'
import { _ } from 'svelte-i18n'
import type { SearchTeamModel } from '../../models/team.model'
import NoResultAnimation from '../common/animation/NoResultAnimation.svelte'

export let teams: SearchTeamModel[]
export let disabled: boolean

const navigate = useNavigate()

</script>

{#if teams && teams.length === 0}
	<div class="flex-column align-center no-dogs-animation">
		<NoResultAnimation />
	</div>
{:else if teams && teams.length > 0}
	<section class="flex-column team-list">
		<header class="header-container">
			<!-- TODO(or-agami): Move header to separate cmp -->
			<ul class="header">
				<li class="team-name">
					<span>{$_('team_name')}</span>
				</li>
				<li class="location">{$_('location')}</li>
				<li class="dogs">{$_('dogs')}</li>
				<li class="users">{$_('users')}</li>
				<li class="status">{$_('status')}</li>
				<li class="action">{$_('action')}</li>
			</ul>
		</header>
		<div class="flex-column content">
			{#each teams as team}
				<button
					class="team-preview-container"
					on:click={() => navigate(team.id, { state: { pageName: $_('team_details') } })}>
					<ul class="team-preview">
						<li class="flex align-center team-name">
							<SvgIcon
								width="30px"
								name={team.icon} />
							<span>{team.name}</span>
						</li>
						<li class="location">{team.location.address || 'Not Added'}</li>
						<li class="dogs">{team.dogIds.length}</li>
						<li class="users">{team.userIds.length}</li>
						<li class="status">
							<TeamStatus status={team.status} />
						</li>
						<li class="action">
							{#if !disabled}
								<button
									class="btn btn-svg btn-edit"
									title={$_('edit')}
									on:click|stopPropagation={() =>
										navigate(`/teams/edit/${team.id}`, { state: { pageName: $_('edit_team') } })}>
									<Icon src={RiDesignPencilLine} />
								</button>
							{/if}
							<button
								on:click={() => navigate(team.id, { state: { pageName: $_('team_details') } })}
								class="btn btn-svg btn-edit"
								title={$_('details')}>
								<Icon src={AiOutlineEye} />
							</button>
						</li>
					</ul>
				</button>
			{/each}
		</div>
	</section>
{/if}

<style lang="scss">
@use '../../styles/setup/mixins';

.team-list {
	@include mixins.card;
	overflow-x: auto;
	height: fit-content;

	.header,
	.team-preview {
		display: grid;
		grid-template-columns:
			minmax(200px, 2fr) minmax(150px, 2fr) repeat(2, minmax(60px, 1fr))
			repeat(2, minmax(80px, 1fr));
		min-width: fit-content;

		> li {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		.team-name {
			gap: 10px;
		}

		.location {
			text-align: initial;
		}

		.dogs,
		.users {
			justify-content: center;
			text-align: center;
			min-width: 60px;
		}

		.status,
		.action {
			justify-content: center;
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);
	}

	.content {
		font-size: 14px;
	}

	.team-preview {
		border-top: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 16px;
		transition: background-color 0.15s ease-out;

		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}

		.dogs,
		.users {
			padding-inline-start: 6px;
		}

		.action {
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}

				:global(svg) {
					font-size: 18px;
				}
			}
		}
	}
	@media only screen and (max-width: 768px) {
		.location,
		.dogs,
		.users {
			display: none !important;
		}
		.header,
		.team-preview {
			grid-template-columns: minmax(120px, 1.5fr) minmax(100px, 1fr) minmax(80px, 1fr);
		}
	}
}
</style>
