<script lang="ts">
import { writable } from 'svelte/store'
import MinutesToYearsStepper from '../MinutesToYearsStepper.svelte'
import WeightSlider from '../WeightSlider.svelte'
import DropdownBottomSheet from '../DropdownBottomSheet.svelte'
import Multiselect from '../Multiselect.svelte'
import MultiselectTextToTags from '../MultiselectTextToTags.svelte'

// TODO: this widget is only used for the odor stepper, it needs to be renamed

interface TimeValue {
	minutes: number
	hours: number
	days: number
	months: number
	years: number
}

interface WeightValue {
	weight: number
	label: string
}

interface MultiselectTextToTagsValue {
	multiselectValue: string[]
	tags: string[]
}

interface OdorInfo {
	odorAge: TimeValue
	odorSetTime: TimeValue
	odorWeight: WeightValue
	multiselectTextToTags: MultiselectTextToTagsValue
	multiselectTextToTags2: MultiselectTextToTagsValue
	odorPlacement: string
	multiselect: string[]
}

interface DataItem {
	odorAmount: number
	odorInfo: OdorInfo[]
}

interface WidgetItem {
	isShown: boolean
	name: string
	//subWidgetOrder?: number;
	items?: string[]
	showDays?: boolean
	showMonths?: boolean
	showYears?: boolean
	showMinutes?: boolean
	showHours?: boolean
	defaultSliderValue?: number
	divisions?: number
	maxKilos?: number
	minKilos?: number
	maxPounds?: number
	minPounds?: number
	useGrams?: boolean
	trigger?: number
}

interface Values {
	items: {
		dropdownBottomSheet: WidgetItem
		minsToYears1: WidgetItem
		minsToYears2: WidgetItem
		multiselect: WidgetItem
		multiselectTextToTags: WidgetItem
		multiselectTextToTags2: WidgetItem
		weightSlider: WidgetItem
	}
	value: {
		odorPlacement: string
		odorAge: TimeValue
		odorSetTime: TimeValue
		odorWeight: WeightValue
		multiselectValue: string[]
		multiselectTextToTagsValue: MultiselectTextToTagsValue
		multiselectTextToTagsValue2: MultiselectTextToTagsValue
	}
}

export let values: Values
export let data: DataItem[] = []
export let counter: number
export let id: string

const stepperMultiplyHelper = writable<Record<string, string[]>>({})

$: stepperMultiplyHelper.update((store) => {
	if (!store[id]) {
		store[id] = []
	}
	return store
})

function initializeValues<T>(defaultValue: T, dataValue?: T): T {
	return data.length > 0 && data[0]?.odorInfo?.[counter] && dataValue !== undefined
		? dataValue
		: defaultValue
}

let dropdownValues = initializeValues(
	{
		items: values.items.dropdownBottomSheet?.items || [],
		returnedValue: {
			value: values.value.odorPlacement || '',
			label: values.value.odorPlacement || ''
		},
		filled: false,
		value: ''
	},
	data[0]?.odorInfo?.[counter]?.odorPlacement
		? {
				items: values.items.dropdownBottomSheet?.items || [],
				returnedValue: {
					value: data[0].odorInfo[counter].odorPlacement || '',
					label: data[0].odorInfo[counter].odorPlacement || ''
				},
				filled: false,
				value: data[0].odorInfo[counter].odorPlacement || ''
			}
		: undefined
)

let weightSliderValues = initializeValues(
	{
		defaultSliderValue: values.items.weightSlider?.defaultSliderValue || 0,
		divisions: values.items.weightSlider?.divisions || 1,
		maxKilos: values.items.weightSlider?.maxKilos || 100,
		maxPounds: values.items.weightSlider?.maxPounds || 220,
		minKilos: values.items.weightSlider?.minKilos || 0,
		minPounds: values.items.weightSlider?.minPounds || 0,
		useGrams: values.items.weightSlider?.useGrams || false,
		value: { weight: 0, label: 'kilograms' as "kilograms" | "pounds" }
	},
	data[0]?.odorInfo?.[counter]?.odorWeight && {
		defaultSliderValue: data[0].odorInfo[counter].odorWeight.weight || 0,
		divisions: values.items.weightSlider?.divisions || 1,
		maxKilos: values.items.weightSlider?.maxKilos || 100,
		maxPounds: values.items.weightSlider?.maxPounds || 220,
		minKilos: values.items.weightSlider?.minKilos || 0,
		minPounds: values.items.weightSlider?.minPounds || 0,
		useGrams: values.items.weightSlider?.useGrams || false,
		value: {
			weight: data[0].odorInfo[counter].odorWeight.weight || 0,
			label:
				data[0].odorInfo[counter].odorWeight.label === 'kilograms' ||
				data[0].odorInfo[counter].odorWeight.label === 'pounds'
					? (data[0].odorInfo[counter].odorWeight.label as "kilograms" | "pounds") // Explicitly cast to the correct type
					: 'kilograms' // Fallback to 'kilograms' if the label is invalid
		}
	}
)

function initializeTimeValues(
	itemKey: 'minsToYears1' | 'minsToYears2',
	valueKey: 'odorAge' | 'odorSetTime'
) {
	return initializeValues(
		{
			showDays: values.items[itemKey]?.showDays || false,
			showMonths: values.items[itemKey]?.showMonths || false,
			showYears: values.items[itemKey]?.showYears || false,
			showMinutes: values.items[itemKey]?.showMinutes || false,
			showHours: values.items[itemKey]?.showHours || false,
			value: {
				minutes: 0,
				hours: 0,
				days: 0,
				months: 0,
				years: 0
			}
		},
		data[0]?.odorInfo?.[counter]?.[valueKey] && {
			showDays: values.items[itemKey]?.showDays || false,
			showMonths: values.items[itemKey]?.showMonths || false,
			showYears: values.items[itemKey]?.showYears || false,
			showMinutes: values.items[itemKey]?.showMinutes || false,
			showHours: values.items[itemKey]?.showHours || false,
			value: data[0].odorInfo[counter][valueKey] || {
				minutes: 0,
				hours: 0,
				days: 0,
				months: 0,
				years: 0
			}
		}
	)
}

let odorAgeValues = {
	...initializeTimeValues('minsToYears1', 'odorAge'),
	filled: false
}

let odorSetTimeValues = {
	...initializeTimeValues('minsToYears2', 'odorSetTime'),
	filled: false
}

let multiselectValues = initializeValues(
	{
		items: values.items.multiselect?.items || [],
		value: values.value.multiselectValue || [],
		returnedValue: $stepperMultiplyHelper[id]?.map((value) => ({ value, label: value })) || [],
		filled: false
	},
	data[0]?.odorInfo?.[counter]?.multiselect && {
		items: values.items.multiselect?.items || [],
		value: values.value.multiselectValue || [],
		returnedValue:
			data[0].odorInfo[counter].multiselect?.map((value) => ({ value, label: value })) || [],
		filled: false
	}
)

function initializeMultiselectTextToTagsValues(
	itemKey: 'multiselectTextToTags' | 'multiselectTextToTags2'
) {
	return initializeValues(
		{
			items: values.items[itemKey]?.items || [],
			trigger: values.items[itemKey]?.trigger || 0,
			value: {
				multiselectValue: [],
				tags: []
			}
		},
		data[0]?.odorInfo?.[counter]?.[itemKey] && {
			items: values.items[itemKey]?.items || [],
			trigger: values.items[itemKey]?.trigger || 0,
			value: {
				multiselectValue: data[0].odorInfo[counter][itemKey]?.multiselectValue || [],
				tags: data[0].odorInfo[counter][itemKey]?.tags || []
			}
		}
	)
}

let multiselectTextToTagsValues = initializeMultiselectTextToTagsValues('multiselectTextToTags')
let multiselectTextToTagsValues2 = initializeMultiselectTextToTagsValues('multiselectTextToTags2')

$: {
	values.value = {
		odorPlacement: values.items.dropdownBottomSheet?.isShown ? dropdownValues.value : '',
		odorAge: values.items.minsToYears1?.isShown
			? odorAgeValues.value
			: { minutes: 0, hours: 0, days: 0, years: 0, months: 0 },
		odorWeight: values.items.weightSlider?.isShown
			? weightSliderValues.value
			: { weight: 0, label: '' },
		odorSetTime: values.items.minsToYears2?.isShown
			? odorSetTimeValues.value
			: { minutes: 0, hours: 0, days: 0, years: 0, months: 0 },
		multiselectValue: values.items.multiselect?.isShown ? multiselectValues.value : [],
		multiselectTextToTagsValue: values.items.multiselectTextToTags?.isShown
			? multiselectTextToTagsValues.value
			: { multiselectValue: [], tags: [] },
		multiselectTextToTagsValue2: values.items.multiselectTextToTags2?.isShown
			? multiselectTextToTagsValues2.value
			: { multiselectValue: [], tags: [] }
	}
}

function updateStoreValue() {
	stepperMultiplyHelper.update((store) => {
		store[id] = multiselectValues.value || []
		return store
	})
}

function safeRender<T>(condition: boolean, content: () => T): T | null {
	try {
		return condition ? content() : null
	} catch (error) {
		console.error('Error in rendering:', error)
		return null
	}
}
</script>

<div class="widget-border flex-column">
	{#if values.items.multiselectTextToTags?.isShown}
		<h4 class="title">{values.items.multiselectTextToTags?.name || ''}</h4>
		{#if safeRender(true, () => true)}
			<MultiselectTextToTags
				bind:values={multiselectTextToTagsValues}
				{id}
				data={data.length > 0 && data[0]?.odorInfo?.[counter]
					? [{ value: data[0].odorInfo[counter].multiselectTextToTags }]
					: undefined} />
		{/if}
	{/if}
	{#if values.items.multiselect?.isShown}
		<h4 class="title">{values.items.multiselect?.name || ''}</h4>
		{#if safeRender(true, () => true)}
			<Multiselect
				on:change={updateStoreValue}
				bind:values={multiselectValues}
				{id} />
		{/if}
	{/if}
	{#if values.items.dropdownBottomSheet?.isShown}
		<h4 class="title">{values.items.dropdownBottomSheet?.name || ''}</h4>
		{#if safeRender(dropdownValues !== undefined, () => true)}
			<DropdownBottomSheet
				bind:values={dropdownValues}
				{id} />
		{/if}
	{/if}
	{#if values.items.multiselectTextToTags2?.isShown}
		<h4 class="title">{values.items.multiselectTextToTags2?.name || ''}</h4>
		{#if safeRender(true, () => true)}
			<MultiselectTextToTags
				bind:values={multiselectTextToTagsValues2}
				id={id + 1}
				data={data.length > 0 && data[0]?.odorInfo?.[counter]?.multiselectTextToTags2
					? [{ value: data[0].odorInfo[counter].multiselectTextToTags2 }]
					: undefined} />
		{/if}
	{/if}
	{#if values.items.minsToYears1?.isShown}
		<h4 class="title">{values.items.minsToYears1?.name || ''}</h4>
		{#if safeRender(true, () => true)}
			<MinutesToYearsStepper
				bind:values={odorAgeValues}
				id={id + 1}
				data={data.length > 0 && data[0]?.odorInfo?.[counter]
					? [
							{
								value: {
									minutes: data[0].odorInfo[counter].odorAge.minutes,
									hours: data[0].odorInfo[counter].odorAge.hours,
									days: data[0].odorInfo[counter].odorAge.days,
									months: data[0].odorInfo[counter].odorAge.months,
									years: data[0].odorInfo[counter].odorAge.years
								}
							}
						]
					: undefined} />
		{/if}
	{/if}
	{#if values.items.minsToYears2?.isShown}
		<h4 class="title">{values.items.minsToYears2?.name || ''}</h4>
		{#if safeRender(true, () => true)}
			<MinutesToYearsStepper
				bind:values={odorSetTimeValues}
				{id}
				data={data.length > 0 && data[0]?.odorInfo?.[counter]
					? [
							{
								value: {
									minutes: data[0].odorInfo[counter].odorAge.minutes,
									hours: data[0].odorInfo[counter].odorAge.hours,
									days: data[0].odorInfo[counter].odorAge.days,
									months: data[0].odorInfo[counter].odorAge.months,
									years: data[0].odorInfo[counter].odorAge.years
								}
							}
						]
					: undefined} />
		{/if}
	{/if}
	{#if values.items.weightSlider?.isShown}
		<h4 class="title">{values.items.weightSlider?.name || ''}</h4>
		{#if safeRender(true, () => true)}
			<WeightSlider
				bind:values={weightSliderValues}
				{id} />
		{/if}
	{/if}
</div>

<style lang="scss">
.widget-border {
	padding: 16px 24px;
	border: 2px solid rgb(231, 219, 219);
	border-radius: 10px;
	gap: 16px;
}

.title {
	color: var(--neutral-05, #33383f);
	font-size: 13px;
	font-family: Montserrat-Medium;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.14px;
}
</style>
