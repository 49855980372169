<svg
	width="120"
	height="130"
	viewBox="0 0 32 35"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="M31.5113 21.5313L27.94 24.6868C27.5723 24.9497 27.1522 25.1075 26.732 25.1075H15.2304L19.747 34.5739H0V19.6906C0 19.2173 0.157553 18.7966 0.472666 18.4284L18.224 0.0741301C18.3816 -0.0836433 18.6442 0.0215358 18.6442 0.284491V8.85685H20.4823C20.7449 8.85685 21.0075 8.96203 21.1651 9.1724L25.5241 15.6937C25.6292 15.8339 25.7517 15.9742 25.8917 16.1144L29.9882 19.1647C29.3055 19.0069 27.3623 20.2691 28.7278 22.0046C28.5177 21.4787 28.4652 20.9002 28.7803 20.4795C29.3055 19.6906 30.4084 19.638 31.3537 20.2165C31.7738 20.4795 32.0364 21.058 31.5113 21.5313V21.5313Z"
		fill="#264654" />
	<path
		d="M23.6334 16.1667C23.6334 16.1667 19.9571 18.7962 19.5894 23.2139H9.71593L11.5541 24.4761L9.76844 32.6277H1.89062V19.9532C1.89062 19.813 1.94315 19.7078 2.04818 19.6377L17.2786 3.86035L14.4951 10.7498H19.852L21.0075 12.4853L16.911 13.169L22.0053 15.8511V13.8527L23.6334 16.1667Z"
		fill="#FD9703" />
</svg>
