<script lang="ts">
import { onMount } from 'svelte'
import { _ } from 'svelte-i18n'
import { timerSlider } from '../../../store/trainingWidgetsStore'
import { afterUpdate } from 'svelte'

export let values: {
	min: number
	max: number
	divisions: number
	defaultSliderValue?: number
	useSeconds: boolean
	value: { sliderValue: number; useSeconds: boolean }
}
export let data = []
export let id: string = null

onMount(() => {
	if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
		document.body.classList.add('firefox')
	}
})

values.value = { sliderValue: values.defaultSliderValue, useSeconds: values.useSeconds }

if (!$timerSlider[id]) {
	$timerSlider[id] = 0
}

if (data.length > 0) {
	values.defaultSliderValue = data[0].value.sliderValue ? data[0].value.sliderValue : data[0].value
}

let currentSliderValue = values.value.sliderValue

if ($timerSlider[id]) {
	values.value.sliderValue = $timerSlider[id]
	currentSliderValue = values.value.sliderValue
} else {
	values.value.sliderValue = values.defaultSliderValue != undefined ? values.defaultSliderValue : 0
	currentSliderValue = values.defaultSliderValue != undefined ? values.defaultSliderValue : 0
}

function handleChange(event) {
	values.value.sliderValue = +event.target.value
	currentSliderValue = values.value.sliderValue
	$timerSlider[id] = values.value.sliderValue
}

let inputElement: HTMLInputElement
let editingLabel = false
let editValue = ''

let shouldFocusInput = false

function handleLabelClick() {
	editingLabel = true
	editValue = currentSliderValue != 0 ? currentSliderValue.toString() : ''
	shouldFocusInput = true
}

function handleNumberInput(event) {
	if (event.target.value.length > 8) {
		event.target.value = event.target.value.slice(0, 8)
	}
}

afterUpdate(() => {
	if (shouldFocusInput) {
		inputElement.focus()
		shouldFocusInput = false
	}
})

function getFormattedTime(value: number): string {
	if (values.useSeconds) {
		const seconds = value % 60
		const minutes = Math.floor(value / 60)

		if (minutes === 0) {
			if (seconds > 0) {
				return `${seconds} ${$_('s')}`
			} else {
				return '0 s'
			}
		} else {
			const hours = Math.floor(minutes / 60)
			const remainingMinutes = minutes % 60
			const parts = []

			if (hours > 0) {
				parts.push(`${hours} ${$_('h')}`)
			}
			if (remainingMinutes > 0) {
				parts.push(`${remainingMinutes} ${$_('min')}`)
			}
			if (seconds > 0) {
				parts.push(`${seconds} ${$_('s')}`)
			}

			return parts.join(' ')
		}
	} else {
		if (value < 60) {
			return `${value} ${$_('min')}`
		} else {
			const hours = Math.floor(value / 60)
			const minutes = value % 60

			if (hours > 0 && minutes > 0) {
				return `${hours} ${$_('h')} ${minutes} ${$_('min')}`
			} else if (hours > 0) {
				return `${hours} ${$_('h')}`
			} else {
				return `${minutes} ${$_('min')}`
			}
		}
	}
}

function handleLabelBlur() {
	editingLabel = false

	let rawValue = parseFloat(editValue)

	if (isNaN(rawValue) || rawValue < values.min) {
		rawValue = values.min
	}

	let maxValue = values.max
	if (rawValue > maxValue) {
		rawValue = maxValue
	}

	currentSliderValue = rawValue
	editValue = currentSliderValue.toString()
	$timerSlider[id] = currentSliderValue
}

let sliderWidth: number

$: filledWidth =
	(sliderWidth / (values.max - values.min)) *
	((currentSliderValue ? currentSliderValue : 0) - values.min)

$: label = getFormattedTime(currentSliderValue)
</script>

<div class="slider-container flex">
	<div class="slider-range flex align-center">
		<div
			class="slider-wrapper"
			bind:clientWidth={sliderWidth}>
			<input
				type="range"
				class="slider"
				min={values.min}
				max={values.max}
				step={values.useSeconds ? ((values.max - values.min) / values.divisions).toFixed() : 1}
				bind:value={currentSliderValue}
				on:input={handleChange} />
		</div>
		<div class="current-value flex justify-center">
			{#if editingLabel}
				<div class="input-container">
					<input
						class="label-input"
						bind:this={inputElement}
						type="number"
						bind:value={editValue}
						on:input={handleNumberInput}
						on:blur={handleLabelBlur} />
					<span class="tooltip">Type the value in {values.useSeconds ? 'seconds' : 'minutes'}</span>
				</div>
			{:else}
				<div
					on:click={handleLabelClick}
					on:keydown={handleLabelClick}>
					{label}
				</div>
			{/if}
		</div>
		<div
			class="filled"
			style={`width:${filledWidth}px`} />
	</div>
</div>

<style lang="scss">
.slider-range {
	align-items: center;
	gap: 10px;

	.slider-wrapper {
		width: 510px;
	}

	@media (max-width: 768px) {
		width: 100%;

		.slider-wrapper {
			width: 100%;
		}
	}

	.filled {
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		position: absolute;
		border-radius: 5px;
		z-index: 0;
		height: 2.5px;
	}
}

.label-input {
	height: 21px;
	width: 76px;
	border: none;
}

.label-input:focus {
	outline: none;
	border-color: transparent;
	box-shadow: none;
}

.input-container {
	position: relative;
	display: inline-block;
}

.input-container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}

.tooltip {
	visibility: hidden;
	opacity: 0;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 140%;
	left: 50%;
	font-size: 14px;
	margin-left: -60px;
	width: 180px;
}
.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 35%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #264a59 transparent transparent transparent;
}
.current-value,
.label-input {
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 6px;
	padding: 5px 0px;
	min-width: 5rem;
	font-size: 13px;
	font-family: Montserrat-Medium;
	text-align: center;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']::-ms-clear {
	display: none;
}

.label-input {
	border: none;
	padding: 0;
}

.current-value {
	padding: 5px 8px;
}

input[type='range'] {
	height: 23px;
	-webkit-appearance: none;
	margin: 10px 0;
	width: 100%;
}
input[type='range']:focus {
	outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
	width: 600px;
	height: 2.5px;
	cursor: pointer;
	transition: 0.2s;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 5px;
}
input[type='range']::-webkit-slider-thumb {
	height: 16px;
	width: 16px;
	border-radius: 50px;
	z-index: 1;
	position: relative;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -7px;
}
input[type='range']:active::-webkit-slider-thumb {
	outline: 10px solid rgba(45, 115, 146, 0.3);
	outline-offset: -1px;
	cursor: grabbing;
}
input[type='range']:focus::-webkit-slider-runnable-track {
	background: rgba(143, 149, 178, 0.4);
}
input[type='range']::-moz-range-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	transition: 0.2s;
	position: relative;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 5px;
}
input[type='range']:active::-moz-range-thumb {
	outline: 10px solid rgba(45, 115, 146, 0.3);
	outline-offset: -1px;
	cursor: grabbing;
}
input[type='range']::-moz-range-progress {
	border-radius: 6px;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
}
input[type='range']::-moz-range-thumb {
	height: 16px;
	width: 16px;
	border-radius: 50px;
	z-index: 9;
	position: relative;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	cursor: pointer;
}
input[type='range']::-ms-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	transition: 0.2s;
	background: transparent;
	position: relative;
	border-color: transparent;
	color: transparent;
}
input[type='range']::-ms-fill-lower {
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;
}
input[type='range']::-ms-fill-upper {
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;
}
input[type='range']::-ms-thumb {
	margin-top: 1px;
	height: 16px;
	width: 16px;
	border-radius: 50px;
	z-index: 9;
	position: relative;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
	background: rgba(143, 149, 178, 0.4);
}
input[type='range']:focus::-ms-fill-upper {
	background: rgba(143, 149, 178, 0.4);
}
body.firefox .slider-range .filled {
	display: none;
}
</style>
