<svg
	width="49"
	height="48"
	viewBox="0 0 49 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_276_3146)">
		<rect
			x="0.952148"
			width="48"
			height="48"
			rx="24"
			fill="#EEEDFD" />
		<path
			d="M26.2159 28.9153C26.9907 29.4246 28.0323 29.263 28.611 28.5436L36.4203 18.8361V16.5294H33.707L26.8304 25.0775L20.3978 20.8493C19.6175 20.3365 18.5681 20.5043 17.9923 21.234L12.6036 28.0629L9.37727 32.1516C9.18769 32.3918 9.07102 32.6652 9.02397 32.9458C8.99184 33.1375 8.99218 33.3325 9.02397 33.5226C9.09465 33.9454 9.32077 34.3441 9.69086 34.628C10.471 35.2265 11.5956 35.0881 12.2026 34.3188L12.6036 33.8106L19.7962 24.6957L26.2159 28.9153Z"
			fill="url(#paint0_linear_276_3146)" />
		<path
			d="M38.2963 13.6645C39.0707 14.2701 39.2006 15.3801 38.5863 16.1437L36.4203 18.8361V22.1765C36.4203 23.1511 37.2217 23.9412 38.2102 23.9412C39.1987 23.9412 40 23.1511 40 22.1765V14.7647C40 13.7901 39.1987 13 38.2102 13H30.6691C29.6806 13 28.8792 13.7901 28.8792 14.7647C28.8792 15.7393 29.6806 16.5294 30.6691 16.5294H33.707L35.7817 13.9504C36.396 13.1869 37.5218 13.0588 38.2963 13.6645Z"
			fill="url(#paint1_linear_276_3146)" />
		<path
			d="M38.5863 16.1437C39.2006 15.3801 39.0707 14.2701 38.2963 13.6645C37.5218 13.0588 36.396 13.1869 35.7817 13.9504L33.707 16.5294H36.4203V18.8361L38.5863 16.1437Z"
			fill="url(#paint2_linear_276_3146)" />
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_276_3146"
			x1="28.5"
			y1="11"
			x2="24.5"
			y2="35"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#7D6FDD" />
			<stop
				offset="1"
				stop-color="#4634C1" />
		</linearGradient>
		<linearGradient
			id="paint1_linear_276_3146"
			x1="28.5"
			y1="11"
			x2="24.5"
			y2="35"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#7D6FDD" />
			<stop
				offset="1"
				stop-color="#4634C1" />
		</linearGradient>
		<linearGradient
			id="paint2_linear_276_3146"
			x1="28.5"
			y1="11"
			x2="24.5"
			y2="35"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#7D6FDD" />
			<stop
				offset="1"
				stop-color="#4634C1" />
		</linearGradient>
		<clipPath id="clip0_276_3146">
			<rect
				width="48"
				height="48"
				fill="white"
				transform="translate(0.952148)" />
		</clipPath>
	</defs>
</svg>
