<script lang="ts">
import { Banner } from 'flowbite-svelte'
import { BullhornSolid } from 'flowbite-svelte-icons'
import { _ } from 'svelte-i18n'

export let sessionKeys: any
</script>

{#if sessionKeys}
	{#if sessionKeys.daysLeftInTrial < 3 && sessionKeys.daysLeftInTrial != 0 && sessionKeys.subscriptionStatus != 'active'}
		<div class="banner">
			<Banner id="default-banner">
				<p class="flex items-center text-lg font-normal text-gray-500 dark:text-gray-400">
					<span class="inline-flex p-1 mr-3 bg-gray-200 rounded-full dark:bg-gray-600">
						<BullhornSolid class="w-5 h-5 text-gray-500 dark:text-gray-400" />
						<span class="sr-only">Light bulb</span>
					</span>
					<span class="banner-text">
						{$_('under_trial')}
						{sessionKeys.daysLeftInTrial}
						{$_('x_days_left_please')}
						<a
							class="link inline font-medium text-primary-600 underline dark:text-primary-500 underline-offset-2 decoration-600 dark:decoration-500 decoration-solid hover:no-underline"
							href="/checkout">{$_('subscribe')}</a>
					</span>
				</p>
			</Banner>
		</div>
	{:else if sessionKeys.daysLeftInTrial == 0 && sessionKeys.subscriptionStatus != 'active'}
		<div class="banner">
			<Banner id="default-banner">
				<p class="flex items-center text-lg font-normal text-gray-500 dark:text-gray-400 container">
					<span class="inline-flex p-1 mr-3 bg-gray-200 rounded-full dark:bg-gray-600">
						<BullhornSolid class="w-3 h-3 text-gray-500 dark:text-gray-400" />
						<span class="sr-only">Light bulb</span>
					</span>
					<span class="banner-text">
						{$_('trial_expired')}
						<a
							class="link inline font-medium text-primary-600 underline dark:text-primary-500 underline-offset-2 decoration-600 dark:decoration-500 decoration-solid hover:no-underline"
							href="/checkout">{$_('subscribe')}!</a>
					</span>
				</p>
			</Banner>
		</div>
	{:else if sessionKeys.subscriptionStatus == 'cancelled' || sessionKeys.subscriptionStatus == 'canceled'}
		<div class="banner">
			<Banner id="default-banner">
				<p class="flex items-center text-lg font-normal text-gray-500 dark:text-gray-400 container">
					<span class="inline-flex p-1 mr-3 bg-gray-200 rounded-full dark:bg-gray-600">
						<BullhornSolid class="w-3 h-3 text-gray-500 dark:text-gray-400" />
						<span class="sr-only">Light bulb</span>
					</span>
					<span class="banner-text">
						{$_('subscription_canceled')}
						<a
							class="link inline font-medium text-primary-600 underline dark:text-primary-500 underline-offset-2 decoration-600 dark:decoration-500 decoration-solid hover:no-underline"
							href="/checkout">{$_('subscribe')}!</a>
					</span>
				</p>
			</Banner>
		</div>
	{/if}
{/if}

<style lang="scss">
.link {
	color: #2d7392;
}
.link:hover {
	color: #264a59;
}
</style>
