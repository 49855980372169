<script lang="ts">
import { createEventDispatcher } from 'svelte'
import { currAccount } from '../../../store/stores'
import { _ } from 'svelte-i18n'
import MapBoxLocationInput from '../../common/MapBoxLocationInput.svelte'
import Icon from 'svelte-icons-pack'
import CgClose from 'svelte-icons-pack/cg/CgClose'
import type { AuthField } from '../../../utils/validation/auth.validation'
import { authValidation } from '../../../utils/validation/auth.validation'
import PhoneInput from '../../common/PhoneInput.svelte'
import type { Location } from '../../../models/common.model'
import { utilService } from '../../../utils/util.service'
import { fireMsg, userMsg } from '../../../utils/userMsg.service'

//Variable for trigger modal opening
export let isOpen: boolean

const dispatch = createEventDispatcher()

let accountName: AuthField = {
	name: 'accountName',
	value: $currAccount.name,
	invalid: false,
	errorMessage: ''
}

let phoneNumber: AuthField = {
	name: 'phoneNumber',
	value: $currAccount.phoneNumber,
	invalid: false,
	errorMessage: ''
}

let website: AuthField = {
	name: 'website',
	value: $currAccount.website,
	invalid: false,
	errorMessage: ''
}
let geolocation: Location = $currAccount.location

let isFormDisabled = false

function cleanInput() {
	isFormDisabled = false
	phoneNumber = authValidation.phoneNumber(phoneNumber)
	accountName = authValidation.username(accountName)
	website = authValidation.url(website)
}

function validateAllFields() {
	phoneNumber = authValidation.phoneNumber(phoneNumber)
	accountName = authValidation.username(accountName)
	website = authValidation.url(website)

	if (phoneNumber.invalid) {
		fireMsg({
			type: 'failure',
			msg: `${$_('problem_edit_account')}`
		})
		return false
	}
	if (accountName.invalid) {
		fireMsg({
			type: 'failure',
			msg: `${$_('problem_edit_account')}`
		})
		return false
	}
	if (website.invalid) {
		fireMsg({
			type: 'failure',
			msg: `${$_('problem_edit_account')}`
		})
		return false
	}
	return true
}

function closeModal(ev: Event) {
	isOpen = false
	ev.preventDefault()
	ev.stopPropagation()

	accountName.invalid = false
	accountName.value = $currAccount.name
	accountName.errorMessage = ''

	website.invalid = false
	website.value = $currAccount.website
	website.errorMessage = ''

	phoneNumber.invalid = false
	phoneNumber.value = $currAccount.phoneNumber
	phoneNumber.errorMessage = ''

	geolocation.address = $currAccount.location ? $currAccount.location.address : ''
	geolocation.geoPoints = $currAccount.location ? $currAccount.location.geoPoints : null

	dispatch('closeModal', { isOpen })
}

async function handleSubmit(ev: Event) {
	isFormDisabled = true
	ev.preventDefault()
	ev.stopPropagation()

	const isFormValid = validateAllFields()
	if (!isFormValid) return (isFormDisabled = false)
	else {
		try {
			let url = `${import.meta.env.VITE_APP_API_URL}/account/${$currAccount.id}`
			let body = {
				name: accountName.value,
				location: geolocation,
				phoneNumber: phoneNumber.value,
				website: website.value
			}
			await utilService.restRequest(url, 'PATCH', body)

			currAccount.update((account) => ({
				...account,
				name: accountName.value,
				phoneNumber: phoneNumber.value,
				website: website.value,
				location: geolocation
			}))
		} finally {
			userMsg.successMsg($_('account_updated'))
			isFormDisabled = false
			closeModal(ev)
		}
	}
}
</script>

{#if isOpen}
	<div
		id="background"
		on:click={closeModal}
		on:keydown={closeModal} />
	<div id="modal">
		<form
			class="flex-column form edit-account-modal"
			on:submit={handleSubmit}>
			<header class="form-header flex">
				<h1 class="title">{$_('edit_account')}</h1>
				<button
					on:click={closeModal}
					class="close-button">
					<Icon
						src={CgClose}
						color="#D8DAE5" />
				</button>
			</header>
			<fieldset class="field account-name flex">
				<label for="account-name">{$_('account_name')}</label>
				<div class="flex-column">
					<input
						placeholder={$_('add_account_name')}
						type="text"
						required
						class="account-name shadow-none"
						class:invalid={accountName.invalid}
						on:focus={() => cleanInput()}
						on:blur={() => (accountName = authValidation.username(accountName))}
						bind:value={accountName.value} />
					<label
						for="account-name"
						role="alert">
						{accountName.errorMessage}
					</label>
				</div>
			</fieldset>
			<fieldset class="field phone-number flex">
				<label for="phone-number">{$_('phone-number')}</label>
				<div class="input-container flex-column">
					<PhoneInput
						on:focus={() => cleanInput()}
						bind:phoneNumber />
					<label
						for="account-name"
						role="alert">
						{phoneNumber.errorMessage}
					</label>
				</div>
			</fieldset>
			<fieldset class="field address flex">
				<label for="address">{$_('address')}</label>
				<div class="location-input">
					<MapBoxLocationInput
						bind:location={geolocation}
						withAccountLocation={true} />
				</div>
			</fieldset>
			<fieldset class="field website flex">
				<label for="website">{$_('website')}</label>
				<div class="flex-column">
					<input
						placeholder={$_('add_website')}
						required
						type="url"
						on:focus={() => cleanInput()}
						on:blur={() => (website = authValidation.url(website))}
						class:invalid={website.invalid}
						class="account-name edit-input shadow-none"
						bind:value={website.value} />
					<label
						for="website"
						role="alert">
						{website.errorMessage}
					</label>
				</div>
			</fieldset>
			<div class="btns-container flex">
				<button
					class="btn theme-btn outline discard"
					on:click={closeModal}>
					{$_('cancel')}
				</button>
				<button
					disabled={isFormDisabled}
					type="submit"
					on:click={handleSubmit}
					class="btn theme-btn invite">{$_('submit')}</button>
			</div>
		</form>
	</div>
{/if}

<style lang="scss">
#background {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
}

#modal {
	position: fixed;
	z-index: 101;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	background: #fff;
	width: 747px;
	max-height: 95vh;
	border-radius: 16px;
	overflow: scroll;

	.edit-account-modal {
		padding: 28px 34px;
		gap: 8px;

		.form-header {
			margin-bottom: 30px;

			.close-button {
				margin: 0 0 0 auto;
			}
		}

		.field {
			gap: 24px;
			height: 65px;
			margin: 0;

			label {
				width: 180px;
				font-size: 16px;
				margin-top: 8px;
				font-family: Nunito-SemiBold;
				color: #33383f;
			}

			input {
				border: 1px solid #e6e8f0;
				border-radius: 6px;
				width: 520px;
				height: 42px;
				flex: 0;

				&::placeholder {
					font-size: 15px;
				}
			}

			&:has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			input.invalid {
				border-color: #b00020;
			}

			label[role='alert'] {
				color: #b00020;
				font-size: 13px;
				margin-top: 0;
				width: 100%;
			}
		}

		.address {
			height: fit-content;
			margin-bottom: 14px;

			label {
				margin-top: 8px;
				width: 175px;
			}

			.location-input {
				width: 100%;
			}
		}

		.btns-container {
			margin: 12px 0 0 auto;
			gap: 12px;
		}
	}

	@media screen and (max-width: 768px) {
		width: 90% !important;

		.field {
			flex-direction: column;
			align-items: flex-start !important;
			width: 100% !important;
			height: 100% !important;
			gap: 0 !important;

			.flex-column {
				width: 100%;
			}
			input {
				width: 100% !important;
			}
			label {
				font-size: 13px !important;
				width: 100% !important;
			}
		}
	}
}
</style>
