import { init, addMessages, locale } from 'svelte-i18n'

import en from '../i18n/locales/en.json'
import he from '../i18n/locales/he.json'
import es from '../i18n/locales/es.json'

const fallbackLocale = 'en';
const lngs = [fallbackLocale, 'es', 'he'];

addMessages('en', en)
addMessages('es', es)
addMessages('he', he)

locale.subscribe((lng) => {
	if (lng) localStorage.setItem('language', lng);
});

let initialLocale;
const detectedLocale = localStorage.getItem('language') || fallbackLocale;
if (lngs.indexOf(detectedLocale) > -1) initialLocale = detectedLocale;
if (!initialLocale && detectedLocale.indexOf('-') > 0) {
	const foundLng = lngs.find((l) => detectedLocale.indexOf(l + '-') === 0);
	if (foundLng) initialLocale = foundLng;
}
if (!initialLocale) initialLocale = fallbackLocale;

init({
	fallbackLocale,
	initialLocale
})
