<script lang="ts">
import type { DogModel } from '../../../models/dog.model'
import NameInitials from '../../common/NameInitials.svelte'
import AgeIcon from './icons/AgeIcon.svelte'
import BreedIcon from './icons/BreedIcon.svelte'
import CakeIcon from './icons/CakeIcon.svelte'
import TypeIcon from './icons/TypeIcon.svelte'
import WeightIcon from './icons/WeightIcon.svelte'
import GenderIcon from './icons/GenderIcon.svelte'
import StatusIcon from './icons/StatusIcon.svelte'
import moment from 'moment'
import { utilService } from '../../../utils/util.service'
import convert from 'convert'
import { _ } from 'svelte-i18n'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

moment.locale(localStorage.getItem('language'))

export let dog: DogModel
function getDogAge(birthDate: Date) {
	const language = localStorage.getItem('language') || 'en' // Default to English if no language is set
	const today = new Date()
	let year = today.getFullYear() - birthDate.getFullYear()
	let month = today.getMonth() - birthDate.getMonth()

	if (month < 0) {
		year--
		month += 12
	}

	let yearLabel = 'y'
	let monthLabel = 'm'

	// Set labels based on language
	switch (language) {
		case 'es':
			yearLabel = 'años'
			monthLabel = 'meses'
			break
		case 'he':
			yearLabel = 'שנים'
			monthLabel = 'חודשים'
			break
		default:
			// English is default, no need to change labels
			break
	}

	return year !== 0 ? `${year} ${yearLabel} ${month} ${monthLabel}` : `${month} ${monthLabel}`
}

function getDogWeight(weight: number) {
	let convertedWeight: number
	convertedWeight = convert(weight, dog.unitOfMeasurement == 'metric' ? 'kg' : 'lb').to(
		localStorage.getItem('measurement-type') === 'metric' ? 'kg' : 'lb'
	) as number
	return `${convertedWeight.toFixed(1)} ${
		localStorage.getItem('measurement-type') === 'metric' ? $_('kg') : $_('lbs')
	}`
}

const infoForDisplay = [
	{
		title: 'birthday',
		value: moment(dog.birthday.toDate()).format('MMMM[,] D YYYY'),
		icon: CakeIcon
	},
	{
		title: 'age',
		value: getDogAge(dog.birthday.toDate()),
		icon: AgeIcon
	},
	{
		title: 'gender',
		value: utilService.capitalizeFirstLetter($_(dog.gender)),
		icon: GenderIcon
	},
	{
		title: 'weight',
		value: getDogWeight(dog.weight),
		icon: WeightIcon
	},
	{
		title: 'breed',
		value: dog.breed.name,
		icon: BreedIcon
	},
	{
		title: 'status',
		value: dog.status,
		icon: StatusIcon
	},
	{
		title: 'type',
		value: dog.type,
		icon: TypeIcon
	}
]
</script>

<section class="dog-info">
	<header class="header">
		<h1 class="title">{$_('dogs_information')}</h1>
	</header>
	<div class="align-center space-around content flex">
		<div class="flex align-center info dog-name">
			<div class="dog-img-container">
				{#if dog.image}
					<img
						src={dog.image}
						alt="dog profile" />
				{:else}
					<NameInitials name={dog.name} />
				{/if}
			</div>
			{dog.name}
		</div>
		{#each infoForDisplay as info}
			{#if info.title != 'type'}
				<div class="flex align-center info {info.title}">
					<div class="svg-container">
						<svelte:component this={info.icon} />
					</div>
					<div class="flex-column description">
						<h1 class="title">{$_(info.title)}</h1>
						<h2 class="value">{utilService.capitalizeFirstLetter(info.value.toString())}</h2>
					</div>
				</div>
			{/if}
		{/each}
	</div>
	<div class="align-center space-around content flex">
		<div class="flex align-center info-type {infoForDisplay[6].title}">
			<div class="svg-container">
				<svelte:component this={infoForDisplay[6].icon} />
			</div>
			<div class="flex-column description types-container">
				<h1 class="title">{$_(infoForDisplay[6].title)}</h1>
				<div class="dog_type-container flex">
					{#each infoForDisplay[6].value as type}
						<p class="dog_type">{$_(`dog_types.${type}`)}</p>
					{/each}
				</div>
			</div>
		</div>
	</div>
</section>

<style lang="scss">
@use '../../../styles/setup/mixins';

.svg-container {
	width: 48px;
	height: 48px;
	min-width: 48px;
}

.description {
	width: fit-content;

	.dog_type-container {
		gap: 0.5rem;
	}

	.title {
		font-size: 14px;
		font-family: Nunito;
		font-weight: normal;
	}

	.value {
		font-size: 18px;
		font-weight: 500;
		min-width: 150px;
	}
}

.info-type {
	flex-basis: 100%;
	padding-inline: 20px;
	margin-bottom: 30px;

	&:not(.dog-name) {
		gap: 16px;
	}

	.dog-img-container {
		width: fit-content;

		.title {
			font-size: 14px;
			font-family: Nunito;
			font-weight: normal;
		}

		.value {
			font-size: 18px;
			font-weight: 500;
			min-width: 150px;
		}
	}
}

.types-container {
	flex-wrap: wrap;
	width: 190px;
	gap: 5px;
}

.dog_type {
	background-color: #fd9703;
	font-size: 13px !important;
	white-space: nowrap;
	border-radius: 6px;
	padding: 5px 7px;
	color: white;
}

.detail {
	text-transform: capitalize;
	color: var(--clr-txt-dark-secondary);

	.title {
		font-weight: 600;
	}
	.value {
		font-size: 15px;
	}
}

.dog-info {
	@include mixins.card;

	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.content {
		display: grid;
		grid-template-columns: repeat(auto-fill, 240px);

		@media screen and (max-width: 768px) {
			padding-inline-start: 18px;
			justify-content: flex-start;
		}

		.info {
			padding-inline: 20px;
			margin-bottom: 30px;

			&:not(.dog-name) {
				gap: 16px;
			}

			.dog-img-container,
			.svg-container {
				width: 48px;
				height: 48px;
				min-width: 48px;
			}

			.description {
				width: fit-content;

				.title {
					font-size: 14px;
					font-family: Nunito;
					font-weight: normal;
				}

				.value {
					font-size: 18px;
					font-weight: 500;
					min-width: 150px;
				}
			}
		}
	}
}
</style>
