<script lang="ts">
import Multiselect from './Multiselect.svelte'
import Slider from './Slider.svelte'
import { multiSelectSlider } from '../../../store/trainingWidgetsStore'

export let values: {
	defaultSliderValue: number
	divisions: number
	max: number
	min: number
	trigger: number
	items: string[]
	label: string
	value: {
		items: string[]
		sliderValue: number
	}
}
export let data = []
export let id: string

let sliderValues: any
let multiselectValues: any

if (!$multiSelectSlider[id]) {
	$multiSelectSlider[id] = {
		defaultSliderValue: values.defaultSliderValue,
		divisions: values.divisions,
		max: values.max,
		min: values.min,
		label: values.label,
		trigger: values.trigger,
		items: values.items,
		value: {
			items: [],
			sliderValue: values.defaultSliderValue
		}
	}
}

if ($multiSelectSlider[id].value.items.length > 0) {
	sliderValues = {
		min: $multiSelectSlider[id].min,
		max: $multiSelectSlider[id].max,
		defaultSliderValue: $multiSelectSlider[id].value.sliderValue.selectedValue,
		divisions: $multiSelectSlider[id].divisions,
		label: values.label,
		value: {
			selectedValue: $multiSelectSlider[id].value.sliderValue.selectedValue,
			label: values.label
		}
	}
} else {
	sliderValues = {
		min: values.min,
		max: values.max,
		defaultSliderValue: values.defaultSliderValue,
		divisions: values.divisions,
		label: values.label,
		value: {
			selectedValue: values.defaultSliderValue,
			label: values.label
		}
	}
}

multiselectValues = $multiSelectSlider[id]
	? {
			items: values.items,
			filled: false,
			returnedValue: $multiSelectSlider[id].value.items,
			value: $multiSelectSlider[id].value.items
		}
	: {
			items: values.items,
			filled: false,
			value: []
		}

values.value = {
	items: multiselectValues.value,
	sliderValue: sliderValues.value
}

$: {
	if (values.value.items.includes(values.items[values.trigger])) {
		values.value = {
			items: multiselectValues.value,
			sliderValue: sliderValues.value
		}
		$multiSelectSlider[id] = values
	} else {
		values.value = {
			items: multiselectValues.value,
			sliderValue: 0
		}
		$multiSelectSlider[id] = values
	}
}
</script>

<Multiselect
	bind:values={multiselectValues}
	{id}
	data={data.length > 0 ? [{ value: data[0].value.items }] : []} />

{#if multiselectValues.value.includes(values.items[values.trigger])}
	<Slider
		bind:values={sliderValues}
		data={data.length > 0 && data[0].value.sliderValue
			? [{ value: { selectedValue: data[0].value.sliderValue.selectedValue } }]
			: []} />
{/if}
