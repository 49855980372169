<script lang="ts">
import { Link, useLocation } from 'svelte-navigator'
import { utilService } from '../../utils/util.service'
import { navBarOpen } from '../../store/stores'

const location = useLocation()
const currPage = $location.pathname
//? Change from full path to parent path to enable active status for nested routs
const rootPath = '/' + currPage.split('/', 2).join('')

export let path: string
export let title: string
export let action: CallableFunction

const handleClick = () => {
	if (action) action()
	if (utilService.isClientInMobile()) setTimeout(() => navBarOpen.set(false), 100)
}
</script>

<Link
	to={path}
	{title}
	class="nav-link {path === rootPath ? 'active' : ''}"
	on:click={handleClick}>
	<slot />
</Link>

<style lang="scss">
:global(.nav-link) {
	display: grid;
	grid-template-columns: 28px 1fr;
	align-items: center;
	width: 100%;
	padding-inline: 16px;
	height: 42px;
	border-radius: 6px;
	gap: 20px;
	transition: color 0.1s ease-out, fill 0.1s ease-out, background-color 0.15s ease-out;
	fill: var(--clr-primary);
	color: #8f95b2;

	&:hover {
		color: #094b68;
	}

	@media screen and (max-width: 768px) {
		gap: 20px !important;
	}
}
:global(.nav-link.active) {
	color: white;
	fill: white;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
}
</style>
