<script lang="ts">
import { compareStore, compareCategory } from './compareStore'
import { onDestroy, onMount } from 'svelte'
import { insightsService } from '../../../services/insights/data.service'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import { _ } from 'svelte-i18n'
import Carousel from '../Carousel.svelte'
import TrainingPercentage from '../TrainingPercentage.svelte'
import LifetimeTrainingComparison from '../LifetimeTrainingComparison.svelte'
import TotalTrainingDays from '../TotalTrainingDays.svelte'
import TotalSessions from '../TotalSessions.svelte'
import TrainingByTypeComparison from './TrainingByTypeComparison.svelte'
import TrainingTypesByTime from './TrainingTypesByTime.svelte'

let element
let category

const compareSubscribe = compareStore.subscribe((value) => {
	element = value
})

const compareCategorySubscribe = compareCategory.subscribe((value) => {
	category = value
})

let data

onMount(async () => {
	data = await insightsService.request()
	compareSubscribe()
	compareCategorySubscribe()
})
</script>

{#if data && $compareStore.length > 1}
	<section class="comparison-container flex-column">
		<Carousel
			comparisonIds={$compareStore}
			category={$compareCategory == 'user' ? 'trainedBy' : $compareCategory}
			arrows={false}
			dots={false}
			swiping={false}
			{data} />
		{#if $compareCategory !== 'training'}
			<h1 class="title">{$_(`insights_pages.total_trainings_by_type`)}</h1>
			<TrainingByTypeComparison {data} />
			<TrainingTypesByTime {data} infoIds={$compareStore} category={$compareCategory}/>
		{/if}
		<TotalSessions
			{data}
			category={$compareCategory}
			comparisonIds={$compareStore} />
		<LifetimeTrainingComparison
			{data}
			category={$compareCategory}
			comparisonIds={$compareStore} />
		<TotalTrainingDays
			{data}
			category={$compareCategory}
			comparisonIds={$compareStore} />
	</section>
{:else}
	<CmpLoader />
{/if}

<style lang="scss">
.comparison-container {
	gap: 20px;

	.title {
		font-family: Montserrat-Medium;
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}
</style>
