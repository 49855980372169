import { authService } from '../../services/core/auth.service'
import DashboardIcon from './icons/DashboardIcon.svelte'
import TeamsIcon from './icons/TeamsIcon.svelte'
import DogsIcon from './icons/DogsIcon.svelte'
import CalendarIcon from './icons/CalendarIcon.svelte'
import InsightsIcon from './icons/InsightsIcon.svelte'
import ReportsIcon from './icons/ReportsIcon.svelte'
import TrainingsIcon from './icons/TrainingsIcon.svelte'
import HelpCenterIcon from './icons/HelpCenterIcon.svelte'
import SettingsIcon from './icons/SettingsIcon.svelte'
import LogoutIcon from './icons/LogoutIcon.svelte'
import UsersIcon from './icons/UsersIcon.svelte'
import type { SvelteComponent } from 'svelte'

interface Link {
	name: string
	path: string
	icon: typeof SvelteComponent
	action?: CallableFunction
}

const linksGroup1: Link[] = [
	{
		name: 'dashboard',
		path: '/',
		icon: DashboardIcon
	},
	{
		name: 'teams',
		path: '/teams',
		icon: TeamsIcon
	},
	{
		name: 'trainings',
		path: '/trainings',
		icon: TrainingsIcon
	},
	{
		name: 'dogs',
		path: '/dogs',
		icon: DogsIcon
	},
	{
		name: 'users',
		path: '/users',
		icon: UsersIcon
	},
	{
		name: 'calendar',
		path: '/calendar',
		icon: CalendarIcon
	},
	{
		name: 'insights',
		path: '/insights',
		icon: InsightsIcon
	},
	{
		name: 'reports',
		path: '/reports',
		icon: ReportsIcon
	}
]

const linksGroup2 = [
	{
		name: 'help_center',
		path: '/help-center',
		icon: HelpCenterIcon
	},
	{
		name: 'settings',
		path: '/settings',
		icon: SettingsIcon
	},
	{
		name: 'log_out',
		path: '',
		icon: LogoutIcon,
		action: () => authService.logout()
	}
]

export const navLinks: Link[][] = [linksGroup1, linksGroup2]
