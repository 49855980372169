import type { SvelteComponent } from 'svelte'
import type { SvgIconName } from '../../models/common.model'
import AddGeneralPerson from '../common/icon/add-general-person.svelte'
import AddGeneralTeam from '../common/icon/add-general-team.svelte'
import AddPolicePerson from '../common/icon/add-police-person.svelte'
import AddTeam from '../common/icon/add-team.svelte'
import DogAlert from '../common/icon/dog-alert.svelte'
import DogBall from '../common/icon/dog-ball.svelte'
import DogScent from '../common/icon/dog-scent.svelte'
import FirePerson from '../common/icon/fire-person.svelte'
import FireTeam from '../common/icon/fire-team.svelte'
import GeneralDog from '../common/icon/general-dog.svelte'
import GeneralTeam from '../common/icon/general-team.svelte'
import MilitaryPerson from '../common/icon/military-person.svelte'
import MilitaryTeam from '../common/icon/military-team.svelte'
import PolicePerson from '../common/icon/police-person.svelte'
import PoliceTeam from '../common/icon/police-team.svelte'
import RemoveTeam from '../common/icon/remove-team.svelte'
import RescueDog from '../common/icon/rescue-dog.svelte'
import RescuePerson from '../common/icon/rescue-person.svelte'
import RescueTeam from '../common/icon/rescue-team.svelte'
import RunningDog from '../common/icon/running-dog.svelte'
import moment from 'moment'

const icons: { name: SvgIconName; icon: typeof SvelteComponent }[] = [
	{
		name: 'add-general-person',
		icon: AddGeneralPerson
	},
	{
		name: 'add-general-team',
		icon: AddGeneralTeam
	},
	{
		name: 'add-police-person',
		icon: AddPolicePerson
	},
	{
		name: 'add-team',
		icon: AddTeam
	},
	{
		name: 'dog-alert',
		icon: DogAlert
	},
	{
		name: 'dog-ball',
		icon: DogBall
	},
	{
		name: 'dog-scent',
		icon: DogScent
	},
	{
		name: 'fire-person',
		icon: FirePerson
	},
	{
		name: 'fire-team',
		icon: FireTeam
	},
	{
		name: 'general-dog',
		icon: GeneralDog
	},
	{
		name: 'general-team',
		icon: GeneralTeam
	},
	{
		name: 'military-person',
		icon: MilitaryPerson
	},
	{
		name: 'military-team',
		icon: MilitaryTeam
	},
	{
		name: 'police-person',
		icon: PolicePerson
	},
	{
		name: 'police-team',
		icon: PoliceTeam
	},
	{
		name: 'remove-team',
		icon: RemoveTeam
	},
	{
		name: 'rescue-dog',
		icon: RescueDog
	},
	{
		name: 'rescue-person',
		icon: RescuePerson
	},
	{
		name: 'rescue-team',
		icon: RescueTeam
	},
	{
		name: 'running-dog',
		icon: RunningDog
	}
]

export let name: SvgIconName
export const width = '24px'
export const theme: 'outline' | 'fill' | 'gradient' = 'gradient'

const getIcon = (name: SvgIconName): typeof SvelteComponent => {
	const selected = icons.find((icon) => icon.name === name)
	return selected ? selected.icon : GeneralTeam
}

function getProperTime(date: Date) {
	return moment().startOf('day').diff(moment(date), 'days') > 11
		? moment(date).format('MMMM D[,] YYYY')
		: moment(date).fromNow()
}

function svelteComponentToHTMLWithStyles(component: typeof SvelteComponent, theme: 'outline' | 'fill' | 'gradient'): string {
	const div = document.createElement('div');
	new component({ target: div });

	if (theme === 'outline') {
		const paths = div.querySelectorAll('svg path') as NodeListOf<SVGPathElement>;
		paths.forEach((path) => {
			path.style.fill = 'none';
			path.style.stroke = 'black';
			path.style.strokeWidth = '8px';
		});
	} else if (theme === 'gradient') {
		const paths = div.querySelectorAll('svg path') as NodeListOf<SVGPathElement>;
		paths.forEach((path) => {
			path.style.fill = 'url(#shape-gradient)';
		});
	}

	return div.innerHTML;
}

export const trainingCellRenderer = (params: any) => {
	if (params.value) return `<div style="display: flex; align-items: center; gap: 8px"><div class="training-img-container flex align-center justify-center" style="background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%); border-radius: 1000px; width: 30px; height: 30px;"><img class="type-icon" style="filter: none; width: 21px; height: 21px; flex-shrink: 0;" src="${params.value.icon}" alt="training profile" /></div><span>${params.value.name}</span></div>`;
};

export const userCellRenderer = (params: any) => {
	if (params.value) return `<div style="width: 30px; height: 100%; display: flex; align-items: center; gap: 6px;">
    <div class="name-initials flex align-center justify-center" style="color: #e76f51; background-color: #fdf1ee; width: 30px; height: 30px; border-radius: 50%; flex-shrink: 0;">
      ${params.value
			.split(' ')
			.slice(0, 2)
			.map((n: string) => n.slice(0, 1))
			.join('')}
    </div>
    <span>${params.value}</span>
  </div>`;
};

export const dogCellRenderer = (params: any) => {
	if (params.value) return `<div style="display: flex; align-items: center; gap: 8px">
    <div style="border-radius: 50%; width: 30px; height: 30px; overflow: hidden;">
      <img src="${params.value.image}"" alt="${params.value.name}" style="width: 100%; height: 100%; object-fit: cover;">
    </div>
    <span>${params.value.name}</span>
  </div>`;
};

export const teamCellRenderer = (params: any) => {
	if (params.value) {
		const iconHTML = svelteComponentToHTMLWithStyles(getIcon(params.value.icon), theme);

		return `
	  <div class="flex align-center" style="height: 100%; padding: 0;">
		<div style="width: 0; height: 0; overflow: hidden;">
		  <svg>
			<defs>
			  <linearGradient id="shape-gradient" y1="100%" y2="0">
				<stop offset="20%" stop-color="#264a59" />
				<stop offset="100%" stop-color="#2d7392" />
			  </linearGradient>
			</defs>
		  </svg>
		</div>
		<div class="flex" style="gap: 10px">
		<div class="svg-icon ${theme}" style="display: grid; width: ${width};">
		  ${iconHTML}
		</div>
		<span>${params.value.name}</span>
		</div>
	  </div>
	`;
	}
};

export const dateCellRenderer = (params) => {
	if (params.value) return `
	<div style="display: flex; align-items: center; gap: 6px">
	<span>${getProperTime(params.value.toDate())}</span>
	</div>`
}

export const dogRatingCellRenderer = (params) => {
	if (params.value) {
		const starRating = params.value
		const stars = Array.from(
			{ length: starRating },
			() =>
				'<img src="../../../assets/img/star-icon.png" alt="" style="margin-top: -1px; height: 15px; width: 15px;"/>'
		).join('')
		return `<div style="height: 100%; display: flex; padding-top: 1px; justify-content: center;"><div class="flex align-center justify-column" style="min-width: 114px">${stars}</div></div>`
	}
	else return
}

export const userRatingCellRenderer = (params) => {
	if (params.value) {
		const icons = [
			'../../../assets/smiles/Smile1.svg',
			'../../../assets/smiles/Smile2.svg',
			'../../../assets/smiles/Smile3.svg',
			'../../../assets/smiles/Smile4.svg',
			'../../../assets/smiles/Smile5.svg'
		]
		return `<div class="flex align-center justify-center" style="min-width: 60px; height: 100%; padding-top: 1px;"><img src=${icons[params.value - 1]
			} style="margin-top: 0px; height: 20px; width: 20px" alt="s" /> </div>`
	} else return
}
