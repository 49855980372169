<script lang="ts">
import BullishIcon from './icons/BullishIcon.svelte'
import DogsIcon from './icons/DogsIcon.svelte'
import LayerIcon from './icons/LayerIcon.svelte'
import TimeIcon from './icons/TimeIcon.svelte'
import UsersIcon from './icons/UsersIcon.svelte'
import TeamsIcon from './icons/TeamsIcon.svelte'
import { currAccount } from '../../store/stores'
import QuickInsights from '../common/QuickInsights.svelte'
import type { AccountStats } from '../../models/account.model'
import { _ } from 'svelte-i18n'

$: ({ teamIds, userIds, dogIds, stats } = $currAccount)

$: insights = buildQuickInsights(teamIds, userIds, dogIds, stats)

function buildQuickInsights(
	teamIds: Array<string>,
	userIds: Array<string>,
	dogIds: Array<string>,
	stats: AccountStats
) {
	const totals = [
		{
			title: 'total_teams',
			value: teamIds ? teamIds.length : 0,
			icon: TeamsIcon
		},
		{
			title: 'total_users',
			value: userIds.length,
			icon: UsersIcon
		},
		{
			title: 'total_dogs',
			value: dogIds.length,
			icon: DogsIcon
		},
		{
			title: 'training_days',
			value: stats.totalTrainingDays,
			icon: LayerIcon
		},
		{
			title: 'training_hours',
			value: (stats.totalTrainingTime / 60).toFixed(),
			icon: TimeIcon
		},
		{
			title: 'total_trainings',
			value: stats.totalTrainingSessions,
			icon: BullishIcon
		}
	]
	return totals
}
</script>

<div class="dashboard-quick-insights">
	<QuickInsights
		{insights}
		titleName={$_('account_quick_insights')} />
</div>
