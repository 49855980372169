<script lang="ts">
import type { DogModel, DogStats } from '../../../models/dog.model'
import BullishIcon from '../../dashboard/icons/BullishIcon.svelte'
import TimeIcon from '../../dashboard/icons/TimeIcon.svelte'
import QuickInsights from '../../common/QuickInsights.svelte'
import CalendarIcon from '../../dashboard/icons/CalendarIcon.svelte'
import LastTrainingsIcon from '../../users/user/icons/LastTrainingsIcon.svelte'
import moment from 'moment'
import { _ } from 'svelte-i18n'

import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale
moment.locale(localStorage.getItem('language'))

export let dog: DogModel

$: insights = dog && buildQuickInsights(dog.stats)

let totalHours = Math.floor(dog.stats.totalTrainingTime / 60)

function buildQuickInsights(stats: DogStats) {
	const totals = [
		{
			title: 'training_hours',
			value: `${totalHours}h ${stats.totalTrainingTime - totalHours * 60}min`,
			icon: TimeIcon
		},
		{
			title: 'training_days',
			value: stats.totalTrainingDays,
			icon: CalendarIcon
		},
		{
			title: 'total_trainings',
			value: stats.totalTrainingSessions,
			icon: BullishIcon
		},
		{
			title: 'last_trained',
			value: stats.recentTraining
				? moment(stats.recentTraining.toDate()).format('MMMM[,] D YYYY')
				: 'N/A',
			icon: LastTrainingsIcon
		}
	]
	return totals
}
</script>

<div class="dog-quick-insights">
	<QuickInsights
		{insights}
		titleName={$_('dog_quick_insights')} />
</div>
