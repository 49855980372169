<svg
	width="80"
	height="80"
	viewBox="0 0 80 80"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#F96E6F"
		fill-opacity="0.07" />
	<rect
		width="80"
		height="80"
		rx="40"
		fill="#F96E6F"
		fill-opacity="0.07" />
	<path
		opacity="0.5"
		d="M60 40C60 51.0457 51.0457 60 40 60C28.9543 60 20 51.0457 20 40C20 28.9543 28.9543 20 40 20C51.0457 20 60 28.9543 60 40Z"
		fill="#EF4F50" />
	<path
		d="M40 31.5C38.7574 31.5 37.75 32.5074 37.75 33.75C37.75 34.5784 37.0784 35.25 36.25 35.25C35.4216 35.25 34.75 34.5784 34.75 33.75C34.75 30.8505 37.1005 28.5 40 28.5C42.8995 28.5 45.25 30.8505 45.25 33.75C45.25 35.1717 44.6829 36.464 43.7659 37.4079C43.5814 37.5979 43.4054 37.7737 43.2374 37.9415C42.8058 38.3728 42.4276 38.7507 42.0958 39.177C41.6577 39.7398 41.5 40.1535 41.5 40.5V42C41.5 42.8284 40.8284 43.5 40 43.5C39.1716 43.5 38.5 42.8284 38.5 42V40.5C38.5 39.1896 39.11 38.1289 39.7283 37.3344C40.1858 36.7466 40.7609 36.1727 41.2276 35.7069C41.3683 35.5664 41.4992 35.4358 41.6141 35.3175C42.0092 34.9109 42.25 34.3601 42.25 33.75C42.25 32.5074 41.2426 31.5 40 31.5Z"
		fill="#EF4F50" />
	<path
		d="M40 50C41.1046 50 42 49.1046 42 48C42 46.8954 41.1046 46 40 46C38.8954 46 38 46.8954 38 48C38 49.1046 38.8954 50 40 50Z"
		fill="#EF4F50" />
</svg>
