import { activityDB } from '../database/activity.db'
import { userMsg } from '../../utils/userMsg.service'

export const activityService = {
  queryAccountActivities,
  queryAccountTeamActivities
}

// Function that gets teamDogs from firebase and returns them as an array
async function queryAccountActivities(accountId: string, itemsPerPage: number) {
  const accountActivities = []

  try {
    const accountActivitiesDocs = await activityDB.getManyActivities(accountId, itemsPerPage)
    accountActivitiesDocs.forEach(doc => accountActivities.push(doc.data()))
    return accountActivities
  } catch (error: unknown) {
    userMsg.errorMsg('Cannot get account activities', error)
  }
}

async function queryAccountTeamActivities(accountId: string, teamId: string, itemsPerPage: number) {
  const accountTeamActivities = []

  try {
    const accountTeamActivitiesDocs = await activityDB.getManyTeamActivities(accountId, teamId, itemsPerPage)
    accountTeamActivitiesDocs.forEach(doc => accountTeamActivities.push(doc.data()))
    return accountTeamActivities
  } catch (error: unknown) {
    userMsg.errorMsg('Cannot get account activities', error)
  }
}