<script lang="ts">
import type { TrainingModel } from '../../../services/model/training.model'
import { _ } from 'svelte-i18n'

export let user: TrainingModel

const rating: number = Math.round(user.trainedByRating)
</script>

<div class="dog-detail">
	<section class="details">
		<header class="header">
			<h1 class="title">{$_('how_are_you_feeling')}</h1>
		</header>
		<div class="user-rating-info content flex align-center justify-center">
			<div class="smiley-rating flex">
				<img
					src="../../../assets/smiles/Smile1.svg"
					alt="crying emoji"
					class="emoji-img"
					style={rating === 1 ? 'width: 60px; aspect-ratio: 1' : ''} />
				<img
					src="../../../assets/smiles/Smile2.svg"
					alt="unhappy emoji"
					class="emoji-img"
					style={rating === 2 ? 'width: 60px; aspect-ratio: 1' : ''} />
				<img
					src="../../../assets/smiles/Smile3.svg"
					alt="normal emoji"
					class="emoji-img"
					style={rating === 3 ? 'width: 60px; aspect-ratio: 1' : ''} />
				<img
					src="../../../assets/smiles/Smile4.svg"
					alt="smiling emoji"
					class="emoji-img"
					style={rating === 4 ? 'width: 60px; aspect-ratio: 1' : ''} />
				<img
					src="../../../assets/smiles/Smile5.svg"
					alt="happy emoji"
					class="emoji-img"
					style={rating === 5 ? 'width: 60px; aspect-ratio: 1' : ''} />
			</div>
		</div>
	</section>
</div>

<style lang="scss">
@use '../../../styles/setup/mixins';

.dog-detail {
	height: 100%;

	.details {
		@include mixins.card;
		height: 170px;

		.header {
			padding: 8px 12px 22px;
			color: var(--clr-txt-dark-primary);
			h1 {
				font-size: 22px;
			}
		}
		.content {
			margin: 15px 0 0 -30px;
			gap: 10px;

			.smiley-rating {
				align-self: center;
				gap: 5px;
			}
		}
	}
}

.emoji-img {
	width: 45px;
	aspect-ratio: 1;
}

@media (max-width: 1500px) {
	.user-name {
		display: none;
	}
}
@media (max-width: 1200px) and (min-width: 768px) {
	.dog-detail {
		margin-right: -12px !important;
	}
	.user-name {
		display: block;
	}
}

@media (max-width: 900px) {
	.user-name,
	.user-image {
		display: none;
	}
	.smiley-rating {
		padding-left: 0 !important;
	}
}
</style>
