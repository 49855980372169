<script lang="ts">
import { _ } from 'svelte-i18n'

export let loadFunc
export let itemsOnPage: number
export let listLength: number
</script>

{#if itemsOnPage}
	<div class="button-container flex align-center justify-center">
		<button
			class="btn theme-btn submit load-more-btn"
			disabled={itemsOnPage > listLength}
			on:click={loadFunc}>
			{$_('Load More')}
		</button>
	</div>
{/if}

<style lang="scss">
.button-container {
	.load-more-btn:disabled {
		display: none;
	}
}
</style>
