<script lang="ts">
	import { onMount, onDestroy } from 'svelte'
	import { insightsService } from '../services/insights/data.service'
	import CmpLoader from '../cmps/common/loader/CmpLoader.svelte'
	import InsightsCategorySelector from '../cmps/insights/InsightsCategorySelector.svelte'
	import LifetimeTrainingComparison from '../cmps/insights/LifetimeTrainingComparison.svelte'
	import TotalTrainingTime from '../cmps/insights/TotalTrainingTime.svelte'
	import TotalSessions from '../cmps/insights/TotalSessions.svelte'
	import TotalTrainingDays from '../cmps/insights/TotalTrainingDays.svelte'
	import Carousel from '../cmps/insights/Carousel.svelte'
	import { currAccount, navBarOpen } from '../store/stores'
	import DemoInsights from '../cmps/insights/demo/DemoInsights.svelte'
	import { categoryStore, updateCategory } from '../cmps/insights/categoryStore'
	import ProfileCard from '../cmps/insights/ProfileCard.svelte'
	import TrainingLocation from '../cmps/insights/TrainingLocation.svelte'
	import { loggedInUser } from '../store/stores'
	import * as Sentry from '@sentry/browser'
	import * as Highcharts from 'highcharts'
import { paddleService } from '../services/APIs/paddle.service'

	let data

	const categories = ['all', 'team', 'trainedBy', 'dog', 'training']
	let category = 'all'

	const token = import.meta.env?.VITE_APP_PADDLE_TOKEN

	onMount(async () => {
		if (localStorage.getItem('language') == 'en') {
			Highcharts.setOptions({
				lang: {
					viewFullscreen: 'View in full screen',
					zoomIn: 'Zoom in',
					zoomOut: 'Zoom out',
					contextButtonTitle: 'Options',
					downloadJPEG: 'Download as JPEG',
					downloadPDF: 'Download as PDF',
					downloadPNG: 'Download as PNG',
					downloadSVG: 'Download as SVG',
					downloadCSV: 'Download CSV',
					downloadXLS: 'Download XLS',
					viewData: 'View data table',
					printChart: 'Print chart',
					resetZoom: 'Reset zoom',
					resetZoomTitle: 'Reset zoom level 1:1'
				}
			})
		} else if (localStorage.getItem('language') == 'es') {
			Highcharts.setOptions({
				lang: {
					viewFullscreen: 'Ver en pantalla completa',
					zoomIn: 'Acercarse',
					zoomOut: 'Disminuir el zoom',
					contextButtonTitle: 'Opciones',
					downloadJPEG: 'Descargar como JPEG',
					downloadPDF: 'Descargar como PDF',
					downloadPNG: 'Descargar como PNG',
					downloadSVG: 'Descargar como SVG',
					downloadCSV: 'Descargar como CSV',
					downloadXLS: 'Descargar XLS',
					viewData: 'Ver tabla de datos',
					printChart: 'Tabla de impresión',
					resetZoom: 'Restablecer zoom',
					resetZoomTitle: 'Restablecer nivel de zoom 1:1'
				}
			})
		} else if (localStorage.getItem('language') == 'he') {
			Highcharts.setOptions({
				lang: {
					viewFullscreen: 'צפה במסך מלא',
					zoomIn: 'לְהִתְמַקֵד',
					zoomOut: 'להקטין את התצוגה',
					contextButtonTitle: 'אפשרויות',
					downloadJPEG: 'JPEG-הורד כ',
					downloadPDF: 'PDF-הורד כ',
					downloadPNG: 'PNG-הורד כ',
					downloadSVG: 'SVG-הורד כ',
					downloadCSV: 'CSV-הורד כ',
					downloadXLS: 'XLS-הורד כ',
					viewData: 'הצג את טבלת הנתונים',
					printChart: 'הדפס תרשים',
					resetZoom: 'אפס זום',
					resetZoomTitle: 'אפס את רמת הזום 1:1'
				}
			})
		}
		try {
			// Gets the data
			data = await insightsService.request()
			category = $categoryStore
		} catch (err) {
			Sentry.captureException(err)
		}
	})

	onDestroy(() => {
		categoryStore.set('all')
	})

	$: accountStats = {
		name: $currAccount.name,
		trainingTime: $currAccount.stats.totalTrainingTime,
		trainingSessions: $currAccount.stats.totalTrainingSessions
			? $currAccount.stats.totalTrainingSessions
			: 0,
		days: data ? insightsService.countTrainingDays(data) : 0
	}
	</script>

	{#if data}
		{#if $currAccount.stats.totalTrainingSessions > 14}
			<div
				class="insights flex-column"
				class:nav-open={$navBarOpen}>
				<div class="category-selector">
					<InsightsCategorySelector
						activeCategory={category}
						{categories}
						on:select={(ev) => {
							category = ev.detail
							updateCategory(category)
						}} />
				</div>
				{#if category == 'all'}
					<ProfileCard element={accountStats} />
					<TotalSessions {data} />
					<TotalTrainingTime {data} />
					<TotalTrainingDays {data} />
					<TrainingLocation {data} />
				{:else if category == 'trainedBy'}
					<Carousel
						{category}
						{data} />
					<TotalSessions
						{category}
						{data} />
					<LifetimeTrainingComparison
						{data}
						{category} />
					<TotalTrainingDays
						{data}
						{category} />
				{:else if category == 'dog'}
					<Carousel
						{category}
						{data} />
					<TotalSessions
						{category}
						{data} />
					<LifetimeTrainingComparison
						{data}
						{category} />
					<TotalTrainingDays
						{data}
						{category} />
				{:else if category == 'team'}
					<Carousel
						{category}
						{data} />
					<TotalSessions
						{category}
						{data} />
					<LifetimeTrainingComparison
						{data}
						{category} />
					<TotalTrainingDays
						{data}
						{category} />
				{:else if category == 'training'}
					<Carousel
						{category}
						{data} />
					<TotalSessions
						{category}
						{data} />
					<LifetimeTrainingComparison
						{data}
						{category} />
					<TotalTrainingDays
						{data}
						{category} />
				{/if}
				<!-- <Leaderboard {data} /> -->
			</div>
		{:else}
			<div class="no-trainings">
				<DemoInsights />
			</div>
		{/if}
	{:else}
		<CmpLoader />
	{/if}

	<style lang="scss">
	@use '../styles/setup/mixins';

	.insights {
		gap: 20px;

		&.nav-open {
			width: calc(100vw - 312px);
		}

		&:not(.nav-open) {
			width: calc(100vw - 122px);
		}

		@media screen and (max-width: 768px) {
			&.nav-open,
			&:not(.nav-open) {
				width: calc(100vw - 56px);
			}
		}
	}

	.no-trainings {
		height: 600px;
		border-radius: 6px;
	}
	</style>