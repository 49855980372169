<!-- TO-DO Finish addTrainings page and implement a validation for the fields -->
<script lang="ts">
import { Timestamp } from 'firebase/firestore'
import { onMount } from 'svelte'
import { useNavigate, useParams } from 'svelte-navigator'
import type { TeamModel } from '../../../models/team.model'
import { currAccount, loggedInUser } from '../../../store/stores'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import type {
	TrainingEvent,
	TrainingData,
	TrainingModel
} from '../../../models/training.model'
import type { DogModel } from '../../../models/dog.model'
import dogBreeds from '../../../data/fci-dog-breeds.json'
import { trainingService } from '../../../services/core/training.service'
import { teamService } from '../../../services/core/team.service'
import { _ } from 'svelte-i18n'
import MapBoxLocationInput from '../../common/MapBoxLocationInput.svelte'
import { utilService } from '../../../utils/util.service'
import StepWizard from 'svelte-step-wizard'
import StarInput from './StarInput.svelte'
import LoadWidgets from './LoadWidgets.svelte'
import DatePicker from '../../common/DatePicker.svelte'
import { fireMsg, userMsg } from '../../../utils/userMsg.service'
import CustomImageInput from './../../common/CustomImageInput.svelte'
import StepPanel from './StepPanel.svelte'
import SelectDog from './SelectDog.svelte'
import SelectTeam from './SelectTeam.svelte'
import Icon from 'svelte-icons-pack'
import RiSystemErrorWarningLine from 'svelte-icons-pack/ri/RiSystemErrorWarningLine'
import Flatpickr from 'svelte-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { formatDate } from 'svelty-picker'
import { en } from 'svelty-picker/i18n'
import { clearAllStores } from '../../../store/trainingWidgetsStore'
import { onDestroy } from 'svelte'
import SelectCategory from './SelectCategory.svelte'
import ChangedTypeAlert from './ChangedTypeAlert.svelte'
import UploadsList from './UploadsList.svelte'

const params = useParams()
const locationEnabled = {
	gpsEnabled: localStorage.getItem('gps-enabled') ? localStorage.getItem('gps-enabled') : 'yes',
	addressEnabled: localStorage.getItem('address-enabled')
		? localStorage.getItem('address-enabled')
		: 'yes'
}

onDestroy(() => {
	clearAllStores()
})

var training: TrainingModel = {
	accountId: '',
	fileUploads: {},
	createdAt: Timestamp.now(),
	data: [],
	dog: {
		name: '',
		id: '',
		image: '',
		type: []
	},
	dogRating: 1,
	id: '',
	iconUrl: '',
	location: {
		address: '',
		geoPoints: null
	},
	trainingTypeId: '',
	notes: '',
	team: {
		icon: '',
		id: '',
		name: ''
	},
	timesOfDay: 'midday',
	dateTime: Timestamp.now(),
	trainedBy: {
		image: '',
		id: '',
		name: '',
		type: ''
	},
	trainedByRating: 1,
	trainingTime: 0,
	type: '',
	event: 'training',
	unitsOfMeasurement: '',
	updatedAt: Timestamp.now(),
	weather: {
		data: [],
		lat: 0,
		lon: 0,
		timezone: '',
		timezone_offset: 0
	},
	airQuality: '',
	season: '',
	trainingTypeName: ''
}

let showAlert = false

let trainingTypes: any
let selectedTrainingType: any

let teamsOptions: TeamModel[]
let teamsOptionsPromise: Promise<TeamModel[]>
let teamsForDisplay: Array<{ value: string; label: string }>

async function getTeams() {
	teamsOptionsPromise = teamService.query($loggedInUser.id, $loggedInUser.selectedAccount.id)
	teamsOptions = await teamsOptionsPromise
}

async function loadTraining() {
	training = await trainingService.getById($params.id)
	bindedValues = {
		team: { value: training.team.id, label: training.team.name },
		type: {
			value: training.id,
			label: training.type
		},
		dog: { value: training.dog.id, label: training.dog.name }
	}
	date = training.dateTime.toDate()
}

async function getTrainingTypes(category: string, accountId: string) {
	const trainingTypes = await trainingService.getTrainingTypes(category, accountId)
	return trainingTypes
}

onMount(async () => {
	window.scrollTo(0, 0)
	loadTraining()
	getTeams().then(() => {
		teamsForDisplay = teamsOptions.map((team) => ({
			label: team.name,
			value: team.id
		}))
	})
	trainingTypes = await getTrainingTypes('public', '')
	selectedTrainingType = trainingTypes
		.map((type) => {
			if (type.id === training.trainingTypeId) {
				return type
			}
		})
		.filter((type) => type != undefined)
	if (selectedTrainingType.length == 0) {
		trainingTypes = await getTrainingTypes('private', $currAccount.id)
		selectedTrainingType = trainingTypes
			.map((type) => {
				if (type.id === training.trainingTypeId) {
					return type
				}
			})
			.filter((type) => type != undefined)
	}
	compareTypes(selectedTrainingType[0].widgets, training.data)
	event = { value: training.event, label: utilService.capitalizeFirstLetter(training.event) }
})

type InputIds = ['type', 'team', 'dog', 'sessionTime', 'dogRating', 'trainedByRating']
type InputId = InputIds[number]

const fieldsValidation = {
	type: { invalid: false, errorMessage: '' },
	team: { invalid: false, errorMessage: '' },
	dog: { invalid: false, errorMessage: '' },
	sessionTime: { invalid: false, errorMessage: '' },
	dogRating: { invalid: false, errorMessage: '' },
	trainedByRating: { invalid: false, errorMessage: '' }
}

async function validateInput(inputId: InputId) {
	switch (inputId) {
		case 'type':
			if (!bindedValues.type.label) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_type_selected_message')
				return
			}
			break

		case 'team':
			if (!bindedValues.team.value) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_team_selected_message')
				return
			}
			break

		case 'dog':
			if (!bindedValues.dog.value) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_dog_selected_message')
				return
			}
			break

		case 'sessionTime':
			if (!training.trainingTime || training.trainingTime < 1) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('wrong_session_time_message')
				return
			}
			break

		case 'dogRating':
			if (!training.dogRating) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_dog_rating_message')
				return
			}
			break

		case 'trainedByRating':
			if (!training.trainedByRating) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_trained_by_rating_message')
				return
			}
			break

		default:
			break
	}
	fieldsValidation[inputId].invalid = false
	fieldsValidation[inputId].errorMessage = ''
}

async function validateAllFields() {
	let isValid = true
	for (const fieldId in fieldsValidation) {
		if (Object.prototype.hasOwnProperty.call(fieldsValidation, fieldId)) {
			await validateInput(fieldId as InputId)
			if (fieldsValidation[fieldId].invalid) {
				fireMsg({
					type: 'failure',
					msg: `Error in field: ${fieldId}, ${fieldsValidation[fieldId].errorMessage}`
				})
				isValid = false
			}
		}
	}
	return isValid
}

const navigate = useNavigate()

var isButtonDisabled: boolean

var date: any = formatDate(new Date(), 'dd/mm/yyyy', en, 'standard')
var time: any = ''

let dogsForDisplay: Array<{ value: string; label: string; image: string }> = []

var bindedValues = {
	team: { value: '', label: $_('please_select') },
	type: {
		value: 'asd',
		label: 'asd'
	},
	dog: { value: '', label: $_('please_select') }
}

let data = []
let teamDogs: DogModel[] = []

let event: { value: TrainingEvent; label: TrainingEvent } = {
	value: 'training',
	label: 'training'
}

let searchBounceTimeout: ReturnType<typeof setTimeout>
let newDogId: string

let category: { value: TrainingEvent; label: TrainingEvent }

let isFormDisabled = false

let breedOptions = dogBreeds
let dogBreedName = { value: '', label: $_('type_to_search') }

async function handleSubmit(ev: Event) {
	isFormDisabled = true
	ev.preventDefault()
	ev.stopPropagation()

	const isFormValid = await validateAllFields()
	if (!isFormValid) return (isFormDisabled = false)

	teamDogs
		.filter((dog) => dog.id == bindedValues.dog.value)
		.forEach((dog) => {
			training.dog.id = dog.id
			training.dog.name = dog.name
			training.dog.image = dog.image
			training.dog.type = dog.type
		})
	teamsOptions
		.filter((team) => team.id == bindedValues.team.value)
		.forEach((team) => {
			training.team.id = team.id
			training.team.icon = team.icon
			training.team.name = team.name
		})

	training.type = bindedValues.type.label
	training.dateTime = Timestamp.fromDate(utilService.combineDateTime(date, time))

	if (newDogId) training.id = newDogId

	training.event = event.value

	try {
		let dataArr = data.map((widget) => {
			let dataObj: TrainingData = {
				id: widget.id,
				name: widget.name,
				type: widget.type,
				value: widget.values.map((value) => {
					return value.value
				})[0]
			}
			if (widget.label) {
				dataObj.label = widget.label
			}
			if (widget.dataText) {
				dataObj.dataText = widget.dataText
			}
			if (widget.numValue) {
				dataObj.numValue = widget.numValue
			}
			return dataObj
		})
		const url = `${import.meta.env.VITE_APP_API_URL}/training/${training.id}`
		const body = {
			event: training.event,
			dogRating: training.dogRating,
			trainedByRating: training.trainedByRating,
			dog: {
				name: training.dog.name,
				type: training.dog.type,
				id: training.dog.id,
				image: training.dog.image
			},
			location: training.location,
			data: dataArr,
			unitsOfMeasurement: localStorage.getItem('measurement-type')
				? localStorage.getItem('measurement-type')
				: 'metric',
			dateTime: utilService.combineDateTime(date, time),
			updateUser: {
				id: $loggedInUser.id,
				name: $loggedInUser.firstName + ' ' + $loggedInUser.lastName
			},
			team: {
				name: training.team.name,
				id: training.team.id,
				icon: training.team.icon
			},
			notes: training.notes
		}
		await utilService.restRequest(url, 'PATCH', body)
		localStorage.removeItem('training')
		clearAllStores()
		navigate('/trainings')
		isFormDisabled = false
		userMsg.successMsg(`${$_('training_created')}`)
	} catch (err: unknown) {
		userMsg.errorMsg('Error', err)
		isFormDisabled = false
	}
}

// TODO(or-agami): Change searchBreed to query from DB once DB breed collection is ready
async function searchBreed(input: string) {
	clearTimeout(searchBounceTimeout)
	if (input === '') return (breedOptions = dogBreeds)

	//? Return Promise to mock breed query from DB
	return new Promise((resolve) => {
		breedOptions = dogBreeds.filter((breed) =>
			breed.name?.toLowerCase().includes(dogBreedName.label.toLowerCase())
		)

		//? Timeout for data retrieve                               👇
		searchBounceTimeout = setTimeout(() => resolve(breedOptions), 400)
	})
}

function compareTypes(arr1, arr2) {
	// Check if both arrays have the same length
	if (arr1.length !== arr2.length) {
		showAlert = false
	}

	// Create arrays of "type" values for both arrays
	const types1 = arr1.map((obj) => obj.type)
	const types2 = arr2.map((obj) => obj.type)

	// Sort the arrays of "type" values
	types1.sort()
	types2.sort()

	// Convert the sorted arrays to strings and compare them
	showAlert = JSON.stringify(types1) === JSON.stringify(types2) ? false : true
}

function onAlertConfirm() {
	showAlert = false
}

function onModalCancel() {
	clearAllStores()
	navigate(-1)
}

$: searchBreed(dogBreedName.label)
</script>

{#if !isFormDisabled}
	{#if !training.type}
		<CmpLoader />
	{:else}
		<div class="training-add">
			<div class="flex-column form-container">
				<StepWizard initialStep={1}>
					<header class="flex align-center form-header">
						<h1 class="title">{bindedValues.type.label}</h1>
					</header>
					<StepWizard.Step
						num={1}
						let:nextStep>
						<form
							on:submit={handleSubmit}
							class="flex-column justify-center dog-edit-form">
							<StepPanel stepNumber="1" />
							<div class="field type">
								<label for="type">{$_('event')} *</label>
								<SelectCategory bind:category={event} />
							</div>

							<div class="field team">
								<label for="team">{$_('team')} *</label>
								<SelectTeam
									{teamsForDisplay}
									bind:dogsForDisplay
									bind:selectedTeam={bindedValues.team.value}
									bind:teamDogs />
								<label
									for="team"
									role="alert" />
							</div>

							<div class="field dog">
								<label for="dog">{$_('dog')} *</label>
								<SelectDog
									objectsForDisplay={dogsForDisplay}
									bind:selectedDog={bindedValues.dog.value} />
								<label
									for="dog"
									role="alert" />
							</div>

							<div class="field trained-by">
								<label for="trained-by">{$_('trained_by')}</label>
								<div class="flex align-center double-input">
									<input
										class="edit-input"
										type="text"
										style="background: rgba(143, 149, 178, 0.13);
                  ; border: 1px solid #E6E8F0; height: 43px; font-family: Nunito"
										disabled={true}
										placeholder={$loggedInUser.firstName + ' ' + $loggedInUser.lastName}
										id="trained-by" />
								</div>
							</div>

							<div class="field date-time">
								<label for="date-time">{$_('date_time')} *</label>
								<Flatpickr
									class="edit-input"
									options={{
										enableTime: false,
										allowInput: true,
										dateFormat: 'd/m/Y',
										defaultDate: date
									}}
									bind:value={date}
									name="date"
									placeholder="dd/mm/yyyy" />
								<DatePicker
									options={{
										id: 'picker2',
										timepicker: true,
										showDate: true,
										onlyTimepicker: true
									}}
									initialDate={training.dateTime.toDate()}
									bind:dateTime={time} />
								<label
									for="date-time"
									role="alert" />
							</div>

							<div class="field location">
								<label for="location">{$_('location')}</label>
								{#if locationEnabled.gpsEnabled == 'yes'}
									<div class="flex-column input-container">
										<MapBoxLocationInput bind:location={training.location} />
									</div>
								{:else if locationEnabled.gpsEnabled == 'no' && locationEnabled.addressEnabled == 'yes'}
									<MapBoxLocationInput
										showMap={false}
										bind:location={training.location} />
								{:else}
									<div class="gps-disabled flex align-center">
										<Icon
											color="var(--clr-txt-dark-secondary)"
											src={RiSystemErrorWarningLine} />
										<p>
											Location services are turned off. This will affect access to weather, terrain,
											and location data.
										</p>
									</div>
								{/if}
							</div>
							<div class="flex align-center btns-container">
								<button
									on:click={() => {
										window.scrollTo({ top: 0, behavior: 'smooth' })
										nextStep()
									}}
									disabled={bindedValues.type.label == '' ||
										bindedValues.team.value == '' ||
										bindedValues.dog.value == '' ||
										!time ||
										!date}
									class="btn theme-btn submit">
									{$_('next_step')}
								</button>
							</div>
						</form>
					</StepWizard.Step>

					<StepWizard.Step
						num={2}
						let:previousStep
						let:nextStep>
						{#if showAlert}
							<ChangedTypeAlert
								message={$_('training_type_changed_message')}
								onConfirm={onAlertConfirm}
								onCancel={onModalCancel} />
						{/if}
						<form class="flex-column justify-center training-widgets-form">
							<StepPanel stepNumber="2" />
							<LoadWidgets
								trainingType={selectedTrainingType}
								bind:presentTypes={data}
								bind:isButtonDisabled
								bind:data={training.data} />
							<div class="flex align-center btns-container">
								<button
									on:click={() => {
										window.scrollTo({ top: 0, behavior: 'smooth' })
										previousStep()
									}}
									class="btn theme-btn outline discard">
									{$_('go_back')}
								</button>

								<button
									on:click={() => {
										window.scrollTo({ top: 0, behavior: 'smooth' })
										nextStep()
									}}
									class="btn theme-btn submit"
									disabled={isButtonDisabled}>
									{$_('next_step')}
								</button>
							</div>
						</form>
					</StepWizard.Step>

					<StepWizard.Step
						num={3}
						let:previousStep>
						<form class="flex-column justify-center dog-edit-form">
							<StepPanel stepNumber="3" />
							<div class="field notes">
								<label for="notes">{$_('notes')}</label>
								<textarea
									class="edit-input"
									rows="5"
									bind:value={training.notes}
									id="notes" />
								<label
									for="notes"
									role="alert" />
							</div>
							<UploadsList
								isInEdit={true}
								fileUploads={training.fileUploads}
								trainingId={training.id} />
							<div class="dog-rating flex-column">
								<p>{$_('how_was_the_dog')} *</p>
								<span><StarInput bind:score={training.dogRating} /></span>
							</div>
							<div class="trained-by-rating flex-column">
								<p>{$_('how_do_you_feel')} *</p>
								<CustomImageInput bind:rating={training.trainedByRating} />
							</div>
							<div class="flex align-center btns-container">
								<button
									type="button"
									on:click={() => {
										window.scrollTo({ top: 0, behavior: 'smooth' })
										previousStep()
									}}
									class="btn theme-btn outline discard">
									{$_('go_back')}
								</button>
								<button
									disabled={isFormDisabled}
									type="button"
									class="btn theme-btn submit"
									on:click={handleSubmit}>
									{$_('submit')}
								</button>
							</div>
						</form>
					</StepWizard.Step>
				</StepWizard>
			</div>
		</div>
	{/if}
{:else}
	<span class="flex align-center justify-center">
		<CmpLoader />
	</span>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.training-add {
	@include mixins.card;
	margin-inline: auto;

	.form-header {
		padding: 24px;
	}

	.dog-edit-form,
	.training-widgets-form {
		width: 100%;
		gap: 20px;
		padding: 28px 24px;

		.btns-container {
			justify-content: flex-end;
			gap: 12px;
		}

		.field {
			display: flex;
			flex-direction: column;
			gap: 6px;

			label {
				font-size: 13px;
				font-family: Montserrat-Medium;
				text-transform: capitalize;
				color: #33383f;
				font-style: normal;
				font-weight: 600;
				line-height: 24px;
				letter-spacing: -0.14px;
			}

			.double-input {
				gap: 15px;
			}

			.edit-input {
				font-size: 16px;
				color: var(--clr-txt-dark-primary);
			}

			&:has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			label[role='alert'] {
				color: #b00020;
			}

			&.location {
				color: var(--clr-txt-dark-secondary);
				font-family: Montserrat-Medium;
				font-size: 13px;
				.gps-disabled {
					gap: 4px;
				}
			}
		}

		.dog-rating span {
			align-self: flex-start;
			margin-top: 1rem;
		}
		.trained-by-rating {
			height: 140px;
		}
		.dog-rating,
		.trained-by-rating {
			font-size: 13px;
			font-family: Montserrat-Medium;
			color: #33383f;
			font-style: normal;
			text-transform: capitalize;
			font-weight: 600;
			line-height: 24px;
			letter-spacing: -0.14px;
		}
	}
	.training-widgets-form {
		gap: 40px;
	}
}

:root {
	--date-input-width: 100%;
}

textarea {
	display: block;
	width: 600px;
	resize: none;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

input {
	padding: 10px;
	width: 100%;
	font-size: 17px;
}
</style>
