<script lang="ts">
import Icon from 'svelte-icons-pack'
import RiDesignPencilLine from 'svelte-icons-pack/ri/RiDesignPencilLine'
import AiOutlineEye from 'svelte-icons-pack/ai/AiOutlineEye'
import { useNavigate } from 'svelte-navigator'
import type { AccountUserModel } from '../../models/user.model'
import NameInitials from '../common/NameInitials.svelte'
import { _ } from 'svelte-i18n'
import RemoveUserIcon from '../teams/team/icons/RemoveUserIcon.svelte'
import { loggedInUser } from '../../store/stores'
import RemoveUserModal from './user/RemoveUserModal.svelte'
import { currAccountUsers } from '../../store/stores'
import { utilService } from '../../utils/util.service'

export let accountUsers: AccountUserModel[]
export let disabled: boolean

const navigate = useNavigate()

let showAlert = false
let alertMessage = ''
let selectedUserId: string

const getFullName = (user: AccountUserModel) => {
	return `${user.firstName} ${user.lastName}`
}

let getAlertMessage = (userName: string) =>
	`${userName} still has teams and dogs. Please choose a new handler for them.`

function onModalCancel() {
	showAlert = false
}
</script>

{#if $currAccountUsers && $currAccountUsers.length > 0}
	<section class="flex-column user-list">
		<header class="header-container">
			<ul class="header">
				<li class="user-name">{$_('username')}</li>
				<!-- Commented out until fixes on backend -->
				<!-- <li class="email">{$_('email_address')}</li> -->
				<li class="role">{$_('role')}</li>
				<li class="type">{$_('type')}</li>
				<li class="last-trained-on">{$_('last_trained_on')}</li>
				<li class="action">{$_('action')}</li>
			</ul>
		</header>
		<div class="flex-column content">
			{#each $currAccountUsers as user, i}
				<button
					on:click={() =>
						navigate($currAccountUsers[i].id, { state: { pageName: $_('user_details') } })}
					class="user-preview-container">
					<ul class="user-preview">
						<li class="flex align-center user-name">
							<div class="user-img-container">
								<NameInitials name={user.firstName + ' ' + user.lastName} />
							</div>
							{user.firstName + ' ' + user.lastName}
						</li>
						<!-- Commented out until fixes on backend -->
						<!-- <li class="email">hasd@gmail.com</li> -->
						<li class="role">{$_(`user_roles.${user.role}`)}</li>
						<li class="type">{$_(`user_types.${user.type}`)}</li>
						<li class="last-trained-on">
							{accountUsers[i].stats.recentTraining != null
								? utilService.getProperTime(accountUsers[i].stats.recentTraining.toDate())
								: 'N/A'}
						</li>
						<li class="action">
							<button
								on:click={() =>
									navigate(`/users/${$currAccountUsers[i].id}`, {
										state: { pageName: $_('user_details') }
									})}
								class="btn btn-svg btn-edit"
								title={$_('details')}>
								<Icon src={AiOutlineEye} />
							</button>

							{#if accountUsers.find((user) => user.id === $loggedInUser.id).role == 'admin'}
								{#if !disabled}
									<button
										on:click={() =>
											navigate(`edit/${$currAccountUsers[i].id}`, {
												state: { pageName: $_('user_edit') }
											})}
										class="btn btn-svg btn-edit"
										title={$_('edit')}>
										<Icon src={RiDesignPencilLine} />
									</button>

									{#if accountUsers.find((user) => user.id === $loggedInUser.id).role == 'admin' && user.id != $loggedInUser.id}
										<button
											on:click={(event) => {
												event.stopPropagation()
												alertMessage = getAlertMessage(getFullName(user))
												selectedUserId = user.id
												showAlert = true
											}}
											class="btn btn-svg btn-edit"
											title={$_('remove')}>
											<div class="svg-container">
												<RemoveUserIcon />
											</div>
										</button>
									{/if}
								{/if}
							{/if}
						</li>
					</ul>
				</button>
			{/each}
		</div>
	</section>
{/if}
{#if showAlert}
	<RemoveUserModal
		title={'Before removing a user'}
		message={alertMessage}
		onCancel={onModalCancel}
		users={accountUsers}
		{selectedUserId} />
{/if}

<style lang="scss">
@use '../../styles/setup/mixins';

.user-list {
	@include mixins.card;
	overflow-x: auto;
	height: fit-content;

	.header,
	.user-preview {
		display: grid;
		grid-template-columns: minmax(130px, 2fr) repeat(2, minmax(100px, 1fr)) minmax(80px, 1fr) minmax(
				52px,
				152px
			);
		min-width: fit-content;

		> li {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		> *:not(.user-name) {
			text-align: center;
			place-self: center;
		}

		.type {
			display: -webkit-box;
			white-space: normal;
			position: relative;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);
	}

	.content {
		font-size: 14px;
	}

	.user-preview {
		border-top: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 14px;
		transition: background-color 0.15s ease-out;

		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}

		.user-name {
			gap: 12px;
		}

		.action {
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}
				:global(svg) {
					font-size: 18px;
				}
			}
		}
	}
	@media (max-width: 1200px) {
		.email,
		.last-trained-on {
			display: none !important;
		}
		.user-preview,
		.header {
			grid-template-columns: minmax(130px, 2fr) repeat(2, minmax(100px, 1fr)) minmax(100px, 1fr);
		}
	}

	@media (max-width: 1060px) {
		.role,
		.type {
			display: none !important;
		}

		.user-preview,
		.header {
			grid-template-columns: minmax(130px, 2fr) minmax(100px, 1fr);
		}
	}
}
</style>
