<svg
	width="49"
	height="48"
	viewBox="0 0 49 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_548_25747)">
		<rect
			x="0.177734"
			width="48"
			height="48"
			rx="24"
			fill="#FFF5E0" />
		<g clip-path="url(#clip1_548_25747)">
			<path
				d="M24.1777 39C31.9077 39 38.1777 32.73 38.1777 25C38.1777 17.27 31.9077 11 24.1777 11C16.4477 11 10.1777 17.27 10.1777 25C10.1777 32.73 16.4477 39 24.1777 39ZM23.1777 17C23.1777 16.45 23.6277 16 24.1777 16C24.7278 16 25.1778 16.45 25.1778 17V24.52L29.8028 28.22C30.2328 28.565 30.3027 29.195 29.9577 29.625C29.7627 29.87 29.4728 30 29.1777 30C28.9577 30 28.7377 29.93 28.5527 29.78L23.5528 25.78C23.3178 25.59 23.1778 25.305 23.1778 25V17H23.1777Z"
				fill="#FE9803" />
		</g>
	</g>
	<defs>
		<clipPath id="clip0_548_25747">
			<rect
				width="48.1731"
				height="48"
				fill="white"
				transform="translate(0.177734)" />
		</clipPath>
		<clipPath id="clip1_548_25747">
			<rect
				width="28"
				height="28"
				fill="white"
				transform="translate(10.1777 11)" />
		</clipPath>
	</defs>
</svg>
