<script lang="ts">
import type { DogModel } from '../../../models/dog.model'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import NameInitials from '../../common/NameInitials.svelte'
import NoDogAnimation from '../../common/animation/NoDogAnimation.svelte'
import DogStatus from '../../dogs/dog/DogStatus.svelte'
import { Link } from 'svelte-navigator'
import { currAccount } from '../../../store/stores'
import { _ } from 'svelte-i18n'
import { fireMsg } from '../../../utils/userMsg.service'
import { useNavigate } from 'svelte-navigator'

const navigate = useNavigate()

export let teamDogs: DogModel[]
</script>

{#if !teamDogs}
	<div class="flex-column align-center no-dogs-animation">
		<NoDogAnimation />
		<Link
			to={'/dogs/add'}
			state={{ pageName: $_('add_dog') }}>
			<button
				on:click={() => {
					if ($currAccount.teamIds.length == 0) {
						fireMsg({
							type: 'failure',
							msg: $_('no_team_message')
						})
					}
				}}
				class="btn theme-btn btn-add-dog">{$_('add_dog')}</button>
		</Link>
	</div>
{:else if teamDogs.length === 0}
	<CmpLoader />
{:else}
	<section class="flex-column dogs-list">
		<header>
			<ul class="flex align-center header">
				<li class="name">{$_('name')}</li>
				<li class="handler">{$_('handler')}</li>
				<li class="dog-type">{$_('type')}</li>
				<li class="hours">{$_('training_hours')}</li>
				<li class="days">{$_('training_days')}</li>
				<li class="sessions">{$_('training_sessions')}</li>
				<li class="status">{$_('status')}</li>
			</ul>
		</header>
		<div class="flex-column content">
			{#each teamDogs as dog}
				<button
					class="dog-preview-container"
					on:click={() => navigate(`/dogs/${dog.id}`, { state: { pageName: $_('dog_details') } })}>
					<ul class="dog-preview">
						<li class="flex align-center dog-name">
							<div class="dog-img-container">
								{#if dog.image}
									<img
										src={dog.image}
										alt="dog profile" />
								{:else}
									<NameInitials name={dog.name} />
								{/if}
							</div>
							{dog.name}
						</li>
						{#if dog.handler}
							<li class="handler">{dog.handler.name}</li>
						{:else}
							<li class="handler">No handler</li>
						{/if}
						<li class="dog-type">
							{#each dog.type as type, i (type)}
								{$_(`dog_types.${type}`)}{i < dog.type.length - 1 ? ', ' : ''}
							{/each}
						</li>
						<li class="hours">
							{Math.floor(dog.stats.totalTrainingTime / 60)}h {dog.stats.totalTrainingTime -
								Math.floor(dog.stats.totalTrainingTime / 60) * 60}min
						</li>
						<li class="days">{dog.stats.totalTrainingDays}</li>
						<li class="sessions">{dog.stats.totalTrainingSessions}</li>
						<li class="status"><DogStatus status={dog.status} /></li>
					</ul>
				</button>
			{/each}
		</div>
	</section>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.dogs-list {
	@include mixins.card;
	overflow-x: auto;

	.header,
	.dog-preview {
		display: grid;
		grid-template-columns:
			minmax(130px, 2fr) minmax(110px, 2fr) minmax(110px, 2fr) repeat(3, minmax(72px, 1fr))
			minmax(100px, 1fr);
		min-width: fit-content;

		> li {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		> *:not(.name, .dog-name) {
			text-align: center;
			place-self: center;
		}

		.hours,
		.days,
		.sessions,
		.status {
			justify-content: center;
			text-align: center;
			min-width: 60px;
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);
	}

	.content {
		font-size: 14px;
	}

	.dog-preview {
		border-top: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 14px;
		transition: background-color 0.15s ease-out;

		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}

		.img-container {
			aspect-ratio: 1/1;
			margin-inline-end: 10px;
			border-radius: 50%;
			height: 40px;
			width: 40px;
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (max-width: 1135px) {
		.header,
		.dog-preview {
			grid-template-columns:
				minmax(130px, 2fr) minmax(110px, 2fr) minmax(110px, 2fr)
				minmax(100px, 1fr);
		}
		.days,
		.sessions,
		.hours {
			display: none !important;
		}
	}

	@media screen and (max-width: 768px) {
		.header {
			display: none !important;
		}

		.dog-preview {
			grid-template-columns: minmax(110px, 2fr) minmax(100px, 1fr);
		}
		.dog-type,
		.hours,
		.handler,
		.days,
		.sessions {
			display: none !important;
		}
	}
}

.no-dogs-animation {
	margin-top: 20px;
	gap: 30px;
}
</style>
