<svg width="151" height="122" viewBox="0 0 151 122" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6875 16.0659C12.6875 8.4879 14.2815 5.48191 20.1875 1.92091C24.6085 -0.744088 32.9375 -0.441094 36.9175 2.52991C42.0465 6.35891 43.6875 9.59491 43.6875 15.8819C43.6875 24.8749 38.3455 30.7759 29.4275 31.6329C23.7775 32.1759 20.2525 30.7059 15.6875 25.9059C12.9685 23.0459 12.6875 22.1259 12.6875 16.0659Z" fill="currentColor"/>
<path d="M115.858 30.5179C103.416 25.3189 103.274 6.67391 115.637 1.28891C122.481 -1.69209 131.181 0.655904 135.687 6.6999C137.22 8.7559 137.687 10.9429 137.687 16.0659C137.687 22.1259 137.406 23.0459 134.687 25.9059C129.025 31.8599 122.75 33.3969 115.858 30.5179Z" fill="currentColor"/>
<path d="M63.3735 49.6229C58.4275 46.3499 54.5825 40.8219 53.6435 35.6319L52.9645 31.8819H75.1875H97.4105L96.7314 35.6319C95.7924 40.8219 91.9474 46.3499 87.0014 49.6229C83.3644 52.0309 81.8605 52.3819 75.1875 52.3819C68.5145 52.3819 67.0105 52.0309 63.3735 49.6229Z" fill="currentColor"/>
<path d="M2.64145 52.3819C6.55845 44.3369 11.8244 39.4149 19.4034 36.7159C29.0604 33.2769 40.0615 36.0719 47.7145 43.9089L51.5935 47.8819H47.8905C43.6605 47.8819 43.6404 47.9229 45.7614 52.0249C46.6264 53.6979 47.1894 55.1699 47.0114 55.2949C46.8334 55.4189 44.6984 56.0929 42.2674 56.7929C39.7924 57.5049 36.7235 59.3449 35.2945 60.9729L32.7425 63.8819H17.6645C-1.37447 63.8819 -2.52754 62.9989 2.64145 52.3819Z" fill="currentColor"/>
<path d="M102.655 43.9149C106.589 39.8859 111.264 37.1679 116.687 35.7579C128.167 32.7709 142.061 40.1919 147.81 52.3819C152.837 63.0399 151.731 63.8819 132.71 63.8819H117.632L115.08 60.9729C113.651 59.3449 110.583 57.5049 108.108 56.7929C105.677 56.0929 103.542 55.4189 103.364 55.2949C103.186 55.1699 103.749 53.6979 104.614 52.0249C106.735 47.9229 106.714 47.8819 102.484 47.8819H98.7815L102.655 43.9149Z" fill="currentColor"/>
<path d="M46.7455 60.2519C53.4835 58.3219 53.8384 57.9659 52.1524 54.8159C51.1744 52.9889 51.5064 52.8819 58.1524 52.8819C63.7114 52.8819 65.1875 53.1939 65.1875 54.3689C65.1875 56.0729 74.3565 74.8819 75.1875 74.8819C76.0185 74.8819 85.1875 56.0729 85.1875 54.3689C85.1875 53.1949 86.6575 52.8819 92.1665 52.8819H99.1445L98.0035 55.3859C97.3765 56.7639 97.0485 58.0239 97.2755 58.1879C97.5015 58.3519 100.387 59.2979 103.687 60.2919C109.305 61.9829 113.187 64.2729 113.187 65.8969C113.187 66.2849 110.746 66.8539 107.762 67.1619C91.9364 68.7949 80.8104 80.9229 80.7264 96.6319L80.6875 103.881L67.4375 103.882H54.1875V97.3819C54.1875 93.7149 53.7515 90.8819 53.1875 90.8819C52.6235 90.8819 52.1875 93.7149 52.1875 97.3819V103.882H44.3614C37.2754 103.882 36.3105 103.642 34.1505 101.343L31.7655 98.8039L34.5024 82.1429C36.1754 71.9599 37.8355 64.8229 38.7715 63.7889C39.6135 62.8579 43.2015 61.2669 46.7455 60.2519Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.324 73.2509C96.0354 75.1649 90.1664 80.7309 87.5794 86.2879C79.8444 102.898 91.8975 121.862 110.187 121.862C128.454 121.862 140.53 102.897 132.813 86.3259C130.06 80.4129 123.815 74.6859 118.329 73.0429C113.436 71.5769 105.689 71.6719 101.324 73.2509ZM125.007 97.5839C125.197 96.5949 124.81 95.1329 124.147 94.3339C123.209 93.2039 120.117 92.8819 110.187 92.8819C96.7635 92.8819 94.5955 93.5699 95.3685 97.5839C95.6825 99.2159 97.0515 99.3819 110.187 99.3819C123.323 99.3819 124.693 99.2159 125.007 97.5839Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.4375 5.54491L54.1875 9.0489V15.4299C54.1875 20.7879 53.8665 21.9829 52.1875 22.8819C49.6425 24.2439 49.6064 27.5009 52.1214 28.8469C53.2804 29.4669 62.5335 29.8819 75.1875 29.8819C87.8415 29.8819 97.0945 29.4669 98.2535 28.8469C100.769 27.5009 100.732 24.2439 98.1875 22.8819C96.5085 21.9829 96.1875 20.7879 96.1875 15.4289V9.04692L86.4375 5.81792C81.0755 4.04192 76.2375 2.46492 75.6875 2.31392C75.1375 2.16392 70.0755 3.61691 64.4375 5.54491ZM67.9645 8.88191L68.6485 12.5279C70.8685 24.3599 79.5064 24.3599 81.7264 12.5279L82.4105 8.88191H75.1875H67.9645Z" fill="currentColor"/>
</svg>
