<script lang="ts">
import type { Location } from '../../../models/common.model'
import { onMount, onDestroy } from 'svelte'
import { _ } from 'svelte-i18n'
import mapboxgl from 'mapbox-gl'
import '../../../../node_modules/mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import SelectButton from '../../common/SelectButton.svelte'

export let location: Location

const defaultGeoPoints = { latitude: 31.26858, longitude: 34.75303 }

let zoom = 16

let center = {
	lat: +location.geoPoints.latitude || defaultGeoPoints.latitude,
	lng: +location.geoPoints.longitude || defaultGeoPoints.longitude
}

const accessToken = import.meta.env?.VITE_APP_MAPBOX_ACCESS_TOKEN

let map: any
let mapContainer: any
let marker: any
let popup: any

let currentStyle = `mapbox://styles/mapbox/outdoors-v11`

onMount(() => {
	const initialState = { lng: center.lng, lat: center.lat, zoom: zoom }

	map = new mapboxgl.Map({
		container: mapContainer,
		accessToken: accessToken,
		style: `mapbox://styles/mapbox/outdoors-v11`,
		center: [center.lng, center.lat],
		zoom: initialState.zoom
	})

	marker = new mapboxgl.Marker({ color: '#2d7392' })
		.setLngLat([center.lng, center.lat])
		.addTo(map)
		.setPopup(
			new mapboxgl.Popup({ closeButton: false, closeOnClick: false }).setHTML(`${location.address}`)
		)

	map.on('click', () => {
		if (popup && popup.isOpen()) {
			popup.remove()
		}
	})
})

onDestroy(() => {
	if (map) {
		map.remove()
	}
})

$: if (map) map.setStyle(currentStyle)
</script>

<div class="training-location-container">
	<section class="details">
		<div class="flex header-container">
			<header class="header flex-row gap-1 justify-content-between">
				<h1 class="title">{$_('training_location')}</h1>
				<h3 class="description flex align-center">{location.address}</h3>
			</header>
		</div>
		<div
			class="map"
			bind:this={mapContainer}>
			<div class="sidebar">
				<SelectButton
					id="map-style"
					bind:value={currentStyle}
					choices={[
						{ label: $_('outdoor'), value: 'mapbox://styles/mapbox/outdoors-v11' },
						{ label: $_('street'), value: 'mapbox://styles/mapbox/streets-v12' },
						{ label: $_('satellite'), value: 'mapbox://styles/mapbox/satellite-v9' }
					]} />
			</div>
		</div>
	</section>
</div>

<style lang="scss">
@use '../../../styles/setup/mixins';

.map {
	position: relative;
	width: 100%;
	height: 274px;
}

.geocoder {
	margin-bottom: 18px;
}

.header-container {
	width: 100%;
	justify-content: space-between;
}

.sidebar {
	border: 1px solid rgba(0, 0, 0, 0.061);
	border-radius: 6px;
	position: absolute;
	z-index: 1000;
	right: 8px;
	top: 8px;
}

.mapboxgl-ctrl-geocoder {
	min-width: 100%;
}

.training-location-container {
	.details {
		@include mixins.card;

		.full-screen {
			height: 274px;
			border-radius: 8px;
		}

		.header {
			padding: 8px 20px 12px;
			color: var(--clr-txt-dark-primary);
			width: 100%;

			h1 {
				font-size: 22px;
			}
		}
		.title {
			font-weight: 600;
			font-size: 22px;
		}

		.description {
			font-family: Nunito;
			padding-top: 1px;
			font-size: 15px;
		}
	}

	@media screen and (max-width: 1200px) and (min-width: 768px) {
		margin-left: -12px;
	}
}
</style>
