<script lang="ts">
import MenuSurface from '@smui/menu-surface'
import SvgIcon from '../../common/SvgIcon.svelte'
import { createEventDispatcher } from 'svelte'
import type { SvgIconName } from '../../../models/common.model'
import { _ } from 'svelte-i18n'

const dispatch = createEventDispatcher()
const teamIcons: SvgIconName[] = [
	'add-general-person',
	'add-general-team',
	'add-police-person',
	'add-team',
	'dog-alert',
	'dog-ball',
	'dog-scent',
	'fire-person',
	'fire-team',
	'general-dog',
	'general-team',
	'military-person',
	'military-team',
	'police-person',
	'police-team',
	'remove-team',
	'rescue-dog',
	'rescue-person',
	'rescue-team',
	'running-dog'
]

export let selectedIcon: SvgIconName
let isOpen = false

function onSelectIcon(icon: string) {
	surface.setOpen(false)
	dispatch('select', icon)
	isOpen = false
}

let surface: MenuSurface
</script>

<div class="flex-column field icon">
	<div class="flex align-center preview">
		<SvgIcon
			name={selectedIcon}
			width="100px" />
		<button
			class="btn theme-btn btn-edit"
			type="button"
			on:click={() => {
				if (!isOpen) {
					surface.setOpen(true)
					isOpen = true
				} else {
					surface.setOpen(false)
					isOpen = false
				}
			}}>
			{$_('change_icon')}
		</button>
	</div>
	<MenuSurface
		bind:this={surface}
		anchorCorner="BOTTOM_LEFT">
		<div
			class="flex align-center icon-list"
			class:open={isOpen}>
			{#each teamIcons as icon}
				<button
					type="button"
					class="btn btn-svg btn-select-icon"
					class:selected={icon === selectedIcon}
					on:click={() => onSelectIcon(icon)}>
					<SvgIcon
						name={icon}
						width="40px" />
				</button>
			{/each}
		</div>
	</MenuSurface>
</div>

<style lang="scss">
.icon {
	padding-top: 8px;
	position: relative;

	.preview {
		gap: 80px;

		.btn-edit {
			font-size: 15px;
			padding: 10px 14px;
			border-radius: 12px;
			white-space: nowrap;
		}
	}

	.icon-list {
		display: grid;
		gap: 10px;
		padding: 10px;
		grid-template-columns: repeat(10, 40px);

		.btn-select-icon {
			padding: 0;
			margin-inline: 0;
			transition: scale 0.4s ease-out;
			transition-delay: 0.4s;

			&:hover {
				scale: 1.4;
			}

			&.selected {
				box-shadow: 0px 8px 12px -9px var(--clr-primary);
			}
		}
	}

	@media screen and (max-width: 576px) {
		.preview {
			gap: 0;
			justify-content: space-between;
		}
	}
}
</style>
