<script lang="ts">
import DropdownBottomSheet from './DropdownBottomSheet.svelte'
import Textarea from './Textarea.svelte'
import { dropdownText } from '../../../store/trainingWidgetsStore'

export let values: {
	initialValue: string
	items: string[]
	maxLines: number
	trigger: number
	value: {
		dropdownValue: string
		text: string
	}
}
export let data = []
export let id: string

let dropdownValues: any
let textValues: any

if (!$dropdownText[id]) {
	$dropdownText[id] = {
		initialValue: values.initialValue,
		items: values.items,
		maxLines: values.maxLines,
		trigger: values.trigger,
		value: {
			dropdownValue: '',
			text: values.initialValue
		}
	}
}

if ($dropdownText[id].value.dropdownValue != '') {
	dropdownValues = {
		items: $dropdownText[id].items,
		value: $dropdownText[id].value.dropdownValue,
		returnedValue: {
			value: $dropdownText[id].value.dropdownValue,
			label: $dropdownText[id].value.dropdownValues
		},
		filled: false
	}
} else if (data.length > 0) {
	dropdownValues = {
		items: values.items,
		value: data[0].value.selectValue,
		filled: false
	}
} else {
	dropdownValues = {
		items: values.items,
		value: '',
		filled: false
	}
}

textValues = $dropdownText[id]
	? {
			initialValue: $dropdownText[id].value.text,
			maxLines: $dropdownText[id].maxLines,
			value: $dropdownText[id].value.text,
			filled: false
		}
	: {
			initialValue: values.initialValue,
			maxLines: values.maxLines,
			value: '',
			filled: false
		}

values.value = {
	dropdownValue: dropdownValues.value,
	text: textValues.initialValue
}

$: values.value = {
	dropdownValue: dropdownValues.value,
	text: textValues.value
}

$: {
	if (dropdownValues.value == values.items[values.trigger]) {
		values.value = {
			dropdownValue: dropdownValues.value,
			text: textValues.value
		}
		$dropdownText[id] = values
	} else {
		values.value.text = ''
		$dropdownText[id] = values
	}
}
</script>

<DropdownBottomSheet
	bind:values={dropdownValues}
	data={data.length > 0 ? [{ value: data[0].value.dropdownValue }] : []} />

{#if dropdownValues.value == values.items[values.trigger]}
	<Textarea
		bind:values={textValues}
		{id}
		data={data.length > 0 && data[0].value.text != null && data[0].value.text != ''
			? [{ value: data[0].value.text }]
			: []} />
{/if}
