<script lang="ts">
import { onMount } from 'svelte'
import type { UserModel } from '../../models/user.model'
import { loggedInUser } from '../../store/stores'
import CmpLoader from '../common/loader/CmpLoader.svelte'
import { _ } from 'svelte-i18n'
import EditInfo from './EditInfo.svelte'
import PasswordChange from './PasswordChange.svelte'

onMount(async () => {
	await loadUser()
})

async function loadUser() {
	user = $loggedInUser
}

let user: UserModel
</script>

{#if !user}
	<CmpLoader />
{:else}
	<div class="user-edit flex-column">
		<EditInfo {user} />
		<div class="email-and-password flex justify-center">
			<PasswordChange {user} />
		</div>
	</div>
{/if}

<style lang="scss">
@use '../../styles/setup/mixins';

.user-edit {
	gap: 20px;

	.email-and-password {
		flex-wrap: nowrap;
		gap: 20px;
	}
}
</style>
