<script lang="ts">
import RiDesignPencilLine from 'svelte-icons-pack/ri/RiDesignPencilLine'
import Icon from 'svelte-icons-pack'
import AccountEditModal from './AccountEditModal.svelte'

let isOpen = false

function closeModal() {
	isOpen = false
}
</script>

<button
	class="btn btn-svg account-edit"
	on:click={() => {
		isOpen = true
	}}>
	<Icon src={RiDesignPencilLine} />
</button>
<AccountEditModal
	{isOpen}
	on:closeModal={closeModal} />

<style lang="scss">
.btn.account-edit {
	fill: var(--clr-txt-dark-secondary);

	:global(svg) {
		width: 18px;
		height: 18px;
	}
}
</style>
