<script lang="ts">
import '../../../styles/themes/_globals.scss'
import { _ } from 'svelte-i18n'
import NoResultAnimation from '../../../cmps/common/animation/NoResultAnimation.svelte'
import Icon from 'svelte-icons-pack'
import { storageService } from '../../../services/core/storage.service'
import BsDownload from 'svelte-icons-pack/bs/BsDownload'
import BsTrash3 from 'svelte-icons-pack/bs/BsTrash3'
import { gpx } from '@tmcw/togeojson'
import mapboxgl from 'mapbox-gl'
import '../../../../node_modules/mapbox-gl/dist/mapbox-gl.css'
import CgNotes from 'svelte-icons-pack/cg/CgNotes'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { currAccount } from '../../../store/stores'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import { onDestroy, onMount } from 'svelte'
import { fireMsg } from '../../../utils/userMsg.service'

export let lng = -71.224518
export let lat = 42.213995
export let zoom = 15

let isPreviewOpened = false
let currentElementId: string
let currentUploadName: string
let videoPlayer: any
let listElement: any
let isDeleteSuccessfull = true
let uploadPreview

onMount(() => {
	listElement = document.querySelectorAll('.training-preview-container')
})

onDestroy(() => {
	if (videoPlayer) videoPlayer.dispose()
})

function previewVideo(elementId: string, uploadName: string) {
	const videoElement = document.getElementById('vid-container' + elementId)
	if (isPreviewOpened) {
		listElement.forEach((element) => (element.style.display = 'block'))
		videoElement.style.display = 'none'
		videoPlayer.pause()
		isPreviewOpened = false
	} else {
		currentUploadName = uploadName
		listElement.forEach((element) => (element.style.display = 'none'))
		videoElement.style.display = 'block'
		videoPlayer = videojs('vid' + elementId, {
			autoplay: 'muted',
			controls: true,
			preload: 'metadata'
		})
		currentElementId = elementId
		isPreviewOpened = true
	}
}

function previewImage(elementId: string, uploadName: string) {
	const imageElement = document.getElementById('image-container' + elementId)
	if (isPreviewOpened) {
		listElement.forEach((element) => (element.style.display = 'block'))
		imageElement.style.display = 'none'
		isPreviewOpened = false
	} else {
		currentUploadName = uploadName
		imageElement.style.display = 'block'
		listElement.forEach((element) => (element.style.display = 'none'))
		currentElementId = elementId
		isPreviewOpened = true
	}
}

function previewGPX(gpxUrl: string, elementId: string, uploadName: string) {
	const GPXElement = document.getElementById('map' + elementId)
	if (isPreviewOpened) {
		listElement.forEach((element) => (element.style.display = 'block'))
		GPXElement.style.display = 'none'
		isPreviewOpened = false
	} else {
		// convert GPX to geoJSON
		fetch(gpxUrl)
			.then((response) => {
				return response.text()
			})
			.then((gpxData) => {
				return gpx(new DOMParser().parseFromString(gpxData, 'text/xml'))
			})
			.then((geoJSON) => {
				currentUploadName = uploadName
				createGeoJSONMap(geoJSON, 'map' + elementId)
				listElement.forEach((element) => (element.style.display = 'none'))
				GPXElement.style.display = 'block'
				GPXElement.style.width = '100%'
				currentElementId = elementId
				isPreviewOpened = true
			})
	}
}

function createGeoJSONMap(geoJSON, elementId: string) {
	map = new mapboxgl.Map({
		container: document.getElementById(elementId),
		accessToken: accessToken,
		style: currentStyle,
		width: '500px',
		center: [
			geoJSON.features[0].geometry.coordinates[0][0],
			geoJSON.features[0].geometry.coordinates[0][1]
		],
		zoom: initialState.zoom
	})

	map.on('style.load', () => {
		map.resize()
		map.setFog({}) // Set the default atmosphere style
	})

	map.on('load', () => {
		map.addSource('geojson-data', {
			type: 'geojson',
			// Use a URL for the value for the `data` property.
			data: geoJSON
		})

		map.addLayer({
			id: 'geojson-line-layer',
			type: 'line',
			source: 'geojson-data',
			layout: {
				'line-join': 'round',
				'line-cap': 'round'
			},
			paint: {
				'line-color': '#FF0000',
				'line-width': 4
			}
		})

		map.addControl(new mapboxgl.NavigationControl())
	})
}

const accessToken = import.meta.env?.VITE_APP_MAPBOX_ACCESS_TOKEN

let map: any
let geocoder: any
let mapContainer: any
let marker: any
let currentStyle = `mapbox://styles/mapbox/outdoors-v11`

const initialState = { lng: lng, lat: lat, zoom: zoom }

const defaultGeoPoints = $currAccount.location.geoPoints ?? {
	latitude: 31.9686,
	longitude: -99.9018
}

export let fileUploads: {
	fileName: string
	fileUrl: string
	id: string
}[]
export let trainingId: string
export let isInEdit = false

let test
let files = fileUploads

$: if (test) {
	files = fileUploads
}
</script>

{#if files && files.length > 0}
	<section class="flex-column training-list">
		{#if !isPreviewOpened}
			<header class="header-container">
				<ul class="header">
					<li class="type">{$_('name')}</li>
					<li class="action">
						{#if isInEdit}
							{$_('delete_file')}
						{:else}
							{$_('download_file')}
						{/if}
					</li>
				</ul>
			</header>
		{:else}
			<header class="header-container">
				<ul class="close-preview-header flex align-center justify-center">
					<button
						class="close-preview flex align-left"
						on:click={async () => {
							if (currentUploadName.slice(-3).toLowerCase() === 'gpx') {
								previewGPX('', currentElementId, '')
							} else if (
								currentUploadName.slice(-3).toLowerCase() === 'mp4' ||
								currentUploadName.slice(-3).toLowerCase() === 'avi' ||
								currentUploadName.slice(-3).toLowerCase() === 'wav' ||
								currentUploadName.slice(-3).toLowerCase() === 'wmv' ||
								currentUploadName.slice(-3).toLowerCase() === 'webm'
							) {
								previewVideo(currentElementId, currentUploadName)
							} else if (
								currentUploadName.slice(-3).toLowerCase() === 'jpg' ||
								currentUploadName.slice(-3).toLowerCase() === 'png' ||
								currentUploadName.slice(-4).toLowerCase() === 'jpeg'
							) {
								previewImage(currentElementId, currentUploadName)
							}
						}}><span>CLOSE</span></button>
				</ul>
			</header>
		{/if}
		<div
			class="flex-column content"
			id="list-container">
			{#each files as upload}
				<button
					class="training-preview-container"
					on:click={async (ev) => {
						ev.preventDefault()
						if (upload.fileName.slice(-3).toLowerCase() === 'gpx') {
							previewGPX(upload.fileUrl, upload.id, upload.fileName)
						} else if (
							upload.fileName.slice(-3).toLowerCase() === 'mp4' ||
							upload.fileName.slice(-3).toLowerCase() === 'avi' ||
							upload.fileName.slice(-3).toLowerCase() === 'wav' ||
							upload.fileName.slice(-3).toLowerCase() === 'wmv' ||
							upload.fileName.slice(-4).toLowerCase() === 'webm'
						) {
							previewVideo(upload.id, upload.fileName)
						} else if (
							upload.fileName.slice(-3).toLowerCase() === 'jpg' ||
							upload.fileName.slice(-3).toLowerCase() === 'png' ||
							upload.fileName.slice(-4).toLowerCase() === 'jpeg'
						) {
							previewImage(upload.id, upload.fileName)
						} else {
							fireMsg({
								type: 'failure',
								msg: 'This file type does not support preview yet.'
							})
						}
					}}>
					<ul class="training-preview">
						<li class="flex training-name">
							<div class="training-img-container" />
							<div class="training-type flex">
								<p>
									{upload.fileName}
								</p>
							</div>
						</li>
						<li class="action action-data">
							{#if isInEdit}
								<button
									on:click={(ev) => {
										ev.stopPropagation()
										ev.preventDefault()
										try {
											storageService
												.deleteTrainingUpload(trainingId, upload.id)
												.then((response) => {
													if (response) {
														files = files.filter((object) => object.id !== upload.id)
													}
												})
										} catch (err) {
											fireMsg({
												type: 'failure',
												msg: 'Could not delete the upload, please try again'
											})
										}
									}}
									class="btn btn-svg btn-edit"
									title={$_('details')}>
									<Icon src={BsTrash3} />
								</button>
							{:else}
								<button
									on:click={(ev) => {
										ev.stopPropagation()
										ev.preventDefault()
										storageService.downloadTrainingUpload(upload.fileUrl, upload.fileName)
									}}
									class="btn btn-svg btn-edit"
									title={$_('details')}>
									<Icon src={BsDownload} />
								</button>
							{/if}
						</li>
					</ul>
				</button>
				<div
					id={'vid-container' + upload.id}
					class="upload-video-container">
					<video-js
						id={'vid' + upload.id}
						class="videojs">
						<source src={upload.fileUrl} />
					</video-js>
				</div>
				<div
					class="upload-image-container"
					id={'image-container' + upload.id}>
					<img
						class="uploads-image"
						src={upload.fileUrl}
						alt="upload preview" />
				</div>
				<div
					class="uploads-map"
					id={'map' + upload.id}
					bind:this={mapContainer} />
			{/each}
		</div>
	</section>
{:else}
	<div class="no-uploads-container">
		<section class="details">
			<header class="header">
				<h1 class="title">{$_('uploads')}</h1>
			</header>
			<div class="content flex">
				<div class="no-notes flex align-center justify-center">
					<Icon src={CgNotes} />
					<p>{$_('no_uploads')}</p>
				</div>
			</div>
		</section>
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.video-js {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.uploads-map,
.upload-image-container {
	position: absolute;
	display: none;
	height: 410px;
	width: 100%;
	z-index: 1000;

	.uploads-image {
		height: 410px;
		width: 100%;
		object-fit: cover;
	}
}

.upload-video-container {
	display: none;
	position: absolute;
	z-index: 1000;
	height: 410px;
	width: 100%;
}

.videojs {
	height: 100%;
	width: 100%;
}

.training-list {
	@include mixins.card;
	overflow-x: auto;

	height: 450px;
	overflow-y: auto;

	.close-preview-header {
		max-height: 40px;
		width: 100%;
		min-width: fit-content;
		column-gap: 8px;
		overflow: hidden;

		.close-preview {
			width: 100%;
			justify-content: flex-end;
			padding: 16px;
			background-color: var(--clr-table-header);
			font-size: 12px;
			font-weight: 600;
			text-transform: uppercase;
			color: var(--clr-txt-dark-secondary);
		}
	}

	.training-preview,
	.header {
		display: grid;
		grid-template-columns: 5fr 1fr;
		min-width: fit-content;
		column-gap: 8px;
		overflow: hidden;

		.close-preview {
			width: 100%;
			justify-content: flex-end;
		}

		> li {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		.training-name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			gap: 12px;

			.training-type {
				p {
					max-width: 400px;
					white-space: nowrap;
					position: relative;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}

		.type {
			white-space: normal;
			position: relative;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.action {
			margin-right: 12px;
			.btn-svg {
				box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);

				&:not(:hover) {
					:global(svg) {
						fill: var(--clr-btn-svg);
					}
				}

				:global(svg) {
					font-size: 18px;
				}
			}
		}
	}

	.header {
		padding: 16px;
		background-color: var(--clr-table-header);
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--clr-txt-dark-secondary);

		.action {
			margin-right: 2rem;
		}
	}

	.content {
		position: relative;
		font-size: 14px;
	}
	.training-preview {
		border-bottom: 2px solid #ebe9f1;
		padding-block: 10px;
		padding-inline: 14px;
		transition: background-color 0.15s ease-out;
		&:hover {
			cursor: pointer;
			background-color: darken(white, 3%);
		}
	}

	@media (max-width: 1350px) {
		.header {
			display: none;
		}

		.training-name {
			max-width: 300px;
		}
	}

	.training-img-container {
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1000px;
		width: 40px;
		height: 40px;
		aspect-ratio: 1/1;
	}
}

.no-uploads-container {
	.details {
		@include mixins.card;
		overflow-y: auto;
		height: 450px;

		.header {
			padding: 8px 12px 22px;
			color: var(--clr-txt-dark-primary);

			h1 {
				font-size: 22px;
			}
		}

		.content {
			height: 60%;
			gap: 10px;
			min-height: 130px;
			.no-notes {
				color: var(--clr-txt-dark-secondary);
				justify-self: center;
				gap: 5px;
				width: 100%;
				font-size: 17px;
				margin-top: 50px;

				p {
					margin-top: 3px;
				}
			}

			.notes {
				margin: 0 50px;
				justify-self: center;
				font-size: 17px;
				width: 100%;
				color: var(--clr-txt-dark-secondary);
			}
		}
		.title {
			font-weight: 600;
			font-size: 22px;
		}
	}

	@media screen and (max-width: 1200px) and (min-width: 768px) {
		margin-right: -12px;
	}
}
</style>
