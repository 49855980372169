<svg
	width="98"
	height="44"
	viewBox="0 0 98 44"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_27_2)">
		<path
			d="M4.74892 41.0917C3.38224 41.0917 2.29511 40.7174 1.48753 39.9688C0.679947 39.2098 0.276154 38.1286 0.276154 36.7251V34.1051C0.276154 32.7016 0.679947 31.6255 1.48753 30.877C2.29511 30.118 3.38224 29.7385 4.74892 29.7385C6.11559 29.7385 7.20273 30.118 8.01029 30.877C8.81791 31.6255 9.22169 32.7016 9.22169 34.1051V36.7251C9.22169 38.1286 8.81791 39.2098 8.01029 39.9688C7.20273 40.7174 6.11559 41.0917 4.74892 41.0917ZM4.74892 39.2515C5.51509 39.2515 6.11042 39.0279 6.5349 38.5809C6.95943 38.1338 7.17169 37.536 7.17169 36.7875V34.0427C7.17169 33.2942 6.95943 32.6964 6.5349 32.2493C6.11042 31.8023 5.51509 31.5787 4.74892 31.5787C3.99311 31.5787 3.39777 31.8023 2.96292 32.2493C2.53842 32.6964 2.32617 33.2942 2.32617 34.0427V36.7875C2.32617 37.536 2.53842 38.1338 2.96292 38.5809C3.39777 39.0279 3.99311 39.2515 4.74892 39.2515Z"
			fill="#464648" />
		<path
			d="M10.7713 43.9923V33.1382H12.6971V34.0739H12.9766C13.1526 33.7724 13.427 33.5073 13.7997 33.2786C14.1725 33.0395 14.7057 32.9199 15.3994 32.9199C16.0206 32.9199 16.5952 33.0759 17.1233 33.3878C17.6513 33.6892 18.0758 34.1363 18.3967 34.7289C18.7177 35.3215 18.8782 36.0389 18.8782 36.881V37.1305C18.8782 37.9727 18.7177 38.69 18.3967 39.2826C18.0758 39.8752 17.6513 40.3275 17.1233 40.6394C16.5952 40.9409 16.0206 41.0917 15.3994 41.0917C14.9335 41.0917 14.54 41.0345 14.2191 40.9201C13.9085 40.8161 13.6548 40.681 13.4581 40.5146C13.2717 40.3379 13.1216 40.1611 13.0077 39.9844H12.7282V43.9923H10.7713ZM14.8092 39.3762C15.4201 39.3762 15.9223 39.1839 16.3157 38.7992C16.7195 38.4041 16.9213 37.8323 16.9213 37.0838V36.9278C16.9213 36.1792 16.7195 35.6126 16.3157 35.228C15.9119 34.8329 15.4097 34.6353 14.8092 34.6353C14.2087 34.6353 13.7066 34.8329 13.3028 35.228C12.899 35.6126 12.6971 36.1792 12.6971 36.9278V37.0838C12.6971 37.8323 12.899 38.4041 13.3028 38.7992C13.7066 39.1839 14.2087 39.3762 14.8092 39.3762Z"
			fill="#464648" />
		<path
			d="M23.8743 41.0917C23.1081 41.0917 22.4299 40.9305 21.8398 40.6082C21.26 40.2755 20.8044 39.8129 20.4731 39.2203C20.1522 38.6172 19.9917 37.9103 19.9917 37.0993V36.9122C19.9917 36.1013 20.1522 35.3995 20.4731 34.8069C20.7941 34.2039 21.2445 33.7413 21.8242 33.4189C22.4041 33.0863 23.077 32.9199 23.8432 32.9199C24.599 32.9199 25.2565 33.0915 25.8156 33.4345C26.3747 33.7672 26.8095 34.2351 27.1201 34.8381C27.4307 35.4307 27.5861 36.122 27.5861 36.9122V37.5828H21.9796C22.0003 38.113 22.197 38.5445 22.5697 38.8772C22.9425 39.2098 23.398 39.3762 23.9364 39.3762C24.4851 39.3762 24.8889 39.2566 25.1478 39.0175C25.4066 38.7784 25.6034 38.5133 25.7379 38.2222L27.3376 39.0643C27.1926 39.3346 26.9804 39.6309 26.7008 39.9532C26.4316 40.2651 26.0693 40.5354 25.6137 40.7642C25.1581 40.9825 24.5783 41.0917 23.8743 41.0917ZM21.9951 36.1169H25.5982C25.5567 35.6698 25.3756 35.3111 25.0546 35.0408C24.744 34.7705 24.335 34.6353 23.8277 34.6353C23.2996 34.6353 22.8803 34.7705 22.5697 35.0408C22.2591 35.3111 22.0676 35.6698 21.9951 36.1169Z"
			fill="#464648" />
		<path
			d="M29.0172 40.8733V33.1382H30.9429V34.1519H31.2225C31.3467 33.8816 31.5797 33.6269 31.9213 33.3878C32.263 33.1382 32.7807 33.0135 33.4744 33.0135C34.0749 33.0135 34.5977 33.1538 35.043 33.4345C35.4985 33.7048 35.8505 34.0843 36.099 34.573C36.3475 35.0512 36.4717 35.6126 36.4717 36.2572V40.8733H34.5149V36.4132C34.5149 35.831 34.37 35.3943 34.0801 35.1032C33.8005 34.8121 33.3967 34.6665 32.8687 34.6665C32.2682 34.6665 31.8023 34.8693 31.471 35.2747C31.1396 35.6698 30.974 36.226 30.974 36.9434V40.8733H29.0172Z"
			fill="#464648" />
		<path
			d="M39.0842 40.8733L37.6553 29.9569H39.6898L40.7459 39.4386H41.0254L42.3921 29.9569H45.9331L47.2998 39.4386H47.5793L48.6354 29.9569H50.6699L49.2411 40.8733H45.7001L44.3024 31.1733H44.0228L42.6251 40.8733H39.0842Z"
			fill="#464648" />
		<path
			d="M55.338 41.0917C54.5718 41.0917 53.8937 40.9305 53.3035 40.6082C52.7237 40.2755 52.2681 39.8129 51.9368 39.2203C51.6158 38.6172 51.4554 37.9103 51.4554 37.0993V36.9122C51.4554 36.1013 51.6158 35.3995 51.9368 34.8069C52.2578 34.2039 52.7081 33.7413 53.288 33.4189C53.8678 33.0863 54.5408 32.9199 55.3069 32.9199C56.0628 32.9199 56.7203 33.0915 57.2793 33.4345C57.8383 33.7672 58.2733 34.2351 58.5837 34.8381C58.8946 35.4307 59.0498 36.122 59.0498 36.9122V37.5828H53.4433C53.464 38.113 53.6607 38.5445 54.0334 38.8772C54.4062 39.2098 54.8617 39.3762 55.4001 39.3762C55.9487 39.3762 56.3526 39.2566 56.6112 39.0175C56.8704 38.7784 57.0668 38.5133 57.2014 38.2222L58.8012 39.0643C58.6566 39.3346 58.4441 39.6309 58.1643 39.9532C57.8951 40.2651 57.5329 40.5354 57.0774 40.7642C56.6218 40.9825 56.0422 41.0917 55.338 41.0917ZM53.4588 36.1169H57.0618C57.0206 35.6698 56.8393 35.3111 56.5183 35.0408C56.208 34.7705 55.7986 34.6353 55.2914 34.6353C54.7634 34.6353 54.344 34.7705 54.0334 35.0408C53.7228 35.3111 53.5313 35.6698 53.4588 36.1169Z"
			fill="#464648" />
		<path
			d="M71.5382 40.8733C71.0309 40.8733 70.617 40.7174 70.2961 40.4055C69.9851 40.0832 69.8299 39.6569 69.8299 39.1267V34.7601H67.9042V33.1382H69.8299V30.7366H71.7868V33.1382H73.8989V34.7601H71.7868V38.7836C71.7868 39.0955 71.932 39.2515 72.2218 39.2515H73.7126V40.8733H71.5382Z"
			fill="#464648" />
		<path
			d="M75.3596 40.8733V29.9569H77.3165V34.0895H77.5963C77.6792 33.9232 77.8083 33.7569 77.9846 33.5905C78.1604 33.4241 78.3934 33.289 78.6832 33.185C78.9836 33.0707 79.3613 33.0135 79.8169 33.0135C80.4176 33.0135 80.9405 33.1538 81.3855 33.4345C81.8411 33.7048 82.1932 34.0843 82.4418 34.573C82.6899 35.0512 82.8145 35.6127 82.8145 36.2572V40.8733H80.8576V36.4132C80.8576 35.831 80.7124 35.3943 80.4226 35.1032C80.1434 34.8121 79.7395 34.6665 79.2111 34.6665C78.6109 34.6665 78.1448 34.8693 77.8138 35.2747C77.4823 35.6698 77.3165 36.226 77.3165 36.9434V40.8733H75.3596Z"
			fill="#464648" />
		<path
			d="M88.1289 41.0917C87.363 41.0917 86.6849 40.9305 86.0947 40.6082C85.5145 40.2755 85.059 39.8129 84.728 39.2203C84.407 38.6172 84.2463 37.9103 84.2463 37.0993V36.9122C84.2463 36.1013 84.407 35.3995 84.728 34.8069C85.049 34.2039 85.499 33.7413 86.0791 33.4189C86.6587 33.0863 87.3318 32.9199 88.0978 32.9199C88.8537 32.9199 89.5112 33.0915 90.0702 33.4345C90.6292 33.7672 91.0642 34.2351 91.3752 34.8381C91.6856 35.4307 91.8408 36.122 91.8408 36.9122V37.5828H86.2343C86.2549 38.113 86.4518 38.5445 86.8245 38.8772C87.1972 39.2098 87.6528 39.3762 88.1912 39.3762C88.7397 39.3762 89.1435 39.2566 89.4027 39.0175C89.6614 38.7784 89.8583 38.5133 89.9929 38.2222L91.5921 39.0643C91.4475 39.3346 91.235 39.6309 90.9558 39.9532C90.6865 40.2651 90.3239 40.5354 89.8683 40.7642C89.4127 40.9825 88.8331 41.0917 88.1289 41.0917ZM86.2499 36.1169H89.8527C89.8116 35.6698 89.6302 35.3111 89.3093 35.0408C88.9989 34.7705 88.59 34.6353 88.0822 34.6353C87.5543 34.6353 87.1349 34.7705 86.8245 35.0408C86.5141 35.3111 86.3222 35.6698 86.2499 36.1169Z"
			fill="#464648" />
		<path
			d="M93.272 40.8733V33.1382H95.1977V34.0116H95.4775C95.591 33.6997 95.7773 33.4709 96.0366 33.3254C96.3058 33.1798 96.6162 33.107 96.9683 33.107H97.9V34.8537H96.9371C96.4404 34.8537 96.031 34.9889 95.71 35.2591C95.3891 35.5191 95.2289 35.9245 95.2289 36.4756V40.8733H93.272Z"
			fill="#464648" />
		<mask
			id="mask0_27_2"
			style="mask-type:luminance"
			maskUnits="userSpaceOnUse"
			x="6"
			y="0"
			width="34"
			height="32">
			<path
				d="M39.2177 0.515079H6.67499V31.148H39.2177V0.515079Z"
				fill="white" />
		</mask>
		<g mask="url(#mask0_27_2)">
			<path
				d="M36.0081 17.305C35.6348 17.2897 35.2585 17.305 34.8822 17.305C33.3526 17.305 33.3526 17.305 33.3862 15.7488C33.4516 13.9492 33.1279 12.1566 32.4372 10.4938C31.7465 8.83102 30.7052 7.33725 29.3845 6.11477V6.09946L29.1092 5.85439C27.7336 4.64623 26.1102 3.75469 24.3533 3.24256C22.5963 2.73044 20.7487 2.61017 18.9403 2.89024C17.132 3.17031 15.407 3.84389 13.8867 4.86358C12.3664 5.88324 11.0879 7.22424 10.1411 8.79209L10.1197 8.82579C10.0554 8.93606 9.98811 9.04634 9.92692 9.15662C8.70851 11.3141 8.15999 13.7863 8.35134 16.2573C8.37582 16.6678 8.65728 17.2743 7.82513 17.3325C7.10618 17.3816 6.56773 17.74 6.69316 18.5579C6.8186 19.3758 7.44577 19.4768 8.14637 19.4768C9.23244 19.4554 10.3216 19.4768 11.4077 19.4768C12.1052 19.4768 12.6069 19.7587 12.6314 20.4969C12.6528 21.1647 12.2276 21.5354 11.5698 21.5446C10.753 21.5446 10.2206 21.9214 10.2849 22.7454C10.3491 23.5694 11.0222 23.6644 11.7167 23.646C13.6655 23.6307 15.6143 23.6276 17.5662 23.646C18.2851 23.646 18.8175 23.9523 18.7746 24.7886C18.7349 25.5514 18.2423 25.7934 17.5509 25.7903C16.3516 25.7903 15.1523 25.7903 13.95 25.7903C13.1882 25.7903 12.4417 25.8301 12.4387 26.8318C12.4356 27.8335 13.1913 27.8672 13.95 27.8641C18.0006 27.8641 22.0512 27.8641 26.0988 27.8641C27.861 27.8641 29.6232 27.8641 31.3854 27.8641C32.1013 27.8641 32.6948 27.7324 32.7162 26.8778C32.7407 25.9588 32.1043 25.775 31.3578 25.7934C31.0519 25.7934 30.746 25.7934 30.4584 25.7934H25.0586C24.3519 25.7934 23.8838 25.487 23.8624 24.7518C23.841 24.0166 24.3274 23.6981 25.0127 23.6491C25.236 23.6307 25.4624 23.6491 25.6858 23.6491C26.3711 23.6491 27.0105 23.5725 27.0655 22.7056C27.1267 21.7866 26.5149 21.5691 25.7561 21.566C25.0647 21.5293 24.6639 21.1249 24.6639 20.5122C24.6639 19.8169 25.1198 19.4401 25.851 19.4615C26.3007 19.4615 26.7535 19.4615 27.2032 19.4615C30.128 19.4615 33.0527 19.4615 35.9775 19.4615C36.7148 19.4615 37.2441 19.2287 37.2686 18.4047C37.293 17.5807 36.7117 17.3356 36.0081 17.305Z"
				fill="#EB6D4A" />
			<path
				d="M35.5722 23.6243C36.1419 23.6243 36.6037 23.1619 36.6037 22.5915C36.6037 22.0211 36.1419 21.5587 35.5722 21.5587C35.0026 21.5587 34.5408 22.0211 34.5408 22.5915C34.5408 23.1619 35.0026 23.6243 35.5722 23.6243Z"
				fill="#EB6D4A" />
			<path
				d="M38.1731 23.6243C38.7428 23.6243 39.2046 23.1619 39.2046 22.5915C39.2046 22.0211 38.7428 21.5587 38.1731 21.5587C37.6035 21.5587 37.1417 22.0211 37.1417 22.5915C37.1417 23.1619 37.6035 23.6243 38.1731 23.6243Z"
				fill="#EB6D4A" />
			<path
				d="M38.2375 21.5587H35.4666V23.6243H38.2375V21.5587Z"
				fill="#EB6D4A" />
			<path
				d="M29.4948 23.6243C30.0644 23.6243 30.5262 23.1619 30.5262 22.5915C30.5262 22.0211 30.0644 21.5587 29.4948 21.5587C28.9251 21.5587 28.4633 22.0211 28.4633 22.5915C28.4633 23.1619 28.9251 23.6243 29.4948 23.6243Z"
				fill="#EB6D4A" />
			<path
				d="M32.0957 23.6243C32.6654 23.6243 33.1272 23.1619 33.1272 22.5915C33.1272 22.0211 32.6654 21.5587 32.0957 21.5587C31.526 21.5587 31.0642 22.0211 31.0642 22.5915C31.0642 23.1619 31.526 23.6243 32.0957 23.6243Z"
				fill="#EB6D4A" />
			<path
				d="M32.16 21.5587H29.3892V23.6243H32.16V21.5587Z"
				fill="#EB6D4A" />
		</g>
		<path
			d="M63.2061 41.0667C62.6576 41.0667 62.1659 40.9731 61.7309 40.786C61.2959 40.5884 60.9489 40.3077 60.6902 39.9439C60.4416 39.5696 60.3175 39.1173 60.3175 38.5871C60.3175 38.0569 60.4416 37.615 60.6902 37.2615C60.9489 36.8977 61.301 36.6273 61.7465 36.4506C62.2021 36.2635 62.7194 36.1699 63.2996 36.1699H65.4116V35.7332C65.4116 35.3694 65.2976 35.0731 65.0701 34.8443C64.842 34.6052 64.4799 34.4856 63.9826 34.4856C63.4959 34.4856 63.1338 34.6 62.8957 34.8287C62.6576 35.0471 62.5019 35.3329 62.4296 35.6865L60.6279 35.0782C60.7525 34.6832 60.9489 34.3245 61.2181 34.0022C61.4979 33.6695 61.8656 33.4044 62.3211 33.2069C62.7867 32.9989 63.3513 32.895 64.0138 32.895C65.0284 32.895 65.8311 33.1497 66.4212 33.6591C67.0114 34.1686 67.3062 34.9067 67.3062 35.8736V38.7587C67.3062 39.0706 67.3062 39.1017 67.3062 39.3513V39.9439V40.8484H67.0576C66.6749 40.8484 66.0435 40.8328 65.7376 40.8328C65.7376 40.5677 65.7376 40.131 65.7376 39.8191V39.8035H65.4428C65.4011 39.9283 65.3082 40.0946 65.163 40.3026C65.0178 40.5001 64.7903 40.6768 64.4799 40.8328C64.169 40.9887 63.7446 41.0667 63.2061 41.0667ZM63.5476 39.476C64.0967 39.476 64.5417 39.3253 64.8832 39.0238C65.2353 38.7119 65.4116 38.3012 65.4116 37.7918V37.6358H63.4392C63.0765 37.6358 62.7923 37.7138 62.5848 37.8697C62.3779 38.0257 62.2744 38.244 62.2744 38.5247C62.2744 38.8054 62.3829 39.0342 62.6004 39.2109C62.8179 39.3876 63.1338 39.476 63.5476 39.476Z"
			fill="#464648" />
	</g>
	<defs>
		<clipPath id="clip0_27_2">
			<rect
				width="97.9"
				height="44"
				fill="white" />
		</clipPath>
	</defs>
</svg>
