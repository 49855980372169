<script lang="ts">
import { onMount } from 'svelte'
import { _ } from 'svelte-i18n'

export let objectsForDisplay: { value: string; label: string; image: string }[]
export let selectedDog: any

function formatSelectedState(state: any) {
	if (!state.id) {
		return state.text
	}

	const jQuerystate = (window as any).jQuery(
		`<span class="flex align-center">
            <img width="27" style="object-fit: cover; border-radius: 50%; width:27px; height:27px; margin-right: 10px;" 
                 src=${state.element.dataset.image} class="img-flag" > ${state.element.text}
        </span>`
	)

	return jQuerystate
}

async function loadScript(src: string) {
	const script = document.createElement('script')
	script.src = src
	document.body.appendChild(script)
	await new Promise((resolve, reject) => {
		script.onload = () => resolve(true)
		script.onerror = () => reject(new Error(`${src} failed to load`))
	})
}

async function initSelect2() {
	const $dogSelect = (window as any).jQuery('.dog-select')

	$dogSelect.select2({
		// Adding a blank value to prevent auto-select of the first object
		placeholder: {
			id: '',
			text: $_('select_a_dog')
		},
		language: {
			noResults: function () {
				return $_('no_results_found')
			}
		},
		dropdownPosition: 'below',
		templateResult: formatState,
		templateSelection: formatSelectedState
	})

	$dogSelect.on('select2:open', function () {
		(window as any).jQuery('.select2-search__field').attr('placeholder', $_('search_a_dog'))
	})

	$dogSelect.on('select2:select', function (e) {
		selectedDog = (window as any).jQuery(this).val()
	})
}

function formatState(state: any) {
	if (!state.id) {
		return state.text
	}
	var jQuerystate = (window as any).jQuery(
		`<span class="flex align-center"><img width="50" style="object-fit: cover; border-radius: 50%; width:50px; height:50px; margin-right: 10px" height="50" src=${state.element.dataset.image} class="img-flag" > 
				 ${state.element.text}
			 </span>`
	)
	return jQuerystate
}

onMount(async () => {
	await loadScript('/assets/js/jquery-3.7.2.min.js')
	await loadScript('/assets/js/select2.min.js')
	initSelect2()
})
</script>

<select
	id="dog-select"
	class="dog-select edit-input"
	name="state"
	bind:value={selectedDog}>
	<option
		selected
		disabled
		value=""
		hidden>{$_('select_a_dog')}</option>
	{#if objectsForDisplay.length > 0}
		{#each objectsForDisplay as object}
			<option
				class="option"
				data-image={object.image}
				value={object.value}>{object.label}</option>
		{/each}
	{/if}
</select>

<style lang="scss">
@import 'select2/dist/css/select2.css';
:global(.select2-container--default .select2-search--dropdown .select2-search__field) {
	// Custom styles for input field
	border: 1px solid #aaa;
	padding: 5px 10px;
	font-size: 16px;
}
:global(.select2-container--default .select2-selection--single) {
	// Custom styles for the selection container
	border: 1px solid #aaa;
	border-radius: 4px;
	padding: 5px;
}

:global(.select2-container--default .select2-selection--single .select2-selection__rendered) {
	// Custom styles for the selected item text
	line-height: 28px;
}

:global(.select2-container--default .select2-selection--single .select2-selection__arrow) {
	// Custom styles for the dropdown arrow
	height: 34px;
}

:global(.select2-dropdown) {
	// Custom styles for the dropdown container
	border: 1px solid #aaa;
}
</style>
