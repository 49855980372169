<script lang="ts">
import Icon from 'svelte-icons-pack'
import CgMenuLeft from 'svelte-icons-pack/cg/CgMenuLeft'
// import IoNotifications from 'svelte-icons-pack/io/IoNotifications' //! DON'T DELETE (see ! comment below)
import RiMediaFullscreenFill from 'svelte-icons-pack/ri/RiMediaFullscreenFill'
import RiMediaFullscreenExitFill from 'svelte-icons-pack/ri/RiMediaFullscreenExitFill'
import CgChevronLeft from 'svelte-icons-pack/cg/CgChevronLeft'
import { Link, useLocation, useNavigate } from 'svelte-navigator'
import { loggedInUser } from '../../store/stores'
import { navBarOpen } from '../../store/stores'
import NameInitials from '../common/NameInitials.svelte'
import { utilService } from '../../utils/util.service'
import { _ } from 'svelte-i18n'
import NavigationAlert from './NavigationAlert.svelte'
import { clearAllStores } from '../../store/trainingWidgetsStore'
import { onSnapshot, type Unsubscribe } from 'firebase/firestore'
import { trainingDB } from '../../services/database/training.db'
import type { TrainingModel } from '../../models/training.model'
import { onMount } from 'svelte'
import HiOutlineSparkles from 'svelte-icons-pack/hi/HiOutlineSparkles'
import Modal from '../common/modal/AISummary.svelte'
import { selectedAITraining } from '../../store/stores'

let unsubscribe: Unsubscribe

let showAlert = false
let alertMessage: string
let isAiSummaryShown = false
let allTrainings = []

const location = useLocation()
const navigate = useNavigate()
const pageName = $location.state?.pageName

const toggleNavbar = () => {
	$navBarOpen = !$navBarOpen
}

const toggleFullScreen = async () => {
	if (!document.fullscreenElement) {
		if (document.documentElement.requestFullscreen) {
			await document.documentElement.requestFullscreen()
		}
	} else await document.exitFullscreen()
	isInFullscreen = document.fullscreenElement
}

function handleNavigation() {
	if (pageName === 'Add Training') {
		showAlert = true
		alertMessage = $_('add_training_alert')
	} else if (pageName === 'Update Training') {
		showAlert = true
		alertMessage = $_('update_training_alert')
	} else {
		navigate(-1)
	}
}

async function getAllTrainings() {
	const paginated = await trainingDB.getAllTrainings($loggedInUser.selectedAccount.id)
	unsubscribe = onSnapshot(paginated, (querySnapshot) => {
		querySnapshot.forEach((doc) => allTrainings.push(doc.data() as TrainingModel))
	})
}

function onAlertConfirm() {
	clearAllStores()
	navigate(-1)
	showAlert = false
}

function onModalCancel() {
	showAlert = false
}

let isInFullscreen = document.fullscreenElement

function removeDuplicatesAndCountTypes(array) {
	// Array to store type info for each type
	const typeInfo = []

	// Iterate through the array to update typeInfo and calculate total training minutes
	array.forEach((obj) => {
		const { id, type, dog, trainingTypeId, dateTime, iconUrl, firstDate, trainingTime, handler } =
			obj
		const existingIndex = typeInfo.findIndex((item) => item.type === type && item.dog.id === dog.id)

		if (existingIndex === -1) {
			// If type not found, add it to the array
			typeInfo.push({
				type,
				trainingTypeId,
				dog,
				count: 1, // Initialize count to 1 for a new entry
				latestDate: dateTime,
				iconUrl,
				firstDate: dateTime,
				handler,
				ids: [id],
				totalTrainingMinutes: trainingTime // Initialize totalTrainingMinutes with trainingTime
			})
		} else {
			// If type found, update latest date, and add id if necessary
			if (dateTime > typeInfo[existingIndex].latestDate) {
				typeInfo[existingIndex].latestDate = dateTime
			}
			if (dateTime < typeInfo[existingIndex].latestDate) {
				typeInfo[existingIndex].firstDate = dateTime
			}
			typeInfo[existingIndex].ids.push(id)

			// Update totalTrainingMinutes for the type
			typeInfo[existingIndex].totalTrainingMinutes += trainingTime

			// Increment count for the existing entry
			typeInfo[existingIndex].count++
		}
	})

	// Return the sorted unique array, type info array, and total training minutes
	return { typeInfo }
}

onMount(() => {
	getAllTrainings()
})
</script>

{#if $selectedAITraining && isAiSummaryShown}
	<Modal
		summaryTraining={$selectedAITraining}
		on:close={() => (isAiSummaryShown = false)} />
{/if}

<header class="flex align-center main-header">
	<div class="flex align-center aside">
		<button
			type="button"
			on:click={() => toggleNavbar()}
			class="btn btn-svg btn-header menu"
			title={$navBarOpen ? 'Close Navbar' : 'Open Navbar'}>
			<Icon src={CgMenuLeft} />
		</button>
		{#if pageName}
			<div class="flex align-center page-navigation">
				<button
					type="button"
					class="btn btn-svg btn-header go-back"
					on:click={handleNavigation}
					title="Go Back">
					<Icon src={CgChevronLeft} />
				</button>
				<h3>{$_(pageName)}</h3>
			</div>
		{:else}
			<div class="user-greeting">
				<h3>{$_(utilService.userGreeting()) + ', ' + $loggedInUser.firstName} 👋</h3>
			</div>
		{/if}
	</div>
	<div class="flex align-center btn-container">
		{#if document.fullscreenEnabled}
			<button
				class="btn btn-svg btn-header full-screen"
				on:click={toggleFullScreen}
				title={isInFullscreen ? 'Exit full screen' : 'Enter full screen'}>
				<Icon src={isInFullscreen ? RiMediaFullscreenExitFill : RiMediaFullscreenFill} />
			</button>
		{/if}
		{#if pageName == 'Training Details'}
			<button
				on:click|stopPropagation={() => (isAiSummaryShown = true)}
				class="btn btn-svg-recap btn-edit"
				title="AI Recap">
				<Icon src={HiOutlineSparkles} />
			</button>
		{/if}
		<Link
			to="/profile"
			title="profile page">
			<div
				class="btn btn-svg btn-header profile"
				title="Profile">
				<NameInitials
					name="{$loggedInUser.firstName} {$loggedInUser.lastName}"
					theme="secondary"
					fontSize={18} />
			</div>
		</Link>
	</div>
</header>
{#if showAlert}
	<NavigationAlert
		message={alertMessage}
		onConfirm={onAlertConfirm}
		onCancel={onModalCancel} />
{/if}

<style lang="scss">
.main-header {
	height: 62px;
	padding-inline: 14px;
	background-color: white;
	box-shadow: var(--shadow-card);
	border-radius: 6px;
	margin-bottom: 24px;

	:global(.btn-header) {
		color: var(--clr-btn-svg);
		fill: var(--clr-btn-svg);
	}

	.aside {
		margin-inline-end: auto;

		.page-navigation,
		.user-greeting {
			h3 {
				color: var(--clr-txt-dark-primary);
				font-size: 18px;
				font-weight: 500;
			}
		}

		.page-navigation {
			gap: 6px;
		}

		.user-greeting {
			margin-inline-start: 30px;
		}
	}

	.btn-container {
		.profile {
			width: 55px;
			height: 55px;
		}
	}

	@media only screen and (max-width: 768px) {
		.aside {
			.user-greeting {
				margin-inline-start: 10px;
				h3 {
					font-size: 16px;
					white-space: nowrap;
				}
			}
			.page-navigation {
				h3 {
					font-size: 15px;
					white-space: nowrap;
					overflow: hidden;
				}
			}
		}

		.btn-container {
			.full-screen {
				display: none;
			}
		}
	}
}

.btn-svg-recap {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	box-shadow: 4px 8px 18px -4px rgba(0, 0, 0, 0.19);
	border-radius: 1000px;
	width: 37px;
	aspect-ratio: 1;

	&:not(:hover) {
		:global(svg) {
			fill: var(--clr-btn-svg);
		}
	}

	:global(svg) {
		font-size: 18px;
	}
}
</style>
