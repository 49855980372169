<script lang="ts">
import { currAccount } from '../../store/stores'
import { _ } from 'svelte-i18n'
import CmpLoader from '../common/loader/CmpLoader.svelte'
import NameInitials from '../common/NameInitials.svelte'
import moment from 'moment'
import SvgIcon from '../common/SvgIcon.svelte'
import { utilService } from '../../utils/util.service'
import BsLightningCharge from 'svelte-icons-pack/bs/BsLightningCharge'
import Icon from 'svelte-icons-pack'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

moment.locale(localStorage.getItem('language'))

export let activities
</script>

<section class="flex-column activities-list">
	<div class="flex-column content">
		<h1>{$_('activity_feed')}</h1>
		{#if !activities}
			<div class="loading flex align-center justify-center">
				<div class="preloader">
					<CmpLoader />
				</div>
			</div>
		{:else if activities && activities.length > 0}
			{#each activities as activity}
				<div class="activity flex-row align-center">
					<div class="btn-container">
						{#if utilService.getActivitiesData(activity).icon}
							<SvgIcon
								width="40px"
								name={utilService.getActivitiesData(activity).icon} />
						{:else if utilService.getActivitiesData(activity).image}
							<div
								class={activity.type == 'training_added'
									? 'training-img-container'
									: 'img-container'}>
								<img
									src={utilService.getActivitiesData(activity).image}
									alt="img" />
							</div>
						{:else if utilService.getActivitiesData(activity).name}
							<div class="btn-container">
								<NameInitials
									name={utilService.getActivitiesData(activity).name}
									theme="secondary"
									fontSize={18} />
							</div>
						{:else}
							<div class="btn-container">
								<NameInitials
									name={$currAccount.name}
									theme="secondary"
									fontSize={18} />
							</div>
						{/if}
					</div>
					<div class="info flex-column">
						<p class="title">
							{$_(`tags.${activity.type}`)}
						</p>
						<p class="description">{utilService.getActivitiesData(activity).info}</p>
					</div>
					<div class="time flex-row align-center">
						<div class="just-now flex align-center justify-center">
							{#if moment().diff(moment(activity.createdAt.toDate()), 'minutes') < 5}
								<div class="circle" />
							{/if}
						</div>
						<p>{utilService.getProperTime(activity.createdAt.toDate())}</p>
					</div>
				</div>
			{/each}
		{:else}
			<div class="no-activities flex justify-center">
				<Icon src={BsLightningCharge} />
				<p class="description">{$_('no_activities_message')}</p>
			</div>
		{/if}
	</div>
</section>

<style lang="scss">
@use '../../styles/setup/mixins';

.activities-list {
	@include mixins.card;
	width: 100%;
	overflow-x: auto;

	h1 {
		font-size: 22px;
		font-family: Nunito-Bold;
		margin: 13px 0px;
	}

	.content {
		padding: 0px 20px;
		.activity {
			border-bottom: 1px solid #ebe9f1;
			padding-block: 10px;
			padding-inline: 14px;
			transition: background-color 0.1s ease-out;
			height: 56px;
			gap: 7px;

			&:last-child {
				margin-bottom: 10px;
			}

			.training-img-container {
				background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 1000px;
				width: 40px;
				height: 40px;
				aspect-ratio: 1/1;

				img {
					filter: none;
					width: 27px;
					height: 27px;
				}
			}

			.time {
				margin: 0 0 0 auto;
				color: var(--clr-txt-dark-secondary);
				gap: 16px;
				font-size: 11px;

				.just-now {
					height: 16px;
					.circle {
						width: 6px;
						height: 6px;
						border-radius: 1000px;
						background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
					}
				}
			}

			.info {
				color: var(--clr-txt-dark-secondary);
				white-space: nowrap;
				font-size: 12px;
				.title {
					font-size: 15px;
					border-radius: 6px;

					font-weight: 900;
					color: var(--clr-txt-dark-primary);
				}
			}

			@media screen and (max-width: 768px) {
				.info {
					.description {
						display: none !important;
					}
				}
				.time {
					white-space: nowrap;
				}
			}
		}

		.loading {
			height: 160px;
			.preloader {
				margin-top: -40px;
			}
		}

		.activity:last-child {
			border-bottom: none;
		}

		.btn-container {
			width: 40px;
			height: 40px;
			margin-right: 12px;

			.img-container {
				width: 40px;
				aspect-ratio: 1/1;
				border-radius: 50%;
				overflow: hidden;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.no-activities {
		width: 100%;
		height: 160px;
		color: var(--clr-txt-dark-secondary);
		font-size: 14px;
		gap: 3px;
		padding-top: 50px;

		p {
			margin-top: -3px;
		}
	}
}
</style>
