import { Timestamp } from 'firebase/firestore'
import { dogDB } from '../database/dog.db'
import type { DogModel, DogStatus } from '../../models/dog.model'
import { storageService } from './storage.service'
import { userMsg } from '../../utils/userMsg.service'

export const dogService = {
	query,
	getById,
	create,
	update,
	uploadDogImg,
	getEmptyDog
}

async function query(
	accountId: string,
	itemsPerPage = 1000,
	filterBy?: Partial<{ status: DogStatus[]; dogIds: string[]; userId: string }>
): Promise<DogModel[]> {
	const dogs = []

	try {
		const dogDocs = await dogDB.getMany(accountId, itemsPerPage, filterBy)
		dogDocs.forEach((doc) => dogs.push(doc.data()))
		return dogs
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get dogs', error)
	}
}

async function getById(dogId: string): Promise<DogModel> {
	try {
		const dog = await dogDB.get(dogId)
		return dog as DogModel
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot get dog', error)
	}
}

async function create(initialData: Partial<DogModel>): Promise<void> {
	try {
		const dog = getEmptyDog(initialData)
		await dogDB.add(dog)
		userMsg.successMsg('Dog created successfully')
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot create dog', error)
	}
}

async function update(updatedDog: DogModel): Promise<void> {
	try {
		const res = await dogDB.update(updatedDog.id, updatedDog)
		userMsg.successMsg('Dog updated successfully')
		return res
	} catch (error: unknown) {
		userMsg.errorMsg('Cannot update dog', error)
	}
}

async function uploadDogImg(dogId: string, imgStr: string): Promise<string> {
	return storageService.uploadImgStr(`dogs/${dogId}.jpg`, imgStr)
}

function getEmptyDog(initialData?: Partial<DogModel>): DogModel {
	const emptyDog: DogModel = {
		id: '',
		createdBy: '',
		name: '',
		accountId: '',
		teams: [],
		image: '',
		breed: {
			breedId: 0,
			name: 'Mixed',
			type: 'Mixed Breed',
			country: ''
		},
		birthday: Timestamp.now(),
		weight: 0,
		unitOfMeasurement: 'metric',
		gender: 'male',
		type: [],
		status: 'active',
		registered: false,
		chipId: null,
		stats: {
			recentTraining: null,
			recentTrainingRef: null,
			totalTrainingSessions: 0,
			totalTrainingTime: 0,
			totalTrainingDays: 0
		},
		handler: null,
		note: null,
		createdAt: null,
		updatedAt: null,
		archived: false
	}

	const dogWithInitData = { ...emptyDog, ...initialData }

	return dogWithInitData
}
