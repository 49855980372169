<script lang="ts">
import { createEventDispatcher } from 'svelte'
import { onMount } from 'svelte'
import type { TeamModel } from '../../../models/team.model'
import { teamService } from '../../../services/core/team.service'
import { loggedInUser } from '../../../store/stores'
import Select from 'svelte-select/Select.svelte'
import { _ } from 'svelte-i18n'
import { utilService } from '../../../utils/util.service.js'
import CgClose from 'svelte-icons-pack/cg/CgClose'
import Icon from 'svelte-icons-pack'
import { authValidation } from '../../../utils/validation/auth.validation'
import type { AuthField } from '../../../utils/validation/auth.validation'
import { fireMsg, userMsg } from '../../../utils/userMsg.service.js'

export let teamSelected = false
export let team = ''

onMount(async () => {
	getTeams()
})

export let isOpen

//adding createEventDispathcer helps pass data between parent and child components
const dispatch = createEventDispatcher()

let firstName: AuthField = {
	name: 'firstName',
	value: '',
	invalid: false,
	errorMessage: ''
}

let lastName: AuthField = {
	name: 'lastName',
	value: '',
	invalid: false,
	errorMessage: ''
}

let email: AuthField = {
	name: 'email',
	value: '',
	invalid: false,
	errorMessage: ''
}

async function getTeams() {
	teamsOptionsPromise = teamService.query($loggedInUser.id, $loggedInUser.selectedAccount.id)
	teamsOptions = await teamsOptionsPromise
}

let teamsOptionsPromise: Promise<TeamModel[]>
let teamsOptions: TeamModel[]
let selectedTeams: { value: ''; label: '' }[]
let selectedTeamsIds: string[]

function cleanInput(inputId: AuthField) {
	isFormDisabled = false
	inputId = authValidation.email(inputId)
}

function validateEmail() {
	email = authValidation['email'](email)
}

type InputIds = ['firstName', 'lastName', 'email', 'teams']
type InputId = InputIds[number]

let fieldsValidation = {
	firstName: { invalid: false, errorMessage: '' },
	lastName: { invalid: false, errorMessage: '' },
	email: { invalid: false, errorMessage: '' },
	teams: { invalid: false, errorMessage: '' }
}

async function validateInput(inputId: InputId) {
	const nameRegEx = /^[a-zA-Z\u0590-\u05FF\u200f\u200e0-9 ]+$/
	switch (inputId) {
		case 'firstName':
			if (firstName.value.length < 2) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_two_characters_message')
				return
			}
			if (!nameRegEx.test(firstName.value)) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_special_characters_message')
				return
			}
			break

		case 'lastName':
			if (lastName.value.length < 2) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_two_characters_message')
				return
			}
			if (!nameRegEx.test(lastName.value)) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_special_characters_message')
				return
			}
			break

		case 'teams':
			if (!teamSelected) {
				if (selectedTeams.length === 0) {
					fieldsValidation[inputId].invalid = true
					fieldsValidation[inputId].errorMessage = $_('at_least_one_team_message')
					return
				}
			}
			break

		case 'email':
			if (email.invalid) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('invalid_email')
				return
			}
			if (email.value == '') {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('invalid_email')
				return
			}
			break

		default:
			break
	}

	fieldsValidation[inputId].invalid = false
	fieldsValidation[inputId].errorMessage = ''
}

async function validateAllFields() {
	let isValid = true
	for (const fieldId in fieldsValidation) {
		if (Object.prototype.hasOwnProperty.call(fieldsValidation, fieldId)) {
			await validateInput(fieldId as InputId)
			if (fieldsValidation[fieldId].invalid) {
				fireMsg({
					type: 'failure',
					msg: `Error in field: ${fieldId}, ${fieldsValidation[fieldId].errorMessage}`
				})
				isValid = false
			}
		}
	}
	return isValid
}

function closeModal() {
	isOpen = false

	firstName.invalid = false
	lastName.invalid = false
	email.errorMessage = ''

	email.invalid = false
	email.errorMessage = ''

	email.value = ''
	firstName.value = ''
	lastName.value = ''
	selectedTeams = []

	fieldsValidation = {
		firstName: { invalid: false, errorMessage: '' },
		lastName: { invalid: false, errorMessage: '' },
		email: { invalid: false, errorMessage: '' },
		teams: { invalid: false, errorMessage: '' }
	}

	dispatch('closeModal', { isOpen })
}

let isFormDisabled = false

async function handleSubmit(ev: Event) {
	isFormDisabled = true
	ev.preventDefault()
	ev.stopPropagation()
	const isFormValid = await validateAllFields()
	if (!isFormValid) return (isFormDisabled = false)

	selectedTeamsIds = teamSelected ? [team] : selectedTeams.map((team) => team.value)

	try {
		const url = `${import.meta.env.VITE_APP_API_URL}/invite`
		const body = {
			firstName: firstName.value,
			lastName: lastName.value,
			to: email.value,
			teamIds: selectedTeamsIds
		}

		await utilService.restRequest(url, 'POST', body)
		isFormDisabled = false
		userMsg.successMsg(`${$_('user_invited')}`)
		closeModal()
	} catch (err: unknown) {
		userMsg.errorMsg('Error', err)
		isFormDisabled = false
	}
}
</script>

{#if isOpen}
	<div
		id="background"
		on:click={closeModal}
		on:keydown={closeModal} />
	<div id="modal">
		<form
			on:submit={handleSubmit}
			class="user-invite-modal flex-column">
			<header class="form-header flex">
				<h1 class="title">{$_('invite_user')}</h1>
				<button
					on:click={closeModal}
					class="close-button">
					<Icon
						src={CgClose}
						color="#D8DAE5" />
				</button>
			</header>
			<div class="field first-name flex align-center">
				<label for="first-name">{$_('first_name')}</label>
				<div class="flex-column">
					<input
						type="text"
						id="first-name"
						class:invalid={fieldsValidation['firstName'].errorMessage}
						on:focus={() => cleanInput(firstName)}
						on:blur={() => validateInput('firstName')}
						bind:value={firstName.value}
						required={true} />
					<label
						for="first-name"
						role="alert">
						{fieldsValidation['firstName'].errorMessage}
					</label>
				</div>
			</div>
			<div class="field last-name flex align-center">
				<label for="last-name">{$_('last_name')}</label>
				<div class="flex-column">
					<input
						type="text"
						id="last-name"
						class:invalid={fieldsValidation['lastName'].errorMessage}
						on:focus={() => cleanInput(lastName)}
						on:blur={() => validateInput('lastName')}
						bind:value={lastName.value}
						required={true} />
					<label
						for="last-name"
						role="alert">
						{fieldsValidation['lastName'].errorMessage}
					</label>
				</div>
			</div>
			<div class="field email flex align-center">
				<label for="email">{$_('email_for_invite')}</label>
				<div class="flex-column">
					<input
						type="email"
						class="email"
						on:focus={() => cleanInput(email)}
						class:invalid={fieldsValidation['email'].errorMessage}
						on:blur={() => validateEmail()}
						bind:value={email.value}
						required={true} />
					<label
						for="last-name"
						role="alert">
						{fieldsValidation['email'].errorMessage}
					</label>
				</div>
			</div>
			{#if !teamSelected}
				<div class="field team flex align-center">
					<label for="team">{`${$_('select_teams')}`}</label>
					{#await teamsOptionsPromise then teamsOptions}
						<Select
							class="team"
							id="team"
							placeholder={$_('please_select_teams')}
							showChevron={true}
							clearable={false}
							containerStyles="z-index: 3"
							multiple={true}
							items={teamsOptions.map((team) => {
								return { label: team.name, value: team.id }
							})}
							required={true}
							bind:value={selectedTeams} />
					{/await}
				</div>
			{/if}
			<div class="btns-container flex">
				<button
					class="btn theme-btn outline discard"
					on:click={closeModal}>
					{$_('cancel')}
				</button>
				<button
					disabled={isFormDisabled}
					type="submit"
					on:click={handleSubmit}
					class="btn theme-btn invite">{$_('invite')}</button>
			</div>
		</form>
	</div>
{/if}

<style lang="scss">
#background {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
}

#modal {
	position: fixed;
	z-index: 2000;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	background: #fff;
	width: 747px;
	border-radius: 16px;

	.user-invite-modal {
		padding: 36px 34px;
		font-size: 24px;

		.form-header {
			margin-bottom: 20px;

			.close-button {
				margin: 0 0 0 auto;
			}
		}

		.field {
			gap: 20px;
			align-items: center;
			height: 65px;
			justify-content: center;

			.flex-column {
				height: 50px;
			}
			input {
				width: 528px;
				background-color: white;
				outline: none;
				font-size: 16px;
				border: 1px solid #e6e8f0;
				border-radius: 6px;
				padding: 9px 13px;
				margin: 0 0 0 auto;
			}

			label {
				font-size: 16px;
				width: 169px;
				text-transform: capitalize;
				white-space: nowrap;
			}

			&:has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			&:has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			input.invalid {
				border-color: #b00020;
			}

			label[role='alert'] {
				color: #b00020;
				font-size: 13px;
				width: 100%;
			}
		}

		.btns-container {
			margin: 35px 0 0 auto;
			gap: 12px;
		}
	}

	@media screen and (max-width: 768px) {
		width: 90% !important;

		.field {
			flex-direction: column;
			align-items: flex-start !important;
			width: 100% !important;
			height: 100% !important;
			gap: 0 !important;

			.flex-column {
				width: 100%;
			}
			input {
				width: 100% !important;
			}
			label {
				font-size: 13px !important;
				width: 100% !important;
			}
		}
	}
}
</style>
