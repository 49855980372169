<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<rect
		width="48"
		height="48"
		rx="24"
		fill="#FFF0CF"
		fill-opacity="0.63" />
	<g clip-path="url(#clip0_354_13359)">
		<path
			d="M17.3912 15.0259C18.3115 15.0259 19.0577 14.2794 19.0577 13.3587C19.0577 12.7451 18.3176 11.3671 17.8238 10.516C17.6311 10.1838 17.1513 10.1838 16.9585 10.516C16.4648 11.3671 15.7246 12.7451 15.7246 13.3587C15.7246 14.2794 16.4708 15.0259 17.3912 15.0259Z"
			fill="url(#paint0_linear_354_13359)" />
		<path
			d="M24.0005 15.0259C24.9209 15.0259 25.6671 14.2794 25.6671 13.3587C25.6671 12.7451 24.927 11.3671 24.4332 10.516C24.2404 10.1838 23.7607 10.1838 23.5679 10.516C23.0741 11.3671 22.334 12.7451 22.334 13.3587C22.334 14.2794 23.0801 15.0259 24.0005 15.0259Z"
			fill="url(#paint1_linear_354_13359)" />
		<path
			d="M30.608 15.0259C31.5283 15.0259 32.2745 14.2794 32.2745 13.3587C32.2745 12.7451 31.5344 11.3671 31.0406 10.516C30.8478 10.1838 30.3681 10.1838 30.1753 10.516C29.6815 11.3671 28.9414 12.7451 28.9414 13.3587C28.9414 14.2794 29.6876 15.0259 30.608 15.0259Z"
			fill="url(#paint2_linear_354_13359)" />
		<path
			d="M16.4744 16.3958C16.0668 16.3958 15.7363 16.7263 15.7363 17.1341V23.0909H19.0477V17.1342C19.0477 16.7264 18.7172 16.3958 18.3095 16.3958H16.4744V16.3958Z"
			fill="url(#paint3_linear_354_13359)" />
		<path
			d="M23.0819 16.3958C22.6742 16.3958 22.3438 16.7263 22.3438 17.1341V23.0909H25.6551V17.1342C25.6551 16.7264 25.3246 16.3958 24.917 16.3958H23.0819V16.3958Z"
			fill="url(#paint4_linear_354_13359)" />
		<path
			d="M29.6912 16.3958C29.2836 16.3958 28.9531 16.7263 28.9531 17.1341V23.0909H32.2645V17.1342C32.2645 16.7264 31.934 16.3958 31.5263 16.3958H29.6912V16.3958Z"
			fill="url(#paint5_linear_354_13359)" />
		<path
			d="M13.6231 29.474C13.7566 29.474 13.8933 29.4551 14.0291 29.4149L16.0999 28.8019C16.7768 28.6015 17.4826 28.5013 18.1884 28.5013C18.8942 28.5013 19.6001 28.6015 20.2769 28.8019L21.9114 29.2858C22.5883 29.4862 23.2942 29.5863 24 29.5863C24.7058 29.5863 25.4116 29.4862 26.0885 29.2858L27.723 28.8019C28.3998 28.6015 29.1057 28.5013 29.8115 28.5013C30.5173 28.5013 31.2231 28.6015 31.9 28.8019L33.9708 29.4149C34.1066 29.4551 34.2434 29.474 34.3769 29.474C35.0396 29.474 35.623 29.0102 35.623 28.3941V26.2792C35.623 25.5781 34.9657 25.0098 34.1548 25.0098H13.8452C13.0343 25.0098 12.377 25.5781 12.377 26.2792V28.3941C12.377 29.0102 12.9605 29.474 13.6231 29.474Z"
			fill="url(#paint6_linear_354_13359)" />
		<path
			d="M37.1797 36.0926H34.9405V31.0619C34.7561 31.0964 34.5675 31.1147 34.3769 31.1147C34.082 31.1147 33.7887 31.0721 33.5051 30.9882L31.4343 30.3752C30.9119 30.2205 30.3659 30.1421 29.8115 30.1421C29.2572 30.1421 28.7112 30.2205 28.1887 30.3752L26.5542 30.859C25.7292 31.1033 24.8699 31.2271 24.0001 31.2271C23.1302 31.2271 22.2708 31.1033 21.4458 30.859L19.8113 30.3752C19.2889 30.2205 18.7429 30.1421 18.1885 30.1421C17.6342 30.1421 17.0882 30.2205 16.5657 30.3752L14.4949 30.9882C14.2113 31.0721 13.918 31.1147 13.6232 31.1147C13.4325 31.1147 13.2439 31.0965 13.0596 31.062V36.0926H10.8203C10.3673 36.0926 10 36.4599 10 36.9129C10 37.366 10.3673 37.7333 10.8203 37.7333H37.1797C37.6327 37.7333 38 37.366 38 36.9129C38 36.4599 37.6327 36.0926 37.1797 36.0926Z"
			fill="url(#paint7_linear_354_13359)" />
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_354_13359"
			x1="17.3912"
			y1="10.2668"
			x2="17.3912"
			y2="15.0259"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#FE9803" />
			<stop
				offset="1"
				stop-color="#EABF80" />
		</linearGradient>
		<linearGradient
			id="paint1_linear_354_13359"
			x1="24.0005"
			y1="10.2668"
			x2="24.0005"
			y2="15.0259"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#FE9803" />
			<stop
				offset="1"
				stop-color="#EABF80" />
		</linearGradient>
		<linearGradient
			id="paint2_linear_354_13359"
			x1="30.608"
			y1="10.2668"
			x2="30.608"
			y2="15.0259"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#FE9803" />
			<stop
				offset="1"
				stop-color="#EABF80" />
		</linearGradient>
		<linearGradient
			id="paint3_linear_354_13359"
			x1="17.392"
			y1="16.3958"
			x2="17.392"
			y2="23.0909"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#FE9803" />
			<stop
				offset="1"
				stop-color="#EABF80" />
		</linearGradient>
		<linearGradient
			id="paint4_linear_354_13359"
			x1="23.9994"
			y1="16.3958"
			x2="23.9994"
			y2="23.0909"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#FE9803" />
			<stop
				offset="1"
				stop-color="#EABF80" />
		</linearGradient>
		<linearGradient
			id="paint5_linear_354_13359"
			x1="30.6088"
			y1="16.3958"
			x2="30.6088"
			y2="23.0909"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#FE9803" />
			<stop
				offset="1"
				stop-color="#EABF80" />
		</linearGradient>
		<linearGradient
			id="paint6_linear_354_13359"
			x1="24"
			y1="25.0098"
			x2="24"
			y2="29.5863"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#FE9803" />
			<stop
				offset="1"
				stop-color="#EABF80" />
		</linearGradient>
		<linearGradient
			id="paint7_linear_354_13359"
			x1="24"
			y1="30.1421"
			x2="24"
			y2="37.7333"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#FE9803" />
			<stop
				offset="1"
				stop-color="#EABF80" />
		</linearGradient>
		<clipPath id="clip0_354_13359">
			<rect
				width="28"
				height="28"
				fill="white"
				transform="translate(10 10)" />
		</clipPath>
	</defs>
</svg>
