<script lang="ts">
import CmpLoader from '../common/loader/CmpLoader.svelte'
import HelpCenterHeader from './HelpCenterHeader.svelte'
import { beforeUpdate } from 'svelte'
import { userMsg } from '../../utils/userMsg.service'
import { _ } from 'svelte-i18n'

let signedNoltToken
let src = ''

beforeUpdate(async () => {
	const script = document.createElement('script')
	script.src = 'https://cdn.nolt.io/widgets.js'
	document.head.appendChild(script)
	;(window as any).noltQueue = (window as any).noltQueue || []
	function nolt(type: string, data: any) {
		(window as any).noltQueue.push([type, data])
	}

	//Getting session keys for nolt
	let sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'))

	try {
		signedNoltToken = sessionKeys.signedNoltToken
		nolt('identify', {
			jwt: signedNoltToken
		})
	} catch (err: unknown) {
		userMsg.errorMsg($_('reload_page'))
	}

	src = `https://feedback.dogbase.co/sso/${signedNoltToken}?returnUrl=https://feedback.dogbase.co/widget/roadmap?withHeader=false`
})
</script>

<HelpCenterHeader />
<div class="roadmap flex-column">
	{#if src == ''}
		<CmpLoader />
	{:else}
		<iframe
			data-nolt
			title="roadmap"
			width="100%"
			height="100%"
			{src} />
	{/if}
</div>

<style lang="scss">
@use '../..//styles/setup/mixins';

.roadmap {
	@include mixins.card;

	position: relative;
	width: 80%;
	height: 850px;
	margin: -50px 0 0 10%;
	align-items: center;

	@media only screen and (max-width: 768px) {
		width: 90%;
		margin-left: 5%;
	}

	.loader {
		margin-top: 150px;
	}
}
</style>
