<script lang="ts">
import { _ } from 'svelte-i18n'
import CgNotes from 'svelte-icons-pack/cg/CgNotes'
import Icon from 'svelte-icons-pack'

export let dogNotes: string
</script>

<section class="dog-notes-container">
	{#if dogNotes}
		<header class="header">
			<h1 class="title">{$_('notes')}</h1>
		</header>
		<p class="notes">{dogNotes}</p>
	{:else}
		<header class="header">
			<h1 class="title">{$_('notes')}</h1>
		</header>
		<div class="no-notes-message flex justify-center">
			<Icon src={CgNotes} />
			<p>{$_('no_notes')}</p>
		</div>
	{/if}
</section>

<style lang="scss">
@use '../../../styles/setup/mixins';

.dog-notes-container {
	@include mixins.card;

	.header {
		padding: 8px 12px 22px;
		color: var(--clr-txt-dark-primary);

		h1 {
			font-size: 22px;
		}
	}

	.notes {
		padding: 0px 28px 26px 28px;
		color: var(--clr-txt-dark-secondary);
		font-size: 14px;
	}

	.no-notes-message {
		color: var(--clr-txt-dark-secondary);
		font-size: 14px;
		padding-top: 20px;
		height: 100px;
		gap: 5px;

		p {
			margin-top: -3px;
		}
	}
}
</style>
