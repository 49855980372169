<script lang="ts">
import Icon from 'svelte-icons-pack'
import IoSearch from 'svelte-icons-pack/io/IoSearch'
import GettingStarted from '../cmps/helpCenter/icons/GettingStartedIcon.svelte'
import ContactUs from '../cmps/helpCenter/icons/ContactUsIcon.svelte'
import KnowledgeBase from '../cmps/helpCenter/icons/KnowledgeBaseIcon.svelte'
import About from '../cmps/helpCenter/icons/AboutIcon.svelte'
import WhatsNew from '../cmps/helpCenter/icons/WhatsNewIcon.svelte'
import Roadmap from '../cmps/helpCenter/icons/RoadmapIcon.svelte'
import Faq from '../cmps/helpCenter/icons/FAQIcon.svelte'
import { navigate } from 'svelte-navigator'
import { _ } from 'svelte-i18n'
import RequestIcon from '../cmps/helpCenter/icons/RequestIcon.svelte'
import StatusIcon from '../cmps/helpCenter/icons/StatusIcon.svelte'
import { onMount } from 'svelte'
import { beaconService } from '../services/APIs/beacon.service'

onMount(() => {
	if (window.Beacon) window.Beacon('close')
	beaconService.identifyBeacon()
})

const cards = [
	{
		name: 'getting_started',
		icon: GettingStarted,
		action: () =>
			window.open('https://support.dogbase.co/category/40-getting-started---teams-webapp')
	},
	{
		name: 'contact_us',
		icon: ContactUs,
		action: () => {
			beaconService.navigateBeacon('/ask/')
			beaconService.openBeacon()
		}
	},
	{
		name: 'get_support',
		icon: KnowledgeBase,
		action: () => {
			window.Beacon('open')
			window.Beacon('navigate', '/answers/')
		}
	},
	{
		name: 'whats_new',
		icon: WhatsNew,
		action: () => window.open('https://support.dogbase.co/category/50-whats-new')
	},
	{
		name: 'request_a_feature',
		icon: RequestIcon,
		action: () => navigate('request', { state: { pageName: $_('request_a_feature') } })
	},
	{
		name: 'roadmap',
		icon: Roadmap,
		action: () => navigate('roadmap', { state: { pageName: $_('roadmap') } })
	},
	//{
	//  name: 'Video Tutorials',
	//	icon: VideoTutorials
	//},
	{
		name: 'faq',
		icon: Faq,
		action: () => window.open('https://support.dogbase.co/category/12-faq')
	},
	{
		name: 'status',
		icon: StatusIcon,
		action: () => window.open('https://status.dogbase.co')
	},
	{
		name: 'about',
		icon: About,
		action: () => window.open('https://www.dogbase.co/about')
	}
]
</script>

<div class="help-center">
	<header class="flex page-header">
		<div class="flex-row">
			<Icon
				src={IoSearch}
				size="32"
				color="white" />
			<h1>{$_('how_can_we_help_you')}</h1>
		</div>
	</header>
	<div class="grid cards">
		{#each cards as card (card.name)}
			<button
				on:click={card.action}
				class="flex-column align-center justify-center card button {card.name}">
				<svelte:component this={card.icon} />
				<h1>{$_(card.name)}</h1>
			</button>
		{/each}
	</div>
</div>

<style lang="scss">
@use '../styles/setup/mixins';

.help-center {
	.page-header {
		@include mixins.card;
		background: linear-gradient(283.95deg, #1f74a8 -12.57%, var(--clr-primary) 126.88%);
		position: relative;
		height: 176px;

		div {
			margin: 42.5px auto 0 22px;
			gap: 16px;

			h1 {
				margin-top: -5px;
				font-size: 32px;
				color: white;

				@media only screen and (max-width: 768px) {
					font-size: 22px;
					margin-top: 5px;
				}
			}
		}

		.search-button {
			background: var(--clr-primary-bg);
			margin: 36px 45px 0 auto;
			color: #264a59;
			height: 40px;
		}
	}

	.cards {
		justify-content: center;
		position: relative;
		margin-top: -50px;
		grid-template-columns: repeat(auto-fit, 340px);
		grid-template-rows: repeat(auto-fit, 250px);
		row-gap: 24px;
		column-gap: 27px;

		.card {
			@include mixins.card;
			background-color: white;
			border: none;
			height: 250px;
			gap: 20px;
		}

		@media only screen and (max-width: 768px) {
			grid-template-columns: repeat(auto-fit, 310px);
		}
	}

	.view-more {
		width: max-content;
		height: 40px;
	}
}
</style>
