<script lang="ts">
import { utilService } from '../../../utils/util.service'
import { distanceStepper } from '../../../store/trainingWidgetsStore'
import { _ } from 'svelte-i18n'

export let values: {
	footStepAmount: number
	meterStepAmount: number
	value: {
		distance: number
		label: 'meters' | 'feet'
	}
	filled: boolean
}
export let data = []

export let id: string

let label: 'meters' | 'feet' | 'miles' | 'kilometers'

let unitsOfMeasurement: string

localStorage.getItem('measurement-type')
	? (unitsOfMeasurement = localStorage.getItem('measurement-type'))
	: (unitsOfMeasurement = 'metric')

if (data.length > 0) {
	values.value = {
		distance: data[0].value.distance,
		label: data[0].value.label
	}
} else {
	values.value = {
		distance: 0,
		label: unitsOfMeasurement == 'metric' ? 'meters' : 'feet'
	}
}

if (!$distanceStepper[id]) {
	$distanceStepper[id] = 0
}

if ($distanceStepper[id]) {
	values.value.distance = $distanceStepper[id]
} else if (data.length > 0) {
	values.value.distance = data[0].value.distance
} else {
	values.value.distance = 0
}

let displayedValue = 0

function increment(event: Event) {
	event.preventDefault()
	let step = unitsOfMeasurement == 'metric' ? values.meterStepAmount : values.footStepAmount
	values.value.distance = Math.min(values.value.distance + step)
	$distanceStepper[id] = values.value.distance
}

function decrement(event: Event) {
	event.preventDefault()
	let step = unitsOfMeasurement == 'metric' ? values.meterStepAmount : values.footStepAmount
	values.value.distance = Math.max(values.value.distance - step, 0)
	$distanceStepper[id] = values.value.distance
}

$: displayedValue = values.value.distance

$: {
	switch (unitsOfMeasurement) {
		case 'metric':
			if (values.value.distance > 999) {
				label = 'kilometers'
				displayedValue = +(values.value.distance / 1000).toFixed(1)
			} else {
				label = 'meters'
			}
			break
		case 'imperial':
			if (values.value.distance > 5279) {
				label = 'miles'
				displayedValue = +(values.value.distance / 5280).toFixed(2)
			} else {
				label = 'feet'
			}
			break
		default:
			break
	}
}

$: {
	if (values.value.distance != 0) {
		values.filled = true
	} else {
		values.filled = false
	}
}
</script>

<div class="stepper flex align-center">
	<button
		class="stepper-button"
		on:click|preventDefault={decrement}
		disabled={values.value.distance === 0}>-</button>
	<span class="stepper-value flex">{displayedValue} {$_(utilService.getShortLabel(label))}</span>
	<button
		class="stepper-button"
		on:click|preventDefault={increment}>+</button>
</div>

<style>
.stepper {
	width: 200px;
	height: 40px;
	overflow: hidden;
	gap: 3.5px;
}

.stepper-button {
	padding: 9px 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.08);
}

.stepper-button:hover {
	background-color: #e5e5e5;
}

.stepper-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.stepper-value {
	min-width: 80px;
	height: 25px;
	text-align: center;
	justify-content: center;
	align-items: center;
	padding: 2px 13px;
	border-radius: 2px;
	border: 1px solid #e6e8f0;
	background: var(--primary-white, #fff);
}
</style>
