<script lang="ts">
import { useNavigate } from 'svelte-navigator'
import { fireMsg } from '../../../utils/userMsg.service'
import { currAccount } from '../../../store/stores'
import { _ } from 'svelte-i18n'

export let sessionKeys: any

let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

const navigate = useNavigate()

let showTooltip = false
function toggleTooltip() {
	showTooltip = true
}

function hideTooltip() {
	showTooltip = false
}
</script>

{#if !sessionKeys}
	<div class="container">
		<button
			disabled={true}
			class="add-dog"
			on:click={() => {
				if ($currAccount.teamIds) {
					navigate('/dogs/add', { state: { pageName: $_('add_dog') } })
				} else {
					fireMsg({
						type: 'failure',
						msg: $_('no_team_message')
					})
				}
			}}
			title={$_('add_dog')}>
			<div class="flex-column btn-content">
				<div class="svg-container">
					<svg
						width="512"
						height="512"
						viewBox="0 0 512 512"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M269.4 2.9C265.2 1 260.7 0 256 0C251.3 0 246.8 1 242.6 2.9L54.3 82.8C32.3 92.1 15.9 113.8 16 140C16.5 239.2 57.3 420.7 229.6 503.2C246.3 511.2 265.7 511.2 282.4 503.2C454.7 420.7 495.5 239.2 496 140C496.1 113.8 479.7 92.1 457.7 82.8L269.4 2.9ZM160.9 286.2C165.7 287.4 170.8 288 176 288C211.3 288 240 259.3 240 224V160H284.2C296.3 160 307.4 166.8 312.8 177.7L320 192H384C392.8 192 400 199.2 400 208V240C400 284.2 364.2 320 320 320H272V370.7C272 378 266.1 384 258.7 384C256.9 384 255.1 383.6 253.5 382.9L154.8 340.6C148.2 337.8 144 331.3 144 324.2C144 321.4 144.6 318.7 145.9 316.2L160.9 286.2ZM160 160H200H208V192V224C208 241.7 193.7 256 176 256C158.3 256 144 241.7 144 224V176C144 167.2 151.2 160 160 160ZM288 208C288 199.2 280.8 192 272 192C263.2 192 256 199.2 256 208C256 216.8 263.2 224 272 224C280.8 224 288 216.8 288 208Z"
							fill="url(#paint0_linear_1_2)" />
						<defs>
							<linearGradient
								id="paint0_linear_1_2"
								x1="256"
								y1="0"
								x2="256"
								y2="509.2"
								gradientUnits="userSpaceOnUse">
								<stop stop-color="#496D7D" />
								<stop
									offset="1"
									stop-color="#15455A" />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('add')}</span>
						<span>{$_('dog')}</span>
					</h1>
				</header>
			</div>
		</button>
	</div>
{:else if disabled}
	<div
		class="container"
		on:mouseover={toggleTooltip}
		on:mouseout={hideTooltip}
		on:focus={toggleTooltip}
		on:blur={hideTooltip}
		aria-hidden={!showTooltip}>
		<button
			{disabled}
			class="add-dog"
			on:click={() => {
				if ($currAccount.teamIds) {
					navigate('/dogs/add', { state: { pageName: $_('add_dog') } })
				} else {
					fireMsg({
						type: 'failure',
						msg: $_('no_team_message')
					})
				}
			}}
			title={$_('add_dog')}>
			<div class="flex-column btn-content">
				<div class="svg-container">
					<svg
						width="512"
						height="512"
						viewBox="0 0 512 512"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M269.4 2.9C265.2 1 260.7 0 256 0C251.3 0 246.8 1 242.6 2.9L54.3 82.8C32.3 92.1 15.9 113.8 16 140C16.5 239.2 57.3 420.7 229.6 503.2C246.3 511.2 265.7 511.2 282.4 503.2C454.7 420.7 495.5 239.2 496 140C496.1 113.8 479.7 92.1 457.7 82.8L269.4 2.9ZM160.9 286.2C165.7 287.4 170.8 288 176 288C211.3 288 240 259.3 240 224V160H284.2C296.3 160 307.4 166.8 312.8 177.7L320 192H384C392.8 192 400 199.2 400 208V240C400 284.2 364.2 320 320 320H272V370.7C272 378 266.1 384 258.7 384C256.9 384 255.1 383.6 253.5 382.9L154.8 340.6C148.2 337.8 144 331.3 144 324.2C144 321.4 144.6 318.7 145.9 316.2L160.9 286.2ZM160 160H200H208V192V224C208 241.7 193.7 256 176 256C158.3 256 144 241.7 144 224V176C144 167.2 151.2 160 160 160ZM288 208C288 199.2 280.8 192 272 192C263.2 192 256 199.2 256 208C256 216.8 263.2 224 272 224C280.8 224 288 216.8 288 208Z"
							fill="#808080" />
						<defs>
							<linearGradient
								id="paint0_linear_1_2"
								x1="256"
								y1="0"
								x2="256"
								y2="509.2"
								gradientUnits="userSpaceOnUse">
								<stop stop-color="#808080" />
								<stop
									offset="1"
									stop-color="#808080" />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('add')}</span>
						<span>{$_('dog')}</span>
					</h1>
				</header>
			</div>
		</button>
		{#if showTooltip}
			<div class="tooltip">{$_('locked_feature')}</div>
		{/if}
	</div>
{:else if !disabled}
	<div class="container">
		<button
			{disabled}
			class="add-dog"
			on:click={() => {
				if ($currAccount.teamIds) {
					navigate('/dogs/add', { state: { pageName: $_('add_dog') } })
				} else {
					fireMsg({
						type: 'failure',
						msg: $_('no_team_message')
					})
				}
			}}
			title={$_('add_dog')}>
			<div class="flex-column btn-content">
				<div class="svg-container">
					<svg
						width="512"
						height="512"
						viewBox="0 0 512 512"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M269.4 2.9C265.2 1 260.7 0 256 0C251.3 0 246.8 1 242.6 2.9L54.3 82.8C32.3 92.1 15.9 113.8 16 140C16.5 239.2 57.3 420.7 229.6 503.2C246.3 511.2 265.7 511.2 282.4 503.2C454.7 420.7 495.5 239.2 496 140C496.1 113.8 479.7 92.1 457.7 82.8L269.4 2.9ZM160.9 286.2C165.7 287.4 170.8 288 176 288C211.3 288 240 259.3 240 224V160H284.2C296.3 160 307.4 166.8 312.8 177.7L320 192H384C392.8 192 400 199.2 400 208V240C400 284.2 364.2 320 320 320H272V370.7C272 378 266.1 384 258.7 384C256.9 384 255.1 383.6 253.5 382.9L154.8 340.6C148.2 337.8 144 331.3 144 324.2C144 321.4 144.6 318.7 145.9 316.2L160.9 286.2ZM160 160H200H208V192V224C208 241.7 193.7 256 176 256C158.3 256 144 241.7 144 224V176C144 167.2 151.2 160 160 160ZM288 208C288 199.2 280.8 192 272 192C263.2 192 256 199.2 256 208C256 216.8 263.2 224 272 224C280.8 224 288 216.8 288 208Z"
							fill="url(#paint0_linear_1_2)" />
						<defs>
							<linearGradient
								id="paint0_linear_1_2"
								x1="256"
								y1="0"
								x2="256"
								y2="509.2"
								gradientUnits="userSpaceOnUse">
								<stop stop-color="#496D7D" />
								<stop
									offset="1"
									stop-color="#15455A" />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<header class="title">
					<h1 class="flex-column action">
						<span>{$_('add')}</span>
						<span>{$_('dog')}</span>
					</h1>
				</header>
			</div>
		</button>
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.container {
	position: relative;
	margin: 0;
	padding: 0;
	.add-dog {
		@include mixins.card;
		aspect-ratio: 1/1;
		min-width: 160px;
		padding: 20px;

		.btn-content {
			justify-content: center;

			.svg-container {
				width: 50px;
				height: 50px;
				background-color: #147ca926;
				border-radius: 50%;
				padding: 9px;
				margin-bottom: auto;
			}

			.title {
				text-align: start;
				color: var(--clr-txt-dark-secondary);
				padding-top: 30px;

				span {
					font-weight: 500;

					&:first-child {
						font-size: 14px;
					}

					&:last-child {
						font-size: 24px;
					}
				}
			}
		}
	}
}
.tooltip {
	visibility: hidden;
	opacity: 0;
	background: #333;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 12px;
	position: absolute;
	z-index: 1;
	bottom: 105%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	min-width: 110px;
}
.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #333 transparent transparent transparent;
}
.container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}
</style>
