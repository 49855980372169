<script lang="ts">
import Toggle from './Toggle.svelte'
import Textarea from './Textarea.svelte'
import { toggleSwitchText } from '../../../store/trainingWidgetsStore'

export let values: {
	initialValue?: string
	maxLines: number
	trigger: number
	labels: any
	selectedIndex: number
	value: {
		selectedIndex: number
		labels: string[]
		answer: string
		text: string
	}
	filled: boolean
}
export let data = []
export let id: string

let textAreaValues: any
let toggleValues: any

if (!$toggleSwitchText[id]) {
	$toggleSwitchText[id] = {
		initialValue: values.initialValue,
		maxLines: values.maxLines,
		trigger: values.trigger,
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: {
			selectedIndex: 0,
			labels: [],
			answer: '',
			text: ''
		},
		filled: false
	}
}

if (data.length > 0 && $toggleSwitchText[id].value.answer == '') {
	toggleValues = {
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
} else if ($toggleSwitchText[id]) {
	toggleValues = {
		labels: values.labels,
		selectedIndex: $toggleSwitchText[id].value.selectedIndex,
		value: $toggleSwitchText[id].value.selectedIndex
	}
} else {
	toggleValues = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
}

if (data.length > 0 && $toggleSwitchText[id].value.answer == '') {
	textAreaValues = {
		initialValue: data[0].value.text,
		maxLines: values.maxLines,
		readOnly: false,
		value: data[0].value.text,
		filled: false
	}
	values.value = {
		selectedIndex: data[0].value.selectedIndex,
		labels: values.labels,
		answer: data[0].value.answer,
		text: data[0].value.text
	}
} else if ($toggleSwitchText[id].value.text) {
	textAreaValues = {
		initialValue: $toggleSwitchText[id].value.text,
		maxLines: $toggleSwitchText[id].maxLines,
		readOnly: false,
		value: $toggleSwitchText[id].value.text,
		filled: $toggleSwitchText[id].filled
	}
	values.value = {
		selectedIndex: $toggleSwitchText[id].value.selectedIndex,
		labels: values.labels,
		answer: $toggleSwitchText[id].value.answer,
		text: $toggleSwitchText[id].value.text
	}
} else {
	textAreaValues = {
		initialValue: values.initialValue,
		maxLines: values.maxLines,
		readOnly: false,
		value: '',
		filled: false
	}
	values.value = {
		selectedIndex: values.selectedIndex,
		labels: values.labels,
		answer: '',
		text: ''
	}
}

$: if (toggleValues.value.selectedIndex == values.trigger) {
	values.value.selectedIndex = toggleValues.value.selectedIndex
	values.value.answer = values.labels[toggleValues.value.selectedIndex]
	values.value.text = textAreaValues.value
	$toggleSwitchText[id] = values
} else {
	values.value.selectedIndex = toggleValues.value.selectedIndex
	values.value.answer = values.labels[toggleValues.value.selectedIndex]
	values.value.text = null
	$toggleSwitchText[id] = values
}
</script>

<div class="toggle-text flex-column">
	<Toggle bind:values={toggleValues} />
	{#if toggleValues.value.selectedIndex === values.trigger}
		<Textarea bind:values={textAreaValues} />
	{/if}
</div>

<style lang="scss">
.toggle-text {
	gap: 20px;
}
</style>
