<script lang="ts">
import CircularProgress from '@smui/circular-progress'
</script>

<div class="cmp-loader">
	<CircularProgress
		style="height: 42px; width: 42px;"
		indeterminate />
</div>

<style lang="scss">
.cmp-loader {
	width: 100%;
	height: 350px;
	display: grid;
	place-items: center;
	filter: none !important;

	@media screen and (max-width: 768px) {
		height: 70vh;
	}
}
</style>
