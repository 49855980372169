<svg
	width="25"
	height="25"
	viewBox="0 0 20 18"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M9.83999 1.78292L2.06172 5.5825C1.8871 5.6702 1.79928 5.75063 1.76143 5.79751C1.76006 5.7992 1.75878 5.80083 1.75757 5.80238C1.75931 5.8047 1.76122 5.80716 1.76331 5.80977C1.80226 5.85841 1.89085 5.94008 2.06466 6.02949L4.21073 7.07756L9.81098 9.83801C9.91658 9.87116 10.0425 9.87108 10.148 9.83777L17.9336 6.02949C18.1074 5.94008 18.196 5.85841 18.2349 5.80977C18.237 5.80716 18.2389 5.80469 18.2407 5.80238C18.2395 5.80082 18.2382 5.7992 18.2368 5.79751C18.199 5.7506 18.111 5.67009 17.9362 5.58232C17.9354 5.58191 17.9345 5.58149 17.9337 5.58108L10.178 1.78284C10.0722 1.74944 9.94578 1.74947 9.83999 1.78292ZM18.2691 4.91025L18.599 4.2367L10.7677 0.401423L10.7351 0.389064C10.2738 0.214062 9.7445 0.214062 9.28313 0.389064L9.2509 0.401289L1.39995 4.23636L1.39373 4.23944C0.794836 4.53889 0.244141 5.06135 0.244141 5.80153C0.244141 6.53909 0.791628 7.06395 1.38829 7.36837L3.54754 8.423L3.55001 8.42421L9.21965 11.2189L9.25313 11.2316C9.7145 11.4066 10.2438 11.4066 10.7051 11.2316L10.7376 11.2192L18.5982 7.37422L18.61 7.36837C19.2066 7.06395 19.7541 6.53909 19.7541 5.80153C19.7541 5.06135 19.2034 4.53889 18.6045 4.23944L18.2691 4.91025ZM18.2582 5.82948C18.2582 5.82949 18.258 5.82905 18.2577 5.82818L18.2582 5.82948ZM18.2573 5.77639C18.2576 5.77539 18.2578 5.77488 18.2579 5.77487L18.2573 5.77639ZM1.74039 5.77487C1.74042 5.77488 1.74064 5.77538 1.74097 5.77638L1.74039 5.77487ZM1.74058 5.82818C1.74028 5.82904 1.74008 5.82948 1.74005 5.82948L1.74058 5.82818Z"
		fill="currentColor" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M3.85938 7C4.27359 7 4.60938 7.33579 4.60938 7.75V12.837C4.62528 13.0686 4.69763 13.2943 4.81961 13.4834L4.82431 13.4907L4.82884 13.4981C4.94387 13.6858 5.12052 13.8555 5.33151 13.9774L9.85224 16.213C9.8932 16.2313 9.93281 16.24 9.98938 16.24C10.046 16.24 10.0856 16.2313 10.1266 16.213L14.6472 13.9775C14.8466 13.8619 15.0211 13.695 15.1545 13.4907C15.2805 13.2826 15.3542 13.061 15.3694 12.8374V7.75C15.3694 7.33579 15.7052 7 16.1194 7C16.5336 7 16.8694 7.33579 16.8694 7.75V12.8802L16.8683 12.9005C16.8408 13.4082 16.6766 13.8777 16.4288 14.2819L16.4243 14.2893L16.4196 14.2966C16.157 14.7037 15.7988 15.0508 15.3671 15.2937L15.3497 15.3035L10.7743 17.5661L10.7666 17.5697C10.5096 17.6896 10.2502 17.74 9.98938 17.74C9.72857 17.74 9.46919 17.6896 9.21222 17.5697L9.20454 17.5661L4.62908 15.3035L4.6117 15.2937C4.19202 15.0576 3.81645 14.7137 3.55449 14.2893C3.28274 13.8652 3.13715 13.3807 3.11053 12.9016L3.10938 12.8808V7.75C3.10938 7.33579 3.44516 7 3.85938 7Z"
		fill="currentColor" />
</svg>
