<svg
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_2_70"
		style="mask-type:luminance"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="48"
		height="48">
		<path
			d="M48 0H0V48H48V0Z"
			fill="white" />
	</mask>
	<g mask="url(#mask0_2_70)">
		<path
			d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z"
			fill="#FFF5E0" />
		<path
			d="M25.7687 8.73125C24.7937 7.75625 23.2125 7.75625 22.2312 8.73125L8.73125 22.2312C7.75625 23.2063 7.75625 24.7875 8.73125 25.7687L22.2312 39.2687C23.2063 40.2437 24.7875 40.2437 25.7687 39.2687L39.2687 25.7687C40.2437 24.7937 40.2437 23.2125 39.2687 22.2312L25.7687 8.73125Z"
			fill="#FE9803" />
	</g>
</svg>
