<script lang="ts">
import CircularProgress from '@smui/circular-progress'
export let isLoading: boolean
export let loaderMsg: string
</script>

{#if isLoading}
	<div class="cmp-loader">
		<CircularProgress
			style="height: 42px; width: 42px;"
			indeterminate />
		<p>{loaderMsg}</p>
	</div>
{/if}

<style lang="scss">
.cmp-loader {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 16px;
}
</style>
