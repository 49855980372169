<script lang="ts">
import Icon from 'svelte-icons-pack'
import IoSearch from 'svelte-icons-pack/io/IoSearch'
import { _ } from 'svelte-i18n'
</script>

<header class="flex page-header">
	<div class="flex-row">
		<Icon
			src={IoSearch}
			size="32"
			color="white" />
		<h1>{$_('how_can_we_help_you')}</h1>
	</div>
</header>

<style lang="scss">
@use '../../styles/setup/mixins';

.page-header {
	@include mixins.card;
	background: linear-gradient(283.95deg, #1f74a8 -12.57%, var(--clr-primary) 126.88%);
	position: relative;
	height: 176px;

	div {
		margin: 42.5px auto 0 22px;
		gap: 16px;

		h1 {
			margin-top: -5px;
			font-size: 32px;
			color: white;

			@media only screen and (max-width: 768px) {
				font-size: 22px;
				margin-top: 5px;
			}
		}
	}

	.search-button {
		background: var(--clr-primary-bg);
		margin: 36px 45px 0 auto;
		color: #264a59;
		height: 40px;
	}
}
</style>
