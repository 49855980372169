<svg
	width="49"
	height="48"
	viewBox="0 0 49 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_354_11383)">
		<rect
			x="0.575195"
			width="48"
			height="48"
			rx="24"
			fill="#FFF5E0" />
		<g clip-path="url(#clip1_354_11383)">
			<path
				d="M25 38C32.73 38 39 31.73 39 24C39 16.27 32.73 10 25 10C17.27 10 11 16.27 11 24C11 31.73 17.27 38 25 38ZM24 16C24 15.45 24.45 15 25 15C25.55 15 26 15.45 26 16V23.52L30.625 27.22C31.0551 27.565 31.125 28.195 30.78 28.625C30.585 28.87 30.295 29 30 29C29.78 29 29.56 28.93 29.375 28.78L24.375 24.78C24.14 24.59 24.0001 24.305 24.0001 24V16H24Z"
				fill="#FE9803" />
		</g>
	</g>
	<defs>
		<clipPath id="clip0_354_11383">
			<rect
				width="48"
				height="48"
				fill="white"
				transform="translate(0.575195)" />
		</clipPath>
		<clipPath id="clip1_354_11383">
			<rect
				width="28"
				height="28"
				fill="white"
				transform="translate(11 10)" />
		</clipPath>
	</defs>
</svg>
