<script lang="ts">
import Chip, { Set, Text } from '@smui/chips'
import { onMount } from 'svelte'
import { useNavigate, useParams } from 'svelte-navigator'
import type { TeamModel, TeamType } from '../../../../models/team.model'
import { teamService } from '../../../../services/core/team.service'
import { currAccount, loggedInUser } from '../../../../store/stores'
import IconSelect from '../IconSelect.svelte'
import { teamDB } from '../../../../services/database/team.db'
import CmpLoader from '../../../common/loader/CmpLoader.svelte'
import MapBoxLocationInput from '../../../common/MapBoxLocationInput.svelte'
import { fireMsg } from '../../../../utils/userMsg.service'
import { _ } from 'svelte-i18n'
import { userMsg } from '../../../../utils/userMsg.service'
import { utilService } from '../../../../utils/util.service'
import { Col, Row } from 'sveltestrap'

const params = useParams()
const navigate = useNavigate()

type InputIds = ['name', 'address', 'type']
type InputId = InputIds[number]

const fieldsValidation = {
	name: { invalid: false, errorMessage: '' },
	type: { invalid: false, errorMessage: '' },
	address: { invalid: false, errorMessage: '' }
}

const teamTypes: TeamType[] = [
	'Training',
	'Dog Sport',
	'SAR',
	'Conservation',
	'Detection',
	'Military',
	'Police'
]
let team: TeamModel
let newTeamId: string

onMount(async () => {
	await loadTeam($params.id)
})

async function loadTeam(teamId?: string) {
	if (teamId) {
		team = await teamService.getById(teamId)
	} else {
		team = teamService.getEmptyTeam({ accountId: $currAccount.id, createdBy: $loggedInUser.id })
		newTeamId = teamDB.newTeamRef().id
	}
}

async function validateInput(inputId: InputId) {
	const nameRegEx = /^[a-zA-Z\u0590-\u05FF\u200f\u200e0-9 ]+$/
	switch (inputId) {
		case 'name':
			if (team.name.length < 2) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_two_characters_message')
				return
			}
			if (!nameRegEx.test(team.name)) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_special_characters_message')
				return
			}
			break

		case 'type':
			if (team.type.length === 0) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_one_type_message')
				return
			}
			break

		case 'address':
			if (team.location.geoPoints == null) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('invalid_address')
				return
			}
			break

		default:
			break
	}

	fieldsValidation[inputId].invalid = false
	fieldsValidation[inputId].errorMessage = ''
}

function cleanInput(inputId: InputId) {
	isFormDisabled = false
	fieldsValidation[inputId].invalid = false
	fieldsValidation[inputId].errorMessage = ''
}

async function validateAllFields() {
	let isValid = true
	for (const fieldId in fieldsValidation) {
		if (Object.prototype.hasOwnProperty.call(fieldsValidation, fieldId)) {
			await validateInput(fieldId as InputId)
			if (fieldsValidation[fieldId].invalid) {
				fireMsg({
					type: 'failure',
					msg: `${$_('error_in_field')} ${$_(fieldId)}, ${$_(
						fieldsValidation[fieldId].errorMessage
					)}`
				})
				isValid = false
			}
		}
	}
	return isValid
}

async function handleSubmit(ev: Event) {
	isFormDisabled = true
	ev.preventDefault()
	ev.stopPropagation()

	const isFormValid = await validateAllFields()
	if (!isFormValid) return (isFormDisabled = false)

	if (newTeamId) team.id = newTeamId

	try {
		const url = newTeamId ? `${import.meta.env.VITE_APP_API_URL}/team` : `${import.meta.env.VITE_APP_API_URL}/team/${team.id}`
		const body = {
			name: team.name,
			icon: team.icon,
			accountId: team.accountId,
			type: team.type,
			status: team.status,
			userIds: team.userIds,
			dogIds: [],
			stats: {
				TotalTrainingSessions: team.stats.totalTrainingSessions,
				TotalTrainingTime: team.stats.totalTrainingTime,
				TotalTrainingDays: team.stats.totalTrainingDays
			},
			location: {
				Address: team.location.address,
				GeoPoints: {
					Latitude: team.location.geoPoints.latitude,
					Longitude: team.location.geoPoints.longitude
				}
			}
		}
		await utilService.restRequest(url, newTeamId ? 'POST' : 'PATCH', body)
		navigate('/teams')
		isFormDisabled = false
		userMsg.successMsg(newTeamId ? `${$_('team_created')}` : `${$_('team_edited')}`)
	} catch (err: unknown) {
		userMsg.errorMsg('Error', err)
		isFormDisabled = false
	}
}

let isFormDisabled = false
</script>

{#if !team}
	<CmpLoader />
{:else}
	<div class="team-edit container-fluid">
		<Row>
			<form
				on:submit={handleSubmit}
				class="team-edit-form col-md-8 col-sm-12">
				<IconSelect
					selectedIcon={team.icon}
					on:select={(ev) => (team.icon = ev.detail)} />
				<Row class="field py-1">
					<Col
						md="3"
						class="py-0">
						<label for="name">{$_('team_name')}</label>
					</Col>
					<Col
						md="9"
						class="py-0">
						<input
							class="edit-input form-control my-1"
							class:invalid={fieldsValidation['name'].errorMessage}
							type="text"
							required
							pattern={'^[a-zA-Z0-9 ]{2,}$'}
							id="name"
							on:focus={() => cleanInput('name')}
							on:blur={() => validateInput('name')}
							bind:value={team.name} />
						<label
							for="name"
							role="alert">{fieldsValidation['name'].errorMessage}</label>
					</Col>
				</Row>

				<Row class="field mb-4">
					<Col md="3">
						<label for="address">{$_('location')}</label>
					</Col>
					<Col md="9">
						<MapBoxLocationInput
							bind:location={team.location}
							teamLocation={team.location ? team.location : undefined} />
					</Col>
				</Row>

				<Row class="field mb-4">
					<Col md="3">
						<label for="status">{$_('team_status')}</label>
					</Col>
					<Col md="9">
						<select
							class="edit-input form-control"
							id="status"
							bind:value={team.status}>
							<option value="active">{$_('active')}</option>
							<option value="inactive">{$_('inactive')}</option>
							<option value="in training">{$_('in_training')}</option>
						</select>
					</Col>
				</Row>

				<Row class="field type mb-4">
					<Col md="3">
						<label for="type">{$_('team_type')}</label>
					</Col>
					<Col class="col px-0">
						<Set
							id="type"
							chips={teamTypes}
							let:chip
							filter
							bind:selected={team.type}>
							<Chip
								{chip}
								touch>
								<Text>{$_(`team_types.${chip}`)}</Text>
							</Chip>
						</Set>
						<label
							for="type"
							role="alert">{fieldsValidation['type'].errorMessage}</label>
					</Col>
				</Row>

				<Row class="flex btns-container">
					<Col
						sm="12"
						class="d-flex justify-content-end">
						<button
							type="button"
							class="btn theme-btn outline discard mr-2"
							on:click={() => navigate(-1)}>{$_('cancel')}</button>
						<button
							disabled={isFormDisabled}
							type="submit"
							class="btn theme-btn submit"
							on:click={handleSubmit}>{$_('submit')}</button>
					</Col>
				</Row>
			</form>
		</Row>
	</div>
{/if}

<style lang="scss">
@use '../../../../styles/setup/mixins';
.team-edit {
	margin-inline: auto;
	max-width: 800px;

	.team-edit-form {
		@include mixins.card;
		padding-block: 10px 30px;
		padding-inline: 30px;
		width: 100%;
		gap: 48px;

		.field {
			align-items: flex-start;
			gap: 64px;

			label {
				font-family: Nunito-Bold;
				width: 150px;
				margin-top: 10px;
			}

			.input-container {
				width: 100%;
			}

			.select-container {
				width: 100%;
				.edit-input {
					width: 100%;
				}

				label {
					font-family: Nunito-Bold;
					text-transform: capitalize;
				}
			}

			&::has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			input.invalid {
				border-color: #b00020;
			}

			label[role='alert'] {
				color: #b00020;
				font-size: 13px;
				width: 100%;
			}
		}
		.type {
			gap: 6px;
		}
		.btns-container {
			justify-content: flex-end;
			margin-right: -30px;
			gap: 12px;
		}
	}

	:global(.mdc-chip) {
		border-radius: 12px;
		background-color: var(--clr-primary);
		color: white;
		border-radius: 6px;

		:global(.mdc-chip__ripple) {
			border-radius: 6px;
		}

		:global(.mdc-chip__checkmark-svg path) {
			stroke: white;
		}
	}
}
</style>
