<script lang="ts">
import { navBarOpen } from '../../store/stores'
import NavLink from './NavLink.svelte'
import NavText from './NavText.svelte'
import { navLinks } from './navlink.const'
import SvgDogBaseFull from '../common/SvgDogBaseFull.svelte'
import Icon from 'svelte-icons-pack'
import CgClose from 'svelte-icons-pack/cg/CgClose'
import { _ } from 'svelte-i18n'
import { utilService } from '../../utils/util.service'
</script>

<div
	class="nav-bar"
	class:active={$navBarOpen}>
	<button
		class="close-btn"
		on:click={() => ($navBarOpen = false)}>
		<Icon src={CgClose} />
	</button>
	<aside class="nav-links">
		<div class="brand-logo">
			<SvgDogBaseFull
				width="180"
				height="50"
				hideTagline={true}
				hideBrand={!utilService.isClientInMobile() && !$navBarOpen} />
		</div>
		{#each navLinks as linksGroup}
			<div class="btns-group">
				{#each linksGroup as link}
					<NavLink
						path={link.path}
						title={!$navBarOpen ? link.name : ''}
						action={link.action}>
						<svelte:component this={link.icon} />
						{#if utilService.isClientInMobile() || $navBarOpen}
							<NavText>{$_(link.name)}</NavText>
						{/if}
					</NavLink>
				{/each}
			</div>
		{/each}
	</aside>
</div>

<button
	class="backdrop"
	on:click={() => {
		$navBarOpen = false
	}}
	class:active={$navBarOpen} />

<div
	class="nav-bar-placeholder"
	class:active={$navBarOpen} />

<style lang="scss">
.nav-bar-placeholder {
	height: 100vh;
	width: 256px;
	transition: width 0.2s ease-out;
	&:not(.active) {
		width: 66px;
	}

	@media (max-width: 768px) {
		width: 70%;
		height: 100%;
		opacity: 1;
		transform: translateX(0);
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;

		&:not(.active) {
			width: 0px;
			height: 0px;
			opacity: 0;
			transform: translateX(-100%);
		}
	}
}

.backdrop {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 99;
	display: none;
}

.backdrop.active {
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
	}
}

.nav-bar {
	background-color: white;
	height: 100vh;
	width: 256px;
	position: fixed;
	overflow-x: hidden;
	transition: width 0.2s ease-out, box-shadow 0.4s ease-out;
	box-shadow: 1px 0px 12px 1px #00000062;
	z-index: 100;

	&:not(.active) {
		width: 66px;

		.brand-logo {
			width: 35px;
			height: 60px;
			margin-inline: auto;
			margin-bottom: 0;
		}

		.nav-links {
			padding: 12px 6px 8px 6px;
			width: 66px;

			.btns-group {
				margin-inline: auto;
			}

			:global(.nav-link) {
				gap: 0;
				padding-inline: 10px;
			}
		}
	}

	.brand-logo {
		margin-inline-start: 10px;
		margin-bottom: 16px;
	}

	.nav-links {
		position: absolute;
		left: 0;
		width: 256px;
		height: 100%;
		padding: 20px 16px 24px 16px;
		display: flex;
		flex-direction: column;
	}

	.btns-group {
		display: flex;
		flex-direction: column;
		gap: 8px;
		position: relative;

		&:nth-last-child(2) {
			flex: 1;
		}
	}

	.close-btn {
		display: none; // Hidden by default
		background: none;
		border: none;
		font-size: 28px;
		height: 50px;
		cursor: pointer;
		position: absolute;
		right: 16px;
		top: 20px;
		z-index: 101;
		color: #8f95b2;
		opacity: 0.5;

		&:hover,
		&:focus {
			opacity: 1;
		}
	}

	@media (max-width: 768px) {
		width: 70%;
		height: 100%; // Makes it full height
		opacity: 1;
		transition: width 0.3s ease-in-out;

		.nav-links {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		&:not(.active) {
			width: 0px;
			.nav-links {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				height: 100%;
				padding: 20px 16px 24px 16px;
				width: 100%;
			}
			.brand-logo {
				margin-inline: 0;
				margin-inline-start: 20px !important;
				margin-bottom: 16px;
				height: auto;
				width: 100%;
			}
			.btns-group {
				width: 100%;
			}
			.close-btn {
				display: none;
			}
			:global(.nav-link) {
				padding-inline: 16px !important;
			}
		}

		.btns-group {
			width: 100%;
		}

		.close-btn {
			display: block; // Only show on mobile
		}

		.brand-logo {
			margin-inline-start: 20px;
			width: 100%;
		}
	}
}
</style>
