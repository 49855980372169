import {
  collection,
  doc,
  DocumentReference,
  orderBy,
  limit,
  getDocs,
  query,
  where,
  type DocumentData
} from 'firebase/firestore'
import { Firestore } from '../firebase/firebase'
import type { QuerySnapshot, Query } from 'firebase/firestore'

export const activityDB = {
  getManyActivities,
  getManyTeamActivities
}

async function getManyActivities(accountId: string, itemsPerPage: number): Promise<QuerySnapshot<DocumentData>> {
  const queryParams = _getActivityDocsQuery(accountId, itemsPerPage)
  const querySnapshot = await getDocs(queryParams)
  return querySnapshot
}

async function getManyTeamActivities(accountId: string, teamId: string, itemsPerPage: number): Promise<QuerySnapshot<DocumentData>> {
  const queryParams = _getTeamActivityDocsQuery(accountId, teamId, itemsPerPage)
  const querySnapshot = await getDocs(queryParams)
  return querySnapshot
}


function _getActivityDocsQuery(accountId: string, itemsPerPage: number): Query<DocumentData> {
  return query(
    collection(Firestore, 'accountActivity'),
    where('accountId', '==', accountId),
    orderBy('createdAt', 'desc'),
    limit(itemsPerPage || 1)
  )
}

function _getTeamActivityDocsQuery(accountId: string, teamId: string, itemsPerPage: number): Query<DocumentData> {
  return query(
    collection(Firestore, 'accountActivity'),
    where('accountId', '==', accountId),
    where('type', 'in', ['training_added', 'dog_added', 'dog_updated', 'team_updated']),
    where('data.teamIds', 'array-contains', teamId),
    orderBy('createdAt', 'desc'),
    limit(itemsPerPage || 1)
  )
}

function _getDocRef(documentId: string): DocumentReference<DocumentData> {
  return doc(Firestore, 'accountActivity', documentId)
}

function _getNewDocRef(): DocumentReference<DocumentData> {
  return doc(collection(Firestore, 'accountActivity'))
}
