<script lang="ts">
import type { Unsubscribe } from 'firebase/auth'
import { onSnapshot } from 'firebase/firestore'
import { onDestroy, onMount } from 'svelte'
import { useLocation, type NavigateFn, type NavigatorLocation } from 'svelte-navigator'
import type AnyObject from 'svelte-navigator/types/AnyObject'
import type RouteParams from 'svelte-navigator/types/RouteParam'
import VerticalLayout from '../../../layouts/VerticalLayout.svelte'
import VerifyEmail from '../../../pages/VerifyEmail.svelte'
import { accountService } from '../../../services/core/account.service'
import { accountDB } from '../../../services/database/account.db'
import type { AccountModel } from '../../../models/account.model'
import { currAccount, loggedInUser, user } from '../../../store/stores'
import PageLoader from '../loader/PageLoader.svelte'
import * as Sentry from '@sentry/browser'
import { utilService } from '../../../utils/util.service'

export let navigate: NavigateFn<AnyObject>
export let params: RouteParams
export let location: NavigatorLocation<AnyObject>

const currLocation = useLocation()
let unsubscribe: Unsubscribe

onMount(() => {
	try {
		if (!$loggedInUser) return
		unsubscribe = onSnapshot(
			accountDB.getAccountRef($loggedInUser.selectedAccount.id || $loggedInUser.accountIds[0]),
			(accountDoc) => {
				if (accountDoc.exists()) currAccount.set(accountDoc.data() as AccountModel)
			}
		)
	} catch (err: unknown) {
		Sentry.captureException(err)
	}
})

onDestroy(() => {
	if (unsubscribe) unsubscribe()
})

async function getSessionKeys() {
	const sessionKeys = await utilService.getSessionKeys()
	sessionStorage.setItem('sessionKeys', JSON.stringify(sessionKeys))
}

$: {
	try {
		if ($user === undefined) {
			window.location.replace((document.location.href = '/login'))
		}
		if ($loggedInUser && !$currAccount) {
			accountService.getById($loggedInUser.selectedAccount.id || $loggedInUser.accountIds[0])
			getSessionKeys()
		}
	} catch (err: unknown) {
		Sentry.captureException(err)
	}
}
</script>

{#if $user}
	{#if !$user.emailVerified}
		<VerifyEmail />
	{:else if $currAccount}
		<VerticalLayout>
			<slot
				{params}
				{location}
				{navigate} />
		</VerticalLayout>
	{:else}
		<PageLoader />
	{/if}
{/if}
