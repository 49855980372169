<script lang="ts">
import type { Unsubscribe } from 'firebase/firestore'
import { onDestroy, onMount } from 'svelte'
import { currAccount, loggedInUser } from '../../../store/stores'
import { useNavigate, useParams } from 'svelte-navigator'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import UserStats from './UserStats.svelte'
import { accountService } from '../../../services/core/account.service'
import type { AccountUserModel } from '../../../models/user.model'
import UserInfo from './UserInfo.svelte'
import { _ } from 'svelte-i18n'
import { Col, Container, Row } from 'sveltestrap'
import { utilService } from '../../../utils/util.service'
import Tooltip from '../../common/Tooltip.svelte'

const params = useParams()
const navigate = useNavigate()
const userId = $params.id

let unsubscribe: Unsubscribe

let userInAccountPromise: Promise<AccountUserModel[]>
let userInAccountOptions: AccountUserModel[]
let user: AccountUserModel

let accountUsersPromise: Promise<AccountUserModel[]>
let accountUsers: AccountUserModel[]

async function getAccountUsers() {
	accountUsersPromise = accountService.queryUsers($currAccount.userIds, $currAccount.id)
	accountUsers = await accountUsersPromise
}

async function getUserInAccount(userId: string) {
	userInAccountPromise = accountService.queryUsers([userId], $currAccount.id)
	userInAccountOptions = await userInAccountPromise
	user = userInAccountOptions[0]
}

let sessionKeys: any
let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	getAccountUsers()
	getUserInAccount(userId)
})

onDestroy(() => {
	if (unsubscribe) unsubscribe()
})
</script>

{#if !user}
	<CmpLoader />
{:else}
	{#await userInAccountPromise}
		<CmpLoader />
	{:then}
		<Container fluid class="user-details flex-column gap-4 p-0">
			<Row>
				<Col>
					<UserInfo {user} />
				</Col>
			</Row>
			<Row>
				<Col>
					<UserStats {user} />
				</Col>
			</Row>
			<Row>
				<Col class="flex align-center edit-user-container justify-content-end">
					{#if accountUsers.find((user) => user.id === $loggedInUser.id).role == 'admin'}
						{#if disabled}
							<button
								{disabled}
								on:click={() =>
									navigate(`/users/edit/${user.id}`, { state: { pageName: $_('user_edit') } })}
								class="btn theme-btn edit-user flex">
								<Tooltip message={$_('locked_feature')} />
								{$_('edit_user')}
							</button>
						{:else}
							<button
								{disabled}
								on:click={() =>
									navigate(`/users/edit/${user.id}`, { state: { pageName: $_('user_edit') } })}
								class="btn theme-btn edit-user">
								{$_('edit_user')}
							</button>
						{/if}
					{/if}
				</Col>
			</Row>
		</Container>
	{/await}
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.user-details {
	gap: 28px;

	.content {
		display: grid;
		grid-template-columns: repeat(auto-fit, 200px);
		gap: 40px;
		margin-inline-start: 40px;
		margin-block-end: 40px;

		.detail {
			text-transform: capitalize;
			color: var(--clr-txt-dark-secondary);

			.title {
				font-weight: 600;
			}
			.value {
				font-size: 15px;
			}
		}
	}
	.fci-links {
		gap: 30px;
		margin-inline-start: 40px;
		margin-block-end: 30px;
	}

	.edit-user-container {
		justify-content: flex-end;
	}
}

.btn:disabled {
	pointer-events: auto;
	gap: 6px;
}
</style>
