<script lang="ts">
import { _ } from 'svelte-i18n'
import { utilService } from '../../../utils/util.service'
import { onMount, afterUpdate } from 'svelte'
import { distanceSlider } from '../../../store/trainingWidgetsStore'

export let values: {
	minMeters: number
	maxMeters: number
	minFeet: number
	maxFeet: number
	divisions: number
	defaultSliderValue?: number
	label?: 'meters' | 'feet' | 'kilometers' | 'miles'
	value: {
		distance: number
		label: 'meters' | 'feet'
	}
}
export let id: string = null
export let data = []

if (!$distanceSlider[id]) {
	$distanceSlider[id] = null
}

let unitsOfMeasurement: string
let label: 'meters' | 'feet' | 'miles' | 'kilometers'

let inputElement: HTMLInputElement
let editingLabel = false
let editValue = ''

let filledWidth = 0

let shouldFocusInput = false

function handleLabelClick() {
	editingLabel = true
	editValue = values.value.distance != 0 ? values.value.distance.toString() : ''
	shouldFocusInput = true
}

function handleNumberInput(event) {
	if (event.target.value.length > 8) {
		event.target.value = event.target.value.slice(0, 8)
	}
}

function handleLabelBlur() {
	editingLabel = false

	let rawValue = parseFloat(editValue)

	let minValue = unitsOfMeasurement === 'metric' ? values.minMeters : values.minFeet
	if (isNaN(rawValue) || rawValue < minValue) {
		rawValue = minValue
	}

	let maxValue = unitsOfMeasurement === 'metric' ? values.maxMeters : values.maxFeet
	if (rawValue > maxValue) {
		rawValue = maxValue
	}

	values.value.distance = rawValue
	editValue = values.value.distance.toString()
	$distanceSlider[id] = values.value.distance
	values.defaultSliderValue = values.value.distance
}

localStorage.getItem('measurement-type')
	? (unitsOfMeasurement = localStorage.getItem('measurement-type'))
	: (unitsOfMeasurement = 'metric')

let displayedValue = 0
values.value = {
	distance: 0,
	label: unitsOfMeasurement === 'metric' ? 'meters' : 'feet'
}

onMount(() => {
	displayedValue =
		values.defaultSliderValue != undefined
			? values.defaultSliderValue
			: unitsOfMeasurement == 'metric'
			? values.minMeters
			: values.minFeet
	values.value.distance =
		values.defaultSliderValue != undefined
			? values.defaultSliderValue
			: unitsOfMeasurement == 'metric'
			? values.minMeters
			: values.minFeet
	if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
		document.body.classList.add('firefox')
	}
})

afterUpdate(() => {
	if (shouldFocusInput) {
		inputElement.focus()
		shouldFocusInput = false
	}
})

function handleChange(event) {
	values.value.distance = +event.target.value
	$distanceSlider[id] = values.value.distance
	values.defaultSliderValue = parseInt(event.target.value)
}

if (data.length > 0 && $distanceSlider[id] == null) {
	values.defaultSliderValue = data[0].value.distance
}

$: {
	switch (unitsOfMeasurement) {
		case 'metric':
			if (values.value.distance > 999) {
				label = 'kilometers'
				displayedValue = +(values.value.distance / 1000).toFixed(1)
			} else {
				displayedValue = values.value.distance
				label = 'meters'
			}
			break
		case 'imperial':
			if (values.value.distance > 5279) {
				label = 'miles'
				displayedValue = +(values.value.distance / 5280).toFixed(2)
			} else {
				displayedValue = values.value.distance
				label = 'feet'
			}
			break
		default:
			break
	}
}

let sliderWidth: number

$: {
	if (unitsOfMeasurement === 'metric') {
		filledWidth =
			(sliderWidth / (values.maxMeters - values.minMeters)) *
			(values.value.distance - values.minMeters)
	} else {
		filledWidth =
			(sliderWidth / (values.maxFeet - values.minFeet)) * (values.value.distance - values.minFeet)
	}
}
</script>

<div class="slider-container flex-column">
	<div class="slider-range flex">
		{#if unitsOfMeasurement == 'metric'}
			<div
				class="slider-wrapper"
				bind:clientWidth={sliderWidth}>
				<input
					type="range"
					class="slider"
					min={values.minMeters}
					max={values.maxMeters}
					bind:value={values.value.distance}
					on:input={handleChange} />
			</div>
			<div
				class="filled"
				style="width:{filledWidth}px" />
		{:else if unitsOfMeasurement == 'imperial'}
			<div
				class="slider-wrapper"
				bind:clientWidth={sliderWidth}>
				<input
					type="range"
					class="slider"
					min={values.minFeet}
					max={values.maxFeet}
					bind:value={values.value.distance}
					on:input={handleChange} />
			</div>
			<div
				class="filled"
				style="width:{filledWidth}px" />
		{/if}
		<div class="current-value">
			{#if editingLabel}
				<div class="input-container">
					<input
						class="label-input"
						bind:this={inputElement}
						type="number"
						bind:value={editValue}
						on:input={handleNumberInput}
						on:blur={handleLabelBlur} />
					<span class="tooltip">Type the value in {values.value.label}</span>
				</div>
			{:else}
				<div
					on:click={handleLabelClick}
					on:keydown={handleLabelClick}>
					{displayedValue}
					{$_(utilService.getShortLabel(label))}
				</div>
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
.slider-range {
	align-items: center;
	gap: 10px;

	.slider-wrapper {
		width: 510px;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.filled {
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		border-radius: 5px;
		width: 300px;
		position: absolute;
		z-index: 0;
		height: 2.5px;
	}
}

.label-input {
	height: 21px;
	width: 80px;
	border: none;
}

.label-input:focus {
	outline: none;
	border-color: transparent;
	box-shadow: none;
}

.input-container {
	position: relative;
	display: inline-block;
}

.input-container:hover .tooltip {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s;
}

.tooltip {
	visibility: hidden;
	opacity: 0;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 140%;
	left: 50%;
	font-size: 14px;
	margin-left: -60px;
	width: 180px;
}

.tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 35%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #264a59 transparent transparent transparent;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']::-ms-clear {
	display: none;
}

.current-value,
.label-input {
	min-width: 5rem;
	text-align: center;
	font-size: 14px;
	font-family: Montserrat-Medium;
	border: 1px solid rgba(0, 0, 0, 0.12);
	padding: 5px 0;
	border-radius: 6px;
}

input[type='range'] {
	height: 23px;
	-webkit-appearance: none;
	margin: 10px 0;
	width: 100%;
}
input[type='range']:focus {
	outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
	width: 100%;
	height: 2.5px;
	cursor: pointer;
	transition: 0.2s;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 5px;
}
input[type='range']::-webkit-slider-thumb {
	height: 17px;
	width: 17px;
	border-radius: 50px;
	z-index: 1;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	-webkit-appearance: none;
	cursor: pointer;
	position: relative;
	-webkit-appearance: none;
	margin-top: -7.5px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
	background: rgba(143, 149, 178, 0.4);
}
input[type='range']:active::-webkit-slider-thumb {
	outline: 10px solid rgba(45, 115, 146, 0.3);
	outline-offset: -1px;
	cursor: grabbing;
}
input[type='range']::-moz-range-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	transition: 0.2s;
	background: rgba(143, 149, 178, 0.4);
	border-radius: 5px;
}
input[type='range']::-moz-range-thumb {
	height: 17px;
	width: 17px;
	border-radius: 50px;
	z-index: 9;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	-webkit-appearance: none;
	cursor: pointer;
}
input[type='range']:active::-moz-range-thumb {
	outline: 10px solid rgba(45, 115, 146, 0.3);
	outline-offset: -1px;
	cursor: grabbing;
}
input[type='range']::-moz-range-progress {
	border-radius: 6px;
	height: 3px;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
}
input[type='range']::-ms-track {
	width: 100%;
	height: 6px;
	cursor: pointer;
	transition: 0.2s;
	background: transparent;
	border-color: transparent;
	color: transparent;
}
input[type='range']::-ms-fill-lower {
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;
}
input[type='range']::-ms-fill-upper {
	background: rgba(143, 149, 178, 0.4);
	border-radius: 10px;
}
input[type='range']::-ms-thumb {
	margin-top: 1px;
	height: 17px;
	width: 17px;
	border-radius: 50px;
	z-index: 9;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	-webkit-appearance: none;
	cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
	background: rgba(143, 149, 178, 0.4);
}
input[type='range']:focus::-ms-fill-upper {
	background: rgba(143, 149, 178, 0.4);
}
body.firefox .slider-range .filled {
	display: none;
}
</style>
