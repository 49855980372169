<script lang="ts">
import Toggle from './Toggle.svelte'
import Textarea from './Textarea.svelte'
import Slider from './Slider.svelte'
import { bitLossECorrection } from '../../../store/trainingWidgetsStore'

export let values: {
	correctionIndex: number
	wonIndex: number
	value: {
		selectedCorrection: number
		selectedWon: number
		textFieldValue: string
		sliderValue: number
	}
}
export let data = []
export let id: string

let wonValues: any
let correctionValues: any
let sliderValues: any
let textValue: any

if (!$bitLossECorrection[id]) {
	$bitLossECorrection[id] = {
		correctionIndex: values.correctionIndex,
		wonIndex: values.wonIndex,
		value: {
			selectedCorrection: values.correctionIndex,
			selectedWon: values.wonIndex,
			textFieldValue: '',
			sliderValue: 0
		}
	}
}

if ($bitLossECorrection[id].value.sliderValue) {
	sliderValues = {
		min: 0,
		max: 18,
		divisions: 18,
		defaultSliderValue: $bitLossECorrection[id].value.sliderValue,
		value: $bitLossECorrection[id].value.sliderValue
	}
} else {
	sliderValues = {
		min: 0,
		max: 18,
		divisions: 18,
		defaultSliderValue: 0,
		value: 0
	}
}

if ($bitLossECorrection[id].value.selectedWon.selectedIndex >= 0) {
	wonValues = {
		selectedIndex: $bitLossECorrection[id].value.selectedWon.selectedIndex,
		labels: ['yes', 'no'],
		value: $bitLossECorrection[id].value.selectedWon.selectedIndex
	}
} else {
	wonValues = {
		selectedIndex: values.wonIndex,
		labels: ['yes', 'no'],
		value: 0
	}
}

if ($bitLossECorrection[id].value.selectedCorrection) {
	correctionValues = {
		selectedIndex: $bitLossECorrection[id].value.selectedCorrection.selectedIndex,
		labels: ['yes', 'no'],
		value: $bitLossECorrection[id].value.selectedCorrection.selectedIndex
	}
} else {
	correctionValues = {
		selectedIndex: values.correctionIndex,
		labels: ['yes', 'no'],
		value: 0
	}
}

if ($bitLossECorrection[id].value.textFieldValue) {
	textValue = {
		maxLines: 4,
		filled: true,
		initialValue: $bitLossECorrection[id].value.textFieldValue,
		value: $bitLossECorrection[id].value.textFieldValue
	}
} else {
	textValue = {
		maxLines: 4,
		filled: false,
		value: ''
	}
}

$: {
	values.value = {
		selectedCorrection: correctionValues.value,
		selectedWon: wonValues.value,
		textFieldValue: textValue.value,
		sliderValue: sliderValues.value.selectedValue
	}
	$bitLossECorrection[id] = values
	if (correctionValues.selectedIndex === 1) {
		values.value.sliderValue = null
		values.value.textFieldValue = null
		$bitLossECorrection[id] = values
	}
}
</script>

<div class="flex align-center">
	<p>Won sleeve</p>
	<Toggle
		bind:values={wonValues}
		data={data.length > 0
			? [{ value: { selectedIndex: data[0].value.selectedWon.selectedIndex } }]
			: []} />
</div>
<div class="flex align-center">
	<p>Loss correction</p>
	<Toggle
		bind:values={correctionValues}
		data={data.length > 0
			? [{ value: { selectedIndex: data[0].value.selectedCorrection.selectedIndex } }]
			: []} />
</div>

{#if correctionValues.selectedIndex === 0}
	<Slider
		bind:values={sliderValues}
		data={data.length > 0 && data[0].value.sliderValue
			? [{ value: { selectedValue: data[0].value.sliderValue } }]
			: []} />
	<Textarea
		bind:values={textValue}
		data={data.length > 0 && data[0].value.textFieldValue
			? [{ value: data[0].value.textFieldValue }]
			: []} />
{/if}

<style lang="scss">
.flex {
	gap: 10px;
	p {
		font-size: 14px;
		font-weight: 900;
		width: 130px;
		font-family: Nunito-SemiBold;
		text-transform: capitalize;
		color: #33383f;
		gap: 14px;
	}
}
</style>
