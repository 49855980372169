<script lang="ts">
import { Lottie } from 'lottie-svelte'
import { _ } from 'svelte-i18n'
</script>

<section class="no-team-animation flex-column align-center">
	<Lottie path="/assets/animations/no-team-animation.json" />
	<h1>{$_('nothing_here')}</h1>
	<p>{$_('add_first_team')}</p>
</section>

<style lang="scss">
.no-team-animation {
	margin-inline: auto;
	width: 240px;
}
</style>
