<script lang="ts">
import type { TeamStatus } from '../../models/team.model'
import { _ } from 'svelte-i18n'

export let status: TeamStatus
</script>

<div class="team-status">
	<span class={status.toLowerCase()}>
		{$_(status)}
	</span>
</div>

<style lang="scss">
.team-status {
	text-transform: capitalize;
	span {
		border-radius: 6px;
		white-space: nowrap;
	}
}
</style>
