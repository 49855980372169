<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_0_1050)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 4.75C9.13313 4.75 7.66669 6.20257 7.66669 7.9375C7.66669 9.67243 9.13313 11.125 11 11.125C12.8669 11.125 14.3334 9.67243 14.3334 7.9375C14.3334 6.20257 12.8669 4.75 11 4.75ZM6.16669 7.9375C6.16669 5.32319 8.35659 3.25 11 3.25C13.6435 3.25 15.8334 5.32319 15.8334 7.9375C15.8334 10.5518 13.6435 12.625 11 12.625C8.35659 12.625 6.16669 10.5518 6.16669 7.9375ZM13.6662 15.9684C11.8999 15.6772 10.1001 15.6772 8.33382 15.9684L8.12605 16.0027L8.00404 15.2627L8.12604 16.0027C6.19882 16.3204 4.75 18.0477 4.75 20.1115C4.75 20.7634 5.25549 21.25 5.82813 21.25H16.1719C16.7445 21.25 17.25 20.7634 17.25 20.1115C17.25 18.0477 15.8012 16.3204 13.874 16.0027L13.996 15.2627L13.874 16.0027L13.6662 15.9684ZM13.9102 14.4884L13.7882 15.2284L13.9102 14.4884L14.118 14.5226C16.8042 14.9655 18.75 17.349 18.75 20.1115C18.75 21.5455 17.6185 22.75 16.1719 22.75H5.82813C4.38147 22.75 3.25 21.5455 3.25 20.1115C3.25 17.349 5.19583 14.9655 7.88203 14.5226L8.08981 14.4884L8.21182 15.2284L8.08982 14.4884C10.0177 14.1705 11.9823 14.1705 13.9102 14.4884Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_0_1050">
<rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>
