<script lang="ts">
import Toggle from './Toggle.svelte'
import Multiselect from './Multiselect.svelte'
import { toggleMultiSelect } from '../../../store/trainingWidgetsStore'

export let values: {
	labels: string[]
	selectedIndex: number
	items: string[]
	trigger: number
	value: {
		selectedIndex: number
		answer: string
		labels: string[]
		dropdownValue: string[]
	}
}
export let data = []
export let id: string = null

let dropdownValues: any
let toggleValues: any

if (!$toggleMultiSelect[id]) {
	$toggleMultiSelect[id] = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		items: values.items,
		trigger: values.trigger,
		value: {
			selectedIndex: values.selectedIndex,
			answer: '',
			labels: values.labels,
			dropdownValue: ''
		}
	}
}
if (data.length > 0 && $toggleMultiSelect[id].value.answer == '') {
	dropdownValues = {
		items: values.items,
		value: data[0].value.dropdownValue,
		returnedValue: data[0].value.dropdownValue
			? data[0].value.dropdownValue.map((value) => {
					return { value: value, label: value }
				})
			: [],
		filled: false
	}
} else if ($toggleMultiSelect[id].value.dropdownValue) {
	dropdownValues = {
		items: values.items,
		value: $toggleMultiSelect[id].value.dropdownValue,
		returnedValue: $toggleMultiSelect[id].returnedValue,
		filled: false
	}
} else {
	dropdownValues = {
		items: values.items,
		value: '',
		filled: false
	}
}

if (data.length > 0 && $toggleMultiSelect[id].value.answer == '') {
	toggleValues = {
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
} else if ($toggleMultiSelect[id]) {
	toggleValues = {
		labels: values.labels,
		selectedIndex: $toggleMultiSelect[id].value.selectedIndex,
		value: $toggleMultiSelect[id].value.selectedIndex
	}
} else {
	toggleValues = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
}

if (data.length > 0) {
	values.value = {
		selectedIndex: data[0].value.selectedIndex,
		labels: values.labels,
		answer: data[0].value.answer,
		dropdownValue: data[0].value.dropdownValue
	}
} else {
	values.value = {
		selectedIndex: values.selectedIndex,
		labels: values.labels,
		answer: '',
		dropdownValue: $toggleMultiSelect[id].value.dropdownValue
	}
}

if (data.length > 0) {
	values.value = {
		selectedIndex: data[0].value.selectedIndex,
		labels: values.labels,
		answer: data[0].value.answer,
		dropdownValue: data[0].value.dropdownValue
	}
} else {
	values.value = {
		selectedIndex: values.selectedIndex,
		labels: values.labels,
		answer: '',
		dropdownValue: $toggleMultiSelect[id].value.dropdownValue
	}
}

$: if (toggleValues.value.selectedIndex === values.trigger) {
	values.value.selectedIndex = toggleValues.value.selectedIndex
	values.value.answer = values.labels[toggleValues.value.selectedIndex]
	values.value.dropdownValue = dropdownValues.value
	$toggleMultiSelect[id] = values
	$toggleMultiSelect[id].returnedValue = dropdownValues.returnedValue
} else {
	values.value.selectedIndex = toggleValues.value.selectedIndex
	values.value.answer = values.labels[toggleValues.value.selectedIndex]
	values.value.dropdownValue = null
	$toggleMultiSelect[id] = values
}
</script>

<Toggle bind:values={toggleValues} />

{#if toggleValues.value.selectedIndex === values.trigger}
	<Multiselect bind:values={dropdownValues} />
{/if}
