import * as Sentry from '@sentry/browser'
import type { PaddleEvent } from '../../types/paddle'
import { eventData } from '../../store/stores'
import { userMsg } from '../../utils/userMsg.service'

const token = import.meta.env?.VITE_APP_PADDLE_TOKEN

export const paddleService = {
	setupPaddle,
	updatePaddle
}

/**
 * Initialize Paddle.
 *
 * @return {Promise<void>} A promise that resolves when the setup is complete.
 */
async function setupPaddle(): Promise<void> {
	try {
		if (window.Paddle?.Setup) {
			window.Paddle.Initialize({
				token: token,
				pwCustomer: {},
				eventCallback: function (event: PaddleEvent) {
					if (
						event.name == 'checkout.loaded' ||
						event.name == 'checkout.items.updated' ||
						event.name == 'checkout.discount.applied' ||
						event.name == 'checkout.discount.removed'
					) {
						eventData.set(event.data)
					}
				}
			})
		} else {
			paddleInitializeError();
		}
	} catch (error) {
		paddleInitializeError();
	}
}

/**
 * Paddle Update with the provided email, for Paddle Retain.
 *
 * @param {string} email - The email to associate with the Paddle setup.
 * @return {Promise<void>} A promise that resolves when the setup is complete.
 */
async function updatePaddle(email: string): Promise<void> {
	try {
		if (window.Paddle.Initialized) {
			try {
				window.Paddle.Update({
					pwCustomer: {
						email: email
					}
				})
				console.log('Paddle updated successfully.')
			} catch (error) {
				console.error('Error updating Paddle:', error)
			}
		} else {
			try {
				setupPaddle();
				console.log('Paddle initialized successfully.')
			} catch (error) {
				console.error('Error initializing Paddle:', error)
				Sentry.captureException('Error initializing Paddle')
			}
		}
	} catch (globalError) {
		console.error('Unexpected error:', globalError)
		Sentry.captureException('Unexpected error')
	}
}

function paddleInitializeError (){
	userMsg.errorMsg('Error: Paddle initialize failed')
	console.error('Error: Error: Paddle initialize failed')
	Sentry.captureException('Error: Error: Paddle initialize failed')
}
