<script lang="ts">
import { userMsg } from '../../../utils/userMsg.service'
import Icon from 'svelte-icons-pack'
import HiSolidX from 'svelte-icons-pack/hi/HiSolidX'
import { utilService } from '../../../utils/util.service'
import { createEventDispatcher } from 'svelte'
import { useNavigate } from 'svelte-navigator'
import { _ } from 'svelte-i18n'

export let message = ''
export let isOpen = false
export let dogId = ''
export let dogName: string

const dispatch = createEventDispatcher()
const navigate = useNavigate()

let inputElement

async function handleSubmit(event: Event) {
	event.preventDefault()
	if (inputElement.validity.valid) {
		userMsg.successMsg($_('dog_archived'))
		isOpen = false
		try {
			const url = `${import.meta.env.VITE_APP_API_URL}/dog/${dogId}/archive`
			const body = {
				Archived: true
			}
			await utilService.restRequest(url, 'PATCH', body)
			navigate('/dogs')
			userMsg.successMsg(`${$_('dog_created')}`)
		} catch (err: unknown) {
			userMsg.errorMsg('Error', err)
		}
	} else {
		userMsg.errorMsg("Confirmation failed. Confirmation text must match dogs's name.")
	}
}

function close() {
	dispatch('close')
}
</script>

{#if isOpen}
	<div id="background-overlay" />

	<div class="dog-archive-modal flex-column align-center">
		<button
			class="close-button"
			on:click={close}>
			<Icon src={HiSolidX} />
		</button>
		<div class="header">
			<h1 class="title">{$_('archive_dog')}</h1>
		</div>
		<div class="dog-archive-message">
			<b>{message}</b>
			<br />
			<span><i>please note that achieved dogs are not deleted but just hidden; in the near future, we
					will add a view for archived dogs</i>
			</span>
		</div>
		<br />
		<div><b>please type the dog's name to confirm</b></div>
		<form
			on:submit={handleSubmit}
			class="flex-column archive-form">
			<div class="flex align-center">
				<input
					bind:this={inputElement}
					class="edit-input"
					type="text"
					placeholder={dogName}
					required
					pattern={dogName}
					id="name" />
				<label
					for="name"
					role="alert">
					<!-- {fieldsValidation['name'].errorMessage} -->
				</label>
			</div>
			<div class="flex align-center btns-container">
				<button
					type="submit"
					class="btn theme-btn orange submit"
					on:click={handleSubmit}>
					{$_('continue')}
				</button>
			</div>
		</form>
	</div>
{/if}

<style lang="scss">
.dog-archive-modal {
	position: fixed;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 450px;
	background-color: white;
	box-shadow: 0px 8px 16px 0px rgba(143, 149, 178, 0.15);
	border-radius: 16px;
	z-index: 101;
	.dog-archive-message {
		padding: 10px;
		text-align: center;
	}
	.close-button {
		position: absolute;
		right: 10px;
		top: 10px;
		background: none;
		border: none;
		font-size: 1.5em;
		cursor: pointer;
		&:hover {
			filter: brightness(1.2);
		}
	}

	.header {
		padding: 24px 22px;
		font-size: 18px;
	}

	.archive-form {
		padding: 6px 24px;
		width: 100%;
		gap: 16px;

		.edit-input {
			font-size: 30px;
			color: var(--clr-txt-dark-primary);
		}
	}

	@media screen and (max-width: 768px) {
		width: 95%;

		.header {
			text-align: center;
		}
	}

	.btns-container {
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;

		.btn {
			font-size: 13px;
		}
	}
}

#background-overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	background: var(--gradient-2, linear-gradient(47deg, #264a59 0%, #2d7392 100%));
}
</style>
