<script lang="ts">
import Chip, { Set, Text } from '@smui/chips'
import { onMount } from 'svelte'
import { useNavigate, useParams } from 'svelte-navigator'
import Select from 'svelte-select'
import type { TeamModel } from '../../../models/team.model'
import type { TeamUserModel } from '../../../models/user.model'
import { currAccount, loggedInUser } from '../../../store/stores'
import type { DogModel, DogType } from '../../../models/dog.model'
import { dogService } from '../../../services/core/dog.service'
import dogBreeds from '../../../data/fci-dog-breeds.json'
import SelectButton from '../../common/SelectButton.svelte'
import { teamService } from '../../../services/core/team.service'
import { dogDB } from '../../../services/database/dog.db'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import { fireMsg, userMsg } from '../../../utils/userMsg.service'
import { _ } from 'svelte-i18n'
import { utilService } from '../../../utils/util.service'
import { parseDate } from 'svelty-picker'
import { en } from 'svelty-picker/i18n'
import { Row, Col } from 'sveltestrap'
import Flatpickr from 'svelte-flatpickr'
import 'flatpickr/dist/flatpickr.css'

const params = useParams()
const navigate = useNavigate()

const breedsOnlyArr = dogBreeds
	.sort((a, b) => a.name.localeCompare(b.name))
	.map((item) => item.name)

var getBreedsData = (arr: Array<string>) => {
	return arr.map((breed: string) => ({ value: breed.toString(), label: breed.toString() }))
}

var getHandlersData = (arr: Array<TeamUserModel[]>) => {
	let allHandlers = arr.flat(1)

	// Handlers array for dropdown menu
	let handlersItems = allHandlers.map((handler) => ({
		value: handler.id,
		label: `${handler.firstName} ${handler.lastName}`
	}))

	return handlersItems
}

function removeDuplicates(arr: Array<object>) {
	const result = []
	const duplicatesIndices = []

	arr.forEach((current: any, index: any) => {
		if (duplicatesIndices.includes(index)) return

		result.push(current)

		for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
			const comparison = arr[comparisonIndex]
			const currentKeys = Object.keys(current)
			const comparisonKeys = Object.keys(comparison)

			if (currentKeys.length !== comparisonKeys.length) continue

			const currentKeysString = currentKeys.sort().join('').toLowerCase()
			const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase()
			if (currentKeysString !== comparisonKeysString) continue

			let valuesEqual = true
			for (let i = 0; i < currentKeys.length; i++) {
				const key = currentKeys[i]
				if (current[key] !== comparison[key]) {
					valuesEqual = false
					break
				}
			}
			if (valuesEqual) duplicatesIndices.push(comparisonIndex)
		}
	})
	return result
}

const items = getBreedsData(breedsOnlyArr)

type InputIds = ['image', 'name', 'teams', 'breed', 'weight', 'type', 'handler', 'birthday']
type InputId = InputIds[number]

let isGoodImage = false

const fieldsValidation = {
	image: { invalid: false, errorMessage: '' },
	name: { invalid: false, errorMessage: '' },
	teams: { invalid: false, errorMessage: '' },
	breed: { invalid: false, errorMessage: '' },
	weight: { invalid: false, errorMessage: '' },
	type: { invalid: false, errorMessage: '' },
	handler: { invalid: false, errorMessage: '' },
	birthday: { invalid: false, errorMessage: '' }
}

const dogTypes: DogType[] = [
	'Family Dog',
	'Rescued Dog',
	'Working Dog',
	'Service Dog',
	'Therapy Dog',
	'Medical Dog',
	'Sport Dog',
	'Hunting Dog',
	'Police Dog',
	'Military Dog',
	'SAR Dog',
	'EDD Dog',
	'NDD Dog',
	'Conservation Dog',
	'Retired Working Dog'
]
let dog: DogModel
let searchBounceTimeout: ReturnType<typeof setTimeout>
let newDogId: string
let imageInputRef: HTMLInputElement

onMount(async () => {
	if ($currAccount.teamIds.length == 0) {
		navigate('/dogs')
	}
	await loadDog($params.id)
	getTeams()
})

async function loadDog(dogId?: string) {
	if (dogId) {
		dog = await dogService.getById(dogId)
	} else {
		dog = dogService.getEmptyDog({ accountId: $currAccount.id, createdBy: $loggedInUser.id })
		newDogId = dogDB.newDogRef().id
	}
}

async function getTeams() {
	teamsOptionsPromise = teamService.query($loggedInUser.id, $loggedInUser.selectedAccount.id)
	teamsOptions = await teamsOptionsPromise
	teamIdsOptions = teamsOptions.map((team) => team.id)
	selectedTeamsIds = dog.teams.map((team) => team.id)
}

async function validateInput(inputId: InputId) {
	switch (inputId) {
		case 'image':
			if (!newDogImage) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_dog_image_message')
				return
			}

			if (!isGoodImage) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('image_size_message')
				return
			}
			break

		case 'name':
			if (dog.name.length < 2) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_two_characters_message')
				return
			}
			if (!utilService.dogNameExp(dog.name)) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_special_characters_message')
				return
			}
			break

		case 'teams':
			if (selectedTeamsIds.length === 0) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_one_team_message')
				return
			}
			break

		case 'breed':
			if (dogBreeds.findIndex((breed) => breed.name === dogBreedName.label) === -1) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_breed_message')
				return
			}
			break

		case 'weight':
			if (!utilService.dogWeightExp(dog.weight + '') || dog.weight == 0) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('invalid_number_message')
				return
			}
			break

		case 'type':
			if (dog.type.length === 0) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('at_least_one_type_message')
				return
			}
			break

		case 'handler':
			if (dogHandler.value === '') {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_handler_message')
				return
			}
			break

		case 'birthday':
			if (+dogBirthday == 0) {
				fieldsValidation[inputId].invalid = true
				fieldsValidation[inputId].errorMessage = $_('no_dog_birthday_message')
				return
			}
			break

		default:
			break
	}

	fieldsValidation[inputId].invalid = false
	fieldsValidation[inputId].errorMessage = ''
}

function cleanInput(inputId: InputId) {
	isFormDisabled = false
	fieldsValidation[inputId].invalid = false
	fieldsValidation[inputId].errorMessage = ''
}

async function validateAllFields() {
	let isValid = true
	for (const fieldId in fieldsValidation) {
		if (Object.prototype.hasOwnProperty.call(fieldsValidation, fieldId)) {
			await validateInput(fieldId as InputId)
			if (fieldsValidation[fieldId].invalid) {
				fireMsg({
					type: 'failure',
					msg:
						$_(`error_in_field`) + $_(`${fieldId}`) + `, ${fieldsValidation[fieldId].errorMessage}`
				})
				isValid = false
			}
		}
	}
	return isValid
}

// TODO(or-agami): add validation for adit / add dog fields
async function handleSubmit(ev: Event) {
	isFormDisabled = true
	ev.preventDefault()
	ev.stopPropagation()

	const isFormValid = await validateAllFields()
	if (!isFormValid) return (isFormDisabled = false)

	dog.breed = breedOptions.find((breed) => dogBreedName.label === breed.name)
	dog.teams = teamsOptions
		.filter((team) => selectedTeamsIds.includes(team.id))
		.map((team) => ({ id: team.id, name: team.name }))
	dog.handler = { name: dogHandler.label, id: dogHandler.value, type: 'dog handler' }

	if (newDogImage) {
		const imgUrl = await dogService.uploadDogImg(dog.id || newDogId, newDogImage)
		dog.image = imgUrl
	}

	if (newDogId) dog.id = newDogId

	try {
		const url = `${import.meta.env.VITE_APP_API_URL}/dog`
		const body = {
			image: dog.image,
			id: dog.id,
			accountId: $currAccount.id,
			teams: dog.teams,
			breed: dog.breed,
			birthday: parseDate(dogBirthday, 'dd/mm/yyyy', en, 'standard'),
			name: dog.name,
			weight: dog.weight,
			gender: dog.gender,
			type: dog.type,
			status: dog.status,
			registered: dog.registered,
			stats: dog.stats,
			createdBy: $loggedInUser.id,
			unitOfMeasurement: dog.unitOfMeasurement,
			note: dog.note,
			handler: {
				Name: dog.handler.name,
				Id: dog.handler.id,
				Utype: dog.handler.type
			},
			chipId: dog.chipId
		}
		await utilService.restRequest(url, 'POST', body)
		navigate('/dogs')
		isFormDisabled = false
		userMsg.successMsg(`${$_('dog_created')}`)
	} catch (err: unknown) {
		userMsg.errorMsg('Error', err)
		isFormDisabled = false
	}
}

// TODO(or-agami): Change searchBreed to query from DB once DB breed collection is ready
async function searchBreed(input: string) {
	clearTimeout(searchBounceTimeout)
	if (input === '') return (breedOptions = dogBreeds)

	//? Return Promise to mock breed query from DB
	return new Promise((resolve) => {
		breedOptions = dogBreeds.filter((breed) =>
			breed.name?.toLowerCase().includes(dogBreedName.label.toLowerCase())
		)

		//? Timeout for data retrieve                               👇
		searchBounceTimeout = setTimeout(() => resolve(breedOptions), 400)
	})
}

function handleImageUpload(e: Event & { currentTarget: EventTarget & HTMLInputElement }) {
	const img = e.currentTarget.files[0]
	if (!img) {
		fireMsg({
			type: 'failure',
			msg: $_('error_image_upload')
		})
	} else {
		const maxSize = 1024 * 1024 * 5
		const reader = new FileReader()

		reader.readAsDataURL(img)
		reader.onload = () => {
			newDogImage = reader.result as string
		}

		img.size > maxSize ? (isGoodImage = false) : (isGoodImage = true)
	}
}

let isFormDisabled = false

let breedOptions = dogBreeds
let dogBreedName = { value: '', label: $_('type_to_search') }
let dogHandler = { value: '', label: $_('must_select_a_team_from_above') }

let teamsOptionsPromise: Promise<TeamModel[]>
let teamsOptions: TeamModel[]
let teamIdsOptions: string[]
let selectedTeamsIds: string[] = []
let handlersFromSelectedTeams: Array<TeamUserModel[]> = []

function loadHandlersFromSelectedTeams() {
	let hand = selectedTeamsIds.map(async (selectedTeamId) => {
		let selectedTeam = await teamService.getById(selectedTeamId)
		let teamHandlers = await teamService.queryUsers($currAccount.id, selectedTeam.id)
		return teamHandlers
	})
	return hand
}

let dogBirthday = ''

let newDogImage: string

$: searchBreed(dogBreedName.label)
</script>

{#if !dog}
	<CmpLoader />
{:else}
	<div class="dog-edit container-fluid">
		<Row>
			<form
				on:submit={handleSubmit}
				class="dog-edit-form col-md-8 col-sm-12">
				<!-- Header -->
				<header class="flex align-center form-header mb-4">
					<h1 class="title">{$_('dog_information')}</h1>
				</header>

				<!-- Image Picker -->
				<Row class="flex-row align-center picker dog-img mb-4">
					<Col
						md="2"
						sm="2"
						xs="3">
						{#if dog.image || newDogImage}
							<div class="dog-img-container">
								<img
									src={newDogImage || dog.image}
									alt="dog profile" />
							</div>
						{:else}
							<div
								class="dog-img-container"
								style="background-color: var(--clr-primary);" />
						{/if}
					</Col>
					<Col class="col">
						<input
							type="file"
							bind:this={imageInputRef}
							class:invalid={fieldsValidation['image'].errorMessage}
							on:input={handleImageUpload}
							id="image"
							required
							hidden
							accept="image/*" />
						<button
							type="button"
							class="btn theme-btn change-photo"
							on:click={() => imageInputRef.click()}>
							{$_('add_photo')} *
						</button>
					</Col>
				</Row>

				<!-- Dog Name -->
				<Row class="field flex-column dog-name mb-4">
					<Col
						md="3"
						class="mb-2">
						<label for="name">{$_('dog_name')}</label>
					</Col>
					<Col class="col flex-column">
						<input
							class="edit-input"
							class:invalid={fieldsValidation['name'].errorMessage}
							type="text"
							required
							pattern={'^[a-zA-Z0-9 ]{2,}$'}
							id="name"
							on:focus={() => cleanInput('name')}
							on:blur={() => validateInput('name')}
							bind:value={dog.name} />
						<label
							for="name"
							role="alert">
							{fieldsValidation['name'].errorMessage}
						</label>
					</Col>
				</Row>

				<!-- Teams -->
				<Row class="field flex-column teams md-3">
					<Col md="3">
						<label for="teams">{$_('teams')} *</label>
					</Col>
					<Col class="col px-0">
						{#await teamsOptionsPromise then}
							<Set
								id="teams"
								chips={teamIdsOptions}
								let:chip={teamId}
								filter
								bind:selected={selectedTeamsIds}
								on:click={async () => {
									handlersFromSelectedTeams = await Promise.all(loadHandlersFromSelectedTeams())
								}}>
								<Chip
									chip={teamId}
									touch>
									<Text>{teamsOptions.find((team) => team.id === teamId).name}</Text>
								</Chip>
							</Set>
						{/await}
						<label
							for="teams"
							role="alert">
							{fieldsValidation['teams'].errorMessage}
						</label>
					</Col>
				</Row>

				<!-- Dog Handler -->
				<Row class="field flex-column dog-handler">
					<Col
						md="3"
						class="mb-2">
						<label for="handler">{$_('dog_handler')} *</label>
					</Col>
					<Col class="col">
						{#if selectedTeamsIds.length}
							<Select
								showChevron={true}
								clearable={false}
								containerStyles="z-index: 3"
								placeholder={$_('must_select_a_team_from_above')}
								items={removeDuplicates(getHandlersData(handlersFromSelectedTeams))}
								bind:value={dogHandler}>
								<div
									slot="empty"
									style="padding: 25px 10px; text-align: center; color: var(--list-empty-color, #78848f)">
									{$_('please_select_a_team_first')}
								</div>
							</Select>
						{:else}
							<Select
								disabled
								placeholder={$_('must_select_a_team_from_above')} />
						{/if}
						<label
							for="name"
							role="alert">
							{fieldsValidation['handler'].errorMessage}
						</label>
					</Col>
				</Row>

				<!-- Breed -->
				<Row class="field flex-column breed">
					<Col
						md="3"
						class="mb-2">
						<label for="breed">{$_('breed')} *</label>
					</Col>
					<Col class="col">
						<Select
							showChevron={true}
							clearable={false}
							containerStyles="z-index: 2"
							{items}
							bind:value={dogBreedName} />
						<label
							for="breed"
							role="alert">
							{fieldsValidation['breed'].errorMessage}
						</label>
					</Col>
				</Row>

				<!-- Birthday -->
				<Row class="field flex-column birthday mb-4">
					<Col
						md="3"
						class="mb-2">
						<label for="birthday">{$_('birthday')} *</label>
					</Col>
					<Col class="col">
						<Flatpickr
							class="edit-input"
							options={{
								enableTime: false,
								allowInput: true,
								dateFormat: 'd/m/Y'
							}}
							bind:value={dogBirthday}
							name="date"
							placeholder="dd/mm/yyyy" />
						<label
							for="birthday"
							role="alert">
							{fieldsValidation['birthday'].errorMessage}
						</label>
					</Col>
				</Row>

				<!-- Weight -->
				<Row class="field flex-column weight">
					<Col md="3">
						<label for="weight">{$_('weight')}</label>
					</Col>
					<Col class="col mt-2">
						<div class="flex align-center double-input">
							<input
								class="edit-input"
								class:invalid={fieldsValidation['weight'].errorMessage}
								type="number"
								step="0.5"
								required
								id="weight"
								on:focus={() => cleanInput('weight')}
								on:blur={() => validateInput('weight')}
								bind:value={dog.weight} />
							<SelectButton
								bind:value={dog.unitOfMeasurement}
								choices={[
									{ label: $_('kg'), value: 'metric' },
									{ label: $_('lbs'), value: 'imperial' }
								]} />
						</div>
						<label
							for="weight"
							role="alert">
							{fieldsValidation['weight'].errorMessage}
						</label>
					</Col>
				</Row>

				<!-- Gender -->
				<Row class="field flex-column gender mb-4">
					<Col md="3">
						<label for="gender">{$_('gender')} *</label>
					</Col>
					<Col class="col mt-2">
						<SelectButton
							id="gender"
							bind:value={dog.gender}
							choices={[
								{ label: $_('male'), value: 'male' },
								{ label: $_('female'), value: 'female' }
							]} />
					</Col>
				</Row>

				<!-- Dog Type -->
				<Row class="field flex-column type">
					<Col md="3">
						<label for="type">{$_('dog_type')} *</label>
					</Col>
					<Col class="col">
						<Set
							id="type"
							chips={dogTypes}
							let:chip
							filter
							bind:selected={dog.type}>
							<Chip
								{chip}
								touch>
								<Text>{$_(`dog_types.${chip}`)}</Text>
							</Chip>
						</Set>
						<label
							for="type"
							role="alert">
							{fieldsValidation['type'].errorMessage}
						</label>
					</Col>
				</Row>

				<!-- Status -->
				<Row class="field flex-column status mb-4">
					<Col md="3">
						<label for="status">{$_('status')} *</label>
					</Col>
					<Col class="col mt-2">
						<SelectButton
							id="status"
							bind:value={dog.status}
							choices={[
								{ label: $_('active'), value: 'active' },
								{ label: $_('in_training'), value: 'training' },
								{ label: $_('injured'), value: 'injured' },
								{ label: $_('retired'), value: 'retired' }
							]} />
					</Col>
				</Row>

				<!-- Registered -->
				<Row class="field flex-column registered mb-4">
					<Col md="3">
						<label for="registered">{$_('papered_registered')} *</label>
					</Col>
					<Col class="col mt-2">
						<SelectButton
							id="registered"
							bind:value={dog.registered}
							choices={[
								{ label: $_('yes'), value: true },
								{ label: $_('no'), value: false }
							]} />
					</Col>
				</Row>

				<!-- Chip ID -->
				<Row class="field flex-column chip-id mb-4">
					<Col md="3">
						<label for="chip-id">{$_('chip_id')}</label>
					</Col>
					<Col class="col">
						<input
							class="edit-input"
							style="width: 100%;"
							type="text"
							id="chip-id"
							bind:value={dog.chipId} />
					</Col>
				</Row>

				<!-- Notes -->
				<Row class="field flex-column notes mb-4">
					<Col md="3">
						<label for="notes">{$_('notes')}</label>
					</Col>
					<Col class="col">
						<textarea
							rows="5"
							style="resize: none; width: 100%;"
							class="edit-input"
							id="notes"
							bind:value={dog.note} />
					</Col>
				</Row>

				<!-- Buttons -->
				<Row class="flex btns-container">
					<Col
						sm="12"
						class="d-flex justify-content-end">
						<button
							type="button"
							class="btn theme-btn outline discard mr-3"
							on:click={() => navigate(-1)}>
							{$_('cancel')}
						</button>
						<button
							disabled={isFormDisabled}
							type="submit"
							class="btn theme-btn submit"
							on:click={handleSubmit}>
							{$_('submit')}
						</button>
					</Col>
				</Row>
			</form>
		</Row>
	</div>
{/if}

<style lang="scss">
@use '../../../styles/setup/mixins';

.dog-edit {
	max-width: 800px;
	margin-inline: auto;

	.dog-edit-form {
		@include mixins.card;
		gap: 20px;
		padding: 28px 24px;
		width: 100%;

		.dog-img-container {
			width: 80px;
			height: 80px;
		}

		.change-photo {
			padding-right: 16px;
		}

		.field {
			display: flex;
			flex-direction: column;
			gap: 6px;

			label {
				font-size: 14px;
				font-family: Nunito-SemiBold;
				color: #33383f;
			}

			.double-input {
				gap: 15px;
			}

			.edit-input {
				font-size: 16px;
				color: var(--clr-txt-dark-primary);
			}

			&:has(:required) {
				label:not([role='alert'])::after {
					content: ' *';
				}
			}

			input.invalid {
				border-color: #b00020;
			}

			label[role='alert'] {
				color: #b00020;
			}
		}

		.btns-container {
			justify-content: flex-end;
			margin-right: -30px;
			gap: 12px;
		}
	}

	:global(.mdc-chip) {
		border-radius: 12px;
		background-color: var(--clr-primary);
		color: white;
		border-radius: 6px;

		:global(.mdc-chip__ripple) {
			border-radius: 6px;
		}

		:global(.mdc-chip__checkmark-svg path) {
			stroke: white;
		}
	}
}
</style>
