<script lang="ts">
import Toggle from './Toggle.svelte'
import Slider from './Slider.svelte'
import { toggleSwitchSlider } from '../../../store/trainingWidgetsStore'

export let values: {
	min: number
	max: number
	defaultSliderValue: number
	label: string
	divisions: number
	trigger: number
	labels: string[]
	selectedIndex: number
	value: {
		answer: string
		labels: string[]
		selectedIndex: number
		sliderValue: {
			selectedValue: number
			label: string
		}
	}
	filled: boolean
}
export let data = []
export let id: string

let sliderValues: any
let toggleValues: any

if (!$toggleSwitchSlider[id]) {
	$toggleSwitchSlider[id] = {
		min: values.min,
		max: values.max,
		defaultSliderValue: values.defaultSliderValue,
		label: values.label,
		divisions: values.divisions,
		trigger: values.trigger,
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: {
			answer: '',
			labels: [],
			selectedIndex: values.selectedIndex,
			sliderValue: {
				selectedValue: 0,
				label: values.label
			}
		},
		filled: false
	}
}

if (data.length > 0 && $toggleSwitchSlider[id].value.answer == '') {
	sliderValues = {
		defaultSliderValue: data[0].value.sliderValue.selectedValue,
		min: values.min,
		max: values.max,
		divisions: values.divisions,
		label: values.label,
		value: {
			selectedValue: data[0].value.sliderValue.selectedValue,
			label: values.label
		}
	}
} else if ($toggleSwitchSlider[id].value.sliderValue != 0) {
	sliderValues = {
		defaultSliderValue: $toggleSwitchSlider[id].value.sliderValue.selectedValue,
		min: $toggleSwitchSlider[id].min,
		max: $toggleSwitchSlider[id].max,
		divisions: $toggleSwitchSlider[id].divisions,
		label: values.label,
		value: {
			selectedValue: $toggleSwitchSlider[id].value.sliderValue.selectedValue,
			label: values.label
		}
	}
} else {
	sliderValues = {
		defaultSliderValue: values.defaultSliderValue,
		min: values.min,
		max: values.max,
		divisions: values.divisions,
		label: values.label,
		value: {
			selectedValue: 0,
			label: values.label
		}
	}
}

if (data.length > 0 && $toggleSwitchSlider[id].value.answer == '') {
	toggleValues = {
		labels: values.labels,
		selectedIndex: data[0].value.selectedIndex,
		value: data[0].value.selectedIndex
	}
	values.value = {
		selectedIndex: data[0].value.selectedIndex,
		answer: data[0].value.answer,
		labels: values.labels,
		sliderValue: {
			selectedValue: data[0].value.sliderValue.selectedValue,
			label: data[0].value.sliderValue.label
		}
	}
} else if ($toggleSwitchSlider[id]) {
	toggleValues = {
		labels: values.labels,
		selectedIndex: $toggleSwitchSlider[id].value.selectedIndex,
		value: $toggleSwitchSlider[id].value.selectedIndex
	}
	values.value = {
		selectedIndex: $toggleSwitchSlider[id].value.selectedIndex,
		answer: $toggleSwitchSlider[id].value.answer,
		labels: values.labels,
		sliderValue: {
			selectedValue: $toggleSwitchSlider[id].value.sliderValue.selectedValue,
			label: $toggleSwitchSlider[id].value.sliderValue.label
		}
	}
} else {
	toggleValues = {
		labels: values.labels,
		selectedIndex: values.selectedIndex,
		value: values.selectedIndex
	}
	values.value = {
		selectedIndex: toggleValues.selectedIndex,
		answer: '',
		labels: values.labels,
		sliderValue: {
			selectedValue: sliderValues.value.selectedValue,
			label: values.label
		}
	}
}

$: {
	if (toggleValues.selectedIndex == values.trigger) {
		values.value = {
			selectedIndex: toggleValues.selectedIndex,
			sliderValue: {
				selectedValue: sliderValues.value.selectedValue,
				label: sliderValues.label
			},
			answer: values.labels[toggleValues.selectedIndex],
			labels: values.labels
		}
		$toggleSwitchSlider[id] = values
	} else {
		values.value = {
			selectedIndex: toggleValues.selectedIndex,
			answer: values.labels[toggleValues.selectedIndex],
			labels: values.labels,
			sliderValue: {
				selectedValue: null,
				label: null
			}
		}
		$toggleSwitchSlider[id] = values
	}
}
</script>

<Toggle bind:values={toggleValues} />

{#if toggleValues.selectedIndex === values.trigger}
	<Slider bind:values={sliderValues} />
{/if}
