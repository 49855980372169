<script lang="ts">
import { onMount, afterUpdate } from 'svelte'
import * as Highcharts from 'highcharts'
import Select from 'svelte-select/Select.svelte'
import { _ } from 'svelte-i18n'

export let data
export let dogId
let chart

let dataForDisplay
let ratingType:
	| { value: 'dogRating'; label: 'How Was Your Dog?' }
	| { value: 'trainedByRating'; label: 'How Do You Feel?' } = {
	value: 'dogRating',
	label: 'How Was Your Dog?'
}
const ratingTypes = [
	{ value: 'dogRating', label: 'How Was Your Dog?' },
	{ value: 'trainedByRating', label: 'How Do You Feel?' }
]

afterUpdate(() => {
	if (data) {
		dataForDisplay = getMoodFromTime(data, dogId)

		generateChart(dataForDisplay)
	}
})

function getMoodFromTime(data, dogId) {
	let moodFromTime = new Map()
	let time = 0

	data.forEach((training) => {
		const { trainedByRating, dogRating, trainingTime, dog } = JSON.parse(training['data'])

		if (dog.id == dogId) {
			time += trainingTime
			moodFromTime.set(time, { trainedByRating, dogRating })
		}
	})

	return moodFromTime
}

function generateChart(data) {
	const chartData = Array.from(data).map(([time, { trainedByRating, dogRating }]) => ({
		x: time,
		y1: trainedByRating,
		y2: dogRating
	}))

	const options = {
		chart: {
			type: 'spline'
		},
		title: {
			text: ''
		},
		xAxis: {
			title: {
				text: $_('minutes')
			}
		},
		yAxis: {
			title: {
				text: 'Rating'
			},
			min: 1,
			max: 5
		},
		legend: {
			enabled: false,
			verticalAlign: 'top',
			align: 'left',
			layout: 'horizontal'
		},
		series:
			ratingType.value == 'dogRating'
				? [
						{
							name: 'Dog Rating',
							data: chartData.map((point) => [point.x, point.y2]),
							lineWidth: 4
						}
				]
				: [
						{
							name: 'Trained By Rating',
							data: chartData.map((point) => [point.x, point.y1]),
							lineWidth: 4,
							color: '#FE9803'
						}
					]
	}

	let chart = Highcharts.chart('mood-from-time-chart', options)
}
</script>

<div class="chart-container flex-column">
	<div class="select-container flex">
		<Select
			showChevron={true}
			items={ratingTypes}
			bind:value={ratingType}
			clearable={false}
			editable={false} />
	</div>
	<div id="mood-from-time-chart" />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.chart-container {
	@include mixins.card;
	position: relative;
	background-color: #fff;
	border-radius: 6px;
	padding-top: 10px;

	.select-container {
		margin: 10px 20px 10px auto;
		position: absolute;
		z-index: 1000;
		right: 0;
		width: 360px;
	}

	#mood-from-time-chart {
		margin-top: 60px;
	}
}
</style>
