import {
	collection,
	doc,
	DocumentReference,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
	type DocumentData
} from 'firebase/firestore'
import { Firestore } from '../firebase/firebase'
import type { AccountModel } from '../../models/account.model'
import type { UserRole, UserType } from '../../models/user.model'
import type { QuerySnapshot, DocumentSnapshot, Query } from 'firebase/firestore'

export const accountDB = {
	getManyUsers,
	get,
	add,
	update,
	getAccountRef,
	newAccountRef,
	getManyUsersQuery
}

async function getManyUsers(userIds: string[], accountId: string): Promise<QuerySnapshot<DocumentData>> {
	const queryParams = _getUsersDocsQuery(userIds, accountId)
	const querySnapshot = await getDocs(queryParams)
	return querySnapshot
}

async function get(accountId: string): Promise<DocumentData> {
	const accountDocSnap = await getDoc(_getDocRef(accountId))
	if (accountDocSnap.exists()) {
		return accountDocSnap.data()
	} else throw new Error('Account not found')
}

function getManyUsersQuery(accountId: string, filterBy?: Partial<{ role: UserRole[]; type: UserType[] }>): Query<DocumentData> {
	const queryConstraints = []
	if (filterBy) {
		const { role, type } = filterBy
		if (role) queryConstraints.push(where('role', '==', role))
		if (type) queryConstraints.push(where('type', '==', type))
	}
	return query(collection(Firestore, 'accounts', accountId, 'accountUsers'), where('accountId', '==', accountId), ...queryConstraints)
}

async function add(newAccount: AccountModel) {
	const newDocRef = _getNewDocRef()
	newAccount.id = newDocRef.id
	const docRef = await setDoc(newDocRef, newAccount)
	return docRef
}

async function update(accountId: string, editedAccount: Partial<AccountModel>) {
	if (!accountId) throw new Error('Account ID is required')
	const docRef = await updateDoc(_getDocRef(accountId), editedAccount)
	return docRef
}

function getAccountRef(documentId: string): DocumentReference<DocumentData> {
	return _getDocRef(documentId)
}

function newAccountRef(): DocumentReference<DocumentData> {
	return _getNewDocRef()
}

function _getUsersDocsQuery(userIds: string[], accountId: string): Query<DocumentData> {
	return query(
		collection(Firestore, 'accounts', accountId, 'accountUsers'),
		where('id', 'in', userIds),
		where('accountId', '==', accountId)
	)
}

function _getDocRef(documentId: string): DocumentReference<DocumentData> {
	return doc(Firestore, 'accounts', documentId)
}

function _getNewDocRef(): DocumentReference<DocumentData> {
	return doc(collection(Firestore, 'accounts'))
}
