<script lang="ts">
import { createEventDispatcher } from 'svelte'

export let value = 'en'

const dispatch = createEventDispatcher()

function handleLocaleChange(event) {
	event.preventDefault()
	dispatch('locale-changed', event.target.value)
}
</script>

<div class="locale-switcher flex-column">
	<select
		class="switch"
		bind:value
		on:change={handleLocaleChange}>
		<option value="en">English</option>
		<option value="he">Hebrew</option>
		<option value="es">Spanish</option>
	</select>
</div>

<style lang="scss">
@use '../styles/setup/mixins';

.locale-switcher {
	font-size: small;
	align-items: flex-start;
	justify-content: center;
	width: 300px;
	height: 53px;

	.switch {
		@include mixins.card;
		font-size: small;
		border: 0.2px solid rgba(0, 0, 0, 0.103);
		box-shadow: none;
		width: 100%;
		height: 45px;
	}
	.switch:hover {
		cursor: pointer;
		background-color: darken(white, 3%);
	}

	@media screen and (max-width: 900px) {
		width: 100%;
	}
}
</style>
