
import { maintenanceDB } from '../database/maintenance.db'
import { userMsg } from '../../utils/userMsg.service'

export const maintenanceService = {
  isUnderMaintenance
}


/**
 * Checks if the application is under maintenance.
 * @returns {Promise<{ on: boolean, message: string }>} An object indicating if the application is under maintenance and a message.
 * @throws {Error} If there is an error while checking the maintenance status.
 */
async function isUnderMaintenance(): Promise<{ on: boolean, message: string }> {
  try {
    const isUnderMaintenance = await maintenanceDB.get("NdyLWrkNJRJcb8oKO7UV")
    const on = isUnderMaintenance.isUnderMaintenance;
    const message = isUnderMaintenance.message;
    return { on, message };
  } catch (error: unknown) {
    userMsg.errorMsg('Error: with maintenance mode', error)
    return { on:false, message:'error'};
  }
}
