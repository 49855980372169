<script lang="ts">
import 'ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-quartz.css'
import { createGrid } from '@ag-grid-community/core'
import { utilService } from '../../utils/util.service'
import { ModuleRegistry } from '@ag-grid-community/core'
import { SideBarModule } from '@ag-grid-enterprise/side-bar'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import {
	trainingCellRenderer,
	userCellRenderer,
	dogCellRenderer,
	teamCellRenderer,
	dateCellRenderer,
	dogRatingCellRenderer,
	userRatingCellRenderer
} from '../reports/cellRenderers'
import type {
	GridOptions,
	GridApi,
	ColDef,
	MenuItemDef,
	GetContextMenuItemsParams,
	IDateFilterParams,
	ISetFilterParams,
	IGroupCellRendererParams,
	ValueFormatterParams
} from '@ag-grid-community/core'
import 'ag-grid-enterprise'
import { SvelteComponent, onMount } from 'svelte'

export let trainingsForDisplay

ModuleRegistry.registerModules([
	SideBarModule,
	RowGroupingModule,
	ExcelExportModule,
	MenuModule,
	ClientSideRowModelModule,
	FiltersToolPanelModule,
	ColumnsToolPanelModule,
	ClipboardModule,
	SetFilterModule,
	MultiFilterModule
])

let dataColumns = []
let columnDefs
let gridOptions: GridOptions

let api: GridApi

let trainingReportsGrid: HTMLDivElement

onMount(() => {
	trainingsForDisplay.sort((a, b) => a.Type.name.localeCompare(b.Type.name))

	gridOptions = {
		columnDefs: columnDefs,
		rowData: trainingsForDisplay,
		rowMultiSelectWithClick: false,
		rowDragManaged: true,
		rowDragText: (params, dragItemCount) => {
			return params.defaultTextValue.name
		},
		defaultColDef: {
			floatingFilter: true
		},
		sideBar: {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					minWidth: 225,
					maxWidth: 225,
					width: 225
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					minWidth: 180,
					maxWidth: 400,
					width: 250
				}
			],
			position: 'right'
		},
		allowContextMenuWithControlKey: true,
		rowHeight: 40,
		groupDisplayType: 'multipleColumns',
		autoGroupColumnDef: {
			headerName: 'Training Type',
			minWidth: 350,
			floatingFilter: true,
			cellRendererParams: {
				checkbox: (params) => params.node.group
			} as IGroupCellRendererParams,
			headerCheckboxSelection: true,
			valueFormatter: (params) => {
				if (params.value && params.node.group) {
					return params.node.key
				}
			}
		},
		domLayout: 'normal',
		rowSelection: 'multiple',
		groupSelectsChildren: true,
		defaultCsvExportParams: {
			onlySelected: true
		},
		defaultExcelExportParams: {
			onlySelected: true
		}
	}

	api = createGrid(trainingReportsGrid, gridOptions)
	api.addEventListener('rowGroupOpened', handleRowGroupOpened)
})

var dateFilterParams: IDateFilterParams = {
	comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
		var dateAsString = cellValue
		if (dateAsString == null) return -1
		var dateParts = dateAsString.split('/')
		var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))

		if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
			return 0
		}

		if (cellDate < filterLocalDateAtMidnight) {
			return -1
		}

		if (cellDate > filterLocalDateAtMidnight) {
			return 1
		}
		return 0
	}
}

function handleRowGroupOpened(event) {
	const { expanded, key } = event.node

	api.setColumnsVisible(
		[...getVisibleColumnsList(key), 'How do you feel?', 'How was your dog?'],
		expanded
	)
}

function getVisibleColumnsList(rowName: string) {
	const widgetsData = trainingsForDisplay.find((row) => row.Type.name === rowName).data.data
	const visibleColumns = []

	widgetsData
		.filter((widget) => widget.name != undefined)
		.forEach((widget) => {
			visibleColumns.push(widget.name)
		})

	return visibleColumns
}

const nameValueFormatter = (params: ValueFormatterParams) => {
	if (params.value) return params.value.name
}

const reportsComparator = (valueA, valueB) => {
	if (valueA.name) return valueA.name.localeCompare(valueB.name)
	else return valueA.localeCompare(valueB)
}

$: {
	const dataNames = Array.from(
		new Set(trainingsForDisplay.flatMap((item) => item.data.data.map((dataItem) => dataItem.name)))
	)

	dataColumns = dataNames
		.filter((name) => name != undefined)
		.map((name) => ({
			field: name,
			cellRenderer: (params) => {
				if (params.valueFormatted) return params.valueFormatted
			},
			valueFormatter: (params: ValueFormatterParams) => {
				if (params.data !== undefined) {
					const dataItem = params.data.data.data.find((item) => item.name === name)

					if (dataItem) {
						const item = {
							value: dataItem.value,
							type: dataItem.type
						}

						return utilService.getProperTrainingValue(item)
					}
				}
			},
			useValueFormatterForExport: true,
			minWidth: 180
		}))

	columnDefs = [
		{
			headerName: 'Training Information',
			children: [
				{
					field: 'Type',
					cellRenderer: trainingCellRenderer,
					comparator: reportsComparator,
					minWidth: 310,
					filter: 'agMultiColumnFilter',
					rowDrag: true,
					rowGroup: true,
					floatingFilter: true,
					filterValueGetter: (data, value) => {
						return data.data.Type.name
					},
					valueFormatter: (params) => {
						if (params.data && params.data.Type) return params.data.Type.name
					},
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								display: 'subMenu'
							},
							{
								filter: 'agSetColumnFilter',
								filterParams: {
									valueFormatter: nameValueFormatter
								}
							}
						]
					} as ISetFilterParams,
					pivot: true,
					checkboxSelection: (params) => !params.node.group
				},
				{
					field: 'Event',
					cellRenderer: (params) => {
						return utilService.capitalizeFirstLetter(params.value)
					},
					valueFormatter: (params: ValueFormatterParams) => {
						if (params.value) return utilService.capitalizeFirstLetter(params.value)
					},
					filter: 'agMultiColumnFilter',
					floatingFilter: true,
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								display: 'subMenu',
								filterParams: {
									valueFormatter: (params) => params.value.name
								}
							},
							{
								filter: 'agSetColumnFilter',
								filterParams: {
									valueFormatter: (params: ValueFormatterParams) => {
										if (params.value) return utilService.capitalizeFirstLetter(params.value)
									}
								}
							}
						]
					} as ISetFilterParams
				},
				{
					field: 'Team',
					cellRenderer: teamCellRenderer,
					comparator: (valueA, valueB) => {
						if (valueA && valueB) return valueA.localeCompare(valueB)
					},
					minWidth: 150,
					filter: 'agMultiColumnFilter',
					floatingFilter: true,
					filterValueGetter: (data, value) => {
						return data.data.Team.name
					},
					valueFormatter: (params) => {
						if (params.data && params.data.Team) return params.data.Team.name
					},
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									valueFormatter: (params) => params.value.name
								},
								display: 'subMenu'
							},
							{
								filter: 'agSetColumnFilter',
								filterParams: {
									valueFormatter: (params) => params.value.name
								}
							}
						]
					} as ISetFilterParams
				},
				{
					field: 'Training By',
					cellRenderer: userCellRenderer,
					comparator: (valueA, valueB) => {
						return valueA.localeCompare(valueB)
					},
					minWidth: 160,
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									valueFormatter: (params) => params.value.name
								},
								display: 'subMenu'
							},
							{
								filter: 'agSetColumnFilter',
								filterParams: {
									valueFormatter: (params) => params.value.name
								}
							}
						]
					} as ISetFilterParams,
					floatingFilter: true
				},
				{
					field: 'Dog',
					cellRenderer: dogCellRenderer,
					comparator: (valueA, valueB) => {
						return valueA.localeCompare(valueB)
					},
					minWidth: 150,
					filter: 'agMultiColumnFilter',
					filterValueGetter: (data, value) => {
						return data.data.Dog.name
					},
					valueFormatter: (params) => {
						if (params.data && params.data.Dog) return params.data.Dog.name
					},
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									valueFormatter: (params) => params.value.name
								},
								display: 'subMenu'
							},
							{
								filter: 'agSetColumnFilter',
								filterParams: {
									valueFormatter: (params) => params.value
								}
							}
						]
					} as ISetFilterParams,
					floatingFilter: true
				},
				{
					field: 'Date',
					cellRenderer: (params) => {
						if (params.value)
							return new Date(params.value).toLocaleDateString('en-US', {
								year: 'numeric',
								month: 'long',
								day: 'numeric'
							})
					},
					valueFormatter: (params: ValueFormatterParams) => {
						if (params.value)
							return new Date(params.value).toLocaleDateString('en-US', {
								year: 'numeric',
								month: 'long',
								day: 'numeric'
							})
					},
					minWidth: 170,
					filter: 'agDateColumnFilter',
					floatingFilter: true,
					filterParams: dateFilterParams
				},
				{
					field: 'Training Time',
					cellRenderer: (params) => {
						if (params.value) return utilService.minutesToHoursAndMinutesStr(params.value)
					},
					valueFormatter: (params: ValueFormatterParams) => {
						if (params.value) return utilService.minutesToHoursAndMinutesStr(params.value)
					},
					minWidth: 140
				}
			]
		},
		{
			headerName: 'Training Details',
			children: [...dataColumns]
		},
		{
			field: 'How do you feel?',
			cellRenderer: userRatingCellRenderer
		},
		{
			field: 'How was your dog?',
			cellRenderer: dogRatingCellRenderer
		}
	]

	if (dataColumns && api) {
		const hidedColumns = dataColumns.map((item) => item.field)

		api.setColumnsVisible([...hidedColumns, 'How do you feel?', 'How was your dog?'], false)
	}
}
</script>

<div class="grid-container">
	{#if trainingsForDisplay}
		<div
			id="myGrid"
			bind:this={trainingReportsGrid}
			class="ag-theme-quartz"
			style="height: 700px; border: none;" />
	{/if}
</div>

<style lang="scss">
@use '../../styles/setup/mixins';
@import '@ag-grid-community/styles/ag-theme-quartz.css';

.grid-container {
	@include mixins.card;
	z-index: 1;
}
</style>
