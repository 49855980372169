<script lang="ts">
import type { TrainingModel } from '../../../../models/training.model'
import { _ } from 'svelte-i18n'
import { utilService } from '../../../../utils/util.service'
import convert from 'convert'
import { Lottie } from 'lottie-svelte'

export let weather: TrainingModel['weather']
export let airQuality

const unitSystem = localStorage.getItem('measurement-type')

function getAirQuality(aqi: number) {
	switch (aqi) {
		case 1:
			return $_('very_poor')
		case 2:
			return $_('poor')
		case 3:
			return $_('fair')
		case 4:
			return $_('good')
		case 5:
			return $_('excellent')
	}
}
</script>

<section class="stats-container grid">
	<div class="info-container humidity flex">
		<span class="lottie-container">
			<Lottie path="../../../../../assets/animations/weather/humidity.json" />
		</span>
		<span>
			<h2>{$_('humidity')}</h2>
			<p>{weather.data[0].humidity}%</p>
		</span>
	</div>
	<div class="uv info-container flex">
		<span class="lottie-container">
			<Lottie path="../../../../../assets/animations/weather/uv-index.json" />
		</span>
		<span>
			<h2>{$_('uv_index')}</h2>
			<p>{weather.data[0].uvi ? weather.data[0].uvi + ' of 10' : $_('no_data')}</p>
		</span>
	</div>
	<div class="visibility info-container flex">
		<div class="lottie-container">
			<Lottie path="../../../../../assets/animations/weather/visibility.json" />
		</div>
		<span>
			<h2>{$_('visibility')}</h2>
			{#if weather.data[0].visibility}
				{#if unitSystem == 'metric'}
					<p>{(weather.data[0].visibility / 1000).toFixed(1)} {$_('km')}</p>
				{:else}
					<p>
						{convert(weather.data[0].visibility / 1000, 'km')
							.to('mi')
							.toFixed(1)}
						{$_('mi')}
					</p>
				{/if}
			{:else}
				<p>{$_('no_data')}</p>
			{/if}
		</span>
	</div>
	<div class="visibility info-container flex">
		<span class="lottie-container">
			<Lottie path="../../../../../assets/animations/weather/smoke.json" />
		</span>

		<span>
			<h2>{$_('air_quality')}</h2>
			<p>
				{airQuality
					? getAirQuality(airQuality.list[0].main.aqi) + ', ' + airQuality.list[0].main.aqi + ' AQI'
					: $_('no_data')}
			</p>
		</span>
	</div>
	<div class="dew-point info-container flex">
		<span class="lottie-container dew-point">
			<Lottie path="../../../../../assets/animations/weather/dew-point.json" />
		</span>
		<span>
			<h2>{$_('dew_point')}</h2>
			{#if weather.data[0].dew_point}
				{#if unitSystem == 'metric'}
					<p>{weather.data[0].dew_point}°C</p>
				{:else}
					<p>{utilService.convertFahrenheitToCelsius(weather.data[0].dew_point)}°F</p>
				{/if}
			{:else}
				<p>{$_('no_data')}</p>
			{/if}
		</span>
	</div>
	<div class="pressure info-container flex">
		<span class="lottie-container">
			<Lottie path="../../../../../assets/animations/weather/pressure-high.json" />
		</span>
		<span>
			<h2>{$_('pressure')}</h2>
			<p>{weather.data[0].pressure ? weather.data[0].pressure + ' hPa' : $_('no_data')}</p>
		</span>
	</div>
</section>

<style lang="scss">
@use '../../../../styles/setup/mixins';

.stats-container {
	@include mixins.card;
	margin: 18px 0 0 0;
	background-color: #ffffff;
	padding: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(110px, 190px));
	justify-content: space-around;
	min-height: 200px;
	column-gap: 10px;

	@media screen and (max-width: 1200px) {
		margin-bottom: 20px !important;
	}

	@media screen and (max-width: 768px) {
		padding-left: 0;
		padding-right: 0;
	}
}

.info-container {
	align-items: center;
	padding: 15px;
	gap: 0.5rem;

	h2 {
		font-size: 20px;
		font-family: Montserrat-Medium;
		font-weight: 400;
		line-height: 24px;
	}

	p {
		font-size: 14px;
		font-family: Montserrat-Light;
		font-weight: 700;
		line-height: 17px;
	}

	.lottie-container {
		width: 40px;
		height: 40px;
	}
}
</style>
