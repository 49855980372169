import {
    DocumentReference,
    collection,
    query,
    limit,
    doc,
    orderBy,
    type DocumentData,
} from 'firebase/firestore'
import { Firestore } from '../firebase/firebase'
import type { Query } from 'firebase/firestore'

//Object to to access the functions from other modules
export const DogBaseNewsDB = {
    loadPage,
    getNewsRef
}

//Loads a page by returning a limited number of messages 
//Ordered by a date in in descending order
async function loadPage(itemsPerPage: number): Promise<Query<DocumentData>> {
    return query(
        collection(Firestore, 'dogbaseNews'),
        orderBy('createdAt', 'desc'),
        limit(itemsPerPage || 1)
    )
}

//Returning the firestore document reference for the specified message 
function getNewsRef(documentId: string): DocumentReference<DocumentData> {
    return _getNewsRef(documentId)
}

//Internal function to return the firestore document reference for the specified message 
function _getNewsRef(documentId: string): DocumentReference<DocumentData> {
    return doc(Firestore, 'dogbaseNews', documentId)
}