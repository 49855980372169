<script lang="ts">
import CmpLoader from '../common/loader/CmpLoader.svelte'
import HelpCenterHeader from './HelpCenterHeader.svelte'

let isLoaded = false
</script>

<svelte:head>
	<script
		async
		src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
</svelte:head>

<div class="contact-us flex-column">
	<HelpCenterHeader />
	<div class="clickup-embed flex">
		{#if !isLoaded}
			<div class="loader">
				<CmpLoader />
			</div>
		{/if}
		<iframe
			title="Contact Us"
			src="https://forms.clickup.com/9003086470/f/8ca0cm6-1182/QJCTXXD5146GIO19PU"
			on:load={() => (isLoaded = true)} />
	</div>
</div>

<style lang="scss">
@use '../..//styles/setup/mixins';

.contact-us {
	.clickup-embed {
		.loader {
			margin-left: 50%;
		}

		@include mixins.card;

		position: relative;
		justify-content: center;
		align-items: center;
		margin: -50px 0 0 9%;
		height: 850px;
		width: 82%;

		iframe {
			width: 100%;
			height: 850px;
		}
	}
}
</style>
