<script lang="ts">
import { onMount } from 'svelte'
import { loggedInUser } from '../../../store/stores'
import { dogService } from '../../../services/core/dog.service'
import type { DogModel } from '../../../models/dog.model'
import { _ } from 'svelte-i18n'

export let teamsForDisplay: { label: string; value: string }[]
export let selectedTeam: any
export let dogsForDisplay: { label: string; value: string; image: string }[]
export let teamDogs: DogModel[]

function getDogsWithTeamId(dogs: DogModel[], teamId: string) {
	return dogs.filter((dog) => {
		return dog.teams.some((team) => team.id === teamId)
	})
}

async function loadTeamDogs(teamId: string) {
	const accountDogs = await dogService.query($loggedInUser.selectedAccount.id)
	teamDogs = getDogsWithTeamId(accountDogs, teamId)
}

function getDogsForDisplay(dogs: DogModel[]) {
	return dogs.map((dog) => ({ label: dog.name, value: dog.id, image: dog.image }))
}

async function loadScript(src: string) {
	const script = document.createElement('script')
	script.src = src
	document.body.appendChild(script)
	await new Promise((resolve, reject) => {
		script.onload = () => resolve(true)
		script.onerror = () => reject(new Error(`${src} failed to load`))
	})
}

onMount(async () => {
	await loadScript('/assets/js/jquery-3.7.2.min.js')
	await loadScript('/assets/js/select2.min.js')
	if (selectedTeam) {
		await loadTeamDogs(selectedTeam)
		dogsForDisplay = getDogsForDisplay(teamDogs)
	}
	initSelect2()
})

async function initSelect2() {
	const $teamSelect = (window as any).jQuery('.team-select')

	$teamSelect.select2({
		placeholder: {
			id: '',
			text: $_('select_a_team')
		},
		language: {
			noResults: function () {
				return $_('no_results_found')
			}
		},
		dropdownPosition: 'below'
	})

	$teamSelect.on('select2:select', async function () {
		selectedTeam = (window as any).jQuery(this).val()
		dogsForDisplay = []
		await loadTeamDogs(selectedTeam)
		dogsForDisplay = getDogsForDisplay(teamDogs)

		$teamSelect
			.siblings('.select2-container')
			.find('.select2-selection__rendered')
			.css('color', '#000')
	})

	$teamSelect.on('select2:open', function () {
		(window as any).jQuery('.select2-search__field').attr('placeholder', $_('search_a_team'))
	})

	$teamSelect.on('select2:unselect', function () {
		$teamSelect
			.siblings('.select2-container')
			.find('.select2-selection__rendered')
			.css('color', '#6b7280')
	})
}
</script>

<select
	id="team-select"
	class="team-select edit-input"
	name="state"
	bind:value={selectedTeam}>
	<option
		value=""
		disabled
		selected
		hidden>{$_('select_a_team')}</option>
	{#if teamsForDisplay}
		{#each teamsForDisplay as object}
			<option
				class="option"
				value={object.value}>{object.label}</option>
		{/each}
	{/if}
</select>

<style lang="scss">
@import 'select2/dist/css/select2.css';
:global(.select2-container--default) {
	height: 42px;
	width: 100% !important;
}
:global(.select2-container .select2-selection--single) {
	height: 42px !important;
	border: none;
	border-radius: 6px !important;
	border: 1px solid #e6e8f0 !important;
}
:global(.select2-container--default .select2-results__option--disabled) {
	display: none;
}
:global(.select2-dropdown) {
	border-radius: 8px;
	border: 1px solid #e6e8f0;
}

:global(.select2-container--default .select2-selection--single .select2-selection__arrow) {
	height: 42px;
	margin: -1px 5px 0 0;
}

:global(.select2-results__option:not(.select2-results__option--selected):hover) {
	background-color: #e7f2ff !important;
	color: #000 !important;
}

:global(.select2-results__option--highlighted) {
	background-color: #e7f2ff !important;
	color: #000 !important;
}

:global(.select2-dropdown) {
	border: 1px solid #e6e8f0 !important;
	border-top: none !important;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

:global(.select2-container--default .select2-search--dropdown .select2-search__field) {
	border: 1px solid #e6e8f0 !important;
}

:global(.select2-results__option--selectable) {
	border: none;
}

:global(.select2-search--dropdown .select2-search__field) {
	border: none !important;
	border-radius: 4px !important;
	height: 42px !important;
}

:global(.select2-results__option) {
	padding: 12px 0 12px 14px !important;
	overflow: hidden !important;
}

:global(.select2-container--open .select2-dropdown--below) {
	margin-top: 3px;
	border-radius: 4px !important;
	overflow: hidden !important;
}

:global(.select2-container--open .select2-dropdown--below) {
	overflow: hidden !important;
}
:global(.select2-container--default .select2-results__option--selected) {
	border: none !important;
	border-radius: 0 !important;
	background-color: #007aff !important;
	color: #fff !important;
}

:global(.select2-results__option) {
	padding: 10px;
}

:global(.select2-results__option .select2-results__option--selectable) {
	display: none !important;
}
:global(.select2-container--default .select2-selection--single .select2-selection__rendered) {
	padding-top: 1px;
}

:global(.select2-selection__placeholder) {
	color: #6b7280 !important;
}
</style>
