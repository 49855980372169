<svg
	width="49"
	height="48"
	viewBox="0 0 49 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_0_644)">
		<rect
			x="0.273438"
			width="48"
			height="48"
			rx="24"
			fill="#FDEAEB" />
		<path
			d="M9.81737 15.8129C10.3304 14.9498 10.8269 14.5815 12.1823 14.0585C12.3654 13.9878 12.9148 13.9801 13.4032 14.0414C17.7996 14.5935 17.6389 20.9834 13.2207 21.2933C11.1048 21.4414 9.5217 20.0892 9.34434 17.982C9.25954 16.9727 9.33568 16.623 9.81737 15.8129Z"
			fill="url(#paint0_linear_0_644)" />
		<path
			d="M31.7304 15.8129C32.2434 14.9498 32.74 14.5815 34.0953 14.0585C34.2785 13.9878 34.8279 13.9801 35.3162 14.0414C39.7127 14.5935 39.5519 20.9834 35.1337 21.2933C33.0179 21.4414 31.4347 20.0892 31.2574 17.982C31.1726 16.9727 31.2487 16.623 31.7304 15.8129Z"
			fill="url(#paint1_linear_0_644)" />
		<path
			d="M18.5439 18.9324C18.8582 17.4215 20.2988 15.7336 21.6898 15.2468C23.715 14.5381 25.4556 14.9564 26.9715 16.5166C28.8222 18.4209 28.9894 20.8677 27.4174 23.0347C26.1982 24.7148 23.4671 25.4413 21.4824 24.6135C19.3577 23.7271 18.0662 21.2301 18.5439 18.9324Z"
			fill="url(#paint2_linear_0_644)" />
		<path
			d="M6.66568 27.8809C7.01635 26.0122 9.12173 23.9021 11.2127 23.3238C13.447 22.7057 16.0636 23.6056 17.569 25.5095L18.4448 26.6177L16.9636 28.0697L15.4827 29.5217L11.2226 29.461C6.46635 29.3931 6.38721 29.3657 6.66568 27.8809Z"
			fill="url(#paint3_linear_0_644)" />
		<path
			d="M30.1386 25.6604C30.984 24.5014 32.1364 23.7228 33.61 23.3151C36.407 22.5412 39.7459 24.2745 40.9413 27.121C41.3538 28.1033 41.3804 28.8878 41.0137 29.2526C40.8225 29.4428 39.5072 29.5217 36.5332 29.5217H32.323L30.8623 28.0966L29.4013 26.6712L30.1386 25.6604Z"
			fill="url(#paint4_linear_0_644)" />
		<path
			d="M18.3382 27.6483C18.8275 27.2775 19.8359 26.7201 20.5787 26.41C22.4856 25.6142 25.4431 25.7068 27.4248 26.6246C28.0497 26.914 28.9031 27.5495 29.5536 28.3575C31.0063 30.1618 32.86 33.5719 31.8538 34.7113C31.5988 35 23.0677 35 23.0677 35C17.4021 35 15.2916 34.9237 15.0778 34.7113C14.0379 33.678 16.0051 29.4165 18.3382 27.6483Z"
			fill="url(#paint5_linear_0_644)" />
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_0_644"
			x1="12.9665"
			y1="14"
			x2="12.9665"
			y2="21.3043"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#F96E6F" />
			<stop
				offset="1"
				stop-color="#EF4F50" />
		</linearGradient>
		<linearGradient
			id="paint1_linear_0_644"
			x1="34.8795"
			y1="14"
			x2="34.8795"
			y2="21.3043"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#F96E6F" />
			<stop
				offset="1"
				stop-color="#EF4F50" />
		</linearGradient>
		<linearGradient
			id="paint2_linear_0_644"
			x1="23.4665"
			y1="14.913"
			x2="23.4665"
			y2="24.9565"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#F96E6F" />
			<stop
				offset="1"
				stop-color="#EF4F50" />
		</linearGradient>
		<linearGradient
			id="paint3_linear_0_644"
			x1="12.51"
			y1="23.1304"
			x2="12.51"
			y2="29.5217"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#F96E6F" />
			<stop
				offset="1"
				stop-color="#EF4F50" />
		</linearGradient>
		<linearGradient
			id="paint4_linear_0_644"
			x1="35.3361"
			y1="23.1304"
			x2="35.3361"
			y2="29.5217"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#F96E6F" />
			<stop
				offset="1"
				stop-color="#EF4F50" />
		</linearGradient>
		<linearGradient
			id="paint5_linear_0_644"
			x1="23.4665"
			y1="25.8696"
			x2="23.4665"
			y2="35"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#F96E6F" />
			<stop
				offset="1"
				stop-color="#EF4F50" />
		</linearGradient>
		<clipPath id="clip0_0_644">
			<rect
				width="48"
				height="48"
				fill="white"
				transform="translate(0.273438)" />
		</clipPath>
	</defs>
</svg>
