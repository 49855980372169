<svg
	width="25"
	height="25"
	viewBox="0 0 24 24"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_0_1092)">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
			fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 9.14999C10.426 9.14999 9.15002 10.426 9.15002 12C9.15002 13.574 10.426 14.85 12 14.85C13.574 14.85 14.85 13.574 14.85 12C14.85 10.426 13.574 9.14999 12 9.14999ZM7.65002 12C7.65002 9.59756 9.59759 7.64999 12 7.64999C14.4025 7.64999 16.35 9.59756 16.35 12C16.35 14.4024 14.4025 16.35 12 16.35C9.59759 16.35 7.65002 14.4024 7.65002 12Z"
			fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.10968 5.10968C5.40258 4.81679 5.87745 4.81679 6.17034 5.10968L9.98034 8.91968C10.2732 9.21258 10.2732 9.68745 9.98034 9.98034C9.68745 10.2732 9.21258 10.2732 8.91968 9.98034L5.10968 6.17034C4.81679 5.87745 4.81679 5.40258 5.10968 5.10968Z"
			fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.0197 14.0197C14.3126 13.7268 14.7874 13.7268 15.0803 14.0197L18.8903 17.8297C19.1832 18.1225 19.1832 18.5974 18.8903 18.8903C18.5974 19.1832 18.1225 19.1832 17.8297 18.8903L14.0197 15.0803C13.7268 14.7874 13.7268 14.3126 14.0197 14.0197Z"
			fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.98034 14.0197C10.2732 14.3126 10.2732 14.7874 9.98034 15.0803L6.17034 18.8903C5.87745 19.1832 5.40258 19.1832 5.10968 18.8903C4.81679 18.5974 4.81679 18.1225 5.10968 17.8297L8.91968 14.0197C9.21258 13.7268 9.68745 13.7268 9.98034 14.0197Z"
			fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.8903 5.10968C19.1832 5.40258 19.1832 5.87745 18.8903 6.17034L15.0803 9.98034C14.7874 10.2732 14.3126 10.2732 14.0197 9.98034C13.7268 9.68745 13.7268 9.21258 14.0197 8.91968L17.8297 5.10968C18.1225 4.81679 18.5974 4.81679 18.8903 5.10968Z"
			fill="currentColor" />
	</g>
	<defs>
		<clipPath id="clip0_0_1092">
			<rect
				width="24"
				height="24"
				fill="white" />
		</clipPath>
	</defs>
</svg>
