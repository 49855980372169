<script lang="ts">
import { onMount } from 'svelte'
import { insightsService } from '../../services/insights/data.service'
import exporting from 'highcharts/modules/exporting'
import * as Highcharts from 'highcharts'
import { _ } from 'svelte-i18n'
import { utilService } from '../../utils/util.service'

export let comparisonIds = []
export let data
export let category = undefined
export let infoId = ''

let chart: Highcharts.Chart
let sessionKeys

onMount(async () => {
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()

	if (data) {
		const dataForDisplay = insightsService.getTotalTrainingSessions(data, category, infoId)

		if (comparisonIds.length > 0) {
			dataForDisplay.datasets = dataForDisplay.datasets.filter((dataset) =>
				comparisonIds.includes(dataset.id)
			)
		}

		createChart(dataForDisplay)
	}
})

function createChart(dataForDisplay) {
	if (sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing') {
		exporting(Highcharts)
	}

	const colorList = [
		'#fd7f6f',
		'#7eb0d5',
		'#b2e061',
		'#bd7ebe',
		'#ffb55a',
		'#ffee65',
		'#beb9db',
		'#fdcce5',
		'#8bd3c7'
	]

	dataForDisplay.datasets.sort((a, b) => a.name.localeCompare(b.name))

	const chartConfig: Highcharts.Options = {
		chart: {
			type: 'area'
		},
		title: {
			text: ''
		},
		xAxis: {
			categories: dataForDisplay.labels,
			lineWidth: 0.1,
			labels: {
				style: {
					opacity: 0.6
				}
			}
		},
		yAxis: {
			title: {
				text: $_('sessions')
			},
			gridLineWidth: 0.6,
			minorGridLineWidth: 0,
			labels: {
				style: {
					opacity: 0.6
				}
			}
		},
		tooltip: {
			formatter: function () {
				const month = this.x
				const value = this.series.name + ': ' + Highcharts.numberFormat(this.y, 0, ',', '')
				return (
					'<span style="font-weight: normal; font-size: 11px">' +
					month +
					'</span><br/><b>' +
					value +
					`</b>`
				)
			}
		},
		plotOptions: {
			area: {
				stacking: 'normal',
				lineWidth: 3,
				marker: {
					lineWidth: 1,
					lineColor: '#2D7392'
				}
			}
		},
		series: dataForDisplay.datasets.map((dataset, index) => ({
			name: dataset.name,
			data: dataset.data,
			color: colorList[index % colorList.length],
			fillOpacity: 0.5
		})),
		legend: {
			enabled: !(!category || category === 'all'),
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'top'
		}
	}

	chart = Highcharts.chart('total-training-sessions', chartConfig)
}
</script>

<h1 class="title">{$_(`insights_pages.total_training_sessions`)}</h1>
<div
	class="chart-container"
	style={!(!category || category === 'all') ? 'padding-top: 5px' : 'padding-top: 30px'}>
	<div id="total-training-sessions" />
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.chart-container {
	@include mixins.card;
	background-color: #fff;
	border-radius: 6px;

	#total-training-sessions {
		height: 360px;
	}
}

.title {
	font-family: Montserrat-Medium;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
</style>
